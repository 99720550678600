
import { ref, onMounted, onUnmounted, watch } from "vue";
export default {
  props: {
    values: { type: Array, required: true },
    hoverEnabled: {type: Boolean, required: false, default: true},
    emptyState: {type: String, required: false, default: null}
  },
  emits: ["loadMoreEl", "row-clicked"],
  setup(props, { emit }) {
    const scrollComponent = ref(null);
    const scrollMore = ref(true);
    watch(
      () => props.values,
      (currentValue, oldValue) => {
        scrollMore.value = true;
        if(oldValue.length > currentValue.length) {
          window.scrollTo(0, 0);
        }
      }
    );

    function handleScroll() {
      const element = scrollComponent.value;
      if (element.getBoundingClientRect().bottom < (window.innerHeight + 1)) {
        if (scrollMore.value) {
          //fix per bug chiamata multipla
          scrollMore.value = false;
          emit("loadMoreEl");
        }
      }
    }

    function rowClicked(value) {
      if (!value.disabled) {
        emit("row-clicked", value);
      }
    }

    onMounted(() => {
      window.addEventListener("scroll", handleScroll);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", handleScroll);
    });

    return {
      scrollComponent,
      rowClicked,
    };
  },
};
