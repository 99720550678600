import { emptyClub } from '@/constants/initial-values.constants'
import { ClubCreationRequest, PadelClub, TablePage } from '@/models/club.models'
import { PadelPlayground } from '@/models/playground.models'
import { AWSSessionTokens } from '@/models/tokens.models'
import { BackEndUser } from '@/models/user.models'
import { constructPlaygroundMap, constructPlaygroundMapFull } from '@/services/mapping.service'
import { GetterTree } from 'vuex'
import { State } from './state'

export type Getters = {
    getLoader(state: State): boolean;
    getUser(state: State): BackEndUser;
    getConfirmSignupUser(state: State): {username: string; password: string};
    getTokens(state: State): AWSSessionTokens;
    getJoinWesmashRequestsPage(state: State): TablePage;
    getJoinWesmashRequests(state: State): ClubCreationRequest[];
    getShowSuccess(state: State): string;
    getShowError(state: State): string;
    getAllowDownloadContract(state: State): boolean;
    getMyClubs(state: State): PadelClub[];
    getMyClub(state: State): PadelClub;
    getMyPlaygrounds(state: State): PadelPlayground[];
    getAdminClubs(state: State): PadelClub[];
    getForcedLogout(state: State): boolean;
    getPlaygroundMapFull(state: State): any;
    getPlaygroundMap(state: State): any;
    getCurrentDate(state: State): any;
    getPriceRule(state: State): any;
    getBundleResponse(state: State): any;
    getScrollEvent(state: State): any;
    getSearchUser(state: State): any;
    getUserCards(state: State): any;
}

export const getters: GetterTree<State, State> & Getters = {
    getLoader: (state) => {
        return state.loader
    },
    getUser: (state) => {
        return state.user
    },
    getConfirmSignupUser: (state) => {
        return state.confirmSignupUser
    },
    getTokens: (state) => {
        return state.sessionTokens
    },
    getJoinWesmashRequestsPage: (state) => {
        return state.joinWesmashRequests
    },
    getJoinWesmashRequests: (state) => {
        return state.joinWesmashRequests.values
    },
    getShowSuccess: (state) => {
        return state.showSuccessToast
    },
    getShowError: (state) => {
        return state.showErrorToast
    },
    getAllowDownloadContract: (state) => {
        return state.allowDownloadContract
    },
    getMyClubs: (state) => {
        return state.myClubs;
    },
    getMyClub: (state) => {
        if(state.myClubs.length === 0) {
            return emptyClub;
        } else {
            let returnedClub = emptyClub;
             state.myClubs.forEach((club: PadelClub)=> {
                if(club.club_id === state.myClub) {
                    returnedClub = club;
                } 
            }) 
            return returnedClub;
        }
    },
    getMyPlaygrounds: (state) => {
        return state.myPlaygrounds;
    },
    getAdminClubs: (state) => {
        return state.adminClubs;
    },
    getForcedLogout: (state) => {
        return state.forcedLogout;
    },
    getPlaygroundMapFull: (state) => {
        return constructPlaygroundMapFull(state.myPlaygrounds)
    },
    getPlaygroundMap: (state) => {
        return constructPlaygroundMap(state.myPlaygrounds)
    },
    getCurrentDate: (state) => {
        return state.currentDate;
    },
    getPriceRule: (state) => {
        return state.priceRule;
    },
    getBundleResponse: (state) => {
        return state.bundleResponse;
    },
    getScrollEvent: (state) => {
        return state.scrollEvent;
    },
    getSearchUser: (state) => {
        return state.searchUser;
    },
    getUserCards: (state) => {
        return state.userCards;
    },
}