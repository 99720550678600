import { createApp } from 'vue';
import App from './App.vue';

import { store } from './store/store';

import { it } from './i18n/it';
import { en } from './i18n/en';
import { createI18n } from 'vue-i18n';

import PrimeVue from 'primevue/config'

import Button from 'primevue/button'
import Carousel from 'primevue/carousel';
import Card from 'primevue/card';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Rating from 'primevue/rating';
import Avatar from 'primevue/avatar';
import Calendar from 'primevue/calendar';
import Paginator from 'primevue/paginator';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dropdown from 'primevue/dropdown';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Chip from 'primevue/chip';
import AutoComplete from 'primevue/autocomplete';
import Chart from 'primevue/chart/Chart.vue';
import Menu from 'primevue/menu/Menu.vue';
import MultiSelect from 'primevue/multiselect';
import InputSwitch from 'primevue/inputswitch';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import Badge from 'primevue/badge';
import Checkbox from 'primevue/checkbox';
import Slider from 'primevue/slider';

/* WIDGETS */
import PadelInput from './widgets/input/PadelInput.vue';
import PadelButton from './widgets/button/PadelButton.vue';
import PadelCalendar from './widgets/calendar/PadelCalendar.vue';
import PadelToggle from './widgets/toggle/PadelToggle.vue';
import PadelCheckbox from './widgets/checkbox/PadelCheckbox.vue';
import PadelRadio from './widgets/radio/PadelRadio.vue';
import DumbModal from '@/widgets/dumb-modal/DumbModal.vue';
import PadelTimepicker from '@/widgets/timepicker/PadelTimepicker.vue';
import PadelUpload from '@/widgets/upload-file/PadelUpload.vue';
import PadelDropdown from '@/widgets/dropdown/PadelDropdown.vue';
import PadelToast from '@/widgets/toast/PadelToast.vue';
import List from '@/widgets/list/List.vue';
import PadelTooltip from '@/widgets/tooltip/PadelTooltip.vue';
import PadelTextarea from '@/widgets/textarea/PadelTextarea.vue';
import PadelSearch from '@/widgets/search/PadelSearch.vue';
import Loader from '@/widgets/loader/Loader.vue';
import ProgressBar from '@/widgets/progress-bar/ProgressBar.vue';


import Nav from '@/components/nav/Nav.vue';
import RequestsTable from '@/components/requests-table/RequestsTable.vue';
import AdminStatsTable from '@/components/admin-stats-table/AdminStatsTable.vue';
import ClubsTable from '@/components/clubs-table/ClubsTable.vue';
import PadelLateralTab from '@/components/lateral-tab/PadelLateralTab.vue';
import Disambiguation from '@/components/disambiguation/Disambiguation.vue';
import ChangePasswordModal from '@/components/change-password-modal/ChangePasswordModal.vue'
import ModifyClub from '@/components/modify-club/ModifyClub.vue';
import ModifyPlaygrounds from '@/components/modify-playgrounds/ModifyPlaygrounds.vue';
import PlaygroundCard from '@/components/playground-card/PlaygroundCard.vue';
import PlaygroundInfoModal from '@/components/playground-info-modal/PlaygroundInfoModal.vue';
import NewBookingModal from '@/components/new-booking-modal/NewBookingModal.vue';
import BookingDetailModal from '@/components/booking-detail-modal/BookingDetailModal.vue';
import ClubDetail from '@/components/club-detail/ClubDetail.vue';
import AddTournament from '@/components/add-tournament/AddTournament.vue';
import TournamentCard from '@/components/tournament-card/TournamentCard.vue';
import ModalSuccess from '@/components/modal-success/ModalSuccess.vue';
import ModifyTournament from '@/components/modify-tournament/ModifyTournament.vue';
import TournamentInfo from '@/components/tournament-info/TournamentInfo.vue';
import TournamentSlots from '@/components/tournament-slots/TournamentSlots.vue';
import TournamentTeams from '@/components/tournament-teams/TournamentTeams.vue';
import TournamentDetailModal from '@/components/tournament-detail-modal/TournamentDetailModal.vue';
import PricesRules from '@/components/prices-rules/PricesRules.vue';
import PricesCalendar from '@/components/prices-calendar/PricesCalendar.vue';
import ADDialog from '@/components/ad-dialog/ADDialog.vue';
import ADFileUpload from '@/components/ad-file-upload/ADFileUpload.vue';
import UsersSearch from '@/components/users-search/UsersSearch.vue';
import ClubSettings from '@/components/club-settings/ClubSettings.vue';
import BookingTakingModal from '@/components/booking-taking-modal/BookingTakingModal.vue';
import OpenBookingForm from '@/components/open-booking-form/OpenBookingForm.vue';
import VueSlider from 'vue-slider-component'



import 'primevue/resources/themes/saga-blue/theme.css'      //theme
import 'primevue/resources/primevue.min.css'                //core css
import 'primeicons/primeicons.css'                          //icons
import 'primeflex/primeflex.css';                           //primeflex
import 'vue-slider-component/theme/default.css'
import '@/theme/override.css';

import Amplify from 'aws-amplify';
import { awsConfiguration } from './aws-exports';
import router from './router';
import 'core-js/stable';
import utils from "@/libraries/utils.js";

import moment from 'moment';
moment.updateLocale("it", {
    week: {
        dow: 1,
        doy: 4
    }
});

Amplify.configure(awsConfiguration);

const app = createApp(App).use(router);
const i18n = createI18n({
    locale: 'it',
    messages: {
        it: it,
        en: en
    }
})


app.component('Button', Button);
app.component('Carousel', Carousel);
app.component('Card', Card);
app.component('Dialog', Dialog);
app.component('InputText', InputText);
app.component('Rating', Rating);
app.component('Avatar', Avatar);
app.component('Calendar', Calendar);
app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Paginator', Paginator);
app.component('Dropdown', Dropdown);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Chip', Chip);
app.component('AutoComplete', AutoComplete);
app.component('Slider', Slider);
app.component('Chart', Chart);
app.component('Menu', Menu);
app.component('Nav', Nav);
app.component('Disambiguation', Disambiguation);
app.component('ChangePasswordModal', ChangePasswordModal);
app.component('ModifyClub', ModifyClub);
app.component('ModifyPlaygrounds', ModifyPlaygrounds);
app.component('PlaygroundCard', PlaygroundCard);
app.component('PlaygroundInfoModal', PlaygroundInfoModal);
app.component('DumbModal', DumbModal);
app.component('PadelTimepicker', PadelTimepicker);
app.component('PadelUpload', PadelUpload);
app.component('PadelDropdown', PadelDropdown);
app.component('PadelToast', PadelToast);
app.component('PadelLateralTab', PadelLateralTab);
app.component('RequestsTable', RequestsTable);
app.component('ClubsTable', ClubsTable);
app.component('PadelInput', PadelInput);
app.component('PadelButton', PadelButton);
app.component('PadelCalendar', PadelCalendar);
app.component('PadelToggle', PadelToggle);
app.component('PadelCheckbox', PadelCheckbox);
app.component('PadelRadio', PadelRadio);
app.component('List', List);
app.component('PadelTooltip', PadelTooltip);
app.component('PadelTextarea', PadelTextarea);
app.component('PadelSearch', PadelSearch);
app.component('Loader', Loader);
app.component('ProgressBar', ProgressBar);
app.component('NewBookingModal', NewBookingModal);
app.component('BookingDetailModal', BookingDetailModal);
app.component('ClubDetail', ClubDetail);
app.component('AddTournament', AddTournament);
app.component('TournamentCard', TournamentCard);
app.component('ModalSuccess', ModalSuccess);
app.component('ModifyTournament', ModifyTournament);
app.component('TournamentInfo', TournamentInfo);
app.component('TournamentSlots', TournamentSlots);
app.component('TournamentTeams', TournamentTeams);
app.component('TournamentDetailModal', TournamentDetailModal);
app.component('PricesRules', PricesRules);
app.component('PricesCalendar', PricesCalendar);
app.component('MultiSelect', MultiSelect);
app.component("InputSwitch", InputSwitch);
app.component("ToggleButton", ToggleButton);
app.component("Badge", Badge);
app.component("ADDialog", ADDialog);
app.component("ADFileUpload", ADFileUpload);
app.component("UsersSearch", UsersSearch);
app.component("Checkbox", Checkbox);
app.component("ClubSettings", ClubSettings);
app.component("BookingTakingModal", BookingTakingModal);
app.component("OpenBookingForm", OpenBookingForm);
app.component('VueSlider', VueSlider);
app.component('AdminStatsTable', AdminStatsTable);
app.directive('tooltip', Tooltip);

app.use(store);
app.use(i18n);
app.use(PrimeVue,
    {
        locale: {
            monthNames: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
            monthNamesShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
            dayNames: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
            dayNamesMin: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
            dayNamesShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
            firstDayOfWeek: 1
        }
    });
app.use(utils);
app.mount('#app')


