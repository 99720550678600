<DumbModal :title="t('edit_taking')" @hide="hideModal()" :modal="false" :minimizable="true"
    :breakpoints="{'720px': '50vw', '640px': '55vw'}" :style="{width: '75vw'}">
    <div class="form-container">
        <PadelInput :label="t('price')" :value="price" :inputType="'number'" :isPrice="true" :icon="'euro_symbol'"
            @input="setFormValue('price', $event)" />
        <PadelInput :label="t('taking')" :value="taking" :inputType="'number'" :isPrice="true" :icon="'euro_symbol'"
            @input="setFormValue('taking', $event)" />

        <PadelDropdown :label="t('payment_state')" :options="PaymentOptions" class="payment-dropdown"
            :default="paymentState" @input="updatePaymentState($event.value)" />
    </div>

    <div class="button-row flexbox-row justify-space-between margin-top-40">
        <p class="abort" @click="hideModal()">{{uppercase(t('back'))}}</p>
        <PadelButton :label="uppercase(t('save'))" @click="saveChanges()"></PadelButton>
    </div>
</DumbModal>