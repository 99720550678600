import { ChartInfo } from "@/models/chart.models";
import { formatStatsDate } from "@/services/dates.service";

export const bookingsStatsOptions = {
    layout: {
        padding: {
            left: 50,
            right: 50,
            top: 0,
            bottom: 0
        }
    },
    legend: {
        display: false
    },
    scales: {
        yAxes: [{
            id: 'left-axis',
            display: true,
            ticks: {
                fontFamily: "Barlow", fontColor: "rgba(255, 255, 255, 0.5)", beginAtZero: true, min: 0, padding: 30,
                callback: function (value) { if (value % 1 === 0 || value % 1 === 0.5) { return value; } }
            },
            yPadding: 20,
            gridLines: {
                zeroLineColor: 'rgba(200, 182, 127, 0.5)',
                color: 'rgba(200, 182, 127, 0.5)',
                lineWidth: 1,
                tickMarkLength: 0
            },

        },
        {
            beforeUpdate: function (scale) {
                /* eslint-disable */
                const nMaxRev = Math.ceil(Math.max.apply(Math, scale.chart.config.data.datasets[0].data)) + 10;
                let nLeftTickCount = scale.chart.scales['left-axis'].ticks.length;
                nLeftTickCount = (nLeftTickCount < 7) ? 7 : nLeftTickCount - 1;
                const nTickInterval = nMaxRev / nLeftTickCount;
                scale.chart.options.scales.yAxes[1].ticks.stepSize = nTickInterval;
                scale.chart.options.scales.yAxes[1].ticks.max = nMaxRev;
                return;
            },
            position: 'right',
            ticks: {
                display: false,
                callback: function (value) { if (value % 1 === 0 || value % 1 === 0.5) { return value; }},
                padding: 0
            },
            gridLines: {
                color: 'rgba(200, 182, 127, 0.5)',
                tickMarkLength: 0,
                display: true
            },

        }],
        xAxes: [{
            display: false
        }]

    },
    tooltips: {
        enabled: true,
        callbacks: {
            title: function (obj, data) {
                if (obj[0].datasetIndex === 0) {
                    return `Somma delle ore di lezione su tutti i campi il ${formatStatsDate(obj[0].label)}`;
                } else {
                    return `Somma delle ore prenotate su tutti i campi il ${formatStatsDate(obj[0].label)}`;
                }
            },
            label: function (obj: ChartInfo, data) {
                if (obj.datasetIndex === 0) {
                    return `Ore lezione: ${obj.value}`;
                } else {
                    return `Ore prenotate: ${obj.value}`;
                }

            },
            afterLabel: function (context) {
                return
            },
            labelColor: function (context) {
                return {
                    display: 'none'
                }
            },
            labelTextColor: function (context) {
                return '#000000'
            }
        },
        titleMarginBottom: 10,
        titleFontColor: '#000000',
        titleFontStyle: 'normal',
        titleFontSize: 14,
        bodyFontSize: 14,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        displayColors: false,
        xPadding: 10,
        yPadding: 10
    },
    plugins: {}
}

export const cancellationsOrIncomeStatsOptions = {
    layout: {
        padding: {
            left: 50,
            right: 50,
            top: 0,
            bottom: 0
        }
    },
    legend: {
        display: false
    },
    scales: {
        yAxes: [{
            id: 'left-axis',
            display: true,
            position: 'left',
            ticks: {
                fontFamily: "Barlow", fontColor: "rgba(255, 255, 255, 0.5)", beginAtZero: true, min: 0, padding: 30,
                callback: function (value) { if (value % 1 === 0 || value % 1 === 0.5) { return value; } }
            },
            yPadding: 20,
            gridLines: {
                zeroLineColor: 'rgba(200, 182, 127, 0.5)',
                color: 'rgba(200, 182, 127, 0.5)',
                lineWidth: 1,
                stepSize: 10,
                tickMarkLength: 0
            },

        },
        {
            beforeUpdate: function (scale) {

                const nMaxRev = Math.max.apply(Math, scale.chart.config.data.datasets[0].data);
                let nLeftTickCount = scale.chart.scales['left-axis'].ticks.length;
                nLeftTickCount = (nLeftTickCount < 7) ? 7 : nLeftTickCount - 1;
                const nTickInterval = nMaxRev / nLeftTickCount;
                scale.chart.options.scales.yAxes[1].ticks.stepSize = nTickInterval;
                return;
            },
            display: true,
            position: 'right',
            ticks: { display: false, callback: function (value) { if (value % 1 === 0 || value % 1 === 0.5) { return value; }} },
            yPadding: 20,
            gridLines: {
                zeroLineColor: 'rgba(200, 182, 127, 0.5)',
                color: 'rgba(200, 182, 127, 0.5)',
                lineWidth: 1,
                stepSize: 10,
                tickMarkLength: 0
            },

        }],
        xAxes: [{
            display: false
        }]

    },
    tooltips: {
        enabled: true,
        callbacks: {
            title: function (obj, data) {
                if (data.datasets[0].label === 'Guadagni') {
                    return `Ricavi nel giorno ${formatStatsDate(obj[0].label)}`;
                } else {
                    return `Ore cancellate del giorno ${formatStatsDate(obj[0].label)}`;
                }
            },
            label: function (obj: ChartInfo, data) {
                if (data.datasets[0].label === 'Guadagni') {
                    return `Ricavi: ${obj.value} €`;
                } else {
                    return `Ore cancellate: ${obj.value}`;
                }

            },
            afterLabel: function (context) {
                return
            },
            labelColor: function (context) {
                return {
                    display: 'none'
                }
            },
            labelTextColor: function (context) {
                return '#000000'
            }
        },
        titleMarginBottom: 10,
        titleFontColor: '#000000',
        titleFontStyle: 'normal',
        titleFontSize: 14,
        bodyFontSize: 14,
        backgroundColor: 'rgba(255, 255, 255, 1)',
        displayColors: false,
        xPadding: 10,
        yPadding: 10
    },
}