import { ErrorMessages } from "@/constants/error.enum";
import { ToastErrors } from "@/constants/generic-errors.enum";
import { ActionTypes } from "@/store/action-types";
import { MutationTypes } from "@/store/mutation-types";
import { useStore } from '@/store/store';
import router from "@/router"


export function showToast(error: string) {
    const store = useStore();
    store.commit(MutationTypes.SET_SHOW_ERROR, error);
    setTimeout(() => {
        store.commit(MutationTypes.SET_SHOW_ERROR, null);
    }, 3000);
}

export function showSuccessToast(error: string) {
    const store = useStore();
    store.commit(MutationTypes.SET_SHOW_SUCCESS, error);
    setTimeout(() => {
        store.commit(MutationTypes.SET_SHOW_SUCCESS, null);
    }, 3000);
}

//METODO PER FORZARE IL LOGOUT
export function forceLogout() {
    const store = useStore();
    store.dispatch(ActionTypes.LOGOUT, null);
    store.commit(MutationTypes.RESET_STORE, null);
    store.commit(MutationTypes.SET_FORCED_LOGOUT, true);
    router.push("login");
}

export function handleError(error: any, _showToast = false) {
    const store = useStore();
    console.error(error);

    store.commit(MutationTypes.CLOSE_LOADER, null);
    if (error.message === ErrorMessages.ERROR_REFRESH_TOKENS) {
        forceLogout();
    }
    else if (_showToast) {
        let message = ToastErrors.GENERIC;
        if (error.response) {
            if (error.response.data) {
                message = error.response.data.message || ToastErrors.GENERIC;
            }
        } else if (error.message) {
            message = error.message;
        }
        showToast(message);
    }
}