
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  nextTick,
  watch,
} from "vue";
import {
  uppercase,
  upperCaseFirstLetter,
  returnSearchUserDropdownChoices,
} from "@/services/mapping.service";
import { PadelUser } from "@/models/user.models";
import { ActionTypes } from "@/store/action-types";
import { boolean } from "yup/lib/locale";

export default defineComponent({
  components: {},
  props: {
    searchUser: {
      type: Object,
      required: false,
      default: null,
    },
    old: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["userSelected"],
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const store = useStore();
    const user = ref(null);
    const options = ref([]);

    // COMPUTED
    const myClubId = computed(function (): string {
      return store.getters.getMyClub.club_id;
    });

    // METHODS
    async function searchUsers(event: string, callback?: Function) {
      user.value = event;

      if (props.old) {
        try {
          const usersArray = await store.dispatch(ActionTypes.GET_USERS, {
            club_id: myClubId.value,
            filters: { page_size: 10, name: event },
          });
          if (usersArray.values.length > 0) {
            // POPOLAMENTO DROPDOWN CHOICES
            options.value = returnSearchUserDropdownChoices(usersArray.values);
            callback(usersArray.values);
          } else {
            options.value = [];
          }
        } catch (error) {
          return;
        }
      } else {
        try {
          const usersArray = await store.dispatch(ActionTypes.GET_USERS_AXIOS, {
            club_id: myClubId.value,
            filters: { page_size: 10, name: event },
          });
          if (usersArray.values.length > 0) {
            // POPOLAMENTO DROPDOWN CHOICES
            options.value = returnSearchUserDropdownChoices(usersArray.values);
            callback(usersArray.values);
          } else {
            options.value = [];
          }
        } catch (error) {
          return;
        }
      }
    }

    function handleSearchClose() {
      options.value = null;
      user.value = null;
    }

    function searchValueSelected(
      event: { label: string; value: PadelUser },
      withEmit: boolean = true
    ) {
      user.value = event.label;

      setTimeout(() => {
        if (withEmit) emit("userSelected", event.value);
      }, 250);
    }

    function onSearchValidate(event) {
      setTimeout(() => {
        user.value = "";
      }, 10);
      user.value = null;
      handleSearchClose();
    }

    function selectDefault() {
      const val = props.searchUser as PadelUser;
      if (val) {
        options.value = returnSearchUserDropdownChoices([val]);
        user.value = options.value[0].label;
      }
    }

    // SYSTEM
    onMounted(() => {
      nextTick(() => {
        selectDefault();
      });
    });

    return {
      t,
      locale,
      uppercase,
      upperCaseFirstLetter,
      searchUsers,
      handleSearchClose,
      searchValueSelected,
      onSearchValidate,

      // DATA
      user,
      options,
    };
  },
});
