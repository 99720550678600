<PadelLateralTab />

<div class="view-main-container">

    <div class="inner-view-container">

        <div class="header flexbox-row padding-top-40 padding-bottom-40">
            <div class="cursor-pointer flexbox-row align-center" @click="router.go(-1)">
                <i class="material-icons color-blue cursor-pointer margin-right-10">keyboard_arrow_left</i>
                <div class="go-back-text color-blue">{{uppercase(t('back'))}}</div>
            </div>
        </div>

        <TabView class="tab-view p-mb-5" :activeIndex="selectedTab" @tab-change="onChangeTab($event.index)"
            v-if="openGame">
            <TabPanel>
                <template #header>
                    <i class="material-icons" :class="{'color-blue': selectedTab === 0}">info</i>
                    <span>{{uppercase(t('info'))}}</span>
                </template>
                <div class="match-container">
                    <MatchPlayers :match="openGame" :players="getPlayersNumber()" @refresh="getOpenGame" />

                    <div class="p-grid p-mb-5">
                        <div class="p-col-12 p-md-3 p-text-left payment-card">
                            <div class="box"  v-if="openGame.player_1">
                                
                                    <div class="p-col-12 p-mb-2">
                                        <label>
                                            {{ `${openGame.player_1.name} ${openGame.player_1.family_name}` }}
                                        </label>
                                    </div>
                                    <div class="p-col-12 p-0">
                                        <label class="label">{{ t('price') }}</label><br />
                                        <span class="value">
                                            {{ t('each_person') }}: {{ openGame.price > 0 ? parseFloat((openGame.price / 100) /
                                            getPlayersNumber()).toFixed(2) : 0}} €
                                        </span>
                                    </div>
                                    <div class="p-col-12">
                                        <PadelInput :label="t('taking')" :inputType="'number'" :isPrice="true" :icon="'euro_symbol'"
                                            :value="openGame.player_1_taking" @input="updateFormValue($event, 'player_1_taking')"
                                            :hideError="hideError" :disabled="formDisabled" />
                                    
                                        <PadelDropdown :label="t('payment_state')" :options="PaymentOptions" class="payment-dropdown"
                                                @input="updateFormValue($event.value, 'player_1_payment_state')" :default="openGame.player_1_payment_state"
                                                :disabled="formDisabled"/> 

                                        <!-- Se il centro è di tipo Altra natura (commerciale): è fisso commerciale -->
                                        <!-- se è di tipo Associazione sportiva: dipente dal giocatore -->
                                        
                                        <PadelDropdown label="Tipologia prenotazione"
                                            :options="bookingCategoriesOptions" class="payment-dropdown p-mt-3"
                                            @input="updateFormValue($event.value, 'player_1_booking_category')"
                                            :default="openGame.player_1_booking_category" 
                                            :disabled="formDisabled" /> 
                                        
                                    </div>
                                
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3 p-text-left payment-card" v-if="openGame.player_2">
                            <div class="box">
                            
                                <div class="p-col-12 p-mb-2">
                                    <label>
                                        {{ `${openGame.player_2.name} ${openGame.player_2.family_name}` }}
                                    </label>
                                </div>
                                <div class="p-col-12 p-0">
                                    <label class="label">{{ t('price') }}</label><br />
                                    <span class="value">
                                        {{ t('each_person') }}: {{ openGame.price > 0 ? parseFloat((openGame.price / 100) /
                                        getPlayersNumber()).toFixed(2) : 0}} €
                                    </span>
                                </div>
                                <div class="p-col-12">
                                    <PadelInput :label="t('taking')" :inputType="'number'" :isPrice="true" :icon="'euro_symbol'"
                                        :value="openGame.player_2_taking" @input="updateFormValue($event, 'player_2_taking')"
                                        :hideError="hideError" :disabled="formDisabled" />
                                
                                    <PadelDropdown :label="t('payment_state')" :options="PaymentOptions" class="payment-dropdown"
                                            @input="updateFormValue($event.value, 'player_2_payment_state')" :default="openGame.player_2_payment_state"
                                            :disabled="formDisabled"/> 

                                    <!-- Se il centro è di tipo Altra natura (commerciale): è fisso commerciale -->
                                    <!-- se è di tipo Associazione sportiva: dipente dal giocatore -->
                                    
                                    <PadelDropdown label="Tipologia prenotazione"
                                        :options="bookingCategoriesOptions" class="payment-dropdown p-mt-3"
                                        @input="updateFormValue($event.value, 'player_2_booking_category')"
                                        :default="openGame.player_2_booking_category" 
                                        :disabled="formDisabled" /> 
                                    
                                </div>
                            
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3 p-text-left payment-card " v-if="getPlayersNumber() > 2 && openGame.player_3">
                            <div class="box">
                                <div class="p-col-12 p-mb-2">
                                    <label>
                                        {{ `${openGame.player_3.name} ${openGame.player_3.family_name}` }}
                                    </label>
                                </div>
                                <div class="p-col-12 p-0">
                                    <label class="label">{{ t('price') }}</label><br />
                                    <span class="value">
                                        {{ t('each_person') }}: {{ openGame.price > 0 ? parseFloat((openGame.price / 100) /
                                        getPlayersNumber()).toFixed(2) : 0}} €
                                    </span>
                                </div>
                                <div class="p-col-12">
                                    <PadelInput :label="t('taking')" :inputType="'number'" :isPrice="true" :icon="'euro_symbol'"
                                        :value="openGame.player_3_taking" @input="updateFormValue($event, 'player_3_taking')"
                                        :hideError="hideError" :disabled="formDisabled" />
                                
                                    <PadelDropdown :label="t('payment_state')" :options="PaymentOptions" class="payment-dropdown"
                                            @input="updateFormValue($event.value, 'player_3_payment_state')" :default="openGame.player_3_payment_state"
                                            :disabled="formDisabled"/> 
                                    
                                    <!-- Se il centro è di tipo Altra natura (commerciale): è fisso commerciale -->
                                    <!-- se è di tipo Associazione sportiva: dipente dal giocatore -->
                                    
                                    <PadelDropdown label="Tipologia prenotazione"
                                        :options="bookingCategoriesOptions" class="payment-dropdown p-mt-3"
                                        @input="updateFormValue($event.value, 'player_3_booking_category')"
                                        :default="openGame.player_3_booking_category" 
                                        :disabled="formDisabled" /> 
                                    
                                </div>
                            </div>
                        </div>
                        <div class="p-col-12 p-md-3 p-text-left payment-card " v-if="getPlayersNumber() > 2 && openGame.player_4">
                            <div class="box">
                              
                                <div class="p-col-12 p-mb-2">
                                    <label>
                                        {{ `${openGame.player_4.name} ${openGame.player_4.family_name}` }}
                                    </label>
                                </div>
                                <div class="p-col-12 p-0">
                                    <label class="label">{{ t('price') }}</label><br />
                                    <span class="value">
                                        {{ t('each_person') }}: {{ openGame.price > 0 ? parseFloat((openGame.price / 100) /
                                        getPlayersNumber()).toFixed(2) : 0}} €
                                    </span>
                                </div>
                                <div class="p-col-12">
                                    <PadelInput :label="t('taking')" :inputType="'number'" :isPrice="true" :icon="'euro_symbol'"
                                        :value="openGame.player_4_taking" @input="updateFormValue($event, 'player_4_taking')"
                                        :hideError="hideError" :disabled="formDisabled" />
                                
                                    <PadelDropdown :label="t('payment_state')" :options="PaymentOptions" class="payment-dropdown"
                                            @input="updateFormValue($event.value, 'player_4_payment_state')" :default="openGame.player_4_payment_state"
                                            :disabled="formDisabled"/> 

                                    <!-- Se il centro è di tipo Altra natura (commerciale): è fisso commerciale -->
                                    <!-- se è di tipo Associazione sportiva: dipente dal giocatore -->
                                    
                                    <PadelDropdown label="Tipologia prenotazione"
                                        :options="bookingCategoriesOptions" class="payment-dropdown p-mt-3"
                                        @input="updateFormValue($event.value, 'player_4_booking_category')"
                                        :default="openGame.player_4_booking_category" 
                                        :disabled="formDisabled" /> 
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="p-grid p-mb-5">
                        <div class="p-col-12 p-text-left">
                            <label class="label">{{ t('game_infos') }}</label>
                        </div>
                        <div class="p-col-12 p-md-4 p-text-left">
                            <label class="label">{{ t('game_date_time') }}</label><br />
                            <span class="value">{{ formatDate() }}, {{ `${formatTime(openGame.start_at, 'HH:mm')} -
                                ${formatTime(openGame.end_at, 'HH:mm')}` }}</span>
                        </div>

                        <div class="p-col-12 p-md-4 p-text-left">
                            <label class="label">{{ t('selected_sport') }}</label><br />
                            <span class="value">{{ t(openGame.sport_type) }}</span>
                        </div>
                        <div class="p-col-12 p-md-4 p-text-left">
                            <label class="label">{{ t('playground') }}</label><br />
                            <span class="value">
                                {{ openGame.playground_name }}
                            </span>
                        </div>
                        <div class="p-col-12 p-md-4 p-text-left">
                            <label class="label">{{ t('players_num') }}</label><br />
                            <span class="value">{{ t('players_with_number', { num: getPlayersNumber() }) }}</span>
                        </div>
                        <div class="p-col-12 p-md-4 p-text-left">
                            <label class="label">{{ t('OPEN_BOOKING_CONFIRMED') }}</label><br />
                            <span class="value">{{ confirmedGame(t, openGame) }}</span>
                        </div>
                        <div class="p-col-12 p-md-4 p-text-left">
                            <label class="label">{{ t('booked_playground') }}</label><br />
                            <span class="value">{{ openGame.booking_state === 'CONFIRMED' ? t('yes') : t('no') }}</span>
                        </div>
                        <div class="p-col-12 p-md-4 p-text-left">
                            <label class="label">{{ t('price') }}</label><br />
                            <span class="value">
                                {{ t('playground') }}: {{ openGame.price > 0 ? parseFloat(openGame.price / 100)
                                .toFixed(2)
                                : 0}} €
                                <!-- <br /> -->
                                <!-- {{ t('each_person') }}: {{ openGame.price > 0 ? parseFloat((openGame.price / 100) /
                                getPlayersNumber()).toFixed(2) : 0}} € -->
                            </span>
                        </div>
                        <div class="p-col-12 p-md-4 p-text-left">
                            <label class="label">{{ t('taking') }}</label><br />
                            <PadelInput :label="t('taking')" :inputType="'number'" :isPrice="true" :icon="'euro_symbol'"
                                :value="openGame.taking" @input="updateFormValue($event, 'taking')"
                                :hideError="hideError" :disabled="formDisabled" />
                        </div>

                        <div class="p-col-12 p-md-4 p-text-left">
                            <label class="label">{{ t('Link') }}</label><br />
                            <div class="p-inputgroup">
                                <InputText ref="linkInput" :placeholder="t('Link')" class="p-inputtext-sm"
                                    :disabled="true" :value="appLink" />
                                <Button icon="pi pi-copy" class="p-button-primary" @click="copyToClipboard" />
                            </div>
                        </div>
                        <div class="p-col-12 p-text-left">
                            <label class="label">{{ t('notes') }}</label><br />
                            <PadelTextarea ref="notesInput" class="textarea" :label="t('notes')"
                                :disabled="formDisabled" @input="updateFormValue($event, 'description')"
                                :value="openGame.description" />
                        </div>
                    </div>

                    <div class="p-grid p-mb-5">
                        <div class="p-col-12 p-text-left">
                            <label class="label">{{ t('game_features') }}</label>
                        </div>
                        <div class="p-col-12 p-md-4 p-text-left">
                            <label class="label">{{ t('players_type') }}</label><br />
                            <span class="value">{{ openGame.isPublic ? t('players_type_public') :
                                t('players_type_private') }}</span>
                        </div>
                        <div class="p-col-12 p-md-4 p-text-left">
                            <label class="label">{{ t('min_level') }}</label><br />
                            <span class="value">{{ minLevelLabel }}</span>
                        </div>
                        <div class="p-col-12 p-md-4 p-text-left">
                            <label class="label">{{ t('max_level') }}</label><br />
                            <span class="value">{{ maxLevelLabel }}</span>
                        </div>
                        <div class="p-col-12 p-md-4 p-text-left">
                            <label class="label">{{ t('playground_booking') }}</label><br />
                            <span class="value" v-if="formDisabled">
                                {{ confirmedBooking ? t('booked_playground') : t('not_booked_playground') }}
                            </span>
                            <span class="value" v-else>
                                <PadelToggle v-model:checked="confirmedBooking" @change="confirmedBooking = $event"
                                    containerClass="p-d-inline-flex p-ml-2"
                                    labelClass="color-white overflow-inherit p-size-mini"
                                    :label="t('booked_playground')" :disabled="formDisabled" />
                            </span>
                        </div>
                    </div>

                    <div class="p-grid p-d-flex p-jc-end">
                        <div class="p-text-right p-d-flex" v-if="formDisabled">
                            <PadelButton class="p-mr-2" :label="uppercase(t('modify_data'))" :type="'secondary'"
                                @click="toggleForm()"></PadelButton>
                            <PadelButton :label="uppercase(t('delete_game'))" :type="'error'"
                                @click="showModal('DELETE_GAME')"></PadelButton>
                        </div>
                        <div class="p-text-right p-d-flex" v-else>
                            <PadelButton class="p-mr-2" :label="uppercase(t('abort'))" :type="'secondary'"
                                @click="abortForm()"></PadelButton>
                            <PadelButton :label="uppercase(t('save_modified'))" :type="'primary'" @click="update()">
                            </PadelButton>
                        </div>
                    </div>
                </div>
            </TabPanel>
        </TabView>

    </div>

</div>


<DumbModal :title="t('delete_game')" @hide="hideModal()" v-if="modalId === 'DELETE_GAME'">
    <div class="modal-content flexbox-column justify-space-between">
        <div class="top-part">
            <i class="material-icons icon-alert">warning_amber</i>
            <div class="color-white alert-text montserrat">{{t('attention')}}</div>
            <div class="color-white modal-text montserrat" v-html="t('delete_game_message')">
            </div>
        </div>
        <div class="button-row flexbox-row justify-space-between width-100">
            <p class="abort" @click="hideModal()">{{uppercase(t('abort'))}}</p>
            <PadelButton :label="uppercase(t('delete_game'))" @click="deleteGame" />
        </div>
    </div>
</DumbModal>
