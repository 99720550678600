
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { PadelClub } from "@/models/club.models";
import { ActionTypes } from "@/store/action-types";
import Actions from "@/components/actions/Actions.vue";
import { useRouter } from "vue-router";
import { PadelPlayground } from "@/models/playground.models";
import { WEEKDAYS_SLOTS } from "@/constants/constants.constants";
import { MutationTypes } from "@/store/mutation-types";

export default defineComponent({
  name: "PricesRules",
  components: {
    Actions,
  },
  setup() {
    const store = useStore();
    const { t, locale } = useI18n();
    const rulesList = ref([]);
    const router = useRouter();
    const pagination = ref({
      total: 0,
      page: 1,
      count: 0,
      limit: 1000,
      from: 0,
      to: 0,
      totalPages: 1,
    });
    const showDeleteDialog = ref(false);
    const showDuplicateDialog = ref(false);
    const selectedRule = ref(null);
    const selectedPlaygrounds = ref([]);

    /* COMPUTED */
    const myClub = computed(function (): PadelClub {
      return store.getters.getMyClub;
    });
    const myPlaygrounds = computed(function (): PadelPlayground[] {
      return store.getters.getMyPlaygrounds;
    });
    const hasMore = computed(function (): boolean {
      return pagination.value.page < pagination.value.totalPages;
    });
    const playgrounds = computed(function () {
      return myPlaygrounds.value.map((el) => {
        return { value: el.id, label: el.name, sport: el.sport };
      });
    });

    // FUNCTIONS
    function hideModal() {
      showDeleteDialog.value = false;
      showDuplicateDialog.value = false;
      selectedRule.value = null;
    }

    async function getRules() {
      try {
        const playgroundIds =
          selectedPlaygrounds.value.length > 0
            ? selectedPlaygrounds.value.map((el) => el.value)
            : undefined;
        const response = await store.dispatch(ActionTypes.GET_PRICES_RULES, {
          club_id: myClub.value.club_id,
          page: pagination.value.page,
          playground_ids: playgroundIds,
        });
        let arr = [];
        if (pagination.value.page > 1) {
          arr = [...rulesList.value];
        }
        arr = arr.concat(response.records);
        rulesList.value = arr;
        pagination.value = response.pagination;
      } catch (error) {
        console.error(error);
        return;
      }
    }

    function mapDays(arr) {
      if (arr.length == 0) return t("no_days");
      const days = [];
      if (arr.includes(WEEKDAYS_SLOTS.MONDAY)) days.push(t("monday_short"));
      if (arr.includes(WEEKDAYS_SLOTS.TUESDAY)) days.push(t("tuesday_short"));
      if (arr.includes(WEEKDAYS_SLOTS.WEDNESDAY))
        days.push(t("wednesday_short"));
      if (arr.includes(WEEKDAYS_SLOTS.THURSDAY)) days.push(t("thursday_short"));
      if (arr.includes(WEEKDAYS_SLOTS.FRIDAY)) days.push(t("friday_short"));
      if (arr.includes(WEEKDAYS_SLOTS.SATURDAY)) days.push(t("saturday_short"));
      if (arr.includes(WEEKDAYS_SLOTS.SUNDAY)) days.push(t("sunday_short"));
      if (days.length == 7) return t("all_days");
      return days.join(", ");
    }

    function mapPlaygrounds(list, separator = ", ") {
      if (list.length === myPlaygrounds.value.length)
        return t("all_playgrounds");
      return myPlaygrounds.value
        .filter((el) => list.includes(el.id))
        .map((el) => el.name)
        .join(separator);
    }

    function goToCreate() {
      router.push({ name: "add-price-rule" });
    }

    function loadMoreRules() {
      pagination.value.page += 1;
      getRules();
    }

    function confirmDuplicate(rule) {
      selectedRule.value = rule;
      showDuplicateDialog.value = true;
    }

    function onDuplicate() {
      store.commit(MutationTypes.SET_PRICE_RULE, selectedRule.value);
      setTimeout(() => {
        goToCreate();
      }, 100);
    }

    function confirmDelete(rule) {
      selectedRule.value = rule;
      showDeleteDialog.value = true;
    }

    async function onDelete(id) {
      try {
        await store.dispatch(ActionTypes.DELETE_PRICE_RULE, {
          id: id,
        });

        // --
        rulesList.value = [];
        pagination.value.page = 1;

        // SET
        hideModal();
        getRules();
      } catch (error) {
        console.error(error);
        return;
      }
    }

    function onEdit(item) {
      router.push({ name: "edit-price-rule", params: { id: item.id } });
    }

    function showMappedPlaygrounds(ids) {
      if (ids.length <= 2 || ids.length === myPlaygrounds.value.length)
        return true;
      return false;
    }

    // SYSTEM
    onMounted(() => {
      getRules();
    });

    /** WATCH */
    watch(selectedPlaygrounds, () => {
      pagination.value.page = 1;
      getRules();
    });

    return {
      t,
      locale,

      rulesList,
      pagination,
      hasMore,
      showDeleteDialog,
      showDuplicateDialog,
      selectedRule,
      playgrounds,
      selectedPlaygrounds,

      confirmDuplicate,
      onDuplicate,
      confirmDelete,
      onDelete,
      mapDays,
      mapPlaygrounds,
      goToCreate,
      loadMoreRules,
      moment,
      hideModal,
      onEdit,
      showMappedPlaygrounds,
    };
  },
});
