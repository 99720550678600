<DumbModal :title="t('tournament_detail')" @hide="hideModal()">
    <div class="form-container flexbox-column margin-bottom-20">
        <div class="row flexbox-row">
            <PadelInput class="flex-50" :label="t('tournament_name')" :value="tournamentInfo.name || '-'" :disabled="formDisabled" />
            <PadelInput class="flex-50" :label="t('tournament_type')" :value="tournamentInfo.type || '-'" :disabled="formDisabled" />
        </div>
        <div class=" row flexbox-row">
            <PadelInput class="flex-50" :label="t('start_date')" :value="tournamentInfo.start_at || '-'" :disabled="formDisabled" />
            <PadelInput class="flex-50" :label="t('end_date')" :value="tournamentInfo.end_at || '-'" :disabled="formDisabled" />
        </div>
        <div class=" row flexbox-row">
            <PadelInput class="flex-50" :label="t('registration_deadline')" :value="tournamentInfo.registration_end_at || '-'"
                :disabled="formDisabled" />
                <PadelInput class="flex-50" :label="t('min_couples')" :value="(tournamentInfo.min_couples || tournamentInfo.min_couples === 0) ? tournamentInfo.min_couples : '-'" :disabled="formDisabled" />

        </div>
        <div class="row flexbox-row">
            <div class="flex-50 progress-bar-container flexbox-column">
                <div class="color-white montserrat margin-bottom-10">{{t("registrations_state")}}</div>
                <ProgressBar :background_color="'black-bg'" :value="tournamentInfo.teams ? tournamentInfo.teams.length : 0"
                    :maxValue="tournamentInfo.max_couples" :minValue="tournamentInfo.min_couples" />
            </div>
            <div class="empty-box flex-50"></div>
        </div>
    </div>

    <div class="button-row flexbox-row justify-space-between margin-top-20">
        <p class="abort" @click="hideModal()">{{uppercase(t('close'))}}</p>
        <PadelButton :label="uppercase(t('go_to_tournaments'))" :type="'secondary'" @click="goToTournaments()">
        </PadelButton>
    </div>
</DumbModal>