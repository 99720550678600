
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import { 
    computed, 
    defineComponent, 
    onMounted, 
    onUnmounted,
    ref,
    nextTick,
    watch,
} from "vue";
import { uppercase, upperCaseFirstLetter } from "@/services/mapping.service";
import { PadelClub } from "@/models/club.models";
import {
    CardLabels,
} from '@/constants/card-types.enum';
import Actions from "@/components/actions/Actions.vue";
import moment from "moment";

export default defineComponent({
    components: {
        Actions,
    },
    props: {
        element: {
            type: Object,
            required: true,
            default: () => {
                return {};
            },
        },
        user: {
            type: Object,
            required: true,
            default: () => {
                return {};
            },
        },
        index: { type: Number, required: true, default: null },
    },
    emits: [],
    setup(props, {emit}) {
        const { t, locale } = useI18n();
        const store         = useStore();

        // COMPUTED
        const myClub = computed(function(): PadelClub {
            return store.getters.getMyClub;
        });
        const cardImage = computed(() => {
            return require(`@/assets/cards/${props.element.type.toLowerCase()}-logo.jpg`);
        });
        const hasActions = computed(() => {
            /*if (props.element.club_id != null) {
                if (props.element.club_id !== myClub.value.club_id) return false;
            }
            if (moment(props.element.expiration_date).isBefore( moment() )) return true;
            if (props.element.certification === 'CERTIFIED') return false;*/
            return (props.element.club_id == myClub.value.club_id) || expired.value;
        });
        const certifiable = computed(() => {
            return false;
            // return props.element.certification !== 'CERTIFIED';
        });
        const editable = computed(() => {
            return props.element.club_id === myClub.value.club_id && !expired.value;
            // return props.element.certification !== 'CERTIFIED';
        });
        const deletable = computed(() => {
            return props.element.club_id === myClub.value.club_id && !expired.value;
            // return props.element.certification !== 'CERTIFIED';
        });
        const expired = computed(() => {
            const now   = moment();
            const date  = moment(props.element.expiration_date);
            return now.isAfter(date) && now.format("YYYY-MM-DD") != date.format("YYYY-MM-DD");
        });

        // SYSTEM
        onMounted(() => {
            nextTick(() => {
            })
        });

        // METHODS
        function toDate(timestamp) {
            return moment(timestamp).format("DD-MM-YYYY")
        }

        function toReadableName(value) {
            return CardLabels[value]
        }

        function onEditCard(card) {
            emit('editCard', card);
        }

        function onDeleteCard(card) {
            emit('deleteCard', card);
        }

        function onRenewCard(card) {
            emit('renewCard', card);
        }

        function addedBy() {
            if (props.element.club_id === myClub.value.club_id) return props.element.club_name;
            if (!props.element.club_id) return `${props.user.name} ${props.user.family_name}`;
            return t('other_center');
        }

        return {
            t, 
            locale, 
            uppercase,
            upperCaseFirstLetter,

            // DATA
            myClub,
            cardImage,
            hasActions,
            certifiable,
            editable,
            deletable,
            expired,

            // FUNCTIONS
            toDate,
            toReadableName,
            onEditCard,
            onDeleteCard,
            onRenewCard,
            addedBy,
        };
    },
});
