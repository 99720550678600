<div class="main-container justify-space-between flexbox-column padding-main-container">
    <input style="display:none">
<input type="password" style="display:none">
    <div class="first-part">
        <div class="header display-flex">
            <img class="smash-img" src="@/theme/imgs/logo_completo.svg" alt="">
            <div class="already-have-account font-12 color-blue cursor-pointer" @click="redirect('login')">
                {{uppercase(t('already_registered'))}}
            </div>
        </div>
        <div class="signup-text-container">
            <div class="signup-title color-gold barlow margin-top-20">
                {{t("register_sport_club")}}
            </div>
            <div class="montserrat signup-text color-white margin-top-10">
                {{t("signup_text")}}
            </div>
        </div>
        <div class="form-container flexbox-row margin-top-20">
       
                <div class="box">
                    <template v-for="(button, index) in registerButtons" :key="button">
                        <div class="input-container" :class="[index === 1 || index === 4 ? 'add-margin' : index]">
                            <PadelInput :placeholder="t(button)" :label="t(button)" :value="formValues[button]"
                                @input="updateFormValue($event, button)" :error="formErrors[button]"
                                @validate="validateSignUp(formValues, button, index, name)"
                                :inputType="button === 'password' || button === 'passwordConfirmation' ? 'password': 'text'" />
                        </div>
                    </template>
                </div>
                
            <div class="flexbox-column width-100">
                <p class="margin-top-10 barlow color-white align-initial">{{t('your_centre')}}</p>
                <div class="flexbox-row box">
                    <div class="input-container">
                        <PadelInput :placeholder="t('clubName')" :label="t('clubName')" :value="formValues['clubName']"
                            @input="updateFormValue($event, 'clubName')"
                            @validate="validateSignUp(formValues, 'clubName')" :error="formErrors['clubName']"
                            :inputType="'text'" />
                    </div>
                    <div class="input-container add-margin">
                        <PadelInput :value="formValues['clubGeoLoc'].address" :googleAutocomplete="true"
                            :placeholder="t('address')" :label="t('address')" @validate="onAutocomplete($event)"
                            :error="formErrors['clubGeoLoc']" :inputType="'text'" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="buttons-container display-flex justify-space-between width-100">
        <p class="font-12 color-blue cursor-pointer bold-text" @click="redirect('login')">{{uppercase(t('back'))}}</p>
        <PadelButton @click="checkSignUp(formValues)" :label="uppercase(t('forward'))"
            :disabled="isFormEmpty(formValues) || !isFormValid(formErrors)">
        </PadelButton>
    </div>
</div>
<div class="g-recaptcha" id="g-recaptcha" :data-sitekey="captchaSiteKey"
    data-callback="onSubmitCaptcha" data-size="invisible">
</div>