<template>
  <div class="loading">
    <div class="loader-container">
      <div class="loader">
        <img src="@/theme/imgs/pallina_empty.svg" alt="" />
      </div>
      <span class="shadow"></span>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
export default defineComponent({
    name: 'Loader'
});
</script>

<style lang="scss">
@import '@/theme/fonts.styles.scss';
@import '@/theme/palette.styles.scss';

.loading {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  display: block;
  opacity: 0.5;
  background-color: black;
  z-index: 10000000000;
  text-align: center;
}

.loader-container {
  position: absolute;
  top: 50%;
  margin-left: -5rem;
  margin-top: -5rem;
  left: 50%;
  width: 120px;
}

.loader {
  position: relative;
  z-index: 2;
  width: 120px;
  -webkit-animation: bounce 0.5s cubic-bezier(0.5, 0.05, 1, 0.5) infinite; /* Safari */
  animation: bounce 0.5s cubic-bezier(0.5, 0.05, 1, 0.5) infinite;
  animation-direction: alternate;
}

/* Safari */
@-webkit-keyframes bounce {
  from {
    -webkit-transform: translate3d(0, -40px, 0);
  }
  to {
    -webkit-transform: translate3d(0, 30px, 0);
  }
}

@keyframes bounce {
  from {
    transform: translate3d(0, -40px, 0);
  }
  to {
    transform: translate3d(0, 30px, 0);
  }
}

.shadow {
  background-color: map-get($colors, disabled-text);
  border-radius: 50%;
  height: 20px;
  width: 50px;
  display: inline-block;
  -webkit-animation: shadow 0.5s cubic-bezier(0.5, 0.05, 1, 0.5) infinite; /* Safari */
  animation: shadow 0.5s cubic-bezier(0.5, 0.05, 1, 0.5) infinite;
  animation-direction: alternate;
}

/* Safari */
@-webkit-keyframes shadow {
  from {
    -webkit-transform: scale(0.5);
  }
  to {
    -webkit-transform: scale(1);
  }
}

@keyframes shadow {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}
</style>
