<PadelLateralTab />
<div class="view-main-container">

    <div class="inner-view-container">

        <div class="header flexbox-row padding-top-40 padding-bottom-40">
            <div class="cursor-pointer flexbox-row align-center"
                @click="router.replace({ name: 'MyClub', query: { tab: 'price-rules' } })">
                <i class="material-icons color-blue cursor-pointer margin-right-10">keyboard_arrow_left</i>
                <div class="go-back-text color-blue">{{uppercase(t('abort'))}}</div>
            </div>
        </div>

        <div class="p-grid padding-bottom-20">
            <div class="p-col-12 p-text-left">
                <h3 class="color-gold barlow margin-bottom-0">{{t('new_rule')}}</h3>
                <div class="barlow color-white margin-top-5">{{t('insert new rule data')}}</div>
            </div>
        </div>

        <div class="p-grid p-mb-6">
            <div class="p-col-12 p-md-6">
                <span class="form-label text-white">{{ t('rule') }}</span>
                <div style="margin-top: 8px;">
                    <PadelInput :label="t('rule_name')" @input="updateFormValue($event, 'name')"
                        :value="addPriceRuleForm.name" />
                </div>
            </div>
            <div class="p-col-12 p-md-6">
                <div class="form-row flexbox-row justify-space-between">
                    <div class="flexbox-column">
                        <span class="form-label text-white">{{ t('start_date') }}</span>
                        <div class="form-row flexbox-row justify-space-between">
                            <div class="dropdown-container">
                                <PadelDropdown class="dropdown" :label="t('month')" :options="MONTHS"
                                    @input="updateFormValue($event.value, 'start_month', true)"
                                    :error="addPriceRuleFormErrors.start_at ? t(addPriceRuleFormErrors.start_at) : ''"
                                    :default="startMonth" />
                            </div>
                            <div class="dropdown-container">
                                <PadelDropdown class="dropdown" :label="t('day')" :options="startOptions"
                                    @input="updateFormValue($event.value, 'start_day', true)"
                                    :error="addPriceRuleFormErrors.start_at ? t(addPriceRuleFormErrors.start_at) : ''"
                                    :default="startDay" />
                            </div>
                        </div>
                    </div>

                    <div class="flexbox-column">
                        <span class="form-label text-white">{{ t('end_date') }}</span>
                        <div class="form-row flexbox-row justify-space-between">
                            <PadelDropdown class="dropdown" :label="t('month')" :options="MONTHS"
                                @input="updateFormValue($event.value, 'end_month', true)"
                                :error="addPriceRuleFormErrors.end_at ? t(addPriceRuleFormErrors.end_at) : ''"
                                :default="endMonth" />
                            <PadelDropdown class="dropdown" :label="t('day')" :options="endOptions"
                                @input="updateFormValue($event.value, 'end_day', true)"
                                :error="addPriceRuleFormErrors.end_at ? t(addPriceRuleFormErrors.end_at) : ''"
                                :default="endDay" />
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12 p-md-6">
                <span class="form-label text-white">{{ t('playgrounds') }}</span>
                <div class="p-grid">
                    <div class="p-col-6">
                        <PadelDropdown class="dropdown" :label="t('sport')" :options="mySports"
                            @input="updateFormValue($event.value, 'sport_type', true)"
                            :error="addPriceRuleFormErrors.sport_type ? t(addPriceRuleFormErrors.sport_type) : ''"
                            :default="addPriceRuleForm.sport_type" />
                    </div>
                    <div class="p-col-6">
                        <MultiSelect v-model="addPriceRuleForm.playground_ids" :options="PlaygroundsOptions"
                            optionLabel="label" optionValue="value" :placeholder="t('select_playground')"
                            :filter="false" class="multiselect-custom" />
                    </div>
                </div>
            </div>
        </div>

        <div class="p-grid p-mb-6">
            <div class="p-col-12">
                <span class="form-label text-white">
                    {{ t('days_of_validity') }}
                    <Button :label="t('with_all_days')" class="p-button-link p-py-0 p-text-bold no-underline"
                        @click="selectAllDays()" />
                </span>
                <div class="p-grid">
                    <div class="p-col p-d-flex p-flex-row p-align-center">
                        <PadelCheckbox v-model:checked="DaysOptions.monday" />
                        <i class="icon icon-locker color-gold margin-left-10 margin-right-10"></i>
                        <p class="color-white montserrat title-description cursor-pointer"
                            @click="DaysOptions.monday = !DaysOptions.monday">
                            {{(t('monday_short'))}}
                        </p>
                    </div>
                    <div class="p-col p-d-flex p-flex-row p-align-center">
                        <PadelCheckbox v-model:checked="DaysOptions.tuesday" />
                        <i class="icon icon-locker color-gold margin-left-10 margin-right-10"></i>
                        <p class="color-white montserrat title-description cursor-pointer"
                            @click="DaysOptions.tuesday = !DaysOptions.tuesday">
                            {{(t('tuesday_short'))}}
                        </p>
                    </div>
                    <div class="p-col p-d-flex p-flex-row p-align-center">
                        <PadelCheckbox v-model:checked="DaysOptions.wednesday" />
                        <i class="icon icon-locker color-gold margin-left-10 margin-right-10"></i>
                        <p class="color-white montserrat title-description cursor-pointer"
                            @click="DaysOptions.wednesday = !DaysOptions.wednesday">
                            {{(t('wednesday_short'))}}
                        </p>
                    </div>
                    <div class="p-col p-d-flex p-flex-row p-align-center">
                        <PadelCheckbox v-model:checked="DaysOptions.thursday" />
                        <i class="icon icon-locker color-gold margin-left-10 margin-right-10"></i>
                        <p class="color-white montserrat title-description cursor-pointer"
                            @click="DaysOptions.thursday = !DaysOptions.thursday">
                            {{(t('thursday_short'))}}
                        </p>
                    </div>
                    <div class="p-col p-d-flex p-flex-row p-align-center">
                        <PadelCheckbox v-model:checked="DaysOptions.friday" />
                        <i class="icon icon-locker color-gold margin-left-10 margin-right-10"></i>
                        <p class="color-white montserrat title-description cursor-pointer"
                            @click="DaysOptions.friday = !DaysOptions.friday">
                            {{(t('friday_short'))}}
                        </p>
                    </div>
                    <div class="p-col p-d-flex p-flex-row p-align-center">
                        <PadelCheckbox v-model:checked="DaysOptions.saturday" />
                        <i class="icon icon-locker color-gold margin-left-10 margin-right-10"></i>
                        <p class="color-white montserrat title-description cursor-pointer"
                            @click="DaysOptions.saturday = !DaysOptions.saturday">
                            {{(t('saturday_short'))}}
                        </p>
                    </div>
                    <div class="p-col p-d-flex p-flex-row p-align-center">
                        <PadelCheckbox v-model:checked="DaysOptions.sunday" />
                        <i class="icon icon-locker color-gold margin-left-10 margin-right-10"></i>
                        <p class="color-white montserrat title-description cursor-pointer"
                            @click="DaysOptions.sunday = !DaysOptions.sunday">
                            {{(t('sunday_short'))}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="p-col-12">
                <hr />
            </div>
        </div>

        <div class="p-grid p-mb-3">
            <div class="p-col-12">
                <span class="form-label text-white">{{ t('select hour slots when rule is valid') }}</span>

                <div class="p-grid p-mt-3">

                    <div class="slot-row flexbox-row justify-space-between"
                        v-for="(item, index) in addPriceRuleForm.slots" :key="`${item.start_at}_${index}`"
                        :data-k="`${item.start_at}_${index}`">

                        <div class="form-row flexbox-row justify-space-between p-pt-3">
                            <PadelTimepicker :label="t('from')"
                                @input="updateSlotValue(index, $event, 'start_at', true)" :from="openingHour"
                                :to="closingHour" :value="addPriceRuleForm.slots[index].start_at"
                                :error="addPriceRuleFormErrors.slots[index].start_at ? t(addPriceRuleFormErrors.slots[index].start_at) : ''" />
                            <PadelTimepicker :label="t('to')" @input="updateSlotValue(index, $event, 'end_at', true)"
                                :from="openingHour" :to="closingHour" :value="addPriceRuleForm.slots[index].end_at"
                                :error="addPriceRuleFormErrors.slots[index].end_at ? t(addPriceRuleFormErrors.slots[index].end_at) : ''" />
                        </div>

                        <div>
                            <label class="small">{{ t('prenotation_duration') }}</label>
                            <div class="p-d-flex p-justify-start">
                                <ToggleButton v-model="addPriceRuleForm.slots[index].duration_60" onLabel="60min"
                                    offLabel="60min" v-if="addPriceRuleForm.slots[index].show_duration_60" />
                                <ToggleButton v-model="addPriceRuleForm.slots[index].duration_90" onLabel="90min"
                                    offLabel="90min" v-if="addPriceRuleForm.slots[index].show_duration_90" />
                                <ToggleButton v-model="addPriceRuleForm.slots[index].duration_120" onLabel="120min"
                                    offLabel="120min" v-if="addPriceRuleForm.slots[index].show_duration_120" />
                            </div>
                        </div>

                        <div>
                            <label class="small">{{ t('slot_prices') }}</label>
                            <div class="time-row flexbox-row justify-space-between">
                                <div>
                                    <PadelInput :label="t('minutes', { time: '60' })"
                                        @input="updateSlotValue(index, $event, 'price_60', true)" :inputType="'number'"
                                        :icon="'euro_symbol'" :isPrice="true"
                                        v-if="addPriceRuleForm.slots[index].duration_60"
                                        :value="addPriceRuleForm.slots[index].price_60"
                                        :error="addPriceRuleFormErrors.slots[index].price_60 ? t(addPriceRuleFormErrors.slots[index].price_60) : ''" />
                                </div>
                                <div>
                                    <PadelInput :label="t('minutes', { time: '90' })"
                                        @input="updateSlotValue(index, $event, 'price_90', true)" :inputType="'number'"
                                        :icon="'euro_symbol'" :isPrice="true"
                                        v-if="addPriceRuleForm.slots[index].duration_90"
                                        :value="addPriceRuleForm.slots[index].price_90"
                                        :error="addPriceRuleFormErrors.slots[index].price_90 ? t(addPriceRuleFormErrors.slots[index].price_90) : ''" />
                                </div>
                                <div>
                                    <PadelInput :label="t('minutes', { time: '120' })"
                                        @input="updateSlotValue(index, $event, 'price_120', true)" :inputType="'number'"
                                        :icon="'euro_symbol'" :isPrice="true"
                                        v-if="addPriceRuleForm.slots[index].duration_120"
                                        :value="addPriceRuleForm.slots[index].price_120"
                                        :error="addPriceRuleFormErrors.slots[index].price_120 ? t(addPriceRuleFormErrors.slots[index].price_120) : ''" />
                                </div>
                            </div>
                        </div>

                        <div style="width: 50px;">
                            <span class="icon-delete material-icons color-blue cursor-pointer p-mt-4"
                                @click="removeSlot(index)">delete</span>
                        </div>

                    </div>

                    <div class="p-col-12 p-text-center p-mt-6">
                        <div class="scheduler-button new-event-button cursor-pointer display-flex margin-top-20 p-justify-center"
                            @click="addSlot">
                            <p class="scheduler-button-text color-blue">{{uppercase(t('new_slot'))}}</p>
                            <i class="pi pi-plus color-blue"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-d-flex p-jc-end p-mt-6">
            <PadelButton :label="uppercase(ruleId ? t('edit_rule') : t('create_new_rule'))"
                :disabled="isFormPriceRuleEmpty(addPriceRuleForm, DaysOptions) && isFormPriceRuleValid(addPriceRuleFormErrors)"
                @click="save()" />
        </div>

    </div>

</div>

<div v-if="showConflictDialog">
    <DumbModal :title="t('config_overlap')" @hide="hideModal()">
        <div class="modal-content p-text-center p-p-6">
            <i class="pi pi-exclamation-triangle color-warning" style="font-size: 2rem"></i>
            <p class="p-text-center color-white" v-html="t('config_overlap_body')"></p>
        </div>

        <template #footer>
            <div class="p-d-flex p-jc-end">
                <PadelButton :label="t('got_it')" @click="hideModal()" />
            </div>
        </template>
    </DumbModal>
</div>