
import { defineComponent } from "vue";
import Compress from "compress.js";
export default defineComponent({
  name: "PadelUpload",
  data() {
    return { file: null };
  },
  methods: {
    //EMETTO UN OGGETTO COL CAMPO NOME E BASE64
    onFileSelected(event) {
      const acceptedTypes = (this.accept as string).split(",");
      const file: File = event.target.files[0];
      const compress = new Compress();
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        if (acceptedTypes.indexOf(file.type) > -1) {
          //comprimo il file a un massimo di 512Kb
          if (this.accept === "image/png,image/jpeg") {
            this.file = file;
            compress
              .compress([file], {
                size: this.maxFileSize,
              })
              .then((data) => {
                this.$emit("change", {
                  name: this.file.name,
                  src: data[0].prefix + data[0].data,
                });
              });
          } else {
            const fileSize = file.size / 1024 / 1024;
            if (fileSize < 7) {
              this.file = file;
              this.$emit("change", {
                name: this.file.name,
                src: base64String,
              });
            } else {
              this.$emit("file-too-heavy", {
                name: file.name,
                src: base64String,
              });
            }
          }
        } else {
          this.$emit("wrong-file-uploaded", {
            name: file.name,
            src: base64String,
          });
        }
      };
      reader.readAsDataURL(file);
    },
  },
  computed: {
    file__uploaded() {
      if (this.file) {
        return "file__loaded ";
      }
      return "";
    },
    has__border() {
      if (this.border) {
        return "border";
      } else {
        return "";
      }
    },
    show() {
      if (this.icon) {
        return "";
      } else {
        return "show-none";
      }
    },
  },
  props: {
    maxFileSize: { type: Number, required: false, default: 0.512 },
    border: { type: Boolean, required: false, default: false },
    icon: { type: String, required: false, default: "" },
    label: { type: String, required: false, default: "" },
    uploadedLabel: { type: String, required: false, default: "" },
    accept: { type: String, required: false, default: "image/png,image/jpeg" },
  },
  emits: ["change", "wrong-file-uploaded", "file-too-heavy"],
});
