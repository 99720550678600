import { 
  mockedCurrentSession, 
  mockedUser, 
  mockedSignIn, 
  mockedUserInfo, 
  mockedRequests, 
  mockedChangeRequestStatus, 
  mockedClubsCreation, 
  mockedClubCreation, 
  mockedGetPlaygrounds, 
  mockedTimeslots, 
  mockedUsers, 
  mockedStatistics, 
  mockedClubList, 
  mockedClubDetail, 
  mockedTeamsList, 
  mockedTournamentsList, 
  mockedCreateTournament, 
  mockedRulesList, 
} from '@/api/mock/data/user'
import { mockedError } from './data/error'
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { UserFilters } from '@/models/filters.models';
import { CreateTournamentRequest, ModifyTournamentRequest } from '@/models/tournaments.models';


const waitTime = 1000;

const fetch = (mockData: any, time = 0) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockData)
    }, time)
  })
}

const throwErr = async (time: number, errorPayload?) => {
  await new Promise(resolve => setTimeout(resolve, time))
  throw errorPayload ? errorPayload : mockedError;
}

/* PER LANCIARE ERRORE COMMENTARE/DECOMMENTARE LE RIGHE */
export async function signIn(obj: any): Promise<CognitoUser | any> {
  console.log('mock!!')
  /* await throwErr(waitTime) */
  return await fetch(mockedSignIn, waitTime);
}

export async function currentAuthenticatedUser(): Promise<CognitoUser | any> {
  console.log('mock!!')
  /* await throwErr(waitTime) */
  return await fetch(mockedSignIn, waitTime);
}

export async function currentSession(obj: any): Promise<CognitoUserSession | any> {
  console.log('mock!!')
  /* await throwErr(waitTime) */
  return await fetch(mockedCurrentSession, waitTime)
}

export async function signUp(obj: any): Promise<any> {
  console.log('mock!!')
  /* await throwErr(waitTime) */
  return await fetch(mockedUser, waitTime)
}

export async function confirmSignUp(username: any, code: any): Promise<any> {
  console.log('mock!!')
  /* await throwErr(waitTime) */
  return await fetch(mockedUser, waitTime)
}

export async function signOut(): Promise<any> {
  console.log('mock!!')
  /* await throwErr(waitTime) */
  return await fetch(mockedUser, waitTime)
}

export async function resendSignUp(): Promise<any> {
  console.log('mock!!')
  /* await throwErr(waitTime) */
  return await fetch(mockedUser, waitTime)
}

export async function forgotPassword(username: string): Promise<any> {
  console.log('mock!!')
  /* await throwErr(waitTime) */
  return await fetch(mockedUser, waitTime)
}

export async function forgotPasswordSubmit(username: string, code: string, new_password: string): Promise<any> {
  console.log('mock!!')
  /* await throwErr(waitTime) */
  return await fetch(mockedUser, waitTime)
}

export async function completeNewPassword(user: CognitoUser, newPassword: string, requiredAttributes: object): Promise<any> {
  console.log('mock!!')
  /* await throwErr(waitTime) */
  return await fetch(mockedUser, waitTime)
}

export async function myUserInfo(): Promise<any> {
  console.log('mock!!')
  /*  return await throwErr(waitTime) */
  return await fetch(mockedUser, waitTime)
}

export async function clubRequests(): Promise<any> {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedRequests, waitTime)
}

export async function getUserInfo(): Promise<any> {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedUserInfo, waitTime)
}

export async function changeRequestStatus(): Promise<any> {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedChangeRequestStatus, waitTime)
}

export async function myClubs(): Promise<any> {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedClubsCreation, waitTime)
}

export async function changeClubInfo(idToken: string, club_id: string, payload): Promise<any> {
  console.log('mock!!')
  /* return await throwErr(waitTime, mockedClubCreation) */
  return await fetch(mockedClubCreation, waitTime)
}

export async function addPlayground(idToken: string, club_id: string, payload): Promise<any> {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedClubCreation, waitTime)
}

export async function updateUserAttributes(user: CognitoUser, payload): Promise<any> {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedClubCreation, waitTime)
}

export async function verifyUserAttribute(user: CognitoUser, payload): Promise<any> {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedClubCreation, waitTime)
}

export async function verifySmsCode(idToken: string, payload): Promise<any> {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedClubCreation, waitTime)
}

export async function addNewClub(idToken: string, payload): Promise<any> {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedClubCreation, waitTime)
}

export async function getPlaygrounds(): Promise<any> {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedGetPlaygrounds, waitTime);
}

export async function deletePlayground(idToken: string, club_id: string,
  playground_id: string): Promise<any> {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedGetPlaygrounds, waitTime);
}

export async function changePlaygroundInfo(idToken: string, club_id: string,
  playground_id: string, payload): Promise<any> {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedGetPlaygrounds, waitTime);
}

export async function getCalendarEvents(idToken: string, payload): Promise<any> {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedTimeslots, waitTime);
}

export async function getUsers(idToken: string, club_id: string, filters?: UserFilters): Promise<any> {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedUsers, waitTime);
}

export async function banUser(idToken: string, club_id: string, user_id: string): Promise<any> {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(true, waitTime);
}

export async function unbanUser(idToken: string, club_id: string, user_id: string): Promise<any> {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(true, waitTime);
}

export async function getClubStats(idToken: string, club_id: string, start_date: string) {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedStatistics, waitTime);
}

export async function addBooking(idToken: string, booking) {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(true, waitTime);
}

export async function clubSearch(idToken: string, nameFilter: string) {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedClubList, waitTime);
}

export async function clubDetail(idToken: string, club_id: string) {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedClubDetail, waitTime);
}

export async function enableClub(idToken: string, club_id: string) {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(true, waitTime);
}

export async function disableClub(idToken: string, club_id: string) {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(true, waitTime);
}

export async function sendOTP(idToken: string, phone_number: string) {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch({otp: "232345"}, waitTime);
}

export async function deleteTimeslot(idToken: string, booking_id: string) {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(true, waitTime);
}

export async function createTournament(idToken: string, club_id: string, tournament: CreateTournamentRequest) {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedCreateTournament, waitTime);
}

export async function modifyTournament(idToken: string, club_id: string, tournament_id: string, tournament: ModifyTournamentRequest) {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(true, waitTime);
}

export async function deleteTournament(idToken: string, club_id: string, tournament_id: string) {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch({}, waitTime);
}

export async function getTournaments(idToken: string, club_id: string) {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedTournamentsList, waitTime);
}

export async function getTournamentDetails(idToken: string, club_id: string, tournament_id: string) {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(true, waitTime);
}

export async function getTournamentTeams(idToken: string, club_id: string, tournament_id: string) {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedTeamsList, waitTime);
}

export async function deleteTeam(idToken: string, club_id: string, tournament_id: string, booker_id: string) {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(true, waitTime);
}

export async function getPricesRules(idToken: string, club_id: string) {
  console.log('mock!!')
  /* return await throwErr(waitTime) */
  return await fetch(mockedRulesList, waitTime);
}
