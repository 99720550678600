
import { useI18n } from "vue-i18n";
import { uppercase } from "@/services/mapping.service";
import { defineComponent, onMounted, ref, computed } from "vue";
import { emptyPlaygroundForm } from "@/constants/initial-values.constants";
import {
  sportOptions,
  playgroundsOptions,
  gameDurationOptions,
} from "@/constants/radio-options.constants";
import {
  SizesOptions,
  GroundBaseOptions,
  PlaygroundTypesOptions,
  VisibilityOptions,
  PlaygroundSports,
  DEFAULT_VALUES,
} from "@/constants/playground.enums";
import { showToast } from "@/services/error-management.service";
import { ToastErrors } from "@/constants/generic-errors.enum";
import { formPlaygroundEmpty } from "@/services/form-validation.service";

export default defineComponent({
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {
        return { ...emptyPlaygroundForm };
      },
    },
    isModifyModal: { type: Boolean, required: true, default: false },
    title: {type: String, required: true, default: 'Aggiunta campo'}
  },
  emits: ["hide", "formConfirmed"],
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const playgroundForm = ref(props.form);
    const showPreview = ref(false);
    const SportOptions = ref(sportOptions);

    function updateFormValue(value, field) {
      playgroundForm.value[field] = value;
      if (field === "sport") {
        const defaults = DEFAULT_VALUES[value];
        if (defaults) {
          for (const [key, value] of Object.entries(defaults)) {
            updateFormValue(value, key);
          }
        }
      }
    }

    onMounted(()=> {
      if(!props.isModifyModal) {
        console.log("isModalCreate");
        updateFormValue(PlaygroundSports.PADEL, 'sport');
      }
    });    

    function closePreview() {
      showPreview.value = false;
    }

    function openPreview() {
      showPreview.value = true;
    }

    function handleWrongFileUploaded(file) {
      showToast(ToastErrors.WRONG_FILE_FORMAT);
    }

    function hideModal() {
      emit("hide", true);
    }

    function savePlayground() {
      emit("formConfirmed", playgroundForm.value);
    }

    // --
    const hasPlaygroundSize = computed(function(): boolean {
      return [PlaygroundSports.PADEL, PlaygroundSports.TENNIS].includes(playgroundForm.value.sport);
    });
    const hasWalls = computed(function(): boolean {
      return [PlaygroundSports.PADEL, PlaygroundSports.PADEBALL].includes(playgroundForm.value.sport);
    });
    const GroundOptions = computed(function(): object[] {
      return GroundBaseOptions.filter(el => el.sports.includes(playgroundForm.value.sport));
    });

    return {
      t,
      locale,
      uppercase,
      SportOptions,
      playgroundsOptions,
      SizesOptions,
      GroundOptions,
      PlaygroundTypesOptions,
      VisibilityOptions,
      gameDurationOptions,
      playgroundForm,
      showPreview,

      hasPlaygroundSize,
      hasWalls,

      hideModal,
      updateFormValue,
      closePreview,
      openPreview,
      handleWrongFileUploaded,
      savePlayground,
      formPlaygroundEmpty,
    };
  },
});
