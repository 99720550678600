import { BookingType, CalendarOpenings } from "@/constants/booking.enum";
import { CognitoErrors } from "@/constants/cognito-errors.enum";
import { ClubCategoriesOptions, DEFAULT_GAME_DURATION, FIXED_PHONE_PREFIX, PlayerCategoriesOptions } from "@/constants/constants.constants";
import { emptyServices, emptyTimeslotInfo, emptyExceptionRuleForm, emptyPadelUser } from "@/constants/initial-values.constants";
import { calendarFilterOptions, END_OF_AFTERNOON, END_OF_MORNING, getSportByValue, sportOptions, matchTypesOptions } from "@/constants/radio-options.constants";
import { ChangeClubInfoRequest, PadelClub } from "@/models/club.models";
import { InverseLevelMap, LevelMap } from "@/constants/tournaments.enums";
import { CognitoError } from "@/models/error.models";
import { PlaygroundForm, SignupForm } from "@/models/forms.models";
import { AddPlaygroundRequest, CalendarSplit, PadelPlayground, SchedulerPlayground } from "@/models/playground.models";
import { BookingDetail, BookingError, BookingForm, CalendarTimeslot, TimeslotInfo } from "@/models/timeslots.models";
import { CreateTournamentForm, ModifyTournamentRequest, Tournament, TournamentDate, TournamentFormDate } from "@/models/tournaments.models";
import { BackEndUser, PadelUser, User } from "@/models/user.models";
import {
    convertCalendarDateFormatToHHMM,
    convertDateAndTimeToTimestamp,
    convertHHMMtoMillisecondsOfDay,
    convertMillisecondsToHHMM,
    convertMMDDToDDMM,
    formatDateDDMMYYYY,
    formatDateMMDDYYYY,
    formatStatsDate,
    getTomorrowTimestamp,
    handleCalendarEventHourMapping,
    mapBookingEndAt,
    transformMillisecondsToHHMM,
    durationIsHigherThan,
    mappedWeekDay,
} from "./dates.service";
import { RuleType } from "@/constants/rules.enum";
import moment from 'moment';
import { PlaygroundSports } from "@/constants/playground.enums";
import { PaymentState } from "@/constants/payment.enum";
import { CardCategories } from "@/constants/card-types.enum";
import { useStore } from "vuex";
import { ActionTypes } from "@/store/action-types";


export function mapUserInfo(user: BackEndUser): User {
    return {
        name: user.name,
        surname: user.family_name,
        email: user.email,
        phonenumber: user.phone_number,
        groups: user.groups,
        profile_pic_ref: user.profile_pic_ref
    }
}

export function mapLoginRequest(payload: SignupForm) {
    return {
        username: payload.email,
        password: payload.password,
        //        validationData: [{
        //            Name: 'recaptchaToken',
        //            Value: payload.captchaToken,
        //        }],
        validationData: {
            recaptchaToken: payload.captchaToken,
        },
        attributes: {
            email: payload.email,
            name: payload.name,
            family_name: payload.surname,
            'custom:signUpIntent': JSON.stringify({
                role: payload.role,
                clubName: payload.clubName,
                clubAddress: payload.clubGeoLoc
            })
        }
    }
}

export function mapFormError(error: string, t) {
    if (error) {
        if (error.indexOf("required field") > -1) {
            return t("required_field")
        } else if (error.indexOf("user_phone_number must be") > -1) {
            return t("required_field")
        } else if (error.indexOf("must match") > -1) {
            return t("password_not_valid")
        } else if (error.indexOf("must be a `number`") > -1) {
            return t("must_be_number")
        } else if (error.indexOf("valid email") > -1) {
            return t("valid_email_error")
        } else if (error.indexOf("must be at least") > -1) {
            return t("password_min_length")
        } else if (error.indexOf("Passwords do not match") > -1) {
            return t("passwords_dont_match")
        } else if (error.indexOf("Must be exactly 6 characters") > -1) {
            return t("code_length_error")
        } else if (error.indexOf("Numero non valido") > -1) {
            return t("number_not_valid")
        } else if (error.indexOf("internazionale") > -1 || error.indexOf("italiano") > -1) {
            return error
        }
        //TOURNAMENT ERRORS
        else if (error.indexOf("max_couples must be greater than 0") > -1) {
            return 'max_couples_must_be_positive'
        } else if (error.indexOf("min_couples must be less than") > -1) {
            return 'min_couples_must_be_less'
        } else if (error.indexOf("max_couples must be greater than or equal to") > -1) {
            return 'max_couples_must_be_greater'
        } else if (error.indexOf("max_level must be greater than or equal to") > -1) {
            return 'max_level_must_be_greater'
        } else if (error.indexOf("min_level must be less than or equal to") > -1) {
            return 'min_level_must_be_less'
        } else if (error.indexOf("Invalid Date") > -1) {
            return 'required_field'
        } else if (error.indexOf("registration_end_at field must be at earlier") > -1) {
            return 'registration_must_be_before_start'
        } else if (error.indexOf("end_at field must be later than") > -1) {
            return 'end_date_must_be_before_start_date'
        } else if (error.indexOf("start_at field must be at earlier") > -1) {
            return 'start_date_must_be_before_end_date'
        } else if (error.indexOf("cannot modify max couples under previous value") > -1) {
            return 'max_couples_cannot_be_decreased'
        } else if (error.indexOf("you must specify") > -1) {
            return 'required_field'
        } else {
            return t("error")
        }
    } else {
        return ''
    }
}

export function uppercase(_string: string) {
    return _string.toUpperCase();
}

export function upperCaseFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function mapInputError(error: string, t) {
    return mapFormError(error, t);
}

export function mapCognitoError(error: CognitoError, t) {
    if (error.code) {
        if (error.code === CognitoErrors.NOT_AUTHORIZED_EXCEPTION) {
            return t(CognitoErrors.NOT_AUTHORIZED_EXCEPTION) /* 'Username o password non corretti' */
        } else if (error.code === CognitoErrors.USER_ALREADY_EXISTS ||
            error.message.indexOf("PreSignUp failed with error User already registered with different method") > -1) {
            return t(CognitoErrors.USER_ALREADY_EXISTS)
        } else if (error.code === CognitoErrors.INVALID_PASSWORD) {
            return t(CognitoErrors.INVALID_PASSWORD)
        } else if (error.code === CognitoErrors.USER_NOT_CONFIRMED) {
            return t(CognitoErrors.USER_NOT_CONFIRMED)
        } else if (error.code === CognitoErrors.PASSWORD_RESET_REQUIRED) {
            return t(CognitoErrors.PASSWORD_RESET_REQUIRED)
        } else if (error.code === CognitoErrors.USER_NOT_FOUND) {
            return t(CognitoErrors.USER_NOT_FOUND)
        } else if (error.code === CognitoErrors.EXPIRED_CODE) {
            return t(CognitoErrors.EXPIRED_CODE)
        } else if (error.code === CognitoErrors.CODE_MISMATCH) {
            return t(CognitoErrors.CODE_MISMATCH)
        } else if (error.code === CognitoErrors.TOO_MANY_ATTEMPTS) {
            return t(CognitoErrors.TOO_MANY_ATTEMPTS)
        } else if (error.message.indexOf("Invalid reCAPTCHA token") > -1) {
            return t("are_you_robot")
        } else {
            return t("sorry_error")
        }
    } else {
        return t("generic_error")
    }
}

export function mapPaginatedPayload(payload) {
    const _provv: any = { ...payload }
    if (typeof (payload.last_evaluated_key) !== 'undefined') {
        _provv.last_evaluated_key = JSON.stringify(payload.last_evaluated_key);
    }
    return _provv;
}

export function convertServices(services): { type: string; customName?: string }[] {
    const _services = [];
    for (const prop in services) {
        if (Object.prototype.hasOwnProperty.call(services, prop)) {
            if (services[prop]) {
                if (prop === 'RESTAURANT' || prop === 'SWIMMING_POOL') {
                    _services.push({ type: 'CUSTOM', customName: prop });
                } else {
                    _services.push({ type: prop });
                }
            }
        }
    }
    return _services;
}

export function deconvertServices(servicesArray: { type: string; customName?: string }[]) {
    const provv = { ...emptyServices };
    servicesArray.forEach((val) => {
        let key = val.type;
        if (val.type === 'CUSTOM' && val.customName) {
            key = val.customName;
        }
        provv[key] = true;
    })
    return provv;
}

export function convertFormIntoPayload(addClubForm, services): ChangeClubInfoRequest {
    const payload = {
        phone_number: addClubForm.phoneNumber,
        email: addClubForm.email,
        opening_hour: convertHHMMtoMillisecondsOfDay(addClubForm.openTime),
        closing_hour: convertHHMMtoMillisecondsOfDay(addClubForm.closeTime),
        services: convertServices(services),
    };
    if (addClubForm.profilePicture) {
        payload["picture"] = addClubForm.profilePicture.src;
    }
    if (addClubForm.club_notes) {
        payload["club_notes"] = addClubForm.club_notes;
    }
    if (addClubForm.player_categories && (addClubForm.player_categories || []).length > 0) {
        payload["player_categories"] = addClubForm.player_categories;
    }
    if (addClubForm.forewarning) {
        payload["forewarning"] = addClubForm.forewarning;
    }
    return payload;
}

export function mapPlaygroundToForm(data: PadelPlayground): PlaygroundForm {
    return {
        id: data.id,
        name: data.name,
        playground_size: data.playground_size,
        sport: data.sport,
        exposure: data.exposure,
        hidden: data.hidden,
        picture_ref: data.picture_ref,
        sort_index: data.sort_index,
        ground: data.ground,
        walls: data.walls,
    }
}

export function numberToCents(price: number) {
    const strPrice = String(price);
    const vett = strPrice.split(/[.,]/);
    return Number(vett[0] + (vett[1] || '00').padEnd(2, '0').slice(0, 2));
}

export function transformPriceIntoHalfHour(price: number, divisionBase: number) {
    const formattedNumber = numberToCents(price);
    return formattedNumber / divisionBase;
}

export function convertPlaygroundFormIntoPayload(ground): AddPlaygroundRequest {
    const request: AddPlaygroundRequest = {
        name: ground.name,
        price_for_duration: [{
            duration: 60,
            price: Number(String(ground.price).replace(/[.,]/, '') + '0') //VALORE IN CENTESIMI
        }],
        playground_size: ground.playground_size,
        sport: ground.sport,
        exposure: ground.exposure,
        half_hour_price: transformPriceIntoHalfHour(ground.price, 2),
        hidden: ground.hidden,
        sort_index: ground.sort_index,
        ground: ground.ground,
        walls: ground.walls,
    }
    if (ground.profilePicture) {
        request["picture"] = ground.profilePicture.src;
    }
    return request;
}

export function refactorPlaygrounds(plArray: PadelPlayground[]): SchedulerPlayground[] {
    const _provv: SchedulerPlayground[] = [];
    plArray.forEach((value) => {
        _provv.push({
            name: value.name,
            id: value.id,
            exposure: value.exposure,
            playground_size: value.playground_size,
            playground_image: value.picture_ref,
            half_hour_price: value.half_hour_price,
            split: null,
            sport: value.sport,
            ground: value.ground,
            walls: value.walls,
            hidden: value.hidden,
        })
    })
    return _provv;
}

export function constructPlaygroundMap(plArray: PadelPlayground[]) {
    const _provv = {};
    plArray.forEach((value) => {
        _provv[value.id] = value.name;
    });
    return _provv;
}

export function constructPlaygroundMapFull(plArray: PadelPlayground[]) {
    const _provv = {};
    plArray.forEach((value) => {
        _provv[value.id] = value;
    });
    return _provv;
}

export function transformPlaygroundsToOptions(pl, allowedSports = null) {
    const _provv = [];
    pl.forEach((val) => {
        if (allowedSports) {
            if (allowedSports.includes(val.sport)) {
                _provv.push({
                    value: val,
                    label: val.name,
                    sport: val.sport,
                })
            }
        } else {
            _provv.push({
                value: val,
                label: val.name,
                sport: val.sport,
            })
        }
    })
    return _provv;
}

export function mapTimeslotTypeToClass(type: string): string {
    return type; //TODO: DA IMPLEMENTARE IN CASO CI SIANO MAPPATURE DA FARE
}

export function returnClass(value: TimeslotInfo) {
    if (value.type === BookingType.OPEN_BOOKING || value.type === BookingType.BACKOFFICE_OPEN_BOOKING) {
        return `${value.type} custom-open-booking ${value.booking_state === 'OPEN' ? 'playground-open' : 'playground-confirmed'} created-event`;
    } else if (value.color) {
        return `${value.type} ${value.color} created-event`;
    } else {
        return `${value.type} default created-event`;
    }
}

export function refactorTitle(event: TimeslotInfo, t) {
    // INIT
    let title = "";
    let players = 0;
    const option = matchTypesOptions.find((el: any) => el.value === event.match_type)
    const participants = (option ? (option as any).participants : 1)

    // BUNDLE
    if (event.bundle_id) {
        if (event.type === BookingType.BACKOFFICE_EVENT) {
            title += `<img src="/images/ricorrente.png" style="vertical-align: middle; height: 16px; margin-right: 8px;">${t('event')}<br>`;
        } else {
            title += `<img src="/images/ricorrente.png" style="vertical-align: middle; height: 16px; margin-right: 8px;">${t('bundle')}<br>`;
        }
    } else {
        if (event.type === BookingType.BACKOFFICE_EVENT) {
            title += `${t('event')}<br>`;
        }
    }
    switch (event.type) {
        case BookingType.BOOKING:
            title += `${event.player_name} ${event.player_family_name}`;
            break;
        case BookingType.BACKOFFICE_BOOKING:
            if (event.player_description) title += `${event.player_description}`;
            else if (event.player_name && event.player_family_name) title += `${event.player_name} ${event.player_family_name}`;
            break;
        case BookingType.LESSON:
            if (durationIsHigherThan(event, 31)) {
                title += t('lesson_with', { lesson: t('LESSON'), name: event.teacher_name });
            }
            if (event.player_name && event.player_family_name) title += `<br>${event.player_name} ${event.player_family_name}`;
            break;
        case BookingType.BACKOFFICE_LESSON:
            if (durationIsHigherThan(event, 31)) {
                title += t('lesson_with', { lesson: t('LESSON'), name: event.teacher_name });
                console.log("t1", title);
            }
            if (event.player_description) title += `<br>${event.player_description}`;
            else if (event.player_name && event.player_family_name) title += `<br>${event.player_name} ${event.player_family_name}`;
            console.log("t2", title);
            break;
        case BookingType.MAINTENANCE:
            title += `${t('MAINTENANCE')}`;
            break;
        case BookingType.CLOSING:
            title += `${t('CLOSING')}`;
            break;
        case BookingType.OTHER:
            title += `${t('OTHER')}`;
            break;
        case BookingType.TOURNAMENT:
            title += `${t('TOURNAMENT')} - ${event.tournament_name}`;
            break;
        case BookingType.OPEN_BOOKING:
        case BookingType.BACKOFFICE_OPEN_BOOKING:
            // --
            if (event.wesmash_user_id) players++;
            if (event.wesmash_user2_id) players++;
            if (event.wesmash_user3_id) players++;
            if (event.wesmash_user4_id) players++;

            title += `<span class="icon material-icons-outlined" style="font-size: 21px; vertical-align: middle; margin-right: 8px;">sports_tennis</span>`;
            title += `${t('OPEN_BOOKING_' + (players === participants ? 'CONFIRMED' : 'OPEN'))}<br>${t('players_with_number_short', { num: `${players}/${participants}` })} - ${t('OPEN_BOOKING_' + event.booking_state + '_PLAYGROUND')}`;
            break;
        default:
            break;
    }
    return title;
}

export function refactorEvents(plArray: TimeslotInfo[], myClub: PadelClub, t, playgrounds: PadelPlayground[]): CalendarTimeslot[] {
    const _provv = [];
    plArray.forEach((value) => {
        const playground = playgrounds.find(el => el.id === value.playground_id);
        const sportKey = (playground || {}).sport;
        const sport = getSportByValue(sportKey);

        // CONTENT
        let title = refactorTitle(value, t) + `<div class="vuecal__notes">${value.description ? value.description : ''}</div>`;
        if (![BookingType.TOURNAMENT, BookingType.CLOSING, BookingType.MAINTENANCE, BookingType.OTHER].includes(value.type as BookingType)) {
            title = title + `<div class="vuecal__sport">${sport}</div>`;
        }

        // PUSH
        _provv.push({
            id: value.id,
            playground_id: value.playground_id,
            start: handleCalendarEventHourMapping(value.start_at, myClub.opening_hour, value.type),
            end: handleCalendarEventHourMapping(value.end_at, myClub.closing_hour, value.type),
            title: title,
            content: value.payment_state ? `${t(value.payment_state)}` : "",
            class: returnClass(value),
            split: null,
            deletable: value.type === BookingType.TOURNAMENT ? false : true,
            timeslotInfo: { ...value, playground_name: null },
            sport: sportKey,
            bundle_id: value.bundle_id,
            payment_online: value.payment_choice != null && value.payment_choice == 'ONLINE',
            transaction_id: value.transaction_id,
            nexi_transaction_id: value.nexi_transaction_id,
            transaction_date: value.transaction_date != null ? Date.parse(value.transaction_date) : null,
        })
    })

    console.log(_provv);

    return _provv;
}

export function mapCalendarFormToFilters(form: { type: string; value: any }[]) {
    const _provv = [];
    form.forEach((val) => {
        if (val.value != null) {
            _provv.push(val);
        }
    });
    return _provv;
}

export function removedFilterArray(array, filter) {
    const provv = [...array]
    array.forEach((el, index) => {
        if (el.type === filter.type) {
            provv.splice(index, 1);
        }
    });
    return provv;
}

export function filteredPlaygrounds(
    playgrounds: PadelPlayground[],
    playground?: SchedulerPlayground,
    sport?: any,
    visibility?: any
) {
    let filtered = JSON.parse(JSON.stringify(playgrounds));
    if (playground) {
        filtered = filtered.filter(el => el.id == playground.id);
    }
    if (sport) {
        filtered = filtered.filter(el => el.sport == sport);
    }
    if (visibility === true || visibility === false) {
        filtered = filtered.filter(el => !!el.hidden === visibility);
    }
    return filtered;
}

export function filteredEvents(
    events: CalendarTimeslot[],
    playgrounds: PadelPlayground[],
    playground?: SchedulerPlayground,
    sport?: any,
    visibility?: any
) {
    let filtered = JSON.parse(JSON.stringify(events));
    if (playground) {
        filtered = filtered.filter(el => el.playground_id === playground.id);
    }
    if (sport) {
        filtered = filtered.filter(el => el.sport === sport);
    }
    if (visibility === true || visibility === false) {
        filtered = filtered.filter(el => {
            const pl = playgrounds.find(pg => pg.id === el.playground_id);
            return !!pl.hidden === visibility;
        });
    }
    return filtered;
}

export function filteredSplits(
    splits: { id: string; playground: string }[],
    playgrounds: PadelPlayground[],
    playground?: SchedulerPlayground,
    sport?: any,
    visibility?: any
): CalendarSplit[] {
    const provv = []
    splits.forEach((el) => {
        const pl = playgrounds.find(pp => pp.id === el.playground);
        if (playground) {
            if (el.playground === playground.id) {
                provv.push(el);
            }
        } else if (sport) {
            // silence is golden
        } else if (visibility === true || visibility === false) {
            if (!!pl.hidden === visibility) {
                provv.push(el);
            }
        }
    });
    return provv;
}

export function filteredTimeFrom(time, filter) {
    if (filter === CalendarOpenings.MORNING) {
        return time;
    }
    else if (filter === CalendarOpenings.AFTERNOON) {
        return END_OF_MORNING / 1000 / 60 < time ? time : END_OF_MORNING / 1000 / 60;
    } else {
        return END_OF_AFTERNOON / 1000 / 60 < time ? time : END_OF_AFTERNOON / 1000 / 60;
    }
}

export function filteredTimeTo(time, filter) {
    if (filter === CalendarOpenings.MORNING) {
        return END_OF_MORNING / 1000 / 60 > time ? time : END_OF_MORNING / 1000 / 60;
    }
    else if (filter === CalendarOpenings.AFTERNOON) {
        return END_OF_AFTERNOON / 1000 / 60 > time ? time : END_OF_AFTERNOON / 1000 / 60;
    } else {
        return time
    }
}

export function transformCalendarFilterOptions(opening_hour: number, closing_hour: number) {
    const _provv = [...calendarFilterOptions];
    _provv.forEach((opt) => {
        if (opt.options.start >= closing_hour || opt.options.end <= opening_hour) {
            opt.disabled = true;
        }
    });
    return _provv;
}

export function isFilterActive(filters, type: string) {
    return filters.filter(e => e.type === type).length > 0;
}

export function returnSearchUserDropdownChoices(array: PadelUser[]) {
    const _provv = [];
    array.forEach((val: PadelUser) => {
        _provv.push({
            label: `${val.name} ${val.family_name} - ${val.email}`,
            value: val
        })
    })
    return _provv;
}

export function mapDetailToNewBookingModel(detailForm: BookingDetail) {
    detailForm;
}

export function formatItalianPhone(phone) {
    return FIXED_PHONE_PREFIX + phone;
}

export function formatPhoneWithPrefix(prefix, phone) {
    if (!prefix.startsWith("+")) prefix = `+${prefix}`;
    return prefix + phone;
}

export function mapNewBookingPayload(_club_id: string, bookingType: string, form: BookingForm, withEndTime = false) {
    const payload = {
        club_id: _club_id,
        type: bookingType.replace("_DESCRIPTION", ""),
        apply_all_playground: form.apply_all_playground,
        taking: form.taking ? numberToCents(form.taking) : null,
    }

    const d = moment(form.date);
    const startAt = moment(`${d.format('YYYY-MM-DD')} ${form.start_at}`);
    let endAt = null;
    if (withEndTime) {
        endAt = moment(`${d.format('YYYY-MM-DD')} ${form.end_at}`);
    } else {
        endAt = moment(`${d.format('YYYY-MM-DD')} ${form.start_at}`);
        endAt.milliseconds(
            endAt.milliseconds() + (form.game_duration * 60 * 1000)
        );
    }

    if (bookingType === BookingType.BOOKING) {
        payload["playground_id"] = form.playground.id;
        payload["playground_name"] = form.playground.name;
        payload["wesmash_user_id"] = form.wesmash_user.id;
        payload["player_name"] = form.wesmash_user.name;
        payload["player_family_name"] = form.wesmash_user.family_name;
        payload["player_phone_number"] = form.wesmash_user.phone_number;
        payload["player_email"] = form.wesmash_user.email;
        payload["date"] = formatDateMMDDYYYY(form.date);
        payload["price"] = form.price ? numberToCents(form.price) : null;
        payload["start_at"] = startAt.valueOf();
        // payload["start_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.start_at)));
        payload["end_at"] = endAt.valueOf();
        //payload["end_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.start_at) + (form.game_duration * 60 * 1000)));
        payload["color"] = form.color;
        payload["payment_state"] = form.payment_state;
        payload["description"] = form.notes;
        payload["sport_type"] = form.sport_type;
        payload["booking_category"] = form.booking_category;
    } else if (bookingType === BookingType.BACKOFFICE_BOOKING) {
        payload["playground_id"] = form.playground.id;
        payload["playground_name"] = form.playground.name;
        payload["player_name"] = form.user_name;
        payload["player_family_name"] = form.user_last_name;
        //payload["player_phone_number"] = form.certify_phone_number ? formatItalianPhone(form.user_phone_number) : form.user_phone_number;
        payload["player_phone_number"] = formatPhoneWithPrefix(form.phone_number_prefix, form.user_phone_number);
        payload["player_email"] = form.user_email;
        payload["date"] = formatDateMMDDYYYY(form.date);
        payload["price"] = form.price ? numberToCents(form.price) : null;
        payload["start_at"] = startAt.valueOf();
        // payload["start_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.start_at)));
        payload["end_at"] = endAt.valueOf();
        payload["color"] = form.color;
        payload["payment_state"] = form.payment_state;
        payload["description"] = form.notes;
        payload["sport_type"] = form.sport_type;
        payload["booking_category"] = form.booking_category;
    } else if (bookingType === BookingType.BACKOFFICE_BOOKING_DESCRIPTION) {
        payload["playground_id"] = form.playground.id;
        payload["playground_name"] = form.playground.name;
        payload["player_description"] = form.player_description;
        payload["date"] = formatDateMMDDYYYY(form.date);
        payload["price"] = form.price ? numberToCents(form.price) : null;
        payload["start_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.start_at)));
        if (withEndTime) {
            payload["end_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.end_at)));
        } else {
            payload["end_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.start_at) + (form.game_duration * 60 * 1000)));
        }
        payload["color"] = form.color;
        payload["payment_state"] = form.payment_state;
        payload["description"] = form.notes;
        payload["sport_type"] = form.sport_type;
        payload["booking_category"] = form.booking_category;
    } else if (bookingType === BookingType.LESSON) {
        payload["playground_id"] = form.playground.id;
        payload["playground_name"] = form.playground.name;
        payload["teacher_name"] = form.teacher_name;
        payload["wesmash_user_id"] = form.wesmash_user.id;
        payload["player_name"] = form.wesmash_user.name;
        payload["player_family_name"] = form.wesmash_user.family_name;
        payload["player_phone_number"] = form.wesmash_user.phone_number;
        payload["player_email"] = form.wesmash_user.email;
        payload["date"] = formatDateMMDDYYYY(form.date);
        payload["price"] = form.price ? numberToCents(form.price) : null;
        payload["start_at"] = startAt.valueOf();
        // payload["start_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.start_at)));
        payload["end_at"] = endAt.valueOf();
        payload["color"] = form.color;
        payload["payment_state"] = form.payment_state;
        payload["description"] = form.notes;
        payload["sport_type"] = form.sport_type;
        payload["booking_category"] = form.booking_category;
    } else if (bookingType === BookingType.BACKOFFICE_LESSON) {
        payload["playground_id"] = form.playground.id;
        payload["playground_name"] = form.playground.name;
        payload["teacher_name"] = form.teacher_name;
        payload["player_name"] = form.user_name;
        payload["player_family_name"] = form.user_last_name;
        // payload["player_phone_number"] = form.certify_phone_number ? formatItalianPhone(form.user_phone_number) : form.user_phone_number;
        payload["player_phone_number"] = formatPhoneWithPrefix(form.phone_number_prefix, form.user_phone_number);
        payload["player_email"] = form.user_email;
        payload["date"] = formatDateMMDDYYYY(form.date);
        payload["price"] = form.price ? numberToCents(form.price) : null;
        payload["start_at"] = startAt.valueOf();
        payload["end_at"] = endAt.valueOf();
        payload["color"] = form.color;
        payload["payment_state"] = form.payment_state;
        payload["description"] = form.notes;
        payload["sport_type"] = form.sport_type;
        payload["booking_category"] = form.booking_category;
    } else if (bookingType === BookingType.BACKOFFICE_LESSON_DESCRIPTION) {
        payload["playground_id"] = form.playground.id;
        payload["playground_name"] = form.playground.name;
        payload["teacher_name"] = form.teacher_name;
        payload["player_description"] = form.player_description;
        payload["date"] = formatDateMMDDYYYY(form.date);
        payload["price"] = form.price ? numberToCents(form.price) : null;
        payload["start_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.start_at)));
        if (withEndTime) {
            payload["end_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.end_at)));
        } else {
            payload["end_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.start_at) + (form.game_duration * 60 * 1000)));
        }
        payload["color"] = form.color;
        payload["payment_state"] = form.payment_state;
        payload["description"] = form.notes;
        payload["sport_type"] = form.sport_type;
        payload["booking_category"] = form.booking_category;
    } else if (bookingType === BookingType.MAINTENANCE) {
        if (form.playground.id) {
            payload["playground_id"] = form.playground.id;
            payload["playground_name"] = form.playground.name;
        }
        payload["start_at"] = startAt.valueOf();
        payload["end_at"] = (new Date(form.end_date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.end_at)));
        payload["description"] = form.notes;
        payload["color"] = form.color;
    } else if (bookingType === BookingType.CLOSING) {
        if (form.playground.id) {
            payload["playground_id"] = form.playground.id;
            payload["playground_name"] = form.playground.name;
        }
        payload["date_from"] = formatDateMMDDYYYY(form.start_date);
        payload["date_to"] = formatDateMMDDYYYY(form.end_date);
        payload["description"] = form.notes;
        payload["color"] = form.color;
    } else if (bookingType === BookingType.OTHER) {
        payload["playground_id"] = form.playground.id;
        payload["playground_name"] = form.playground.name;
        payload["start_at"] = (new Date(form.start_date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.start_at)));
        payload["end_at"] = (new Date(form.end_date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.end_at)));
        payload["description"] = form.notes;
        payload["color"] = form.color;
    } else if (bookingType === BookingType.TOURNAMENT) {
        payload["tournament_id"] = form.tournament_id;
        payload["playground_id"] = form.playground.id;
        payload["start_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.start_at)));
        payload["end_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.end_at)));
    } else if (bookingType === BookingType.BACKOFFICE_EVENT) {
        payload["playground_id"] = form.playground.id;
        payload["playground_name"] = form.playground.name;
        payload["start_at"] = startAt.valueOf();
        payload["end_at"] = endAt.valueOf();
        payload["description"] = form.notes;
        payload["color"] = form.color;
        payload["event_type"] = form.event_type;
    } else if (bookingType === BookingType.BACKOFFICE_OPEN_BOOKING) {
        const sportType = (matchTypesOptions.find((el: any) => el.value === form.match_type) || {} as any).sport
        payload["playground_id"] = form.playground.id;
        payload["playground_name"] = form.playground.name;
        if (form.wesmash_user && form.wesmash_user.id) {
            payload["wesmash_user_id"] = form.wesmash_user.id;
            payload["player_name"] = form.wesmash_user.name;
            payload["player_family_name"] = form.wesmash_user.family_name;
            payload["player_phone_number"] = form.wesmash_user.phone_number;
            payload["player_email"] = form.wesmash_user.email;
        }
        payload["date"] = formatDateMMDDYYYY(form.date);
        payload["price"] = form.price ? numberToCents(form.price) : null;
        payload["start_at"] = startAt.valueOf();
        payload["end_at"] = endAt.valueOf();
        payload["color"] = form.color;
        payload["payment_state"] = PaymentState.UNPAID;
        payload["description"] = '';
        payload["sport_type"] = sportType;
        payload["match_type"] = form.match_type;
        // payload["isPublic"] = form.isPublic;
        payload["min_level"] = form.min_level;
        payload["max_level"] = form.max_level;
        payload["taking"] = 0;
        payload["booking_state"] = form.booking_state;
    }
    return payload;
}

export function realBookingType(bookingType: string) {
    return bookingType.replace("_DESCRIPTION", "");
}

export function mapModifyBookingPayload(club_id: string, form: BookingDetail, info: TimeslotInfo, oldUserId?: string, withEndTime = false, selectedTab = 0) {
    // INIT
    const d = moment(form.date);
    const startAt = moment(`${d.format('YYYY-MM-DD')} ${form.start_at}`);
    let endAt = null;
    if (withEndTime) {
        endAt = moment(`${d.format('YYYY-MM-DD')} ${form.hour_end}`);
    } else {
        endAt = moment(`${d.format('YYYY-MM-DD')} ${form.start_at}`);
        endAt.milliseconds(
            endAt.milliseconds() + (form.game_duration * 60 * 1000)
        );
    }
    console.log(form.booking_category);


    const payload = {
        club_id: club_id,
        booking_id: info.id,
        playground_id: form.playground.id,
        playground_name: form.playground.name,
        apply_all_playground: false,
        date: formatDateMMDDYYYY(form.date),
        start_at: startAt.valueOf(),
        end_at: endAt.valueOf(),
        price: form.price ? numberToCents(form.price) : null,
        description: form.notes ? form.notes : '',
        color: form.color,
        payment_state: form.payment_state,
        sport_type: form.sport_type,
        taking: form.taking ? numberToCents(form.taking) : null,
        type: realBookingType(form.type),
        event_type: form.event_type || undefined,
        bundle_end_at: new Date(form.bundle_end_at),
        booking_category: form.booking_category,
    }

    if (form.detach_bundle) {
        payload['detach_bundle'] = form.detach_bundle;
    }
    if (form.override_bundle) {
        // payload['override_bundle']  = form.override_bundle;
        payload['old_bundle_id'] = form.bundle_id;
    }
    if (info.type === BookingType.LESSON || info.type === BookingType.BACKOFFICE_LESSON) {
        payload["teacher_name"] = form.teacher_name;
    }
    if (selectedTab === 2) {
        payload["wesmash_user_id"] = oldUserId || undefined;
        payload["player_description"] = form.player_description;
    } else if (selectedTab === 1) {
        payload["wesmash_user_id"] = oldUserId || undefined;
        payload["player_name"] = form.name;
        payload["player_family_name"] = form.family_name;
        payload["player_phone_number"] = form.phone_number;
        payload["player_email"] = form.email;
    } else if (selectedTab === 0) {
        payload["wesmash_user_id"] = form.wesmash_user.id;
        payload["player_name"] = form.wesmash_user.name;
        payload["player_family_name"] = form.wesmash_user.family_name;
        payload["player_phone_number"] = form.wesmash_user.phone_number;
        payload["player_email"] = form.wesmash_user.email;
        if (oldUserId) {
            if (oldUserId != form.wesmash_user.id) {
                payload["new_wesmash_user_id"] = form.wesmash_user.id;
                payload["wesmash_user_id"] = oldUserId;
            } else {
                payload["wesmash_user_id"] = form.wesmash_user.id;
            }
        } else {
            payload["wesmash_user_id"] = form.wesmash_user.id;
        }
    }
    return payload;
}


export function mapBookingToInfoForm(value: CalendarTimeslot, playgroundOptions, hasCards: boolean, colorChoices: { label: string; color: string; value: string }[]): BookingDetail {
    const booking = value.timeslotInfo;
    const defaultPlayground = playgroundOptions.find((pl) => pl.value.id === booking.playground_id).value;

    const store = useStore();
    const myClub = store.getters.getMyClub;

    const _provv = {
        type: booking.type,
        name: booking.player_name,
        family_name: booking.player_family_name,
        phone_number: booking.player_phone_number,
        email: booking.player_email,
        date: new Date(booking.date),
        start_at: convertCalendarDateFormatToHHMM(value.start),
        hour_end: mapBookingEndAt(value.start, new Date(booking.end_at), booking.start_at, booking.end_at),
        playground: defaultPlayground,
        sport_type: (defaultPlayground || {}).sport || "PADEL",
        game_duration: (booking.end_at - booking.start_at) / (60 * 1000),
        price: booking.price / 100,
        payment_state: booking.payment_state,
        notes: booking.description,
        color: booking.color ? booking.color : colorChoices[0].value,
        taking: booking.taking / 100,
        event_type: booking.event_type,
        payment_online: booking.payment_choice != null && booking.payment_choice == 'ONLINE',
        transaction_id: booking.transaction_id,
        nexi_transaction_id: booking.nexi_transaction_id,
        transaction_date: booking.transaction_date != null ? Date.parse(booking.transaction_date) : null,
    }

    // Se il centro è di tipo commerciale: è fisso commerciale 
    // se è di altra natura: dipente dal giocatore
    if (booking.booking_category != null) {
        _provv['booking_category'] = booking.booking_category;
    }
    else if (myClub.club_category == CardCategories.COMMERCIAL) {
        _provv['booking_category'] = CardCategories.COMMERCIAL;
    }
    else if (booking.player_category != null) {
        const category = myClub.player_categories.filter((el) => el.code == booking.player_category)[0];
        if (category !== undefined) {
            _provv['booking_category'] = category.type;
        }
        else {
            if (hasCards) {
                _provv['booking_category'] = "INSTITUTIONAL";
            }
            else {
                _provv['booking_category'] = PlayerCategoriesOptions[1].value;
            }
        }
    } else {
        _provv['booking_category'] = CardCategories.INSTITUTIONAL;
    }

    if (value.detach_bundle) { _provv['detach_bundle'] = value.detach_bundle; }
    if (value.override_bundle) { _provv['override_bundle'] = value.override_bundle; }
    if (value.recurring) { _provv['recurring'] = value.recurring; }
    if (booking.bundle_id) { _provv['bundle_id'] = booking.bundle_id; }
    if (booking.bundle_end_at) { _provv['bundle_end_at'] = new Date(booking.bundle_end_at); }
    if (booking.player_description) { _provv['player_description'] = booking.player_description; }
    else { _provv['wesmash_user'] = emptyPadelUser; }
    if (booking.teacher_name) { _provv["teacher_name"] = booking.teacher_name; }
    return _provv;
}

export function convertTimestampBack(timestamp: number) {
    return timestamp - (24 * 60 * 60 * 1000)
}

export function convertTimestampForward(timestamp: number) {
    return timestamp + (24 * 60 * 60 * 1000)
}

export function mapTournamentToInfoForm(tournament: Tournament, t) {
    return {
        ...tournament,
        type: t(tournament.type),
        start_at: formatDateDDMMYYYY(new Date(tournament.start_at)),
        end_at: formatDateDDMMYYYY(new Date(convertTimestampBack(tournament.end_at))), //adjust date end
        registration_end_at: formatDateDDMMYYYY(new Date(convertTimestampBack(tournament.registration_end_at))),
    }
}

export function convertPhoneNumberForExcel(phone_number) {
    return "\"=\"\"" + phone_number + "\"\"\"";
}

export function convertStatsMapToArray(entries, t) {
    const _provv = [];
    entries.forEach((entry) => {
        const el = {};
        el[t('date')] = formatStatsDate(entry[0]);
        el[t('booked_hours')] = entry[1].booked_hours / (60 * 60 * 1000);
        el[t('lesson_hours')] = entry[1].lessons_hours / (60 * 60 * 1000);
        el[t('cancelled_hours')] = entry[1].cancelled_hours / (60 * 60 * 1000);
        el[t('income')] = entry[1].income / 100;
        _provv.push(el)
    })
    return _provv;
}

export function convertSchedulerToArray(array, t, playgroundMap) {
    const _provv = [];
    array.forEach((entry: CalendarTimeslot) => {
        const info = entry.timeslotInfo ? entry.timeslotInfo : emptyTimeslotInfo;
        const el = {};
        const type = t(info.type);

        el[t('type')] = type;
        el[t('event_type')] = info.event_type ? t(info.event_type) : '-';

        const playground_name = playgroundMap[info.playground_id];

        el[t('playground_name')] = playground_name ? playground_name : '-';
        el[t('date')] = info.date ? convertMMDDToDDMM(info.date) : '-';
        el[t('start_at')] = entry.start ? convertCalendarDateFormatToHHMM(entry.start) : '-';
        el[t('end_at')] = mapBookingEndAt(entry.start, entry.end, info.start_at, info.end_at);
        el[t('price')] = info.price && (typeof (info.price) === 'number') ? (info.price / 100) : '-';
        el[t('taking')] = info.taking && (typeof (info.taking) === 'number') ? (info.taking / 100) : '-';
        el[t('payment_state')] = info.payment_state ? t(info.payment_state) : '-';
        if (info.player_description) {
            el[t('player_email')] = '-';
            el[t('player_name')] = '-';
            el[t('player_last_name')] = '-';
            el[t('player_phone_number')] = '-';
            el[t('player_description')] = info.player_description ? info.player_description : '-';
        } else {
            el[t('player_email')] = info.player_email ? info.player_email : '-';
            el[t('player_name')] = info.player_name ? info.player_name : '-';
            el[t('player_last_name')] = info.player_family_name ? info.player_family_name : '-';
            el[t('player_phone_number')] = info.player_phone_number ? convertPhoneNumberForExcel(info.player_phone_number) : '-';
            el[t('player_description')] = '-';
        }

        el['Note'] = entry.timeslotInfo ? (entry.timeslotInfo.description ? `"${entry.timeslotInfo.description.replace(",", "\n").replace("\n", "; ")}"` : '-') : '-';
        el['Tipologia pagamento'] = entry.timeslotInfo.payment_choice === 'ONLINE' ? 'ONLINE' : 'OFFLINE';
        el['ID Trans. Nexi'] = entry.timeslotInfo.payment_choice === 'ONLINE' && entry.timeslotInfo.nexi_transaction_id ? entry.timeslotInfo.nexi_transaction_id : '-';

        _provv.push(el);
    });
    return _provv;
}


export function convertPaymentsotToArray(array, t, date, onlinePayments, refunds) {
    const _provv = [];
    let online = 0.0;
    let offline = 0.0;

    let onlineCommercial = 0.0;
    let onlineInstitutional = 0.0;
    let offlineCommercial = 0.0;
    let offlineInstitutional = 0.0;

    const elOnline = { "DATA": date, 'PAGAMENTO': 'ONLINE', 'TOTALE': "0", "VALORE COMMERCIALE": "0", "VALORE ISTITUZIONALE": "0" };
    const elOffline = { "DATA": date, 'PAGAMENTO': 'OFFLINE', 'TOTALE': "0", "VALORE COMMERCIALE": "0", "VALORE ISTITUZIONALE": "0" };

    array.forEach((info) => {
        const price = (info.price && (typeof (info.price) === 'number') ? (info.price / 100) : 0);

        if (info.payment_choice !== 'ONLINE') {
            offline += price;

            if (info.booking !== undefined && info.booking.booking_category === ClubCategoriesOptions[1].value) {
                // Commerciale
                offlineCommercial += price;
            }
            else {
                // Istituzionale (Default)
                offlineInstitutional += price;
            }
        }
    });

    onlinePayments.forEach((info) => {
        const price = (info.price && (typeof (info.price) === 'number') ? (info.price / 100) : 0);

        if (info.booking !== undefined && info.booking.booking_category === ClubCategoriesOptions[1].value) {
            // Commerciale
            onlineCommercial += price;
        }
        else {
            // Istituzionale (Default)
            onlineInstitutional += price;
        }
        online += price;
    });

    refunds.forEach((info) => {
        if (info.refund_price !== undefined) {
            const refund = ((info.price / 100) - (info.refund_price / 100));
            online += refund;

            if (info.booking !== undefined && info.booking.booking_category === ClubCategoriesOptions[1].value) {
                // Commerciale
                onlineCommercial += refund;
            }
            else {
                // Istituzionale (Default)
                onlineInstitutional += refund;
            }
        }
    });

    elOnline['TOTALE'] = online.toFixed(2);
    elOnline['VALORE COMMERCIALE'] = onlineCommercial.toFixed(2);
    elOnline['VALORE ISTITUZIONALE'] = onlineInstitutional.toFixed(2);

    elOffline['TOTALE'] = offline.toFixed(2);
    elOffline['VALORE COMMERCIALE'] = offlineCommercial.toFixed(2);
    elOffline['VALORE ISTITUZIONALE'] = offlineInstitutional.toFixed(2);

    _provv.push(elOnline);
    _provv.push(elOffline);
    return _provv;
}

export function convertTimeslotToArray(array, t, playgroundMap) {
    const _provv = [];
    array.forEach((info) => {
        const el = {};
        const type = t(info.type);

        const playground_name = playgroundMap[info.playground_id];

        el[t('type')] = type;
        el[t('event_type')] = info.event_type ? t(info.event_type) : '-';
        el[t('playground_name')] = playground_name ? playground_name : '-',
            el[t('date')] = info.date ? convertMMDDToDDMM(info.date) : '-',
            el[t('start_at')] = info.start_at ? transformMillisecondsToHHMM(info.start_at) : '-',
            el[t('end_at')] = info.end_at ? transformMillisecondsToHHMM(info.end_at) : '-',
            el[t('price')] = info.price && (typeof (info.price) === 'number') ? (info.price / 100) : '-',
            el[t('taking')] = info.taking && (typeof (info.taking) === 'number') ? (info.taking / 100) : '-';
        el[t('payment_state')] = info.payment_state ? t(info.payment_state) : '-';

        if (info.player_description) {
            el[t('player_email')] = '-';
            el[t('player_name')] = '-';
            el[t('player_last_name')] = '-';
            el[t('player_phone_number')] = '-';
            el[t('player_description')] = info.player_description ? info.player_description : '-';
        } else {
            el[t('player_email')] = info.player_email ? info.player_email : '-';
            el[t('player_name')] = info.player_name ? info.player_name : '-';
            el[t('player_last_name')] = info.player_family_name ? info.player_family_name : '-';
            el[t('player_phone_number')] = info.player_phone_number ? convertPhoneNumberForExcel(info.player_phone_number) : '-';
            el[t('player_description')] = '-';
        }

        el['Note'] = info.description ? `"${info.description.replace(",", "\n").replace("\n", "; ")}"` : '-';
        el['Tipologia pagamento'] = info.payment_choice === 'ONLINE' ? 'ONLINE' : 'OFFLINE';
        el['ID Trans. Nexi'] = info.payment_choice === 'ONLINE' && info.nexi_transaction_id ? info.nexi_transaction_id : '-';
        _provv.push(el);
    });
    return _provv;
}

export function convertMonthSchedulerToArray(obj, t, playgroundMap) {
    let _provv = [];
    Object.values(obj).forEach((entry) => {
        const transformedVal = convertTimeslotToArray(entry, t, playgroundMap);
        _provv = _provv.concat(transformedVal);
    });
    return _provv;
}



export function removePlusPhonenumber(value: string) {
    return value.startsWith("+") ? "00" + value.substring(1) : value;
}

export function mapUsersArray(array: PadelUser[], t) {
    const _provv = [];
    array.forEach((entry: PadelUser) => {
        const el = {};
        el[t('name')] = entry.name;
        el[t('surname')] = entry.family_name;
        el[t('email')] = entry.email;
        el[t('phonenumber')] = convertPhoneNumberForExcel(entry.phone_number);
        el[t('level')] = upperCaseFirstLetter(entry.exp_level?.description || '');
        el[t('bookings_number')] = entry.bookings_count;
        el[t('cancellations_number')] = entry.cancellations_count;
        el[t('banned')] = entry.banned ? 'SI' : 'NO';
        el['Consenso a comunicazioni del Centro'] = entry.consents?.accepts_commercial_partner ? 'SI' : 'NO';
        _provv.push(el);
    });
    return _provv;
}

export function convertTeamsToArray(entries, t) {
    const _provv = [];
    entries.forEach((entry) => {
        const el = {};
        el[t('fullname_player_1')] = `${entry.players[0].name} ${entry.players[0].family_name}`;
        el[t('fullname_player_2')] = `${entry.players[1].name} ${entry.players[1].family_name}`;
        el[t('email_player_1')] = `${entry.players[0].email}`;
        el[t('phonenumber_player_1')] = convertPhoneNumberForExcel(entry.players[0].phone_number);
        el[t('level_player_1')] = t(entry.players[0].level);
        el[t('level_player_2')] = t(entry.players[1].level);
        el[t('sex_player_1')] = t(entry.players[0].gender + '_GENDER');
        el[t('sex_player_2')] = t(entry.players[1].gender + '_GENDER');
        el[t('team_name')] = `${entry.team_name}`;
        el[t('notes')] = `${entry.notes}`;
        _provv.push(el)
    })
    return _provv;
}

export function mapBookingError(error: BookingError, map) {
    if (error.reason.toLowerCase().indexOf('already closed or unusable') > -1) {
        return `Il campo ${map[error.playground_id]} è già chiuso il ${convertMMDDToDDMM(error.date)}`
    } else if (error.reason.toLowerCase().indexOf('there is already a reservation') > -1) {
        return `Nel campo ${map[error.playground_id]} è già presente una prenotazione in quest'orario`
    } else {
        return "Errore con la prenotazione"
    }
}

export function mapCancelBookingPayload(booking: TimeslotInfo, _club_id: string) {
    return {
        booking_id: booking.id,
        club_id: _club_id,
        playground_id: booking.playground_id,
        date: booking.date,
        booking_type: booking.type,
        wesmash_user_id: booking.wesmash_user_id,

        //        sport_type: booking.sport_type,
    }
}

export function mapSlotCancelBookingPayload(slot, _club_id: string) {
    return {
        booking_id: slot.booking_id,
        club_id: _club_id,
        playground_id: slot.playground.id,
        date: formatDateMMDDYYYY(slot.date),
        booking_type: BookingType.TOURNAMENT,
    }
}


export function calculateDefaultBookingPrice(half_hour_price: number, game_duration: number) {
    return Math.round((half_hour_price / 100) * (game_duration / 30));
}

export function mapTournamentRequestDates(dates: TournamentFormDate[]): TournamentDate[] {
    const _provv = [];
    dates.forEach((date) => {
        _provv.push({
            playground_id: date.playground.id,
            start_at: convertDateAndTimeToTimestamp(date.date, date.start_at),
            end_at: date.end_at == '24:00' ? convertDateAndTimeToTimestamp(moment(date.date).add(1, 'days').toDate(), '00:00') : convertDateAndTimeToTimestamp(date.date, date.end_at),
        })
    })
    return _provv;
}

export function mapTournamentDates(dates, playgroundOptions) {
    const _provv = [];
    dates.forEach((date) => {
        _provv.push({
            date: new Date(date.start_at),
            playground: (playgroundOptions.find((option) => option.value.id === date.playground_id)).value,
            start_at: convertMillisecondsToHHMM(date.start_at),
            end_at: convertMillisecondsToHHMM(date.end_at),
            booking_id: date.booking_id
        })
    })
    return _provv;
}

export function mapCreateTournamentRequest(form: CreateTournamentForm) {

    const mappedDates = mapTournamentRequestDates(form.dates);
    const payload = {
        name: form.name,
        price: parseFloat(form.price) * 100,
        type: form.type,
        start_at: form.start_at.getTime(),
        end_at: getTomorrowTimestamp(form.end_at),
        registration_end_at: getTomorrowTimestamp(form.registration_end_at),
        min_couples: parseFloat(form.min_couples),
        max_couples: parseFloat(form.max_couples),
        min_level: LevelMap[form.min_level],
        max_level: LevelMap[form.max_level],
        description: form.description,
        mode_description: form.mode_description,
        prizes: form.prizes,
        dates: []
    }
    payload["dates"] = mappedDates;

    if (form.picture && form.picture.src) {
        payload["picture"] = form.picture.src;
    }
    return payload;
}

export function mapTournamentToModifyFormWithPreviousTourn(tournament: Tournament) {
    return {
        name: tournament.name,
        price: tournament.price / 100,
        type: tournament.type,
        min_couples: tournament.min_couples,
        max_couples: tournament.max_couples,
        min_level: InverseLevelMap[tournament.min_level],
        max_level: InverseLevelMap[tournament.max_level],
        description: tournament.description,
        mode_description: tournament.mode_description,
        prizes: tournament.prizes,
        picture: '',
        dates: []
    }
}

export function mapTournamentToModifyForm(tournament: Tournament) {
    return {
        name: tournament.name,
        price: tournament.price / 100,
        type: tournament.type,
        min_couples: tournament.min_couples,
        max_couples: tournament.max_couples,
        min_level: InverseLevelMap[tournament.min_level],
        max_level: InverseLevelMap[tournament.max_level],
        description: tournament.description,
        mode_description: tournament.mode_description,
        prizes: tournament.prizes,
        picture: '',
        start_at: tournament.start_at,
        end_at: tournament.end_at,
        registration_end_at: tournament.registration_end_at
    }
}

export function mapTournamentToForm(tournament: Tournament) {
    return {
        ...mapTournamentToModifyForm(tournament),
        dates: []
    }
}



export function mapTournamentToSlotTab(tournament: Tournament, playgroundOptions) {
    return {
        ...mapTournamentToModifyForm(tournament),
        start_at: new Date(tournament.start_at),
        end_at: new Date(convertTimestampBack(tournament.end_at)), //adjust date end
        registration_end_at: new Date(convertTimestampBack(tournament.registration_end_at)),
        dates: mapTournamentDates(tournament.dates, playgroundOptions)
    }
}



export function mapModifyTournamentRequest(form): ModifyTournamentRequest {
    const payload = {
        start_at: form.start_at,
        end_at: form.end_at,
        registration_end_at: form.start_at,
        min_level: LevelMap[form.min_level],
        max_level: LevelMap[form.max_level],
        min_couples: parseFloat(form.min_couples),
        max_couples: parseFloat(form.max_couples),
        description: form.description,
        mode_description: form.mode_description,
        prizes: form.prizes,
    }
    if (form.picture && form.picture.src) {
        payload["picture"] = form.picture.src;
    }
    return payload;
}

export function mapChangeSlotsRequest(form): ModifyTournamentRequest {
    return {
        ...mapModifyTournamentRequest(form),
        start_at: form.start_at.getTime(),
        end_at: getTomorrowTimestamp(form.end_at),
        registration_end_at: getTomorrowTimestamp(form.registration_end_at)
    }
}

export function mapPriceRuleIntoForm(response, addPriceRuleFormErrors, emptySlotItemErrors) {
    // INIT
    const slots = response.slots;
    for (let i = 0; i < slots.length; i++) {
        // ADD ERRORS SLOTS
        addPriceRuleFormErrors.value.slots.push(
            JSON.parse(JSON.stringify(emptySlotItemErrors))
        );

        // PARSE DURATION
        response.slots[i].duration_60 = (response.slots[i].price_60 != -1);
        response.slots[i].duration_90 = (response.slots[i].price_90 != -1);
        response.slots[i].duration_120 = (response.slots[i].price_120 != -1);
        response.slots[i].show_duration_60 = durationIsHigherThan(response.slots[i], 60);
        response.slots[i].show_duration_90 = durationIsHigherThan(response.slots[i], 90);
        response.slots[i].show_duration_120 = durationIsHigherThan(response.slots[i], 120);

        // PARSE PRICE
        response.slots[i].price_60 = (response.slots[i].price_60 == -1 ? null : response.slots[i].price_60 / 100);
        response.slots[i].price_90 = (response.slots[i].price_90 == -1 ? null : response.slots[i].price_90 / 100);
        response.slots[i].price_120 = (response.slots[i].price_120 == -1 ? null : response.slots[i].price_120 / 100);
    }

    // CONVERT DATE
    const year = (new Date()).getFullYear();
    response.start_at = new Date(`${year}-${response.start_at}`);
    response.end_at = new Date(`${year}-${response.end_at}`);

    // RETURN
    return response;
}

export function priceCalendarClass(value, day = null, rulesList = [], playgroundId = null) {
    const classes = [];
    if (value.type) classes.push(value.type);
    if (value.color) classes.push(value.color);
    if (value.type === RuleType.RULE) {
        classes.push("custom-gray");

        // CHECK IF THERE IS AN EXCEPTION INTO THE SAME DAY
        const exceptions = rulesList.filter(el => el.type === RuleType.EXCEPTION);
        const found = exceptions.filter(el => {
            return moment(el.exception_at).isSame(day, 'date') && el.playground_ids.includes(playgroundId);
        });
        if (found.length > 0) {
            classes.push('p-d-none');
        }
    }
    if (value.type === RuleType.EXCEPTION) classes.push("custom-fucsia");

    // RETURN
    return classes.join(" ");
}

export function mapRulesIntoExceptionRule(event, rules, selectedSport, selectedPlayground) {
    // INIT
    const day = event.day;
    const output = JSON.parse(JSON.stringify(emptyExceptionRuleForm));
    const playground_ids = [];
    let slots = [];

    // FILTER RULES IN DAY
    const mappedRules = rules.value.filter(rule => {
        const startAt = moment(`${day.format("YYYY")}-${rule.start_at}`);
        const endAt = moment(`${day.format("YYYY")}-${rule.end_at}`);
        const weekDay = mappedWeekDay(day.weekday());
        return day.valueOf() >= startAt.valueOf() && day.valueOf() <= endAt.valueOf()
            && rule.days.includes(weekDay);
    });

    // SET
    mappedRules.forEach(rule => {
        if (rule.playground_ids.includes(selectedPlayground)) {
            playground_ids.push(selectedPlayground);
            slots = slots.concat(rule.slots);
        }
    });
    output.club_id = event.parent.club_id;
    output.exception_at = day.toDate();
    output.slots = JSON.parse(JSON.stringify(slots));
    output.playground_ids = [...new Set(playground_ids)];
    output.sport_type = selectedSport;

    // ADD DATA TO SLOTS
    for (let i = 0; i < output.slots.length; i++) {
        // PARSE DURATION
        output.slots[i].duration_60 = (output.slots[i].price_60 != -1);
        output.slots[i].duration_90 = (output.slots[i].price_90 != -1);
        output.slots[i].duration_120 = (output.slots[i].price_120 != -1);
        output.slots[i].show_duration_60 = durationIsHigherThan(output.slots[i], 60);
        output.slots[i].show_duration_90 = durationIsHigherThan(output.slots[i], 90);
        output.slots[i].show_duration_120 = durationIsHigherThan(output.slots[i], 120);

        // PARSE PRICE
        output.slots[i].price_60 = (output.slots[i].price_60 == -1 ? null : output.slots[i].price_60 / 100);
        output.slots[i].price_90 = (output.slots[i].price_90 == -1 ? null : output.slots[i].price_90 / 100);
        output.slots[i].price_120 = (output.slots[i].price_120 == -1 ? null : output.slots[i].price_120 / 100);
    }

    // RETURN
    return output;
}

export function mapExceptionRuleIntoForm(parent) {
    // INIT
    const output = JSON.parse(JSON.stringify(emptyExceptionRuleForm));
    const playground_ids = [].concat(parent.playground_ids);
    const slots = [].concat(parent.slots);
    output.id = parent.id;
    output.name = parent.name;
    output.club_id = parent.club_id;
    output.exception_at = new Date(parent.exception_at);
    output.slots = slots;
    output.sport_type = parent.sport_type;
    output.playground_ids = [...new Set(playground_ids)];

    // ADD DATA TO SLOTS
    for (let i = 0; i < output.slots.length; i++) {
        // PARSE DURATION
        output.slots[i].duration_60 = (output.slots[i].price_60 != -1);
        output.slots[i].duration_90 = (output.slots[i].price_90 != -1);
        output.slots[i].duration_120 = (output.slots[i].price_120 != -1);
        output.slots[i].show_duration_60 = durationIsHigherThan(output.slots[i], 60);
        output.slots[i].show_duration_90 = durationIsHigherThan(output.slots[i], 90);
        output.slots[i].show_duration_120 = durationIsHigherThan(output.slots[i], 120);

        // PARSE PRICE
        output.slots[i].price_60 = (output.slots[i].price_60 == -1 ? null : output.slots[i].price_60 / 100);
        output.slots[i].price_90 = (output.slots[i].price_90 == -1 ? null : output.slots[i].price_90 / 100);
        output.slots[i].price_120 = (output.slots[i].price_120 == -1 ? null : output.slots[i].price_120 / 100);
    }

    // RETURN
    return output;
}

export function mapFilteredPlaygrounds(playgrounds, sport, visibility) {
    // INIT
    let output = JSON.parse(JSON.stringify(playgrounds));

    // FILTER
    if (sport != 'all') {
        output = output.filter(el => el.sport.toLowerCase() === sport.toLowerCase());
    }
    if (visibility != 'all') {
        output = output.filter(el => !!el.hidden === visibility);
    }

    // RETURN
    return output;
}

export function mapContentForTooltip(playground, t) {
    // INIT
    const output = {};

    // EXPOSURE
    output[t('exposure')] = t(playground.exposure);

    // SIZE
    if ([PlaygroundSports.PADEL, PlaygroundSports.TENNIS].includes(playground.sport)) {
        output[t('playground_size')] = t(playground.playground_size);
    }

    // GROUND
    if (playground.ground) {
        output[t('ground')] = t(playground.ground);
    }

    // WALLS
    if ([PlaygroundSports.PADEL, PlaygroundSports.PADEBALL].includes(playground.sport)) {
        output[t('walls')] = t(playground.walls);
    }

    // RETURN
    return output;
}

export function mySportsByOptions(options, playgrounds) {
    return options.filter(option => {
        return [...new Set(playgrounds.map(el => el.sport))]
            .includes(option.value);
    });
}

export function mySportsByPlaygrounds(playgrounds) {
    const options = sportOptions;
    const sports = [...new Set(playgrounds.map(el => el.sport))];
    return options.filter(option => sports.includes(option.value));
}

export function getDateRange(startDate, endDate, dateFormat) {
    const dates = [],
        end = endDate.clone(),
        diff = endDate.diff(startDate, 'days');

    if (!startDate.isValid() || !endDate.isValid()) {
        return [];
    }
    if (diff <= 0) {
        return [startDate.format(dateFormat)];
    }

    for (let i = 0; i < diff; i++) {
        dates.push(end.subtract(1, 'd').format(dateFormat));
    }
    if (!dates.find(el => el === endDate.format(dateFormat))) {
        dates.push(endDate.format(dateFormat));
    }

    return dates.sort();
}

export function mapRecurringBookingPayload(club_id: string, bookingType: string, form: BookingForm, withEndTime = false) {
    // Usare start_date | end_date per fare ricorrenza multi giorno dell'evento (solo evento)
    // INIT
    const output = [];
    let range = [];

    // CHECK RANGE
    if (bookingType === BookingType.BACKOFFICE_EVENT) {
        range = getDateRange(
            moment(form.start_date),
            moment(form.end_date).clone().endOf('date'),
            "YYYY-MM-DD"
        );
    } else {
        range = [
            moment(form.date).format("YYYY-MM-DD")
        ];
    }

    // ITERATE
    for (let i = 0; i < range.length; i++) {
        ///---
        const date = range[i];
        const payload = {
            club_id: club_id,
            type: bookingType.replace("_DESCRIPTION", ""),
            apply_all_playground: form.apply_all_playground,
            bundle_end_at: moment(form.bundle_end_at).toISOString(),
            taking: form.taking ? numberToCents(form.taking) : null,
        };

        const d = moment(date);
        const startAt = moment(`${d.format('YYYY-MM-DD')} ${form.start_at}`);
        let endAt = null;
        if (withEndTime) {
            endAt = moment(`${d.format('YYYY-MM-DD')} ${form.end_at}`);
        } else {
            endAt = moment(`${d.format('YYYY-MM-DD')} ${form.start_at}`);
            endAt.milliseconds(
                endAt.milliseconds() + (form.game_duration * 60 * 1000)
            );
        }

        if (bookingType === BookingType.BOOKING) {
            payload["playground_id"] = form.playground.id;
            payload["playground_name"] = form.playground.name;
            payload["wesmash_user_id"] = form.wesmash_user.id;
            payload["player_name"] = form.wesmash_user.name;
            payload["player_family_name"] = form.wesmash_user.family_name;
            payload["player_phone_number"] = form.wesmash_user.phone_number;
            payload["player_email"] = form.wesmash_user.email;
            payload["date"] = formatDateMMDDYYYY(form.date);
            payload["price"] = form.price ? numberToCents(form.price) : null;
            payload["start_at"] = startAt.valueOf();
            payload["end_at"] = endAt.valueOf();
            payload["color"] = form.color;
            payload["payment_state"] = form.payment_state;
            payload["description"] = form.notes;
            payload["sport_type"] = form.sport_type;
            payload["booking_category"] = form.booking_category;
        } else if (bookingType === BookingType.BACKOFFICE_BOOKING) {
            payload["playground_id"] = form.playground.id;
            payload["playground_name"] = form.playground.name;
            payload["player_name"] = form.user_name;
            payload["player_family_name"] = form.user_last_name;
            payload["player_phone_number"] = formatPhoneWithPrefix(form.phone_number_prefix, form.user_phone_number);
            payload["player_email"] = form.user_email;
            payload["date"] = formatDateMMDDYYYY(form.date);
            payload["price"] = form.price ? numberToCents(form.price) : null;
            payload["start_at"] = startAt.valueOf();
            payload["end_at"] = endAt.valueOf();
            payload["color"] = form.color;
            payload["payment_state"] = form.payment_state;
            payload["description"] = form.notes;
            payload["sport_type"] = form.sport_type;
            payload["booking_category"] = form.booking_category;
        } else if (bookingType === BookingType.BACKOFFICE_BOOKING_DESCRIPTION) {
            payload["playground_id"] = form.playground.id;
            payload["playground_name"] = form.playground.name;
            payload["player_description"] = form.player_description;
            payload["date"] = formatDateMMDDYYYY(form.date);
            payload["price"] = form.price ? numberToCents(form.price) : null;
            payload["start_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.start_at)));
            if (withEndTime) {
                payload["end_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.end_at)));
            } else {
                payload["end_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.start_at) + (form.game_duration * 60 * 1000)));
            }
            payload["color"] = form.color;
            payload["payment_state"] = form.payment_state;
            payload["description"] = form.notes;
            payload["sport_type"] = form.sport_type;
            payload["booking_category"] = form.booking_category;
        } else if (bookingType === BookingType.LESSON) {
            payload["playground_id"] = form.playground.id;
            payload["playground_name"] = form.playground.name;
            payload["teacher_name"] = form.teacher_name;
            payload["wesmash_user_id"] = form.wesmash_user.id;
            payload["player_name"] = form.wesmash_user.name;
            payload["player_family_name"] = form.wesmash_user.family_name;
            payload["player_phone_number"] = form.wesmash_user.phone_number;
            payload["player_email"] = form.wesmash_user.email;
            payload["date"] = formatDateMMDDYYYY(form.date);
            payload["price"] = form.price ? numberToCents(form.price) : null;
            payload["start_at"] = startAt.valueOf();
            payload["end_at"] = endAt.valueOf();
            payload["color"] = form.color;
            payload["payment_state"] = form.payment_state;
            payload["description"] = form.notes;
            payload["sport_type"] = form.sport_type;
            payload["booking_category"] = form.booking_category;
        } else if (bookingType === BookingType.BACKOFFICE_LESSON) {
            payload["playground_id"] = form.playground.id;
            payload["playground_name"] = form.playground.name;
            payload["teacher_name"] = form.teacher_name;
            payload["player_name"] = form.user_name;
            payload["player_family_name"] = form.user_last_name;
            payload["player_phone_number"] = formatPhoneWithPrefix(form.phone_number_prefix, form.user_phone_number);
            payload["player_email"] = form.user_email;
            payload["date"] = formatDateMMDDYYYY(form.date);
            payload["price"] = form.price ? numberToCents(form.price) : null;
            payload["start_at"] = startAt.valueOf();
            payload["end_at"] = endAt.valueOf();
            payload["color"] = form.color;
            payload["payment_state"] = form.payment_state;
            payload["description"] = form.notes;
            payload["sport_type"] = form.sport_type;
            payload["booking_category"] = form.booking_category;
        } else if (bookingType === BookingType.BACKOFFICE_LESSON_DESCRIPTION) {
            payload["playground_id"] = form.playground.id;
            payload["playground_name"] = form.playground.name;
            payload["teacher_name"] = form.teacher_name;
            payload["player_description"] = form.player_description;
            payload["date"] = formatDateMMDDYYYY(form.date);
            payload["price"] = form.price ? numberToCents(form.price) : null;
            payload["start_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.start_at)));
            if (withEndTime) {
                payload["end_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.end_at)));
            } else {
                payload["end_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.start_at) + (form.game_duration * 60 * 1000)));
            }
            payload["color"] = form.color;
            payload["payment_state"] = form.payment_state;
            payload["description"] = form.notes;
            payload["sport_type"] = form.sport_type;
            payload["booking_category"] = form.booking_category;
        } else if (bookingType === BookingType.MAINTENANCE) {
            if (form.playground.id) {
                payload["playground_id"] = form.playground.id;
                payload["playground_name"] = form.playground.name;
            }
            payload["start_at"] = startAt.valueOf();
            payload["end_at"] = (new Date(form.end_date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.end_at)));
            payload["description"] = form.notes;
            payload["color"] = form.color;
        } else if (bookingType === BookingType.CLOSING) {
            if (form.playground.id) {
                payload["playground_id"] = form.playground.id;
                payload["playground_name"] = form.playground.name;
            }
            payload["date_from"] = formatDateMMDDYYYY(form.start_date);
            payload["date_to"] = formatDateMMDDYYYY(form.end_date);
            payload["description"] = form.notes;
            payload["color"] = form.color;
        } else if (bookingType === BookingType.OTHER) {
            payload["playground_id"] = form.playground.id;
            payload["playground_name"] = form.playground.name;
            payload["start_at"] = (new Date(form.start_date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.start_at)));
            payload["end_at"] = (new Date(form.end_date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.end_at)));
            payload["description"] = form.notes;
            payload["color"] = form.color;
        } else if (bookingType === BookingType.TOURNAMENT) {
            payload["tournament_id"] = form.tournament_id;
            payload["playground_id"] = form.playground.id;
            payload["start_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.start_at)));
            payload["end_at"] = (new Date(form.date.setHours(0, 0, 0, 0)).setMilliseconds(convertHHMMtoMillisecondsOfDay(form.end_at)));
        } else if (bookingType === BookingType.BACKOFFICE_EVENT) {
            payload["playground_id"] = form.playground.id;
            payload["playground_name"] = form.playground.name;
            payload["date"] = formatDateMMDDYYYY(date);
            payload["start_at"] = startAt.valueOf();
            payload["end_at"] = endAt.valueOf();
            payload["description"] = form.notes;
            payload["color"] = form.color;
            payload["event_type"] = form.event_type;
        }

        // PUSH
        output.push(payload);
    }

    // RETURN
    return output;
}

export function mapBundleConfirmation(item) {
    const body = JSON.parse(JSON.stringify(item));
    return body
        .filter(el => el.selected === true)
        .map((el) => {
            delete el.key;
            delete el.status;
            delete el.playground_ids;
            delete el.selected;
            return el;
        });
}

export function isGameConfirmed(game) {
    const matchType = matchTypesOptions.find(el => el.value === game.match_type);
    const max = matchType.participants;

    // CHECK
    let total = 0;
    if (game.wesmash_user_id) total++;
    if (game.wesmash_user2_id) total++;
    if (game.wesmash_user3_id) total++;
    if (game.wesmash_user4_id) total++;

    return total === max;
}

export function confirmedGame(t, game, labelType = 'bool') {
    const positive = (labelType === 'bool' ? 'yes' : 'OPEN_BOOKING_CONFIRMED');
    const negative = (labelType === 'bool' ? 'no' : 'OPEN_BOOKING_OPEN');
    return isGameConfirmed(game) ? t(positive) : t(negative);
}