
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PadelCheckbox',
    inheritAttrs: false,
    props: {
      label: { type: String, required: false, default: '' },
      checked: { type: Boolean, required: true },
    },
})
