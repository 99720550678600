<div class="calendar-container" :class="error? 'calendar-container-error' : ''">
    <div class="label-container" v-if="showLabel">
        <label :class="label__class + ' ' + color__class">{{label}}</label>
    </div>    
    <Calendar :inline="!showLabel" v-model="internalValue" :showIcon="true" :view="calendarViewType()"
    icon="icona" :dateFormat="dateFormat" @show="toggleLabel()" @hide="toggleLabel()"
    @month-change="onMonthChange($event)"  :selectOtherMonths="true"
    :minDate="minDate" :maxDate="maxDate" :class="calendar__class"
    :manualInput="manualInput"  />
    <div class="error-container">
        <div class="error-text">{{error? error : ''}}</div>
    </div> 
</div>
