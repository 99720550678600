
import { defineComponent } from "vue";
export default defineComponent({
  name: "PadelInput",
  props: {
    value: { type: [String, Number], required: false, default: "" },
    label: { type: String, required: false, default: "" },
    hint: { type: String, required: false, default: "" },
    icon: { type: String, required: false, default: "" },
    placeholder: { type: String, required: false, default: "" },
    color: {
      type: String,
      required: false,
      default: "map-get($colors, blue-jeans);",
    },
    error: { type: String, required: false, default: "" },
    inputType: { type: String, required: false, default: "text" },
    disabled: { type: Boolean, required: false, default: false },
    isPrice: { type: Boolean, required: false, default: false },
    googleAutocomplete: { type: Boolean, required: false, default: false },
    isSearch: { type: Boolean, required: false, default: false },
    buttonClickable: { type: Boolean, required: false, default: false },
    hideError: { type: Boolean, required: false, default: false },
    isInteger: { type: Boolean, required: false, default: false },
    isPhonenumber: { type: Boolean, required: false, default: false },
    isIntegerNumber: { type: Boolean, required: false, default: false },
    forcedPrefix: { type: String, required: false, default: null },
    autocomplete: { type: Boolean, required: false, default: false },
    isIconOutlined: { type: Boolean, required: false, default: false },
    isButtonActive: { type: Boolean, required: false, default: false },
    customButtonId: { type: String, required: false, default: undefined },
    containerClass: { type: String, required: false, default: "" },
    step: { type: String, required: false, default: ".01" },
    min: { type: Number, required: false, default: 0 },
    max: { type: Number, required: false, default: 9999 },
  },
  data() {
    return {
      focus: false,
      val: this.value,
      id: this.googleAutocomplete
        ? "autocomplete"
        : "padel-input-element-" + Math.floor(Math.random() * 100000000),
    };
  },
  computed: {
    is__password() {
      if (this.inputType === "password") {
        return "override__css__password";
      }
      return "";
    },
    has__icon() {
      if (this.icon) {
        return "input-has-icon";
      } else {
        return "";
      }
    },
    focus__border(): any {
      return {
        "background-color": this.color,
      };
    },
    get__button__color() {
      if (this.error) {
        return "color__red";
      } else {
        if (this.disabled) return "color__grey";
        if (this.focus || this.isButtonActive) {
          return "color__blue";
        } else if (this.isSearch) {
          return "color__white";
        } else {
          return "color__grey";
        }
      }
    },
    get__color() {
      if (this.error) {
        return "color__red";
      } else {
        if (this.focus) {
          return "color__blue";
        } else if (this.isSearch) {
          return "color__white";
        } else {
          return "color__grey";
        }
      }
    },
    get__color__span() {
      if (this.error) {
        return "border__color__red";
      } else {
        if (this.focus) {
          return "border__color__blue";
        } else {
          return "border__color__grey";
        }
      }
    },
    min__height() {
      if (!this.hideError) {
        return "min-height";
      } else {
        return "margin-0";
      }
    },
    is__clickable() {
      if (this.buttonClickable) {
        return "clickable";
      } else {
        return "";
      }
    },
    is__forced__prefix() {
      if (this.forcedPrefix) {
        return "forced-up";
      } else {
        return "";
      }
    },
    is__disabled() {
      if (this.disabled) {
        return "color-disabled";
      } else {
        return "";
      }
    },
    is__outlined() {
      if (this.isIconOutlined) {
        return "material-icons-outlined";
      } else {
        return "material-icons";
      }
    },
  },

  emits: [
    "input",
    "validate",
    "focus",
    "onEnterPressed",
    "buttonClicked",
    "autofilled",
    "input-id",
  ],
  mounted() {
    const input = document.getElementById(this.id);
    setTimeout(() => {
      if (input.matches(":-internal-autofill-selected")) {
        this.$emit("autofilled", true);
      }
    }, 200);
    this.$emit("input-id", this.id);
  },
  methods: {
    blurValidate(value) {
      try {
        this.$emit("validate", value);
      } catch (error) {
        console.log("validation error: ", error);
      }
      this.focus = !this.focus;
    },
    toggleFocus() {
      this.focus = !this.focus;
      this.$emit("focus", this.focus);
    },
    valueChange(value) {
      this.$emit("input", value);
    },
    showError() {
      if (this.error) {
        return this.error;
      } else {
        return " ";
      }
    },
    restrictChars(event) {
      if (this.forcedPrefix || this.isInteger) {
        if (
          event.charCode > 31 &&
          (event.charCode < 48 || event.charCode > 57)
        ) {
          event.preventDefault();
        }
      }
      if (event.charCode === 13) {
        this.$emit("onEnterPressed", event.target.value);
        document.getElementById(this.id).blur();
      }
      if (this.isPrice) {
        if (event.charCode === 45 || event.charCode === 43) {
          event.preventDefault();
        }
        const t: string = event.target.value;
        if (t.indexOf(".") >= 0) {
          if (t.substr(t.indexOf(".")).length > 2) {
            event.preventDefault();
          }
        }
      } else if (this.isPhonenumber) {
        const t: string = event.target.value;
        if (this.forcedPrefix) {
          if (t.length >= 15 - (this.forcedPrefix as string).length) {
            event.preventDefault();
          }
        } else if (t.length >= 15) {
          event.preventDefault();
        }
        if (event.charCode === 43) {
          return true;
        } else if (
          event.charCode > 31 &&
          (event.charCode < 48 || event.charCode > 57)
        ) {
          event.preventDefault();
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    emitButtonClick() {
      if (this.disabled) return;
      if (this.buttonClickable) {
        this.$emit("buttonClicked", true);
      }
    },
  },
});
