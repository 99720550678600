export enum BookingType {
  CLOSING = 'CLOSING',
  MAINTENANCE = 'MAINTENANCE',
  LESSON = 'LESSON',
  BOOKING = 'BOOKING',
  TOURNAMENT = 'TOURNAMENT',
  BACKOFFICE_BOOKING = 'BACKOFFICE_BOOKING',
  BACKOFFICE_LESSON = 'BACKOFFICE_LESSON',
  BACKOFFICE_TOURNAMENT = 'BACKOFFICE_TOURNAMENT',
  OTHER = 'OTHER',
  BACKOFFICE_BOOKING_DESCRIPTION = 'BACKOFFICE_BOOKING_DESCRIPTION',
  BACKOFFICE_LESSON_DESCRIPTION = 'BACKOFFICE_LESSON_DESCRIPTION',
  BACKOFFICE_EVENT = 'BACKOFFICE_EVENT',
  OPEN_BOOKING = 'OPEN_BOOKING',
  BACKOFFICE_OPEN_BOOKING = 'BACKOFFICE_OPEN_BOOKING',
}

export enum CalendarOpenings {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
  EVENING = 'EVENING'
}

export enum EventType {
  EVENT = 'EVENT',
  TOURNAMENT = 'TOURNAMENT',
  OTHER = 'OTHER',
}

export enum PaymentType {
  NESSUNO = 'NESSUNO',
  ISTITUTIONAL = 'TOURNAMENT',
  COMMERCIAL = 'COMMERCIAL',
}

