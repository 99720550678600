<div class="main-container justify-space-between flexbox-column padding-main-container">
    <div class="first-part">
        <div class="header display-flex">
            <img class="smash-img" src="@/theme/imgs/logo_completo.svg" alt="">
            <div class="already-have-account font-12 color-blue cursor-pointer" @click="redirect('login')">
                {{uppercase(t('already_registered'))}}
            </div>
        </div>
        <h3 class="title color-gold barlow">
            {{t('insert_phone_number')}}
        </h3>
        <p class="color-white">{{t('insert_phone_text')}}</p>
        <div class="confirm-signup-container flexbox-column">
            <PadelInput class="input" :placeholder="t('phonenumber')" :label="t('phonenumber')"
                :value="formValues.phonenumber" :error="formErrors.phonenumber"
                :forcedPrefix="FIXED_PHONE_PREFIX"
                @onEnterPressed="validatePhoneAndSendCode($event)"
                @input="updateFormValue($event, 'phonenumber')" @validate="validate($event, 'phonenumber')"
                :icon="focusOnPhone ? 'done' : 'mode'" @focus="toggleFocus($event)" />
            <PadelInput class="input" :placeholder="t('verify_code')" :label="t('verify_code')"
                :inputType="'number'" :value="formValues.code" :error="formErrors.code"
                @input="updateFormValue($event, 'code')" @validate="validate($event, 'code')"
                @onEnterPressed="validateCodeAndConfirm($event)"
                :disabled="returnCodeDisabled"  @input-id="saveCodeInputId($event)" />
            <p v-if="phoneChanged" class="send-again montserrat"
                :class="formErrors.phonenumber || !formValues.phonenumber ? 'disabled' : '' "
                @click="sendPhoneNumberAndCode()">
                {{t('send_code')}}
            </p>
            <p v-else class="send-again montserrat"
                :class="formErrors.phonenumber || !formValues.phonenumber ? 'disabled' : '' " @click="sendCode()">
                {{t('resend_code')}}
            </p>
        </div>
    </div>

    <div class="buttons-container flexbox-row justify-space-between width-100">
        <p class="font-12 color-blue cursor-pointer bold-text" @click="goBack()">{{uppercase(t('back'))}}</p>
        <PadelButton @click="confirmPhoneNumber()" :label="uppercase(t('forward'))"
            :disabled="isPhoneFormEmpty(formValues) || !isPhoneFormValid(formErrors)">
        </PadelButton>
    </div>


</div>