
/* eslint-disable */
import { useStore } from "@/store/store";
import { ActionTypes } from "@/store/action-types";
// import { MutationTypes } from "@/store/mutation-types";
import moment from "moment";
import {
  returnYesterday,
  returnTomorrow,
  returnDateAdMidnight,
  formatDateDDMMYYYY,
  formatDateMMDDYYYY,
  convertMMDDToDDMM,
  convertTimestampToDDMMYYYY
} from "@/services/dates.service";
import {
  computed,
  onMounted,
  defineComponent,
  ref,
} from "vue";
import {
  
  convertPaymentsotToArray,
  uppercase,
  
} from "@/services/mapping.service";
import { useI18n } from "vue-i18n";
import { PadelClub } from "@/models/club.models";
import { Transaction } from "@/models/transaction.models";
import { MutationTypes } from "@/store/mutation-types";
import { useRouter } from "vue-router";
import { convertArrayIntoCsv } from "@/services/files.service";
import { CardCategories } from "@/constants/card-types.enum";

export default defineComponent({
  name: "PaymentSettings",
  components: {},

  setup() {
    const { t, locale } = useI18n();
    const store = useStore();
    const router = useRouter();
    const selectedDate = ref(new Date());
    const transactions = ref(new Array<Transaction>());
    const showRefundModal= ref(false);
    const showExportModal = ref(false);
    const refundPercentage = ref(100);
    const selectedTransaction = ref(null as null | Transaction);
    const refundOptions = ref(
      [
        { value: 25, label: "" }, 
        { value: 50, label: "" }, 
        { value: 75, label: "" }, 
        { value: 100, label: "" }
      ]
    );
    const downloadCalendarForm = ref(
      JSON.parse(JSON.stringify( {
      daily_download: true,
        start_date: undefined
      }))
    );
    
    
    // TABS
    const selectedTab = ref(0);
    const showCalendar = ref(false);

    // FORM
    const alias = ref("");
    const mac = ref("");
    const paymentEnabled = ref(false);
    const forceOnlinePayment = ref(false);

    // Computed
    const club = computed(function (): PadelClub {
      return store.getters.getMyClub;
    });

    // Functions
    function onChangeTab(event) {
      selectedTab.value = event;
      if (selectedTab.value == 0) {
        // Transazioni
        fetchTransactions();
      }
      else if (selectedTab.value == 1) {
        // Storni
        fetchRefunds();
      }
      else {
        // Impostazioni
      }
    }

    // Calendar
    function toggleCalendar() {
      showCalendar.value = !showCalendar.value;
    }

    function hideCalendar() {
      showCalendar.value = false;
    }

    function changeDateWithCalendar(event: Date) {
      if (event.getTime() === selectedDate.value.getTime()) return;
      setTimeout(() => {
        hideCalendar();
      }, 1);
      
      selectedDate.value = new Date(event.getTime());
      fetchTransactions();
    }


    function goToToday() {
      selectedDate.value = new Date();
      if (selectedTab.value == 0) {
        fetchTransactions();
      }
      else if (selectedTab.value == 1) {
        fetchRefunds();
      }
    }

    function goToYesterday() {
      selectedDate.value = returnYesterday(selectedDate.value);
      if (selectedTab.value == 0) {
        fetchTransactions();
      }
      else if (selectedTab.value == 1 ){
        fetchRefunds();
      }
    }

    function goToTomorrow() {
      selectedDate.value = returnTomorrow(selectedDate.value);
      if (selectedTab.value == 0) {
        fetchTransactions();
      }
      else if (selectedTab.value == 1) {
        fetchRefunds();
      }
    }

    function updateFormDownloadCalendar(value, field) {
      downloadCalendarForm.value[field] = value;
    }

    function handleToggleDailyDownload(event) {
      updateFormDownloadCalendar(undefined, "start_date");
    }

    async function monthlyDownload(month_date: Date) {
      
      try {
        return await getCalendarEvents({
          club_id: club.value.club_id,
          start_date: formatDateMMDDYYYY(month_date),
        }).then((values) => {
          return values;
        });
      } catch (err) {
        return;
      }
    }

    async function getCalendarEvents(payload) {
      try {
        const timeslots = await store.dispatch(
          ActionTypes.GET_CALENDAR_EVENTS,
          payload
        );
        return timeslots;
      } catch (error) {
        return;
      }
    }


    function formatDate() {
      const options: Intl.DateTimeFormatOptions = {
        weekday: "long",
        month: "long",
        day: "2-digit",
        year: "numeric",
      };
      const stringDate = selectedDate.value.toLocaleDateString(
        "it-IT",
        options
      );
      return (" " + stringDate)
        .replace(/ [\w]/g, (a) => a.toLocaleUpperCase())
        .trim();
    }

    async function handleToggleEnableNexiPayment(value: boolean) {
      const resp = await store.dispatch(ActionTypes.ENABLE_ONLINE_PAYMENT, { club_id: club.value.club_id, enable: value });
      await store.dispatch(ActionTypes.MY_CLUBS, null);
    }

    async function handleToggleForceNexiPayment(value: boolean) {
      // DISPATCH
      try {
        const payload = {
          phone_number: club.value.club_phone_number,
          email: club.value.email,
          opening_hour: club.value.opening_hour,
          closing_hour: club.value.closing_hour,
          services: club.value.services,
          player_categories: club.value.player_categories,
          forewarning: club.value.forewarning,
          club_category: club.value.club_category != null ? club.value.club_category : CardCategories.INSTITUTIONAL,
          force_online_payment: value
        };
        await store.dispatch(ActionTypes.CHANGE_CLUB_INFO, {
          club_id: club.value.club_id,
          request: payload,
        });
        await store.dispatch(ActionTypes.MY_CLUBS, null);
      } catch (error) {
        console.error(error);
      }
    }

    async function fetchTransactions() {
      transactions.value = [];
      const resp = await store.dispatch(ActionTypes.FETCH_TRANSACTIONS, { club_id: club.value.club_id, refund: false, date: returnDateAdMidnight(selectedDate.value).toISOString() });
      const records = resp.data['records'];
      transactions.value = records.sort(transactionComparator);
    }

    async function fetchTransactionsByDate(date: Date) {
      const resp = await store.dispatch(ActionTypes.FETCH_TRANSACTIONS, { club_id: club.value.club_id, refund: false, date: returnDateAdMidnight(date).toISOString() });
      return resp.data['records'];
    }

    async function fetchRefundsByDate(date: Date) {
      const resp = await store.dispatch(ActionTypes.FETCH_TRANSACTIONS, { club_id: club.value.club_id, refund: true, date: returnDateAdMidnight(date).toISOString() });
      return resp.data['records'];      
    }

    async function fetchRefunds() {
      transactions.value = [];
      const resp = await store.dispatch(ActionTypes.FETCH_TRANSACTIONS, { club_id: club.value.club_id, refund: true, date: returnDateAdMidnight(selectedDate.value).toISOString() });
      const records = resp.data['records'];
      transactions.value = records.sort(transactionComparator);
    }

    async function saveNexiPaymentSettings() {
      await store.dispatch(ActionTypes.NEXI_SAVE_CONFIG, { club_id: club.value.club_id, mac: mac.value, alias: alias.value });
      await store.dispatch(ActionTypes.MY_CLUBS, null);
    }

    function transactionComparator(a, b) {
      if (a.booking.start_at < b.booking.start_at) {
        return -1;
      }
      if (b.booking.start_at < a.booking.start_at) {
        return 1;
      }
      return 0;
    }

    function initFormValues() {
      alias.value = club.value.alias != null ? club.value.alias : '';
      paymentEnabled.value = club.value.online_payment == 'ENABLED';
      forceOnlinePayment.value = club.value.force_online_payment;
      mac.value = club.value.mac != null ? club.value.mac : '';
    }

    function updateFormValue(value, field) {
      if (field == 'alias') {
        alias.value = value;
      }
      else if (field == 'mac') {
        mac.value = value;
      }
    }


    function rowFormatGameDuration(item) {
      const duration = (item.booking.end_at - item.booking.start_at) / 1000 / 60;
      return duration;
    }

    onMounted(async function () {
      initFormValues();
      fetchTransactions();
      //goToToday();
    });

    function rowFormatDate(item) {
      // INIT
      const formattedDate = moment(item.booking.date, "M/D/YYYY").format(
        "DD MMMM YYYY"
      );
      return `${formattedDate}`;
    }

    async function showRefund(transaction: Transaction) {
      selectedTransaction.value = transaction;
      refundPercentage.value = 100;

      refundOptions.value[0]['label'] =  `${((selectedTransaction.value.price * 0.25) / 100).toFixed(2)} €`;
      refundOptions.value[1]['label'] = `${((selectedTransaction.value.price * 0.5) / 100).toFixed(2)} €`;
      refundOptions.value[2]['label'] = `${((selectedTransaction.value.price * 0.75) / 100).toFixed(2)} €`;
      refundOptions.value[3]['label'] = `${((selectedTransaction.value.price) / 100).toFixed(2)} €`;
        
      showRefundModal.value = true;
    }

    async function refund() {
      showRefundModal.value = false;

      await store.dispatch(ActionTypes.REFUND_TRANSACTION, { club_id: club.value.club_id, bookingId: selectedTransaction.value.booking.id, refundPercentage: refundPercentage.value });
      fetchRefunds();
      selectedTab.value = 1;
    }

    function setRefundValue(value: number) {
      refundPercentage.value = value;
    }

    function onClickEvent(event: any) {
      const item = JSON.parse(JSON.stringify(event.booking));
      store.commit(MutationTypes.SET_SCROLL_EVENT, item);
      setTimeout(() => {
        router.push({ name: "Scheduler" });
      }, 100);
    }


    async function downloadExcel() {
      if (downloadCalendarForm.value.daily_download) {
        const events = await getCalendarEvents({
          club_id: club.value.club_id,
          date: formatDateMMDDYYYY(selectedDate.value),
        });
        
        const onlinePayments = await fetchTransactionsByDate(selectedDate.value);
        const refunds = await fetchRefundsByDate(selectedDate.value);

        convertArrayIntoCsv(
          await converPaymentsToArray(t, events, onlinePayments, refunds),
          `calendar_${formatDateDDMMYYYY(selectedDate.value)}`
        );
      } else {
        const monthEvents = await monthlyDownload(
          downloadCalendarForm.value.start_date
        );

        convertArrayIntoCsv(
          await convertMonthPaymentsToArray(monthEvents, t),
          `month_calendar_${formatDateDDMMYYYY(selectedDate.value)}`
        );
        //
        console.log("month events: ", monthEvents);
      }
      // TODO: 
      // resetDownloadCalendarForm();
      // hideModal();
    }

    async function converPaymentsToArray(t, events, onlinePayments, refunds) {
      let _provv = [];

      console.log(events);
      console.log(onlinePayments);
      console.log(refunds);
      
      const date = formatDateDDMMYYYY(selectedDate.value);
      const transformedVal = convertPaymentsotToArray(events, t, date, onlinePayments, refunds);
      _provv = _provv.concat(transformedVal);
      
      return _provv;
    }


    async function convertMonthPaymentsToArray(obj, t) {
      let _provv = [];

      let index = 0;
      for (let key in obj) {
        const entry = obj[key];
        const date = convertMMDDToDDMM(Object.keys(obj)[index]);
        const onlinePayments = await fetchTransactionsByDate(new Date(Object.keys(obj)[index]));
        const refunds = await fetchRefundsByDate(new Date(Object.keys(obj)[index]));

        const transformedVal = convertPaymentsotToArray(entry, t, date, onlinePayments, refunds);
        index += 1;
        _provv = _provv.concat(transformedVal);
      }
      
      return _provv;
    }

    function openModalDownload() {
      showExportModal.value = true;
    }

    function hideModal() {
      showExportModal.value = false;
    }


    // RETURN
    return {
      locale,
      t,
      moment,
      uppercase,

      // Variables
      alias,
      mac,
      paymentEnabled,
      forceOnlinePayment,
      
      selectedDate,
      selectedTab,
      showCalendar,
      transactions,
      showRefundModal,
      refundPercentage,
      selectedTransaction,
      refundOptions,
      downloadCalendarForm,
      showExportModal,
      

      // Functions
      toggleCalendar,
      refund,
      changeDateWithCalendar,
      formatDate,
      onChangeTab,
      goToToday,
      goToYesterday,
      goToTomorrow,
      rowFormatDate,
      rowFormatGameDuration,
      saveNexiPaymentSettings,
      updateFormValue,
      handleToggleEnableNexiPayment,
      handleToggleForceNexiPayment,
      onClickEvent,
      showRefund,
      setRefundValue,
      handleToggleDailyDownload,
      monthlyDownload,
      updateFormDownloadCalendar,
      downloadExcel,
      openModalDownload,
      hideModal
    }
  },

  

  
});


