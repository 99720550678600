<img src="@/theme/imgs/actions.svg" 
    alt="" 
    class="cursor-pointer" 
    @click.stop="toggleMenu" />
<Menu ref="menu" 
    class="dark"
    :model="items" 
    :popup="true">
    <template #item="{item}">
        <a class="p-menuitem-link" role="menuitem"
            @click="item.command">
            <img class="p-menuitem-icon" 
                :src="item.image"
                v-if="item.image" /> 
            <span class="p-menuitem-text">{{ item.label }}</span>
        </a>
    </template>
</Menu>