
import { defineComponent, PropType } from "vue";

export default defineComponent({
  data() {
    return { search: true, open: false, refresh: 0, choiceClicked: false, innerValue: this.value, valueSelected: false };
  },
  props: {
    value: { type: String, required: false, default: "" },
    error: { type: String, required: false, default: "" },
    label: { type: String, required: false, default: "Cerca nome utente..." },
    maxLength: { type: Number, required: false, default: 500 },
    options: {
      type: Array as PropType<Array<{ value: string; label: string }>>,
      required: true,
    },
    disabled: { type: Boolean, required: false, default: false },
  },
  watch: {
    value: function(newVal, oldVal) {
      this.innerValue = newVal;
    }
  },
  emits: ["onEnterPressed", "onClosePressed", "valueClicked", "validate"],
  methods: {
    resetInnerValue() {
      this.innerValue = this.value;
    },
    emitValue(event) {
      this.$emit("onEnterPressed", event);
      setTimeout(() => {
        this.open = true;
      }, 200);
    },
    changeValueSelected(value: boolean) {
      this.valueSelected = value
    },
    emitCancelButtonClicked(event) {
      this.$emit("onClosePressed", event);
      this.changeValueSelected(false);
      this.open = false;
      
    },
    emitClick(event) {
      if(this.value === this.innerValue && this.value !== null) {
        this.emitCancelButtonClicked(event)
      }
      else { 
        this.emitValue(this.innerValue);
      }
    },
    onInput(event) {
      this.innerValue = event;
      this.changeValueSelected(false);
    },
    onValidate() {
        setTimeout(()=> {
            this.open = false;
        }, 100);
        setTimeout(() => {
          if(!this.open && !this.valueSelected && !this.error) {
            this.$emit("validate", this.innerValue); 
          }
      }, 300);      
    },
    onChoice(event) {
      this.changeValueSelected(true);
      this.open = false;
      this.$emit("valueClicked", event);
    },
    onFocus() {
      this.open = true;
    },
  },
});
