import { createRouter, createWebHashHistory, RouteRecordRaw, RouterScrollBehavior } from 'vue-router'
import Scheduler from '@/views/scheduler/Scheduler.vue'
import Admin from '@/views/admin/Admin.vue'
import PadelLogin from '@/views/login/PadelLogin.vue'
import PadelSignup from '@/views/signup/PadelSignup.vue'
import PadelConfirmSignup from '@/views/confirmsignup/PadelConfirmSignup.vue'
import PadelConfirmPhone from '@/views/confirmphonenumber/PadelConfirmPhone.vue'
import DownloadContract from '@/views/download-contract/DownloadContract.vue'
import AddClub from '@/views/add-club/AddClub.vue'
import AddPlaygrounds from '@/views/add-playgrounds/AddPlaygrounds.vue'
import MyClub from '@/views/myclub/MyClub.vue'
import Stats from '@/views/stats/Stats.vue'
import Users from '@/views/users/Users.vue'
import MyProfile from '@/views/myprofile/MyProfile.vue'
import AddNewClub from '@/views/add-new-club/AddNewClub.vue'
import Tournaments from '@/views/tournaments/Tournaments.vue'
import AddPriceRule from '@/views/add-price-rule/AddPriceRule.vue'
import ConfirmBundle from '@/views/confirm-bundle/ConfirmBundle.vue'
import Scheduled from '@/views/scheduled/Scheduled.vue'
import UserDetails from '@/views/user-details/UserDetails.vue'
import CreateCard from '@/views/create-card/CreateCard.vue'
import OpenBookings from '@/views/open-bookings/OpenBookings.vue'
import OpenBookingDetail from '@/views/open-booking-detail/OpenBookingDetail.vue'
import PaymentSettings from '@/views/paymentSettings/PaymentSettings.vue'
import { useStore } from '@/store/store'
import { RoleTypes } from '@/constants/role-types.enum'
import { PadelClub } from '@/models/club.models'
import { ClubStatus } from '@/constants/club-status.enum'
import { postLoginPagesAvailable } from '@/services/routing.service'
import { isViewPostLogin } from '@/services/token-validation.service'
import { ActionTypes } from '@/store/action-types'
import { ApiModes } from '@/constants/api-modes.enum'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/scheduler',
    name: 'Scheduler',
    component: Scheduler
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/login',
    name: 'PadelLogin',
    alias: '/',
    component: PadelLogin
  },
  {
    path: '/signup',
    name: 'PadelSignup',
    component: PadelSignup
  },
  {
    path: '/confirmsignup',
    name: 'PadelConfirmSignup',
    component: PadelConfirmSignup
  },
  {
    path: '/confirm-phone-number',
    name: 'PadelConfirmPhone',
    component: PadelConfirmPhone
  },
  {
    path: '/download-contract',
    name: 'DownloadContract',
    component: DownloadContract
  },
  {
    path: '/add-club',
    name: 'AddClub',
    component: AddClub
  },
  {
    path: '/add-playgrounds',
    name: 'AddPlaygrounds',
    component: AddPlaygrounds
  },
  {
    path: '/myclub',
    name: 'MyClub',
    component: MyClub
  },
  {
    path: '/myprofile',
    name: 'MyProfile',
    component: MyProfile
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/users/:id',
    name: 'user-details',
    component: UserDetails
  },
  {
    path: '/users/:id/card',
    name: 'create-card',
    component: CreateCard
  },
  {
    path: '/users/:id/card/:card_id',
    name: 'edit-card',
    component: CreateCard
  },
  {
    path: '/stats',
    name: 'Stats',
    component: Stats
  },
  {
    path: '/add-new-club',
    name: 'AddNewClub',
    component: AddNewClub
  },
  {
    path: '/tournaments',
    name: 'Tournaments',
    component: Tournaments
  },
  {
    path: '/add-price-rule',
    name: 'add-price-rule',
    component: AddPriceRule
  },
  {
    path: '/edit-price-rule/:id',
    name: 'edit-price-rule',
    component: AddPriceRule
  },
  {
    path: '/confirm-bundle',
    name: 'confirm-bundle',
    component: ConfirmBundle
  },
  {
    path: '/scheduled/:id',
    name: 'scheduled',
    component: Scheduled
  },
  {
    path: '/open-bookings',
    name: 'open-bookings',
    component: OpenBookings
  },
  {
    path: '/open-bookings/:id',
    name: 'open-booking-detail',
    component: OpenBookingDetail
  },
  {
    path: '/payments',
    name: 'payments',
    component: PaymentSettings
  },
  {
    path: '/payments/settings',
    name: 'paymentSettings',
    component: PaymentSettings
  },
  {
    path: '/payments/:id',
    name: 'paymentDetail',
    component: PaymentSettings
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});


//ROUTE GUARDS
router.beforeEach(async (to, from, next) => {
  const store = useStore();
  const userInfo = store.getters.getUser;
  const myClub: PadelClub = store.getters.getMyClub;
  const confirmUser = store.getters.getConfirmSignupUser;
  const allowDownload = store.getters.getAllowDownloadContract;
  const apiMode = process.env.VUE_APP_IS_MOCK;

  if (isViewPostLogin(to.name as string)) {
    await store.dispatch(ActionTypes.AUTH_ACTION, null);
  }

  if (apiMode === ApiModes.SERVER) {

    // INGRESSO A PAGINA ADMIN SOLO SE ADMIN
    if (to.name === 'Admin') {
      if (userInfo.name && userInfo.groups.indexOf(RoleTypes.ADMIN) > -1) {
        next();
      } else { next("/"); }
    }
    // INGRESSO A PAGINA CONFIRMUSERNAME SOLO SE E' PRESENTE NOME USERNAME 
    else if (to.name === 'PadelConfirmSignup') {
      if (confirmUser.username) {
        next();
      } else { next("/"); }
    }
    // INGRESSO A PAGINA CONFIRMPHONE SOLO SE E' PRESENTE NOME USERNAME E PASSWORD
    else if (to.name === 'PadelConfirmPhone') {
      if (confirmUser.username && confirmUser.password) {
        next();
      } else { next("/"); }
    }
    // INGRESSO A PAGINA DOWNLOADCONTRACT SOLO SE E' VALIDO FLAG
    else if (to.name === 'DownloadContract') {
      if (allowDownload) {
        next();
      } else { next("/"); }
    }
    // INGRESSO A PAGINA ADD-CLUB SOLO SE CLUB IN STATO CREATION 
    else if (to.name === 'AddClub') {
      if (myClub.club_id && myClub.status === ClubStatus.CREATION) {
        next();
      } else { next("/"); }
    }
    // INGRESSO A PAGINA ADD-PLAYGROUNDS SOLO SE CLUB IN STATO CREATED 
    else if (to.name === 'AddPlaygrounds') {
      if (myClub.club_id && (myClub.status === ClubStatus.CREATED || myClub.status === ClubStatus.DISABLED)) {
        next();
      } else { next("/"); }
    }
    // INGRESSO A PAGINA SCHEDULER SOLO SE CLUB IN STATO DIVERSO DA CREATION 
    else if (to.name === 'Scheduler') {
      if (postLoginPagesAvailable(myClub)) {
        next();
      } else { next("/"); }
    }
    // INGRESSO A PAGINA MYCLUB SOLO SE CLUB IN STATO DIVERSO DA CREATION 
    else if (to.name === 'MyClub') {
      if (postLoginPagesAvailable(myClub)) {
        next();
      } else { next("/"); }
    }
    // INGRESSO A PAGINA MYPROFILE SOLO SE CLUB IN STATO DIVERSO DA CREATION 
    else if (to.name === 'MyProfile') {
      if (postLoginPagesAvailable(myClub)) {
        next();
      } else { next("/"); }
    }
    // INGRESSO A PAGINA STATS SOLO SE CLUB IN STATO DIVERSO DA CREATION 
    else if (to.name === 'Stats') {
      if (postLoginPagesAvailable(myClub)) {
        next();
      } else { next("/"); }
    }
    // INGRESSO A PAGINA USERS SOLO SE CLUB IN STATO DIVERSO DA CREATION 
    else if (to.name === 'Users') {
      if (postLoginPagesAvailable(myClub)) {
        next();
      } else { next("/"); }
    }
    // INGRESSO A PAGINA ADD-NEW-CLUB SOLO SE CLUB IN STATO DIVERSO DA CREATION 
    else if (to.name === 'AddNewClub') {
      if (postLoginPagesAvailable(myClub)) {
        next();
      } else { next("/"); }

    }
    // INGRESSO A PAGINA TOURNAMENTS SOLO SE CLUB IN STATO DIVERSO DA CREATION 
    else if (to.name === 'Tournaments') {
      if (postLoginPagesAvailable(myClub)) {
        next();
      } else { next("/"); }
    }
    else if (!to.name) {
      next("/");
    }
    else {
      next();
    }
  }
  else {
    next();
  }



})

export default router
