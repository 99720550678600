
import { defineComponent } from "vue";
import { doubleZeros } from '@/services/dates.service';
export default defineComponent({
  name: "PadelTimepicker",
  data() {
    return {
      selectedHour: null,
      forceSelection: true,
      hourSuggestions: [],
      hourSuggestionFiltered: [],
    };
  },
  mounted() {
    const _from = new Date(1970, 1, 1);
    const _to = new Date(1970, 1, 1);
    _from.setHours(this.from as number);
    _to.setHours(this.to as number);
    if ( (this.from as number) % 1 === 0.5) {
      _from.setMinutes(30)
    }
    if ( (this.to as number) % 1 === 0.5) {
      _to.setMinutes(30)
    }
    this.hourSuggestions = this.generateHourArray(_from, _to, this.interval as number);
    this.selectedHour = this.value;
  },
  computed: {
    get__color() {
      if (this.error) {
        return "color__red";
      } else if(this.disabled) {
        return "color__disabled"
      }else {
        return "";
      }
    },
    isError() {
      if (this.error) {
        return "error"
      } else if(this.disabled){
        return "disabled"
      }else {return ""}
    }
  },
  methods: {
    searchHour(event: string) {
      const filtered = this.hourSuggestions.filter((hour: string) => {
        return hour.toLowerCase().startsWith(event.toLowerCase());
      });
      this.hourSuggestionFiltered = filtered;
    },
    changeClass(event) {
      setTimeout(() => {
        const items = document.getElementsByClassName(
          "p-autocomplete-item"
        ) as HTMLCollectionOf<HTMLElement>;
        Array.from(items).forEach((item) => {
          const value = item.innerHTML;
          if (value === this.selectedHour) {
            item.setAttribute(
              "style",
              "color: rgb(56,168,252) !important; background-color: rgba(56,168,252,0.1)"
            );
          }
        });
        if (event === 'click') {
          (this.$refs.autocomplete as any).onDropdownClick({});
        }
      }, 0);
    },
    generateHourArray(from: Date, to: Date, interval: number) {
      const time = new Date(from);
      const intervals = [];
      const max =
        Math.abs(to.getTime() - from.getTime()) / (60 * 1000 * interval);
      for (let i = 0; i <= max; i++) {
        const hour = time.getHours();
        const minute = time.getMinutes();
        if(i === max && to.getDay() === 1) { //fix per mettere orario chiusura alle 24
          intervals.push('24:00');
        } else {intervals.push(doubleZeros(hour) + ":" + doubleZeros(minute))}
        time.setMinutes(time.getMinutes() + interval);
      }
      return intervals;
    },
    emitItem(event) {
      this.$emit("input", event);
    },
  },
  watch: {
    selectedHour: function() {
      if(!this.selectedHour) {
        this.$emit("input", '')
      }
    },
  },
  emits: ["input"],
  props: {
    disabled: {type: Boolean, required: false, default: false },
    error: { type: String, required: false, default: '' },
    label: { type: String, required: false, default: "" },
    value: { type: String, required: false, default: "" },
    from: { type: Number, required: false, default: 0 },
    to: { type: Number, required: false, default: 24 },
    interval: { type: Number, required: false, default: 30 },
    containerClass: { type: String, required: false, default: "" },
  },
});
