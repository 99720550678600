<div class="box bundle-box color-white flexbox-column"
    :class="{'unavailable': element.status === 'UNAVAILABLE'}">
    <div class="box-info-part">
        <p class="box-row margin-bottom-5">
            <div class="p-field-checkbox">
                <Checkbox :id="elementId" v-model="element.selected" :binary="true" />
                <label :for="elementId" class="color-white montserrat font-14">
                    {{ element.status === "UNAVAILABLE" ? t('playground_unavailable') : t('included_in_bundle') }}
                </label>
            </div>
        </p>

        <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-10 font-13 pre-wrap">
            {{ t('booking_type') }}: <span class="color-white p-text-normal montserrat">{{ t(element.type) }}</span>
        </p>
        <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-10 font-13 pre-wrap">
            {{ t('day_and_time') }}: <span class="color-white p-text-normal montserrat">{{ formatDateTime() }}</span>
        </p>
        <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-10 font-13 pre-wrap"
            v-if="hasPlayground || event">
            {{ t('playground') }}: <span class="color-white p-text-normal montserrat">{{ playground ? playground.label : '' }}</span>
        </p>
        <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-10 font-13 pre-wrap p-d-flex"
            v-else-if="!hasPlayground && availablePlaygrounds.length > 0">
            {{ t('playground') }}: 
            <div class="absolute-select">
                <PadelDropdown 
                    class="dropdown adjust-dropdown-alignment" 
                    :label="t('playgrounds')"
                    :options="availablePlaygrounds" 
                    @input="selectPlayground($event.value)"
                    :default="playground"
                    :static="true" />
            </div>
        </p>
        <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-10 font-13 pre-wrap p-d-flex"
            v-else>
            {{ t('playground') }}: <span class="color-white p-text-normal montserrat">{{ t('no_playgrounds_available') }}</span>
        </p>
        <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-10 font-13 pre-wrap"
            v-if="!event">
            {{ t('payment_state_label') }}: <span class="color-white p-text-normal montserrat">{{ t(element.payment_state) }}</span>
        </p>
        <div v-if="!event">
            <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-10 font-13 pre-wrap p-d-flex"
                v-if="!hasPlayground && availablePlaygrounds.length <= 0">
                {{ t('price') }}: <span class="color-white p-text-normal montserrat">{{ (element.price > 0 ? (element.price / 100) : 0) }}€</span>
            </p>
            <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-10 font-13 pre-wrap p-d-flex"
                v-else>
                {{ t('price') }}: 
                <div class="absolute-input">
                    <PadelInput :label="t('price')" 
                        :inputType="'number'" 
                        :isPrice="true"
                        :icon="'euro_symbol'" 
                        :value="price"
                        @input="setFormValue('price', $event)" 
                        :hideError="hideError"
                        containerClass="full-width static" />
                </div>
            </p>
            <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-10 font-13 pre-wrap p-d-flex">
                {{ t('taking') }}: 
                <div class="absolute-input">
                    <PadelInput :label="t('taking')" 
                        :inputType="'number'" 
                        :isPrice="true"
                        :icon="'euro_symbol'" 
                        :value="taking"
                        @input="setFormValue('taking', $event)" 
                        :hideError="hideError"
                        containerClass="full-width static" />
                </div>
            </p>
        </div>
        <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-10 font-13 pre-wrap">
            {{ t('status') }}: <span class="color-white p-text-normal montserrat">{{ t(element.status) }}</span>
        </p>
    </div>
</div>