
import moment from "moment";
import { defineComponent, ref } from "vue";

import { useI18n } from "vue-i18n";

import { uppercase } from "@/services/mapping.service";
import { store } from "@/store/store";
import { formatDateYYYYMMDD, formatISOString } from "@/services/dates.service";

import { ActionTypes } from "@/store/action-types";

import { MutationTypes } from "@/store/mutation-types";
import { BookingType } from "@/constants/booking.enum";
import { log } from "console";
import { join } from "path";



export default defineComponent({

  setup() {

    const { t, locale } = useI18n();

    const showStartAtCalendar = ref(false);
    const showEndAtCalendar = ref(false);
    // const totalRecords = ref(0);

    const startAtDate = ref(new Date());
    const endAtDate = ref(new Date());

    function convertToCsv(csvContent: string) {

      // Building the CSV from the Data two-dimensional array
      // Each column is separated by ";" and new line "\n" for next row
      // Centro, Campo, Data, Da dove, chi, ecc


      // const csvMap = {};

      // records.forEach(function (row, index) {
      //   const clubId = row['club_id'];

      //   if (csvMap[clubId] === undefined) {
      //     csvMap[clubId] = {
      //       "nomeCentro": row['club_name'] ?? '-',
      //       "confermateTotali": 0,
      //       "confermateTorneo": 0,
      //       "confermateGestionale": 0,
      //       "confermateLezioni": 0,
      //       "confermateApp":0,
      //       "confermatePartiteAperte":0,
      //       "cancellateTotali": 0,
      //       "cancellatePercentage": 0,
      //       "totali": 0,
      //     };
      //   }

      //   if (row['type'] != BookingType.CLOSING && row['type'] != BookingType.MAINTENANCE && row['type'] != BookingType.OTHER) {
      //     if (row['booking_state'] == 'CANCELLED') {
      //       csvMap[clubId]['cancellateTotali'] += 1;
      //     }
      //     else if (row['type'] == BookingType.BOOKING) {
      //       csvMap['confermateTotali'] += 1;
      //       csvMap['confermateApp'] += 1;
      //     }
      //     else if (row['type'] == BookingType.TOURNAMENT) {
      //       csvMap['confermateTotali'] += 1;
      //       csvMap['confermateTorneo'] += 1;
      //     }
      //     else if (row['type'] == BookingType.BACKOFFICE_BOOKING) {
      //       csvMap['confermateTotali'] += 1;
      //       csvMap['confermateGestionale'] += 1;
      //     }
      //     else if (row['type'] == BookingType.BACKOFFICE_LESSON) {
      //       csvMap['confermateTotali'] += 1;
      //       csvMap['confermateLezioni'] += 1;
      //     }
      //     else if (row['type'] == BookingType.BACKOFFICE_LESSON) {
      //       csvMap['confermateTotali'] += 1;
      //       csvMap['confermateGestionale'] += 1;
      //     }
      //     else if (row['type'] == BookingType.OPEN_BOOKING || row['type'] == BookingType.BACKOFFICE_OPEN_BOOKING) {
      //       csvMap['confermateTotali'] += 1;
      //       csvMap['confermatePartiteAperte'] += 1;
      //     }

      //     csvMap[clubId]['totali'] += 1;
      //   }
      // });

      

      // The download function takes a CSV string, the filename and mimeType as parameters
      // Scroll/look down at the bottom of this snippet to see how download is called
      const download = function (content, fileName, mimeType) {
        const a = document.createElement('a');
        mimeType = mimeType || 'application/octet-stream';

        if (navigator.msSaveBlob) { // IE10
          navigator.msSaveBlob(new Blob([content], {
            type: mimeType
          }), fileName);
        } else if (URL && 'download' in a) { //html5 A[download]
          a.href = URL.createObjectURL(new Blob([content], {
            type: mimeType
          }));
          a.setAttribute('download', fileName);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        } else {
          location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
        }
      }
      download(csvContent, `export_${formatDateYYYYMMDD(startAtDate.value)}_${formatDateYYYYMMDD(endAtDate.value)}`, 'text/csv;encoding:utf-8');
    }


    async function fetchData() {
      try {
        const csvContent = [];

        const dateStart = moment('2021-06-01').add(10, 'hour');
        const dateEnd = moment(new Date()).startOf('month').subtract(2, 'days').endOf('month');
        const months = [];

        while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
          const beginningOfMonth = moment(dateStart).startOf('month').format('MM/DD/YYYY');
          const endOfMonth = formatISOString(moment(dateStart).endOf('month').toDate());
          const monthString = moment(dateStart).endOf('month').format("MM/YYYY");
          
          months.push({ 
            "begin": beginningOfMonth, 
            "end": endOfMonth, 
            "monthString": monthString,
            "month": moment(dateStart).endOf('month').format("M"),
            "year": moment(dateStart).endOf('month').format("YYYY") 
          });
          dateStart.add(1, 'month');
        }

        // Intestazione -- Inizio
        let row = `Club;Online;Confermate Totali;Confermate Torneo;Confermate Gestionale;Confermate Lezioni;Confermate App;Pagate da App con CC;Confermate partite aperte;Cancellate partite aperte;Cancellate Totali;Cancellate %;Totali;;`;
        for (let monthIndex = 0; monthIndex < months.length; monthIndex++) {
          row += `${months[monthIndex]['monthString']} Confermate;${months[monthIndex]['monthString']} Cancellate;`;
        }
        row += ';Utenti con almeno 1 prenotazione;';
        csvContent.push(row);
        // Intestazzione -- Fine

               
        const clubs = await store.dispatch(ActionTypes.GET_CLUBS);
        const clubStats = {};

        for (let i = 0; i < clubs.length; i++) {
          const users = await store.dispatch(ActionTypes.GET_CUSTOMER_STATS, {
              club_id: clubs[i].club_id,
              start_date: moment(dateStart).startOf('month').format('MM/DD/YYYY')
            });
            
          console.table(users);
          
          clubStats[clubs[i].club_id] = {
            clubName: '',
            clubOnline: false,
            totalBookings: 0,    
            // Confermate
            totalConfirmed: 0,
            appConfirmed: 0,
            tournamentsConfirmed: 0,
            matchConfirmed: 0,
            backofficeConfirmed: 0,
            lessonsConfirmed: 0,
            confirmedPaidByCard: 0,
            // Cancellate
            totalCancelled: 0,
            matchCancelled: 0,
            cancelledPercentage: 0,

            userStats: users['users_with_at_least_one_booking'],

            // Dati mensili
            months: []
          }
        }

        
        for (let monthIndex = 0; monthIndex < months.length; monthIndex++) {
          const resp = await store.dispatch(ActionTypes.ADMIN_GET_USER_STATS, { month: months[monthIndex]['month'], year: months[monthIndex]['year'] });
          
          for (let j = 0; j < resp.results[0].length; j++ ) {
            const clubData = resp.results[0][j][1];


            clubStats[clubData.club_id]['clubName'] = clubData.club_name;
            clubStats[clubData.club_id]['clubOnline'] = clubData.online;
            
            clubStats[clubData.club_id]['appConfirmed'] += clubData.app_confirmed;
            clubStats[clubData.club_id]['confirmedPaidByCard'] += clubData.paid_by_card;
            clubStats[clubData.club_id]['tournamentsConfirmed'] += clubData.tournaments_confirmed;
            clubStats[clubData.club_id]['backofficeConfirmed'] += clubData.backoffice_confirmed;
            clubStats[clubData.club_id]['lessonsConfirmed'] += clubData.lessons_confirmed;
            clubStats[clubData.club_id]['matchConfirmed'] += clubData.open_games_confirmed;
            
            clubStats[clubData.club_id]['totalConfirmed'] += clubData.total_confirmed;

            clubStats[clubData.club_id]['matchCancelled'] += clubData.open_games_cancelled;
            
            const canceled = clubData.total_cancelled;
            clubStats[clubData.club_id]['totalCancelled'] += canceled;

            clubStats[clubData.club_id]['totalBookings'] = clubStats[clubData.club_id]['totalConfirmed'] + clubStats[clubData.club_id]['totalCancelled'];
            clubStats[clubData.club_id]['months'][monthIndex] = { confirmed: clubData.total_confirmed, canceled: canceled };
          }
        }


        for (let j = 0; j < clubs.length; j++) {
          const data = clubStats[clubs[j].club_id];

          csvContent.push(`${data['clubName']};${data['clubOnline'] ? 'O' : 'D'};${data['totalConfirmed']};${data['tournamentsConfirmed']};${data['backofficeConfirmed']};${data['lessonsConfirmed']};${data['appConfirmed']};${data['confirmedPaidByCard']};${data['matchConfirmed']};${data['matchCancelled']};${data['totalCancelled']};${data['totalBookings'] == 0 ? 0 : ((data['totalCancelled'] / data['totalBookings']) * 100).toFixed()}%;${data['totalBookings']};;${data['months'].map((elem) => `${elem['confirmed']};${elem['canceled']};`).join('')};${data['userStats']};`);
        }
      

        convertToCsv(csvContent.join("\n"));

      } catch (error) {
        console.error(error);
        store.commit(MutationTypes.CLOSE_LOADER);
        return;
      }
    }



    function toggleStartAtCalendar() {
      showStartAtCalendar.value = !showStartAtCalendar.value;
    }

    function toggleEndAtCalendar() {
      showEndAtCalendar.value = !showEndAtCalendar.value;
    }

    function changeStartAtDate(event: Date) {
      startAtDate.value = event;
      setTimeout(() => {
        showStartAtCalendar.value = false;
      }, 1);

    }

    function changeEndAtDate(event: Date) {
      endAtDate.value = event;
      setTimeout(() => {
        showEndAtCalendar.value = false;
      }, 1);

    }


    async function downloadExcel() {
      const results = await fetchData();
      // convertToCsv(results);
    }


    // onMounted(() => {
    //   try {
    //     // startAtDate.value = new Date();
    //     // endAtDate.value = new Date();
    //   } catch (error) {
    //     console.log(error);
    //   }

    // });


    return {
      t,
      locale,
      uppercase,

      showStartAtCalendar,
      showEndAtCalendar,
      startAtDate,
      endAtDate,


      downloadExcel,
      changeStartAtDate,
      changeEndAtDate,
      toggleStartAtCalendar,
      toggleEndAtCalendar,
    };
  },
});
