export const mockedUser = {
	"id": "e90fda1a-d693-5fe7-b09e-0066819f1fae",
	"name": "Fabrizio",
	"family_name": "Boi",
	"email": "f.boi@reply.it",
	"status": "ACTIVE",
	"sub": "26b1796e-d0e9-44a1-a9ad-8d920c71b9a1",
	"email_verified": true,
	"phone_number_verified": true,
	"phone_number": "+393409389980",
	"username": "f.boi@reply.it",
	"groups": [
		"CLUB_MANAGER"
	],
	"alias": "mock-alias",
	"favorite_clubs": [],
	"profile_pic_ref": "https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
}

export const mockedSignIn = {
	"username": "f.boi@reply.it",
	"pool": {
		"userPoolId": "eu-central-1_HnCWYAYhH",
		"clientId": "5ulfv7ujf4g3vvkimb0umqtk1c",
		"client": {
			"endpoint": "https://cognito-idp.eu-central-1.amazonaws.com/",
			"fetchOptions": {}
		},
		"advancedSecurityDataCollectionFlag": true,
		"storage": {
			"CognitoIdentityServiceProvider.5ulfv7ujf4g3vvkimb0umqtk1c.f.boi@reply.it.userData": "{\"UserAttributes\":[{\"Name\":\"sub\",\"Value\":\"3e45e298-ea30-497a-a8ec-60390adfa9db\"},{\"Name\":\"email_verified\",\"Value\":\"true\"},{\"Name\":\"phone_number_verified\",\"Value\":\"false\"},{\"Name\":\"phone_number\",\"Value\":\"+393456028079\"},{\"Name\":\"email\",\"Value\":\"f.boi@reply.it\"}],\"Username\":\"f.boi@reply.it\"}",
			"CognitoIdentityServiceProvider.5ulfv7ujf4g3vvkimb0umqtk1c.f.boi@reply.it.clockDrift": "87",
			"amplify-signin-with-hostedUI": "false",
			"CognitoIdentityServiceProvider.5ulfv7ujf4g3vvkimb0umqtk1c.f.boi@reply.it.refreshToken": "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.0S1cHike3z7Sk5KI6uDoZ6iR69cABrlZz8tlJrns6FAoYc0WG2EMVZPJVnYHm9WHJQaW9PIAqY2ox0t2uXrHe8t-ZkxlJPzhFsqUhk0D93SOetq-E5Gut79V1tWHZLkp1LXxzh2qlDBdHEAey2CnMqwAIBuARQI7y4cKEdnAg4BTX9YhjGgSvfwv9smLFx1IAGsXloMQ7ROms3cxmMOqOUNwtm9J78QPABdZM08Qq48qfp2a-18MparSApcjnvsds2NnQch8gHva9L_ffO4xBFr2eeYtbzKFWhehGRaiOZ9BbXUjp2nacQn8BrpPZEQS2bzuJrScv5Om1BGWWI3REA.ieCKfet_oJ6DRfRq.FFiOTOSWefChKCrPvwrgkofaCsEYvSWOHFn3CejmTWX0LWkk-EyV8XpfNU6VI1xyBf59zk5_6Z3S-sATReOLakUmZDf8k7qTZBHOEVLPzulCC6pnUSknhmvnk8cdon6vOjBx2bkOh4SayhFQidQ2QYYqEaFbBVF79lHZZKLug56K3Zwzj1Sxq4N5NdMgBQRMTgU-cwd_wM4bohVVRU4kL-2gMN0C23SwwDJxNMjh_ze-shvX6QVvl8jdjOwifNQRjAwx_o74u43_oHjCu__fD2-3qBeG_j6APKUMP15Wg8BAybEBd0N_JlYZgaTIAkQivfT45ft1LRu4OnLyxcnqO-Jn-2AvFu2hZMzW1EeJEbqBLaUG4MqZeqBl1ZQFStG_yth29kdUEAv93a0Y4S20HoL-Ep5E6c1X9b9C_neDLP2zujIAHyfu9n41Q1s0zsuPNrOLakKSo3rPZOergCtdZAe9fvtVCQbllrHPTreoahbmM4eLdfSrF-R899lr0i6aFLd6j840xpEfGMtC58v59fRRsEG7b-hnNek9LsnFFRZbMWSTjeiulZHSDNoIRwNW26XGqeZRxHSvWk6ji8zAwrz4pGAEh52f59TxwzkCSOl52tPSdcfn6Lb178-4S1yHFihaj_zeGK6SDTX1JagWsa6t50GQlDg6_Pmwt-6s7ny0FyXEwkgeMZoXsID0V0mHho7dh3fjopTK727BFderafHV3qTMlwH2KUvT1sNlz9uJSI19SAX5I51vL-U1NkbFqjgTFCA7Tgts4datVTqcrZ1jK4F8AhrQh5vTTbRKEIboGpmfkLE42jNgiPc7Qa6ViNwmtuWhEzvsZvMchI_6Lu4iimlSTv1seImTdRySr00SpLhHiSrW-LE7HsPO6pRfiD_lc8AVmWLWGi4U51xmjhUO70bIDjyn9pdgqyetOrZFUA6oCDjNLAn2azCgC5TSRvkgsgiwVftFUhlro602CkMD5D2X33BtTb4hAExczzsZqNlM2Q6qkgQCL3YiPQnX3PO6cc3xOu8h5mq0cCsVPYGvf04P2agLXznB4BW5-6P9VNDI4c5SuHwn4qWAvO2rlepcHyoAD2ZkarjsP8zjP4S0iL9pXpchfFhKfd21b02PgznmE5ExNVVLr5fEvj6pKPJ6hoHlUayH4fyvj_TMIzhGp1xppx59EEFz_hj7pcmr8-5Fj9rYeNMDsn_j8w4LOcLqq2uwEahR1OVahJepODABXcafrkaPYFjNJLTjDlc5J9A7JI-D0VJaZa-MJGDg0LKnuoMuuah2AidFaZ_-yOpk5ugZ2w.c9-9fjGylbjW91DnfBqfag",
			"CognitoIdentityServiceProvider.5ulfv7ujf4g3vvkimb0umqtk1c.f.boi@reply.it.accessToken": "eyJraWQiOiJXWGt5OVFNS2Zjd0dvN1A0Wkd2aVdFbjNrM3pcLzB2Q29WVUVYK25qMGJuRT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIzZTQ1ZTI5OC1lYTMwLTQ5N2EtYThlYy02MDM5MGFkZmE5ZGIiLCJjb2duaXRvOmdyb3VwcyI6WyJBRE1JTiJdLCJldmVudF9pZCI6IjM5NTc0M2MzLWM1OTEtNGVhMy05ZTI4LWZiYTIyMGYyZWIxMiIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2MTg0MDMwODcsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbVwvZXUtY2VudHJhbC0xX0huQ1dZQVloSCIsImV4cCI6MTYxODQwNjY4NywiaWF0IjoxNjE4NDAzMDg3LCJqdGkiOiI3MmMxM2MzMy0yYzZmLTQyYjUtODUwMy1jMjRiYzU0ZjcwZTAiLCJjbGllbnRfaWQiOiI1dWxmdjd1amY0ZzN2dmtpbWIwdW1xdGsxYyIsInVzZXJuYW1lIjoiZi5ib2lAcmVwbHkuaXQifQ.n5pVucdaigWDqpC2ZLsLUrMMspQyd00YFcNRjt98Udv9KPR2xe40M_3FanCfNBekxP3PKlD0gnfRx96xnRWbJKLeWrrP5fgEOHq_TQefcexU7gEf5NTq0qGNvbqSzPuwgnkPSIwp2ef5De_JEytNo--E2Cpmu0pQRZYguJvFcfJPQAxMhhnpLNoLQASiasS-Z-e2GRY0M2eGW8JzW9H8d8Z3OZRLWSVuzsZMP43ze7xbSPdnM5CE13yrEmvhhZKn1CrjqpXyO-cNowjVsmdJ-v7wNbDH8PmvEU_UR9TPUEjqL72vafdsd8Yb6umMcDLyYHVtbQGt1DgavjlzmE3jIg",
			"vuex": "{\"loader\":false,\"showErrorToast\":\"\",\"allowDownloadContract\":false,\"user\":{\"name\":\"Fabrizio\",\"surname\":\"Boi\",\"email\":\"f.boi@reply.it\",\"phonenumber\":\"+39742839742783\",\"groups\":[\"ADMIN\"],\"profile_pic_ref\":\"https://wesmash-private-images-dev.s3.eu-central-1.amazonaws.com/users/e90fda1a-d693-5fe7-b09e-0066819f1fae/picture?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3PNAZAR64U2ABEGE%2F20210414%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20210414T122442Z&X-Amz-Expires=600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJT%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDGV1LWNlbnRyYWwtMSJIMEYCIQCY0PWokLIhMqdtkeR%2BfNjU119vA9P4122%2BIEEgcjY%2B%2BQIhAMG557D%2B9rHS%2F25%2F8QFxSdRNFgWdDiW21ydkj3U%2BGSwSKvcBCO3%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMNzg5MDAwNTUzNTk3IgzkehvabSPcIQE3VbEqywGNtmnJLARihwdvsr%2B5EZRkU%2FDUtgHzuAczWn1mZq5zvsPY9rwdukF3MEb1WU38PQp17ggV1hPKTqFFZRxyAe5noymDTYt8Iz9s%2BIgT%2F361jHoOB%2F2YMmm1yD9AnaD%2BdVK1WHPwgfpNr6pHqt4S96Yfj4%2BX1OZaFmylOrXxSrBy4IU%2B6jSPJIurefX2fpez28PBQZ3mrnmwPHh72UBpRm9HXjn1zQcLYkBQFVLrLubs2bO%2Fa%2FG0vU4NE93UGBbftE5myiP%2F96l4Zf3A7DCtuNuDBjrfAe5kOFSXDeqntC6LcE82BKmqOjmslkvHrW3IhZRHdv8R4FJbQbOmP0dxlg%2BgQNWsmQ8nbs3OOC%2FWtF1EevHrKyro9SRuv0XfbPMb1OMxmEhhaB8L%2FGMwUUrFOxhPAyDA8by9W4ZeEfnW3ochyHmlvU1hMKq4Wei8x%2FQBEklYH0%2FsP7xdl4GYUsYIngqh8p1RVsU%2FCuDK4QOh%2Fg4RQBUaQCdDRIiKZw9TtmhGtPQl4PO%2Fid%2FOBuDP6vVQCP7aIMAuNANViXb78RL5J2pqPnLo81aRHJciKXDevPSobU%2F%2Ba8w%3D&X-Amz-Signature=31f14de150a9c27d6572f4c6b3417a260074d3fa925637a84e9c27370a7188f7&X-Amz-SignedHeaders=host\"},\"confirmSignupUser\":{\"username\":\"\",\"password\":\"\"},\"joinWesmashRequests\":[],\"sessionTokens\":{\"accessToken\":\"\",\"idToken\":\"\",\"refreshToken\":\"\",\"accessTokenExpiration\":null,\"idTokenExpiration\":null},\"myClubs\":[],\"myClub\":null}",
			"CognitoIdentityServiceProvider.5ulfv7ujf4g3vvkimb0umqtk1c.f.boi@reply.it.idToken": "eyJraWQiOiJnbE5zWTNQMXpEb3FWTWNBTElUYkJ3R2kwckpKTm9hbStLenJXRDFsNXVBPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIzZTQ1ZTI5OC1lYTMwLTQ5N2EtYThlYy02MDM5MGFkZmE5ZGIiLCJjb2duaXRvOmdyb3VwcyI6WyJBRE1JTiJdLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfSG5DV1lBWWhIIiwicGhvbmVfbnVtYmVyX3ZlcmlmaWVkIjpmYWxzZSwiY29nbml0bzp1c2VybmFtZSI6ImYuYm9pQHJlcGx5Lml0IiwiYXVkIjoiNXVsZnY3dWpmNGczdnZraW1iMHVtcXRrMWMiLCJldmVudF9pZCI6IjM5NTc0M2MzLWM1OTEtNGVhMy05ZTI4LWZiYTIyMGYyZWIxMiIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjE4NDAzMDg3LCJwaG9uZV9udW1iZXIiOiIrMzkzNDU2MDI4MDc5IiwiZXhwIjoxNjE4NDA2Njg3LCJpYXQiOjE2MTg0MDMwODcsImVtYWlsIjoiZi5ib2lAcmVwbHkuaXQifQ.TEjEgmfL6Koh-lKdjO3iq6WERglzRZman3CmCfT__hFnx3Dq9OEw3GYNv5-9In6cH4VF5ElKu4iev9NW2VHaeXXJ0ez1LnkXsJIAaHZK6YbkQmO-1kvI78LxjCSq7qg_YRBMRhqBSPqPRqJBILGMvnO2qaBwGDNohsgbASgSvQIF88RngVRX9zbbQfgXSgo39LhvF4Ys0R7GgpX2YWRJdu8cpeJFnTqVcFGazG9NctMY3s82vnB_7zl9Zf8l70lNJiv6ga-BLNsCxAwAd6ARRZm1V4F6HDwRNJPzoTknDTN0ddAupsG7Oe9owwhHGN_sl9uMzsgYm2NoodEDiUrrWw",
			"_grecaptcha": "09ANblmnjfqrXycnrPI3pqSXip5dckGW0aIzWgBhVyFvkO2_ihEBUJpXv6iYKnSF01YFNTvfVbzGhpj3FpEzuhY-w",
			"loglevel:webpack-dev-server": "SILENT",
			"CognitoIdentityServiceProvider.5ulfv7ujf4g3vvkimb0umqtk1c.LastAuthUser": "f.boi@reply.it"
		}
	},
	"Session": null,
	"client": {
		"endpoint": "https://cognito-idp.eu-central-1.amazonaws.com/",
		"fetchOptions": {}
	},
	"signInUserSession": {
		"idToken": {
			"jwtToken": "eyJraWQiOiJnbE5zWTNQMXpEb3FWTWNBTElUYkJ3R2kwckpKTm9hbStLenJXRDFsNXVBPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIzZTQ1ZTI5OC1lYTMwLTQ5N2EtYThlYy02MDM5MGFkZmE5ZGIiLCJjb2duaXRvOmdyb3VwcyI6WyJBRE1JTiJdLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfSG5DV1lBWWhIIiwicGhvbmVfbnVtYmVyX3ZlcmlmaWVkIjpmYWxzZSwiY29nbml0bzp1c2VybmFtZSI6ImYuYm9pQHJlcGx5Lml0IiwiYXVkIjoiNXVsZnY3dWpmNGczdnZraW1iMHVtcXRrMWMiLCJldmVudF9pZCI6IjM5NTc0M2MzLWM1OTEtNGVhMy05ZTI4LWZiYTIyMGYyZWIxMiIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjE4NDAzMDg3LCJwaG9uZV9udW1iZXIiOiIrMzkzNDU2MDI4MDc5IiwiZXhwIjoxNjE4NDA2Njg3LCJpYXQiOjE2MTg0MDMwODcsImVtYWlsIjoiZi5ib2lAcmVwbHkuaXQifQ.TEjEgmfL6Koh-lKdjO3iq6WERglzRZman3CmCfT__hFnx3Dq9OEw3GYNv5-9In6cH4VF5ElKu4iev9NW2VHaeXXJ0ez1LnkXsJIAaHZK6YbkQmO-1kvI78LxjCSq7qg_YRBMRhqBSPqPRqJBILGMvnO2qaBwGDNohsgbASgSvQIF88RngVRX9zbbQfgXSgo39LhvF4Ys0R7GgpX2YWRJdu8cpeJFnTqVcFGazG9NctMY3s82vnB_7zl9Zf8l70lNJiv6ga-BLNsCxAwAd6ARRZm1V4F6HDwRNJPzoTknDTN0ddAupsG7Oe9owwhHGN_sl9uMzsgYm2NoodEDiUrrWw",
			"payload": {
				"sub": "3e45e298-ea30-497a-a8ec-60390adfa9db",
				"cognito:groups": [
					"ADMIN"
				],
				"email_verified": true,
				"iss": "https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_HnCWYAYhH",
				"phone_number_verified": false,
				"cognito:username": "f.boi@reply.it",
				"aud": "5ulfv7ujf4g3vvkimb0umqtk1c",
				"event_id": "395743c3-c591-4ea3-9e28-fba220f2eb12",
				"token_use": "id",
				"auth_time": 1618403087,
				"phone_number": "+393456028079",
				"exp": 1618406687,
				"iat": 1618403087,
				"email": "f.boi@reply.it"
			}
		},
		"refreshToken": {
			"token": "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.0S1cHike3z7Sk5KI6uDoZ6iR69cABrlZz8tlJrns6FAoYc0WG2EMVZPJVnYHm9WHJQaW9PIAqY2ox0t2uXrHe8t-ZkxlJPzhFsqUhk0D93SOetq-E5Gut79V1tWHZLkp1LXxzh2qlDBdHEAey2CnMqwAIBuARQI7y4cKEdnAg4BTX9YhjGgSvfwv9smLFx1IAGsXloMQ7ROms3cxmMOqOUNwtm9J78QPABdZM08Qq48qfp2a-18MparSApcjnvsds2NnQch8gHva9L_ffO4xBFr2eeYtbzKFWhehGRaiOZ9BbXUjp2nacQn8BrpPZEQS2bzuJrScv5Om1BGWWI3REA.ieCKfet_oJ6DRfRq.FFiOTOSWefChKCrPvwrgkofaCsEYvSWOHFn3CejmTWX0LWkk-EyV8XpfNU6VI1xyBf59zk5_6Z3S-sATReOLakUmZDf8k7qTZBHOEVLPzulCC6pnUSknhmvnk8cdon6vOjBx2bkOh4SayhFQidQ2QYYqEaFbBVF79lHZZKLug56K3Zwzj1Sxq4N5NdMgBQRMTgU-cwd_wM4bohVVRU4kL-2gMN0C23SwwDJxNMjh_ze-shvX6QVvl8jdjOwifNQRjAwx_o74u43_oHjCu__fD2-3qBeG_j6APKUMP15Wg8BAybEBd0N_JlYZgaTIAkQivfT45ft1LRu4OnLyxcnqO-Jn-2AvFu2hZMzW1EeJEbqBLaUG4MqZeqBl1ZQFStG_yth29kdUEAv93a0Y4S20HoL-Ep5E6c1X9b9C_neDLP2zujIAHyfu9n41Q1s0zsuPNrOLakKSo3rPZOergCtdZAe9fvtVCQbllrHPTreoahbmM4eLdfSrF-R899lr0i6aFLd6j840xpEfGMtC58v59fRRsEG7b-hnNek9LsnFFRZbMWSTjeiulZHSDNoIRwNW26XGqeZRxHSvWk6ji8zAwrz4pGAEh52f59TxwzkCSOl52tPSdcfn6Lb178-4S1yHFihaj_zeGK6SDTX1JagWsa6t50GQlDg6_Pmwt-6s7ny0FyXEwkgeMZoXsID0V0mHho7dh3fjopTK727BFderafHV3qTMlwH2KUvT1sNlz9uJSI19SAX5I51vL-U1NkbFqjgTFCA7Tgts4datVTqcrZ1jK4F8AhrQh5vTTbRKEIboGpmfkLE42jNgiPc7Qa6ViNwmtuWhEzvsZvMchI_6Lu4iimlSTv1seImTdRySr00SpLhHiSrW-LE7HsPO6pRfiD_lc8AVmWLWGi4U51xmjhUO70bIDjyn9pdgqyetOrZFUA6oCDjNLAn2azCgC5TSRvkgsgiwVftFUhlro602CkMD5D2X33BtTb4hAExczzsZqNlM2Q6qkgQCL3YiPQnX3PO6cc3xOu8h5mq0cCsVPYGvf04P2agLXznB4BW5-6P9VNDI4c5SuHwn4qWAvO2rlepcHyoAD2ZkarjsP8zjP4S0iL9pXpchfFhKfd21b02PgznmE5ExNVVLr5fEvj6pKPJ6hoHlUayH4fyvj_TMIzhGp1xppx59EEFz_hj7pcmr8-5Fj9rYeNMDsn_j8w4LOcLqq2uwEahR1OVahJepODABXcafrkaPYFjNJLTjDlc5J9A7JI-D0VJaZa-MJGDg0LKnuoMuuah2AidFaZ_-yOpk5ugZ2w.c9-9fjGylbjW91DnfBqfag"
		},
		"accessToken": {
			"jwtToken": "eyJraWQiOiJXWGt5OVFNS2Zjd0dvN1A0Wkd2aVdFbjNrM3pcLzB2Q29WVUVYK25qMGJuRT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIzZTQ1ZTI5OC1lYTMwLTQ5N2EtYThlYy02MDM5MGFkZmE5ZGIiLCJjb2duaXRvOmdyb3VwcyI6WyJBRE1JTiJdLCJldmVudF9pZCI6IjM5NTc0M2MzLWM1OTEtNGVhMy05ZTI4LWZiYTIyMGYyZWIxMiIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2MTg0MDMwODcsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbVwvZXUtY2VudHJhbC0xX0huQ1dZQVloSCIsImV4cCI6MTYxODQwNjY4NywiaWF0IjoxNjE4NDAzMDg3LCJqdGkiOiI3MmMxM2MzMy0yYzZmLTQyYjUtODUwMy1jMjRiYzU0ZjcwZTAiLCJjbGllbnRfaWQiOiI1dWxmdjd1amY0ZzN2dmtpbWIwdW1xdGsxYyIsInVzZXJuYW1lIjoiZi5ib2lAcmVwbHkuaXQifQ.n5pVucdaigWDqpC2ZLsLUrMMspQyd00YFcNRjt98Udv9KPR2xe40M_3FanCfNBekxP3PKlD0gnfRx96xnRWbJKLeWrrP5fgEOHq_TQefcexU7gEf5NTq0qGNvbqSzPuwgnkPSIwp2ef5De_JEytNo--E2Cpmu0pQRZYguJvFcfJPQAxMhhnpLNoLQASiasS-Z-e2GRY0M2eGW8JzW9H8d8Z3OZRLWSVuzsZMP43ze7xbSPdnM5CE13yrEmvhhZKn1CrjqpXyO-cNowjVsmdJ-v7wNbDH8PmvEU_UR9TPUEjqL72vafdsd8Yb6umMcDLyYHVtbQGt1DgavjlzmE3jIg",
			"payload": {
				"sub": "3e45e298-ea30-497a-a8ec-60390adfa9db",
				"cognito:groups": [
					"ADMIN"
				],
				"event_id": "395743c3-c591-4ea3-9e28-fba220f2eb12",
				"token_use": "access",
				"scope": "aws.cognito.signin.user.admin",
				"auth_time": 1618403087,
				"iss": "https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_HnCWYAYhH",
				"exp": 1618406687,
				"iat": 1618403087,
				"jti": "72c13c33-2c6f-42b5-8503-c24bc54f70e0",
				"client_id": "5ulfv7ujf4g3vvkimb0umqtk1c",
				"username": "f.boi@reply.it"
			}
		},
		"clockDrift": 87,
		getIdToken() {
			return {
				getJwtToken() { return "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYW" },
				getExpiration() { return new Date().getTime() }
			}
		},
		getAccessToken() {
			return {
				getJwtToken() { return "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYW" },
				getExpiration() { return new Date().getTime() }
			}
		}
	},
	"authenticationFlowType": "USER_SRP_AUTH",
	"storage": {
		"CognitoIdentityServiceProvider.5ulfv7ujf4g3vvkimb0umqtk1c.f.boi@reply.it.userData": "{\"UserAttributes\":[{\"Name\":\"sub\",\"Value\":\"3e45e298-ea30-497a-a8ec-60390adfa9db\"},{\"Name\":\"email_verified\",\"Value\":\"true\"},{\"Name\":\"phone_number_verified\",\"Value\":\"false\"},{\"Name\":\"phone_number\",\"Value\":\"+393456028079\"},{\"Name\":\"email\",\"Value\":\"f.boi@reply.it\"}],\"Username\":\"f.boi@reply.it\"}",
		"CognitoIdentityServiceProvider.5ulfv7ujf4g3vvkimb0umqtk1c.f.boi@reply.it.clockDrift": "87",
		"amplify-signin-with-hostedUI": "false",
		"CognitoIdentityServiceProvider.5ulfv7ujf4g3vvkimb0umqtk1c.f.boi@reply.it.refreshToken": "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.0S1cHike3z7Sk5KI6uDoZ6iR69cABrlZz8tlJrns6FAoYc0WG2EMVZPJVnYHm9WHJQaW9PIAqY2ox0t2uXrHe8t-ZkxlJPzhFsqUhk0D93SOetq-E5Gut79V1tWHZLkp1LXxzh2qlDBdHEAey2CnMqwAIBuARQI7y4cKEdnAg4BTX9YhjGgSvfwv9smLFx1IAGsXloMQ7ROms3cxmMOqOUNwtm9J78QPABdZM08Qq48qfp2a-18MparSApcjnvsds2NnQch8gHva9L_ffO4xBFr2eeYtbzKFWhehGRaiOZ9BbXUjp2nacQn8BrpPZEQS2bzuJrScv5Om1BGWWI3REA.ieCKfet_oJ6DRfRq.FFiOTOSWefChKCrPvwrgkofaCsEYvSWOHFn3CejmTWX0LWkk-EyV8XpfNU6VI1xyBf59zk5_6Z3S-sATReOLakUmZDf8k7qTZBHOEVLPzulCC6pnUSknhmvnk8cdon6vOjBx2bkOh4SayhFQidQ2QYYqEaFbBVF79lHZZKLug56K3Zwzj1Sxq4N5NdMgBQRMTgU-cwd_wM4bohVVRU4kL-2gMN0C23SwwDJxNMjh_ze-shvX6QVvl8jdjOwifNQRjAwx_o74u43_oHjCu__fD2-3qBeG_j6APKUMP15Wg8BAybEBd0N_JlYZgaTIAkQivfT45ft1LRu4OnLyxcnqO-Jn-2AvFu2hZMzW1EeJEbqBLaUG4MqZeqBl1ZQFStG_yth29kdUEAv93a0Y4S20HoL-Ep5E6c1X9b9C_neDLP2zujIAHyfu9n41Q1s0zsuPNrOLakKSo3rPZOergCtdZAe9fvtVCQbllrHPTreoahbmM4eLdfSrF-R899lr0i6aFLd6j840xpEfGMtC58v59fRRsEG7b-hnNek9LsnFFRZbMWSTjeiulZHSDNoIRwNW26XGqeZRxHSvWk6ji8zAwrz4pGAEh52f59TxwzkCSOl52tPSdcfn6Lb178-4S1yHFihaj_zeGK6SDTX1JagWsa6t50GQlDg6_Pmwt-6s7ny0FyXEwkgeMZoXsID0V0mHho7dh3fjopTK727BFderafHV3qTMlwH2KUvT1sNlz9uJSI19SAX5I51vL-U1NkbFqjgTFCA7Tgts4datVTqcrZ1jK4F8AhrQh5vTTbRKEIboGpmfkLE42jNgiPc7Qa6ViNwmtuWhEzvsZvMchI_6Lu4iimlSTv1seImTdRySr00SpLhHiSrW-LE7HsPO6pRfiD_lc8AVmWLWGi4U51xmjhUO70bIDjyn9pdgqyetOrZFUA6oCDjNLAn2azCgC5TSRvkgsgiwVftFUhlro602CkMD5D2X33BtTb4hAExczzsZqNlM2Q6qkgQCL3YiPQnX3PO6cc3xOu8h5mq0cCsVPYGvf04P2agLXznB4BW5-6P9VNDI4c5SuHwn4qWAvO2rlepcHyoAD2ZkarjsP8zjP4S0iL9pXpchfFhKfd21b02PgznmE5ExNVVLr5fEvj6pKPJ6hoHlUayH4fyvj_TMIzhGp1xppx59EEFz_hj7pcmr8-5Fj9rYeNMDsn_j8w4LOcLqq2uwEahR1OVahJepODABXcafrkaPYFjNJLTjDlc5J9A7JI-D0VJaZa-MJGDg0LKnuoMuuah2AidFaZ_-yOpk5ugZ2w.c9-9fjGylbjW91DnfBqfag",
		"CognitoIdentityServiceProvider.5ulfv7ujf4g3vvkimb0umqtk1c.f.boi@reply.it.accessToken": "eyJraWQiOiJXWGt5OVFNS2Zjd0dvN1A0Wkd2aVdFbjNrM3pcLzB2Q29WVUVYK25qMGJuRT0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIzZTQ1ZTI5OC1lYTMwLTQ5N2EtYThlYy02MDM5MGFkZmE5ZGIiLCJjb2duaXRvOmdyb3VwcyI6WyJBRE1JTiJdLCJldmVudF9pZCI6IjM5NTc0M2MzLWM1OTEtNGVhMy05ZTI4LWZiYTIyMGYyZWIxMiIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2MTg0MDMwODcsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbVwvZXUtY2VudHJhbC0xX0huQ1dZQVloSCIsImV4cCI6MTYxODQwNjY4NywiaWF0IjoxNjE4NDAzMDg3LCJqdGkiOiI3MmMxM2MzMy0yYzZmLTQyYjUtODUwMy1jMjRiYzU0ZjcwZTAiLCJjbGllbnRfaWQiOiI1dWxmdjd1amY0ZzN2dmtpbWIwdW1xdGsxYyIsInVzZXJuYW1lIjoiZi5ib2lAcmVwbHkuaXQifQ.n5pVucdaigWDqpC2ZLsLUrMMspQyd00YFcNRjt98Udv9KPR2xe40M_3FanCfNBekxP3PKlD0gnfRx96xnRWbJKLeWrrP5fgEOHq_TQefcexU7gEf5NTq0qGNvbqSzPuwgnkPSIwp2ef5De_JEytNo--E2Cpmu0pQRZYguJvFcfJPQAxMhhnpLNoLQASiasS-Z-e2GRY0M2eGW8JzW9H8d8Z3OZRLWSVuzsZMP43ze7xbSPdnM5CE13yrEmvhhZKn1CrjqpXyO-cNowjVsmdJ-v7wNbDH8PmvEU_UR9TPUEjqL72vafdsd8Yb6umMcDLyYHVtbQGt1DgavjlzmE3jIg",
		"vuex": "{\"loader\":false,\"showErrorToast\":\"\",\"allowDownloadContract\":false,\"user\":{\"name\":\"Fabrizio\",\"surname\":\"Boi\",\"email\":\"f.boi@reply.it\",\"phonenumber\":\"+39742839742783\",\"groups\":[\"ADMIN\"],\"profile_pic_ref\":\"https://wesmash-private-images-dev.s3.eu-central-1.amazonaws.com/users/e90fda1a-d693-5fe7-b09e-0066819f1fae/picture?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIA3PNAZAR64U2ABEGE%2F20210414%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20210414T122442Z&X-Amz-Expires=600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEJT%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaDGV1LWNlbnRyYWwtMSJIMEYCIQCY0PWokLIhMqdtkeR%2BfNjU119vA9P4122%2BIEEgcjY%2B%2BQIhAMG557D%2B9rHS%2F25%2F8QFxSdRNFgWdDiW21ydkj3U%2BGSwSKvcBCO3%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMNzg5MDAwNTUzNTk3IgzkehvabSPcIQE3VbEqywGNtmnJLARihwdvsr%2B5EZRkU%2FDUtgHzuAczWn1mZq5zvsPY9rwdukF3MEb1WU38PQp17ggV1hPKTqFFZRxyAe5noymDTYt8Iz9s%2BIgT%2F361jHoOB%2F2YMmm1yD9AnaD%2BdVK1WHPwgfpNr6pHqt4S96Yfj4%2BX1OZaFmylOrXxSrBy4IU%2B6jSPJIurefX2fpez28PBQZ3mrnmwPHh72UBpRm9HXjn1zQcLYkBQFVLrLubs2bO%2Fa%2FG0vU4NE93UGBbftE5myiP%2F96l4Zf3A7DCtuNuDBjrfAe5kOFSXDeqntC6LcE82BKmqOjmslkvHrW3IhZRHdv8R4FJbQbOmP0dxlg%2BgQNWsmQ8nbs3OOC%2FWtF1EevHrKyro9SRuv0XfbPMb1OMxmEhhaB8L%2FGMwUUrFOxhPAyDA8by9W4ZeEfnW3ochyHmlvU1hMKq4Wei8x%2FQBEklYH0%2FsP7xdl4GYUsYIngqh8p1RVsU%2FCuDK4QOh%2Fg4RQBUaQCdDRIiKZw9TtmhGtPQl4PO%2Fid%2FOBuDP6vVQCP7aIMAuNANViXb78RL5J2pqPnLo81aRHJciKXDevPSobU%2F%2Ba8w%3D&X-Amz-Signature=31f14de150a9c27d6572f4c6b3417a260074d3fa925637a84e9c27370a7188f7&X-Amz-SignedHeaders=host\"},\"confirmSignupUser\":{\"username\":\"\",\"password\":\"\"},\"joinWesmashRequests\":[],\"sessionTokens\":{\"accessToken\":\"\",\"idToken\":\"\",\"refreshToken\":\"\",\"accessTokenExpiration\":null,\"idTokenExpiration\":null},\"myClubs\":[],\"myClub\":null}",
		"CognitoIdentityServiceProvider.5ulfv7ujf4g3vvkimb0umqtk1c.f.boi@reply.it.idToken": "eyJraWQiOiJnbE5zWTNQMXpEb3FWTWNBTElUYkJ3R2kwckpKTm9hbStLenJXRDFsNXVBPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiIzZTQ1ZTI5OC1lYTMwLTQ5N2EtYThlYy02MDM5MGFkZmE5ZGIiLCJjb2duaXRvOmdyb3VwcyI6WyJBRE1JTiJdLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfSG5DV1lBWWhIIiwicGhvbmVfbnVtYmVyX3ZlcmlmaWVkIjpmYWxzZSwiY29nbml0bzp1c2VybmFtZSI6ImYuYm9pQHJlcGx5Lml0IiwiYXVkIjoiNXVsZnY3dWpmNGczdnZraW1iMHVtcXRrMWMiLCJldmVudF9pZCI6IjM5NTc0M2MzLWM1OTEtNGVhMy05ZTI4LWZiYTIyMGYyZWIxMiIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjE4NDAzMDg3LCJwaG9uZV9udW1iZXIiOiIrMzkzNDU2MDI4MDc5IiwiZXhwIjoxNjE4NDA2Njg3LCJpYXQiOjE2MTg0MDMwODcsImVtYWlsIjoiZi5ib2lAcmVwbHkuaXQifQ.TEjEgmfL6Koh-lKdjO3iq6WERglzRZman3CmCfT__hFnx3Dq9OEw3GYNv5-9In6cH4VF5ElKu4iev9NW2VHaeXXJ0ez1LnkXsJIAaHZK6YbkQmO-1kvI78LxjCSq7qg_YRBMRhqBSPqPRqJBILGMvnO2qaBwGDNohsgbASgSvQIF88RngVRX9zbbQfgXSgo39LhvF4Ys0R7GgpX2YWRJdu8cpeJFnTqVcFGazG9NctMY3s82vnB_7zl9Zf8l70lNJiv6ga-BLNsCxAwAd6ARRZm1V4F6HDwRNJPzoTknDTN0ddAupsG7Oe9owwhHGN_sl9uMzsgYm2NoodEDiUrrWw",
		"_grecaptcha": "09ANblmnjfqrXycnrPI3pqSXip5dckGW0aIzWgBhVyFvkO2_ihEBUJpXv6iYKnSF01YFNTvfVbzGhpj3FpEzuhY-w",
		"loglevel:webpack-dev-server": "SILENT",
		"CognitoIdentityServiceProvider.5ulfv7ujf4g3vvkimb0umqtk1c.LastAuthUser": "f.boi@reply.it"
	},
	"keyPrefix": "CognitoIdentityServiceProvider.5ulfv7ujf4g3vvkimb0umqtk1c",
	"userDataKey": "CognitoIdentityServiceProvider.5ulfv7ujf4g3vvkimb0umqtk1c.f.boi@reply.it.userData",
	"attributes": {
		"sub": "3e45e298-ea30-497a-a8ec-60390adfa9db",
		"email_verified": true,
		"phone_number_verified": false,
		"phone_number": "+393456028079",
		"email": "f.boi@reply.it"
	},
	"preferredMFA": "NOMFA"
}

export const mockedRequests = {
	"values": [
		{
			"date": "2021-03-26T13:46:38.814Z",
			"requester_email": "f.boi@reply.it",
			"club_id": "b05da7e0-8e39-11eb-8f10-7b35a48159c2",
			"club_name": "Padel1",
			"club_address": {
				"address": "55049 Viareggio con un indirizzo così lungo che non si priesce a far vedere in un unica riga, Italia",
				"state": "Italia",
				"city": "Viareggio",
				"zip_code": "55049",
				"latitude": 43.8657267,
				"longitude": 10.2513103
			},
			"status": "PENDING"
		},
		{
			"date": "2021-03-26T14:13:58.527Z",
			"requester_email": "f.boi@reply.it",
			"club_id": "81b600f0-8e3d-11eb-883a-cb2aa45e6fc4",
			"club_name": "Padel 1",
			"club_address": {
				"address": "36100 Vicenza VI, 24, Italia",
				"state": "Italia",
				"city": "Vicenza",
				"zip_code": "36100",
				"latitude": 45.5454787,
				"longitude": 11.5354214
			},
			"status": "REJECTED"
		},
		{
			"date": "2021-03-26T14:13:58.527Z",
			"requester_email": "f.boi@reply.it",
			"club_id": "81b600f0-8e3d-11eb-883a-cb2aa45e6fc4",
			"club_name": "Padel 1",
			"club_address": {
				"address": "36100 Vicenza VI, 24, Italia",
				"state": "Italia",
				"city": "Vicenza",
				"zip_code": "36100",
				"latitude": 45.5454787,
				"longitude": 11.5354214
			},
			"status": "REJECTED"
		},
		{
			"date": "2021-03-26T14:13:58.527Z",
			"requester_email": "f.boi@reply.it",
			"club_id": "81b600f0-8e3d-11eb-883a-cb2aa45e6fc4",
			"club_name": "Padel 1",
			"club_address": {
				"address": "36100 Vicenza VI, 24, Italia",
				"state": "Italia",
				"city": "Vicenza",
				"zip_code": "36100",
				"latitude": 45.5454787,
				"longitude": 11.5354214
			},
			"status": "REJECTED"
		},
		{
			"date": "2021-03-26T14:13:58.527Z",
			"requester_email": "f.boi@reply.it",
			"club_id": "81b600f0-8e3d-11eb-883a-cb2aa45e6fc4",
			"club_name": "Padel 1",
			"club_address": {
				"address": "36100 Vicenza VI, 24, Italia",
				"state": "Italia",
				"city": "Vicenza",
				"zip_code": "36100",
				"latitude": 45.5454787,
				"longitude": 11.5354214
			},
			"status": "REJECTED"
		},
		{
			"date": "2021-03-26T14:13:58.527Z",
			"requester_email": "f.boi@reply.it",
			"club_id": "81b600f0-8e3d-11eb-883a-cb2aa45e6fc4",
			"club_name": "Padel 1",
			"club_address": {
				"address": "36100 Vicenza VI, 24, Italia",
				"state": "Italia",
				"city": "Vicenza",
				"zip_code": "36100",
				"latitude": 45.5454787,
				"longitude": 11.5354214
			},
			"status": "REJECTED"
		},
		{
			"date": "2021-03-26T14:13:58.527Z",
			"requester_email": "f.boi@reply.it",
			"club_id": "81b600f0-8e3d-11eb-883a-cb2aa45e6fc4",
			"club_name": "Padel 1",
			"club_address": {
				"address": "36100 Vicenza VI, 24, Italia",
				"state": "Italia",
				"city": "Vicenza",
				"zip_code": "36100",
				"latitude": 45.5454787,
				"longitude": 11.5354214
			},
			"status": "REJECTED"
		}
	],
	"last_evaluated_key": "jsgfs"
}

export const mockedUserInfo = {
	"id": "e90fda1a-d693-5fe7-b09e-0066819f1fae",
	"name": "Fabrizio",
	"family_name": "Boi",
	"phone_number": "+390000000000",
	"email": "f.boi@reply.it",
	"status": "ACTIVE"
}

export const mockedChangeRequestStatus = {
	"date": "2021-03-26T14:13:58.527Z",
	"requester_email": "f.boi@reply.it",
	"club_id": "81b600f0-8e3d-11eb-883a-cb2aa45e6fc4",
	"club_name": "Padel 1",
	"club_address": {
		"address": "36100 Vicenza VI, Italia",
		"state": "Italia",
		"city": "Vicenza",
		"zip_code": "36100",
		"latitude": 45.5454787,
		"longitude": 11.5354214
	},
	"status": "REJECTED"
}

export const mockedCurrentSession = {
	"AuthenticationResult": {
		"AccessToken": "eyJraWQiOiJ4YlFCS1FRVFZSdDRHbXNTXC9HYm1HMU53VWgwWm9ueXpxU1M5RktiNzE1cz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI1NGY4YzY0ZC01OWJjLTRjZGQtOTUzNi00ZjljNzZjZWM3MGUiLCJldmVudF9pZCI6IjQwY2Y2ZDA2LWE4MGMtNDhlOC05NWViLThhMjIwMmNmOGI3NSIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoiYXdzLmNvZ25pdG8uc2lnbmluLnVzZXIuYWRtaW4iLCJhdXRoX3RpbWUiOjE2MTM3MjM0OTIsImlzcyI6Imh0dHBzOlwvXC9jb2duaXRvLWlkcC5ldS1jZW50cmFsLTEuYW1hem9uYXdzLmNvbVwvZXUtY2VudHJhbC0xXzRTRnNkZ3hKbiIsImV4cCI6MTYxMzcyNzA5MiwiaWF0IjoxNjEzNzIzNDkyLCJqdGkiOiIxMDc3OTMwMC04ZjMzLTQwYTgtYjFjMy0xMDIzOTE5ZDgzN2IiLCJjbGllbnRfaWQiOiI1ajNmZm0ydjhmOWJ0NnNtdDJjN2J0NmI5ZCIsInVzZXJuYW1lIjoiZi5ib2lAcmVwbHkuaXQifQ.ajCcMuutkmJIqDPphn1Mn3fYG6471hpyLRzoIIvjOVBLmsu0L_vVPt7NmJFO9casSxP24fAFnaA2FI_onIMkfyi1hIWV0M-L4KjjjmVsqVn24dCSBfLYPVLKCVZwN-OzcSL4HxiQJpC3l78ON-Lh8cD5dFKznFkWXA4yr-ginqCG-4YDT-YcKdS-TVF0EZ-kKl_sUuVZ_AGKZcXaisjh1ctoVUxEuG2oh_Jos6mzwBAT4hLhX_oyFITaAUKiu0k6vELtJujD025wfDFi-zKGzZ7gFnzGyt0xy8Zq_7CCix7cFTt5xUsP3IscfltP7Dm9W5b-HCttMtaCPgNXj1L-Pw",
		"ExpiresIn": 3600,
		"IdToken": "eyJraWQiOiJTK1U3VHpcL0FlN3d0V0VCRUdhbExHclh3UG5jOG1rUEtuVE12b2xzekpJdz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiI1NGY4YzY0ZC01OWJjLTRjZGQtOTUzNi00ZjljNzZjZWM3MGUiLCJhdWQiOiI1ajNmZm0ydjhmOWJ0NnNtdDJjN2J0NmI5ZCIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJldmVudF9pZCI6IjQwY2Y2ZDA2LWE4MGMtNDhlOC05NWViLThhMjIwMmNmOGI3NSIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjEzNzIzNDkyLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAuZXUtY2VudHJhbC0xLmFtYXpvbmF3cy5jb21cL2V1LWNlbnRyYWwtMV80U0ZzZGd4Sm4iLCJuaWNrbmFtZSI6ImZhYnJpemlvIiwiY29nbml0bzp1c2VybmFtZSI6ImYuYm9pQHJlcGx5Lml0IiwiZXhwIjoxNjEzNzI3MDkyLCJpYXQiOjE2MTM3MjM0OTIsImVtYWlsIjoiZi5ib2lAcmVwbHkuaXQifQ.dvm4elGcT8VJzv9K8d0rHdaW1uyu3GzPyf6AB7cvF0gCDvOyKCRhqC_hAreABymKP_10vIbVrYEZrU5UNHOCYjAnYYhpzvxIm6YelCnoCV-6usCdYd2Po21euZgkjUiiuwrXSsR5VukTfx_KHfvLpGpricYnESqDeRgNWkaWiIa8Ei7-pb2Y3HMBp7BUHqoyWu2OUql7kluZt-4d7O4b1CS2NZVcnGH0oQW_yPaF4XG346YJZ1Tuxfl7MZpnTyC1JZ0IHnARg2eLaViqSJKefeqIJsXi0OWiKJUtK8boj5CCAOeyK8_giUHZ16n3oXS9bryjp9JCJHItcHT1hK57_Q",
		"RefreshToken": "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYWxnIjoiUlNBLU9BRVAifQ.FrIDKDhBDeriR6p1HSTIWcPX8ua_qBGsE5pfQy9XY4Ik02BtiW-kLrt4lrvk_EmFoJ1VDURGxv5iLuWY673cY4JFEa-7-u3PdoZA2kaMAGVe9POQ8nAS8hQZ560WXT_j3ZXV_ZO-F8Eh9zbWS8u3c5KTvJ8tAbG_lu_PNy2g2BdbprZrT2uxeS5yWaOVJtxEJTCAKzv8LMdaDcXGkXgF4Az2K4ljLV5dt966ncfFHjXjQsa5UmAkcdLrJP52ET754Hspqqcu7KIm00LLgmzy_gVy4NnJiUUDE4z44-eUMhEXPfR_mnWGUv10mgDfMK5D_ACMJNfh1LlpyVhdSAfrdQ.j588f7YOsDTUEFya.sTIUyaKgqUE8MeGxT9glGgRfhd9jyfQUKzFV-a1XoV5l9XWyKHJachcy0VfN_TOZ5JR35-p6CLr39865FDfT1vqJHHVzaJeLKZMPPSOzjgexSi4_Z1LMLaUlN3fo4icHQsO43J1Qt-QFW9lIonQlAKWbw_E7gvch7fHNT9NTtio5B_NXLHPy5NGypxm2LbrvZW0vqw5PBSS7kFqrXLy1b0R_ylm8eDStYYLmXiK49eqBcNEltcx3pauhg_uqWf5thpKd0KhTrXWkFmv2IOuRECgqxT2ww404fCM7_lXUQ-82KmRphTCwTCg4LIlRGXqwuP9_RUxASpHsMxhLbNsJC0l0ZgIcUjgCzm15KHXFUx4HJIcL864NDHwISzXKNIJAYmQBFMxRAuQ4psELKdojs9HmVYWBAnw48P3ifXKamEQuLLS-ebxvAcFHCxOz8enu2kmjepks1seJR1lJ5D4U71RWAQm4jgRz6BCuRjnc7BqIKTZuo3qgQPuyfMURaIilsYKECZ5ncTz9VnABUa4shPaMdwzDZ1CeiTDSSbKpuiCbIRfP9atsGCMVf_HsyDEukPSuFuCCfXK4OV3zFUFc9tU2HPkbcDkLMsAqguxg8z--IaHFdXeD8sjfU5AJIhqAJOPTVKzl23hMkuM-N9g0OQXNCtDigUZLWqktL28B8YIHFxizabrP-tGHeb741aV67o1ktYLxs1BXnF83K5VhksNZx7EeAJjqcT27siVZNWztRqi9AYWamzTSiVS2keHl3H043iEVNf2LVxANlT7BF8r5c8AibAo0jq62l5vFuh06KwDIrGnFvkAkjmycm3bNDv4VSL-YHGyJqOuFFd5SPK3KZghPoCnB0dviCVxRthGo_LqjhbEzD0355uNZWyD00mKbjGhGljlwmqy7HD_HZwusk4SM9zGoN1dGoZXnIxMaPDRPWPhrcHxbEKpC1YDANmx3H2lpIWcRkmPXpQPeSCPeJt1-UeqCe_hvNFpUgu23KV_1nnhonyQFzJxsY9a351H-eFW3hSsYGAnEvp_8c1GQk3ugNM673K_zh1EA313y_DgH6US3wccCp-3nlAb6y73lRs5ynkhYnEXGnDr6_kEhqabB5l6mefRbpcrDrGxnBe02bfPVzCRSb7yZyg7t3dE_UD1oSzMOF5QI3s5VTNEoGEOB5kimxc4UOpFCz-rttQLmUk_UEw6MnwywXAyn7BY0TUmIDcHtZROZ8XtegpsK20oliW2Ry0HwGGKrmz7e3L9MraW_je-TaFSjoKhf-aBTLotOPPs8MJH1JrThGIBOqKMiVB4.gmhJfYihrtODuhu0YrkyPQ",
		"TokenType": "Bearer"
	},
	"idToken": { "jwtToken": 'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYW' },
	"ChallengeParameters": {},
	getIdToken() {
		return {
			getJwtToken() { return "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYW" },
			getExpiration() { return new Date().getTime() }
		}
	},
	getAccessToken() {
		return {
			getJwtToken() { return "eyJjdHkiOiJKV1QiLCJlbmMiOiJBMjU2R0NNIiwiYW" },
			getExpiration() { return new Date().getTime() }
		}
	}
}

export const mockedClubsCreation = [
	{
		"club_id": "c17b65e0-922f-11eb-ae9f-2ba49d5513af",
		"name": "Centro In Creazione",
		"club_phone_number": '6472638',
		"opening_hour": 30600000,
		"closing_hour": 82800000,
		"location": {
			"address": "Via Don Gavino Lai, 08100 Nuoro NU, Italia",
			"city": "Nuoro",
			"state": "Italia",
			"zip_code": "08100",
			"latitude": 40.3223208,
			"longitude": 9.3033017
		},
		"services": [],
		"status": "CREATION",
		"club_notes": ""
	},
	{
		"club_id": "c17b65e0-922f-11eb-ae9f-2ba49d551323",
		"name": "Centro Creato",
		"club_phone_number": '6472638',
		"opening_hour": 1800000,
		"closing_hour": 46800000,
		"email": "email-prova@reply.it", 
		"location": {
			"address": "Via Don Gavino Lai, 08100 Nuoro NU, Italia",
			"city": "Nuoro",
			"state": "Italia",
			"zip_code": "08100",
			"latitude": 40.3223208,
			"longitude": 9.3033017
		},
		"services": [{ "type": "LOCKER_ROOM" }, { "type": "RENTAL" }, {"type": "custom", "customName": "SWIMMING_POOL"}],
		"status": "OPERATIVE",
		"cover_pic_ref": "https://thumbs.dreamstime.com/b/black-padel-racket-balls-black-padel-racket-balls-court-150938775.jpg",
		"club_notes": "Note di prova"
	},

]


/* 
export const mockedClubCreation = {
	"conflicts": [
		{
			"playground_id": "b0074260-9ebf-11eb-a796-3d903eb8c2ec",
			"start_hour": 0,
			"end_hour": 0,
			"playground_name": "prova 1 con un nome così tanto lungo che spacca proprio tutto sia le chips che"
		},
		{
			"playground_id": "b0074260-9ebf-11eb-a796-3d903eb8c2ec",
			"start_hour": 0,
			"end_hour": 0,
			"playground_name": "prova 1 con un nome così tanto lungo che spacca proprio tutto sia le chips che"
		},
		{
			"playground_id": "b0074260-9ebf-11eb-a796-3d903eb8c2ec",
			"start_hour": 0,
			"end_hour": 0,
			"playground_name": "prova 1 con un nome così tanto lungo che spacca proprio tutto sia le chips che"
		},
		{
			"playground_id": "b0074260-9ebf-11eb-a796-3d903eb8c2ec",
			"start_hour": 0,
			"end_hour": 0,
			"playground_name": "prova 1 con un nome così tanto lungo che spacca proprio tutto sia le chips che"
		}
	]
} */

export const mockedClubCreation = {
	"club_id": "c17b65e0-922f-11eb-ae9f-2ba49d5513af",
	"name": "Centro In Creazione",
	"location": {
		"address": "Via Don Gavino Lai, 08100 Nuoro NU, Italia",
		"city": "Nuoro",
		"state": "Italia",
		"zip_code": "08100",
		"latitude": 40.3223208,
		"longitude": 9.3033017
	},
	"services": [],
	"status": "CREATION"
}

export const emptyClubs = []

export const mockedGetPlaygrounds = {
	"playgrounds": [
		{
			"id": "b0074260-9ebf-11eb-a796-3d903eb8c2ec",
			"club_id": "c210cc10-96e5-11eb-92fc-df359eaab90e",
			"name": "prova 1 con un nome così tanto lungo che spacca proprio tutto sia le chips che",
			"exposure": "OUTDOOR",
			"playground_size": "DOUBLE",
			"half_hour_price": 500,
			"price_for_duration": [
				{ "duration": 90, "price": 2000 }
			],
			"sport": "PADEL",
			"bookable": true
		},
		{
			"id": "b0074260-9ebf-11eb-a796-3d9gdf03eb8c2ec",
			"club_id": "c210cc10-96e5-11eb-92fc-df359eaab90e",
			"name": "prova 2",
			"exposure": "OUTDOOR",
			"playground_size": "DOUBLE",
			"half_hour_price": 5,
			"price_for_duration": [
				{ "duration": 90, "price": 2000 }
			],
			"sport": "PADEL",
			"bookable": true,
			"picture_ref": "https://wesmash-public-images-dev.s3.amazonaws.com/clubs/c210cc10-96e5-11eb-92fc-df359eaab90e/playgrounds/b0074260-9ebf-11eb-a796-3d903eb8c2ec/picture"
		},
		{
			"id": "b0074260-9ebf-11eb-a796-3d9gdf03eb8c2ecA",
			"club_id": "c210cc10-96e5-11eb-92fc-df359eaab90e",
			"name": "prova 2",
			"exposure": "OUTDOOR",
			"playground_size": "DOUBLE",
			"half_hour_price": 5,
			"price_for_duration": [
				{ "duration": 90, "price": 2000 }
			],
			"sport": "PADEL",
			"bookable": true,
			"picture_ref": "https://wesmash-public-images-dev.s3.amazonaws.com/clubs/c210cc10-96e5-11eb-92fc-df359eaab90e/playgrounds/b0074260-9ebf-11eb-a796-3d903eb8c2ec/picture"
		},
		{
			"id": "b0074260-9ebf-11eb-a796-3d9gdf03eb8c2ecAB",
			"club_id": "c210cc10-96e5-11eb-92fc-df359eaab90e",
			"name": "prova 3",
			"exposure": "OUTDOOR",
			"playground_size": "DOUBLE",
			"half_hour_price": 5,
			"price_for_duration": [
				{ "duration": 90, "price": 2000 }
			],
			"sport": "PADEL",
			"bookable": true,
			"picture_ref": "https://wesmash-public-images-dev.s3.amazonaws.com/clubs/c210cc10-96e5-11eb-92fc-df359eaab90e/playgrounds/b0074260-9ebf-11eb-a796-3d903eb8c2ec/picture"
		},
		{
			"status": "DELETED",
			"id": "b0074260-9ebf-11eb-a796-3d9gdf03eb8c2ecAB",
			"club_id": "c210cc10-96e5-11eb-92fc-df359eaab90e",
			"name": "campo eliminato",
			"exposure": "OUTDOOR",
			"playground_size": "DOUBLE",
			"half_hour_price": 5,
			"price_for_duration": [
				{ "duration": 90, "price": 2000 }
			],
			"sport": "PADEL",
			"bookable": true,
			"picture_ref": "https://wesmash-public-images-dev.s3.amazonaws.com/clubs/c210cc10-96e5-11eb-92fc-df359eaab90e/playgrounds/b0074260-9ebf-11eb-a796-3d903eb8c2ec/picture"
		}]
}


export const mockedTimeslots = [
	{
		"id": "00021",
		"playground_id": "b0074260-9ebf-11eb-a796-3d903eb8c2ec",
		"player_name": "Abramo",
		"player_family_name": "Lincoln",
		"date": "1/21/2021",
		"price": 200,
		"start_at": 1620720000000,
		"end_at": 1620723600000,
		"title": "Abramo Lincoln",
		"type": "BACKOFFICE_BOOKING",
	},
	{
		"id": "00022",
		"playground_id": "b0074260-9ebf-11eb-a796-3d9gdf03eb8c2ecA",
		"player_name": "Giuseppe",
		"player_family_name": "Torpedine",
		"player_email": "signor.torpedine@gmail.com",
		"date": "01/21/2021",
		"price": 200,
		"start_at": 1621287000000,
		"end_at": 1621290600000,
		"title": "Giuseppe Torpedine",
		"type": "BOOKING",
	},
	{
		"id": "00022",
		"playground_id": "b0074260-9ebf-11eb-a796-3d9gdf03eb8c2ec",
		"date": "21/1/2021",
		"start_at": 1622109600000,
		"end_at": 1622113200000,
		"title": "Chiusura - 1",
		"type": "CLOSING",
	},
	{
		"id": "00022",
		"playground_id": "b0074260-9ebf-11eb-a796-3d9gdf03eb8c2ecAB",
		"date": "1/21/2021",
		"start_at": 1622109600000,
		"end_at": 1622113200000,
		"title": "manutenzione - 2",
		"type": "MAINTENANCE",
	}
]

export const mockedUsers = {
	last_evaluated_key: 'hhgdfhguj',
	values: [
		{
			id: 'hjhdkjgslh',
			name: 'Mario',
			family_name: 'Rossi Fini Bossi Del Savoia Incoronata Giuseppi Conti Mainardi',
			email: 'mario.rossi@emailmoltolunga.it',
			phone_number: '7587334534',
			level: 'Principiante',
			bookings_count: 21,
			cancellations_count: 1,
			banned: false,
			favorite: ''
		}, {
			id: 'hjhdkjgslha',
			name: 'Mario',
			family_name: 'Bianchi',
			email: 'mario.bianchi@emailmoltolunga.it',
			phone_number: '+393409388839',
			level: 'Principiante',
			bookings_count: 2,
			cancellations_count: 21,
			banned: true,
			favorite: ''
		},
		{
			id: 'hjhdkjgslh',
			name: 'Mario',
			family_name: 'Rossi',
			email: 'mario.rossi@emailmoltolunga.it',
			phone_number: '7587334534',
			level: 'Principiante',
			bookings_count: 21,
			cancellations_count: 1,
			banned: false,
			favorite: ''
		}, {
			id: 'hjhdkjgslha',
			name: 'Mario',
			family_name: 'Bianchi',
			email: 'mario.bianchi@emailmoltolunga.it',
			phone_number: '+393409388839',
			level: 'Principiante',
			bookings_count: 2,
			cancellations_count: 21,
			banned: true,
			favorite: ''
		},
		{
			id: 'hjhdkjgslh',
			name: 'Mario',
			family_name: 'Rossi',
			email: 'mario.rossi@emailmoltolunga.it',
			phone_number: '7587334534',
			level: 'Principiante',
			bookings_count: 21,
			cancellations_count: 1,
			banned: false,
			favorite: ''
		}, {
			id: 'hjhdkjgslha',
			name: 'Mario',
			family_name: 'Bianchi',
			email: 'mario.bianchi@emailmoltolunga.it',
			phone_number: '+393409388839',
			level: 'Principiante',
			bookings_count: 2,
			cancellations_count: 21,
			banned: true,
			favorite: ''
		},
		{
			id: 'hjhdkjgslh',
			name: 'Mario',
			family_name: 'Rossi',
			email: 'mario.rossi@emailmoltolunga.it',
			phone_number: '7587334534',
			level: 'Principiante',
			bookings_count: 21,
			cancellations_count: 1,
			banned: false,
			favorite: ''
		}, {
			id: 'hjhdkjgslha',
			name: 'Mario',
			family_name: 'Bianchi',
			email: 'mario.bianchi@emailmoltolunga.it',
			phone_number: '+393409388839',
			level: 'Principiante',
			bookings_count: 2,
			cancellations_count: 21,
			banned: true,
			favorite: ''
		},
		{
			id: 'hjhdkjgslh',
			name: 'Mario',
			family_name: 'Rossi',
			email: 'mario.rossi@emailmoltolunga.it',
			phone_number: '7587334534',
			level: 'Principiante',
			bookings_count: 21,
			cancellations_count: 1,
			banned: false,
			favorite: ''
		}, {
			id: 'hjhdkjgslha',
			name: 'Mario',
			family_name: 'Bianchi',
			email: 'mario.bianchi@emailmoltolunga.it',
			phone_number: '+393409388839',
			level: 'Principiante',
			bookings_count: 2,
			cancellations_count: 21,
			banned: true,
			favorite: ''
		},
		{
			id: 'hjhdkjgslh',
			name: 'Mario',
			family_name: 'Rossi',
			email: 'mario.rossi@emailmoltolunga.it',
			phone_number: '7587334534',
			level: 'Principiante',
			bookings_count: 21,
			cancellations_count: 1,
			banned: false,
			favorite: ''
		}, {
			id: 'hjhdkjgslha',
			name: 'Mario',
			family_name: 'Bianchi',
			email: 'mario.bianchi@emailmoltolunga.it',
			phone_number: '+393409388839',
			level: 'Principiante',
			bookings_count: 2,
			cancellations_count: 21,
			banned: true,
			favorite: ''
		}

	]

}

export const mockedClubList = {
	clubs: [
		{
			"club_id": "hfhjasdfhjs",
			"name": "City Padel Cosenza",
			"club_phone_number": "+393409389980",
			"location": {
				"state": "Italy",
				"city": "Milano",
				"zip_code": "20145",
				"latitude": "45.478824066205206",
				"address": "Via Anna Maria Ortese",
				"longitude": "9.159293127138046"
			},
			"status": "DISABLED",
			"manager_email": "tenente@silvestri.it",
			"manager_name": "Tenente",
			"manager_last_name": "Silvestri"
		}, {
			"club_id": "hfggdfsdfhjs",
			"name": "City Padel Genova",
			"club_phone_number": "+393409389980",
			"location": {
				"state": "Italy",
				"city": "Milano",
				"zip_code": "20145",
				"latitude": "45.478824066205206",
				"address": "Via Del Corso, 1",
				"longitude": "9.159293127138046"
			},
			"status": "OPERATIVE",
			"manager_email": "signor@torpedine.it",
			"manager_name": "Signor",
			"manager_last_name": "Torpedine"
		}, {
			"club_id": "gdsgertret",
			"name": "City Padel Milano",
			"club_phone_number": "+393409389980",
			"location": {
				"state": "Italy",
				"city": "Milano",
				"zip_code": "20145",
				"latitude": "45.478824066205206",
				"address": "Via Anna Maria Ortese",
				"longitude": "9.159293127138046"
			},
			"status": "CREATION",
			"manager_email": "manager@manager.it",
			"manager_name": "Signora",
			"manager_last_name": "Anna"
		},
	]
};

export const mockedClubDetail = {
	"club_id": "hfggdfsdfhjs",
	"name": "City Padel Genova",
	"club_phone_number": "0784202277",
	"location": {
		"state": "Italy",
		"city": "Milano",
		"zip_code": "20145",
		"latitude": "45.478824066205206",
		"address": "Via Del Corso, 1",
		"longitude": "9.159293127138046"
	},
	"status": "OPERATIVE",
	"managers": [
		{
			"name": "Signor",
			"family_name": "Torpedine",
			"email": "signor@torpedine.it",
			"phone_number": "+393409389980"
		}
	]
};

export const mockedCreateTournament = {
	"conflicts": [{
		"playground_id": "b0074260-9ebf-11eb-a796-3d9gdf03eb8c2ec",
		"booking_id": "blablabla",
		"start_at": 1612164600000,
		"end_at": 1612171800000
	}]
}

export const mockedTournamentsList = {
	"tournaments": [{
		"id": "hfjkldhgljkdf",
		"club_id": "",
		"club": "",
		"name": "Prova 1",
		"price": 200,
		"type": "MALE",
		"start_at": 1630792800000,
		"end_at": 1631224800000,
		"registration_end_at": 1612134000000,
		"min_couples": 1,
		"max_couples": 2,
		"min_level": "principiante",
		"max_level": "competizione",
		"description": "desc",
		"mode_description": "mod",
		"prizes": "",
		"dates": [{
			"playground_id": "b0074260-9ebf-11eb-a796-3d9gdf03eb8c2ecA",
			"booking_id": "bla",
			"start_at": 1622012400000,
			"end_at": 1622032200000,
		}],
		"teams": [{}]
	},
	{
		"id": "hfjkldhgljkdf",
		"club_id": "",
		"club": "",
		"name": "Prova 2",
		"price": 200,
		"type": "MALE",
		"start_at": 1612134000000,
		"end_at": 1612393200000,
		"registration_end_at": 1612134000000,
		"min_couples": 1,
		"max_couples": 2,
		"min_level": "principiante",
		"max_level": "competizione",
		"description": "desc",
		"mode_description": "mod",
		"prizes": "",
		"picture_ref": "https://wesmash-public-images-dev.s3.amazonaws.com/clubs/c210cc10-96e5-11eb-92fc-df359eaab90e/playgrounds/b0074260-9ebf-11eb-a796-3d903eb8c2ec/picture",
		"dates": [{
			"playground_id": "b0074260-9ebf-11eb-a796-3d9gdf03eb8c2ecA",
			"booking_id": "bla",
			"start_at": 1622012400000,
			"end_at": 1622032200000,
		}],
		"teams": [{}]
	},
	{
		"id": "hfjkldhgljkdf",
		"club_id": "",
		"club": "",
		"name": "Prova 3",
		"status": "DELETED",
		"price": 200,
		"type": "MALE",
		"start_at": 1621980000000,
		"end_at": 1622239200000,
		"registration_end_at": 1621980000000,
		"min_couples": 1,
		"max_couples": 2,
		"min_level": "principiante",
		"max_level": "avanzato",
		"description": "desc",
		"mode_description": "mod",
		"prizes": "",
		"picture_ref": "https://wesmash-public-images-dev.s3.amazonaws.com/clubs/c210cc10-96e5-11eb-92fc-df359eaab90e/playgrounds/b0074260-9ebf-11eb-a796-3d903eb8c2ec/picture",
		"dates": [{
			"playground_id": "b0074260-9ebf-11eb-a796-3d9gdf03eb8c2ecA",
			"booking_id": "bla",
			"start_at": 1622012400000,
			"end_at": 1622032200000,
		}],
		"teams": [{}]
	},
	{
		"id": "hfjkldhgljkdf",
		"club_id": "",
		"club": "",
		"name": "Prova 4",
		"price": 200,
		"type": "MALE",
		"start_at": 1621980000000,
		"end_at": 1622239200000,
		"registration_end_at": 1621980000000,
		"min_couples": 1,
		"max_couples": 2,
		"min_level": "principiante",
		"max_level": "competizione",
		"description": "desc",
		"mode_description": "mod",
		"prizes": "",
		"picture_ref": "https://wesmash-public-images-dev.s3.amazonaws.com/clubs/c210cc10-96e5-11eb-92fc-df359eaab90e/playgrounds/b0074260-9ebf-11eb-a796-3d903eb8c2ec/picture",
		"dates": [{
			"playground_id": "b0074260-9ebf-11eb-a796-3d9gdf03eb8c2ecA",
			"booking_id": "bla",
			"start_at": 1622012400000,
			"end_at": 1622032200000,
		}],
		"teams": [{}]
	}]
}

export const mockedTeamsList = {
	"teams": []
}

export const mockedStatistics = {
	"2021/03/01": {
		"booked_hours": 20,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/02": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/03": {
		"booked_hours": 192,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/04": {
		"booked_hours": 10,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/05": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/06": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/07": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/08": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/09": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/10": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/11": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/12": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/13": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/14": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 2
	},
	"2021/03/15": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 12,
		"cancelled_hours": 1
	},
	"2021/03/16": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/17": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/18": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/19": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 60,
		"cancelled_hours": 0
	},
	"2021/03/20": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/21": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/22": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/23": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/24": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 10,
		"cancelled_hours": 0
	},
	"2021/03/25": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/26": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/27": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/28": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/29": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 200,
		"cancelled_hours": 0
	},
	"2021/03/30": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	},
	"2021/03/31": {
		"booked_hours": 130,
		"lessons_hours": 60,
		"income": 400,
		"cancelled_hours": 0
	}
}

export const mockedRulesList = {
	"records": [
		{
			"id": "45b9d507-04cc-4e15-9e50-ad7c3df601e0",
			"club_id": "c43a7c00-df35-11eb-881d-bb3dba86564b",
			"name":"test1",
			"sport_type":"PADEL",
			"playground_ids":[],
			"start_at":"09/11",
			"end_at":"09/30",
			"slots": [
				{
					"start_at":"09:00",
					"end_at":"12:00",
					"price_60":-1,
					"price_90":-1,
					"price_120":-1
				}
			],
			"days": [0,5,6]
		},
	],
	pagination: {
		total: 1,
		page: 1,
		count: 1,
		limit: 1,
		from: 1,
		to: 1,
		totalPages: 1,
	}
}
