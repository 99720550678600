/*eslint no-unused-vars: ["error", { "args": "none" }]*/
import { Auth, API } from "aws-amplify"
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AdminFilters, UserFilters } from "@/models/filters.models";
import { BackEndUser } from "@/models/user.models";
import { CreateTournamentRequest, ModifyTournamentRequest } from "@/models/tournaments.models";
import axios from 'axios';

function parseResponse(response) {
  // REFORMAT THE INDEX REQUESTS
  // REWRAP THE RESPONSE ARRAY INTO A JSON OBJECT WITH PAGINATION DATA TOOK FROM HEADERS
  if (response.headers['x-page']) {
    const total = parseInt(response.headers['x-total']);
    const limit = parseInt(response.headers['x-limit']);

    const pagination = {
      total: parseInt(response.headers['x-total']),
      page: parseInt(response.headers['x-page']),
      count: parseInt(response.headers['x-count']),
      limit: parseInt(response.headers['x-limit']),
      from: parseInt(response.headers['x-from']),
      to: parseInt(response.headers['x-to']),
      totalPages: (total > limit ? (Math.floor(total / limit) + 1) : 1),
    };
    let data = response.data;

    // CHECK
    if (!Array.isArray(data)) {
      const keys = Object.keys(response.data);
      if (keys.length == 1) data = response.data[keys[0]];
    }

    // SET NEW PARSED DATA
    const newData = {
      records: data,
      pagination: pagination,
    };
    response.data = newData;
  }
  return response;
}

// INIT AXIOS
axios.defaults.baseURL = `${process.env.VUE_APP_API_ENDPOINT}`;
axios.defaults.headers.common['Accept'] = `application/json`;
axios.defaults.headers.common['Content-Type'] = `application/json`;
axios.interceptors.response.use(
  (response) => {
    response = parseResponse(response);
    return response;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export async function signIn(obj: any): Promise<CognitoUser | any> {
  try {
    return await Auth.signIn(obj)
  } catch (error) {
    throw error
  }
}

export async function currentSession() {
  try {
    return await Auth.currentSession()
  } catch (error) {
    throw error
  }
}

export async function signOut() {
  try {
    return await Auth.signOut({ global: false })
  } catch (error) {
    throw error
  }
}

export async function signUp(obj: any) {
  try {
    return await Auth.signUp(obj)
  } catch (error) {
    throw error
  }
}

export async function confirmSignUp(username: string, code: string) {
  try {
    return await Auth.confirmSignUp(username, code)
  } catch (error) {
    throw error
  }
}

export async function resendSignUp(username: string) {
  try {
    return await Auth.resendSignUp(username)
  } catch (error) {
    throw error
  }
}

export async function forgotPassword(username: string) {
  try {
    return await Auth.forgotPassword(username)
  } catch (error) {
    throw error
  }
}

export async function forgotPasswordSubmit(username: string, code: string, new_password: string) {
  try {
    return await Auth.forgotPasswordSubmit(username, code, new_password)
  } catch (error) {
    throw error
  }
}

export async function completeNewPassword(user: CognitoUser, newPassword: string, requiredAttributes: object) {
  try {
    return await Auth.completeNewPassword(user, newPassword, requiredAttributes)
  } catch (error) {
    throw error
  }
}

export async function updateUserAttributes(user: CognitoUser, attributes: object) {
  try {
    return await Auth.updateUserAttributes(user, attributes)
  } catch (error) {
    throw error
  }
}

export async function verifyUserAttribute(user: CognitoUser, attr: string) {
  try {
    return await Auth.verifyUserAttribute(user, attr)
  } catch (error) {
    throw error
  }
}

export async function currentAuthenticatedUser() {
  try {
    return await Auth.currentAuthenticatedUser()
  } catch (error) {
    throw error
  }
}

export function verifySmsCode(idToken: string, payload: { access_token: string; code: string }) {
  const apiName = 'WeSmashApi';
  const path = `/verify-sms-code`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken
    },
    body: payload
  };
  return API.post(apiName, path, myInit);
}


export function myUserInfo(idToken: string) {
  const apiName = 'WeSmashApi';
  const path = '/user-info';
  const myInit = { // OPTIONAL
    headers: {
      "Authorization": "Bearer " + idToken
    }, // OPTIONAL
  };

  return API.get(apiName, path, myInit);
}

export function postUserInfo(idToken: string, user: BackEndUser) {
  const apiName = 'WeSmashApi';
  const path = '/user-info';
  const myInit = { // OPTIONAL
    headers: {
      "Authorization": "Bearer " + idToken
    }, // OPTIONAL
    body: user
  };

  return API.post(apiName, path, myInit);
}

export function clubRequests(idToken: string, filters: AdminFilters) {
  const apiName = 'WeSmashApi';
  const path = '/admin/requests';
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken
    }
  };
  if (filters) {
    myInit['queryStringParameters'] = filters
  }
  return API.get(apiName, path, myInit);
}

export function getUserInfo(idToken: string, id: string) {
  const apiName = 'WeSmashApi';
  const path = `/admin/users/${id}`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken
    }
  };
  return API.get(apiName, path, myInit);
}

export function changeRequestStatus(idToken: string, status: string, club_id: string) {
  const apiName = 'WeSmashApi';
  const path = `/admin/requests/${club_id}/${status}`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken
    }
  };
  return API.post(apiName, path, myInit);
}

export function myClubs(idToken: string) {
  const apiName = 'WeSmashApi';
  const path = `/my-clubs`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken
    }
  };
  return API.get(apiName, path, myInit);
}

export function changeClubInfo(idToken: string, club_id: string, payload) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken
    },
    body: payload
  };
  return API.put(apiName, path, myInit);
}

export function getPlaygrounds(idToken: string, club_id: string) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/playgrounds`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken
    }
  };
  return API.get(apiName, path, myInit);
}

export function getPlaygroundInfo(idToken: string, club_id: string, playground_id: string) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/playgrounds/${playground_id}`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken
    }
  };
  return API.get(apiName, path, myInit);
}

export function addPlayground(idToken: string, club_id: string, payload) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/playgrounds`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken
    },
    body: payload
  };
  return API.post(apiName, path, myInit);
}

export function changePlaygroundInfo(idToken: string, club_id: string,
  playground_id: string, payload) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/playgrounds/${playground_id}`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken
    },
    body: payload
  };
  return API.put(apiName, path, myInit);
}

export function deletePlayground(idToken: string, club_id: string,
  playground_id: string) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/playgrounds/${playground_id}`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken
    }
  };
  return API.del(apiName, path, myInit);
}

export function changeProfilePic(idToken: string, _picture: string) {
  const apiName = 'WeSmashApi';
  const path = `/profile-picture`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken
    },
    body: { picture: _picture }
  };
  return API.post(apiName, path, myInit);
}

export function addNewClub(idToken: string, payload: {
  club: any;
  token: string;
}) {
  const apiName = 'WeSmashApi';
  const path = `/clubs`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
      "recaptcha-token": payload.token
    },
    body: payload.club
  };
  return API.post(apiName, path, myInit);
}

export function getCalendarEvents(idToken: string, payload: {
  club_id: string;
  date?: string;
  start_date?: string;
  user_id?: string;
  future?: boolean;
  archived?: boolean;
  deleted?: boolean;
  hideLoader?: boolean;
}) {
  const apiName = 'WeSmashApi';
  const path = `/timeslots/get-calendar`;
  const params = {};
  params['club_id'] = payload.club_id;

  if (payload.start_date !== undefined) {
    params['start_date'] = payload.start_date;
  }
  if (payload.date !== undefined) {
    params['date'] = payload.date;
  }
  if (payload.user_id !== undefined) {
    params['user_id'] = payload.user_id;
  }
  if (payload.future !== undefined) {
    params['future'] = payload.future;
  }
  if (payload.archived !== undefined) {
    params['archived'] = payload.archived;
  }
  if (payload.deleted !== undefined) {
    params['deleted'] = payload.deleted;
  }

  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
    queryStringParameters: params
  };
  return API.get(apiName, path, myInit);

}

export function getUsers(idToken: string, club_id: string, filters?: UserFilters) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/users`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    }
  };
  if (filters) {
    myInit['queryStringParameters'] = filters
  }

  return API.get(apiName, path, myInit);
}

export async function getUsersAxios(idToken: string, club_id: string, filters?: UserFilters) {
  const queryString = [];

  if (filters.limit != null) {
    queryString.push(`limit=${filters.limit}`);
  }
  if (filters.page != null) {
    queryString.push(`page=${filters.page}`);
  }
  else {
    queryString.push('page=1');
  }
  if (filters.name != null) {
    queryString.push(`name=${filters.name}`);
  }
  if (filters.banned != null) {
    queryString.push(`banned=${filters.banned}`);
  }
  if (filters.min_bookings != null) {
    queryString.push(`min_bookings=${filters.min_bookings}`);
  }
  if (filters.min_cancellations != null) {
    queryString.push(`min_cancellations=${filters.min_cancellations}`);
  }


  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    }
  };

  const path = `/clubs/${club_id}/users?${queryString.join('&')}`;

  const resp = await axios.get(path, myInit);
  // for (const index in resp.data.records) {
  //   console.log(resp.data.records[index]['id']);
  // }
  return resp;
}

export function banUser(idToken: string, club_id: string, user_id: string) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/users/${user_id}/ban`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    }
  };
  return API.post(apiName, path, myInit);
}

export function unbanUser(idToken: string, club_id: string, user_id: string) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/users/${user_id}/unban`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    }
  };
  return API.post(apiName, path, myInit);
}

export function getClubStats(idToken: string, club_id: string, start_date: string) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/statistics`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
    queryStringParameters: {
      start_date: start_date
    }
  };
  return API.get(apiName, path, myInit);
}

export function getCustomerStats(idToken: string, club_id: string, start_date: string) {
  const apiName = 'WeSmashApi';
  const path = `/admin/statistics`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
    queryStringParameters: {
      club_id: club_id,
      start_date: start_date
    }
  };
  return API.get(apiName, path, myInit);
}

export function addBooking(idToken: string, booking) {
  const apiName = 'WeSmashApi';
  const path = `/backoffice/bookings`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
    body: booking
  };
  return API.post(apiName, path, myInit);
}

export function modifyBooking(idToken: string, booking) {
  const apiName = 'WeSmashApi';
  const path = `/backoffice/bookings`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
    body: booking
  };
  return API.put(apiName, path, myInit);
}

export function clubSearch(idToken: string, nameFilter: string) {
  const apiName = 'WeSmashApi';
  const path = `/admin/clubs`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    }
  };
  if (nameFilter) {
    myInit['queryStringParameters'] = { name: nameFilter }
  }
  return API.get(apiName, path, myInit);
}

export function clubDetail(idToken: string, club_id: string) {
  const apiName = 'WeSmashApi';
  const path = `/admin/clubs/${club_id}`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    }
  };
  return API.get(apiName, path, myInit);
}

export function enableClub(idToken: string, club_id: string) {
  const apiName = 'WeSmashApi';
  const path = `/admin/clubs/${club_id}/enable`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    }
  };
  return API.post(apiName, path, myInit);
}

export function disableClub(idToken: string, club_id: string) {
  const apiName = 'WeSmashApi';
  const path = `/admin/clubs/${club_id}/disable`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    }
  };
  return API.post(apiName, path, myInit);
}

export function sendOTP(idToken: string, phone_number: string) {
  const apiName = 'WeSmashApi';
  const path = `/send-otp`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
    queryStringParameters: {
      phone_number: phone_number
    }
  };
  return API.post(apiName, path, myInit);
}

export function deleteTimeslot(idToken: string, booking) {
  const apiName = 'WeSmashApi';
  const path = `/backoffice/bookings/cancel`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
    body: booking
  };

  return API.post(apiName, path, myInit);
}

export function createTournament(idToken: string, club_id: string, tournament: CreateTournamentRequest) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/tournaments`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
    body: tournament
  };
  return API.post(apiName, path, myInit);
}

export function modifyTournament(idToken: string, club_id: string, tournament_id: string, tournament: ModifyTournamentRequest) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/tournaments/${tournament_id}`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
    body: tournament
  };
  return API.put(apiName, path, myInit);
}

export function deleteTournament(idToken: string, club_id: string, tournament_id: string) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/tournaments/${tournament_id}`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    }
  };
  return API.del(apiName, path, myInit);
}

export function getTournaments(idToken: string, club_id: string) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/tournaments`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    }
  };
  return API.get(apiName, path, myInit);
}

export function getTournamentDetails(idToken: string, club_id: string, tournament_id: string) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/tournaments/${tournament_id}`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    }
  };
  return API.get(apiName, path, myInit);
}

export function getTournamentTeams(idToken: string, club_id: string, tournament_id: string) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/tournaments/${tournament_id}/teams`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    }
  };
  return API.get(apiName, path, myInit);
}

export function deleteTeam(idToken: string, club_id: string, tournament_id: string, booker_id: string) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/tournaments/${tournament_id}/teams/${booker_id}`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    }
  };
  return API.del(apiName, path, myInit);
}

export function uploadScoreboard(idToken: string, club_id: string, tournament_id: string, payload: { scoreboard: string }) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/tournaments/${tournament_id}/scoreboard `;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
    body: payload
  };
  return API.post(apiName, path, myInit);
}

export async function getPricesRules(idToken: string, params: object) {
  const path = `rule`;
  const configs = {
    params: {
      ...params,
    },
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };
  return axios.get(path, configs);
}

export async function getPriceRule(idToken: string, id: string) {
  const path = `rule/${id}`;
  const configs = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };
  return axios.get(path, configs);
}

export async function createPricesRule(idToken: string, form: object) {
  const path = `rule`;
  const configs = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };
  return axios.post(path, form, configs);
}

export async function updatePriceRule(idToken: string, payload: { id: string; body: object }) {
  const path = `rule/${payload.id}`;
  const configs = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };
  return axios.patch(path, payload.body, configs);
}

export async function deletePriceRule(idToken: string, id: string) {
  const path = `rule/${id}`;
  const configs = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };
  return axios.delete(path, configs);
}

export async function getExceptionRules(idToken: string, params: object) {
  const path = `exception-rule`;
  const configs = {
    params: {
      ...params,
    },
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };
  return axios.get(path, configs);
}

export async function createExceptionRule(idToken: string, form: object) {
  const path = `exception-rule`;
  const configs = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };
  return axios.post(path, form, configs);
}

export async function updateExceptionRule(idToken: string, payload: { id: string; body: object }) {
  const path = `exception-rule/${payload.id}`;
  const configs = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };
  return axios.patch(path, payload.body, configs);
}

export async function deleteExceptionRule(idToken: string, id: string) {
  const path = `exception-rule/${id}`;
  const configs = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };
  return axios.delete(path, configs);
}

export function duplicatePlayground(idToken: string, club_id: string, id: string, name: string) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/playgrounds/${id}`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken
    },
    body: { name: name },
  };
  return API.post(apiName, path, myInit);
}

export function checkBundle(idToken: string, bookings) {
  const apiName = 'WeSmashApi';
  const path = `/backoffice/bundles/check`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
    body: bookings,
    timeout: 200 * 1000
  };
  return API.post(apiName, path, myInit);
}

export function confirmBundle(idToken: string, bookings) {
  const apiName = 'WeSmashApi';
  const path = `/backoffice/bundles/confirm`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
    body: bookings,
    timeout: 200 * 1000
  };
  return API.post(apiName, path, myInit);
}

export function updateBundle(idToken: string, bookings) {
  const apiName = 'WeSmashApi';
  const path = `/backoffice/bundles/confirm`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
    body: bookings,
    timeout: 200 * 1000
  };
  return API.post(apiName, path, myInit);
}

export function uploadBookingCSV(idToken: string, club_id: string, id: string, file: File) {
  const path = `/import/${club_id}/${id}`;
  const configs = {
    headers: {
      "Authorization": "Bearer " + idToken,
      "Content-Type": "multipart/form-data",
    },
  };

  const formData = new FormData();
  formData.append('file', file);

  // RETURN
  return axios.post(path, formData, configs);
}

export function getCards(idToken: string, user_id: string, limit = 10, club_id?: string) {
  const path = `cards`;
  const configs = {
    params: {
      club_id: club_id || undefined,
      user_id: user_id,
      limit: limit
    },
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };

  // RETURN
  return axios.get(path, configs);
}

export function getCard(idToken: string, card_id: string) {
  const path = `cards/${card_id}`;
  const configs = {
    params: {
    },
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };

  // RETURN
  return axios.get(path, configs);
}

export async function createCard(idToken: string, form: object) {
  const path = `cards`;
  const configs = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };
  return axios.post(path, form, configs);
}

export async function updateCard(idToken: string, form: any) {
  const path = `cards/${form.id}`;
  const configs = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };
  delete form.id;
  return axios.patch(path, form, configs);
}

export async function deleteCard(idToken: string, form: any) {
  const path = `cards/${form.id}`;
  const configs = {
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };
  return axios.delete(path, configs);
}


export function getUser(idToken: string, club_id: string, user_id: string) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/users/${user_id}`;
  const myInit = { // OPTIONAL
    headers: {
      "Authorization": "Bearer " + idToken
    }, // OPTIONAL
  };

  return API.get(apiName, path, myInit);
}

export function changePlayerCategory(idToken: string, club_id: string, user_id: string, category: string) {
  const apiName = 'WeSmashApi';
  const path = `/clubs/${club_id}/users/${user_id}`;
  const myInit = {
    headers: {
      "Authorization": "Bearer " + idToken
    },
    body: { player_category: category }
  };
  return API.put(apiName, path, myInit);
}

export function getOpenGames(idToken: string, club_id: string, match_type?: string, status?: string) {
  const path = `bookings/open-games`;
  const configs = {
    params: {
      club_id: club_id,
      match_type: match_type || undefined,
      status: status,
    },
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };

  // RETURN
  return axios.get(path, configs);
}

export function getOpenGame(idToken: string, id: string) {
  const path = `bookings/${id}`;
  const configs = {
    params: {
    },
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };

  // RETURN
  return axios.get(path, configs);
}

export function subscribeToGame(idToken: string, booking_id: string, wesmash_user_id: string, spot: number) {
  const path = `bookings/${booking_id}/subscribe`;
  const body = {
    wesmash_user_id: wesmash_user_id,
    spot: spot,
  };
  const configs = {
    params: {
    },
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };

  // RETURN
  return axios.post(path, body, configs);
}

export function unsubscribeToGame(idToken: string, booking_id: string, wesmash_user_id: string) {
  const path = `bookings/${booking_id}/unsubscribe`;
  const body = {
    wesmash_user_id: wesmash_user_id,
  };
  const configs = {
    params: {
    },
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };

  // RETURN
  return axios.post(path, body, configs);
}


export async function adminUserStats(idToken: string, month: string, year: string) {

  const path = `clubs/full-statistics?month=${month}&year=${year}`;
  // const path = `bookings/admin-search?club_id=${club_id}&start_date=${start_at}`;

  const configs = {
    params: {
    },
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };

  // RETURN
  const results = [];
  let resp = await axios.get(path, configs);
  const totalPages = resp.data.pagination.totalPages;



  results.push(Object.entries(resp.data.records));

  for (let i = 2; i <= totalPages; i++) {
    resp = await axios.get(`${path}&page=${i} `, configs);
    results.push(Object.entries(resp.data.records));
  }

  return { "results": results };
}

export async function nexiSaveConfig(idToken: string, club_id: string, mac: string, alias: string) {

  const path = `clubs/${club_id}/nexi-payment-codes`;
  const body = {
    "alias": alias,
    "mac": mac
  };
  const configs = {
    params: {
      "alias": alias,
      "mac": mac
    },
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };

  return await axios.post(path, body, configs);
}

export async function enableOnlinePayment(idToken: string, club_id: string, enable: boolean) {
  const path = `clubs/${club_id}/enable-online-payment`;
  const body = {
    "online_payment": enable
  };
  const configs = {
    params: {

    },
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };

  return await axios.post(path, body, configs);
}

export async function fetchTransactions(idToken: string, club_id: string, refund: boolean, date: string) {
  const path = 'transactions';

  const configs = {
    params: {
      'club_id': club_id,
      'type': refund ? 'REFUND' : 'SALE',
      'payment_date': date,
    },
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };
  return await axios.get(path, configs);
}

export async function refund(idToken: string, clubId: string, bookingId: string, refundPercentage: number) {
  const path = `clubs/${clubId}/refund_payment`;
  const body = {
    'booking_id': bookingId,
    'refund_percentage': refundPercentage
  }
  const configs = {
    params: {},
    headers: {
      "Authorization": "Bearer " + idToken,
    },
  };
  return await axios.post(path, body, configs);
}

