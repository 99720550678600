<PadelLateralTab />

<div class="view-main-container">
    
    <div class="inner-view-container">

        <div class="header flexbox-row padding-top-40 padding-bottom-40">
            <div class="cursor-pointer flexbox-row align-center" 
                @click="router.go(-1)">
                <i class="material-icons color-blue cursor-pointer margin-right-10">keyboard_arrow_left</i>
                <div class="go-back-text color-blue">{{uppercase(t('abort'))}}</div>
            </div>
        </div>

        <div class="p-grid padding-bottom-20">
            <div class="p-col-12 p-text-left">
                <h3 class="color-gold barlow margin-bottom-0">{{ t('overall_picture_bookings') }}</h3>
            </div>
        </div>

        <div class="p-d-flex p-jc-start p-mt-2 grid-space-4">
            <div class="p-text-left">
                <h3 class="color-gold barlow margin-bottom-0">{{t('recurring_period')}}</h3>
                <div class="barlow color-white margin-top-5">{{ t('recurring_period_value', { from: fromDate, to: endDate }) }}</div>
            </div>
            <div class="p-text-left">
                <h3 class="color-gold barlow margin-bottom-0">{{t('day_hour')}}</h3>
                <div class="barlow color-white margin-top-5">{{ t('day_hour_value', { date: date, from: fromTime, to: toTime }) }}</div>
            </div>
            <div class="p-text-left"
                v-if="!isEvent">
                <h3 class="color-gold barlow margin-bottom-0">{{t('total_cost')}}</h3>
                <div class="barlow color-white margin-top-5">{{ totalPrice }}€</div>
            </div>
            <div class="p-text-left"
                v-else>
                <h3 class="color-gold barlow margin-bottom-0">{{t('playground')}}</h3>
                <div class="barlow color-white margin-top-5">{{ playgroundSelected ? playgroundSelected.name : 'n/a' }}</div>
            </div>
        </div>

        <div class="bundle-container margin-top-20 margin-bottom-10">
            <BundleItemCard 
                v-for="(element, index) in bundleResponse"
                :key="element.key"
                :element="element" 
                :event="isEvent"
                :index="index" />
        </div>

        <div class="p-d-flex p-jc-end p-my-3">
            <PadelButton 
                :label="t('confirm')" 
                :disabled="!bundleIsFilled(bundleResponse)"
                @click="save()" />
        </div>

    </div>

</div>