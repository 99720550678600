<div class="main-container">
    <div class="p-grid align-center">


        <!-- <div class="p-col-12 p-md-3">
            <PadelCalendar :showLabel="true" @input="changeStartAtDate($event)" class="calendaaar" label="Dal"
                :value="startAtDate.value" :maxDate="new Date()" />
        </div>


        <div class="p-col-12 p-md-3">
            <PadelCalendar :showLabel="true" @input="changeEndAtDate($event)" class="calendaaar" label="Al"
                :value="endAtDate.value" :maxDate="new Date()" />
        </div> -->

        <div class="p-col-12 p-md-6 text-right">

            <div class="scheduler-button export-button cursor-pointer display-flex"
                :disabled="startAtDate.value == null && endAtDate.value == null" @click="downloadExcel()">
                <p class="scheduler-button-text color-blue"
                    :class="[startAtDate.value != null && endAtDate.value != null  ? '' : 'disabled']">
                    <strong>SCARICA LE STATISTICHE</strong>
                </p>
                <i class="material-icons color-blue">file_download</i>
            </div>
        </div>
    </div>
</div>