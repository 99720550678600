
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { ActionTypes } from "@/store/action-types";
import { uppercase } from "@/services/mapping.service";
import { PadelUser } from "@/models/user.models";
import { showToast } from "@/services/error-management.service";

export default defineComponent({
  name: "PlayerItem",
  props: {
    booking_id: {
      type: String,
      default: null,
    },
    player: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
    playerIds: {
      type: Array,
      default: [],
    },
  },
  emits: ["refresh"],
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const { t, locale } = useI18n();
    const modalId = ref(null);
    /* COMPUTED */

    // SYSTEM
    onMounted(() => {});

    // FUNCTIONS
    function showModal(id) {
      modalId.value = id;
    }

    function hideModal() {
      modalId.value = null;
    }

    function userSelected(user: PadelUser) {
      if (user) {
        // HIDE
        hideModal();

        if ((props.playerIds as Array<string>).indexOf(user.id) != -1) {
          showToast("Giocatore già iscritto");
          emit("refresh");
          return;
        }

        // SUBSCRIBE
        store
          .dispatch(ActionTypes.SUBSCRIBE_TO_GAME, {
            booking_id: props.booking_id,
            wesmash_user_id: user.id,
            spot: (props.index as number) + 1,
          })
          .then(() => {
            emit("refresh");
          });
      }
    }

    function removePlayer() {
      // HIDE
      hideModal();

      // SUBSCRIBE
      store
        .dispatch(ActionTypes.UNSUBSCRIBE_TO_GAME, {
          booking_id: props.booking_id,
          wesmash_user_id: props.player.id,
        })
        .then(() => {
          emit("refresh");
        });
    }

    return {
      t,
      locale,
      uppercase,

      // DATA
      modalId,

      // METHIDS
      showModal,
      hideModal,
      userSelected,
      removePlayer,
    };
  },
});
