<div class="main-container justify-space-between flexbox-column padding-main-container">
    <div class="first-part">
        <div class="header display-flex">
            <img class="smash-img" src="@/theme/imgs/logo_completo.svg" alt="">
            <div class="already-have-account font-12 color-blue cursor-pointer" @click="redirect('login')">
                {{uppercase(t('already_registered'))}}
            </div>
        </div>
        <h3 class="title color-gold barlow">
            {{t('insert_your_code')}}
        </h3>
        <p class="color-white">{{t('insert_code_text')}}</p>
        <div class="confirm-signup-container flexbox-column">
            <PadelInput style="min-width: 28vw;" :placeholder="t('verify_code')" :label="t('verify_code')"
                :value="codeValue" :error="codeError" :inputType="'number'" @input="updateFormValue($event)"
                @validate="validate()" />
            <p class="send-again montserrat" @click="resendCode()">
                {{t('resend_code')}}
            </p>
        </div>
    </div>

    <div class="buttons-container flexbox-row justify-space-between width-100">
        <p class="font-12 color-blue cursor-pointer bold-text" @click="goBack()">{{uppercase(t('back'))}}</p>
        <PadelButton @click="confirmSignUp()" :label="uppercase(t('forward'))"
            :disabled="codeValue === '' || codeError != ''">
        </PadelButton>
    </div>


</div>