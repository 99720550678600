
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import moment from "moment";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  nextTick,
  watch,
} from "vue";
import { PadelClub } from "@/models/club.models";
import { ActionTypes } from "@/store/action-types";
import { useRouter, useRoute } from "vue-router";
import {
  uppercase,
  isGameConfirmed,
  confirmedGame,
} from "@/services/mapping.service";
import { matchTypesOptions } from "@/constants/radio-options.constants";
import { Levels } from "@/constants/constants.constants";

export default defineComponent({
  name: "OpenBookings",
  components: {},
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const { t, locale } = useI18n();
    const selectedMatchType = ref(null);
    const openGames = ref([]);
    const selectedTab = ref(0);

    /* COMPUTED */
    const myClub = computed(function (): PadelClub {
      return store.getters.getMyClub;
    });
    const myClubId = computed(function (): string {
      return store.getters.getMyClub.club_id;
    });
    const status = computed(function () {
      switch (selectedTab.value) {
        case 1:
          return "PAST";
        case 2:
          return "CANCELLED";
        case 0:
        default:
          return null;
      }
    });

    // LIFECYCLE
    onMounted(() => {
      nextTick(() => {
        selectMatchType(matchTypesOptions[0].value);
      });
    });

    onUnmounted(() => {});

    /** WATCH */
    watch(selectedMatchType, function (newVal, oldVal) {
      getOpenGames();
    });

    // FUNCTIONS
    function getOpenGames() {
      store
        .dispatch(ActionTypes.GET_OPEN_GAMES, {
          club_id: myClubId.value,
          match_type: selectedMatchType.value,
          status: status.value,
        })
        .then((response) => {
          openGames.value = response.data.records.sort((a, b) => {
            if (a.start_at < b.start_at) return -1;
            return 1;
          });
        });
    }

    function selectMatchType(value) {
      selectedMatchType.value = value;
    }

    function formatDate(item) {
      // INIT
      const formattedDate = moment(item.date, "M/D/YYYY").format(
        "DD MMMM YYYY"
      );
      return `${formattedDate}`;
    }

    function formatGameDuration(item) {
      const duration = (item.end_at - item.start_at) / 1000 / 60;
      return duration;
    }

    function getPlayersNumber(item) {
      const matchType = matchTypesOptions.find(
        (el) => el.value === item.match_type
      );
      const max = matchType.participants;

      // CHECK
      let total = 0;
      if (item.wesmash_user_id) total++;
      if (item.wesmash_user2_id) total++;
      if (item.wesmash_user3_id) total++;
      if (item.wesmash_user4_id) total++;
      return `${total}/${max}`;
    }

    function formatTime(value, format) {
      return moment(value).format(format);
    }

    function getLevelsLabel(min, max) {
      const minItem = Levels.find((el) => el.value === min);
      const maxItem = Levels.find((el) => el.value === max);
      const minLabel = minItem ? minItem.short : "n/a";
      const maxLabel = maxItem ? maxItem.short : "n/a";

      // RETURN
      if (minLabel === maxLabel) return minLabel;
      return `${minLabel} - ${maxLabel}`;
    }

    function onClickEvent(event: any) {
      router.push({ name: "open-booking-detail", params: { id: event.id } });
    }

    function onChangeTab(event) {
      selectedTab.value = event;
      getOpenGames();
    }

    // RETURN
    return {
      t,
      locale,
      router,
      uppercase,
      moment,

      // OPTIONS
      matchTypesOptions,

      // DATA
      myClub,
      myClubId,
      selectedMatchType,
      openGames,
      selectedTab,

      // METHODS
      selectMatchType,
      formatDate,
      formatGameDuration,
      getPlayersNumber,
      formatTime,
      getLevelsLabel,
      onClickEvent,
      isGameConfirmed,
      confirmedGame,
      onChangeTab,
    };
  },
});
