export enum CognitoErrors {
    NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException', //WRONG PASSWORD
    USER_ALREADY_EXISTS = 'UsernameExistsException', //USER WITH THIS USERNAME/EMAIL ALREADY EXISTS
    INVALID_PASSWORD = 'InvalidPasswordException', //INVALID PASSWORD
    USER_NOT_CONFIRMED = 'UserNotConfirmedException', //USER DID NOT CONFIRM ACCOUNT
    PASSWORD_RESET_REQUIRED = 'PasswordResetRequiredException', //NEED TO CALL THE forgotPassword TO RESET THE PASSWORD
    USER_NOT_FOUND = 'UserNotFoundException', // THE USERNAME/EMAIL DOES NOT EXIST IN THE USER POOL
    EXPIRED_CODE = 'ExpiredCodeException', // CODE EXPIRED
    CODE_MISMATCH = 'CodeMismatchException', //the code given is not correct
    TOO_MANY_ATTEMPTS = 'LimitExceededException' //TOO MANY ATTEMPTS TRY AFTER SOME TIME
}