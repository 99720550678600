<PadelLateralTab />

<div class="view-main-container">
    
    <div class="inner-view-container">

        <div class="header flexbox-row padding-top-40 padding-bottom-40">
            <div class="cursor-pointer flexbox-row align-center" 
                @click="router.go(-1)">
                <i class="material-icons color-blue cursor-pointer margin-right-10">keyboard_arrow_left</i>
                <div class="go-back-text color-blue">{{uppercase(t('abort'))}}</div>
            </div>
        </div>

        <div class="p-grid padding-bottom-20">
            <div class="p-col-12 p-text-left">
                <UsersSearch 
                    :searchUser="searchUser"
                    @userSelected="userSelected" />
            </div>
        </div>

        <UserScheduledTable
            :user="searchUser"
            @onClick="onClickEvent" />

    </div>

</div>