
/* eslint-disable */
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { mapUsersArray, uppercase } from "@/services/mapping.service";
import {
  emptyPadelUser,
  emptyUserFilter,
} from "@/constants/initial-values.constants";
import { userAbilitationOptions } from "@/constants/radio-options.constants";
import { formUserFilterEmpty } from "@/services/form-validation.service";
import { useStore } from "@/store/store";
import { ActionTypes } from "@/store/action-types";
import { UserFilters } from "@/models/filters.models";
import { convertArrayIntoCsv } from "@/services/files.service";
import { USERS_PAGE_SIZE } from "@/constants/constants.constants";
import { PadelUser } from "@/models/user.models";
import { SexType } from "@/constants/sex.enum";
import { MutationTypes } from "@/store/mutation-types";
import { upperCaseFirstLetter } from "@/services/mapping.service";
import router from "@/router";

export default defineComponent({
  setup() {
    const page = ref(1);
    const showLoadMore = ref(false);
    const store = useStore();
    const searchFormValue = ref(null);
    const modalOpened = ref(null);
    const activeFilters = ref([]);
    const filtersForm = ref({ ...emptyUserFilter });
    const userTable = ref({ values: [], last_evaluated_key: undefined });
    const hoverEnabled = ref(true);
    const provvUser = ref({ ...emptyPadelUser });
    const { t, locale } = useI18n();

    /* COMPUTED */
    const myClubId = computed(function (): string {
      return store.getters.getMyClub.club_id;
    });

    function formatFilters(
      filters: { key: string; value: string | boolean }[]
    ) {
      const _filters = { limit: USERS_PAGE_SIZE, page: page.value };
      filters.forEach((val) => {
        let _provv = val.value;
        if (val.key === "banned") {
          _provv = val.value === "BANNED" ? true : false;
        }
        _filters[val.key] = _provv;
      });
      return _filters;
    }

    function constructPayload(payload) {
      const _payload = { ...payload };
      return _payload;
    }

    async function fetchUsers(
      filters: { key: string; value: string }[],
      isScroll = false
    ) {
      try {
        const usersArray = await store.dispatch(ActionTypes.GET_USERS_AXIOS, {
          club_id: myClubId.value,
          filters: constructPayload(formatFilters(filters)),
        });

        showLoadMore.value =
          usersArray.data.pagination.page <
          usersArray.data.pagination.totalPages;

        if (isScroll) {
          //aggiungi al fondo della lista
          userTable.value.values = userTable.value.values.concat(
            usersArray.data.records.filter((el) => el.hasOwnProperty("name"))
          );
        } else {
          userTable.value.values = [
            ...usersArray.data.records.filter((el) =>
              el.hasOwnProperty("name")
            ),
          ];
        }
        return usersArray;
      } catch (error) {
        console.log(error);
      }
    }

    onMounted(async function () {
      try {
        await fetchUsers([]);
      } catch (error) {
        console.log(error);
      }
    });

    function resetLastEvaluatedKey() {
      userTable.value.last_evaluated_key = undefined;
    }

    watch(activeFilters, async function (currentValue, oldValue) {
      resetLastEvaluatedKey();
      await fetchUsers(currentValue);
    });

    async function fetchAllUsers() {
      try {
        let usr = {
          values: [],
        };
        let last_evaluated_key = undefined;
        let isFirstCycle = true;
        while (last_evaluated_key || isFirstCycle) {
          isFirstCycle = false;
          const _values = await store.dispatch(ActionTypes.GET_USERS, {
            club_id: myClubId.value,
            filters: constructPayload({ limit: 10000 }),
          });
          last_evaluated_key = _values.last_evaluated_key;
          usr = {
            values: usr.values.concat(_values.values.filter((el) => !!el.name)),
          };
        }
        return usr;
      } catch (err) {
        return err;
      }
    }

    async function exportData() {
      try {
        const users = (await fetchAllUsers()) as any;

        convertArrayIntoCsv(mapUsersArray(users.values, t), "users_list");
      } catch (err) {
        return;
      }
    }

    function searchValueChanged(event) {
      searchFormValue.value = event;
      activeFilters.value = [
        ...activeFilters.value,
        { key: "name", value: event },
      ];
    }

    function removeFilter(key: string) {
      activeFilters.value = activeFilters.value.filter((el) => el.key !== key);
    }

    function eraseSearchValue() {
      searchFormValue.value = null;
      removeFilter("name");
    }

    function openModal(modal: string) {
      modalOpened.value = modal;
    }

    function initializeFilterForm() {
      filtersForm.value = { ...emptyUserFilter };
    }

    function closeModal() {
      initializeFilterForm();
      modalOpened.value = null;
    }

    function applyFilters() {
      const _provv = [];
      Object.entries(filtersForm.value)
        .filter((el) => el[1])
        .forEach((el) => {
          _provv.push({ key: el[0], value: el[1] });
        });
      const nameFilter = activeFilters.value.filter((el) => el.key === "name");
      activeFilters.value = [...nameFilter, ..._provv];
      closeModal();
    }

    function setFilterFormValue(key: string, value) {
      filtersForm.value[key] = value;
    }

    function formatValue(val: { key: string; value: string }) {
      if (val.key === "min_bookings") {
        return `num. min. prenotazioni: ${val.value}`;
      } else if (val.key === "min_cancellations") {
        return `num. min. cancellazioni: ${val.value}`;
      } else {
        return val.value;
      }
    }

    function initializeProvvUser() {
      provvUser.value = { ...emptyPadelUser };
    }

    async function loadMoreRequests() {
      page.value += 1;
      await fetchUsers(activeFilters.value, true);
    }

    function handleUserBlocking(user: PadelUser) {
      provvUser.value = { ...user };
      if (user.banned) {
        openModal("UNBAN_USER");
      } else {
        openModal("BAN_USER");
      }
    }

    function closeModalAndResetProvvUser() {
      initializeProvvUser();
      closeModal();
    }

    async function banUser() {
      try {
        await store.dispatch(ActionTypes.BAN_USER, {
          club_id: myClubId.value,
          user_id: provvUser.value.id,
        });
        await fetchUsers(activeFilters.value);
      } catch (error) {
        return;
      }
      closeModalAndResetProvvUser();
    }

    async function unbanUser() {
      try {
        await store.dispatch(ActionTypes.UNBAN_USER, {
          club_id: myClubId.value,
          user_id: provvUser.value.id,
        });
        await fetchUsers(activeFilters.value);
      } catch (error) {
        return;
      }
      closeModalAndResetProvvUser();
    }

    function returnGender(gender: string) {
      if (gender === SexType.MALE) {
        return "male";
      } else if (gender === SexType.FEMALE) {
        return "female";
      } else {
        return "-";
      }
    }

    function onClickEvent(event: any) {
      const item = JSON.parse(JSON.stringify(event));
      store.commit(MutationTypes.SET_SEARCH_USER, item);
      setTimeout(() => {
        router.push({ name: "user-details", params: { id: item.id } });
      }, 100);
    }

    return {
      t,
      locale,
      uppercase,
      exportData,
      searchValueChanged,
      modalOpened,
      userAbilitationOptions,
      filtersForm,
      activeFilters,
      userTable,
      loadMoreRequests,
      showLoadMore,
      hoverEnabled,
      provvUser,

      searchFormValue,
      eraseSearchValue,
      openModal,
      closeModal,
      applyFilters,
      setFilterFormValue,
      initializeFilterForm,
      formUserFilterEmpty,
      removeFilter,
      formatValue,
      handleUserBlocking,
      banUser,
      unbanUser,
      closeModalAndResetProvvUser,
      returnGender,
      onClickEvent,

      upperCaseFirstLetter,
    };
  },
});
