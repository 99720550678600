<DumbModal :title="t('custom_config')" @hide="hideModal()" :maximizable="true" :customStyle="{width: '90vw'}"
    :breakpoints="{'720px': '90vw', '640px': '100vw'}">
    <div class="modal-content">
        <strong class="color-white" v-html="t('custom_config_message')"></strong>

        <div class="p-grid p-mt-3">
            <div class="p-col-12 p-md-6 p-pr-6">
                <PadelInput :label="t('exception_name')" @input="updateFormValue($event, 'name')" :value="form.name" />
            </div>
        </div>

        <div class="form-row flexbox-row justify-space-between">
            <div>
                <label>{{ t('valid_period') }}</label>
                <div class="form-row flexbox-row justify-space-between">
                    <div class="calendar-element">
                        <PadelCalendar :label="t('start_date')" :viewType="'date'" :value="form.exception_at"
                            :error="formErrors.exception_at ? t(formErrors.exception_at) : ''" :manualInput="false"
                            :disabled="true" />
                    </div>

                    <div class="calendar-element">
                        <PadelCalendar :label="t('end_date')" :viewType="'date'" :value="form.exception_at"
                            :error="formErrors.exception_at ? t(formErrors.exception_at) : ''" :manualInput="false"
                            :disabled="true" />
                    </div>
                </div>
            </div>
            <div>
                <label>{{ t('playgrounds') }}</label>
                <div class="form-row flexbox-row justify-space-between">
                    <!-- <div class="dropdown-container">
                        <PadelDropdown class="dropdown" :disabled="true" :label="t('sport')" :options="[]"
                            @input="updateFormValue($event.value, 'sport_type', true)"
                            :error="formErrors.sport_type ? t(formErrors.sport_type) : ''" :default="selectectedSport"
                            :isDropdownInModal="true" />
                    </div> -->

                    <div class="dropdown-container">
                        <MultiSelect v-model="form.playground_ids" :options="PlaygroundsOptions" optionLabel="label"
                            optionValue="value" :placeholder="t('select_playground')" :filter="false"
                            class="multiselect-custom" />
                    </div>
                </div>
            </div>
        </div>


        <!-- DIVIDER -->
        <div class="p-grid">
            <div class="p-col-12">
                <div class="gold-separator"></div>
            </div>
        </div>


        <!-- SLOTS HEADER -->
        <div class="p-grid">
            <div class="p-col-12">
                <p class="color-white">{{ t('select hour slots when rule is valid') }}</p>
            </div>
        </div>


        <!-- SLOTS -->
        <div class="slot-row flexbox-row justify-space-between" v-for="(slot, index) in form.slots" :key="index">

            <div class="form-row flexbox-row justify-space-between p-pt-3">
                <PadelTimepicker :label="t('from')" @input="updateSlotValue(index, $event, 'start_at', true)"
                    :from="openingHour" :to="closingHour" :value="form.slots[index].start_at"
                    :error="formErrors.slots[index].start_at ? t(formErrors.slots[index].start_at) : ''" />
                <PadelTimepicker :label="t('to')" @input="updateSlotValue(index, $event, 'end_at', true)"
                    :from="openingHour" :to="closingHour" :value="form.slots[index].end_at"
                    :error="formErrors.slots[index].end_at ? t(formErrors.slots[index].end_at) : ''" />
            </div>

            <div>
                <label class="small">{{ t('prenotation_duration') }}</label>
                <div class="p-d-flex p-justify-start">
                    <ToggleButton v-model="form.slots[index].duration_60" onLabel="60min" offLabel="60min"
                        v-if="form.slots[index].show_duration_60" />
                    <ToggleButton v-model="form.slots[index].duration_90" onLabel="90min" offLabel="90min"
                        v-if="form.slots[index].show_duration_90" />
                    <ToggleButton v-model="form.slots[index].duration_120" onLabel="120min" offLabel="120min"
                        v-if="form.slots[index].show_duration_120" />
                </div>
            </div>

            <div>
                <label class="small">{{ t('slot_prices') }}</label>
                <div class="time-row flexbox-row justify-space-between">
                    <div>
                        <PadelInput :label="t('minutes', { time: '60' })"
                            @input="updateSlotValue(index, $event, 'price_60', true)" :inputType="'number'"
                            :icon="'euro_symbol'" :isPrice="true" v-if="form.slots[index].duration_60"
                            :value="form.slots[index].price_60"
                            :error="formErrors.slots[index].price_60 ? t(formErrors.slots[index].price_60) : ''" />
                    </div>
                    <div>
                        <PadelInput :label="t('minutes', { time: '90' })"
                            @input="updateSlotValue(index, $event, 'price_90', true)" :inputType="'number'"
                            :icon="'euro_symbol'" :isPrice="true" v-if="form.slots[index].duration_90"
                            :value="form.slots[index].price_90"
                            :error="formErrors.slots[index].price_90 ? t(formErrors.slots[index].price_90) : ''" />
                    </div>
                    <div>
                        <PadelInput :label="t('minutes', { time: '120' })"
                            @input="updateSlotValue(index, $event, 'price_120', true)" :inputType="'number'"
                            :icon="'euro_symbol'" :isPrice="true" v-if="form.slots[index].duration_120"
                            :value="form.slots[index].price_120"
                            :error="formErrors.slots[index].price_120 ? t(formErrors.slots[index].price_120) : ''" />
                    </div>
                </div>
            </div>

            <div style="width: 50px;">
                <span class="icon-delete material-icons color-blue cursor-pointer p-mt-4"
                    @click="removeSlot(index)">delete</span>
            </div>

        </div>

        <div class="p-grid">
            <div class="p-col-12 p-text-center p-mt-3">
                <div class="scheduler-button new-event-button cursor-pointer display-flex margin-top-20 p-justify-center"
                    @click="addSlot">
                    <p class="scheduler-button-text color-blue">{{uppercase(t('new_slot'))}}</p>
                    <i class="pi pi-plus color-blue"></i>
                </div>
            </div>
        </div>
    </div>

    <template #footer>
        <div class="p-d-flex p-jc-end">
            <PadelButton :label="uppercase(t('delete'))" :type="'secondary'" v-if="form.id" @click="confirmDelete()"
                style="margin-right: 0.5rem" />
            <PadelButton :label="uppercase(form.id ? t('edit') : t('save'))"
                :disabled="isFormExceptionRuleEmpty(form) && isFormExceptionRuleValid(formErrors)" @click="save()" />
        </div>
    </template>
</DumbModal>

<div v-if="showDeleteDialog">
    <DumbModal :title="t('deleting_rule')" @hide="abortDelete()">
        <div class="modal-content p-text-center p-p-6">
            <i class="pi pi-exclamation-triangle color-warning" style="font-size: 2rem"></i>
            <p class="p-text-center color-white" v-html="t('deleting_exception_message', { name: form.name })"></p>
        </div>

        <template #footer>
            <div class="p-d-flex p-jc-end">
                <PadelButton :label="t('confirm')" @click="onDelete(form.id)" />
            </div>
        </template>
    </DumbModal>
</div>

<div v-if="showConflictDialog">
    <DumbModal :title="t('exception_overlap')" @hide="hideConflict()">
        <div class="modal-content p-text-center p-p-6">
            <i class="pi pi-exclamation-triangle color-warning" style="font-size: 2rem"></i>
            <p class="p-text-center color-white" v-html="t('exception_overlap_body', { name: form.name })"></p>
        </div>

        <template #footer>
            <div class="p-d-flex p-jc-end">
                <PadelButton :label="t('confirm')" @click="hideConflict()" />
            </div>
        </template>
    </DumbModal>
</div>