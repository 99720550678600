export function downloadFile(url: string, filename: string) {
    /* window.open(url); */
    const save = document.createElement('a');
    save.href = url;
    save.download = filename;
    document.body.appendChild(save);
    save.click();
    document.body.removeChild(save);
}

export function transformIntoArrayOfArrays(data) {
    const _provv = [];
    data.forEach((val, index) => {
        if (index === 0) {
            const row = [];
            const firstRow = [];
            Object.entries(val).forEach((innerVal) => {
                row.push(innerVal[0]);
                firstRow.push(innerVal[1]);
            })
            _provv.push(row);
            _provv.push(firstRow);
        } else {
            const row = [];
            Object.entries(val).forEach((innerVal) => {
                row.push(innerVal[1]);
            })
            _provv.push(row);
        }
    });
    return _provv;
}

export function convertArrayIntoCsv(data, filename: string) {
    // Building the CSV from the Data two-dimensional array
    // Each column is separated by ";" and new line "\n" for next row
    let csvContent = '';
    const transformedIntoArray = transformIntoArrayOfArrays(data);
    transformedIntoArray.forEach(function (infoArray, index) {
        const dataString = infoArray.join(';');
        csvContent += index < data.length ? dataString + '\n' : dataString;
    });

    // The download function takes a CSV string, the filename and mimeType as parameters
    // Scroll/look down at the bottom of this snippet to see how download is called
    const download = function (content, fileName, mimeType) {
        const a = document.createElement('a');
        mimeType = mimeType || 'application/octet-stream';

        if (navigator.msSaveBlob) { // IE10
            navigator.msSaveBlob(new Blob([content], {
                type: mimeType
            }), fileName);
        } else if (URL && 'download' in a) { //html5 A[download]
            a.href = URL.createObjectURL(new Blob([content], {
                type: mimeType
            }));
            a.setAttribute('download', fileName);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } else {
            location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
        }
    }
    download(csvContent, `${filename}.csv`, 'text/csv;encoding:utf-8');
}
