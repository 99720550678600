<template>
    <div class="avatar-container">
        <div class="avatar" 
            :style="{ backgroundImage: `url('${photo}')` }"
            v-if="photo"></div>
        <div class="avatar" 
            :style="{ backgroundImage: `url('https://www.w3schools.com/howto/img_avatar.png')` }"
            v-else></div>
    </div>
</template>

<script>
export default {
    name: 'Avatar',
    props: {
        photo: {
            type: String,
            required: false,
            default: null,
        },
    },
}
</script>

<style lang="scss" scoped>
@import '@/theme/fonts.styles.scss';
@import '@/theme/palette.styles.scss';
@import '@/theme/common.styles.scss';

.avatar-container {
    width: 180px;
    height: 180px;
    border-radius: 50%;
    border: 2px solid map-get($colors, lime-green);
    padding: 4px;

    .avatar {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
    }
}
</style>