<PadelLateralTab />
<div class="view-main-container">
    
    <div class="inner-view-container">
        <div class="nav-container flexbox-row justify-space-between padding-bottom-20 padding-top-20">
            <p class="title color-white">{{t('my_club')}}</p>
            <div class="cursor-pointer color-blue flexbox-row align-center" @click="openModal('ADD_CLUB')">
                <span class="button-text montserrat">{{uppercase(t('register_new_club'))}}</span>
                <span class="material-icons">add</span>
            </div>
        </div>
        <TabView class="tab-view" :activeIndex="selectedTab" @tab-change="onChangeTab($event.index)">
            <TabPanel>
                <template #header>
                    <span class="material-icons" :class="{'color-blue': selectedTab === 0}">villa</span>
                    <span :class="{'color-blue': selectedTab === 0}">{{uppercase(t('my_centre'))}}</span>                    
                </template>

                <ModifyClub v-if="selectedTab === 0" />
            </TabPanel>
            <TabPanel>
                <template #header>
                    <img src="@/theme/imgs/icon_campi.svg" alt=""
                        :class="{'filter-blue': selectedTab === 1}">
                    <span :class="{'color-blue': selectedTab === 1}">{{uppercase(t('my_playgrounds'))}}</span>
                </template>
                <ModifyPlaygrounds v-if="selectedTab === 1"
                    @selectTab="selectTab" />
            </TabPanel>
            <TabPanel>
                <template #header>
                    <img src="@/theme/imgs/regole_prezzi_orari.svg" alt=""
                        :class="{'filter-blue': selectedTab === 2}">
                    <span :class="{'color-blue': selectedTab === 2}">{{uppercase(t('prices_rules'))}}</span>
                </template>
                <PricesRules v-if="selectedTab === 2" />
            </TabPanel>
            <TabPanel>
                <template #header>
                    <img src="@/theme/imgs/calendario_prezzi_orari.svg" alt=""
                        :class="{'filter-blue': selectedTab === 3}">
                    <span :class="{'color-blue': selectedTab === 3}">{{uppercase(t('prices_calendar'))}}</span>
                </template>
                <PricesCalendar v-if="selectedTab === 3" />
            </TabPanel>
            <TabPanel>
                <template #header>
                    <span class="material-icons" :class="{'color-blue': selectedTab === 4}">settings</span>
                    <span :class="{'color-blue': selectedTab === 4}">{{uppercase(t('settings'))}}</span>
                </template>
                <ClubSettings v-if="selectedTab === 4" />
            </TabPanel>
        </TabView>
    </div>
</div>

<div v-if="openedModal === 'ADD_CLUB'">
    <DumbModal :title="t('register_new_club')"
        @hide="closeModal()">
        <div class="modal-text-container">
            <div class="color-white montserrat modal-text">{{t('add_new_club_text')}}</div>
            <div class="color-white montserrat modal-text margin-top-40">{{t('wanna_continue')}}</div>
        </div>    
        <div class="button-row flexbox-row justify-space-between">
            <p class="color-blue button-text montserrat cursor-pointer" @click="closeModal()">{{uppercase(t('abort'))}}</p>
            <PadelButton :label="uppercase(t('proceed'))"
                @click="goToAddClubPage()">
            </PadelButton>
        </div>
    </DumbModal>
</div>