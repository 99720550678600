
import { defineComponent } from "vue";

export default defineComponent({
  name: "PadelRadio",
  computed: {
    is__chipmode() {
      if (this.chipMode) {
        return "chip-mode";
      }
      return "";
    }    
  },
  methods: {
    getLabelClass(_value) {
      if(!this.chipMode) {
        return "container"
      }
      else { 
        if(_value.disabled) {
          return "container chip-mode disabled"
        } else {
          if(_value.value === this.value) {
            return "container chip-mode checked"
          } else {return "container chip-mode"}
        }
      }
    }
  },
  props: {
    value: { required: false, type: String, default: "" },
    options: { required: true, type: Array }, //di tipo RadioOption
    chipMode: { required: false, type: Boolean, default: false },
  },
  emits: ["input"],
});
