export enum ActionTypes {
    LOGIN = 'LOGIN', //chiamata per fare login
    CURRENT_AUTHENTICATED_USER = 'CURRENT_AUTHENTICATED_USER', //chiamata di cognito per avere i dati dell'utente correntemente loggato
    LOGOUT = 'LOGOUT', //chiamata per fare logout
    SIGNUP = 'SIGNUP', //chiamata per fare registrazione
    CONFIRM_SIGNUP = 'CONFIRM_SIGNUP', // chiamata per confermare registrazione
    RESEND_CODE = 'RESEND_CODE', //chiamata per reinviare il codice di registrazione
    AUTH_ACTION = 'AUTH_ACTION', //chiamata per avere i dati sull'utente loggato, da fare dopo la login
    CURRENT_SESSION = 'CURRENT_SESSION', //chiamata per avere i dati di sessione, in particolare l'idToken
    FORGOT_PASSWORD = 'FORGOT_PASSWORD', //chiamata per triggerare il flusso di password dimenticata
    FORGOT_PASSWORD_SUBMIT = 'FORGOT_PASSWORD_SUBMIT', //chiamata per inviare la nuova password
    COMPLETE_NEW_PASSWORD = 'COMPLETE_NEW_PASSWORD', //chiamata per completare la password in caso sia necessario resettarla, ad esempio l'admin alla prima login
    GET_CLUB_REQUESTS = 'GET_CLUB_REQUESTS', //chiamata per avere tutte le richieste di iscrizione a Wesmash
    GET_USER_INFO = 'GET_USER_INFO', //chiamata per avere le info sul singolo utente Wesmash
    CHECK_TOKEN_VALIDITY = 'CHECK_TOKEN_VALIDITY', //azione che controlla la validità del token, se non è valido ne chiede uno nuovo
    CHANGE_REQUEST_STATUS = 'CHANGE_REQUEST_STATUS', //chiamata per accettare/rifiutare richiesta iscrizione a wesmash
    MY_CLUBS = 'MY_CLUBS', //chiamata per ottenere la lista dei miei centri
    CHANGE_CLUB_INFO = 'CHANGE_CLUB_INFO', //chiamata per cambiare i dati di un centro
    GET_PLAYGROUNDS = 'GET_PLAYGROUNDS', //chiamata per avere i campi
    PLAYGROUND_DETAIL = 'PLAYGROUND_DETAIL', //chiamata per avere i dettagli di un campo
    ADD_PLAYGROUND = 'ADD_PLAYGROUND', //chiamata per aggiungere un nuovo campo
    CHANGE_PLAYGROUND_INFO = 'CHANGE_PLAYGROUND_INFO', //chiamata per modificare un campo
    DELETE_PLAYGROUND = 'DELETE_PLAYGROUND', //chiamata per eliminare un campo
    CHANGE_PROFILE_PIC = 'CHANGE_PROFILE_PIC', //cambia immagine profilo
    UPDATE_USER_ATTR = 'UPDATE_USER_ATTR', //chiamata di cognito per modificare un attributo
    VERIFY_USER_ATTR = 'VERIFY_USER_ATTR', //chiamata di cognito per verificare uno specifico attributo
    VERIFY_SMS_CODE = 'VERIFY_SMS_CODE', //chiamata API per fare verificare codice SMS a Backend
    POST_USER_INFO = 'POST_USER_INFO', //chiamata per cambiare i dati dell'utente
    ADD_NEW_CLUB = 'ADD_NEW_CLUB', //chiamata per creare un secondo centro
    GET_CALENDAR_EVENTS = 'GET_CALENDAR_EVENTS', //chiamata per avere tutti gli eventi di un club per un dato giorno
    GET_USERS = 'GET_USERS', //chiamata per ottenere tutti gli user di un determinato club
    GET_USERS_AXIOS = 'GET_USERS_AXIOS', //chiamata per ottenere tutti gli user di un determinato club
    GET_USER = 'GET_USER',
    BAN_USER = 'BAN_USER', //chiamata per bannare un utente
    UNBAN_USER = 'UNBAN_USER', //chiamata per togliere il ban su un utente
    GET_CLUB_STATS = 'GET_CLUB_STATS', //chiamata per ottenere le statistiche di un club
    GET_CUSTOMER_STATS = 'GET_CUSTOMER_STATS', //chiamata per ottenere le statistiche di un club
    ADD_BOOKING = 'ADD_BOOKING', //chiamata per creare un booking da backoffice
    MODIFY_BOOKING = 'MODIFY_BOOKING', //chiamata per modificare il booking
    GET_CLUBS = 'GET_CLUBS',  //chiamata della pagina di admin per avere la lista di tutti i club
    CLUB_DETAIL = 'CLUB_DETAIL', //chiamata della pagina di admin per avere il dettaglio di un singolo club
    DISABLE_CLUB = 'DISABLE_CLUB', //chiamata per disabilitare un club
    ENABLE_CLUB = 'ENABLE_CLUB', //chiamata per riabilitare un club
    SEND_OTP = 'SEND_OTP', //chiamata per mandare otp    
    DELETE_TIMESLOT = 'DELETE_TIMESLOT', //chiamata per eliminare un appuntamento da calendario
    CREATE_TOURNAMENT = 'CREATE_TOURNAMENT', //chiamata per creazione torneo
    MODIFY_TOURNAMENT = 'MODIFY_TOURNAMENT', //chiamata per modifica torneo
    DELETE_TOURNAMENT = 'DELETE_TOURNAMENT', //chiamata per eliminazione torneo
    GET_TOURNAMENTS = 'GET_TOURNAMENTS', //chiamata per lista tornei
    GET_TOURNAMENT_DETAILS = 'GET_TOURNAMENT_DETAILS', //chiamata per dettaglio singolo torneo
    GET_TOURNAMENT_TEAMS = 'GET_TOURNAMENT_TEAMS', //chiamata per lista team iscritti ad un torneo
    DELETE_TEAM = 'DELETE_TEAM', //chiamata per eliminare un team da un torneo
    UPLOAD_SCOREBOARD = 'UPLOAD_SCOREBOARD', //chiamata per effettuare il load del pdf tabellone

    GET_PRICES_RULES = 'GET_PRICES_RULES', // chiamata per lista delle regole di prezzi
    GET_PRICE_RULE = 'GET_PRICE_RULE',
    CREATE_PRICE_RULE = 'CREATE_PRICE_RULE', // chiamata per lista delle regole di prezzi
    UPDATE_PRICE_RULE = 'UPDATE_PRICE_RULE',
    DELETE_PRICE_RULE = 'DELETE_PRICE_RULE', // chiamata per lista delle regole di prezzi

    GET_EXCEPTION_RULES = "GET_EXCEPTION_RULES",
    CREATE_EXCEPTION_RULE = "CREATE_EXCEPTION_RULE",
    UPDATE_EXCEPTION_RULE = "UPDATE_EXCEPTION_RULE",
    DELETE_EXCEPTION_RULE = "DELETE_EXCEPTION_RULE",

    DUPLICATE_PLAYGROUND = 'DUPLICATE_PLAYGROUND', //chiamata per aggiungere un nuovo campo

    BOOKING_BUNDLE_CHECK = "BOOKING_BUNDLE_CHECK",
    BOOKING_BUNDLE_CONFIRM = "BOOKING_BUNDLE_CONFIRM",

    UPLOAD_BOOKING_CSV = "UPLOAD_BOOKING_CSV",

    GET_CARDS = "GET_CARDS",
    GET_CARD = "GET_CARD",
    CREATE_CARD = "CREATE_CARD",
    UPDATE_CARD = "UPDATE_CARD",
    DELETE_CARD = "DELETE_CARD",

    UPDATE_USER_CATEGORY = "UPDATE_USER_CATEGORY",

    GET_OPEN_GAMES = "GET_OPEN_GAMES",
    GET_OPEN_GAME = "GET_OPEN_GAME",
    SUBSCRIBE_TO_GAME = "SUBSCRIBE_TO_GAME",
    UNSUBSCRIBE_TO_GAME = "UNSUBSCRIBE_TO_GAME",

    ADMIN_GET_USER_STATS = 'ADMIN_GET_USER_STATS',

    NEXI_SAVE_CONFIG = "NEXI_SAVE_CONFIG",
    ENABLE_ONLINE_PAYMENT = "ENABLE_ONLINE_PAYMENT",
    FETCH_TRANSACTIONS = "FETCH_TRANSACTIONS",
    REFUND_TRANSACTION = "REFUND_TRANSACTION",
}
