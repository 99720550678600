<div v-if="showPreview" class="overlay-image">
    <div class="inner-pic-div">
        <img :src="addPlaygroundForm.profilePicture.src" class="image-preview" />
        <span class="material-icons icon-close" @click="closePreview()">
            clear
        </span>
    </div>
</div>

<div class="view-main-container flexbox-column justify-space-between height-100">
    <div class="top-section">
        <div class="nav-container flexbox-row justify-space-between padding-bottom-20 padding-top-20">
            <div class="logo-box">
                <img class="logo-img" src="@/theme/imgs/logo_completo.svg" alt="">
            </div>
            <Nav :dropdownChoices="dropdownChoices" />
        </div>
        <div class="title-container flexbox-column">
            <h3 class="step-text color-gold barlow margin-bottom-0 ">{{t('step_2')}}</h3>
            <p class="title-description color-white barlow margin-top-10 ">{{t('insert_playgrounds_info')}}</p>
        </div>
        <div class="playgrounds-container margin-top-0 margin-bottom-10">
            <template v-for="(element, index) in currentPlaygrounds">
                <PlaygroundCard :element="element" :index="index" @modifyPlayground="modifyPlayground(element, index)"
                    @deletePlayground="deletePlayground(index)" @copyPlayground="copyPlayground(element)" />
            </template>
            <div class="box add-playground" @click="openAddPlaygroundModal()">
                <i class="material-icons icon-add color-blue" :class="get__color">add</i>
                <p class="font-12 color-blue cursor-pointer bold-text">{{uppercase(t('add_playground'))}}</p>
            </div>
        </div>
    </div>
    <div class="button-container flexbox-row justify-space-between padding-bottom-20">
        <p class="font-12 color-blue cursor-pointer bold-text" @click="goBack()">{{uppercase(t('back'))}}</p>
        <PadelButton :label="currentPlaygrounds.length > 0 ? uppercase(t('save')) : uppercase(t('go_to_calendar'))"
            @click="sendForm()" />
    </div>
</div>

<!-- MODALE INSERISCI/MODIFICA CAMPO -->
<div v-if="modalToShow === 'ADD_PLAYGROUND'">
    <PlaygroundInfoModal :title="isModifyModal? t('modify_playground_title'): t('add_playground_title')"
        :form="addPlaygroundForm" :isModifyModal="isModifyModal" @hide="hideModal()"
        @formConfirmed="savePlayground($event)" />
</div>

<!-- MODALE FOTO MANCANTE -->
<div v-if="modalToShow === 'PHOTO_MISSING_MODAL'">
    <DumbModal :title="t('insert_playground_picture')" @hide="openAddPlaygroundModal()">
        <div class="modal-content-missing flexbox-column justify-space-between">
            <div class="top-part">
                <i class="material-icons icon-alert">warning_amber</i>
                <div class="color-white alert-text montserrat">{{t('no_pic_playground_text')}}</div>
            </div>
            <div class="buttons-container flexbox-row justify-space-between">
                <p class="abort" @click="openAddPlaygroundModal()">{{uppercase(t('close'))}}</p>
                <div class="flexbox-row">
                    <div class="margin-right-30">
                        <PadelUpload @change="updateFormValue($event, 'profilePicture')"
                            :label="uppercase(t('add_picture'))" :uploadedLabel="uppercase(t('picture_added'))"
                            @wrong-file-uploaded="handleWrongFileUploaded($event)">
                        </PadelUpload>
                    </div>
                    <PadelButton :label="uppercase(t('forward'))" @click="uploadDefaultPicAndSendForm()" />
                </div>
            </div>
        </div>
    </DumbModal>
</div>

