<div :class="'p-field ' + min__height + ' ' + containerClass">
  <div class="input__container">
    <div :class="'input__effect '">
      <span v-if="forcedPrefix" class="prefix" :class="is__disabled">{{forcedPrefix}}</span>
      <input :step="step" class="input" :type="inputType" :id="id" :value="value" :disabled="disabled"
        :placeholder="' '" @input="valueChange($event.target.value)" :class="'effect ' + is__password + ' ' + has__icon"
        @focus="toggleFocus()" @blur="blurValidate($event.target.value)" @keypress="restrictChars($event)"
        :autocomplete="autocomplete ? '' : 'new-password'"
        :min="min"
        :max="max" />
      <label :class="get__color + ' ' + is__forced__prefix">{{label}}</label>
      <span :class="'focus__border ' + get__color__span"></span>
    </div>
    <div class="input__hint">{{showError()}}</div>
    <i class="input__icon" :class="get__button__color + ' ' + is__clickable + ' ' + is__outlined"
      @click="emitButtonClick()" :id="customButtonId">{{icon}}</i>
  </div>
</div>