<div class="width-100">
    <div class="first-row margin-bottom-20">
        <ProgressBar class="progress-bar-container" :value="teamsList ? teamsList.length : 0"
            :maxValue="tournament.max_couples" :minValue="tournament.min_couples" />

        <div class="button export-button cursor-pointer display-flex" @click="downloadTeams()">
            <p class="button-text color-blue">
                {{uppercase(t('export'))}}</p>
            <i class="material-icons color-blue">file_download</i>
        </div>
    </div>
    <div class="table-main-container">
        <List :values="teamsList" :hoverEnabled="hoverEnabled" :emptyState="t('no_teams_registered')">
            <template class="header" v-slot:header>
                <th></th>
                <th>{{t('fullname')}}</th>
                <th>{{t('email')}}</th>
                <th>{{t('phonenumber_abbreviated')}}</th>
                <th>{{t('level')}}</th>
                <th>{{t('sex')}}</th>
                <th>{{t('team_name')}}</th>
                <th></th>
            </template>
            <template v-if="teamsList.length > 0" v-slot:tile="slotProps">
                <td class="width-1">
                    <div class="td-content">
                        <img class="td-content-row wesmash-logo" src="@/theme/imgs/logo_mark_negativo.svg" alt="">
                        <p class="td-content-row"></p>
                    </div>
                </td>
                <td>
                    <div class="td-content">
                        <p class="td-content-row">
                            <b>{{`${slotProps.value.players[0].name} ${slotProps.value.players[0].family_name}`}}</b>
                        </p>
                        <p class="td-content-row">
                            <b>{{`${slotProps.value.players[1].name} ${slotProps.value.players[1].family_name}`}}</b>
                        </p>
                    </div>
                </td>
                <td>
                    <div class="td-content">
                        <p class="td-content-row">{{slotProps.value.players[0].email}}</p>
                        <p class="td-content-row">-</p>
                    </div>
                </td>
                <td>
                    <div class="td-content">
                        <p class="td-content-row">{{slotProps.value.players[0].phone_number}}</p>
                        <p class="td-content-row">-</p>
                    </div>
                </td>
                <td>
                    <div class="td-content">
                        <p class="td-content-row">{{t(slotProps.value.players[0].exp_level != null ? slotProps.value.players[0].exp_level.description : slotProps.value.players[0].level)}}</p>
                        <p class="td-content-row">{{t(slotProps.value.players[1].exp_level != null ? slotProps.value.players[1].exp_level.description : slotProps.value.players[1].level)}}</p>
                    </div>
                </td>
                <td>
                    <div class="td-content">
                        <p class="td-content-row">{{t(slotProps.value.players[0].gender + '_GENDER')}}</p>
                        <p class="td-content-row">{{t(slotProps.value.players[1].gender + '_GENDER')}}</p>
                    </div>
                </td>
                <td>
                    <div class="td-content">
                        <p class="td-content-row">{{slotProps.value.team_name}}</p>
                        <p class="td-content-row">{{slotProps.value.team_name}}</p>
                    </div>
                </td>
                <td class="width-1">
                    <div class="td-content">
                        <span class="icon-delete material-icons"
                            @click="handleDeleteClick(slotProps.value)">delete</span>
                        <p class="td-content-row"></p>
                    </div>
                </td>

            </template>
        </List>

    </div>

</div>

<div v-if="modalToShow === 'SURE_WANNA_DELETE_TEAM'">
    <DumbModal :title="t('eliminate_team')" @hide="hideModal()">
        <div class="modal-content flexbox-column justify-space-between">
            <div class="top-part">
                <i class="material-icons icon-alert">warning_amber</i>
                <div class="color-white alert-text montserrat">{{t('attention')}}</div>
                <div class="color-white modal-text montserrat">{{t('delete_team_text')}}</div>
            </div>
        </div>
        <div class="buttons-container flexbox-row justify-space-between">
            <p class="abort" @click="hideModal()">{{uppercase(t('abort'))}}</p>
            <div class="flexbox-row">
                <PadelButton :label="uppercase(t('confirm'))" @click="deleteTeam()" />
            </div>
        </div>
    </DumbModal>
</div>

