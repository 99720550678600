
import { defineComponent, ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { AdminModalTypes } from "@/constants/modal-types.enum";
import { uppercase } from "@/services/mapping.service";
import { useStore } from "@/store/store";
import {
  formatDateDDMMYYYY,
  formatDateYYYYMMDD,
} from "@/services/dates.service";
import { ClubRequestTypes } from "@/constants/request-types.enum";
import { ActionTypes } from "@/store/action-types";
import { AdminFilters } from "@/models/filters.models";
import { MutationTypes } from "@/store/mutation-types";
import { adminFilterOptions } from "@/constants/radio-options.constants";
import { REQUESTS_PAGE_SIZE } from "@/constants/constants.constants";

export default defineComponent({
  props: {
    forceUnmountInfiniteScroll: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup() {
    const isClubs = ref(null);
    const store = useStore();
    const { t, locale } = useI18n();
    const confirmTitle = ref(null);
    const rejectTitle = ref(null);
    const iscriptionRequest = ref(null);

    const selectedClubRequest = ref(null);
    const modalToShow = ref(null);
    const detailOpened = ref(false);
    const isVisible = ref(true);

    const activeFilters = ref(null);
    const filtersForm = ref({});
    const radioOptions = ref(adminFilterOptions);
    const hiddenFilters = ref({ status: ClubRequestTypes.ACCEPTABLE });

    /* COMPUTED */
    const currentPage = computed(function() {
      return store.getters.getJoinWesmashRequestsPage;
    });

    const creationRequests = computed(function() {
      const array = [];
      const requests = store.getters.getJoinWesmashRequests;
      requests.forEach((request) => {
        array.push({
          club_address: request.club_address.address,
          club_id: request.club_id,
          club_name: request.club_name,
          date: formatDateDDMMYYYY(request.date),
          requester_email: request.requester_email,
          requester_id: request.requester_id,
          status: request.status,
        });
      });
      return array;
    });

    // FORMATTAZIONE FILTRI CHE PASSA SEMPRE I DUE STATI REJECTED E PENDING SE MANCA FILTRO STATUS
    function formatFilters(filters) {
      const _filters = filters ? { ...filters } : hiddenFilters.value;
      if (_filters.date) {
        if (!_filters.status) {
          _filters.status = hiddenFilters.value.status;
        }
        _filters.date = formatDateYYYYMMDD(_filters.date);
      }
      return _filters;
    }

    function constructPayload(filters, isScroll) {
      const payload = {
        ...filters,
        page_size: REQUESTS_PAGE_SIZE,
      };
      if (isScroll) {
        payload["last_evaluated_key"] = currentPage.value.last_evaluated_key;
      }
      return payload;
    }

    async function fetchRequests(filters: AdminFilters, isScroll = false) {
      try {
        if (
          !isScroll ||
          (isScroll && typeof currentPage.value.last_evaluated_key) !==
            "undefined"
        ) {
          const requestsArray = await store.dispatch(
            ActionTypes.GET_CLUB_REQUESTS,
            constructPayload(formatFilters(filters), isScroll)
          );
          if (isScroll) {
            store.commit(MutationTypes.ADD_CLUB_REQUESTS, requestsArray);
          } else {
            store.commit(MutationTypes.WRITE_CLUB_REQUESTS, requestsArray);
          }
          return requestsArray;
        }
      } catch (error) {
        return;
      }
    }

    function resetLastEvaluatedKey() {
      store.commit(MutationTypes.RESET_LAST_EVALUATED_KEY, null);
    }

    watch(activeFilters, async function(currentValue, oldValue) {
      resetLastEvaluatedKey();
      await fetchRequests(currentValue);
    });

    function initializeActiveFilters() {
      activeFilters.value = null;
    }

    function setFilterFormValue(key: string, value) {
      if (key === "status" || (key === "date" && value.getTime)) {
        filtersForm.value[key] = value;
      }
    }

    function openFilterModal() {
      modalToShow.value = AdminModalTypes.FILTER;
    }
    function initializeFiltersForm() {
      filtersForm.value = {};
    }
    function hideModal() {
      isVisible.value = true;
      modalToShow.value = null;
      detailOpened.value = false;
      initializeFiltersForm();
    }

    async function applyFilters() {
      activeFilters.value = filtersForm.value;
      hideModal();
    }

    async function abortFilters() {
      initializeActiveFilters();
      hideModal();
    }

    function removeFilter(filter) {
      if (Object.keys(activeFilters.value).length === 1) {
        initializeActiveFilters();
      } else {
        if (filter.key === "status") {
          activeFilters.value = { date: activeFilters.value.date };
        } else if (filter.key === "date") {
          activeFilters.value = { status: activeFilters.value.status };
        }
      }
    }

    function transformObjIntoArray(data) {
      return Object.entries(data).map(([key, value]) => ({ key, value }));
    }

    function formatValue(value) {
      if (value.getTime) {
        return formatDateDDMMYYYY(value.toISOString());
      } else return value;
    }

    async function getUserInfo(id: string) {
      try {
        const userInfo = await store.dispatch(ActionTypes.GET_USER_INFO, id);
        return userInfo;
      } catch (error) {
        return;
      }
    }

    async function changeRequestStatus(_status: string, _club_id: string) {
      try {
        const changedStatus = await store.dispatch(
          ActionTypes.CHANGE_REQUEST_STATUS,
          {
            status: _status,
            club_id: _club_id,
          }
        );
        return changedStatus;
      } catch (error) {
        return;
      }
    }

    async function getRequest(data) {
      const selectedRequest = creationRequests.value.find(
        (element) => element.club_id === data.club_id
      );
      try {
      const userInfo = await getUserInfo(selectedRequest.requester_id);
      selectedClubRequest.value = {
        club_id: selectedRequest.club_id,
        name: userInfo.name,
        surname: userInfo.family_name,
        phonenumber: userInfo.phone_number,
        email: selectedRequest.requester_email,
        clubName: selectedRequest.club_name,
        address: selectedRequest.club_address,
      };
      return userInfo;
      } catch(err) {
        return;
      }

    }

    async function rowClicked(data) {
      try {
             await getRequest(data);
      if (!modalToShow.value) {
        detailOpened.value = true;
      }
      } catch(err) {
        return;
      }
 
    }

    function setChangeStatusModalValues(data) {
      selectedClubRequest.value = {
        club_id: data.club_id,
        clubName: data.club_name,
      };
    }

    function openConfirmModal(data) {
      /*  await getRequest(data); */
      setChangeStatusModalValues(data);
      modalToShow.value = AdminModalTypes.CONFIRM;
    }
    function openDeleteModal(data) {
      /*  await getRequest(data); */
      setChangeStatusModalValues(data);
      modalToShow.value = AdminModalTypes.REJECT;
    }
    function openConfirmOkModal() {
      modalToShow.value = AdminModalTypes.CONFIRMED_OK;
    }
    function openDeleteOkModal() {
      modalToShow.value = AdminModalTypes.REJECTED_OK;
    }
    async function confirmClub(club_id: string) {
      try {
        await changeRequestStatus("approve", club_id);
        await fetchRequests(hiddenFilters.value);
        openConfirmOkModal();
      } catch (e) {
        return;
      }
    }
    async function rejectClub(club_id: string) {
      try {
        await changeRequestStatus("reject", club_id);
        await fetchRequests(hiddenFilters.value);
        openDeleteOkModal();
      } catch (e) {
        return;
      }
    }

    async function loadMoreRequests() {
      await fetchRequests(activeFilters.value, true);
    }

    function returnChipClass(category: string) {
      if (category === ClubRequestTypes.PENDING) {
        return "background-orange";
      } else if (category === ClubRequestTypes.REJECTED) {
        return "background-red";
      } else if (category === ClubRequestTypes.APPROVED) {
        return "background-green";
      } else {
        return "";
      }
    }

    return {
      t,
      locale,
      uppercase,
      confirmTitle,
      rejectTitle,
      iscriptionRequest,
      selectedClubRequest,
      creationRequests,
      isClubs,
      modalToShow,
      detailOpened,
      isVisible,
      rowClicked,
      hideModal,
      openConfirmModal,
      openDeleteModal,
      confirmClub,
      rejectClub,
      loadMoreRequests,
      returnChipClass,
      formatValue,
      setFilterFormValue,
      openFilterModal,
      applyFilters,
      abortFilters,
      removeFilter,
      transformObjIntoArray,
      activeFilters,
      filtersForm,
      radioOptions,
    };
  },
});
