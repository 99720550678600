
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { PadelClub } from "@/models/club.models";
import { PadelPlayground } from "@/models/playground.models";
import { ActionTypes } from "@/store/action-types";
import {
  emptyExceptionRuleForm,
  emptyExceptionRuleFormErrors,
  emptySlotItem,
  emptySlotItemErrors,
} from "@/constants/initial-values.constants";
import { PlaygroundSports } from "@/constants/playground.enums";
import {
  isTimeBeforeStart,
  isTimeAfterEnd,
  isTimeInsideAnotherSlot,
  isTimeLowerThan,
  durationIsHigherThan,
} from "@/services/dates.service";
import {
  isFormExceptionRuleEmpty,
  isFormExceptionRuleValid,
} from "@/services/form-validation.service";
import { uppercase } from "@/services/mapping.service";

export default defineComponent({
  name: "ExceptionRuleDialog",
  props: ["exceptionRule", "selectedDate"],
  emits: ["hide"],
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const { t, locale } = useI18n();
    const showDeleteDialog = ref(false);
    const showConflictDialog = ref(false);
    const selectectedSport = props.exceptionRule.sport_type;
    const SportOptions = ref([
      {
        label: "Padel",
        value: PlaygroundSports.PADEL,
        disabled: false,
      },
    ]);
    const form = ref(JSON.parse(JSON.stringify(props.exceptionRule)));
    const formErrors = ref(
      JSON.parse(JSON.stringify(emptyExceptionRuleFormErrors))
    );
    form.value.exception_at = new Date(form.value.exception_at);

    // ITERATE
    for (let i = 0; i < form.value.slots.length; i++) {
      formErrors.value.slots.push(
        JSON.parse(JSON.stringify(emptySlotItemErrors))
      );
    }

    /* COMPUTED */
    const myClub = computed(function (): PadelClub {
      return store.getters.getMyClub;
    });
    const myPlaygrounds = computed(function (): PadelPlayground[] {
      return store.getters.getMyPlaygrounds;
    });
    const PlaygroundsOptions = computed(function (): Object[] {
      return myPlaygrounds.value
        .filter((el) => {
          return el.sport == props.exceptionRule.sport_type;
        })
        .map((el) => {
          return { value: el.id, label: el.name };
        });
    });
    const openingHour = computed(function (): Number {
      return myClub.value.opening_hour / 1000 / 60 / 60;
    });
    const closingHour = computed(function (): Number {
      return myClub.value.closing_hour / 1000 / 60 / 60;
    });

    // FUNCTIONS
    function hideModal(reload = false) {
      showDeleteDialog.value = false;
      emit("hide", reload);
    }

    function handleValidation(field) {
      switch (field) {
      }
    }

    function handleSlotValidation(field, index) {
      const slot = form.value.slots[index];
      switch (field) {
        case "start_at":
          if (isTimeAfterEnd(slot.start_at, slot.end_at)) {
            updateSlotError("start_at", t("cannot_slot_after_end"), index);
          } else if (isTimeInsideAnotherSlot(index, slot, form.value.slots)) {
            updateSlotError(
              "start_at",
              t("slot_interfere_with_another"),
              index
            );
          } else if (isTimeLowerThan(slot.start_at, slot.end_at, 60)) {
            updateSlotError(
              "start_at",
              t("cannot_slot_end_at_least", { minutes: 60 }),
              index
            );
          } else {
            updateSlotError("start_at", "", index);
          }
          break;
        case "end_at":
          if (isTimeBeforeStart(slot.end_at, slot.start_at)) {
            updateSlotError("end_at", t("cannot_slot_after_end"), index);
          } else if (isTimeInsideAnotherSlot(index, slot, form.value.slots)) {
            updateSlotError("end_at", t("slot_interfere_with_another"), index);
          } else if (isTimeLowerThan(slot.start_at, slot.end_at, 60)) {
            updateSlotError(
              "end_at",
              t("cannot_slot_end_at_least", { minutes: 60 }),
              index
            );
          } else {
            updateSlotError("end_at", "", index);
          }
          break;
      }
    }

    function updateFormError(field, error) {
      formErrors.value[field] = error;
    }

    function updateSlotError(field, error, index) {
      formErrors.value.slots[index][field] = error;
    }

    function updateFormValue(value, field, validate = false) {
      form.value[field] = value;
      if (validate) {
        handleValidation(field);
      }
    }

    function updateSlotValue(
      index: number,
      value: string,
      field: string,
      validate = false
    ) {
      form.value.slots[index][field] = value;
      if (validate) {
        if (field === "start_at" || field === "end_at") {
          let duration60 = durationIsHigherThan(form.value.slots[index], 60);
          let duration90 = durationIsHigherThan(form.value.slots[index], 90);
          let duration120 = durationIsHigherThan(form.value.slots[index], 120);

          // --
          form.value.slots[index].show_duration_60 = duration60;
          form.value.slots[index].show_duration_90 = duration90;
          form.value.slots[index].show_duration_120 = duration120;
        }
        handleSlotValidation(field, index);
      }
    }

    function addSlot() {
      form.value.slots.push(JSON.parse(JSON.stringify(emptySlotItem)));
      formErrors.value.slots.push(
        JSON.parse(JSON.stringify(emptySlotItemErrors))
      );
    }

    function removeSlot(index) {
      form.value.slots.splice(index, 1);
      formErrors.value.slots.splice(index, 1);
    }

    function save() {
      // INIT
      const body = JSON.parse(JSON.stringify(form.value));
      delete body.id;
      body.slots.forEach((slot: any) => {
        delete slot.duration_60;
        delete slot.duration_90;
        delete slot.duration_120;
        delete slot.show_duration_60;
        delete slot.show_duration_90;
        delete slot.show_duration_120;

        // --
        if (slot.price_60 === null || slot.price_60 === "") {
          slot.price_60 = -1;
        } else {
          slot.price_60 = slot.price_60 * 100;
        }
        if (slot.price_90 === null || slot.price_90 === "") {
          slot.price_90 = -1;
        } else {
          slot.price_90 = slot.price_90 * 100;
        }
        if (slot.price_120 === null || slot.price_120 === "") {
          slot.price_120 = -1;
        } else {
          slot.price_120 = slot.price_120 * 100;
        }
      });

      // DISPATCH
      if (form.value.id) {
        store
          .dispatch(ActionTypes.UPDATE_EXCEPTION_RULE, {
            id: form.value.id,
            body: body,
          })
          .then(() => {
            hideModal(true);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 409) {
                console.log(error.response);
                showConflictDialog.value = true;
              }
            }
          });
      } else {
        store
          .dispatch(ActionTypes.CREATE_EXCEPTION_RULE, body)
          .then(() => {
            hideModal(true);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 409) {
                console.log(error.response);
                showConflictDialog.value = true;
              }
            }
          });
      }
    }

    function confirmDelete() {
      showDeleteDialog.value = true;
    }

    async function onDelete() {
      try {
        await store.dispatch(ActionTypes.DELETE_EXCEPTION_RULE, {
          id: form.value.id,
        });

        // SET
        hideModal(true);
      } catch (error) {
        console.error(error);
        return;
      }
    }

    function abortDelete() {
      showDeleteDialog.value = false;
    }

    function hideConflict() {
      showConflictDialog.value = false;
    }

    // SYSTEM
    onMounted(() => {});

    return {
      t,
      locale,
      uppercase,

      // REFS
      form,
      formErrors,
      SportOptions,
      selectectedSport,
      myClub,
      myPlaygrounds,
      PlaygroundsOptions,
      openingHour,
      closingHour,
      showDeleteDialog,
      showConflictDialog,

      // METHODS
      hideModal,
      updateFormValue,
      updateSlotValue,
      addSlot,
      removeSlot,
      save,
      confirmDelete,
      onDelete,
      abortDelete,
      hideConflict,
      isFormExceptionRuleEmpty,
      isFormExceptionRuleValid,
    };
  },
});
