<PadelLateralTab />
<div class="view-main-container height-100">
    <div class="inner-view-container">
        <template v-if="tournaments.length > 0 || archivedTournaments.length > 0">
            <div class="main-tournaments-container flexbox-column"
                :class="selectedView === 'FIRST_PAGE' ? '' : 'display-none'">
                <div class="nav-container flexbox-row justify-space-between padding-bottom-20 padding-top-20">
                    <p class="title color-white barlow">{{t('tournaments')}}</p>
                </div>
                <div class="active-tournaments-container width-100">
                    <template v-for="tournament in tournaments">
                        <TournamentCard :club_picture_ref="myClub.cover_pic_ref" :element="tournament"
                        @deleteTournament="handleDeleteTournament(tournament)" 
                        @modifyTournament="modifyTournament(tournament)" />
                    </template>
                    <div class="box-add justify-center cursor-pointer" @click="changeView('ADD_TOURNAMENT_FIRST')">
                        <i class="material-icons icon-add color-blue" :class="get__color">add</i>
                        <p class="font-12 color-blue cursor-pointer bold-text montserrat">
                            {{uppercase(t('add_tournament'))}}</p>
                    </div>
                </div>
                <div class="tournaments-history width-100">
                    <p class=" color-white montserrat text-initial">{{t('tournaments_archive')}}</p>
                    <div v-if="archivedTournaments.length > 0" class="active-tournaments-container width-100">
                        <template v-for="tournament in archivedTournaments">
                            <div class="archive-box">
                                <div class="flexbox-row justify-space-between width-100">
                                    <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-5">
                                        {{tournament.name}}</p>
                                    <span class="icon-copy material-icons"
                                        @click="copyTournament(tournament)">content_copy</span>
                                </div>
                                <p class="box-row color-white margin-0 barlow">{{t('typology') + ':'}} <b
                                        class="montserrat">{{t(tournament.type)}}</b></p>
                                <p class="box-row color-white margin-0 barlow">{{t('start_date') + ':'}} <b
                                        class="montserrat">{{(formatDateTournament(tournament.start_at))}}</b></p>
                                <div class="flexbox-row align-center chip-row">
                                    <p class="box-row color-white margin-0 barlow margin-right-10">
                                        {{t('status') + ':'}}</p>
                                    <!--<Chip class="chip" :label="uppercase(t(returnStatus(tournament.status)))"
                                        :class="returnChipClass(returnStatus(tournament.status))" />-->
                                </div>

                            </div>
                        </template>
                    </div>
                    <div v-else>
                        <p class="color-white montserrat text-empty-archive">{{t('empty_tournament_archive')}}</p>
                    </div>
                </div>
            </div>

        </template>

        <div v-else-if="showTemplate && tournaments.length === 0" class="empty-tournaments-container"
            :class="selectedView === 'FIRST_PAGE' ? '' : 'display-none'">
            <img src="@/theme/imgs/pallina_empty.svg" alt="">
            <div class="color-white empty-text-title margin-top-20">{{t('tournaments_missing')}}.</div>
            <div class="color-white empty-text">{{t('start_handle_tournaments')}}.</div>

            <div class="scheduler-button new-event-button cursor-pointer display-flex margin-top-20"
                @click="changeView('ADD_TOURNAMENT_FIRST')">
                <p class="scheduler-button-text color-blue">{{uppercase(t('add_tournament'))}}</p>
                <i class="pi pi-plus color-blue"></i>
            </div>
        </div>
        <div class="add-tournament-container flexbox-column"
            :class="selectedView === 'ADD_TOURNAMENT_FIRST' ? '' : 'display-none'">
            <div class="header flexbox-row padding-top-40 padding-bottom-40">
                <div class="cursor-pointer flexbox-row align-center" @click="goToFirstPage()">
                    <i class="material-icons color-blue cursor-pointer margin-right-10">keyboard_arrow_left</i>
                    <div class="go-back-text color-blue">{{uppercase(t('abort'))}}</div>
                </div>
            </div>
            <AddTournament :openingTime="openTime" :closingTime="closingTime" :playgroundOptions="playgroundOptions"
                :playgroundMap="playgroundMap" :forceReset="forceResetAddTournament" :forceInitialize="forceInitializeAddTournament"
                :tournamentInitializer="tournamentInitializer"
                @creation-completed="goToFirstPage()"  />
        </div>
        <div class="modify-tournament-container flexbox-column"
            :class="selectedView === 'MODIFY_TOURNAMENT' ? '' : 'display-none'"
            v-if="selectedView === 'MODIFY_TOURNAMENT'">
            
            <ModifyTournament :tournament="tournamentToModify" @goToFirstPage="goToFirstPage()"
            @tournamentChanged="onTournamentChanged($event)" @teamDeleted="getMyTournaments()"
             :playgroundOptions="playgroundOptions" :playgroundMapFull="playgroundMapFull" />
        </div>
    </div>
</div>

    <!-- MODALE CANCELLAZIONE TORNEO -->
    <div v-if="openedModal === 'DELETE_TOURNAMENT'">
        <DumbModal :title="t('tournament_deletion')" @hide="hideModal()">
            <div class="modal-content flexbox-column justify-space-between">
                <div class="top-part">
                    <i class="material-icons icon-alert">warning_amber</i>
                    <div class="color-white alert-text montserrat">{{t('attention')}}</div>
                    <div class="color-white modal-text montserrat" 
                    v-html="t('delete_tournament_text', {tournament_name: tournamentToDelete.name})"></div>
                    <div class="color-white modal-text montserrat">{{t('sure_wanna_continue_timeslot_delete')}}</div>
                </div>
                <div class="buttons-container flexbox-row justify-space-between">
                    <p class="abort" @click="hideModal()">{{uppercase(t('close'))}}</p>
                    <PadelButton :label="uppercase(t('confirm'))" 
                    @click="deleteTournament()" />
                </div>
            </div>
        </DumbModal>
    </div>