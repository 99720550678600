
import { useStore } from "@/store/store";
import { useRouter } from "vue-router";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { uppercase } from "@/services/mapping.service";
import { sideNavButtons } from "@/constants/constants.constants";
import { downloadFile } from "@/services/files.service";

export default defineComponent({
  name: "PadelLateralTab",
  setup() {
    const store = useStore();
    const router = useRouter();
    const isHover = ref(false);
    const selectedTab = ref(null);
    const { t, locale } = useI18n();

    onMounted(() => {
      const route = router.currentRoute;
      selectedTab.value = route.value.name;
    });

    function isTabSelected(tab: string) {
      if (selectedTab.value === tab) {
        return "selected";
      } else {
        return "";
      }
    }

    function downloadFileFromS3() {
      downloadFile(process.env.VUE_APP_WESMASH_GUIDE_LINK, "MANUALE DI UTILIZZO WESMASH.pdf");
    }

    function goToPage(nav: string) {
      //SE CLICCO SULLA STESSA PAGINA FORZO IL REFRESH
      isHover.value = false;
      if (
        (router.currentRoute.value.name as string).toLowerCase() === nav.toLowerCase()) {
        router.go(0);
      } else {
        router.push({ name: nav });
      }
    }

    const user = computed(function() {
      return store.getters.getUser;
    });

    return {
      t,
      locale,
      uppercase,
      isTabSelected,
      isHover,
      selectedTab,
      sideNavButtons,
      goToPage,
      user,
      downloadFileFromS3
    };
  },
});
