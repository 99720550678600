
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { uppercase } from "@/services/mapping.service";
export default defineComponent({
  props: ["tournament", "playgroundMapFull", "playgroundOptions"],
  emits: ["goToFirstPage", "tournamentChanged", "teamDeleted"],
  setup(props, { emit }) {
    const selectedTab = ref(0);
    const isFormDirty = ref(false);
    const modalToShow = ref(null);
    const { t, locale } = useI18n();

    function openModal(modal: string) {
      modalToShow.value = modal;
    }

    function hideModal() {
      modalToShow.value = null;
    }

    function changeFormDirty(event) {
      isFormDirty.value = event;
    }

    function onChangeTab(event) {
      changeFormDirty(false);
      selectedTab.value = event;
    }

    function goToFirstPage() {
      emit("goToFirstPage", true);
    }

    function handleBackClick() {
      if (isFormDirty.value) {
        openModal("SURE_WANNA_CONFIRM");
      } else {
        goToFirstPage();
      }
    }

    function onTournamentChanged(event) {
      emit("tournamentChanged", event);
    }

    function onTeamDeleted() {
      emit("teamDeleted", true);
    }

    return {
      t,
      locale,
      uppercase,
      selectedTab,
      isFormDirty,
      modalToShow,

      onChangeTab,
      goToFirstPage,
      handleBackClick,
      changeFormDirty,
      hideModal,
      onTournamentChanged,
      onTeamDeleted
    };
  },
});
