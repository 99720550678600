
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { 
    computed, 
    defineComponent, 
    nextTick, 
    onMounted, 
    ref,
    watch,
} from "vue";
import { useRouter } from "vue-router";
import {
    emptyCreatePlayerType,
    emptyCreatePlayerTypeErrors,
} from "@/constants/initial-values.constants";
import {
    PlayerCategoriesOptions,
    ClubCategoriesOptions,
    ForewarningOptions,
} from "@/constants/constants.constants";
import {
    isFormPlayerTypeEmpty,
    isFormPlayerTypeValid,
} from "@/services/form-validation.service";
import { PadelClub } from "@/models/club.models";
import { ActionTypes } from "@/store/action-types";
import UserTypeCard from "@/components/user-type-card/UserTypeCard.vue";
import { uuid } from 'vue-uuid';
import { CardCategories } from "@/constants/card-types.enum";

export default defineComponent({
    name: "ClubSettings",
    components: {
        UserTypeCard,
    },
    setup() {
        const store         = useStore();
        const { t, locale } = useI18n();
        const router        = useRouter();
        const dialog        = ref(null);
        const form          = ref(
            JSON.parse( JSON.stringify(emptyCreatePlayerType) )
        );
        const formErrors    = ref(
            JSON.parse( JSON.stringify(emptyCreatePlayerTypeErrors) )
        );
        const category      = ref(null);
        

        /* COMPUTED */
        const myClub = computed(function(): PadelClub {
            return store.getters.getMyClub;
        });
        const types = computed(function(): Array<object> {
            return myClub.value.player_categories;
        });

        // SYSTEM
        onMounted(() => {
        });
        
        // METHODS
        function resetForm(withId = false) {
            form.value = JSON.parse( JSON.stringify(emptyCreatePlayerType) );
            formErrors.value = JSON.parse( JSON.stringify(emptyCreatePlayerTypeErrors) );

            if (withId) {
                form.value.code = uuid.v1();
            }
        }

        function hideDialog() {
            dialog.value = null;
            resetForm();
        }

        function setFormValue(key: string, value) {
            
            if (['forewarning'].includes(key)) {
                mapAndSave(null, value, myClub.value.club_category);
            } 
            else if (['club_category'].includes(key)) {
                mapAndSave(null, myClub.value.forewarning, value);
            }else {
                form.value[key] = value;
                if (!value) {
                    formErrors.value[key] = t('required_field');
                }
            }
        }

        function createType() {
            resetForm(true);
            dialog.value = 'CREATE';
        }

        function editType(type) {
            form.value = JSON.parse( JSON.stringify(type) );
            dialog.value = 'CREATE';
        }

        function deleteType(type) {
            category.value = JSON.parse( JSON.stringify(type) );
            dialog.value = 'DELETE';
        }
        
        async function mapAndSave(categories = null, forewarning = null, club_category = null) {
            // BODY
            if (categories == null) categories = myClub.value.player_categories;
            if (forewarning == null) forewarning = myClub.value.forewarning;

            
            const payload = {
                phone_number: myClub.value.club_phone_number,
                email: myClub.value.email,
                opening_hour: myClub.value.opening_hour,
                closing_hour: myClub.value.closing_hour,
                services: myClub.value.services,
                player_categories: categories,
                forewarning: forewarning,
                club_category: club_category != null ? club_category : CardCategories.INSTITUTIONAL,
            };
            if (myClub.value.club_notes) {
                payload["club_notes"] = myClub.value.club_notes;
            }
            
            
            // DISPATCH
            try {
                const res = await store.dispatch(ActionTypes.CHANGE_CLUB_INFO, {
                    club_id: myClub.value.club_id,
                    request: payload,
                });
                const clubs = await store.dispatch(ActionTypes.MY_CLUBS, null);
                dialog.value = 'SUCCESS';
            } catch (error) {
                console.error(error);
            }
        }

        function save() {
            // REMAP
            const categories = (myClub.value.player_categories || []);
            const index      = categories.findIndex(el => el.code === form.value.code);
            if (index > -1) {
                categories[index].name = form.value.name;
                categories[index].type = form.value.type;
            } else {
                categories.push(form.value);
            }
            
            // EXECUTE
            mapAndSave(categories);
        }

        function confirmDelete() {
            // REMAP
            const categories = (myClub.value.player_categories || []);
            const index      = categories.findIndex(el => el.code === category.value.code);
            if (index > -1) {
                categories.splice(index, 1);
            }

            // EXECUTE
            mapAndSave(categories);
        }



        /** WATCH */
        

        return {
            t,
            locale,
            moment,

            // DATA
            myClub,
            types,
            dialog,
            form,
            formErrors,
            PlayerCategoriesOptions,
            ClubCategoriesOptions,
            ForewarningOptions,
            category,

            // METHODS
            hideDialog,
            setFormValue,
            createType,
            editType,
            deleteType,
            save,
            confirmDelete,
            isFormPlayerTypeEmpty,
            isFormPlayerTypeValid,
        };
    },
});
