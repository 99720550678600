
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { adminDropdownChoices } from "@/constants/dropdown-choices.constants";
import { useStore } from "@/store/store";
import {
  formatItalianPhone,
  mapCognitoError,
  mapInputError,
  uppercase,
} from "@/services/mapping.service";
import {
  changeProfileInfoSchemaValidation,
  isChangeInfoFormValid,
  confirmsignupSchemaValidation,
} from "@/services/form-validation.service";
import { showToast } from "@/services/error-management.service";
import { ToastErrors } from "@/constants/generic-errors.enum";
import { ActionTypes } from "@/store/action-types";
import { useI18n } from "vue-i18n";
import { BackEndUser } from "@/models/user.models";
import { changeInfoEmpty } from "@/constants/initial-values.constants";
import { OTP_WAIT_TIME_IN_SECONDS } from "@/constants/constants.constants";
import { returnNavTooltipChoices } from "@/services/routing.service";
import { FIXED_PHONE_PREFIX } from '@/constants/constants.constants';
export default defineComponent({
  setup() {
    const store = useStore();
    const formValues = ref({ ...changeInfoEmpty });
    const formErrors = ref({ ...changeInfoEmpty });
    const confirmCodeValue = ref("");
    const confirmCodeError = ref("");
    const formDisabled = ref(true);
    const sendCodeAllowed = ref(true);
    const saveDisabled = ref(true);
    const confirmPhoneModalOpened = ref(false);
    const cognitoUserInfo = ref(null);
    const dropdownChoices = ref(returnNavTooltipChoices());
    const { t, locale } = useI18n();

    /* COMPUTED */
    const userInfo = computed(function(): BackEndUser {
      return store.getters.getUser;
    });

    function compareUserToForm(form) {
      let _provv = true;
      Object.entries(form).forEach((val) => {
        if (val[1] !== userInfo.value[val[0]]) {
          _provv = false;
        }
      });
      return _provv;
    }

    watch(formValues.value, function(currentValue, oldValue) {
      saveDisabled.value = compareUserToForm(currentValue);
    });

    function initializeFormWithValuesFromStore() {
      formValues.value.name = store.getters.getUser.name;
      formValues.value.surname = store.getters.getUser.family_name;
      formValues.value.phonenumber = store.getters.getUser.phone_number.replace("+39", "");
    }

    function initializeErrors() {
      formErrors.value = { ...changeInfoEmpty };
    }

    //AL MOUNTED PREPOPOLA FORM CON VALORI DELL'UTENTE
    onMounted(() => {
      initializeFormWithValuesFromStore();
    });

    function enableForm() {
      formDisabled.value = false;
    }

    function disableForm() {
      initializeErrors();
      initializeFormWithValuesFromStore();
      formDisabled.value = true;
    }

    function updateFormValue(value: string, field: string) {
      formValues.value[field] = value;
    }

    function updateFormError(value: string, field: string) {
      formErrors.value[field] = value;
    }

    function updateCodeValue(value: string) {
      confirmCodeValue.value = value;
    }

    function updateCodeError(value: string) {
      confirmCodeError.value = value;
    }

    function validate(values, field: string) {
      changeProfileInfoSchemaValidation
        .validateAt(field, values)
        .then(() => {
          updateFormError("", field);
        })
        .catch((err) => {
          updateFormError(mapInputError(err.message, t), field);
        });
    }

    function validateCode(value) {
      confirmsignupSchemaValidation
        .validateAt("code", { code: value })
        .then(() => {
          updateCodeError("");
        })
        .catch((err) => {
          updateCodeError(mapInputError(err.message, t));
        });
    }

    function openOTPModal() {
      confirmPhoneModalOpened.value = true;
    }

    function closeOTPModal() {
      confirmPhoneModalOpened.value = false;
    }

    function blockSendCodeForSeconds(seconds: number) {
      if (sendCodeAllowed.value) {
        sendCodeAllowed.value = false;
        setTimeout(() => {
          sendCodeAllowed.value = true;
        }, seconds * 1000);
      }
    }

    function showWaitError() {
      showToast(ToastErrors.WAIT);
    }

    async function sendCode() {
      if (sendCodeAllowed.value) {
        try {
          blockSendCodeForSeconds(OTP_WAIT_TIME_IN_SECONDS);
          await store.dispatch(ActionTypes.VERIFY_USER_ATTR, {
            user: cognitoUserInfo.value,
            userAttribute: "phone_number",
          });
        } catch (error) {
          return;
        }
      } else {
        showWaitError();
      }
    }

    async function handlePostConfirmation() {
      try {
        await store.dispatch(ActionTypes.AUTH_ACTION, null);
        await store.dispatch(ActionTypes.CURRENT_SESSION, null);
        initializeFormWithValuesFromStore();
        closeOTPModal();
      } catch (error) {
        return;
      }
    }

    async function confirmPhoneNumber() {
      try {
        await store.dispatch(
          ActionTypes.VERIFY_SMS_CODE,
          confirmCodeValue.value
        );
        await handlePostConfirmation();
      } catch (err) {
        confirmCodeError.value = mapCognitoError(err, t);
      }
    }

    async function sendPhoneNumberAndCode() {
      try {
        const user = await store.dispatch(
          ActionTypes.CURRENT_AUTHENTICATED_USER,
          null
        );
        cognitoUserInfo.value = user;
        await store.dispatch(ActionTypes.UPDATE_USER_ATTR, {
          user: user,
          attributes: { phone_number: formatItalianPhone(formValues.value.phonenumber) },
        });
        await sendCode();
      } catch (error) {
        return;
      }
    }

    async function updateUserAttributes() {
      try {
        await store.dispatch(ActionTypes.POST_USER_INFO, {
          name: formValues.value.name,
          family_name: formValues.value.surname,
        });
      } catch (error) {
        return;
      }
    }

    function isPhoneChanged() {
      return formValues.value.phonenumber !== userInfo.value.phone_number;
    }

    async function saveProfileChanges() {
      try {
              await updateUserAttributes();
      initializeFormWithValuesFromStore();
      if (isPhoneChanged()) {
        sendPhoneNumberAndCode();
        openOTPModal();
      }
      } catch(err) {
        return;
      }

    }

    function isButtonDisabled() {
      return !confirmCodeValue.value || confirmCodeError.value;
    }

    return {
      t,
      locale,
      uppercase,
      adminDropdownChoices,
      formValues,
      formErrors,
      userInfo,
      formDisabled,
      enableForm,
      disableForm,
      updateFormValue,
      validate,
      saveDisabled,
      isChangeInfoFormValid,
      saveProfileChanges,
      confirmPhoneModalOpened,
      closeOTPModal,
      confirmCodeValue,
      confirmCodeError,
      updateCodeValue,
      validateCode,
      sendCode,
      isButtonDisabled,
      confirmPhoneNumber,
      dropdownChoices,
      FIXED_PHONE_PREFIX
    };
  },
});
