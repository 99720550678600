
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  nextTick,
  watch,
} from "vue";
import { uppercase, upperCaseFirstLetter } from "@/services/mapping.service";
import { SexType } from "@/constants/sex.enum";
import { PadelClub } from "@/models/club.models";
import { PadelUser } from "@/models/user.models";
import { PadelPlayground } from "@/models/playground.models";
import { ActionTypes } from "@/store/action-types";
import { MutationTypes } from "@/store/mutation-types";
import Avatar from "@/components/avatar/Avatar.vue";
import UserCard from "@/components/user-card/UserCard.vue";
import UserScheduledTable from "@/components/user-scheduled-table/UserScheduledTable.vue";
import moment from "moment";

export default defineComponent({
  name: "UserDetails",
  components: {
    Avatar,
    UserCard,
    UserScheduledTable,
  },
  setup() {
    const { t, locale } = useI18n();
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const cards = ref(null);
    const showConfirmDialog = ref(false);
    const selectedCard = ref(null);
    const user = ref(null);

    // TABS
    const selectedTab = ref(0);

    /* COMPUTED */
    const myClub = computed(function (): PadelClub {
      return store.getters.getMyClub;
    });
    const myPlaygrounds = computed(function (): PadelPlayground[] {
      return store.getters.getMyPlaygrounds;
    });
    const playerCategoryOptions = computed(function () {
      return myClub.value.player_categories.map((el) => {
        return { value: el.code, label: el.name };
      });
    });

    // DATA

    // LIFECYCLE
    onMounted(() => {
      nextTick(() => {
        getUser();
      });
    });

    onUnmounted(() => {});

    // FUNCTIONS
    async function getUser() {
      try {
        user.value = await store.dispatch(ActionTypes.GET_USER, {
          club_id: myClub.value.club_id,
          user_id: route.params.id,
        });

        // ---
        const item = JSON.parse(JSON.stringify(user.value));
        store.commit(MutationTypes.SET_SEARCH_USER, item);

        // SELECT
        selectTab();
      } catch (e) {
        console.log(e);
      }
    }

    function selectTab() {
      if (route.query.tab) {
        if (route.query.tab === "cards") {
          selectedTab.value = 1;
        }

        // CLEAR QUERY
        setTimeout(() => {
          const query = Object.assign({}, route.query);
          delete query.tab;
          router.replace({ query });
        }, 100);
      }
    }

    function onChangeTab(event) {
      selectedTab.value = event;
    }

    function gender(gender: string) {
      if (gender === SexType.MALE) {
        return "MALE_GENDER";
      } else if (gender === SexType.FEMALE) {
        return "FEMALE_GENDER";
      } else {
        return "OTHER_GENDER";
      }
    }

    function userLevel() {
      let level = (user.value.exp_level || {}).level;
      if (!level) level = 0;
      return parseInt(`${level / 100}`);
    }

    function userLevelString() {
      return user.value.exp_level?.description || null;
    }

    function createCard() {
      // SET
      store.commit(MutationTypes.SET_USER_CARDS, cards.value);

      // PUSH
      router.push({ name: "create-card", params: { id: user.value.id } });
    }

    function getCards() {
      store
        .dispatch(ActionTypes.GET_CARDS, { user_id: user.value.id, limit: 20 })
        .then((response) => {
          cards.value = response.data.records;
        });
    }

    function onClickEvent(event: any) {
      const item = JSON.parse(JSON.stringify(event));
      store.commit(MutationTypes.SET_SCROLL_EVENT, item);
      setTimeout(() => {
        router.push({ name: "Scheduler" });
      }, 100);
    }

    function onEditCard(card) {
      router.push({ name: "edit-card", params: { card_id: card.id } });
    }

    function onDeleteCard(card) {
      selectedCard.value = card;
      showConfirmDialog.value = true;
    }

    function onDelete(id) {
      store.dispatch(ActionTypes.DELETE_CARD, { id: id }).then(() => {
        hideDialog();
        getCards();
      });
    }

    function onRenewCard(card) {
      // SET
      store.commit(MutationTypes.SET_USER_CARDS, cards.value);

      // PUSH
      router.push({
        name: "create-card",
        params: { id: user.value.id },
        query: { renew_id: card.id },
      });
    }

    function hideDialog() {
      selectedCard.value = null;
      showConfirmDialog.value = false;
    }

    function selectPlayerCategory(value) {
      user.value.player_category = value;
      store
        .dispatch(ActionTypes.UPDATE_USER_CATEGORY, {
          club_id: myClub.value.club_id,
          user_id: user.value.id,
          category: value,
        })
        .then(() => {
          getUser();
        });
    }

    /** WATCH */
    watch(selectedTab, function () {
      if (selectedTab.value == 1) {
        if (cards.value == null) getCards();
      }
    });

    // RETURN
    return {
      t,
      locale,
      router,
      uppercase,
      upperCaseFirstLetter,
      moment,

      // DATA
      user,
      myClub,
      myPlaygrounds,
      selectedTab,
      cards,
      selectedCard,
      showConfirmDialog,
      playerCategoryOptions,

      // METHODS
      onChangeTab,
      gender,
      userLevel,
      userLevelString,
      createCard,
      onClickEvent,
      onEditCard,
      onDeleteCard,
      onDelete,
      onRenewCard,
      hideDialog,
      selectPlayerCategory,
    };
  },
});
