<div class="table-responsive">
    <table class="scrolling-component" ref="scrollComponent">
        <tr class="header">
            <slot name="header"></slot>
        </tr>
        <template v-if="(values.length > 0) || (!emptyState)">
            <template v-for="(value, index) in values" :key="index">
                <tr class="tile" 
                    :class="{'hover-disabled': !hoverEnabled, 'disabled': !!value.disabled}" 
                    @click="rowClicked(value)">
                    <slot name="tile" :value="value"></slot>
                </tr>
            </template>
        </template>       

    </table>
    <template v-if="(values.length <= 0) && (emptyState)">
        <div class="empty-state">{{emptyState}}</div>            
    </template>
</div>