<div class="custom-select">
<PadelInput :value="innerValue" 
    :icon="value === innerValue && value !== null? 'close' :'search'" 
    :label="label" @onEnterPressed="emitValue($event)"
    :buttonClickable="(value || innerValue)? true: false" 
    @buttonClicked="emitClick($event)"
    @input="onInput($event)"
    :isSearch="search" 
    @focus="onFocus()" 
    @validate="onValidate()" 
    :error="error"
    :disabled="disabled"></PadelInput>
    <div class="items" :class="{ selectHide: !open }" >
        <div class="outer" v-for="(option, i) of options" :key="i" @click="onChoice(option)">
            <div class="inner">
                {{ option.label }}
            </div>

        </div>
    </div>
</div>
