
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  mapFormError,
  mapModifyTournamentRequest,
  mapTournamentToModifyForm,
  uppercase,
} from "@/services/mapping.service";
import {
  emptyCreateTournamentForm,
  emptyCreateTournamentFormErrors,
} from "@/constants/initial-values.constants";
import { LevelMap } from "@/constants/tournaments.enums";
import {
  tournamentsSchemaValidation,
  isFirstStepEmpty,
  isFirstStepValid,
  isValidRegistrationEnd,
} from "@/services/form-validation.service";
import { showToast } from "@/services/error-management.service";
import { ToastErrors } from "@/constants/generic-errors.enum";
import { PadelClub } from "@/models/club.models";
import { useStore } from "@/store/store";
import { ActionTypes } from "@/store/action-types";
import { LevelOptions } from "@/constants/tournaments.enums";
export default defineComponent({
  emits: ["isFormDirty", "tournamentChanged"],
  props: ["tournament"],
  setup(props, { emit }) {
    const schema = tournamentsSchemaValidation;
    const store = useStore();
    const formDisabled = ref(true);
    const { t, locale } = useI18n();
    const scoreboard = ref(null);

    const formValues = ref(
      JSON.parse(JSON.stringify(emptyCreateTournamentForm))
    );
    const formErrors = ref(
      JSON.parse(JSON.stringify(emptyCreateTournamentFormErrors))
    );
    const modalToShow = ref(null);
    const temporaryPic = ref(null);
    const keyComponent = ref(null);

    /* COMPUTED */
    const myClub = computed(function(): PadelClub {
      return store.getters.getMyClub;
    });

    function initializeFormWithTournament() {
      formValues.value = mapTournamentToModifyForm(props.tournament);
      keyComponent.value++;
    }

    onMounted(() => {
      initializeFormWithTournament();
    });

    function openModal(modal: string) {
      modalToShow.value = modal;
    }

    function hideModal() {
      modalToShow.value = null;
      scoreboard.value = null;
      temporaryPic.value = null;
    }

    async function saveTournamentChanges() {
      try {
        const payload = mapModifyTournamentRequest(formValues.value);
        const resp = await store.dispatch(ActionTypes.MODIFY_TOURNAMENT, {
          club_id: myClub.value.club_id,
          tournament_id: props.tournament.id,
          tournament: payload,
        });
        openModal("MODAL_SUCCESS");
        emit("isFormDirty", false);
        emit("tournamentChanged", resp);
      } catch (error) {
        return;
      }
    }

    function updateFormValue(value, field: string) {
      emit("isFormDirty", true);
      formValues.value[field] = value;
    }

    function updateFormError(value, field: string) {
      formErrors.value[field] = value;
    }

    function handleCrossValidation(field) {
      if (field === "max_couples") {
        updateFormError("", "min_couples");
      } else if (field === "min_couples" && formValues.value.max_couples != 0) {
        updateFormError("", "max_couples");
      }
    }



    function validateForm(values, field) {
      schema
        .validateAt(field, values)
        .then(() => {
          if (field === 'registration_end_at' && !isValidRegistrationEnd(values)) {
            updateFormError(
              t("registration_must_be_before_start"),
              'registration_end_at'
            );
          }
          else if (field === "max_couples" && values.max_couples === "0") {
            updateFormError(
              mapFormError("max_couples must be greater than 0", t),
              field
            );
          } else if (
            field === "max_couples" &&
            parseInt(values.max_couples) < props.tournament.max_couples
          ) {
            updateFormError(
              mapFormError("cannot modify max couples under previous value", t),
              field
            );
          } else if (field === "min_couples") {
            validateForm(values, "max_couples");
          } else {
            handleCrossValidation(field);
            updateFormError("", field);
          }
        })
        .catch((err) => {
          updateFormError(mapFormError(err.message, t), field);
        });
    }

    function temporaryUploader(event) {
      temporaryPic.value = event;
    }

    function temporaryUploaderScoreboard(event) {
      scoreboard.value = event;
    }

    
    async function refreshPage() {
      try {
        const resp = await store.dispatch(ActionTypes.GET_TOURNAMENT_DETAILS, {
          club_id: myClub.value.club_id,
          tournament_id: props.tournament.id,
        });
        emit("isFormDirty", false);
        emit("tournamentChanged", resp);
      } catch (error) {
        return;
      }
    }

    async function confirmUploadScoreboard() {
      try {
        const resp = await store.dispatch(ActionTypes.UPLOAD_SCOREBOARD, {
          club_id: myClub.value.club_id,
          tournament_id: props.tournament.id,
          file: scoreboard.value.src,
        });
        hideModal();
        refreshPage();
      } catch (error) {
        return;
      }
    }

    function handleWrongFileUploaded(file) {
      showToast(ToastErrors.WRONG_FILE_FORMAT);
    }

    function handleFileTooHeavy(file) {
      showToast(ToastErrors.FILE_TOO_HEAVY);
    }

    function mapLevel(level: number) {
      const _level: string = LevelMap[level];
      if (_level) {
        return t(_level);
      } else {
        return "";
      }
    }

    function confirmUploadPhoto() {
      formValues.value.picture = temporaryPic.value;
      hideModal();
    }
    return {
      t,
      locale,
      uppercase,
      formValues,
      formErrors,
      temporaryPic,
      keyComponent,
      modalToShow,
      myClub,
      formDisabled,
      scoreboard,
      LevelOptions,

      openModal,
      hideModal,
      saveTournamentChanges,
      validateForm,
      updateFormValue,
      handleWrongFileUploaded,
      temporaryUploader,
      mapLevel,
      isFirstStepEmpty,
      isFirstStepValid,
      confirmUploadPhoto,
      temporaryUploaderScoreboard,
      confirmUploadScoreboard,
      handleFileTooHeavy
    };
  },
});
