
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import { 
    defineComponent, 
    onMounted, 
    ref,
} from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
    name: "Actions",
    props: {
        item: {
            type: Object,
            default: null,
        },
        editable: {
            type: Boolean,
            default: true,
        },
        deletable: {
            type: Boolean,
            default: true,
        },
        duplicable: {
            type: Boolean,
            default: true,
        },
        certifiable: {
            type: Boolean,
            default: false,
        },
        renewable: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['edit', 'delete', 'duplicate', 'certify', 'renew'],
    setup(props, { emit }) {
        const router = useRouter();
        const store = useStore();
        const { t, locale } = useI18n();
        const menu = ref(null);
        const items = [];
        if (props.duplicable) {
            items.push({
                label: t('duplicate'),
                image: require('@/theme/imgs/action_duplica.svg'),
                command: () => {
                    emit("duplicate", props.item);
                    menu.value.hide();
                }
            });
        }
        if (props.editable) {
            items.push({
                label: t('edit'),
                image: require('@/theme/imgs/action_modifica.svg'),
                command: () => {
                    emit("edit", props.item);
                    menu.value.hide();
                }
            });
        }
        if (props.deletable) {
            items.push({
                label: t('delete'),
                image: require('@/theme/imgs/action_elimina.svg'),
                command: () => {
                    emit("delete", props.item);
                    menu.value.hide();
                }
            });
        }
        if (props.certifiable) {
            items.push({
                label: t('certify'),
                image: null,
                command: () => {
                    emit("certify", props.item);
                    menu.value.hide();
                }
            });
        }
        if (props.renewable) {
            items.push({
                label: t('add_new'),
                image: null,
                command: () => {
                    emit("renew", props.item);
                    menu.value.hide();
                }
            });
        }

        /* COMPUTED */
        
        // FUNCTIONS
        function toggleMenu(event) {
            menu.value.toggle(event);
        }

        // SYSTEM
        onMounted(() => {
        });

        return {
            t,
            locale,

            toggleMenu,

            items,
            menu,
        };
    },
});
