
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/drag-and-drop.js";
import "vue-cal/dist/i18n/it.js";
import { useStore } from "@/store/store";
import { useRouter } from "vue-router";
import { ActionTypes } from "@/store/action-types";
import {
  formatDateDDMMYYYY,
  convertMinutesOfDayToHHMM,
  formatDateMMDDYYYY,
  formatDateYYYYMMDD,
  transformStringToDate,
  isDateBefore,
  isToday,
  convertEventTimeToMinutesOfDay,
  returnYesterday,
  returnTomorrow,
  changeDateTime,
  transformMillisecondsToHHMM,
  returnDateAdMidnight,
  mapTimestamp,
} from "@/services/dates.service";
import {
  filteredPlaygrounds,
  filteredEvents,
  filteredTimeFrom,
  filteredTimeTo,
  isFilterActive,
  mapCalendarFormToFilters,
  refactorEvents,
  refactorPlaygrounds,
  constructPlaygroundMap,
  removedFilterArray,
  transformCalendarFilterOptions,
  transformPlaygroundsToOptions,
  uppercase,
  convertSchedulerToArray,
  convertMonthSchedulerToArray,
  mapCancelBookingPayload,
  mapContentForTooltip,
  mySportsByPlaygrounds,
} from "@/services/mapping.service";
import { PadelPlayground } from "@/models/playground.models";
import { PadelClub } from "@/models/club.models";
import { convertArrayIntoCsv } from "@/services/files.service";
import {
  CalendarTimeslot,
  TimeslotInfo,
  VueCalEvent,
} from "@/models/timeslots.models";
import { ClubStatus } from "@/constants/club-status.enum";
import { showToast } from "@/services/error-management.service";
import { ToastErrors } from "@/constants/generic-errors.enum";
import { emptyDownloadCalendarForm } from "@/constants/initial-values.constants";
import { BookingType, CalendarOpenings } from "@/constants/booking.enum";
import {
  END_OF_AFTERNOON,
  END_OF_MORNING,
} from "@/constants/radio-options.constants";
import { MutationTypes } from "@/store/mutation-types";
import {
  sportOptions,
  getSportByValue,
} from "@/constants/radio-options.constants";
import moment from "moment";
import { PadelUser } from "@/models/user.models";

export default defineComponent({
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t, locale } = useI18n();
    const timeCellHeight = ref(50);
    const timeFrom = ref(null);
    const timeTo = ref(null);
    const stickySplitLabels = ref(false);
    const minCellWidth = ref(400);
    const minSplitWidth = ref(0);
    const tempDeleted = ref(null);
    const display = ref(false);
    const splits = ref([]);
    const show_calendar = ref(false);
    const playgrounds = ref([]);
    const rawCalendarEvents = ref([]);
    const events = ref([]);
    const showCalendarBox = ref(false);
    const playgroundPages = ref([]);
    const currentPage = ref(0);
    const modalToShow = ref(null);
    const deleteClicked = ref(false);
    const activeFilters = ref([]);
    const filtersForm = ref([
      { type: "playground", value: null, label: null },
      { type: "timeslot", value: null, label: null },
      { type: "sport", value: null, label: null },
      { type: "visibility", value: null, label: t("private") },
    ]);
    const detailBooking = ref(null);
    let pageWindow = 6;
    const MIN_WIDTH_SIZE = 200;
    const playgroundMap = ref({});
    const bookingToDelete = ref(null);
    const tournamentDetails = ref(null);
    const provvNewEvent = ref(null);
    const downloadCalendarForm = ref(
      JSON.parse(JSON.stringify(emptyDownloadCalendarForm))
    );
    const specialHours = ref({});
    const uploadStep = ref(1);
    const uploadPlayground = ref(null);
    const uploadFile = ref(null);
    const fileUploader = ref(null);

    // const START_OF_DAY = 0;
    // const END_OF_DAY = 24 * 60;

    const filtersTimeslotsApplied = ref(false);

    const showPriceRules = ref(false);
    const tooltipId = ref(null);
    const tooltipX = ref(0);
    const tooltipY = ref(0);

    /* COMPUTED */
    const myClub = computed(function (): PadelClub {
      return store.getters.getMyClub;
    });

    const myClubStatus = computed(function (): string {
      return store.getters.getMyClub.status;
    });

    const selectedDate = computed(function (): Date {
      return store.getters.getCurrentDate
        ? new Date(store.getters.getCurrentDate)
        : undefined;
    });

    const clubTimeFrom = myClub.value.opening_hour / 1000 / 60;
    const clubTimeTo = myClub.value.closing_hour / 1000 / 60;

    const computedTimeFrom = ref(clubTimeFrom);
    const computedTimeTo = ref(clubTimeTo);

    const createValue = computed(function () {
      return (
        isDateBefore(new Date(), selectedDate.value) ||
        isToday(selectedDate.value)
      );
    });

    const allPlaygrounds = computed(function (): PadelPlayground[] {
      return store.getters.getMyPlaygrounds;
    });
    const mySports = computed(function () {
      return mySportsByPlaygrounds(allPlaygrounds.value);
    });

    //CHECK FALSE ENABLES DISABLED CLUBS TO CREATE EVENTS
    function isMyClubDisabled(check = false) {
      if (check) {
        return myClubStatus.value === ClubStatus.DISABLED;
      } else return false;
    }

    const new__booking = computed(function (): string {
      if (playgrounds.value.length <= 0) {
        return "disabled";
      } else {
        if (isMyClubDisabled()) {
          return "color-disabled";
        } else {
          return "";
        }
      }
    });

    async function changePageWindowSize() {
      const calendarTable = document.getElementsByClassName("vuecal__cells");
      const columns = Math.floor(
        (calendarTable[0] as HTMLElement).offsetWidth / MIN_WIDTH_SIZE
      );
      if (columns > 0) {
        pageWindow = columns;
      } else {
        pageWindow = 1;
      }
    }

    function calculateSplits() {
      splits.value = [];
      playgroundPages.value[currentPage.value].forEach((court, index) => {
        splits.value.push({ id: index + 1, playground: court.id });
      });
      events.value.forEach((event) => {
        event.split = null;
        playgroundPages.value[currentPage.value].forEach((court, index) => {
          if (event.playground_id === court.id) {
            event.split = index + 1;
            event.playground_name = court.name;
            event.timeslotInfo
              ? (event.timeslotInfo.playground_name = court.name)
              : "";
          }
        });
      });
    }

    function findSubArrays(array, window: number) {
      if (array.length <= window) {
        return [array];
      }
      const provv = [];
      for (let i = 0; i < array.length; i++) {
        const subArray = array.slice(i, window + i);
        if (subArray.length === window) {
          provv.push(subArray);
        }
      }
      return provv;
    }

    function returnFilter(type: string) {
      return activeFilters.value.find((filter) => filter.type === type);
    }

    function reportWindowSize(needToRefactorEvents = false) {
      changePageWindowSize();
      playgroundPages.value = findSubArrays(playgrounds.value, pageWindow);
      if (needToRefactorEvents) {
        events.value = refactorEvents(
          rawCalendarEvents.value,
          myClub.value,
          t,
          allPlaygrounds.value
        );
      }
      calculateSplits();
    }

    //RECALCULATE COLUMNS EVERY TIME THERE IS A RESIZE
    window.onresize = () => {
      reportWindowSize(false);
    };

    const playgroundOptions = computed(function () {
      return transformPlaygroundsToOptions(playgrounds.value);
    });

    const computedPlaygrounds = computed(function (): {
      value: string;
      label: string;
      sport: string;
    }[] {
      return playgroundOptions.value.filter(
        (el) => el.sport === filtersForm.value[2].value
      );
    });

    const computedCalendarFilterOptions = computed(function () {
      return transformCalendarFilterOptions(
        myClub.value.opening_hour,
        myClub.value.closing_hour
      );
    });

    const computedEvents = computed(function () {
      if (
        activeFilters.value.length > 0 &&
        (isFilterActive(activeFilters.value, "playground") ||
          isFilterActive(activeFilters.value, "sport") ||
          isFilterActive(activeFilters.value, "visibility"))
      ) {
        return filteredEvents(
          events.value,
          allPlaygrounds.value,
          (returnFilter("playground") || {}).value,
          (returnFilter("sport") || {}).value,
          (returnFilter("visibility") || {}).value
        );
      } else return events.value;
    });

    const filtersPlaygroundApplied = computed(function () {
      return (
        activeFilters.value.length > 0 &&
        (isFilterActive(activeFilters.value, "playground") ||
          isFilterActive(activeFilters.value, "sport") ||
          isFilterActive(activeFilters.value, "visibility"))
      );
    });

    const computedSplits = computed(function () {
      return splits.value;
    });

    async function getPlaygrounds(club_id: string): Promise<PadelPlayground[]> {
      try {
        const pl = await store.dispatch(ActionTypes.GET_PLAYGROUNDS, club_id);
        return pl;
      } catch (error) {
        return;
      }
    }

    async function getCalendarEvents(payload) {
      try {
        const timeslots = await store.dispatch(
          ActionTypes.GET_CALENDAR_EVENTS,
          payload
        );
        return timeslots;
      } catch (error) {
        return;
      }
    }

    const delay = (ms) => new Promise((res) => setTimeout(res, ms));

    async function initializeEvents(date: string, hideLoader?: boolean) {
      showCalendarBox.value = false;

      const dateString = formatDateMMDDYYYY(date);

      try {
        await getCalendarEvents({
          club_id: myClub.value.club_id,
          date: dateString,
          hideLoader: hideLoader
        }).then(async (val) => {
          console.table(val);
          rawCalendarEvents.value = val;
          events.value = refactorEvents(
            rawCalendarEvents.value,
            myClub.value,
            t,
            allPlaygrounds.value
          );
        });
        calculateSplits();
      } catch (err) {
        return;
      }
    }

    function displayClosingHours(openMillis, closeMillis) {
      const _specialHours = {};
      for (let i = 1; i <= 7; i++) {
        _specialHours[i] = [
          //{ from: 0 * 60, to: openMillis / 1000 / 60, class: "closed" },
          {
            from: openMillis / 1000 / 60,
            to: closeMillis / 1000 / 60,
            class: "special-hours-opened",
          },
          //{ from: closeMillis / 1000 / 60, to: 24 * 60, class: "closed" },
        ];
      }
      specialHours.value = _specialHours;
    }

    function initializeCalendarBoundaries(openMillis, closeMillis) {
      displayClosingHours(openMillis, closeMillis);
      timeFrom.value = openMillis / 1000 / 60;
      timeTo.value = closeMillis / 1000 / 60;
    }

    function scrollIntoElement(element) {
      const header = document.getElementById("scheduler-header");
      const offset = 10;
      const topOfElement =
        window.pageYOffset +
        element.getBoundingClientRect().top -
        header.scrollHeight -
        offset;
      window.scroll({ top: topOfElement, behavior: "smooth" });
    }

    function scrollToOpenedHour(className: string) {
      const firstOpenedElements = document.getElementsByClassName(className);
      if (firstOpenedElements[0]) {
        scrollIntoElement(firstOpenedElements[0]);
      } else {
        setTimeout(() => {
          scrollToOpenedHour(className);
        }, 100);
      }
    }

    function handleClickCalendar(event) {
      const specifiedElement = document.getElementById(
        "toggle-calendar-button"
      );
      if (!specifiedElement) return;
      const schedulerBox = document.getElementById("calendar-scheduler-box");
      const isMonthPickClicked =
        (event.target as HTMLElement).classList.contains(
          "p-datepicker-prev-icon"
        ) ||
        (event.target as HTMLElement).classList.contains("p-datepicker-prev") ||
        (event.target as HTMLElement).classList.contains(
          "p-datepicker-next-icon"
        ) ||
        (event.target as HTMLElement).classList.contains(
          "p-datepicker-next-icon"
        );
      const isClickInside = specifiedElement.contains(event.target as Node);
      const isClickInsideCalendar = schedulerBox.contains(event.target as Node);
      if (isMonthPickClicked || isClickInsideCalendar) {
        show_calendar.value = true;
      } else if (!isClickInside) {
        show_calendar.value = false;
      }
    }

    function handleButtonCalendarClickOut() {
      document.addEventListener("click", handleClickCalendar);
    }

    onUnmounted(() => {
      document.removeEventListener("click", handleClickCalendar);
      store.commit(MutationTypes.RESET_CURRENT_DATE, null);
    });

    function initializeFilterForm() {
      filtersForm.value = [
        { type: "playground", value: null, label: null },
        { type: "timeslot", value: null, label: null },
        { type: "sport", value: null, label: null },
        { type: "visibility", value: null, label: t("private") },
      ];
    }

    function initializeDetailBooking() {
      detailBooking.value = null;
    }

    function initializeTournamentDetail() {
      tournamentDetails.value = null;
    }

    const userHasCards = ref(false);

    async function checkUserHasCards(userId: string) {
      const response = await store.dispatch(ActionTypes.GET_CARDS, { user_id: userId, limit: 1 });
      return response.data.records.length > 0;
    }

    async function openModal(modal: string) {
      if (modal === "BOOKING_DETAIL") {
        userHasCards.value = await checkUserHasCards(detailBooking.value.wesmash_user_id);
      }

      if (modalToShow.value === "SURE_WANNA_DELETE" && modal === "BOOKING_DETAIL") {
        return;
      } else {
        modalToShow.value = modal;
      }
    }

    function hideModal() {
      initializeFilterForm();
      initializeDetailBooking();
      initializeTournamentDetail();
      modalToShow.value = null;
      bookingToDelete.value = null;
      provvNewEvent.value = null;
      uploadStep.value = 1;
      uploadPlayground.value = null;
      uploadFile.value = null;
    }

    function checkIfClubDisabled() {
      if (isMyClubDisabled(true)) {
        openModal("CLUB_DISABLED");
      }
    }

    async function initializeEventsInCalendar() {
      playgroundPages.value = findSubArrays(playgrounds.value, pageWindow);
      await initializeEvents(selectedDate.value.toISOString());
      initializeCalendarBoundaries(
        myClub.value.opening_hour,
        myClub.value.closing_hour
      );
    }

    function handleOrientation() {
      window.addEventListener("resize", () => {
        const isMobileDevice = window.navigator.userAgent
          .toLowerCase()
          .includes("mobi");
        const element = document.getElementById("scheduler-header");
        if (
          window.matchMedia("(orientation: landscape)").matches &&
          isMobileDevice
        ) {
          element.classList.add("scrollable-header");
          /* showToast(ToastErrors.ORIENTATION_ERROR); */
        } else {
          element.classList.remove("scrollable-header");
        }
      });
    }

    function setDate() {
      if (!selectedDate.value) {
        store.commit(MutationTypes.SET_CURRENT_DATE, new Date());
      }
    }

    onMounted(async function () {
      try {
        setDate();
        await getPlaygrounds(myClub.value.club_id).then((val) => {
          const filtered = filteredPlaygrounds(val);
          playgrounds.value = refactorPlaygrounds(filtered);
          playgroundMap.value = { ...constructPlaygroundMap(filtered) };
        });
        await initializeEventsInCalendar();

        
        setInterval(() => {
          initializeEvents(selectedDate.value.toISOString(), true);
        }, 300000); // refresh ogni 5 minuti (300000ms)

        reportWindowSize(true);
        showCalendarBox.value = true;
        checkIfClubDisabled();
        // scrollToOpenedHour("special-hours-opened");
        handleButtonCalendarClickOut();
        handleOrientation();
      } catch (err) {
        return;
      }
    });

    //OGNI VOLTA CHE CAMBIA LA DATA RICHIEDI I TIMESLOTS
    watch(selectedDate, async function (currentValue, oldValue) {
      try {
        await initializeEvents(currentValue.toISOString());
        // scrollToOpenedHour("special-hours-opened");
      } catch (e) {
        console.error(e);
      }
    });

    watch(showPriceRules, function (currentValue, oldValue) {
      if (currentValue === false) {
        setTimeout(() => {
          // scrollToOpenedHour("special-hours-opened");
        }, 250);
      }
    });

    function areFiltersTimeslotsApplied(filters) {
      return filters.length > 0 && isFilterActive(filters, "timeslot");
    }

    function calculateTimeFrom() {
      if (filtersTimeslotsApplied.value) {
        computedTimeFrom.value = filteredTimeFrom(
          clubTimeFrom,
          returnFilter("timeslot").value
        );
      } else {
        computedTimeFrom.value = clubTimeFrom;
      }
    }

    function calculateTimeTo() {
      if (filtersTimeslotsApplied.value) {
        computedTimeTo.value = filteredTimeTo(
          clubTimeTo,
          returnFilter("timeslot").value
        );
      } else {
        computedTimeTo.value = clubTimeTo;
      }
    }

    watch(activeFilters, async function (currentValue, oldValue) {
      if (areFiltersTimeslotsApplied(currentValue)) {
        filtersTimeslotsApplied.value = true;
      } else {
        filtersTimeslotsApplied.value = false;
      }
      await calculateTimeFrom();
      await calculateTimeTo();
    });

    function handleCalendarDelete(event) {
      tempDeleted.value = event;
      display.value = true;
      events.value.push({
        playground_id: "",
        id: "000",
        start: null,
        end: null,
        title: "",
        class: "",
        split: 0,
      });
      const el = events.value.pop();
    }

    function arrayContainsElement(page, playground_id) {
      return page.find((pl) => pl.id === playground_id) ? true : false;
    }

    function goToPageThatContainsPlayground(playground_id: string) {
      const arrays = [];
      playgroundPages.value.forEach((page, index) => {
        arrayContainsElement(page, playground_id) ? arrays.push(index) : null;
      });
      arrays.length > 0
        ? (currentPage.value = arrays[0])
        : (currentPage.value = 0);
    }

    async function refreshPage() {
      await initializeEvents(selectedDate.value.toISOString());
    }

    function scrollOntoElement(start_at) {
      const labels = document.getElementsByClassName("vuecal__time-cell-label");
      Array.from(labels).forEach((lab) => {
        if ((lab as HTMLElement).innerText === start_at) {
          scrollIntoElement(lab);
        }
      });
    }

    function scrollToTime(timestamp) {
      store.commit(
        MutationTypes.SET_CURRENT_DATE,
        returnDateAdMidnight(new Date(timestamp))
      );
      setTimeout(() => {
        scrollOntoElement(transformMillisecondsToHHMM(timestamp));
      }, 100);
    }

    function goToPage(event, start_at) {
      scrollToTime(start_at);
      if (event.playground_id) {
        goToPageThatContainsPlayground(event.playground_id);
      }
    }

    async function deleteTimeslot(event: TimeslotInfo) {
      try {
        await store.dispatch(
          ActionTypes.DELETE_TIMESLOT,
          mapCancelBookingPayload(event, myClub.value.club_id)
        );
        await refreshPage();
        hideModal();
      } catch (err) {
        return;
      }
    }

    function handleDelete(event: CalendarTimeslot) {
      handleCalendarDelete(event);
      openModal("SURE_WANNA_DELETE");
      bookingToDelete.value = event.timeslotInfo;
    }

    async function handleTournamentSlot(event: CalendarTimeslot) {
      try {
        const resp = await store.dispatch(ActionTypes.GET_TOURNAMENT_DETAILS, {
          club_id: myClub.value.club_id,
          tournament_id: event.timeslotInfo.tournament_id,
        });
        tournamentDetails.value = resp;
        openModal("TOURNAMENT_DETAIL");
      } catch (error) {
        return;
      }
    }

    function handleClick(event: CalendarTimeslot) {
      if (event.timeslotInfo.type === BookingType.TOURNAMENT) {
        handleTournamentSlot(event);
      } else if (
        event.timeslotInfo.type === BookingType.OPEN_BOOKING ||
        event.timeslotInfo.type === BookingType.BACKOFFICE_OPEN_BOOKING
      ) {
        router.push({
          name: "open-booking-detail",
          params: { id: event.timeslotInfo.id },
        });
      } else {
        detailBooking.value = event;
        if (event.bundle_id) {
          openModal("BOOKING_BUNDLE_ALERT");
        } else {
          openModal("BOOKING_DETAIL");
        }
      }
    }

    function editBundle() {
      detailBooking.value.override_bundle = true;
      detailBooking.value.recurring = detailBooking.value.bundle_id != null;
      modalToShow.value = "BOOKING_DETAIL";
    }

    function editSingleBooking() {
      detailBooking.value.detach_bundle = true;
      modalToShow.value = "BOOKING_DETAIL";
    }

    function editTaking() {
      detailBooking.value.recurring = detailBooking.value.bundle_id != null;
      modalToShow.value = "BOOKING_TAKING";
    }

    function openNewBooking() {
      if (!isMyClubDisabled()) {
        openModal("NEW_BOOKING");
      } else {
        showToast(ToastErrors.CLUB_DISABLED);
      }
    }

    function getPlaygroundIdFromFakeEvent(event) {
      return splits.value.find((split) => split.id === event.split).playground;
    }

    function createFakeNewBookingEvent(event) {
      return {
        ...event,
        title: t("new_booking"),
        class: "fake-event",
        playground_id: getPlaygroundIdFromFakeEvent(event),
      };
    }

    function openNewBookingDrag(event) {
      const fakeEvent = createFakeNewBookingEvent(event);
      provvNewEvent.value = fakeEvent;
      events.value.push(fakeEvent);
      openNewBooking();
    }

    function eventOverlapsCreatedEvent(event: VueCalEvent) {
      const foundValue = events.value.find((val: CalendarTimeslot) => {
        const endMinutes =
          convertEventTimeToMinutesOfDay(val.end) === 0
            ? 1440
            : convertEventTimeToMinutesOfDay(val.end);
        return (
          moment(val.start).format("MM/DD/YYYY") ===
            moment(event.start).format("MM/DD/YYYY") &&
          val.split === event.split &&
          event.startTimeMinutes < endMinutes &&
          event.endTimeMinutes > convertEventTimeToMinutesOfDay(val.start)
        );
      });
      return Boolean(foundValue);
    }

    function adjustEvent(event: VueCalEvent) {
      let _provv = event;
      const minutesOfMorning = END_OF_MORNING / (60 * 1000);
      const minutesOfAfternoon = END_OF_AFTERNOON / (60 * 1000);
      const filter = returnFilter("timeslot").value;
      if (
        filter === CalendarOpenings.MORNING &&
        event.endTimeMinutes > minutesOfMorning
      ) {
        _provv = {
          ..._provv,
          endTimeMinutes: minutesOfMorning,
          end: new Date(changeDateTime(event.end, END_OF_MORNING)),
        };
      } else if (
        filter === CalendarOpenings.AFTERNOON &&
        event.endTimeMinutes > minutesOfAfternoon
      ) {
        console.log("filters applied: ", event);
        _provv = {
          ..._provv,
          endTimeMinutes: minutesOfAfternoon,
          end: new Date(changeDateTime(event.end, END_OF_AFTERNOON)),
        };
      }
      return _provv;
    }

    function handleDragCreate(event: VueCalEvent) {
      const endMinutes =
        event.endTimeMinutes === 0 ? 1440 : event.endTimeMinutes;
      if (event.start.getTime() === event.end.getTime()) {
        handleCalendarDelete(event);
      } else if (isMyClubDisabled()) {
        showToast(ToastErrors.CLUB_DISABLED);
        handleCalendarDelete(event);
      } else if (
        event.startTimeMinutes < timeFrom.value ||
        endMinutes > timeTo.value
      ) {
        openModal("EVENT_OVER_CLOSING_TIME");
        handleCalendarDelete(event);
      } else if (eventOverlapsCreatedEvent(event)) {
        openModal("EVENT_OVERLAPS");
        handleCalendarDelete(event);
      } else {
        if (filtersTimeslotsApplied.value) {
          event = adjustEvent(event);
        }
        openNewBookingDrag(event);
      }
    }

    function eliminateEvent() {
      const filtered = events.value.filter((value) => {
        return value.id !== tempDeleted.value.id;
      });
      events.value = filtered;
      display.value = false;
    }

    function abortEliminateEvent() {
      display.value = false;
    }

    function goToToday() {
      store.commit(MutationTypes.SET_CURRENT_DATE, new Date());
    }

    function goToYesterday() {
      store.commit(
        MutationTypes.SET_CURRENT_DATE,
        returnYesterday(selectedDate.value)
      );
    }

    function goToTomorrow() {
      store.commit(
        MutationTypes.SET_CURRENT_DATE,
        returnTomorrow(selectedDate.value)
      );
    }

    function formatDate() {
      const options: Intl.DateTimeFormatOptions = {
        weekday: "long",
        month: "long",
        day: "2-digit",
        year: "numeric",
      };
      const stringDate = selectedDate.value.toLocaleDateString(
        "it-IT",
        options
      );
      return (" " + stringDate)
        .replace(/ [\w]/g, (a) => a.toLocaleUpperCase())
        .trim();
    }

    function goToAddPlaygrounds() {
      router.push("add-playgrounds");
    }

    function goToTournaments() {
      router.push("tournaments");
    }

    function toggleCalendar() {
      show_calendar.value = !show_calendar.value;
    }

    function hideCalendar() {
      show_calendar.value = false;
    }

    function changeDateWithCalendar(event: Date) {
      if (event.getTime() === selectedDate.value.getTime()) return;
      setTimeout(() => {
        hideCalendar();
      }, 1);
      store.commit(MutationTypes.SET_CURRENT_DATE, new Date(event.getTime()));
    }

    function goToPreviousPage() {
      if (playgroundPages.value[currentPage.value - pageWindow]) {
        currentPage.value -= pageWindow;
      } else {
        currentPage.value = 0;
      }

      calculateSplits();
    }

    function goToNextPage() {
      if (playgroundPages.value[currentPage.value + pageWindow]) {
        currentPage.value += pageWindow;
      } else {
        currentPage.value = playgroundPages.value.length - 1;
      }
      calculateSplits();
    }

    function initializeActiveFilters() {
      activeFilters.value = [];
    }

    function setFilterFormValue(arrayIndex, obj) {
      filtersForm.value[arrayIndex].value = obj.value;
      filtersForm.value[arrayIndex].label = obj.label;
    }

    function toggleFilterFormValue(arrayIndex, value) {
      filtersForm.value[arrayIndex].value = value;
      filtersForm.value[arrayIndex].label = value ? t("private") : t("public");
    }

    function applyFilters() {
      activeFilters.value = mapCalendarFormToFilters(filtersForm.value);
      setTimeout(() => {
        const filtered = filteredPlaygrounds(
          allPlaygrounds.value,
          (returnFilter("playground") || {}).value,
          (returnFilter("sport") || {}).value,
          (returnFilter("visibility") || {}).value
        );
        playgrounds.value = refactorPlaygrounds(filtered);
        playgroundMap.value = { ...constructPlaygroundMap(filtered) };
        reportWindowSize(true);
        hideModal();
      }, 50);
    }

    function abortFilters() {
      initializeFilterForm();
      initializeActiveFilters();
      applyFilters();
    }

    function removeFilter(filter) {
      activeFilters.value = removedFilterArray(activeFilters.value, filter);
      const filtered = filteredPlaygrounds(
        allPlaygrounds.value,
        (returnFilter("playground") || {}).value,
        (returnFilter("sport") || {}).value,
        (returnFilter("visibility") || {}).value
      );
      playgrounds.value = refactorPlaygrounds(filtered);
      playgroundMap.value = { ...constructPlaygroundMap(filtered) };
      reportWindowSize(true);
    }

    function removeTimeslotFilter() {
      removeFilter({ type: "timeslot" });
    }

    function scrollOntoEvent(event) {
      if (event) {
        if (filtersTimeslotsApplied.value) {
          removeTimeslotFilter();
        }
        const start_at =
          event.type === BookingType.CLOSING
            ? mapTimestamp(
                transformStringToDate(event.date_from, "MM/DD/YYYY").getTime(),
                myClub.value.opening_hour
              )
            : event.start_at;
        goToPage(event, start_at);
      } else {
        refreshPage();
      }
      hideModal();

    }

    function downloadTodayTimeslots() {
      convertArrayIntoCsv(
        convertSchedulerToArray(events.value, t, playgroundMap.value),
        `calendar_${formatDateDDMMYYYY(selectedDate.value)}`
      );
    }

    function convertMonthTimeslots(monthObj) {
      convertArrayIntoCsv(
        convertMonthSchedulerToArray(monthObj, t, playgroundMap.value),
        `month_calendar_${formatDateDDMMYYYY(selectedDate.value)}`
      );
    }

    function logout() {
      store.dispatch(ActionTypes.LOGOUT, null);
      router.push("login");
    }

    async function hideModalAndRefresh() {
      hideModal();
      try {
        await refreshPage();
      } catch (err) {
        return;
      }
    }

    function resetDownloadCalendarForm() {
      downloadCalendarForm.value = JSON.parse(
        JSON.stringify(emptyDownloadCalendarForm)
      );
    }

    function updateFormDownloadCalendar(value, field) {
      downloadCalendarForm.value[field] = value;
    }

    function handleToggleDailyDownload(event) {
      updateFormDownloadCalendar(undefined, "start_date");
      console.log("toggle daily download: ", event);
    }

    async function monthlyDownload(month_date: Date) {
      try {
        return await getCalendarEvents({
          club_id: myClub.value.club_id,
          start_date: formatDateMMDDYYYY(month_date),
        }).then((values) => {
          return values;
        });
      } catch (err) {
        return;
      }
    }

    async function downloadExcel() {
      if (downloadCalendarForm.value.daily_download) {
        downloadTodayTimeslots();
      } else {
        const monthEvents = await monthlyDownload(
          downloadCalendarForm.value.start_date
        );
        convertMonthTimeslots(monthEvents);
        console.log("month events: ", monthEvents);
      }
      resetDownloadCalendarForm();
      hideModal();
    }

    function getPlayground(id) {
      return allPlaygrounds.value.find((el) => el.id === id);
    }

    function switchCalendarView(value) {
      showPriceRules.value = value;
    }

    function showTooltip($event, id) {
      const rect = $event.srcElement.getBoundingClientRect();
      tooltipId.value = id;
      tooltipX.value = rect.x - 240;
      tooltipY.value = rect.bottom - rect.top + 25;
    }

    function hideTooltip() {
      tooltipId.value = null;
      tooltipX.value = null;
      tooltipY.value = null;
    }

    function checkBundle(event) {
      if (event) {
        store.commit(MutationTypes.SET_BUNDLE_RESPONSE, event);
        setTimeout(() => {
          router.push("confirm-bundle");
        }, 100);
      } else {
        refreshPage();
      }
      hideModal();
    }

    function setUploadPlayground(val) {
      uploadPlayground.value = val;
    }

    function isUploadEmpty(playground, file) {
      if (playground && file) return false;
      return true;
    }

    function fileAdded() {
      if (fileUploader.value && fileUploader.value.files) {
        try {
          const file = fileUploader.value.files[0];
          const extension = file.name.split(".").pop();

          if (extension != "csv") {
            fileUploader.value.files = [];
            return;
          }

          // SET
          uploadFile.value = file;
        } catch (e) {
          console.error(e);
        }
      }
    }

    function fileRemoved() {
      uploadFile.value = null;
    }

    async function uploadBookings() {
      try {
        await store.dispatch(ActionTypes.UPLOAD_BOOKING_CSV, {
          club_id: myClub.value.club_id,
          id: uploadPlayground.value.id,
          file: uploadFile.value,
        });
        // ALL GOOD
        uploadStep.value = 3;
      } catch (e) {
        console.error(e);
        alert(e);
        uploadStep.value = 4;
      }
    }

    function downloadCSV(url, name) {
      const link = document.createElement("a");
      link.setAttribute("download", name);
      link.href = url;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }

    function userSelected(user: PadelUser) {
      store.commit(MutationTypes.SET_SEARCH_USER, user);
      router.push({ name: "scheduled", params: { id: user.id } });
    }

    //METODO PER GESTIRE IL BUG DI VUE CAL CHE CHIAMA ENTRAMBI GLI EVENTI DI DELETE, DI CLICK E DRAG
    function handleEvents(event_name, event) {
      if (event_name === "drag-create") {
        handleDragCreate(event);
      } else if (event_name === "dbl-click") {
        // PARSE
        const d = event.date;
        const y = d.getFullYear();
        const m = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
        const dd = d.getDate();
        const hh = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours();
        const string = `${y}-${m}-${dd} ${hh}:00`;

        // REFORMAT
        const tmpStart = moment(string);
        if (d.getMinutes() < 30) {
          tmpStart.set("minute", 0);
        } else {
          tmpStart.set("minute", 30);
        }
        const start = tmpStart.clone().toDate();
        const end = moment(start).add(1, "hour");
        const startTime =
          tmpStart.clone().hours() * 60 + tmpStart.clone().minutes();
        const endTime = end.clone().hours() * 60 + end.clone().minutes();

        // CREATE
        const mEvent = {
          _eid: "custom",
          allDay: false,
          background: false,
          class: "",
          content: "",
          daysCount: 1,
          end: end.toDate(),
          endTimeMinutes: endTime,
          split: event.split,
          start: start,
          startTimeMinutes: startTime,
          title: "",
          playground_id: splits.value[event.split].playground,
        };
        handleDragCreate(mEvent as VueCalEvent);
      } else if (event_name === "cell-click") {
        setTimeout(() => {
          if (!deleteClicked.value) {
            handleClick(event);
            deleteClicked.value = false;
          }
        }, 150);
      } else if (event_name === "event-delete") {
        deleteClicked.value = true;
        handleDelete(event);
        setTimeout(() => {
          deleteClicked.value = false;
        }, 300);
      } else if (event_name === "ready") {
        setTimeout(() => {
          const event = store.getters.getScrollEvent;
          if (event) {
            store.commit(MutationTypes.SET_SCROLL_EVENT, null);
            scrollOntoEvent(event);
          }
        }, 250);
      }
    }


    return {
      t,
      locale,
      uppercase,
      timeCellHeight,
      timeFrom,
      timeTo,
      selectedDate,
      stickySplitLabels,
      minCellWidth,
      minSplitWidth,
      tempDeleted,
      display,
      splits,
      events,
      show_calendar,
      playgrounds,
      showCalendarBox,
      playgroundPages,
      currentPage,
      modalToShow,
      computedCalendarFilterOptions,
      playgroundOptions,
      computedPlaygrounds,
      sportOptions,
      filtersForm,
      activeFilters,
      computedEvents,
      computedSplits,
      computedTimeFrom,
      computedTimeTo,
      playgroundMap,
      bookingToDelete,
      new__booking,
      provvNewEvent,
      downloadCalendarForm,
      showPriceRules,
      switchCalendarView,
      tooltipId,
      tooltipX,
      tooltipY,
      mySports,
      fileUploader,
      userHasCards,

      handleEvents,
      eliminateEvent,
      abortEliminateEvent,
      formatDateYYYYMMDD,
      goToToday,
      goToYesterday,
      goToTomorrow,
      formatDate,
      goToAddPlaygrounds,
      toggleCalendar,
      changeDateWithCalendar,
      goToPreviousPage,
      goToNextPage,
      convertMinutesOfDayToHHMM,
      openModal,
      hideModal,
      abortFilters,
      setFilterFormValue,
      toggleFilterFormValue,
      initializeFilterForm,
      applyFilters,
      removeFilter,
      isFilterActive,
      detailBooking,
      refreshPage,
      deleteTimeslot,
      handleDelete,
      logout,
      openNewBooking,
      hideModalAndRefresh,
      specialHours,
      tournamentDetails,
      goToTournaments,
      createValue,
      handleToggleDailyDownload,
      updateFormDownloadCalendar,
      downloadExcel,
      scrollOntoEvent,
      getPlayground,
      getSportByValue,
      showTooltip,
      hideTooltip,
      mapContentForTooltip,
      checkBundle,
      uploadStep,
      uploadPlayground,
      uploadFile,
      setUploadPlayground,
      isUploadEmpty,
      fileAdded,
      fileRemoved,
      uploadBookings,
      editBundle,
      editSingleBooking,
      editTaking,
      downloadCSV,
      userSelected,
    };
  },
  components: {
    VueCal,
  },
});
