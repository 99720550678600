
import { computed, defineComponent, nextTick, onMounted, ref } from "vue";
import {
  mapBookingToInfoForm,
  mapBookingError,
  mySportsByOptions,
  returnSearchUserDropdownChoices,
} from "@/services/mapping.service";
import {
  isDateAfter,
  isTimeBeforeNow,
  isTimeAfterEnd,
  isToday,
  isDurationOverClosing,
  isDateBefore,
  convertDateAndTimeToTimestamp,
  getNextDayMidnight,
  calculateGameDuration,
  convertHHMMtoMinutesOfDay,
  mappedWeekDay,
  convertHHMMtoMillisecondsOfDay,
  convertMillisecondsOfDaytoHHMM,
} from "@/services/dates.service";
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import { uppercase, mapModifyBookingPayload } from "@/services/mapping.service";
import {
  gameDurationOptions,
  eventTypeOptions,
} from "@/constants/radio-options.constants";
import { PaymentOptions } from "@/constants/payment-choices.constants";
import {PaymentTypeChoices } from "@/constants/payment-type-choices.constants";
import {
  emptyBookingDetailErrors,
  emptyPadelUser,
} from "@/constants/initial-values.constants";
import {
  isFormBookingEmpty,
  isFormBookingValid,
} from "@/services/form-validation.service";
import { ActionTypes } from "@/store/action-types";
import { BookingType } from "@/constants/booking.enum";
import {
  BookingColorChoices,
  ClosureColorChoices,
  LessonColorChoices,
  MantainanceColorChoices,
  OtherColorChoices,
  WeSmashBookingColorChoices,
} from "@/constants/color-choices.constants";
import { CALENDAR_USERS_PAGE_SIZE, PlayerCategoriesOptions } from "@/constants/constants.constants";
import { PadelUser } from "@/models/user.models";
import { showToast } from "@/services/error-management.service";
import { ToastErrors } from "@/constants/generic-errors.enum";
import moment from "moment";
import RepeatBooking from "@/components/repeat-booking/RepeatBooking.vue";
import { PadelClub } from "@/models/club.models";
import { CardCategories } from "@/constants/card-types.enum";

export default defineComponent({
  components: {
    RepeatBooking,
  },
  props: [
    "booking",
    "playgroundOptions",
    "playgroundMap",
    "openingHour",
    "closingHour",
    "sportsOptions",
    "hasCards"
  ],
  emits: ["hideModal", "onCancel", "creationCompleted", "checkBundle", "onCancelWithRefund"],
  setup(props, { emit }) {
    
    const colorChoices = computed(function () {
      switch (props.booking.timeslotInfo.type) {
        case BookingType.BOOKING:
          return WeSmashBookingColorChoices;
        case BookingType.BACKOFFICE_BOOKING:
          return BookingColorChoices;
        case BookingType.LESSON:
        case BookingType.BACKOFFICE_LESSON:
          return LessonColorChoices;
        case BookingType.MAINTENANCE:
          return MantainanceColorChoices;
        case BookingType.CLOSING:
          return ClosureColorChoices;
        case BookingType.OTHER:
          return OtherColorChoices;
        default:
          return OtherColorChoices;
      }
    });

    const store = useStore();
    const { t, locale } = useI18n();
    const formDisabled = ref(true);
    const componentKey = ref(0);
    const withEndTime = ref(false);
    const bookingInfoForm = ref({
      ...mapBookingToInfoForm(
        props.booking,
        props.playgroundOptions,
        props.hasCards,
        colorChoices.value,
      ),
    });
    const bookingInfoErrors = ref(
      JSON.parse(JSON.stringify(emptyBookingDetailErrors))
    );

    // onMounted(() => {
    //   nextTick(() => {
    if (bookingInfoForm.value.game_duration > 120) {
      withEndTime.value = true;
    }
    //   });
    // });
    

    // TABS
    const selectedTab = ref(0);
    const searchOptions = ref([]);
    const searchPlaceholder = ref(null);
    const oldWeSmashUserId = ref(null);

    const paymentTypes = PaymentTypeChoices;

    // SET DEFAULT TAB
    if (props.booking.timeslotInfo.wesmash_user_id) {
      selectedTab.value = 0;
    } else if (props.booking.timeslotInfo.player_description) {
      selectedTab.value = 2;
    } else {
      selectedTab.value = 1;
    }

    function hideModal() {
      emit("hideModal", true);
    }

    function emitCancel() {
      emit("onCancel", true);
    }

    //COMPUTED
    const myClub = computed(function (): PadelClub {
      return store.getters.getMyClub;
    });

    const myClubId = computed(function (): string {
      return store.getters.getMyClub.club_id;
    });

    const bookingCategoriesOptions = computed(function (): Array<any> {
      if (myClub.value.club_category == CardCategories.COMMERCIAL) {
        return [PlayerCategoriesOptions[1]]; // PlayerCategoriesOptions[1] => Commerciale
      }
      return PlayerCategoriesOptions;
    });

    const gameDurations = computed(function () {
      const _provv = gameDurationOptions.map((a) => ({ ...a }));
      _provv.forEach((val) => {
        if (val.value !== bookingInfoForm.value.game_duration) {
          val.disabled = true;
        } else {
          val.disabled = false;
        }
      });
      return _provv;
    });

    const mySports = computed(function () {
      return mySportsByOptions(props.sportsOptions, props.playgroundOptions);
    });

    const computedPlaygrounds = computed(function (): {
      value: string;
      label: string;
      sport: string;
    }[] {
      return props.playgroundOptions.filter(
        (el) => el.sport === bookingInfoForm.value.sport_type
      );
    });

    function isBookingTimeAcceptable() {
      const MODIFIABLE_UNTIL = 1000;
      const tomorrowTimestamp = getNextDayMidnight(
        new Date(props.booking.timeslotInfo.start_at),
        MODIFIABLE_UNTIL
      );
      return isDateAfter(new Date(tomorrowTimestamp), new Date());
    }

    const is__booking__modifiable = computed(() => {
      return (
        (bookingInfoForm.value.type === BookingType.BOOKING ||
          bookingInfoForm.value.type === BookingType.BACKOFFICE_BOOKING ||
          bookingInfoForm.value.type === BookingType.LESSON ||
          bookingInfoForm.value.type === BookingType.BACKOFFICE_LESSON ||
          bookingInfoForm.value.type === BookingType.BACKOFFICE_EVENT) &&
        isBookingTimeAcceptable()
      );
    });

    const is_booking_started = computed(() => {
      return isDateBefore(
        new Date(props.booking.timeslotInfo.start_at),
        new Date()
      );
    });

    const mustSyncPrice = computed(() => {
      return [
        BookingType.BOOKING,
        BookingType.LESSON,
        BookingType.BACKOFFICE_LESSON_DESCRIPTION,
        BookingType.BACKOFFICE_BOOKING_DESCRIPTION,
        BookingType.BACKOFFICE_BOOKING,
        BookingType.BACKOFFICE_LESSON,
      ].includes(bookingInfoForm.value.type as BookingType);
    });

    const timeFrom = computed(() => {
      return myClub.value.opening_hour / 1000 / 60 / 60;
    });
    const timeTo = computed(() => {
      return myClub.value.closing_hour / 1000 / 60 / 60;
    });

    function getPriceFromSlotInRules(playgroundId, rules) {
      // INIT
      let endTime = bookingInfoForm.value.hour_end;
      if (!withEndTime.value) {
        const time =
          convertHHMMtoMillisecondsOfDay(bookingInfoForm.value.start_at) +
          bookingInfoForm.value.game_duration * 60 * 1000;
        endTime = convertMillisecondsOfDaytoHHMM(time);
      }
      const date = moment(bookingInfoForm.value.date);
      const bookingDay = date.weekday();
      const realDay = mappedWeekDay(bookingDay);
      const startAt = moment(
        `${date.format("YYYY-MM-DD")} ${bookingInfoForm.value.start_at}`
      );
      const endAt = moment(`${date.format("YYYY-MM-DD")} ${endTime}`);

      // ITERATE
      for (let x = 0; x < rules.length; x++) {
        const rule = rules[x];
        if (!rule.playground_ids.includes(playgroundId)) continue;
        if (rule.days) {
          if (!rule.days.includes(realDay)) continue;
        }
        const slots = rule.slots.reverse();

        // DATES
        let ruleStartAt = null;
        let ruleEndAt = null;

        // CHECK TYPE
        if (rule.exception_at) {
          ruleStartAt = moment(`${rule.exception_at}`);
          ruleEndAt = moment(`${rule.exception_at}`);
        } else {
          ruleStartAt = moment(`${date.format("YYYY")}-${rule.start_at}`);
          ruleEndAt = moment(`${date.format("YYYY")}-${rule.end_at}`);
        }

        // EXIT POINT
        if (
          !(
            date.valueOf() >= ruleStartAt.valueOf() &&
            date.valueOf() <= ruleEndAt.valueOf()
          )
        )
          continue;
        for (let i = 0; i < slots.length; i++) {
          const slot = slots[i];
          const slotStartAt = moment(
            `${date.format("YYYY-MM-DD")} ${slot.start_at}`
          );
          const slotEndAt = moment(
            `${date.format("YYYY-MM-DD")} ${slot.end_at}`
          );

          if (
            (startAt.valueOf() >= slotStartAt.valueOf() &&
              endAt.valueOf() <= slotEndAt.valueOf()) ||
            (startAt.valueOf() >= slotStartAt.valueOf() &&
              startAt.valueOf() <= slotEndAt.valueOf())
          ) {
            const minutes = moment(endAt).diff(moment(startAt), "minutes");
            if (minutes == 120 && slot.price_120 > -1)
              return slot.price_120 / 100;
            if (minutes >= 90 && minutes < 120 && slot.price_90 > -1)
              return slot.price_90 / 100;
            if (minutes >= 60 && minutes < 90 && slot.price_60 > -1)
              return slot.price_60 / 100;
            return null;
          }
        }
      }
      return null;
    }

    async function syncPrice() {
      // GET RULES
      try {
        const startAt = bookingInfoForm.value.date;
        const endAt = bookingInfoForm.value.date;
        let price = null;

        // GET EXCEPTIONS
        const exceptions = await store.dispatch(
          ActionTypes.GET_EXCEPTION_RULES,
          {
            club_id: myClubId.value,
            exception_at: startAt.toISOString(),
            limit: 1,
          }
        );
        price = getPriceFromSlotInRules(
          bookingInfoForm.value.playground.id,
          exceptions.records
        );

        if (!price) {
          // GET RULES
          const rules = await store.dispatch(ActionTypes.GET_PRICES_RULES, {
            club_id: myClubId.value,
            between_start: startAt.toISOString(),
            between_end: endAt.toISOString(),
            limit: 999,
          });
          price = getPriceFromSlotInRules(
            bookingInfoForm.value.playground.id,
            rules.records
          );
        }

        // CHECK
        if (price) {
          bookingInfoForm.value.price = price;
        } else {
          bookingInfoForm.value.price = null;
        }
      } catch (error) {
        return;
      }
    }

    function resetErrors() {
      bookingInfoErrors.value = JSON.parse(
        JSON.stringify(emptyBookingDetailErrors)
      );
    }

    function resetForm() {
      bookingInfoForm.value = {
        ...mapBookingToInfoForm(
          props.booking,
          props.playgroundOptions,
          props.hasCards,
          colorChoices.value
        ),
      };
      resetErrors();
      componentKey.value++;
    }

    async function getUser(id: string, callback?: Function) {
      try {
        const user = await store.dispatch(ActionTypes.GET_USER, {
          club_id: myClub.value.club_id,
          user_id: id,
        });
        if (user) {
          bookingInfoErrors.value.wesmash_user = null;
          searchOptions.value = returnSearchUserDropdownChoices([user]);
          if (callback) return callback(user);
          return user;
        } else {
          bookingInfoErrors.value.wesmash_user = t("no_user_found");
          searchOptions.value = [];
        }
      } catch (error) {
        bookingInfoErrors.value.wesmash_user = t("no_user_found");
        searchOptions.value = [];
        return;
      }
    }

    async function searchUsers(event: string, callback?: Function) {
      searchPlaceholder.value = event;
      try {
        const usersArray = await store.dispatch(ActionTypes.GET_USERS, {
          club_id: myClubId.value,
          filters: { page_size: CALENDAR_USERS_PAGE_SIZE, name: event },
        });
        if (usersArray.values.length > 0) {
          bookingInfoErrors.value.wesmash_user = null;
          //POPOLAMENTO DROPDOWN CHOICES
          searchOptions.value = returnSearchUserDropdownChoices(
            usersArray.values
          );
          callback(usersArray.values);
        } else {
          bookingInfoErrors.value.wesmash_user = t("no_user_found");
          searchOptions.value = [];
        }
      } catch (error) {
        return;
      }
    }

    function handleSearchClose() {
      searchOptions.value = null;
      searchPlaceholder.value = null;
      bookingInfoForm.value.wesmash_user = { ...emptyPadelUser };
      bookingInfoErrors.value.wesmash_user = null;
    }

    function searchValueSelected(event: { label: string; value: PadelUser }) {
      searchPlaceholder.value = event.label;
      bookingInfoForm.value.wesmash_user = event.value;
    }

    function onSearchValidate(event) {
      setTimeout(() => {
        searchPlaceholder.value = "";
      }, 10);
      searchPlaceholder.value = null;
      handleSearchClose();
    }

    onMounted(() => {
      gameDurations.value.forEach((val) => {
        if (val.value !== bookingInfoForm.value.game_duration) {
          val.disabled = true;
        } else {
          val.disabled = false;
        }
      });
      const start = convertHHMMtoMinutesOfDay(bookingInfoForm.value.start_at);
      const end = convertHHMMtoMinutesOfDay(bookingInfoForm.value.hour_end);
      if (end - start > 120) {
        withEndTime.value = true;
      }
      if (
        [
          BookingType.BOOKING,
          BookingType.BACKOFFICE_BOOKING,
          BookingType.LESSON,
          BookingType.BACKOFFICE_LESSON,
        ].includes(props.booking.timeslotInfo.type)
      ) {
        if (props.booking.timeslotInfo.wesmash_user_id) {
          console.log(props.booking.timeslotInfo.wesmash_user_id);
          getUser(
            props.booking.timeslotInfo.wesmash_user_id,
            (user: PadelUser) => {
              bookingInfoForm.value.wesmash_user = user;
              console.log(user);
              searchValueSelected({
                label: `${user.name} ${user.family_name} - ${user.email}`,
                value: user,
              });
            }
          );
        }
      }
    });

    function updateFormValue(value, field) {
      bookingInfoForm.value[field] = value;
      if (field === "sport_type") {
        updateFormValue(null, "playground");
      }
    }

    function updateFormError(field, value) {
      bookingInfoErrors.value[field] = value;
    }

    function updateDateAndTimeErrors(
      date: string,
      time: string,
      endTime: string
    ) {
      updateFormError("date", date);
      updateFormError("start_at", time);
      updateFormError("hour_end", endTime);
    }

    function handleExceedingOverClosureTimeError() {
      if (bookingInfoForm.value.game_duration) {
        if (
          isDurationOverClosing(
            bookingInfoForm.value.start_at,
            bookingInfoForm.value.game_duration,
            props.closingHour,
            props.openingHour
          )
        ) {
          updateFormError("start_at", t("booking_exceeds_closing_hours"));
        } else {
          updateFormError("start_at", "");
          // CALL PRICE RELOAD
          if (mustSyncPrice.value) {
            syncPrice();
          }
        }
      } else if (bookingInfoForm.value.hour_end) {
        const duration = calculateGameDuration(
          bookingInfoForm.value.hour_end,
          bookingInfoForm.value.start_at
        );
        if (
          isDurationOverClosing(
            bookingInfoForm.value.start_at,
            duration,
            props.closingHour,
            props.openingHour
          )
        ) {
          updateFormError("hour_end", t("booking_exceeds_closing_hours"));
        } else {
          updateFormError("hour_end", "");
          // CALL PRICE RELOAD
          if (mustSyncPrice.value) {
            syncPrice();
          }
        }
      } else {
        updateFormError("start_at", "");
        updateFormError("hour_end", "");
      }
    }

    function handleBookingBeforeNow(field: string) {
      if (field === "date" || field === "start_at") {
        if (
          isTimeBeforeNow(
            bookingInfoForm.value.start_at,
            bookingInfoForm.value.date
          )
        ) {
          updateFormError(field, t("cannot_book_before_now"));
        } else if (
          isTimeAfterEnd(
            bookingInfoForm.value.start_at,
            bookingInfoForm.value.hour_end
          )
        ) {
          updateFormError(field, t("cannot_book_after_end"));
        } else {
          updateDateAndTimeErrors("", "", "");
          handleExceedingOverClosureTimeError();
          if (mustSyncPrice.value) {
            syncPrice();
          }
        }
      } else if (field === "hour_end") {
        if (
          isTimeAfterEnd(
            bookingInfoForm.value.start_at,
            bookingInfoForm.value.hour_end
          )
        ) {
          updateFormError("hour_end", t("cannot_book_after_end"));
        } else {
          updateDateAndTimeErrors("", "", "");
          handleExceedingOverClosureTimeError();
          if (mustSyncPrice.value) {
            syncPrice();
          }
        }
      }
    }

    function handleBookingStartedValidation(field: string) {
      if (
        convertDateAndTimeToTimestamp(
          bookingInfoForm.value.date,
          bookingInfoForm.value.start_at
        ) < new Date().getTime()
      ) {
        updateFormError(field, t("cannot_move_started_booking_in_past"));
      } else {
        updateDateAndTimeErrors("", "", "");
        handleExceedingOverClosureTimeError();
        if (mustSyncPrice.value) {
          syncPrice();
        }
      }
    }

    function handleTimingErrors(field: string) {
      if (!is_booking_started.value) {
        handleBookingBeforeNow(field);
      } else {
        handleBookingStartedValidation(field);
      }
    }

    function validateHourToday(value, field: string) {
      if (!formDisabled.value) {
        updateFormValue(value, field);
        if (field === "game_duration") {
          if (
            !(
              isToday(bookingInfoForm.value.date) &&
              isTimeBeforeNow(bookingInfoForm.value.start_at)
            )
          ) {
            handleExceedingOverClosureTimeError();
          } else {
            // CALL PRICE RELOAD
            if (mustSyncPrice.value) {
              syncPrice();
            }
          }
        } else if (field === "start_at") {
          if (!withEndTime.value && 
            bookingInfoForm.value.type != BookingType.LESSON &&
            bookingInfoForm.value.type != BookingType.BACKOFFICE_LESSON &&
            bookingInfoForm.value.type != BookingType.BACKOFFICE_EVENT) {
            const millis =
              convertHHMMtoMillisecondsOfDay(bookingInfoForm.value.start_at) +
              bookingInfoForm.value.game_duration * 60 * 1000;
            const newEndHour = convertMillisecondsOfDaytoHHMM(millis);
            bookingInfoForm.value.hour_end = newEndHour;
          }
          else if (value !== '' && (
            bookingInfoForm.value.type == BookingType.LESSON ||
            bookingInfoForm.value.type == BookingType.BACKOFFICE_LESSON ||
            bookingInfoForm.value.type == BookingType.BACKOFFICE_EVENT)) {
            const millisStart = convertHHMMtoMillisecondsOfDay(value);
            const millisEnd = convertHHMMtoMillisecondsOfDay(bookingInfoForm.value.hour_end);
            const newDuration = (millisEnd - millisStart) / 60 / 1000;
            bookingInfoForm.value.game_duration = newDuration;
          }
          
          handleTimingErrors("start_at");
        } else if (field === "hour_end") {
          handleTimingErrors("hour_end");
        } else if (field === "date") {
          handleTimingErrors("date");
        }
      }
    }

    function validateHours() {
      validateHourToday(bookingInfoForm.value.game_duration, "game_duration");
      validateHourToday(bookingInfoForm.value.start_at, "start_at");
    }

    function disableForm() {
      formDisabled.value = true;
      resetErrors();
    }

    function enableForm() {
      formDisabled.value = false;
      /* validateHours(); */
    }

    function abortChanges() {
      disableForm();
      resetForm();
    }

    function handleNotCreatedBookingError(error) {
      updateFormError("start_at", mapBookingError(error, props.playgroundMap));
    }

    async function saveChanges() {
      try {
        if (!mustSyncPrice.value) withEndTime.value = true;
        if (bookingInfoForm.value.override_bundle) {
          const payload = mapModifyBookingPayload(
            myClubId.value,
            bookingInfoForm.value,
            props.booking.timeslotInfo,
            oldWeSmashUserId.value,
            withEndTime.value,
            selectedTab.value
          );

          const bundleResponse = await store.dispatch(
            ActionTypes.BOOKING_BUNDLE_CHECK,
            [payload]
          );
          emit("checkBundle", bundleResponse);
        } else {
          const payload = mapModifyBookingPayload(
            myClubId.value,
            bookingInfoForm.value,
            props.booking.timeslotInfo,
            oldWeSmashUserId.value,
            withEndTime.value,
            selectedTab.value
          );

          const bookingResponse = await store.dispatch(
            ActionTypes.MODIFY_BOOKING,
            payload
          );
          emit("creationCompleted", payload);
        }
        hideModal();
      } catch (error) {
        if (error.response && error.response.status === 410) {
          showToast(ToastErrors.USER_HAS_DELETED_BOOKING);
          hideModal();
        } else if (error.response && error.response.status === 409) {
          handleNotCreatedBookingError(error.response.data);
        } else {
          showToast(ToastErrors.GENERIC);
        }
        return;
      }
    }

    function switchEndTime(value) {
      withEndTime.value = value;
    }

    function onChangeTab(event) {
      selectedTab.value = event;
      if (event !== 0) {
        bookingInfoForm.value.wesmash_user = emptyPadelUser;
        bookingInfoErrors.value.wesmash_user = "";
      } else {
        bookingInfoForm.value.player_description = "";
        bookingInfoErrors.value.player_description = "";
      }
    }

    return {
      t,
      locale,
      bookingInfoForm,
      formDisabled,
      gameDurations,
      gameDurationOptions,
      eventTypeOptions,
      componentKey,
      withEndTime,
      PaymentOptions,
      bookingInfoErrors,
      isFormBookingEmpty,
      isFormBookingValid,
      colorChoices,
      switchEndTime,
      computedPlaygrounds,
      mySports,
      selectedTab,
      searchOptions,
      searchPlaceholder,
      handleSearchClose,
      searchValueSelected,
      onSearchValidate,
      searchUsers,

      hideModal,
      emitCancel,
      uppercase,
      mapBookingToInfoForm,
      enableForm,
      disableForm,
      abortChanges,
      updateFormValue,
      saveChanges,
      is__booking__modifiable,
      validateHourToday,
      onChangeTab,
      timeFrom,
      timeTo,
      paymentTypes,
      bookingCategoriesOptions,
      PlayerCategoriesOptions
    };
  },
});
