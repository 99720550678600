<div v-if="showPreview" class="overlay-image">
    <div class="inner-pic-div"><img v-if="playgroundForm.profilePicture && playgroundForm.profilePicture.src"
            :src="playgroundForm.profilePicture.src" class="image-preview" />
        <img v-else :src="playgroundForm.picture_ref" class="image-preview" />
        <span class="material-icons icon-close" @click="closePreview()">
            clear
        </span>
    </div>
</div>

<DumbModal :title="title" @hide="hideModal()">
    <div class="modal-content">
        <div class="form-container flexbox-column">
            <div class="row first-row margin-bottom-20 flexbox-row justify-space-between">
                <div class="right-side fix-alignment">
                    <PadelInput :label="t('playground_name')" 
                        :value="playgroundForm.name"
                        @input="updateFormValue($event, 'name')" />
                </div>
                <div class="right-side">
                    <PadelDropdown 
                        class="dropdown" 
                        @input="updateFormValue($event.value, 'sport')"
                        :default="playgroundForm.sport" 
                        :options="SportOptions"
                        :label="t('sport_type')" />
                </div>
            </div>
            <div class="row second-row margin-bottom-20 flexbox-row justify-space-between">
                <div class="right-side">
                    <PadelDropdown 
                        class="dropdown" 
                        @input="updateFormValue($event.value, 'hidden')"
                        :default="playgroundForm.hidden" 
                        :options="VisibilityOptions"
                        :label="t('playground_visibility')" />
                </div>
                <div class="right-side">
                    <PadelDropdown 
                        class="dropdown" 
                        @input="updateFormValue($event.value, 'exposure')"
                        :default="playgroundForm.exposure" 
                        :options="playgroundsOptions"
                        :label="t('exposure')" />
                </div>
            </div>
            <div class="row third-row flexbox-row margin-bottom-20 margin-top-10 justify-space-between">
                <div class="color-white montserrat preview-text"
                    v-if="isModifyModal && playgroundForm.profilePicture && playgroundForm.profilePicture.src"
                    @click="openPreview()">
                    {{playgroundForm.profilePicture.name}}
                </div>
                <div class="color-white montserrat preview-text"
                    v-else-if="isModifyModal && playgroundForm.picture_ref"
                    @click="openPreview()">
                    {{'cover'}}
                </div>            
                <div v-else
                    style="padding-top: 10px;">
                    <PadelUpload @change="updateFormValue($event, 'profilePicture')" 
                        :icon="'add_a_photo'"
                        :label="uppercase(t('add_picture'))" 
                        :uploadedLabel="uppercase(t('picture_added'))"
                        @wrong-file-uploaded="handleWrongFileUploaded($event)"></PadelUpload>
                </div>
                <div class="right-side"
                    style="padding-top: 0px;">
                    <PadelDropdown 
                        class="dropdown" 
                        @input="updateFormValue($event.value, 'playground_size')"
                        :default="playgroundForm.playground_size" 
                        :options="SizesOptions"
                        :label="t('playground_size')"
                        v-if="hasPlaygroundSize" />
                    <PadelDropdown 
                        class="dropdown" 
                        @input="updateFormValue($event.value, 'ground')"
                        :default="playgroundForm.ground" 
                        :options="GroundOptions"
                        :label="t('ground')"
                        v-else />
                </div>
            </div>
            <div class="row third-row flexbox-row margin-bottom-20 margin-top-10 justify-space-between"
                v-if="hasPlaygroundSize || hasWalls">
                <div class="right-side"
                    style="padding-top: 0px;">
                </div>
                <div class="right-side"
                    style="padding-top: 0px;">
                    <PadelDropdown 
                        class="dropdown" 
                        @input="updateFormValue($event.value, 'ground')"
                        :default="playgroundForm.ground" 
                        :options="GroundOptions"
                        :label="t('ground')"
                        v-if="hasPlaygroundSize" />
                    <PadelDropdown 
                        class="dropdown" 
                        @input="updateFormValue($event.value, 'walls')"
                        :default="playgroundForm.walls" 
                        :options="PlaygroundTypesOptions"
                        :label="t('walls')"
                        v-else />
                </div>
            </div>
            <div class="row third-row flexbox-row margin-bottom-20 margin-top-10 justify-space-between"
                v-if="hasWalls && hasPlaygroundSize">
                <div class="right-side"
                    style="padding-top: 0px;">
                </div>
                <div class="right-side"
                    style="padding-top: 0px;">
                    <PadelDropdown 
                        class="dropdown" 
                        @input="updateFormValue($event.value, 'walls')"
                        :default="playgroundForm.walls" 
                        :options="PlaygroundTypesOptions"
                        :label="t('walls')" />
                </div>
            </div>
        </div>
        <div class="bottom-section flexbox-column justify-space-between align-center">
            

            <div class="flexbox-row justify-space-between buttons-container">
                <p class="font-12 color-blue cursor-pointer montserrat bold-text" @click="hideModal()">
                    {{uppercase(t('back'))}}
                </p>
                <div class="right-side-buttons">
                    <div class="margin-right-10">
                        <PadelUpload v-if="(isModifyModal && playgroundForm.profilePicture && playgroundForm.profilePicture.src) ||
                            (isModifyModal && playgroundForm.picture_ref)"
                            @change="updateFormValue($event, 'profilePicture')" :border="true"
                            :label="uppercase(t('add_picture'))" :uploadedLabel="uppercase(t('picture_added'))"
                            @wrong-file-uploaded="handleWrongFileUploaded($event)"></PadelUpload>
                    </div>
                    <PadelButton :label="uppercase(t('save'))" :type="'primary'" @click="savePlayground()"
                        :disabled="formPlaygroundEmpty(playgroundForm)"></PadelButton>
                </div>
            </div>
        </div>

    </div>
</DumbModal>