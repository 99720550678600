
import {
  mapCognitoError,
  mapInputError,
  uppercase,
} from "@/services/mapping.service";
import { ActionTypes } from "@/store/action-types";
import { useStore } from "@/store/store";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { confirmsignupSchemaValidation } from "@/services/form-validation.service";

export default defineComponent({
  name: "PadelConfirmSignup",
  setup() {
    const store = useStore();
    const router = useRouter();
    const isVisible = ref(true);
    const codeValue = ref("");
    const codeError = ref("");
    const schema = confirmsignupSchemaValidation;
    const { t, locale } = useI18n();

    /* COMPUTED */
    const confirmSignupUser = computed(function() {
      return store.getters.getConfirmSignupUser;
    });

    /* FUNCTIONS */
    function goBack() {
      router.back();
    }

    function redirect(page: string) {
      router.push(page);
    }

    async function confirmSignUp() {
      try {
        await store.dispatch(ActionTypes.CONFIRM_SIGNUP, {
          username: confirmSignupUser.value.username,
          code: codeValue.value,
        });
        redirect("confirm-phone-number");
      } catch (error) {
        codeError.value = mapCognitoError(error, t);
      }
    }

    function updateFormError(value: string) {
      codeError.value = value;
    }

    function validate() {
      schema
        .validateAt("code", { code: codeValue.value })
        .then(() => {
          updateFormError("");
        })
        .catch((err) => {
          updateFormError(mapInputError(err.message, t));
        });
    }

    function updateFormValue(event) {
      codeValue.value = event;
    }

    async function resendCode() {
      try {
        await store.dispatch(
          ActionTypes.RESEND_CODE,
          confirmSignupUser.value.username
        );
      } catch (error) {
        return;
      }
    }

    return {
      t,
      locale,
      isVisible,
      codeValue,
      codeError,
      validate,
      redirect,
      uppercase: uppercase,
      goBack: goBack,
      confirmSignUp: confirmSignUp,
      updateFormValue: updateFormValue,
      resendCode: resendCode,
    };
  },
});
