
import { useI18n } from "vue-i18n";
import { uppercase, upperCaseFirstLetter } from "@/services/mapping.service";
import { doubleZeros, formatDateTournament } from "@/services/dates.service";
import { defineComponent } from "vue";
import {
  emptyTournament,
} from "@/constants/initial-values.constants";

export default defineComponent({
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {
        return { ...emptyTournament };
      },
    },
    club_picture_ref: {
      type: String,
      required: true,
      default: "",
    },
  },
  emits: ["deleteTournament", "modifyTournament"],
  setup(props, { emit }) {
    const { t, locale } = useI18n();

    function deleteTournament() {
      emit("deleteTournament");
    }

    function modifyTournament() {
      emit("modifyTournament");
    }

    return {
      t,
      locale,
      uppercase,
      upperCaseFirstLetter,
      doubleZeros,
      deleteTournament,
      modifyTournament,
      formatDateTournament
    };
  },
});
