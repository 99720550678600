
import { defineComponent } from 'vue';
import { useI18n } from "vue-i18n";
import { uppercase } from "@/services/mapping.service";
import { mockedClubsCreation } from '@/api/mock/data/user';

export default defineComponent({
    name: 'Disambiguation',
    setup() {
        const { t, locale } = useI18n();
        return { t, locale, uppercase }
    },
    data() {
        return {
            isVisible: true,
            selectedClub: null
        }        
    },
    methods: {
         isSelected(club) {
            if(this.selectedClub && club.club_id === this.selectedClub.club_id) {
                return "selected"
            } else { return "" }
        },
        hideModal() {
            this.isVisible = true;
            this.$emit('hide', true);
        },
        selectClub(club) {
            this.selectedClub = club;
        },
        emitClickedClub() {
            this.$emit('clubSelected', this.selectedClub)
        }
    },
    props: {
        clubs : { type: Array , required: true}
    },
    emits: ['hide', 'clubSelected']
});
