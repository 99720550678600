<div class="width-100">
    <div class="p-grid p-grid-header">
        
        <div class="p-col-12 p-md-5 p-d-flex p-align-center p-align-start"
            :class="{'p-md-5': !!onlyAllPlaygrounds === false, 'p-md-12': !!onlyAllPlaygrounds === true}">
            <div class="p-d-flex p-align-center p-align-start calendar-header-controls"
                v-if="calendarView == 'week'">
                <i class="pi pi-chevron-left color-blue cursor-pointer"
                    @click="addDays(-7)"></i>
                <i class="pi pi-chevron-right color-blue cursor-pointer"
                    @click="addDays(7)"></i>
                <Calendar id="dateformat" 
                    ref="dateCalendar"
                    v-model="selectedDate" 
                    dateFormat="D dd MM yy"
                    inputClass="p-d-none"
                    @date-select="onDateSelected" />
                <span class="p-calendar p-component p-inputwrapper p-inputwrapper-filled">
                    <input class="p-inputtext p-component" 
                        type="text" 
                        inputmode="none" 
                        :value="ucwords(weekFormat)"
                        @click.prevent="openCalendar">
                </span>
            </div>
            <div class="p-d-flex p-align-center p-align-start calendar-header-controls day-view"
                v-if="calendarView == 'day'">
                <PadelButton 
                    type="dark" 
                    :label="uppercase(t('today'))"
                    class="margin-right-20" 
                    @click="goToToday()" />
                <i class="pi pi-chevron-left color-blue cursor-pointer"
                    @click="addDays(-1)"></i>
                <i class="pi pi-chevron-right color-blue cursor-pointer"
                    @click="addDays(1)"></i>
                <Calendar id="fullFormat" 
                    v-model="selectedDate" 
                    dateFormat="DD dd MM yy"
                    @date-select="onDateSelected" />
            </div>
        </div>
        
        <div class="p-col-12 p-md-7 p-d-flex p-flex-sm-row p-flex-column p-align-center p-justify-end"
            id="calendar_filters"
            v-if="!!onlyAllPlaygrounds === false">
            <PadelDropdown 
                class="dropdown adjust-dropdown-alignment" 
                :label="t('sport')"
                :options="mySports" 
                @input="selectSport($event.value)"
                :default="selectedSport" />
            <PadelDropdown 
                v-if="!filterAllPlaygrounds"
                class="dropdown adjust-dropdown-alignment" 
                :label="t('playgrounds')"
                :options="computedPlaygrounds" 
                @input="selectPlayground($event.value)"
                :default="selectedPlayground" />
            <PadelToggle 
                v-model:checked="filterAllPlaygrounds"
                @change="switchAllPlaygrounds($event)"
                containerClass="p-d-inline-flex p-ml-2"
                labelClass="color-white overflow-inherit p-size-mini"
                :label="t('all_playgrounds')" />
        </div>

    </div>

    <div class="cal-container prices-calendar"
        :class="containerClass">
        <vue-cal 
            id="vuecal"
            ref="calendar"
            :time-cell-height="timeCellHeight" 
            locale="it"
            :selected-date="formatDateYYYYMMDD(selectedDate)"
            :time-from="computedTimeFrom" 
            :time-to="computedTimeTo" 
            hide-view-selector
            :disable-views="['years', 'year', 'month']"
            :sticky-split-labels="true"
            :active-view="calendarView" 
            :time-step="30"
            :min-event-width="100" 
            :snapToTime="30"
            :min-split-width="220"
            :split-days="computedSplits"
            :editable-events="{ delete: false, create: false }" 
            :events="eventsList"
            :special-hours="specialHours"
            :on-event-click="onEventClick"
            :dblclick-to-navigate="false">
            <template v-slot:split-label="{ split, view }">
                <div class="p-d-flex flex-100">
                    <div class="image-container table-head">
                        <Avatar v-if="split.playground.picture_ref" 
                            shape="circle"
                            size="large"
                            :image="split.playground.picture_ref" />
                        <img v-else src="@/theme/imgs/default_campo.png" class="image-circle" />
                    </div>
                    <div class="calendar-header-description">
                        <div class="calendar-header-title">{{ split.playground.name }}</div>
                        <!--<div class="calendar-header-address color-white">
                            {{ `${t(split.playground.exposure) || '-'} | ${t(split.playground.playground_size) || '-'}` }}
                        </div>-->
                        <div class="calendar-header-address color-white">
                            {{ getSportByValue(split.playground.sport) }}<br>
                            {{ t(split.playground.hidden ? 'private' : 'public') }}
                        </div>
                        <div class="position-relative flexbox-row" style="width: 100%">
                            <span class="i-icon margin-left-10 material-icons-outlined cursor-pointer color-blue" 
                                id="info-icon"
                                @click="showTooltip($event, split.playground.id)">
                                info
                            </span>
                        </div>
                        <PadelTooltip 
                            v-if="tooltipId == split.playground.id"
                            :absolute="true"
                            :x="tooltipX"
                            :y="tooltipY"
                            :dark="true"
                            class="tooltip" 
                            :arrowPosition="'NONE'"
                            :content="mapContentForTooltip(split.playground, t)"
                            @close="hideTooltip" />
                    </div>
                </div>
            </template>
        </vue-cal>
    </div>
</div>

<div v-if="showExceptionDialog">
    <ExceptionRuleDialog 
        :exceptionRule="selectedExceptionRule"
        :selectedDate="eventDate"
        @hide="hideExceptionDialog" />
</div>