
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { convertStatsMapToArray, uppercase } from "@/services/mapping.service";
import { adminDropdownChoices } from "@/constants/dropdown-choices.constants";
import { ClubStatus } from "@/constants/club-status.enum";
import { ActionTypes } from "@/store/action-types";
import { useStore } from "@/store/store";
import { formatDateYYYYMMDD } from "@/services/dates.service";
import { convertArrayIntoCsv } from "@/services/files.service";
import { emptyManager } from "@/constants/initial-values.constants";
export default defineComponent({
  props: ["selectedClub"],
  emits: ["returnToPrevPage"],
  setup(props, { emit }) {
    const store = useStore();
    const { t, locale } = useI18n();
    const modalOpened = ref(null);
    const selectedDate = ref(null);
    const formDisabled = ref(true);

    const manager = computed(function() {
      return props.selectedClub.managers[0] ? 
      props.selectedClub.managers[0] :
      {...emptyManager};
    });

    function returnChipClass(category: string) {
      if (category === ClubStatus.OPERATIVE) {
        return "background-green";
      } else if (category === ClubStatus.DISABLED) {
        return "background-gray";
      } else {
        return "";
      }
    }

    function returnToClubPage(refreshPage: boolean) {
      emit("returnToPrevPage", refreshPage);
    }

    function initialize() {
      selectedDate.value = null;
    }

    function openModal(modal: string) {
      modalOpened.value = modal;
    }

    function hideModal() {
      modalOpened.value = null;
      initialize();
    }

    async function retrieveClubStats() {
      try {
        const val = await store.dispatch(ActionTypes.GET_CLUB_STATS, {
          club_id: props.selectedClub.club_id,
          start_date: formatDateYYYYMMDD(selectedDate.value),
          handleLoaderInternally: true
        });
        const values = convertStatsMapToArray(Object.entries(val), t);
        convertArrayIntoCsv(values, "club_report");
      } catch (error) {
        return;
      }
      hideModal();
    }

    async function disableClub(club_id: string) {
      try {
        await store.dispatch(ActionTypes.DISABLE_CLUB, club_id);
      } catch (error) {
        return;
      }
    }

    async function enableClub(club_id: string) {
      try {
        await store.dispatch(ActionTypes.ENABLE_CLUB, club_id);
      } catch (error) {
        return;
      }
    }

    async function toggleClubStatus(status: string) {
      hideModal();
      try {
              if (status === ClubStatus.OPERATIVE) {
        await disableClub(props.selectedClub.club_id);
      } else {
        await enableClub(props.selectedClub.club_id);
      }
      returnToClubPage(true);
      } catch(err) {
        return;
      }

    }

    function changeDate(event) {
      selectedDate.value = event;
    }

    return {
      t,
      locale,
      uppercase,
      adminDropdownChoices,
      modalOpened,
      selectedDate,
      formDisabled,
      manager,

      returnChipClass,
      returnToClubPage,
      openModal,
      hideModal,
      retrieveClubStats,
      changeDate,
      toggleClubStatus,
    };
  },
});
