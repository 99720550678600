<div class="header flexbox-row padding-top-40 padding-bottom-40">
    <div class="cursor-pointer flexbox-row align-center" @click="handleBackClick()">
        <i class="material-icons color-blue cursor-pointer margin-right-10">keyboard_arrow_left</i>
        <div class="go-back-text color-blue">{{uppercase(t('back'))}}</div>
    </div>
</div>
<p class="title color-white">{{tournament.name}}</p>

<TabView class="tab-view" :activeIndex="selectedTab" @tab-change="onChangeTab($event.index)">
    <TabPanel>
        <template #header>
            <span class="material-icons-outlined" :class="{'color-blue': selectedTab === 0}">info</span>
            <span :class="{'color-blue': selectedTab === 0}">{{uppercase(t('info'))}}</span>
        </template>

        <TournamentInfo v-if="selectedTab === 0" :tournament="tournament" @isFormDirty="changeFormDirty($event)"
        @tournamentChanged="onTournamentChanged($event)" />
    </TabPanel>
    <TabPanel>
        <template #header>
            <span class="material-icons" :class="{'color-blue': selectedTab === 1}">schedule</span>
            <span :class="{'color-blue': selectedTab === 1}">{{uppercase(t('hour_slots'))}}</span>
        </template>
        <TournamentSlots v-if="selectedTab === 1" :tournament="tournament" :playgroundMapFull="playgroundMapFull"
        :playgroundOptions="playgroundOptions" @isFormDirty="changeFormDirty($event)"
        @tournamentChanged="onTournamentChanged($event)" />
    </TabPanel>
    <TabPanel>
        <template #header>
            <span class="material-icons-outlined" :class="{'color-blue': selectedTab === 2}">group</span>
            <span :class="{'color-blue': selectedTab === 2}">{{uppercase(t('registered_players'))}}</span>
        </template>
        <TournamentTeams v-if="selectedTab === 2" :tournament="tournament" @tournamentChanged="onTournamentChanged($event)" />
    </TabPanel>
</TabView>

<!-- MODALE CONFERMA INDIETRO -->
<div v-if="modalToShow === 'SURE_WANNA_CONFIRM'">
    <DumbModal :title="t('go_back')" @hide="hideModal()">
        
            <div class="top-part">
                <i class="material-icons icon-alert">warning_amber</i>
                <div class="margin-top-20">
                    <div class="color-white modal-text montserrat">{{t('tournament_go_back_modal_text')}}</div>
                </div>

            </div>
            <div class="buttons-container flexbox-row justify-space-between">
                <p class="abort" @click="hideModal()">{{uppercase(t('close'))}}</p>
                <PadelButton :label="uppercase(t('confirm'))" @click="goToFirstPage()" />
            </div>
      
    </DumbModal>
</div>