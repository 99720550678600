
import {
  emptyAddClubForm,
  emptyAddClubFormError,
  emptyServices,
} from "@/constants/initial-values.constants";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  convertFormIntoPayload,
  mapInputError,
  uppercase,
} from "@/services/mapping.service";
import { addClubSchemaValidation, isAddFirstClubFormEmpty, isAddFirstClubFormInvalid } from "@/services/form-validation.service";
import {
  isSameOrBefore,
} from "@/services/dates.service";
import { useRouter } from "vue-router";
import { useStore } from "@/store/store";
import { ToastErrors } from "@/constants/generic-errors.enum";
import { showToast } from "@/services/error-management.service";
import { ActionTypes } from "@/store/action-types";
import { returnNavTooltipChoices } from "@/services/routing.service";

export default defineComponent({
  name: "AddClub",

  setup() {
    const router = useRouter();
    const store = useStore();
    const formSchema = addClubSchemaValidation;
    const addClubForm = ref(emptyAddClubForm);
    const addClubFormErrors = ref(emptyAddClubFormError);
    const services = ref(emptyServices);
    const temporaryPic = ref(null);
    const insertPhotoTitle = ref("");
    const isModalChange = ref(false);
    const showPreview = ref(false);
    const modalToShow = ref(null);
    const dropdownChoices = ref(returnNavTooltipChoices());
    const { t, locale } = useI18n();

    const myClub = computed(function() {
      return store.getters.getMyClub;
    });

    onMounted(() => {
      //TODO: REPERIRE INFORMAZIONI SU NOME E INDIRIZZO DEL CLUB
      insertPhotoTitle.value = t("insert_club_picture");
      (addClubForm.value.name = myClub.value.name),
        (addClubForm.value.address = myClub.value.location.address);
    });

    function hideModal() {
      temporaryPic.value = null;
      modalToShow.value = null;
    }    

    function temporaryUploader(event) {
      temporaryPic.value = event;
    }

    function myUploader(event) {
      addClubForm.value.profilePicture = event;
    }

    function confirmUploadPhoto() {
      myUploader(temporaryPic.value);
      hideModal();
    }

    function openModal(type: string) {
      modalToShow.value = type;
    }

    async function addClub() {
      try {
        const payload = convertFormIntoPayload(
          addClubForm.value,
          services.value
        );
        const club = await store.dispatch(ActionTypes.CHANGE_CLUB_INFO, {
          club_id: myClub.value.club_id,
          request: payload,
        });
        const clubs = await store.dispatch(ActionTypes.MY_CLUBS, null);
        router.push("add-playgrounds");
      } catch (error) {
        return;
      }
    }

    function sendForm() {
      if (addClubForm.value.profilePicture) {
        addClub();
      } else {
        openModal("PHOTO_MISSING_MODAL");
      }
    }

    function updateFormValue(value: string, field: string) {
      addClubForm.value[field] = value;
    }

    function updateFormError(value: string, field: string) {
      addClubFormErrors.value[field] = value;
    }

    function validateForm(field: string) {
      formSchema
        .validateAt(field, addClubForm.value)
        .then(() => {
          updateFormError("", field);
        })
        .catch((err) => {
          updateFormError(mapInputError(err.message, t), field);
        });
    }

    function updateTimeErrors(from: string, to: string) {
      updateFormError(from, "openTime");
      updateFormError(to, "closeTime");
    }

    function updateFormTimeValue(value: string, field: string) {
      updateFormValue(value, field);
      if (field === "openTime") {
        if (isSameOrBefore(addClubForm.value.closeTime, value)) {
          updateTimeErrors(
            "L'orario di apertura deve essere precedente a quello di chiusura",
            ""
          );
        } else {
          updateTimeErrors("", "");
        }
      } else if (field === "closeTime") {
        if (isSameOrBefore(value, addClubForm.value.openTime)) {
          updateTimeErrors(
            "",
            "L'orario di chiusura deve essere successivo a quello di apertura"
          );
        } else {
          updateTimeErrors("", "");
        }
      }
    }

    function openInsertPhotoModal() {
      isModalChange.value = !!addClubForm.value.profilePicture;
      temporaryUploader(addClubForm.value.profilePicture);
      openModal("INSERT_PHOTO_MODAL");
    }

    function returnSrc(file: File) {
      return URL.createObjectURL(file);
    }

    function closePreview() {
      showPreview.value = false;
    }

    function openPreview() {
      showPreview.value = true;
    }

    function isFormEmpty() {
      return (
        !addClubForm.value.openTime ||
        !addClubForm.value.closeTime ||
        !addClubForm.value.phoneNumber ||
        !addClubForm.value.email
      );
    }

    function formContainsError() {
      return (
        !!addClubFormErrors.value.openTime ||
        !!addClubFormErrors.value.closeTime ||
        !!addClubFormErrors.value.phoneNumber ||
        !!addClubFormErrors.value.email
      );
    }

    function handleWrongFileUploaded(file) {
      showToast(ToastErrors.WRONG_FILE_FORMAT);
    }

    return {
      t,
      locale,
      uppercase,
      addClubForm,
      addClubFormErrors,
      services,
      isModalChange,
      showPreview,
      modalToShow,
      insertPhotoTitle,
      temporaryPic,
      sendForm,
      updateFormValue,
      validateForm,
      updateFormTimeValue,
      hideModal,
      openInsertPhotoModal,
      myUploader,
      temporaryUploader,
      confirmUploadPhoto,
      isFormEmpty,
      formContainsError,
      addClub,
      openPreview,
      closePreview,
      returnSrc,
      handleWrongFileUploaded,
      dropdownChoices,
      myClub,
      isAddFirstClubFormEmpty,
      isAddFirstClubFormInvalid
    };
  },
});
