
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  watch,
} from "vue";
import {
  mapCreateTournamentRequest,
  mapFormError,
  mapTournamentToModifyFormWithPreviousTourn,
  uppercase,
} from "@/services/mapping.service";
import { LevelOptions, TournamentOptions } from "@/constants/tournaments.enums";
import { useI18n } from "vue-i18n";
import {
  emptyCreateTournamentForm,
  emptyCreateTournamentFormErrors,
  emptyDateTournamentForm,
  emptyDateTournamentFormErrors,
} from "@/constants/initial-values.constants";
import {
  isTournamentDatesEmpty,
  tournamentsSchemaValidation,
  validateDates,
  isFirstStepEmpty,
  isFirstStepValid,
  isSecondStepEmpty,
  isSecondStepValid,
  isSlotEmpty,
  isValidRegistrationEnd,
} from "@/services/form-validation.service";
import {
  isDateBefore,
  isDateAfter,
  isSameOrBefore,
  convertTimestampToDDMMYYYY,
  convertHHMMtoMinutesOfDay,
} from "@/services/dates.service";
import { showToast } from "@/services/error-management.service";
import { ToastErrors } from "@/constants/generic-errors.enum";
import { useStore } from "@/store/store";
import { useRouter } from "vue-router";
import { ActionTypes } from "@/store/action-types";
import { PadelClub } from "@/models/club.models";
import { Tournament, TournamentFormDate } from "@/models/tournaments.models";

export default defineComponent({
  props: [
    "openingTime",
    "closingTime",
    "playgroundOptions",
    "playgroundMap",
    "forceReset",
    "forceInitialize",
    "tournamentInitializer",
  ],
  emits: ["creation-completed"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const { t, locale } = useI18n();
    const componentKey = ref(0);
    const schema = tournamentsSchemaValidation;
    const modalToShow = ref(null);
    const isModalChange = ref(null);
    const temporaryPic = ref(null);
    const showPreview = ref(false);
    const firstConflict = ref(null);
    const showTextareas = ref(true);
    const tooltipActive = ref(false);
    const minDate = ref(new Date(new Date().setHours(0, 0, 0, 0)));
    const addTournamentForm = ref(
      JSON.parse(JSON.stringify(emptyCreateTournamentForm))
    );
    const addTournamentFormErrors = ref(
      JSON.parse(JSON.stringify(emptyCreateTournamentFormErrors))
    );
    const selectedView = ref("FIRST_PAGE");

    /* COMPUTED */
    const myClub = computed(function (): PadelClub {
      return store.getters.getMyClub;
    });

    function handleClickCalendar(event) {
      const specifiedElement = document.getElementById("info-icon");
      const isClickInside = specifiedElement.contains(event.target as Node);
      if (!isClickInside) {
        tooltipActive.value = false;
      }
    }

    //AL MOUNTED PREPOPOLA FORM CON VALORI DELL'UTENTE
    onMounted(() => {
      document.addEventListener("click", handleClickCalendar);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickCalendar);
    });

    function resetTextareas() {
      showTextareas.value = false;
      setTimeout(() => {
        showTextareas.value = true;
      }, 1);
    }

    function resetForm() {
      addTournamentForm.value = JSON.parse(
        JSON.stringify(emptyCreateTournamentForm)
      );
      addTournamentFormErrors.value = JSON.parse(
        JSON.stringify(emptyCreateTournamentFormErrors)
      );
      selectedView.value = "FIRST_PAGE";
      resetTextareas();
    }

    function initializeForm() {
      addTournamentForm.value = mapTournamentToModifyFormWithPreviousTourn(
        props.tournamentInitializer
      );
      console.log(props.tournamentInitializer);
      resetTextareas();
    }

    watch(
      () => props.forceReset,
      (first, second) => {
        resetForm();
      }
    );

    watch(
      () => props.forceInitialize,
      (first, second) => {
        initializeForm();
      }
    );

    function hideModal() {
      modalToShow.value = null;
    }

    function openModal(modal: string) {
      modalToShow.value = modal;
    }

    function goToFirstPage() {
      selectedView.value = "FIRST_PAGE";
    }

    function goToSecondPage() {
      selectedView.value = "SECOND_PAGE";
      hideModal();
    }

    function updateFormError(error, field) {
      addTournamentFormErrors.value[field] = error;
    }

    function handleCrossValidation(field) {
      if (field === "max_couples") {
        updateFormError("", "min_couples");
      } else if (
        field === "min_couples" &&
        addTournamentForm.value.max_couples != 0
      ) {
        updateFormError("", "max_couples");
      } else if (field === "min_level") {
        updateFormError("", "max_level");
      } else if (field === "max_level") {
        updateFormError("", "min_level");
      }
    }

    function canValidateForm(values, field) {
      return (
        (field !== "min_couples" && field !== "min_level") ||
        (field === "min_couples" && values.max_couples) ||
        (field === "min_level" && values.max_level)
      );
    }

    function validateForm(values, field) {
      if (canValidateForm(values, field)) {
        schema
          .validateAt(field, values)
          .then(() => {
            if (
              field === "registration_end_at" &&
              !isValidRegistrationEnd(values)
            ) {
              updateFormError(
                t("registration_must_be_before_start"),
                "registration_end_at"
              );
            } else if (field === "max_couples" && values.max_couples === "0") {
              updateFormError(
                mapFormError("max_couples must be greater than 0", t),
                field
              );
            } else if (field === "min_couples") {
              validateForm(values, "max_couples");
            } else {
              handleCrossValidation(field);
              updateFormError("", field);
            }
          })
          .catch((err) => {
            updateFormError(mapFormError(err.message, t), field);
          });
      }
    }

    function validateCalendar() {
      if (validateDates(addTournamentForm.value)) {
        validateForm(addTournamentForm.value, "start_at");
        validateForm(addTournamentForm.value, "end_at");
        validateForm(addTournamentForm.value, "registration_end_at");
      }
    }

    function updateSlotFormError(value: string, field: string, index: number) {
      addTournamentFormErrors.value.dates[index][field] = value;
    }

    function handleSlotDateValidation(index) {
      if (
        isDateBefore(
          addTournamentForm.value.dates[index].date,
          addTournamentForm.value.start_at
        )
      ) {
        updateSlotFormError("tournament_date_before", "date", index);
      } else if (
        isDateAfter(
          addTournamentForm.value.dates[index].date,
          addTournamentForm.value.end_at
        )
      ) {
        updateSlotFormError("tournament_date_after", "date", index);
      } else {
        updateSlotFormError("", "date", index);
      }
    }

    function validateAllSlotsDates() {
      addTournamentForm.value.dates.forEach((date, index) => {
        handleSlotDateValidation(index);
      });
    }

    function handleValidation(field) {
      if (
        field === "start_at" ||
        field === "end_at" ||
        field === "registration_end_at"
      ) {
        validateCalendar();
        if (field != "registration_end_at") {
          validateAllSlotsDates();
        }
      } else {
        validateForm(addTournamentForm.value, field);
      }
    }

    function updateFormValue(value: string, field: string, validate = false) {
      addTournamentForm.value[field] = value;
      if (validate) {
        handleValidation(field);
      }
    }

    function updateTimeErrors(startError, endError, index) {
      updateSlotFormError(startError, "start_at", index);
      updateSlotFormError(endError, "end_at", index);
    }

    function handleSlotHourValidation(field, index, value) {
      if (field === "start_at") {
        if (
          isSameOrBefore(addTournamentForm.value.dates[index].end_at, value)
        ) {
          updateTimeErrors("start_at_after_end", "", index);
        } else {
          updateTimeErrors("", "", index);
        }
      } else if (field === "end_at") {
        if (
          isSameOrBefore(value, addTournamentForm.value.dates[index].start_at)
        ) {
          updateTimeErrors("", "end_at_before_start", index);
        } else {
          updateTimeErrors("", "", index);
        }
      }
    }

    function handleSlotValidation(field, index, value) {
      if (field === "date") {
        handleSlotDateValidation(index);
      } else {
        handleSlotHourValidation(field, index, value);
      }
    }

    function validateAllSlots(index, form: TournamentFormDate) {
      let testPassed = true;
      addTournamentForm.value.dates.forEach(
        (value: TournamentFormDate, _index) => {
          if (
            index !== _index &&
            !isSlotEmpty(value) &&
            value.playground.id === form.playground.id &&
            value.date.getTime() === form.date.getTime()
          ) {
            if (
              convertHHMMtoMinutesOfDay(form.start_at) <
                convertHHMMtoMinutesOfDay(value.end_at) &&
              convertHHMMtoMinutesOfDay(form.end_at) >
                convertHHMMtoMinutesOfDay(value.start_at)
            ) {
              testPassed = false;
            }
          }
        }
      );
      return testPassed;
    }

    function updateSlotFormValue(
      value: string,
      field: string,
      index: number,
      validate = false
    ) {
      addTournamentForm.value.dates[index][field] = value;
      componentKey.value++;
      if (!isSlotEmpty(addTournamentForm.value.dates[index])) {
        const testPassed = validateAllSlots(
          index,
          addTournamentForm.value.dates[index]
        );
        if (!testPassed) {
          updateSlotFormError("error_overlap", "date", index);
          return;
        } else {
          updateSlotFormError("", "date", index);
        }
      }
      if (validate) {
        handleSlotValidation(field, index, value);
      }
    }

    function deleteSlot(index: number) {
      addTournamentForm.value.dates.splice(index, 1);
      addTournamentFormErrors.value.dates.splice(index, 1);
      componentKey.value++;
    }

    function addSlot() {
      addTournamentForm.value.dates.push({
        ...emptyDateTournamentForm,
      });
      addTournamentFormErrors.value.dates.push({
        ...emptyDateTournamentFormErrors,
      });
      componentKey.value++;
    }

    function temporaryUploader(event) {
      temporaryPic.value = event;
    }

    function openAddTournamentPicModal() {
      openModal("INSERT_PHOTO_MODAL");
      isModalChange.value = !!addTournamentForm.value.picture;
      temporaryUploader(addTournamentForm.value.picture);
    }

    function openPreview() {
      showPreview.value = true;
    }

    function closePreview() {
      showPreview.value = false;
    }

    function handleWrongFileUploaded(file) {
      showToast(ToastErrors.WRONG_FILE_FORMAT);
    }

    function myUploader(event) {
      addTournamentForm.value.picture = event;
    }

    function confirmUploadPhoto() {
      myUploader(temporaryPic.value);
      hideModal();
    }

    function proceedWithStep2Form() {
      if (addTournamentForm.value.picture) {
        goToSecondPage();
      } else {
        openModal("PHOTO_MISSING_MODAL");
      }
    }

    async function confirmForm() {
      const payload = mapCreateTournamentRequest(addTournamentForm.value);
      try {
        const res = await store.dispatch(ActionTypes.CREATE_TOURNAMENT, {
          club_id: myClub.value.club_id,
          tournament: payload,
        });
        openModal("TOURNAMENT_CREATION_OK");
      } catch (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.conflicts &&
          error.response.data.conflicts.length > 0
        ) {
          firstConflict.value = error.response.data.conflicts[0];
          openModal("CONFLICT_DETECTED");
        } else {
          showToast(ToastErrors.GENERIC);
        }
        return;
      }
    }

    function goToTournaments() {
      hideModal();
      emit("creation-completed", true);
    }

    function goToCalendar() {
      router.push("scheduler");
    }

    function toggleTooltip() {
      console.log("toggle tooltip! ");
      tooltipActive.value = !tooltipActive.value;
    }

    return {
      t,
      locale,
      uppercase,
      TournamentOptions,
      LevelOptions,
      selectedView,
      addTournamentForm,
      addTournamentFormErrors,
      componentKey,
      modalToShow,
      isModalChange,
      temporaryPic,
      showPreview,
      minDate,
      showTextareas,
      firstConflict,

      updateFormValue,
      goToFirstPage,
      goToSecondPage,
      deleteSlot,
      addSlot,
      updateSlotFormValue,
      confirmForm,
      validateForm,
      isTournamentDatesEmpty,
      openAddTournamentPicModal,
      openModal,
      hideModal,
      openPreview,
      closePreview,
      handleWrongFileUploaded,
      confirmUploadPhoto,
      temporaryUploader,
      proceedWithStep2Form,
      isFirstStepEmpty,
      isFirstStepValid,
      isSecondStepEmpty,
      isSecondStepValid,
      goToTournaments,
      goToCalendar,
      convertTimestampToDDMMYYYY,
      toggleTooltip,
      tooltipActive,
    };
  },
});
