
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { 
    computed, 
    defineComponent, 
    onMounted, 
    ref,
    watch,
} from "vue";
import { BookingType } from "@/constants/booking.enum";

export default defineComponent({
    name: "RepeatBooking",
    components: {
    },
    props: ['newBookingForm', 'newBookingErrors', 'formDisabled', 'withEndTime'],
    setup(props, { emit }) {
        const store = useStore();
        const { t, locale }     = useI18n();
        const repeat            = ref(props.newBookingForm.recurring);
        const useNumberOfWeeks  = ref(false);
        const numberOfWeeks     = ref(null);
        const WeeksOptions      = ref([]);
        const defaultEndDate    = ref(null);
        if (props.newBookingForm.bundle_end_at) {
            defaultEndDate.value = props.newBookingForm.bundle_end_at;
        } else {
            if (props.newBookingForm.type === BookingType.BACKOFFICE_EVENT) {
                defaultEndDate.value = props.newBookingForm.end_date;
            } else {
                defaultEndDate.value = props.newBookingForm.date;
            }
        }

        // --
        for (let i = 3; i <= 15; i++) {
            WeeksOptions.value.push({
                label: t('num_weeks', { num: i }), value: `${(i - 1)}`,
            });
        }

        /* COMPUTED */
        const weekday = computed(function() {
            const day = moment(props.newBookingForm.date).format("dddd");
            return (' '+day).replace(/ [\w]/g, a => a.toLocaleUpperCase()).trim();
        });
        const from = computed(function() {
            return props.newBookingForm.start_at;
        });
        const to = computed(function() {
            if (props.newBookingForm.type === BookingType.BACKOFFICE_EVENT) return props.newBookingForm.hour_end;
            if (props.withEndTime) return props.newBookingForm.end_at;
            return moment(`${moment(props.newBookingForm.date).format("YYYY-MM-DD")} ${props.newBookingForm.start_at}`)
                .add(props.newBookingForm.game_duration, 'minutes')
                .format("HH:mm")
        });
        
        // FUNCTIONS
        function setFormErrors(key: string, value: string) {
            props.newBookingErrors[key] = value;
        }

        function setFormValue(key: string, value) {
            if (key === 'number_of_weeks') {
                if (value) {
                    numberOfWeeks.value = value;
                    
                    // CALCULATE
                    let newEndDate = null;
                    if (props.newBookingForm.type === BookingType.BACKOFFICE_EVENT) {
                        newEndDate = moment(props.newBookingForm.end_date)
                            .add(parseInt(value), 'weeks');
                    } else {
                        newEndDate = moment(defaultEndDate.value)
                            .add(parseInt(value), 'weeks');
                    }
                    setFormValue('bundle_end_at', newEndDate.toDate())
                    setFormErrors('number_of_weeks', "");
                }
            } else {
                if (value) {
                    props.newBookingForm[key] = value;
                    setFormErrors(key, "");
                }
            }

            // NOTIFY PARENT
            props.newBookingForm.recurring = repeat.value;
        }

        function switchView(value) {
            useNumberOfWeeks.value = value;
            numberOfWeeks.value = null;
            props.newBookingForm.bundle_end_at = undefined;
            setFormErrors('number_of_weeks', "");
            setFormErrors('bundle_end_at', "");

            // DEFAULT ERRORS
            if (value) {
                setFormErrors('number_of_weeks', "Inserire il numero di settimane in cui si vuole ripetere la prenotazione.");
            } else {
                setFormErrors('bundle_end_at', "Inserire la data di fine ricorrenza.");
            }

            // NOTIFY PARENT
            props.newBookingForm.recurring = repeat.value;
        }

        // SYSTEM
        onMounted(() => {
        });

        /** WATCH */
        watch(repeat, function() {
            if (!repeat.value) {
                setFormValue('bundle_end_at', undefined);
                setFormErrors('number_of_weeks', "");
                setFormErrors('bundle_end_at', "");
                props.newBookingForm.recurring = repeat.value;
                return;
            }

            // SET KEY
            props.newBookingForm.recurring = repeat.value;
            setFormErrors('bundle_end_at', "Inserire la data di fine ricorrenza.");
        });

        watch(() => props.newBookingForm, (newValue) => {
            if (!repeat.value) return;
            
            if (props.newBookingForm.type === BookingType.BACKOFFICE_EVENT) {
                defaultEndDate.value = newValue.end_date;
            } else {
                defaultEndDate.value = newValue.date;
            }
            if (useNumberOfWeeks.value) {
                if (!props.newBookingForm.bundle_end_at) {
                    setFormValue('number_of_weeks', numberOfWeeks.value);
                }
            } else {
                if (!props.newBookingForm.bundle_end_at) {
                    setFormValue('bundle_end_at', null);
                    setFormErrors('bundle_end_at', "Inserire la data di fine ricorrenza.");
                }
            }
        }, { deep: true });

        watch(() => props.withEndTime, (newValue) => {
        }, { deep: true });

        return {
            t,
            locale,

            // VALUES
            repeat,
            useNumberOfWeeks,
            numberOfWeeks,
            WeeksOptions,
            weekday,
            from,
            to,

            // METHODS
            setFormValue,
            switchView,
        };
    },
});
