<!-- OVERLAY PREVIEW IMAGE -->
<div v-if="showPreview" class="overlay-image">
    <div class="inner-pic-div">
        <img :src="temporaryPic.src" class="image" />
        <span class="material-icons icon-close" @click="closePreview()">
            clear
        </span>
    </div>
</div>

<div class="flexbox-column height-100">
    <div class="main-step-container flexbox-column" :class="selectedView === 'FIRST_PAGE' ? '' : 'display-none'">
        <div>
            <div class="step-title flexbox-column">
                <h3 class="color-gold barlow margin-bottom-0">{{t('step_1')}}</h3>
                <div class="barlow color-white margin-top-5">{{t('tournaments_first_step')}}</div>
            </div>
            <div class="step-form-container">
                <div class="form-row margin-top-20">
                    <div class="position-relative">
                        <PadelInput :label="t('tournament_name')" @input="updateFormValue($event, 'name')"
                            :value="addTournamentForm.name" :icon="'info'" isIconOutlined="true" buttonClickable="true"
                            @buttonClicked="toggleTooltip()" :isButtonActive="tooltipActive" :customButtonId="'info-icon'" />
                        <PadelTooltip v-if="tooltipActive" class="tooltip" :label="t('tournament_name_info')" :arrowPosition="'TOP'"
                        alignRight="true" />
                    </div>

                    <PadelInput :label="t('tournament_price')" :inputType="'number'" isPrice="true"
                        :icon="'euro_symbol'" @input="updateFormValue($event, 'price')"
                        :value="addTournamentForm.price" />
                    <PadelDropdown class="dropdown adjust-dropdown-alignment" :label="t('tournament_type')"
                        :options="TournamentOptions" @input="updateFormValue($event.value, 'type')"
                        :default="addTournamentForm.type" />
                </div>
                <div class="form-row margin-top-20">
                    <PadelInput :label="t('min_couples')" @input="updateFormValue($event, 'min_couples')"
                        @validate="validateForm(addTournamentForm, 'min_couples', true)" :inputType="'number'"
                        isInteger="true" :value="addTournamentForm.min_couples"
                        :error="addTournamentFormErrors.min_couples ? t(addTournamentFormErrors.min_couples) : ''" />
                    <PadelInput :label="t('max_couples')" @input="updateFormValue($event, 'max_couples')"
                        @validate="validateForm(addTournamentForm, 'max_couples', true)" :inputType="'number'"
                        isInteger="true" :value="addTournamentForm.max_couples"
                        :error="addTournamentFormErrors.max_couples ? t(addTournamentFormErrors.max_couples) : ''" />
                    <div class="empty-div"></div>
                </div>
                <div class="form-row margin-top-20 margin-bottom-40">
                    <PadelDropdown class="dropdown" :label="t('min_level')" :options="LevelOptions"
                        @input="updateFormValue($event.value, 'min_level', true)"
                        :error="addTournamentFormErrors.min_level ? t(addTournamentFormErrors.min_level) : ''"
                        :default="addTournamentForm.min_level" />
                    <PadelDropdown class="dropdown" :label="t('max_level')" :options="LevelOptions"
                        @input="updateFormValue($event.value, 'max_level', true)"
                        :error="addTournamentFormErrors.max_level ? t(addTournamentFormErrors.max_level) : ''"
                        :default="addTournamentForm.max_level" />
                    <div class="empty-div"></div>
                </div>
                <div v-if="showTextareas" class="form-row margin-top-20">
                    <PadelTextarea class="textarea" :label="t('tournament_description')"
                        @input="updateFormValue($event, 'description')" :value="addTournamentForm.description" />
                    <PadelTextarea class="textarea" :label="t('tournament_mode')"
                        @input="updateFormValue($event, 'mode_description')"
                        :value="addTournamentForm.mode_description" />
                    <PadelTextarea class="textarea" :label="t('tournament_prizes')"
                        @input="updateFormValue($event, 'prizes')" :value="addTournamentForm.prizes" />
                </div>

            </div>
        </div>
        <div class="button-container flexbox-row padding-bottom-40">
            <PadelButton v-if="!addTournamentForm.picture" class="margin-right-20"
                :label="uppercase(t('add_tournament_pic'))" :icon="'add_a_photo'" :type="'secondary'"
                @click="openAddTournamentPicModal()" />
            <PadelButton v-else type="secondary-white" :label="uppercase(t('tournament_pic_added'))"
                class="margin-right-20" :icon="'check'" @click="openAddTournamentPicModal()" />
            <PadelButton :label="uppercase(t('forward'))" @click="proceedWithStep2Form()"
                :disabled="isFirstStepEmpty(addTournamentForm) || !isFirstStepValid(addTournamentFormErrors)" />
        </div>
    </div>

    <!-- MODALE INSERISCI FOTO -->
    <div v-if="modalToShow === 'INSERT_PHOTO_MODAL'">
        <DumbModal :title="t('add_tournament_pic')" @hide="hideModal()">
            <div class="modal-content flexbox-column justify-space-between">
                <div class="top-part">
                    <p v-if="isModalChange" class="color-white montserrat insert_pic_text change_text">
                        {{t('change_tournament_picture')}}</p>
                    <p v-else class="color-white montserrat insert_pic_text" v-html="t('load_tournament_pic_text')">
                    </p>
                    <div class="temporary-container" v-if="temporaryPic">
                        <span v-if="!isModalChange" class="material-icons color-white margin-right-10">check</span>
                        <p class="color-white montserrat insert_pic_text preview-text cursor-pointer"
                            @click="openPreview()">
                            {{temporaryPic.name}}</p>
                    </div>

                    <div v-else-if="!isModalChange" class="margin-top-10">
                        <PadelUpload @change="temporaryUploader($event)" :icon="'add_a_photo'"
                            :label="uppercase(t('add_picture'))" :uploadedLabel="uppercase(t('picture_added'))"
                            @wrong-file-uploaded="handleWrongFileUploaded($event)"></PadelUpload>
                    </div>

                </div>
                <div class="buttons-container flexbox-row justify-space-between">
                    <p class="abort" @click="hideModal()">{{isModalChange? uppercase(t('back')) :uppercase(t('close'))}}
                    </p>
                    <div class="flexbox-row inner-container">
                        <div v-if="isModalChange" class="margin-right-30">
                            <PadelUpload @change="temporaryUploader($event)"
                                :label="isModalChange?uppercase(t('change_photo')) : uppercase(t('add_picture'))"
                                :uploadedLabel="uppercase(t('picture_added'))" :border="true"
                                @wrong-file-uploaded="handleWrongFileUploaded($event)"></PadelUpload>
                        </div>
                        <PadelButton :label="uppercase(t('load'))" :disabled="!temporaryPic"
                            @click="confirmUploadPhoto()" />
                    </div>
                </div>
            </div>
        </DumbModal>
    </div>

    <!-- MODALE FOTO MANCANTE -->
    <div v-else-if="modalToShow === 'PHOTO_MISSING_MODAL'">
        <DumbModal :title="t('add_tournament_pic')" @hide="hideModal()">
            <div class="modal-content flexbox-column justify-space-between">
                <div class="top-part">
                    <i class="material-icons icon-alert">warning_amber</i>
                    <div class="color-white alert-text montserrat">{{t('sure_wanna_confirm_without_tournament_pic')}}
                    </div>
                </div>
                <div class="buttons-container flexbox-row justify-space-between">
                    <p class="abort" @click="hideModal()">{{uppercase(t('close'))}}</p>
                    <div class="flexbox-row">
                        <div class="margin-right-30">
                            <PadelUpload @change="myUploader($event)" :label="uppercase(t('add_picture'))"
                                :uploadedLabel="uppercase(t('picture_added'))"
                                @wrong-file-uploaded="handleWrongFileUploaded($event)">
                            </PadelUpload>
                        </div>
                        <PadelButton :label="uppercase(t('forward'))" @click="goToSecondPage()" />
                    </div>
                </div>
            </div>
        </DumbModal>
    </div>


    <!-- MODALE CONFERMA -->
    <div v-else-if="modalToShow === 'SURE_WANNA_CONFIRM'">
        <DumbModal :title="t('tournament_creation')" @hide="hideModal()">
            <div class="modal-content flexbox-column justify-space-between">
                <div class="top-part">
                    <img class="feedback-icon" src="@/theme/imgs/icon_feedback.svg" alt="">
                    <div class="margin-top-20">
                        <div class="color-white modal-text montserrat">{{t('tournament_creation_text_1')}}</div>
                        <div class="color-white modal-text montserrat" v-html="t('tournament_creation_text_2')"></div>
                        <div class="color-white modal-text montserrat">{{t('wanna_confirm_text')}}</div>
                    </div>

                </div>
                <div class="buttons-container flexbox-row justify-space-between">
                    <p class="abort" @click="hideModal()">{{uppercase(t('close'))}}</p>
                    <PadelButton :label="uppercase(t('confirm'))" @click="confirmForm()" />
                </div>
            </div>
        </DumbModal>
    </div>

    <!-- MODALE CREAZIONE OK -->
    <div v-else-if="modalToShow === 'TOURNAMENT_CREATION_OK'">
        <DumbModal :title="t('tournament_creation')" @hide="goToTournaments()">
            <div class="modal-content flexbox-column justify-space-between">
                <div class="top-part">
                    <span class="material-icons confirm-icon">check_circle_outline</span>
                    <div class="margin-top-20">
                        <div class="color-white modal-text montserrat">{{t('tournament_creation_ok')}}</div>
                        <div class="color-white modal-text montserrat">{{t('new_tournament_created')}}</div>
                    </div>

                </div>
                <div class="buttons-container flexbox-row justify-space-between">
                    <p class="abort" @click="goToTournaments()">{{uppercase(t('close'))}}</p>
                    <PadelButton :label="uppercase(t('go_to_the_calendar'))" :type="'secondary'"
                        @click="goToCalendar()" />
                </div>
            </div>
        </DumbModal>
    </div>

    <!-- MODALE CONFLITTO TROVATO -->
    <div v-else-if="modalToShow === 'CONFLICT_DETECTED'">
        <DumbModal :title="t('tournament_creation')" @hide="hideModal()">
            <div class="modal-content flexbox-column justify-space-between">
                <div class="top-part">
                    <i class="material-icons icon-alert">warning_amber</i>
                    <div class="color-white alert-text montserrat">{{t('attention')}}</div>
                    <div class="color-white modal-text montserrat" v-html="t('tournament_conflict_text', {date: convertTimestampToDDMMYYYY(firstConflict.start_at), 
                        playground_name: playgroundMap[firstConflict.playground_id]})">
                    </div>
                </div>
                <div class="buttons-container flexbox-column align-center">
                    <p class="abort" @click="hideModal()">{{uppercase(t('go_back'))}}</p>
                </div>
            </div>
        </DumbModal>
    </div>

    <div class="second-step flexbox-column justify-space-between padding-bottom-40"
        :class="selectedView === 'SECOND_PAGE' ? '' : 'display-none'">
        <div class="width-100">
            <div class="step-title flexbox-column">
                <h3 class="color-gold barlow margin-bottom-0">{{t('step_2')}}</h3>
                <div class="barlow color-white margin-top-5">{{t('tournaments_second_step')}}</div>
            </div>
            <div class="calendar-row margin-bottom-20">
                <PadelCalendar :label="t('start_date')" @input="updateFormValue($event, 'start_at', true)"
                    :minDate="minDate" :value="addTournamentForm.start_at"
                    :error="addTournamentFormErrors.start_at ? t(addTournamentFormErrors.start_at) : ''" />
                <PadelCalendar :label="t('end_date')" :value="addTournamentForm.end_at"
                    @input="updateFormValue($event, 'end_at', true)" :minDate="minDate"
                    :error="addTournamentFormErrors.end_at ? t(addTournamentFormErrors.end_at) : ''" />
                <PadelCalendar :label="t('registration_deadline')" :minDate="minDate"
                    :value="addTournamentForm.registration_end_at"
                    @input="updateFormValue($event, 'registration_end_at', true)"
                    :error="addTournamentFormErrors.registration_end_at ? t(addTournamentFormErrors.registration_end_at) : ''" />
            </div>
            <div class="gold-separator"></div>
            <div class="slots-container margin-top-20 flexbox-column align-center">
                <div class="top-slots flexbox-column align-center width-100">
                    <div class="slots-title barlow color-white">{{t('tournaments_slots_title')}}</div>
                    <template v-for="(slotForm, index) in addTournamentForm.dates">
                        <div class="slot-row flexbox-row">
                            <div class="row-item">
                                <PadelCalendar :label="t('select_day')" :minDate="minDate" :value="slotForm.date"
                                    @input="updateSlotFormValue($event, 'date', index, true)"
                                    :error="addTournamentFormErrors.dates[index].date ? t(addTournamentFormErrors.dates[index].date) : ''" />
                            </div>
                            <div class="row-item flexbox-row justify-center timepickers">
                                <div class="timepicker-container margin-right-20">
                                    <PadelTimepicker :label="t('from')" :from="openingTime" :to="closingTime"
                                        :value="slotForm.start_at"
                                        @input="updateSlotFormValue($event, 'start_at', index, true)"
                                        :error="addTournamentFormErrors.dates[index].start_at ? t(addTournamentFormErrors.dates[index].start_at) : ''" />
                                </div>
                                <div class="timepicker-container">
                                    <PadelTimepicker :label="t('to')" :from="openingTime" :to="closingTime"
                                        :value="slotForm.end_at"
                                        @input="updateSlotFormValue($event, 'end_at', index, true)"
                                        :error="addTournamentFormErrors.dates[index].end_at ? t(addTournamentFormErrors.dates[index].end_at) : ''" />
                                </div>
                            </div>
                            <div class="row-item flexbox-row align-center playground-container">
                                <PadelDropdown class="dropdown" :options="playgroundOptions"
                                    :label="t('select_playground')"
                                    @input="updateSlotFormValue($event.value, 'playground', index)"
                                    :default="slotForm.playground" />
                                <span class="icon-delete material-icons color-blue cursor-pointer margin-left-10"
                                    @click="deleteSlot(index)">delete</span>
                            </div>
                        </div>
                    </template>
                    <div class="flexbox-row align-center" @click="addSlot()"
                        :class="{'disabled': isTournamentDatesEmpty(addTournamentForm)}">
                        <i class="pi pi-plus color-blue margin-right-10"
                            :class="{'disabled': isTournamentDatesEmpty(addTournamentForm)}"></i>
                        <p class="abort" :class="{'disabled': isTournamentDatesEmpty(addTournamentForm)}">
                            {{uppercase(t('add_day'))}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="button-row flexbox-row justify-space-between width-100">
            <div class="flexbox-row align-center">
                <p class="abort" @click="goToFirstPage()">{{uppercase(t('back'))}}</p>
            </div>
            <PadelButton :label="uppercase(t('confirm'))" @click="openModal('SURE_WANNA_CONFIRM')"
                :disabled="isSecondStepEmpty(addTournamentForm) || !isSecondStepValid(addTournamentFormErrors)" />
        </div>
    </div>
</div>