import { DropdownOption, RadioOption } from "@/models/forms.models";
import { BookingType, CalendarOpenings, EventType } from "./booking.enum";
import { PlaygroundExposures, PlaygroundSports, OpenBookingSports } from "./playground.enums";
import { ClubRequestTypes } from "./request-types.enum";
import { UserAbilitationTypes } from "./user-abilitation.enum";

export const sportOptions: RadioOption[] = [
    {
        label: 'Padel',
        value: PlaygroundSports.PADEL,
        disabled: false
    },
    {
        label: 'Tennis',
        value: PlaygroundSports.TENNIS,
        disabled: false
    },
    {
        label: 'Padeball',
        value: PlaygroundSports.PADEBALL,
        disabled: false
    },
    {
        label: 'Pickleball',
        value: PlaygroundSports.PICKLEBALL,
        disabled: false
    },
    {
        label: 'Calcio a 5',
        value: PlaygroundSports.SOCCER_5,
        disabled: false
    },
    {
        label: 'Calcio a 7',
        value: PlaygroundSports.SOCCER_7,
        disabled: false
    },
    {
        label: 'Calcio',
        value: PlaygroundSports.SOCCER_11,
        disabled: false
    },
    {
        label: 'Beach Volley',
        value: PlaygroundSports.BEACH_VOLLEY,
        disabled: false
    },
    {
        label: 'Beach Tennis',
        value: PlaygroundSports.BEACH_TENNIS,
        disabled: false
    },
]

export const playgroundsOptions: RadioOption[] = [
    {
        label: 'Indoor',
        value: PlaygroundExposures.INDOOR,
        disabled: false
    },
    {
        label: 'Outdoor',
        value: PlaygroundExposures.OUTDOOR,
        disabled: false
    }
]

export const gameDurationOptions: RadioOption[] = [
    {
        label: '60min',
        value: 60,
        disabled: false
    },
    {
        label: '90min',
        value: 90,
        disabled: false
    },
    {
        label: '120min',
        value: 120,
        disabled: false
    }
]

export const fieldTypesOptions: DropdownOption[] = [
    {
        label: 'Cemento',
        value: 'Cemento'
    },
    {
        label: 'Terra',
        value: 'Terra'
    }
]

export const adminFilterOptions: RadioOption[] = [
    {
        label: 'In sospeso',
        value: ClubRequestTypes.PENDING,
        disabled: false
    },
    {
        label: 'Rifiutata',
        value: ClubRequestTypes.REJECTED,
        disabled: false
    }
]

export const END_OF_MORNING = 12 * 60 * 60 * 1000;
export const END_OF_AFTERNOON = 18 * 60 * 60 * 1000;

export const calendarFilterOptions: RadioOption[] = [
    {
        label: 'Mattina',
        value: CalendarOpenings.MORNING,
        disabled: false,
        options: { start: 0, end: END_OF_MORNING }
    },
    {
        label: 'Pomeriggio',
        value: CalendarOpenings.AFTERNOON,
        disabled: false,
        options: { start: END_OF_MORNING, end: END_OF_AFTERNOON }
    },
    {
        label: 'Sera',
        value: CalendarOpenings.EVENING,
        disabled: false,
        options: { start: END_OF_AFTERNOON, end: 24 * 60 * 60 * 1000 }
    }
]

export const userAbilitationOptions: RadioOption[] = [
    {
        label: 'Bannati',
        disabled: false,
        value: UserAbilitationTypes.BANNED
    },
    {
        label: 'Abilitati',
        disabled: false,
        value: UserAbilitationTypes.ABILITATED
    }
]

export const statsRadioOptions: RadioOption[] = [
    {
        label: 'Ore prenotate',
        disabled: false,
        value: 'bookings'
    },
    {
        label: 'Ore cancellate',
        disabled: false,
        value: 'cancellations'
    },
    {
        label: 'Ricavi in €',
        disabled: false,
        value: 'income'
    }
]

export const bookingRadioOptions: RadioOption[] = [
    {
        label: 'Partita',
        disabled: false,
        value: BookingType.BOOKING
    },
    {
        label: 'Lezione',
        disabled: false,
        value: BookingType.LESSON
    },
    {
        label: 'Eventi',
        disabled: false,
        value: BookingType.BACKOFFICE_EVENT
    },
    {
        label: 'Manutenzione',
        disabled: false,
        value: BookingType.MAINTENANCE
    },
    {
        label: 'Chiusure',
        disabled: false,
        value: BookingType.CLOSING
    },
    {
        label: 'Altro',
        disabled: false,
        value: BookingType.OTHER
    }
]

export const openGamesRadioOptions: RadioOption[] = [
    {
        label: 'Partita aperta',
        disabled: false,
        value: BookingType.OPEN_BOOKING
    },
]

export const eventTypeOptions: object[] = [
    {
        label: 'Evento',
        value: EventType.EVENT
    },
    {
        label: 'Torneo',
        value: EventType.TOURNAMENT
    },
    {
        label: 'Altro',
        value: EventType.OTHER
    },
]

export function getSportByValue(sport) {
    return (sportOptions.find(el => el.value === sport) || {}).label;
}

export const matchTypesOptions: { label: string; value: string; sport: string; participants: number }[] = [
    {
        label: 'Padel (4 partecipanti)',
        value: OpenBookingSports.PADEL,
        sport: PlaygroundSports.PADEL,
        participants: 4,
    },
    {
        label: 'Padel (2 partecipanti)',
        value: OpenBookingSports.PADEL_SINGOLO,
        sport: PlaygroundSports.PADEL,
        participants: 2,
    },
    {
        label: 'Tennis (2 partecipanti)',
        value: OpenBookingSports.TENNIS,
        sport: PlaygroundSports.TENNIS,
        participants: 2,
    },
    {
        label: 'Tennis - Doppio (4 partecipanti)',
        value: OpenBookingSports.TENNIS_DOUBLE,
        sport: PlaygroundSports.TENNIS,
        participants: 4,
    },
];