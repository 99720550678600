<template src="./dumb-modal.template.html"> </template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "DumbModal",
  data() {
    return {
      isVisible: true,
    };
  },
  methods: {
    hideModal() {
      this.isVisible = true;
      this.$emit("hide", true);
    },
  },
  props: {
    title: {
      type: String
    },
    showHidden: {
      type: Boolean,
      default: false,
    },
    modal: {
      type: Boolean,
      default: true,
    },
    customStyle: {
      type: Object,
      default: function() {
        return {width: '680px'};
      },
    },
    maximizable: {
      type: Boolean,
      default: false,
    },
    minimizable: {
      type: Boolean,
      default: false,
    },
    breakpoints: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  emits: ["hide"],
  mounted() {
    if (this.showHidden) {
      this.isVisible = false;
    } else {
      this.isVisible = true;
    }
  },
});
</script>

<style lang="scss" scoped>
@import "./dumb-modal.style.scss";
</style>
