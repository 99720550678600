<DumbModal @hide="hideModal()" :title="t('switch_club')">
    <div class="top-part flexbox-column align-center">
        <img class="img-switch margin-bottom-10" src="@/theme/imgs/switch_struttura.svg" alt="">
        <p class="switch-club-text">{{t('which_club_login')}}</p>
        <div class="box-container">
            <template v-for="club in clubs" :key="club.club_id">
                <div :class="'box ' + isSelected(club)" @click="selectClub(club)">
                    <img v-if="club.cover_pic_ref" class="club-img" :src="club.cover_pic_ref + '?' + new Date().getTime()">
                    <img v-else class="club-img" src="@/theme/imgs/default_centro.png" alt="">
                    <div :class="'info-box flexbox-column ' + isSelected(club)">
                        <span class="club-name-text barlow">{{club.name}}</span>
                        <span class="club-address-text margin-top-10">{{club.location.address}}</span>
                    </div>
                </div>        
            </template>
        </div>        
    </div>
    <div class="buttons-container flexbox-row justify-space-between margin-top-20">
        <p class="abort" @click="hideModal()">{{uppercase(t('abort'))}}</p>
        <PadelButton :label="'PROCEDI'" :type="'primary'" @click="emitClickedClub()" :disabled="!selectedClub"></PadelButton>
    </div>

</DumbModal>