<div class="nav-container flexbox-row justify-space-between padding-top-20 padding-bottom-20">
    <div class="cursor-pointer flexbox-row align-center" @click="returnToClubPage(false)">
        <span class="span-button"><i
                class="material-icons color-blue cursor-pointer margin-right-10">keyboard_arrow_left</i></span>
        <div class="go-back-text">{{uppercase(t('go_back_to_club'))}}</div>
    </div>
    <Nav v-if="selectedClub" :dropdownChoices="adminDropdownChoices" />
</div>
<div class="main-club-detail-div flexbox-column">
    <div class="first-row flexbox-row justify-space-between">
        <div class="flexbox-row title-container">
            <div class="color-white barlow club-title margin-right-20">{{selectedClub.name}}</div>
            <Chip class="chip" :class="returnChipClass(selectedClub.status)"
                :label="uppercase(t(selectedClub.status))" />
        </div>
        <div class="flexbox-row">
            <div class="export-report flexbox-row align-center cursor-pointer" @click="openModal('EXPORT_STATS_MODAL')">
                <div class="go-back-text margin-right-10">{{uppercase(t('export_report'))}}</div>
                <i class="material-icons color-blue">file_download</i>
            </div>
            <div class="vl"></div>
            <div class="modify-club-status flexbox-row cursor-pointer" @click="openModal('MODIFY_CLUB_STATUS')">
                <div v-if="selectedClub.status === 'OPERATIVE'" class="flexbox-row align-center">
                    <div class="go-back-text margin-right-10">{{uppercase(t('disable_club'))}}</div>
                    <i class="material-icons color-blue">block</i>
                </div>
                <div v-else class="flexbox-row align-center">
                    <div class="go-back-text margin-right-10">{{uppercase(t('enable_club'))}}</div>
                    <i class="material-icons color-blue">published_with_changes</i>
                </div>
            </div>
        </div>
    </div>
    <div class="form-container margin-top-40">
        <PadelInput :value="selectedClub.name" :label="t('clubName')" :disabled="formDisabled" />
        <PadelInput :value="selectedClub.location.address" :label="t('clubAddress')" :disabled="formDisabled" />
        <PadelInput :value="selectedClub.club_phone_number" :label="t('clubPhonenumber')" :disabled="formDisabled" />
        <PadelInput :value="manager.name" :label="t('manager_name')" :disabled="formDisabled" />
        <PadelInput :value="manager.family_name" :label="t('manager_surname')" :disabled="formDisabled" />
        <PadelInput :value="manager.email" :label="t('manager_email')" :disabled="formDisabled" />
        <PadelInput :value="manager.phone_number" :label="t('manager_phonenumber')" :disabled="formDisabled" />
    </div>
</div>
<div v-if="modalOpened === 'EXPORT_STATS_MODAL'">
    <DumbModal :title="t('export_club_report')" @hide="hideModal()">
        <div class="top-part flexbox-column align-center">
            <p class="montserrat color-white">{{t('export_club_report_text')}}</p>
            <PadelCalendar @input="changeDate($event)" :viewType="'month'" />
        </div>
        <div class="button-row flexbox-row justify-space-between">
            <p class="color-blue go-back-text montserrat cursor-pointer" @click="hideModal()">{{uppercase(t('abort'))}}</p>
            <PadelButton :label="uppercase(t('apply'))"
            @click="retrieveClubStats()" :disabled="!selectedDate">
            </PadelButton>
        </div>
    </DumbModal>
</div>
<div v-else-if="modalOpened === 'MODIFY_CLUB_STATUS'">
    <DumbModal :title="selectedClub.status === 'OPERATIVE' ? t('disable_club') : t('enable_club')" @hide="hideModal()">
        <div class="top-part flexbox-column align-center">
            <i class="material-icons icon-alert">warning_amber</i>
            <div v-if="selectedClub.status === 'OPERATIVE'" class="margin-top-30">
                <div class="color-white alert-text montserrat">{{t('attention')}}</div>            
                <div class="color-white alert-text montserrat" v-html="t('disable_club_text', {name: selectedClub.name})"></div>
                <div class="color-white alert-text montserrat">{{t('are_you_sure_disable')}}</div> 
            </div>
            <div v-else class="margin-top-30">
                <div class="color-white alert-text montserrat">{{t('attention')}}</div>            
                <div class="color-white alert-text montserrat" v-html="t('enable_club_text', {name: selectedClub.name})"></div>
                <div class="color-white alert-text montserrat">{{t('are_you_sure_enable')}}</div> 
            </div>
        </div>
        <div class="button-row flexbox-row justify-space-between margin-top-40">
            <p class="color-blue go-back-text montserrat cursor-pointer" @click="hideModal()">{{uppercase(t('abort'))}}</p>
            <PadelButton :label="uppercase(t('proceed'))"
            @click="toggleClubStatus(selectedClub.status)" >
            </PadelButton>
        </div>
    </DumbModal>
</div>