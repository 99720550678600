<PadelLateralTab />
<div class="view-main-container">
    
    <div class="inner-view-container">

        <div class="header flexbox-row padding-top-40 padding-bottom-40">
            <div class="cursor-pointer flexbox-row align-center" 
                @click="router.replace({ name: 'user-details', params: { id: user.id },  query: { tab: 'cards' } });">
                <i class="material-icons color-blue cursor-pointer margin-right-10">keyboard_arrow_left</i>
                <div class="go-back-text color-blue">{{uppercase(t('abort'))}}</div>
            </div>
        </div>

        <div class="p-grid padding-bottom-20">
            <div class="p-col-12 p-text-left">
                <h3 class="color-gold barlow margin-bottom-0">{{ card ? t('edit_card') : t('new_card') }}</h3>
                <div class="barlow color-white margin-top-5">{{ card ? t('edit_card_description') : t('new_card_description') }}</div>
            </div>
        </div>

        <div class="form-row">

            <div class="p-field min-height dropdown-container">
                <PadelDropdown 
                    :label="t('card_type') + '*'" 
                    :options="allowedCards"
                    @input="setFormValue('type', $event.value)"
                    :disabled="!!renewId || editMode" 
                    :default="form.type"
                    :error="formErrors.type" />
            </div>
            
            <PadelInput 
                :label="t('card number') + '*'" 
                :value="form.card_code" 
                @input="setFormValue('card_code', $event)"
                :error="formErrors.card_code" />

            <div class="p-field min-height calendar-container">
                <PadelCalendar 
                    :label="t('card expiration') + '*'" 
                    :value="form.expiration_date" 
                    :minDate="new Date()"
                    @input="setFormValue('expiration_date', $event)" 
                    :error="formErrors.expiration_date" />
            </div>
            
            <PadelInput 
                :label="t('ranking') + '*'" 
                :value="form.rank" 
                @input="setFormValue('rank', $event)"
                :error="formErrors.rank"
                v-if="form.type === 'FIT'" />
            
        </div>

        <div class="p-d-flex p-jc-end">
            <PadelButton 
                :label="uppercase(card ? t('edit_card') : t('add_new_card'))" 
                :disabled="isFormCardEmpty(form, editMode) && isFormCardValid(formErrors)"
                @click="save()" />
        </div>

    </div>

</div>
