<TabView class="tab-view" :activeIndex="selectedTab" @tab-change="onChangeTab($event.index)">
    <TabPanel>
        <template #header>
            <span>{{uppercase(t('actives'))}}</span>
        </template>
        <div></div>
    </TabPanel>
    <TabPanel>
        <template #header>
            <span>{{uppercase(t('archived'))}}</span>
        </template>
        <div></div>
    </TabPanel>
    <TabPanel>
        <template #header>
            <span>{{uppercase(t('canceled'))}}</span>
        </template>
        <div></div>
    </TabPanel>
</TabView>


<div class="table-main-container">
    <List :values="scheduled" :hoverEnabled="true" :emptyState="t('no_schedules_found')" @row-clicked="onClickEvent">
        <template class="header" v-slot:header>
            <th>{{t('date')}}</th>
            <th>{{t('player_name')}}</th>
            <th>{{t('time')}}</th>
            <th>{{t('type')}}</th>
            <th>{{t('playground')}}</th>
            <th>{{t('online_booking')}}</th>
            <th>{{t('importo')}}</th>
            <!--<th>{{t('taking')}}</th>-->
            <th>{{t('notes')}}</th>
        </template>
        <template v-if="scheduled.length > 0" v-slot:tile="slotProps">
            <td>
                <div class="td-content">
                    {{ formatDate(slotProps.value) }}
                </div>
            </td>
            <td>
                <div class="td-content">
                    {{ (slotProps.value.type == "BACKOFFICE_OPEN_BOOKING" || slotProps.value.type == "OPEN_BOOKING") ?
                    '-' :
                    `${slotProps.value.player_name} ${slotProps.value.player_family_name}` }}
                </div>
            </td>
            <td>
                <div class="td-content">
                    {{ moment(slotProps.value.start_at).format("HH:mm") }} - {{
                    moment(slotProps.value.end_at).format("HH:mm") }}
                </div>
            </td>
            <td>
                <div class="td-content">
                    {{ bookingType(slotProps.value) }}
                </div>
            </td>
            <td>
                <div class="td-content">
                    {{ playgroundById(slotProps.value.playground_id) }}
                </div>
            </td>
            <td>
                <div class="td-content">
                    {{ onlineBooking(slotProps.value) }}
                </div>
            </td>
            <td>
                <div class="td-content">
                    {{ numberToEuro(slotProps.value.price) }}€
                </div>
            </td>
            <!--<td>
                <div class="td-content">
                    {{ numberToEuro(slotProps.value.taking) }}€
                </div>
            </td>-->
            <td>
                <div class="td-content">
                    {{ slotProps.value.description || "-" }}
                </div>
            </td>
        </template>
    </List>
</div>