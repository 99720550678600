<template src="./padel-signup.template.html"></template>

<script>
import { signupButtons } from "@/constants/constants.constants";
import {
  isFormEmpty,
  isFormValid,
  signupSchemaValidation,
} from "@/services/form-validation.service";
import { useRouter } from "vue-router";
import {
  mapCognitoError,
  mapInputError,
  uppercase,
} from "@/services/mapping.service";
import { MutationTypes } from "@/store/mutation-types";
import { useStore } from "@/store/store";
import { defineComponent, onMounted, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import {
  emptySignupForm,
  emptySignupFormErrors,
} from "@/constants/initial-values.constants";
import { ActionTypes } from "@/store/action-types";
import { CognitoErrors } from "@/constants/cognito-errors.enum";
import { RoleTypes } from "@/constants/role-types.enum";
import { extractAddressData } from "@/services/google-management.service";

export default defineComponent({
  name: "PadelSignup",
  setup() {
    /* DATA */
    const router = useRouter();
    const store = useStore();
    const formSchema = signupSchemaValidation;
    const registerButtons = ref(signupButtons);
    const formValues = ref(emptySignupForm);
    const formErrors = ref(emptySignupFormErrors);
    const captchaSiteKey = ref(process.env.VUE_APP_CAPTCHA_SITE_KEY);
    const isVisible = ref(true);
    const address = ref(null);
    const filteredAddresses = ref([]);
    const { t, locale } = useI18n();

    /* COMPUTED */
    const signupFormValues = computed(function() {
      return store.getters.getSignupFormValues;
    });

    const signupFormErrors = computed(function() {
      return store.getters.getSignupFormErrors;
    });

    function initializeSignupErrors() {
      formErrors.value = { ...emptySignupFormErrors };
    }

    function initializeSignupValues() {
      formValues.value = { ...emptySignupForm };
    }

    function initialize() {
      initializeSignupValues();
      initializeSignupErrors();
    }

    /* FUNCTIONS */
    function updateFormValue(value, field) {
      formValues.value[field] = value;
    }

    function updateFormError(value, field) {
      formErrors.value[field] = value;
    }

    function redirect(page) {
      router.push(page);
    }

    function sendConfirmSignUp(obj) {
      store.commit(MutationTypes.WRITE_CONFIRM_SIGNUP_USER, obj);
      /* resendCode(username); */
      redirect("confirmsignup");
    }

    function handleCognitoError(error) {
      if (error.code === CognitoErrors.USER_ALREADY_EXISTS) {
        updateFormError(mapCognitoError(error, t), "email");
      } else if (
        error.message.indexOf(
          "PreSignUp failed with error User already registered with different method"
        ) > -1
      ) {
        updateFormError(mapCognitoError(error, t), "email");
      } else if (error.message.indexOf("Invalid reCAPTCHA token") > -1) {
        updateFormError(mapCognitoError(error, t), "clubGeoLoc");
      } else {
        updateFormError(mapCognitoError(error, t), "password");
      }
    }

    async function signup(event, token) {
      initializeSignupErrors();
      if (!event.email || !event.password) {
        return;
      }
      try {
        await store.dispatch(ActionTypes.SIGNUP, {
          email: event.email,
          name: event.name,
          surname: event.surname,
          password: event.password,
          passwordConfirmation: event.passwordConfirmation,
          clubName: event.clubName,
          clubGeoLoc: event.clubGeoLoc,
          captchaToken: token,
          role: RoleTypes.MANAGER,
        });
        sendConfirmSignUp({ username: event.email, password: event.password });
        initialize();
      } catch (error) {
        handleCognitoError(error);
      }
    }

    function validateSignUp(values, field) {
      formSchema
        .validateAt(field, values)
        .then(() => {
          updateFormError("", field);
        })
        .catch((err) => {
          updateFormError(mapInputError(err.message, t), field);
        });
      if (field === "password" && values.passwordConfirmation) {
        validateSignUp(values, "passwordConfirmation");
      }
    }

    function checkSignUp(values) {
      formSchema
        .validate(values, { abortEarly: false })
        .then(() => {
          /* eslint-disable */
          grecaptcha.execute();
        })
        .catch((err) => {
          if (err.inner) {
            err.inner.forEach((error) => {
              updateFormError(mapInputError(error.message, t), error.path);
            });
            console.log(err);
          } else {
            console.log(err);
          }
        });
    }

    function updateVenue(place) {
      if (!place.geometry) {
        updateFormError("Inserire un luogo valido", "clubGeoLoc");
      } else {
        const resp = extractAddressData(place);
        updateFormValue(resp.location, "clubGeoLoc");
        //SE NON C'È IL CIVICO MOSTRARE ERRORE
        if (resp.err) {
          updateFormError(
            t("invalid_address"),
            "clubGeoLoc"
          );
        } else {
          updateFormError("", "clubGeoLoc");
        }
      }
    }

    function onAutocomplete(event) {
      if (!event && (!formValues.value || !formValues.value.clubGeoLoc || !formValues.value.clubGeoLoc.latitude)) {
        updateFormError("Campo obbligatorio", "clubGeoLoc");
      } /*  else {
        updateFormError("", "clubGeoLoc");
      } */
    }

    function onPlaceChanged(autocomplete) {
      const place = autocomplete.getPlace();
      updateVenue(place);
    }

    function onSubmitCaptcha(token) {
      signup(formValues.value, token);
    }

    function initializeAll() {
      store.commit(MutationTypes.RESET_STORE, null);
      initialize();
    }

    function loadCaptcha() {
      window.onSubmitCaptcha = onSubmitCaptcha;
      initializeAll();

      //LOAD CAPTCHA script
      const recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        `https://www.google.com/recaptcha/api.js`
      );
      document.head.appendChild(recaptchaScript);
    }

    function initializeAutocomplete() {
      loadCaptcha();
      const input = document.getElementById("autocomplete");
      /* eslint-disable */
      const autocomplete = new google.maps.places.Autocomplete(input, {
        fields: ["geometry", "formatted_address", "address_components"],
        types: ["address"],
      });
      autocomplete.setComponentRestrictions({
        country: ["it"],
      });
      /* eslint-disable */
      google.maps.event.addListener(autocomplete, "place_changed", () => {
        onPlaceChanged(autocomplete);
      });
    }

    function initializePlacesAndCaptcha() {
      if (window.google && window.google.maps) {
        initializeAutocomplete();
        return;
      }
      var script = document.createElement("script");
      script.onload = function() {
        if (!window.google && !window.google.maps)
          return void console.error("no google maps script included");
        initializeAutocomplete();
      };
      script.async = true;
      script.defer = true;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_PLACES_KEY}&libraries=places`;
      document.getElementsByTagName("head")[0].appendChild(script);
    }

    onMounted(() => {
      initializePlacesAndCaptcha();
    });

    return {
      t,
      locale,
      formSchema,
      registerButtons,
      isVisible,
      address,
      filteredAddresses,
      formValues,
      formErrors,

      uppercase,
      redirect,
      isFormValid,
      isFormEmpty,
      sendConfirmSignUp,
      mapInputError,
      checkSignUp,
      validateSignUp,
      updateFormValue,
      updateFormError,
      signupFormValues,
      signupFormErrors,
      onAutocomplete,
      onSubmitCaptcha,
      captchaSiteKey,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "./padel-signup.style.scss";
</style>

/* CLASSES FOR GOOGLE AUTOCOMPLETE */
<style lang="scss">
@import "@/theme/google-autocomplete.styles.scss";
</style>
