
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { uppercase } from "@/services/mapping.service";
import { useStore } from "@/store/store";
import { ActionTypes } from "@/store/action-types";
import { MutationTypes } from "@/store/mutation-types";
import { AdminFilters } from "@/models/filters.models";
import { adminDropdownChoices } from "@/constants/dropdown-choices.constants";
import { ClubRequestTypes } from "@/constants/request-types.enum";
import { REQUESTS_PAGE_SIZE } from "@/constants/constants.constants";
import { emptyPageTable } from "@/constants/initial-values.constants";
import { PadelClub } from "@/models/club.models";
import { ClubStatus } from "@/constants/club-status.enum";

export default defineComponent({
  setup() {
    const store = useStore();
    const { t, locale } = useI18n();
    const selectedTab = ref(0);
    const selectedClub = ref(null);
    const refreshClubs = ref(0);

    async function fetchRequests(filters: AdminFilters) {
      try {
        const requestsArray = await store.dispatch(
          ActionTypes.GET_CLUB_REQUESTS,
          {
            ...filters,
            page_size: REQUESTS_PAGE_SIZE,
          }
        );
        store.commit(MutationTypes.WRITE_CLUB_REQUESTS, requestsArray);
        return requestsArray;
      } catch (error) {
        console.log(error);
      }
    }

    async function fetchClubs() {
      try {
        store
          .dispatch(ActionTypes.GET_CLUBS, null)
          .then((list: PadelClub[]) => {
            const clubArray = list.filter(
              (val) =>
                val.status === ClubStatus.OPERATIVE ||
                val.status === ClubStatus.DISABLED
            );
            store.commit(MutationTypes.WRITE_ADMIN_CLUBS, clubArray);
            return clubArray;
          });
      } catch (error) {
        console.log(error);
      }
    }

    function onChangeTab(event) {
      if (event === 0) {
        fetchRequests({ status: ClubRequestTypes.ACCEPTABLE });
      } else if (event === 1) {
        fetchClubs();
      }
      selectedTab.value = event;
    }

    onMounted(() => {
      store.commit(MutationTypes.WRITE_CLUB_REQUESTS, emptyPageTable);
      fetchRequests({ status: ClubRequestTypes.ACCEPTABLE });
    });

    function handleClubClicked(event: PadelClub) {
      selectedClub.value = event;
    }

    function returnToClubPage(refreshPage: boolean) {
      if (refreshPage) {
        refreshClubs.value++;
      }
      selectedClub.value = null;
    }

    return {
      t,
      locale,
      uppercase,
      selectedTab,
      onChangeTab,
      adminDropdownChoices,
      selectedClub,
      handleClubClicked,
      returnToClubPage,
      refreshClubs,
    };
  },
});
