<PadelLateralTab />

<div class="view-main-container">

    <div class="inner-view-container" v-if="user">

        <div class="header flexbox-row padding-top-40 padding-bottom-40">
            <div class="cursor-pointer flexbox-row align-center" @click="router.go(-1)">
                <i class="material-icons color-blue cursor-pointer margin-right-10">keyboard_arrow_left</i>
                <div class="go-back-text color-blue">{{uppercase(t('back'))}}</div>
            </div>
        </div>

        <div class="p-grid padding-bottom-20">
            <div class="p-col-12 p-text-left">
                <h3 class="title margin-bottom-0">{{ `${user.name} ${user.family_name}` }}</h3>
            </div>
        </div>

        <TabView class="tab-view p-mb-5" :activeIndex="selectedTab" @tab-change="onChangeTab($event.index)">
            <TabPanel :disabled="formDisabled">
                <template #header>
                    <i class="material-icons" :class="{'color-blue': selectedTab === 0}">person</i>
                    <span>{{uppercase(t('registry'))}}</span>
                </template>
                <div class="registry-container">
                    <div class="centered">
                        <Avatar :photo="user.profile_pic_ref" />
                    </div>

                    <div class="registry-row">
                        <div>
                            <span class="label">{{ t('birthdate') }}</span><br>
                            <span class="value">{{ user.birth_date || '-' }}</span>
                        </div>
                        <div>
                            <span class="label">{{ t('sex') }}</span><br>
                            <span class="value">{{ t(gender(user.gender)) }}</span>
                        </div>
                        <div>
                            <span class="label">{{ t('player_type') }}</span><br>
                            <span class="value">
                                <PadelDropdown class="dropdown" :options="playerCategoryOptions"
                                    @input="selectPlayerCategory($event.value)" :default="user.player_category" />
                            </span>
                        </div>
                        <div>
                            <span class="label">{{ 'Email' }}</span><br>
                            <span class="value">{{ user.email }}</span>
                        </div>
                        <div>
                            <span class="label">{{ t('level') }}</span><br>
                            <span class="value">{{ `${t('level')} ${userLevel()} -
                                ${upperCaseFirstLetter(userLevelString())}`
                                }}</span>
                        </div>
                        <div>
                            <span class="label">{{ t('privacy_consents') }}</span><br>
                            <span class="value">{{ user.consents.accepts_commercial_partner ? t("yes") : t("no")
                                }}</span>
                        </div>
                        <div>
                            <span class="label">{{ t('phonenumber') }}</span><br>
                            <span class="value">{{ user.phone_number }}</span>
                        </div>
                        <div>
                            <span class="label">{{ t('certificate_due') }}</span><br>
                            <span class="value">{{ user.certificate_due || '-' }}</span>
                        </div>
                        <div>
                            <span class="label">{{ t('partner_communication_consents') }}</span><br>
                            <span class="value">{{ user.consents.accepts_commercial_partner ? t("yes") : t("no")
                                }}</span>
                        </div>
                    </div>
                </div>
            </TabPanel>
            <TabPanel :disabled="formDisabled">
                <template #header>
                    <i class="material-icons" :class="{'color-blue': selectedTab === 1}">recent_actors</i>
                    <span>{{uppercase(t('cards'))}}</span>
                </template>
                <div>
                    <div class="p-d-flex p-jc-end margin-bottom-20">
                        <div class="cursor-pointer flexbox-row align-center p-mt-md-3" @click="createCard()">
                            <span class="button-text">{{ t('add_new_card') }}</span>
                            <span class="material-icons color-blue">add</span>
                        </div>
                    </div>

                    <div class="cards-container margin-top-20 margin-bottom-10">
                        <UserCard v-for="(element, index) in cards" :key="index" :element="element" :user="user"
                            :index="index" @editCard="onEditCard" @deleteCard="onDeleteCard" @renewCard="onRenewCard" />
                    </div>
                </div>
            </TabPanel>
            <TabPanel :disabled="formDisabled">
                <template #header>
                    <i class="material-icons" :class="{'color-blue': selectedTab === 2}">list</i>
                    <span>{{uppercase(t('scheduled'))}}</span>
                </template>
                <div>
                    <UserScheduledTable :user="user" @onClick="onClickEvent" />
                </div>
            </TabPanel>
        </TabView>

    </div>

</div>

<div v-if="showConfirmDialog">
    <DumbModal :title="t('deleting_card')" @hide="hideDialog()">
        <div class="modal-content p-text-center p-p-6">
            <i class="pi pi-exclamation-triangle color-warning" style="font-size: 2rem"></i>
            <p class="p-text-center color-white" v-html="t('deleting_card_message', { card: selectedCard.card_code })">
            </p>
        </div>

        <template #footer>
            <div class="p-d-flex p-jc-end">
                <PadelButton :label="t('confirm')" @click="onDelete(selectedCard.id)" />
            </div>
        </template>
    </DumbModal>
</div>