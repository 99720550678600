
import { useI18n } from "vue-i18n";
import {
  isDateBefore,
  isToday,
  isSameOrBefore,
  formatDateDDMMYYYY,
  isDateAfter,
  returnTomorrow,
} from "@/services/dates.service";
import {
  mapFormError,
  mapChangeSlotsRequest,
  mapTournamentToSlotTab,
  uppercase,
  mapSlotCancelBookingPayload,
  mapNewBookingPayload,
  convertTimestampBack,
} from "@/services/mapping.service";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import {
  emptyCreateTournamentForm,
  emptyCreateTournamentFormErrors,
  emptyDateTournamentForm,
  emptyDateTournamentFormErrors,
  emptyFirstConflict,
} from "@/constants/initial-values.constants";
import {
  tournamentsSchemaValidation,
  isSecondStepEmpty,
  isSecondStepValid,
  validateDates,
  isSlotEmpty,
  isSlotValid,
  isValidRegistrationEnd,
} from "@/services/form-validation.service";
import { useStore } from "@/store/store";
import { ActionTypes } from "@/store/action-types";
import { PadelClub } from "@/models/club.models";
import { BookingType } from "@/constants/booking.enum";
import { BookingError } from "@/models/timeslots.models";

export default defineComponent({
  emits: ["isFormDirty", "tournamentChanged"],
  props: ["tournament", "playgroundMapFull", "playgroundOptions"],
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const minDate = ref(new Date(new Date().setHours(0, 0, 0, 0)));
    const registrationMinDate = ref(
      new Date(convertTimestampBack(props.tournament.registration_end_at))
    );
    const slotDisabled = ref(true);
    const addDayFormEnabled = ref(false);
    const firstConflict = ref(JSON.parse(JSON.stringify(emptyFirstConflict)));
    const newSlotForm = ref(
      JSON.parse(JSON.stringify(emptyDateTournamentForm))
    );
    const newSlotFormErrors = ref(
      JSON.parse(JSON.stringify(emptyDateTournamentFormErrors))
    );
    const isEdit = ref(true);
    const store = useStore();
    const formValues = ref(
      JSON.parse(JSON.stringify(emptyCreateTournamentForm))
    );
    const formErrors = ref(
      JSON.parse(JSON.stringify(emptyCreateTournamentFormErrors))
    );

    const modalToShow = ref(null);

    const playgroundMap = computed(function (): PadelClub {
      return store.getters.getPlaygroundMap;
    });

    /* COMPUTED */
    const myClub = computed(function (): PadelClub {
      return store.getters.getMyClub;
    });

    const openTime = computed(function (): number {
      return myClub.value.opening_hour / (1000 * 60 * 60);
    });

    const closeTime = computed(function (): number {
      return myClub.value.closing_hour / (1000 * 60 * 60);
    });

    function initializeValues() {
      isEdit.value = true;
      slotDisabled.value = true;
      addDayFormEnabled.value = false;
    }

    function initializeFormWithTournament() {
      initializeValues();
      formValues.value = {
        ...mapTournamentToSlotTab(props.tournament, props.playgroundOptions),
      };
      /* keyComponent.value++; */
    }

    onMounted(() => {
      initializeFormWithTournament();
    });

    watch(
      () => props.tournament,
      (first, second) => {
        initializeFormWithTournament();
      }
    );

    function updateFormError(error, field) {
      formErrors.value[field] = error;
    }

    function isDateFixed(date: Date) {
      return isDateBefore(date, new Date()) && !isToday(date);
    }

    function validateForm(values, field) {
      tournamentsSchemaValidation
        .validateAt(field, values)
        .then(() => {
          if (
            field === "registration_end_at" &&
            !isValidRegistrationEnd(values)
          ) {
            updateFormError(
              t("registration_must_be_before_start"),
              "registration_end_at"
            );
          } else {
            updateFormError("", field);
          }
        })
        .catch((err) => {
          updateFormError(mapFormError(err.message, t), field);
        });
    }

    function handleSlotDateValidation(index, field) {
      if (
        isDateBefore(
          formValues.value.dates[index].date,
          formValues.value.start_at
        )
      ) {
        updateFormError(t("found_timeslot_conflict"), field);
        return true;
      } else if (
        isDateAfter(
          formValues.value.dates[index].date,
          returnTomorrow(formValues.value.end_at)
        )
      ) {
        updateFormError(t("found_timeslot_conflict"), field);
        return true;
      } else {
        updateFormError("", field);
        return false;
      }
    }

    function validateAllSlotsDates(field) {
      let conflictFound = false;
      formValues.value.dates.forEach((date, index) => {
        if (!conflictFound) {
          conflictFound = handleSlotDateValidation(index, field);
        }
      });
    }

    function validateCalendar(field) {
      if (field != "registration_end_at") {
        validateAllSlotsDates(field);
        if (formErrors.value[field]) {
          return;
        }
      }
      if (validateDates(formValues.value)) {
        validateForm(formValues.value, "start_at");
        validateForm(formValues.value, "end_at");
        validateForm(formValues.value, "registration_end_at");
      }
    }

    function updateFormValue(value, field, validate = false) {
      formValues.value[field] = value;
      if (validate) {
        validateCalendar(field);
      }
    }

    function openModal(modal: string) {
      modalToShow.value = modal;
    }

    function hideModal() {
      firstConflict.value = JSON.parse(JSON.stringify(emptyFirstConflict));
      modalToShow.value = null;
    }

    async function saveTournamentChanges() {
      try {
        const payload = mapChangeSlotsRequest(formValues.value);
        const resp = await store.dispatch(ActionTypes.MODIFY_TOURNAMENT, {
          club_id: myClub.value.club_id,
          tournament_id: props.tournament.id,
          tournament: payload,
        });
        openModal("MODAL_SUCCESS");
        emit("isFormDirty", false);
        emit("tournamentChanged", resp);
      } catch (error) {
        return;
      }
    }

    function toggleFormDisabled() {
      isEdit.value = !isEdit.value;
    }

    function handleCalendarButtonClick() {
      if (!isEdit.value) {
        saveTournamentChanges();
      }
      toggleFormDisabled();
    }

    async function refreshPage() {
      try {
        const resp = await store.dispatch(ActionTypes.GET_TOURNAMENT_DETAILS, {
          club_id: myClub.value.club_id,
          tournament_id: props.tournament.id,
        });
        emit("isFormDirty", false);
        emit("tournamentChanged", resp);
      } catch (error) {
        return;
      }
    }

    async function deleteSlot(slot) {
      try {
        await store.dispatch(
          ActionTypes.DELETE_TIMESLOT,
          mapSlotCancelBookingPayload(slot, myClub.value.club_id)
        );
        openModal("MODAL_SUCCESS");
        await refreshPage();
      } catch (err) {
        return;
      }
    }

    function addSlot() {
      addDayFormEnabled.value = true;
    }

    function addDayDisabled() {
      return !isEdit.value || addDayFormEnabled.value;
    }

    function handleNotCreatedBookingError(notUpdated: BookingError[]) {
      firstConflict.value = notUpdated[0];
      openModal("CONFLICT_DETECTED");
      console.log(firstConflict.value, modalToShow.value);
    }

    async function saveNewSlot() {
      try {
        const bookingResponse = await store.dispatch(
          ActionTypes.ADD_BOOKING,
          mapNewBookingPayload(myClub.value.club_id, BookingType.TOURNAMENT, {
            ...newSlotForm.value,
            tournament_id: props.tournament.id,
            apply_all_playground: false,
          })
        );
        if (
          bookingResponse.notUpdated &&
          bookingResponse.notUpdated.length > 0
        ) {
          handleNotCreatedBookingError(bookingResponse.notUpdated);
        } else {
          openModal("MODAL_SUCCESS");
          refreshPage();
        }
      } catch (error) {
        return;
      }
    }

    function updateSlotFormError(value, field) {
      newSlotFormErrors.value[field] = value;
    }

    function updateTimeErrors(startError, endError) {
      updateSlotFormError(startError, "start_at");
      updateSlotFormError(endError, "end_at");
    }

    function handleSlotHourValidation(field, value) {
      if (field === "start_at") {
        if (isSameOrBefore(newSlotForm.value.end_at, value)) {
          updateTimeErrors("start_at_after_end", "");
        } else {
          updateTimeErrors("", "");
        }
      } else if (field === "end_at") {
        if (value != "00:00" && newSlotForm.value.start_at != "00:00") {
          if (isSameOrBefore(value, newSlotForm.value.start_at)) {
            updateTimeErrors("", "end_at_before_start");
          } else {
            updateTimeErrors("", "");
          }
        }
      }
    }

    function updateSlotFormValue(value, field, validateHours = false) {
      newSlotForm.value[field] = value;
      if (validateHours) {
        handleSlotHourValidation(field, value);
      }
    }

    return {
      t,
      locale,
      uppercase,
      minDate,
      formValues,
      formErrors,
      isEdit,
      modalToShow,
      myClub,
      openTime,
      closeTime,
      slotDisabled,
      addDayFormEnabled,
      newSlotForm,
      newSlotFormErrors,
      playgroundMap,

      updateFormValue,
      toggleFormDisabled,
      handleCalendarButtonClick,
      isDateBefore,
      isToday,
      hideModal,
      isSecondStepEmpty,
      isSecondStepValid,
      deleteSlot,
      addSlot,
      addDayDisabled,
      saveNewSlot,
      updateSlotFormValue,
      isSlotEmpty,
      isSlotValid,
      firstConflict,
      formatDateDDMMYYYY,
      isDateFixed,
      registrationMinDate,
    };
  },
});
