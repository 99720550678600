
import { 
  defineComponent,
  computed,
} from "vue";
import { Positions } from "@/constants/positions.enum";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "PadelTooltip",
  props: {
    label: { type: String, required: false, default: "" },
    arrowPosition: { type: String, required: true, default: Positions.LEFT },
    alignRight: { type: Boolean, required: false, default: false },
    absolute: { type: Boolean, required: false, default: false },
    x: { type: Number, required: false, default: 0 },
    y: { type: Number, required: false, default: 0 },
    dark: { type: Boolean, required: false, default: false },
    content: { type: Object, required: false, default: null },
  },
  emits: ['close'],
  setup(props, { emit }) {
      const {t, locale } = useI18n();
      const style = computed(function() {
        if (!props.absolute) return {};
        return {
          left: `${props.x}px`,
          top: `${props.y}px`,
        }
      });

      function close() {
        emit('close');
      }

      return {t, locale, style, close}
  }
});
