
import { RoleTypes } from "@/constants/role-types.enum";
import { LoginForm } from "@/models/forms.models";
import {
  isLoginFormEmpty,
  isLoginFormValid,
  loginFormSchemaValidation,
  newPasswordFormSchemaValidation,
  isNewPasswordFormValid,
  isNewPasswordFormEmpty,
  isNewPasswordRequiredFormValid,
  isNewPasswordRequiredFormEmpty,
} from "@/services/form-validation.service";
import {
  mapCognitoError,
  mapInputError,
  uppercase,
} from "@/services/mapping.service";
import { ActionTypes } from "@/store/action-types";
import { MutationTypes } from "@/store/mutation-types";
import { useStore } from "@/store/store";
import { defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { CognitoErrors } from "@/constants/cognito-errors.enum";
import { ClubStatus } from "@/constants/club-status.enum";
import { PadelClub } from "@/models/club.models";
import { addExpirationToTokens } from "@/services/token-validation.service";
import { BackEndUser } from "@/models/user.models";
import { showToast } from "@/services/error-management.service";
import { ToastErrors } from "@/constants/generic-errors.enum";

export default defineComponent({
  name: "PadelLogin",
  setup() {
    const router = useRouter();
    const store = useStore();
    const isVisible = ref(true);
    const autocomplete = ref(true);
    const loginFormSchema = loginFormSchemaValidation;
    const newPasswordFormSchema = newPasswordFormSchemaValidation;
    const bypassDisabledButton = ref(false);
    const openedModal = ref(null);
    const formValues = ref({ loginEmail: "", loginPassword: "" });
    const formErrors = ref({ loginEmail: "", loginPassword: "" });
    const newPasswordRequiredFormValues = ref({
      password: "",
      confirmPassword: "",
    });
    const newPasswordRequiredFormErrors = ref({
      password: "",
      confirmPassword: "",
    });

    const newPasswordRequiredCognitoUser = ref(null);
    const disambiguationClubs = ref([]);
    const { t, locale } = useI18n();

    /* FUNCTIONS */

    function initializeLoginErrors() {
      formErrors.value = { loginEmail: "", loginPassword: "" };
    }

    function initializeLoginForm() {
      formValues.value = { loginEmail: "", loginPassword: "" };
    }

    function initialize() {
      initializeLoginForm();
      initializeLoginErrors();
    }

    function updateFormValue(value: string, field: string) {
      bypassDisabledButton.value = false;
      formValues.value[field] = value;
    }

    function updateFormError(value: string, field: string) {
      formErrors.value[field] = value;
    }

    function redirect(page: string) {
      router.push(page);
    }

    function initializeNewPasswordRequiredForm() {
      newPasswordRequiredFormValues.value = {
        password: "",
        confirmPassword: "",
      };
      newPasswordRequiredFormErrors.value = {
        password: "",
        confirmPassword: "",
      };
    }

    function initializeModalForms() {
      initializeNewPasswordRequiredForm();
    }

    function openModal(modal: string) {
      openedModal.value = modal;
    }

    function hideModal() {
      initializeModalForms();
      openedModal.value = null;
    }

    function writeClubInStoreAndRedirect(club: PadelClub) {
      store.commit(MutationTypes.WRITE_LOGGED_CLUB, club);
      if (club.status === ClubStatus.CREATION) {
        redirect("add-club"); //INSERISCI I DATI DEL CENTRO
      } else {
        redirect("scheduler"); //VAI AL CALENDARIO DEL TUO UNICO CENTRO
      }
    }

    function handleClubsResponse(clubs: PadelClub[]) {
      if (clubs.length === 0) {
        store.commit(MutationTypes.WRITE_ALLOW_DOWNLOAD, true);
        redirect("download-contract"); //UTENTE NON CONFERMATO, ANDARE A DOWNLOAD CONTRACT
      }
      if (clubs.length > 1) {
        disambiguationClubs.value = clubs;
        openModal("DISAMBIGUATION"); //DISAMBIGUAZIONE
      } else {
        writeClubInStoreAndRedirect(clubs[0]);
      }
    }

    function handleClubSelected(club) {
      writeClubInStoreAndRedirect(club);
      hideModal();
    }

    function handleUserNotConfirmed(
      obj: { username: string; password: string },
      page: string
    ) {
      store.commit(MutationTypes.WRITE_CONFIRM_SIGNUP_USER, obj);
      redirect(page);
    }

    async function handlePostLogin(userInfo: BackEndUser) {
      if (!userInfo.phone_number) {
        handleUserNotConfirmed(
          {
            username: formValues.value.loginEmail,
            password: formValues.value.loginPassword,
          },
          "confirm-phone-number"
        );
      } else if (userInfo.groups.indexOf(RoleTypes.ADMIN) > -1) {
        redirect("admin");
      } else if (userInfo.groups.indexOf(RoleTypes.MANAGER) > -1) {
        try {
          const clubs = await store.dispatch(ActionTypes.MY_CLUBS, null);
          handleClubsResponse(clubs);
        } catch (error) {
          return;
        }
      } else {
        showToast(ToastErrors.LOGIN_NOT_POSSIBLE);
      }
    }

    function handleCognitoError(error) {
      if (
        error.code === CognitoErrors.USER_ALREADY_EXISTS ||
        error.code === CognitoErrors.USER_NOT_FOUND
      ) {
        updateFormError(mapCognitoError(error, t), "loginEmail");
      } else {
        updateFormError(mapCognitoError(error, t), "loginPassword");
      }
    }

    function forgotPassword() {
      openModal("FIND_ACCOUNT");
      /* initialize();   */
    }

    function updateNewPasswordRequiredFormValue(value: string, field: string) {
      newPasswordRequiredFormValues.value[field] = value;
    }

    function updateNewPasswordRequiredFormError(value: string, field: string) {
      newPasswordRequiredFormErrors.value[field] = value;
    }

    async function currentSessionAction() {
      return store.dispatch(ActionTypes.CURRENT_SESSION, null);
    }

    async function userInfoAction(currentSession) {
      store.commit(MutationTypes.WRITE_TOKENS, currentSession);
      return store.dispatch(ActionTypes.AUTH_ACTION, currentSession);
    }

    function handleCompleteNewPassword(signInInfo) {
      newPasswordRequiredCognitoUser.value = signInInfo;
      openModal("NEW_PASSWORD_REQUIRED");
    }

    async function login(form: LoginForm) {
      initializeLoginErrors();
      try {
        const signInInfo = await store.dispatch(ActionTypes.LOGIN, form);
        /* NECESSARIO RESETTARE PSWD */
        if (signInInfo.challengeName === "NEW_PASSWORD_REQUIRED") {
          handleCompleteNewPassword(signInInfo);
        } else {
          try {
            const currSession = addExpirationToTokens(
              signInInfo.signInUserSession
            );
            const userInfo = await userInfoAction(currSession);
            handlePostLogin(userInfo);
          } catch (err) {
            return;
          }
        }
      } catch (error) {
        if (error.code === CognitoErrors.USER_NOT_CONFIRMED) {
          handleUserNotConfirmed(
            { username: form.loginEmail, password: form.loginPassword },
            "confirmsignup"
          );
        } else {
          handleCognitoError(error);
        }
      }
    }

    function validate(values, field: string) {
      loginFormSchema
        .validateAt(field, values)
        .then(() => {
          updateFormError("", field);
        })
        .catch((err) => {
          updateFormError(mapInputError(err.message, t), field);
        });
    }

    function validateNewPasswordRequiredForm(values, field: string) {
      newPasswordFormSchema
        .validateAt(field, values)
        .then(() => {
          updateNewPasswordRequiredFormError("", field);
        })
        .catch((err) => {
          updateNewPasswordRequiredFormError(
            mapInputError(err.message, t),
            field
          );
        });
      if (field === "password" && values.confirmPassword) {
        validateNewPasswordRequiredForm(values, "confirmPassword");
      }
    }

    function loginUser(values) {
      loginFormSchema
        .validate(values, { abortEarly: false })
        .then(() => {
          login(values);
        })
        .catch((err) => {
          if (err.inner) {
            err.inner.forEach((error) => {
              updateFormError(mapInputError(error.message, t), error.path);
            });
          } else {
            return;
          }
        });
    }
    function goToRegister() {
      redirect("signup");
    }

    async function setRequiredPassword() {
      try {
        await store.dispatch(ActionTypes.COMPLETE_NEW_PASSWORD, {
          user: newPasswordRequiredCognitoUser.value,
          newPassword: newPasswordRequiredFormValues.value.password,
          requiredAttributes: {},
        });
        //PROSEGUI CON LA LOGIN CURRENT-SESSION E USER-INFO
        const currentSession = await currentSessionAction();
        const userInfo = await userInfoAction(currentSession);
        handlePostLogin(userInfo);
        hideModal();
      } catch (error) {
        if (error.code === CognitoErrors.USER_NOT_CONFIRMED) {
          handleUserNotConfirmed(
            {
              username: newPasswordRequiredCognitoUser.value.username,
              password: newPasswordRequiredFormValues.value.password,
            },
            "confirmsignup"
          );
        } else {
          handleCognitoError(error);
        }
      }
    }

    function handleEnterPressed(event, field) {
      updateFormValue(event, field);
      loginUser(formValues.value);
    }

    function handleAutofill() {
      bypassDisabledButton.value = true;
    }

    onMounted(() => {
      initialize();
      if (store.getters.getForcedLogout) {
        store.commit(MutationTypes.RESET_STORE, null);
        /* showToast(ToastErrors.FORCE_LOGOUT); */
      } else {
        store.commit(MutationTypes.RESET_STORE, null);
      }
    });

    return {
      t,
      locale,
      loginFormSchema,
      isVisible,
      formValues,
      formErrors,
      openedModal,
      disambiguationClubs,
      autocomplete,
      bypassDisabledButton,

      validate: validate,
      loginUser: loginUser,
      uppercase: uppercase,
      isLoginFormValid: isLoginFormValid,
      isLoginFormEmpty: isLoginFormEmpty,
      isNewPasswordFormValid: isNewPasswordFormValid,
      isNewPasswordFormEmpty: isNewPasswordFormEmpty,
      isNewPasswordRequiredFormValid,
      isNewPasswordRequiredFormEmpty,
      mapInputError: mapInputError,
      goToRegister: goToRegister,
      updateFormValue: updateFormValue,
      forgotPassword,
      hideModal,
      newPasswordRequiredFormValues,
      newPasswordRequiredFormErrors,
      validateNewPasswordRequiredForm,
      updateNewPasswordRequiredFormValue,
      setRequiredPassword,
      handleClubSelected,
      handleEnterPressed,
      handleAutofill,
    };
  },
});
