
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useStore } from "@/store/store";
import { useRouter } from "vue-router";
import { PadelClub } from "@/models/club.models";
import { useI18n } from "vue-i18n";
import {
  convertFormIntoPayload,
  deconvertServices,
  mapInputError,
  uppercase,
} from "@/services/mapping.service";
import {
  emptyAddClubForm,
  emptyAddClubFormError,
  emptyServices,
} from "@/constants/initial-values.constants";
import {
  convertMillisecondsOfDaytoHHMM,
  isSameOrBefore,
} from "@/services/dates.service";
import {
  isChangeClubFormValid,
  isChangeClubFormEmpty,
  addClubSchemaValidation,
} from "@/services/form-validation.service";
import { showToast } from "@/services/error-management.service";
import { ToastErrors } from "@/constants/generic-errors.enum";
import { ActionTypes } from "@/store/action-types";
import { convertConflictTime } from "@/services/dates.service";
export default defineComponent({
  name: "ModifyClub",
  setup() {
    const store = useStore();
    const router = useRouter();
    const { t, locale } = useI18n();
    const services = ref(emptyServices);
    const formSchema = addClubSchemaValidation;
    const formValues = ref({ ...emptyAddClubForm });
    const formErrors = ref({ ...emptyAddClubFormError });
    const formDisabled = ref(true);
    const modalToShow = ref(null);
    const componentKey = ref(0);
    const temporaryPic = ref(null);
    const showTooltip = ref(false);
    const textMaxLength = ref(1500);
    const conflicts = ref([]);
    /* COMPUTED */
    const myClub = computed(function(): PadelClub {
      return store.getters.getMyClub;
    });

    function initializeFormWithValuesFromStore() {
      formValues.value = {
        ...formValues.value,
        phoneNumber: store.getters.getMyClub.club_phone_number,
        openTime: convertMillisecondsOfDaytoHHMM(
          store.getters.getMyClub.opening_hour
        ),
        closeTime: convertMillisecondsOfDaytoHHMM(
          store.getters.getMyClub.closing_hour
        ),
        club_notes: store.getters.getMyClub.club_notes,
        picture_ref: store.getters.getMyClub.cover_pic_ref,
        email: store.getters.getMyClub.email,
        profilePicture: { name: "", src: "" },
      };
      services.value = deconvertServices(store.getters.getMyClub.services);
      componentKey.value++;
    }

    function handleClickCalendar(event) {
      const specifiedElement = document.getElementById(
        "info-icon"
      );
      const isClickInside = specifiedElement.contains(event.target as Node);
      if (!isClickInside) {
        showTooltip.value = false;
      }
    }

    //AL MOUNTED PREPOPOLA FORM CON VALORI DELL'UTENTE
    onMounted(() => {
      initializeFormWithValuesFromStore();
      document.addEventListener("click", handleClickCalendar);
    });

    onUnmounted(() => {
      document.removeEventListener("click", handleClickCalendar);
    });

    function updateFormValue(value: string, field: string) {
      formValues.value[field] = value;
    }

    function updateFormError(value: string, field: string) {
      formErrors.value[field] = value;
    }

    function goToCalendar() {
      router.push("scheduler");
    }

    function updateTimeErrors(from: string, to: string) {
      updateFormError(from, "openTime");
      updateFormError(to, "closeTime");
    }

    function updateFormTimeValue(value: string, field: string) {
      if (value) {
        updateFormValue(value, field);
        if (field === "openTime") {
          if (isSameOrBefore(formValues.value.closeTime, value)) {
            updateTimeErrors(
              "L'orario di apertura deve essere precedente a quello di chiusura",
              ""
            );
          } else {
            updateTimeErrors("", "");
          }
        } else if (field === "closeTime") {
          if (isSameOrBefore(value, formValues.value.openTime)) {
            updateTimeErrors(
              "",
              "L'orario di chiusura deve essere successivo a quello di apertura"
            );
          } else {
            updateTimeErrors("", "");
          }
        }
      }
    }

    function initializeErrors() {
      formErrors.value = { ...emptyAddClubFormError };
    }

    function enableForm() {
      formDisabled.value = false;
    }

    function disableForm() {
      initializeErrors();
      initializeFormWithValuesFromStore();
      formDisabled.value = true;
    }

    function openChangePicModal() {
      modalToShow.value = "INSERT_PHOTO_MODAL";
    }

    function initialize() {
      conflicts.value = [];
    }

    function openModal(modal: string) {
      modalToShow.value = modal;
    }

    function hideModal() {
      initialize();
      modalToShow.value = null;
    }

    function temporaryUploader(event) {
      temporaryPic.value = event;
    }

    function handleWrongFileUploaded(file) {
      showToast(ToastErrors.WRONG_FILE_FORMAT);
    }

    function confirmUploadPhoto() {
      formValues.value.profilePicture = temporaryPic.value;
      hideModal();
    }

    function goToScheduler() {
      router.push("scheduler");
    }

    function handleHideModalSuccess() {
      goToScheduler();
      hideModal();
    }

    async function saveClubChanges() {
      try {
        const payload = convertFormIntoPayload(
          formValues.value,
          services.value
        );
        const res = await store.dispatch(ActionTypes.CHANGE_CLUB_INFO, {
          club_id: myClub.value.club_id,
          request: payload,
        });
        const clubs = await store.dispatch(ActionTypes.MY_CLUBS, null);
        openModal("MODAL_SUCCESS");
      } catch (error) {
        if (error.response && error.response.data && error.response.data.conflicts) {
          conflicts.value = error.response.data.conflicts;
          openModal("SHOW_CONFLICTS");
          return;
        }
      }
    }

    function validateForm(field: string) {
      formSchema
        .validateAt(field, formValues.value)
        .then(() => {
          updateFormError("", field);
        })
        .catch((err) => {
          updateFormError(mapInputError(err.message, t), field);
        });
    }

    return {
      myClub,
      t,
      locale,
      uppercase,
      services,
      formValues,
      formErrors,
      formDisabled,
      componentKey,
      updateFormValue,
      updateFormTimeValue,
      enableForm,
      disableForm,
      saveClubChanges,
      isChangeClubFormValid,
      isChangeClubFormEmpty,
      openChangePicModal,
      modalToShow,
      hideModal,
      temporaryUploader,
      temporaryPic,
      handleWrongFileUploaded,
      confirmUploadPhoto,
      showTooltip,
      textMaxLength,
      validateForm,
      handleHideModalSuccess,
      conflicts,
      goToCalendar,
      convertConflictTime,
    };
  },
});
