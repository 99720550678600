
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { ActionTypes } from "@/store/action-types";
import PlayerItem from "./player-item/PlayerItem.vue";

export default defineComponent({
  name: "MatchPlayers",
  components: {
    PlayerItem,
  },
  props: {
    match: {
      type: Object,
      default: null,
    },
    players: {
      type: Number,
      default: 4,
    },
  },
  emits: ["refresh"],
  setup(props, { emit }) {
    const router = useRouter();
    const store = useStore();
    const { t, locale } = useI18n();

    /* COMPUTED */
    const playerIds = computed(function () {
      // INIT
      const arr = [];

      if (!props.match) return arr;

      // ITERATE
      for (let i = 0; i < props.players; i++) {
        if (i === 0) {
          arr.push(props.match[`wesmash_user_id`]);
        } else {
          arr.push(props.match[`wesmash_user${i + 1}_id`]);
        }
      }

      // RETURN
      return arr;
    });

    const playersList = computed(function () {
      // INIT
      const arr = [];

      if (!props.match) return arr;

      // ITERATE
      for (let i = 0; i < props.players; i++) {
        let item = props.match[`player_${i + 1}`];
        if (item) {
          if (i === 0) {
            item.id = props.match[`wesmash_user_id`];
          } else {
            item.id = props.match[`wesmash_user${i + 1}_id`];
          }
        }
        arr.push(item);
      }

      // RETURN
      return arr;
    });

    // SYSTEM
    onMounted(() => {});

    // FUNCTIONS
    function refresh() {
      emit("refresh");
    }

    return {
      t,
      locale,

      // DATA
      playersList,
      playerIds,

      // METHODS
      refresh,
    };
  },
});
