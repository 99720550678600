
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import { 
    computed, 
    defineComponent, 
    onMounted, 
    onUnmounted,
    ref,
    nextTick,
    watch,
} from "vue";
import { uppercase, upperCaseFirstLetter } from "@/services/mapping.service";
import { doubleZeros } from "@/services/dates.service";
import moment from "moment";
import { uuid } from 'vue-uuid';

export default defineComponent({
    components: {
    },
    props: {
        element: {
            type: Object,
            required: true,
            default: () => {
                return {};
            },
        },
        index: { type: Number, required: true, default: null },
        event: { type: Boolean, required: true, default: false },
    },
    emits: [],
    setup(props, {emit}) {
        const { t, locale } = useI18n();
        const store         = useStore();
        const hasPlayground = ref(props.element.status == "AVAILABLE");
        const playground    = ref(null);
        const price         = ref(0);
        const taking        = ref(0);
        const elementId     = uuid.v1();

        // COMPUTED
        const myPlaygrounds = computed(function() {
            return store.getters.getMyPlaygrounds;
        });
        const mappedPlaygrounds = computed(function(): {value: string; label: string; sport: string}[] {
            return myPlaygrounds.value.map(el => { 
                return { value: el.id, label: el.name, sport: el.sport };
            });
        });
        const availablePlaygrounds = computed(function() {
            return mappedPlaygrounds.value.filter(el => (props.element.playground_ids || []).includes(el.value));
        });

        // SYSTEM
        onMounted(() => {
            nextTick(() => {
                playground.value = mappedPlaygrounds.value.find(el => el.value === props.element.playground_id);
                price.value      = props.element.price ? props.element.price / 100 : 0;
                taking.value     = props.element.taking ? props.element.taking / 100 : 0;
            })
        });

        // METHODS
        function formatDateTime() {
            const startAt   = moment(props.element.start_at);
            const endAt     = moment(props.element.end_at);
            return `${startAt.format("DD/MM/YYYY")} - ${startAt.format("HH:mm")} / ${endAt.format("HH:mm")}`;
        }

        function selectPlayground(value) {
            props.element.playground_id = value;
            props.element.status        = "AVAILABLE";
            playground.value            = value;
        }

        function setFormValue(key: string, value) {
            if (key == 'price') {
                price.value = value;
                props.element[key] = value * 100;
            } else if (key == 'taking') {
                taking.value = value
                props.element[key] = value * 100
            }
        }

        return {
            t, 
            locale, 
            uppercase,
            upperCaseFirstLetter,
            doubleZeros,
            formatDateTime,
            selectPlayground,
            elementId,
            setFormValue,

            // DATA
            hasPlayground,
            availablePlaygrounds,
            playground,
            price,
            taking,
        };
    },
});
