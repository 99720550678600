
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import moment from "moment";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  nextTick,
  watch,
} from "vue";
import {
  emptyCreatePriceRuleForm,
  emptyCreatePriceRuleFormErrors,
  emptySlotItem,
  emptySlotItemErrors,
} from "@/constants/initial-values.constants";
import {
  isTimeBeforeStart,
  isTimeAfterEnd,
  isTimeInsideAnotherSlot,
  isTimeLowerThan,
  durationIsHigherThan,
  MONTHS,
  daysByMonth,
  isDateAfter,
  isDateBefore,
} from "@/services/dates.service";
import {
  isFormPriceRuleEmpty,
  isFormPriceRuleValid,
} from "@/services/form-validation.service";
import { PadelClub } from "@/models/club.models";
import { PadelPlayground } from "@/models/playground.models";
import { ActionTypes } from "@/store/action-types";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import {
  uppercase,
  mapPriceRuleIntoForm,
  mySportsByPlaygrounds,
} from "@/services/mapping.service";
import { sportOptions } from "@/constants/radio-options.constants";
import { WEEKDAYS_SLOTS } from "@/constants/constants.constants";
import { MutationTypes } from "@/store/mutation-types";

export default defineComponent({
  name: "AddPriceRule",
  components: {},
  setup() {
    const route = useRoute();
    const ruleId = route.params.id || null;
    const tooltipActive = ref(false);
    const store = useStore();
    const { t, locale } = useI18n();
    const router = useRouter();
    const DaysOptions = ref({
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    });
    const showConflictDialog = ref(false);
    const startMonth = ref(null);
    const startDay = ref(null);
    const endMonth = ref(null);
    const endDay = ref(null);

    /* COMPUTED */
    const myClub = computed(function (): PadelClub {
      return store.getters.getMyClub;
    });
    const myPlaygrounds = computed(function (): PadelPlayground[] {
      return store.getters.getMyPlaygrounds;
    });
    const mappedPlaygrounds = computed(function (): {
      value: string;
      label: string;
      sport: string;
    }[] {
      return myPlaygrounds.value.map((el) => {
        return { value: el.id, label: el.name, sport: el.sport };
      });
    });
    const openingHour = computed(function (): Number {
      return myClub.value.opening_hour / 1000 / 60 / 60;
    });
    const closingHour = computed(function (): Number {
      return myClub.value.closing_hour / 1000 / 60 / 60;
    });
    const startOptions = computed(function () {
      return daysByMonth(startMonth.value);
    });
    const endOptions = computed(function () {
      return daysByMonth(endMonth.value);
    });
    const PlaygroundsOptions = computed(function (): {
      value: string;
      label: string;
      sport: string;
    }[] {
      return mappedPlaygrounds.value.filter(
        (el) => el.sport === addPriceRuleForm.value.sport_type
      );
    });
    const mySports = computed(function () {
      return mySportsByPlaygrounds(myPlaygrounds.value);
    });

    // DATA
    const addPriceRuleForm = ref(
      JSON.parse(JSON.stringify(emptyCreatePriceRuleForm))
    );
    if (mySports.value.length > 0) {
      addPriceRuleForm.value.sport_type = mySports.value[0].value;
    }
    const addPriceRuleFormErrors = ref(
      JSON.parse(JSON.stringify(emptyCreatePriceRuleFormErrors))
    );

    //AL MOUNTED PREPOPOLA FORM CON VALORI DELL'UTENTE
    function parseRule(response) {
      addPriceRuleForm.value = mapPriceRuleIntoForm(
        response,
        addPriceRuleFormErrors,
        emptySlotItemErrors
      );
      DaysOptions.value = {
        monday: response.days.includes(WEEKDAYS_SLOTS.MONDAY),
        tuesday: response.days.includes(WEEKDAYS_SLOTS.TUESDAY),
        wednesday: response.days.includes(WEEKDAYS_SLOTS.WEDNESDAY),
        thursday: response.days.includes(WEEKDAYS_SLOTS.THURSDAY),
        friday: response.days.includes(WEEKDAYS_SLOTS.FRIDAY),
        saturday: response.days.includes(WEEKDAYS_SLOTS.SATURDAY),
        sunday: response.days.includes(WEEKDAYS_SLOTS.SUNDAY),
      };
      startMonth.value = addPriceRuleForm.value.start_at.getMonth() + 1;
      startDay.value = addPriceRuleForm.value.start_at.getDate();
      endMonth.value = addPriceRuleForm.value.end_at.getMonth() + 1;
      endDay.value = addPriceRuleForm.value.end_at.getDate();
    }

    async function getRuleById() {
      try {
        const response = await store.dispatch(ActionTypes.GET_PRICE_RULE, {
          id: ruleId,
        });
        parseRule(response);
      } catch (error) {
        console.error(error);
        return;
      }
    }

    onMounted(() => {
      nextTick(() => {
        addPriceRuleForm.value.club_id = myClub.value.club_id;

        if (ruleId) {
          getRuleById();
        } else {
          // CHECK STATE
          const rule = store.getters.getPriceRule;
          if (rule) {
            parseRule(rule);
          } else {
            addSlot();
          }
        }
      });
    });

    onUnmounted(() => {});

    onBeforeRouteLeave((to, from) => {
      store.commit(MutationTypes.SET_PRICE_RULE, null);
      return true;
    });

    /** WATCH */
    watch(addPriceRuleForm.value, function (currentValue) {
      console.log(currentValue);
    });

    // FUNCTIONS
    function hideModal() {
      showConflictDialog.value = false;
    }

    function handleValidation(field) {
      const form = addPriceRuleForm.value;
      switch (field) {
        case "start_at":
          if (isDateAfter(form.start_at, form.end_at)) {
            updateFormError("start_at", t("rule_start_before_end"));
          } else {
            updateFormError("start_at", "");
          }
          break;
        case "end_at":
          if (isDateBefore(form.end_at, form.start_at)) {
            updateFormError("end_at", t("rule_start_before_end"));
          } else {
            updateFormError("end_at", "");
          }
          break;
      }
    }

    function handleSlotValidation(field, index) {
      const slot = addPriceRuleForm.value.slots[index];
      switch (field) {
        case "start_at":
          if (isTimeAfterEnd(slot.start_at, slot.end_at)) {
            updateSlotError("start_at", t("cannot_slot_after_end"), index);
          } else if (
            isTimeInsideAnotherSlot(index, slot, addPriceRuleForm.value.slots)
          ) {
            updateSlotError(
              "start_at",
              t("slot_interfere_with_another"),
              index
            );
          } else if (isTimeLowerThan(slot.start_at, slot.end_at, 60)) {
            updateSlotError(
              "start_at",
              t("cannot_slot_end_at_least", { minutes: 60 }),
              index
            );
          } else {
            updateSlotError("start_at", "", index);
            updateSlotError("end_at", "", index);
          }
          break;
        case "end_at":
          if (isTimeBeforeStart(slot.end_at, slot.start_at)) {
            updateSlotError("end_at", t("cannot_slot_after_end"), index);
          } else if (
            isTimeInsideAnotherSlot(index, slot, addPriceRuleForm.value.slots)
          ) {
            updateSlotError("end_at", t("slot_interfere_with_another"), index);
          } else if (isTimeLowerThan(slot.start_at, slot.end_at, 60)) {
            updateSlotError(
              "end_at",
              t("cannot_slot_end_at_least", { minutes: 60 }),
              index
            );
          } else {
            updateSlotError("start_at", "", index);
            updateSlotError("end_at", "", index);
          }
          break;
      }
    }

    function toggleTooltip() {
      tooltipActive.value = !tooltipActive.value;
    }

    function updateFormError(field, error) {
      addPriceRuleFormErrors.value[field] = error;
    }

    function updateSlotError(field, error, index) {
      addPriceRuleFormErrors.value.slots[index][field] = error;
    }

    function updateFormValue(value: string, field: string, validate = false) {
      // INIT
      let newField = field;
      let newVal: any = value;

      // CHECK
      if (field == "start_month") {
        startMonth.value = value;
        value = `${moment().year()}-${value.toString().padStart(2, "0")}-01`;
        newVal = new Date(value);
        newField = "start_at";
      } else if (field == "start_day") {
        startDay.value = value;
        value = `${moment().year()}-${startMonth.value
          .toString()
          .padStart(2, "0")}-${value.toString().padStart(2, "0")}`;
        newVal = new Date(value);
        newField = "start_at";
      } else if (field == "end_month") {
        endMonth.value = value;
        value = `${moment().year()}-${value.toString().padStart(2, "0")}-01`;
        newVal = new Date(value);
        newField = "end_at";
      } else if (field == "end_day") {
        endDay.value = value;
        value = `${moment().year()}-${endMonth.value
          .toString()
          .padStart(2, "0")}-${value.toString().padStart(2, "0")}`;
        newVal = new Date(value);
        newField = "end_at";
      } else if (field == "sport_type") {
        addPriceRuleForm.value.playground_ids = [];
      }

      // SET
      addPriceRuleForm.value[newField] = newVal;
      if (validate) {
        handleValidation(newField);
      }
    }

    function updateSlotValue(
      index: number,
      value: string,
      field: string,
      validate = false
    ) {
      addPriceRuleForm.value.slots[index][field] = value;
      if (validate) {
        if (field === "start_at" || field === "end_at") {
          let duration60 = durationIsHigherThan(
            addPriceRuleForm.value.slots[index],
            60
          );
          let duration90 = durationIsHigherThan(
            addPriceRuleForm.value.slots[index],
            90
          );
          let duration120 = durationIsHigherThan(
            addPriceRuleForm.value.slots[index],
            120
          );

          // --
          addPriceRuleForm.value.slots[index].show_duration_60 = duration60;
          addPriceRuleForm.value.slots[index].show_duration_90 = duration90;
          addPriceRuleForm.value.slots[index].show_duration_120 = duration120;

          // -- CHECK
          if (!duration60) {
            addPriceRuleForm.value.slots[index].duration_60 = false;
            addPriceRuleForm.value.slots[index].price_60 = null;
          }
          if (!duration90) {
            addPriceRuleForm.value.slots[index].duration_90 = false;
            addPriceRuleForm.value.slots[index].price_90 = null;
          }
          if (!duration120) {
            addPriceRuleForm.value.slots[index].duration_120 = false;
            addPriceRuleForm.value.slots[index].price_120 = null;
          }
        }
        handleSlotValidation(field, index);
      }
    }

    function addSlot() {
      addPriceRuleForm.value.slots.push(
        JSON.parse(JSON.stringify(emptySlotItem))
      );
      addPriceRuleFormErrors.value.slots.push(
        JSON.parse(JSON.stringify(emptySlotItemErrors))
      );
    }

    function removeSlot(index) {
      addPriceRuleForm.value.slots.splice(index, 1);
      addPriceRuleFormErrors.value.slots.splice(index, 1);
    }

    function mapDays() {
      const output = [];
      const keys = Object.keys(DaysOptions.value);
      keys.forEach((key) => {
        if (DaysOptions.value[key]) {
          output.push(WEEKDAYS_SLOTS[key.toUpperCase()]);
        }
      });
      return output;
    }

    function save() {
      // INIT
      const body = JSON.parse(JSON.stringify(addPriceRuleForm.value));
      body.slots.forEach((slot: any) => {
        // PRE-CHECK
        if (!slot.duration_60) slot.price_60 = null;
        if (!slot.duration_90) slot.price_90 = null;
        if (!slot.duration_120) slot.price_120 = null;

        // FULL CHECK
        if (slot.price_60 === null || slot.price_60 === "") {
          slot.price_60 = -1;
        } else {
          slot.price_60 = slot.price_60 * 100;
        }
        if (slot.price_90 === null || slot.price_90 === "") {
          slot.price_90 = -1;
        } else {
          slot.price_90 = slot.price_90 * 100;
        }
        if (slot.price_120 === null || slot.price_120 === "") {
          slot.price_120 = -1;
        } else {
          slot.price_120 = slot.price_120 * 100;
        }

        // DELETE
        delete slot.duration_60;
        delete slot.duration_90;
        delete slot.duration_120;
        delete slot.show_duration_60;
        delete slot.show_duration_90;
        delete slot.show_duration_120;
      });
      body.days = mapDays();
      if (body.start_at.length > 5) {
        body.start_at = moment(body.start_at).format("MM-DD");
      }
      if (body.end_at.length > 5) {
        body.end_at = moment(body.end_at).format("MM-DD");
      }
      delete body.starting_price;

      // DISPATCH
      if (ruleId) {
        store
          .dispatch(ActionTypes.UPDATE_PRICE_RULE, { id: ruleId, body: body })
          .then(() => {
            router.replace({ name: "MyClub", query: { tab: "price-rules" } });
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 409) {
                showConflictDialog.value = true;
              }
            }
          });
      } else {
        delete body.id;
        store
          .dispatch(ActionTypes.CREATE_PRICE_RULE, body)
          .then(() => {
            router.replace({ name: "MyClub", query: { tab: "price-rules" } });
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.status === 409) {
                showConflictDialog.value = true;
              }
            }
          });
      }
    }

    function selectAllDays() {
      if (
        DaysOptions.value.monday &&
        DaysOptions.value.tuesday &&
        DaysOptions.value.wednesday &&
        DaysOptions.value.thursday &&
        DaysOptions.value.friday &&
        DaysOptions.value.saturday &&
        DaysOptions.value.sunday
      ) {
        DaysOptions.value = {
          monday: false,
          tuesday: false,
          wednesday: false,
          thursday: false,
          friday: false,
          saturday: false,
          sunday: false,
        };
      } else {
        DaysOptions.value = {
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
        };
      }
    }

    return {
      t,
      locale,
      router,
      mySports,
      PlaygroundsOptions,
      DaysOptions,
      ruleId,
      openingHour,
      closingHour,
      showConflictDialog,
      MONTHS,
      startMonth,
      startDay,
      endMonth,
      endDay,
      startOptions,
      endOptions,

      addPriceRuleForm,
      addPriceRuleFormErrors,
      addSlot,
      removeSlot,
      durationIsHigherThan,
      isFormPriceRuleEmpty,
      isFormPriceRuleValid,
      save,
      hideModal,
      selectAllDays,

      uppercase,
      moment,
      updateFormError,
      updateFormValue,
      updateSlotValue,
      toggleTooltip,
      tooltipActive,
    };
  },
});
