<div class="box card-box color-white flexbox-column"
    :style="{ backgroundImage: `url('${cardImage}')`, backgroundPosition: 'right 10px center', backgroundRepeat: 'no-repeat', backgroundSize: '72px auto' }">
    <div class="box-info-part">
        <div class="actions-container"
            v-if="hasActions">
            <Actions
                :item="element"
                :editable="editable"
                :deletable="deletable"
                :duplicable="false"
                :certifiable="certifiable"
                :renewable="expired"
                @edit="onEditCard"
                @delete="onDeleteCard"
                @renew="onRenewCard" />
        </div>
        <div class="fit-box"
            v-if="element.type === 'FIT'">
            <i class="material-icons font-16">equalizer</i> {{ element.rank || '-' }}
        </div>
        <p class="box-row margin-top-10 margin-0 margin-bottom-20">
            <span class="label">{{ t('card_type') }}</span><br>
            <span class="value">{{ toReadableName(element.type) }}</span>
        </p>
        <p class="box-row margin-0 margin-bottom-20">
            <span class="label">{{ t('card number') }}</span><br>
            <span class="value">{{ element.card_code }}</span>
        </p>
        <p class="box-row margin-0 margin-bottom-20">
            <span class="label">{{ t('card expiration') }}</span><br>
            <span class="value"
                :class="{'expired': expired}">{{ toDate(element.expiration_date) }}</span>
        </p>
        <p class="box-row margin-0 margin-bottom-20">
            <span class="label">{{ t('added by') }}</span><br>
            <span class="value">{{ addedBy() }}</span>
        </p>
    </div>
</div>