<div class="main-div" :class="selectedClub ? 'display-none' : ''">
	<div class="nav-container flexbox-row justify-space-between padding-top-20 padding-bottom-20">
		<div class="logo-box">
			<img class="logo-img" src="@/theme/imgs/logo_completo.svg" alt="">
		</div>
		<Nav :dropdownChoices="adminDropdownChoices" />
	</div>
	<div class="table-container">
		<TabView class="tab-view" :activeIndex="selectedTab" @tab-change="onChangeTab($event.index)">
			<TabPanel>
				<template #header>
					<i class="icon icon-request" :class="{'bg-color-blue': selectedTab === 0}"></i>
					<span>{{uppercase(t('requests'))}}</span>
				</template>
				<RequestsTable v-if="selectedTab === 0" :forceUnmountInfiniteScroll="selectedTab === 0" tableType="requests" />
			</TabPanel>
			<TabPanel>
				<template #header>
					<i class="icon icon-ball" :class="{'bg-color-blue': selectedTab === 1}"></i>
					<span>{{uppercase(t('clubs'))}}</span>
				</template>
				<ClubsTable v-if="selectedTab === 1" :forceUnmountInfiniteScroll="selectedTab === 1"
					@clubClicked="handleClubClicked($event)" :forceRefresh="refreshClubs" />
			</TabPanel>
			<TabPanel>
				<template #header>
					
					<i class="icon icon-coin" :class="{'bg-color-blue': selectedTab === 2}"></i> 
					<span>{{uppercase('Statistiche')}}</span>
				</template>

				<AdminStatsTable v-if="selectedTab === 2" :forceUnmountInfiniteScroll="selectedTab === 2"
					tableType="requests" />
			</TabPanel>
		</TabView>
	</div>
</div>
<div class="main-div" v-if="selectedClub">
	<ClubDetail @returnToPrevPage="returnToClubPage($event)" :selectedClub="selectedClub" />
</div>