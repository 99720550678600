

import { defineComponent } from 'vue';
import {ButtonTypes} from '@/constants/button-types.enum'
export default defineComponent({
    name:'PadelButton',
    props: {
        label : { type: String , required: false, default: ''},
        type : { type: String , required: false, default: ButtonTypes.PRIMARY},
        icon : { type: String , required: false, default: ''},
        disabled: {type: Boolean, required: false, default: false}
    },
    methods: { 
        returnCssClass(){
            let _provv = ''
            if(this.label === '') {
                _provv = "empty-button "
            }
            if (this.type === ButtonTypes.SECONDARY && this.disabled) {
                return _provv + 'disabled disabled-secondary'
            } else if (this.disabled) {
                return _provv + 'disabled'
            } else if (this.type === ButtonTypes.SECONDARY) {
                return _provv + 'secondary-internal'
            } else if (this.type === ButtonTypes.DARK) {
                return _provv + 'dark'
            }
            else { return _provv}
        }
    },
    emits: ['click'],
})
