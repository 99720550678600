
/* eslint-disable */
import { useStore } from "@/store/store";
import {
    useRouter,
    useRoute,
} from "vue-router";
import { useI18n } from "vue-i18n";
import { 
    computed, 
    defineComponent, 
    onMounted, 
    onUnmounted,
    ref,
    nextTick,
    watch,
} from "vue";
import {
    uppercase,
    upperCaseFirstLetter,
} from "@/services/mapping.service";
import { PadelClub } from "@/models/club.models";
import { PadelPlayground } from "@/models/playground.models";
import { ActionTypes } from "@/store/action-types";
import moment from "moment";
import { BookingType } from "@/constants/booking.enum";


export default defineComponent({
    components: {
    },
    props: {
        user: {
            type: Object,
            required: true,
            default: () => {
                return {};
            },
        },
    },
    emits: ['onClick'],
    setup(props, {emit}) {
        const route         = useRoute()
        const router        = useRouter();
        const { t, locale } = useI18n();
        const store         = useStore();
        const scheduled     = ref([]);
        const selectedTab   = ref(0);

        // COMPUTED
        const myClub = computed(function(): PadelClub {
            return store.getters.getMyClub;
        });
        const myPlaygrounds = computed(function(): PadelPlayground[] {
            return store.getters.getMyPlaygrounds;
        });

        // SYSTEM
        onMounted(() => {
            nextTick(() => {
                getScheduled();
            })
        });

        // METHODS
        async function getScheduled() {
            try {
                const params = {
                    club_id: myClub.value.club_id,
                    user_id: props.user.id,
                };
                switch (selectedTab.value) {
                    case 0:
                        params['future'] = true;
                        break;
                    case 1:
                        params['archived'] = true;
                        break;
                    case 2:
                    default:
                        params['deleted'] = true;
                        break;
                }
                // deleted = true
                const response = await store.dispatch(
                    ActionTypes.GET_CALENDAR_EVENTS, 
                    params
                );
                response.map((el) => {
                    el.disabled = (el.booking_state == 'CANCELLED' || !myPlaygrounds.value.find(p => p.id === el.playground_id));
                    return el;
                })
                scheduled.value = (response || []).sort((a, b) => {
                    if (a.start_at < b.start_at) return -1;
                    if (b.start_at < a.start_at) return 1;
                    return 0;
                });
                if (selectedTab.value == 1) {
                    scheduled.value.reverse();
                }
            } catch (e) {
                console.error(e);
            }
        }

        function onClickEvent(event: any) {
            emit('onClick', event);
        }

        function onChangeTab(event) {
            selectedTab.value = event;
            getScheduled();
        }

        function bookingType(item) {
            let output = `${t(item.type)}`;
            if (item.bundle_id) output += `- ${t('bundle')}`;
            return output;
        }

        function playgroundById(id) {
            const playground = myPlaygrounds.value.find(el => el.id === id);
            return playground ? `${playground.sport} - ${playground.name}` : 'n/a';
        }

        function formatDate(item) {
            // INIT
            const startDate = moment(item.start_at).format("DD/MM/YYYY");
            const endDate   = moment(item.end_at).format("DD/MM/YYYY");

            // CHECK
            if (startDate != endDate) return `${startDate} - ${endDate}`;
            return `${startDate}`
        }

        function onlineBooking(item) {
            return item.type === BookingType.BOOKING ? t('yes') : t('no');
        }

        function numberToEuro(value) {
            return (value / 100).toFixed(2);
        }

        // WATCH
        watch(() => props.user, function() {
            getScheduled();
        }, { deep: true });

        return {
            t, 
            locale, 
            uppercase,
            upperCaseFirstLetter,
            moment,

            // DATA
            myClub,
            myPlaygrounds,
            scheduled,
            selectedTab,

            // FUNCTIONS
            onClickEvent,
            bookingType,
            playgroundById,
            onChangeTab,
            formatDate,
            onlineBooking,
            numberToEuro,
        };
    },
});
