<div class="main-container">
    <div class="filter-row">
        <div v-if="creationRequests.length > 0 || activeFilters && Object.values(activeFilters).length > 0" class="filter-container" @click="openFilterModal()">
            <p class="button-text color-blue">{{uppercase(t('filters'))}}</p>
            <i class="material-icons color-blue">filter_alt</i>
        </div>
    </div>    
    <div v-if="activeFilters && Object.values(activeFilters).length > 0" class="chip-container">
        <template v-for="chip in transformObjIntoArray(activeFilters)">
            <Chip class="chip-table">
                <div class="chip-content">
                    <span class="chip-text">{{uppercase(t(formatValue(chip.value)))}}</span><span
                        class="material-icons icon-close-chip" @click="removeFilter(chip)">
                        close
                    </span>
                </div>
            </Chip>
        </template>
    </div>
    <div v-if="creationRequests.length > 0">
        <List :values="creationRequests" @loadMoreEl="loadMoreRequests()" v-if="forceUnmountInfiniteScroll"
            @row-clicked="rowClicked($event)">
            <template v-slot:header>
                <th>{{t('club_name')}}</th>
                <th>{{t('address_only')}}</th>
                <th>{{t('request_date')}}</th>
                <th>{{t('manager_mail')}}</th>
                <th>{{t('request_state')}}</th>
                <th></th>
            </template>
            <template v-slot:tile="slotProps">
                <td>{{ slotProps.value.club_name }}</td>
                <td>{{ slotProps.value.club_address }}</td>
                <td>{{ slotProps.value.date }}</td>
                <td>{{ slotProps.value.requester_email }}</td>
                <td>
                    <Chip class="chip" :class="returnChipClass(slotProps.value.status)"
                        :label="uppercase(t(slotProps.value.status))" />
                </td>
                <td>
                    <div class="button-container">
                        <span class="span-button" @click.stop="openConfirmModal(slotProps.value)"><i
                                class="material-icons color-blue cursor-pointer">check_circle_outline</i></span>
                        <span v-if="slotProps.value.status=== 'PENDING'" class="span-button button-block"
                            @click.stop="openDeleteModal(slotProps.value)"><i
                                class="material-icons color-blue cursor-pointer">block</i></span>
                    </div>
                </td>

            </template>
        </List>

    </div>
    <div class="no-records-text" v-else>{{t('no_records')}}</div>
    <div v-if="modalToShow === 'CONFIRM'">
        <DumbModal :title="t('confirm_club')" :isVisible="isVisible" @hide="hideModal()">
            <div class="modal-top-part">
                <p class="confirm-text">{{t('with_this_operation_confirm')}} <b>{{selectedClubRequest.clubName}}</b>
                    {{t('enter_wesmash')}}
                    {{t('proceed_registration')}}.</p>
                <p class="confirm-text question">{{t('wanna_confirm')}}</p>
            </div>

            <div class="info-buttons-container display-flex space-between">
                <p class="abort" @click="hideModal()">{{uppercase(t('abort'))}}</p>
                <PadelButton :label="'ACCETTA'" :type="'primary'" @click="confirmClub(selectedClubRequest.club_id)">
                </PadelButton>
            </div>
        </DumbModal>
    </div>
    <div v-else-if="modalToShow === 'REJECT'">
        <DumbModal :title="t('reject_club')" :isVisible="isVisible" @hide="hideModal()">
            <div class="modal-top-part">
                <p class="confirm-text">{{t('with_this_operation_reject')}} <b>{{selectedClubRequest.clubName}}</b>
                    {{t('enter_wesmash')}}.</p>
                <p class="confirm-text question">{{t('wanna_reject')}}</p>
            </div>

            <div class="info-buttons-container display-flex space-between">
                <p class="abort" @click="hideModal()">{{uppercase(t('abort'))}}</p>
                <PadelButton :label="'RIFIUTA'" :type="'primary'" @click="rejectClub(selectedClubRequest.club_id)">
                </PadelButton>
            </div>
        </DumbModal>
    </div>

    <div v-else-if="modalToShow === 'CONFIRMED_OK'">
        <DumbModal :title="t('confirm_club')" :isVisible="isVisible" @hide="hideModal()">
            <div class="modal-top-part">
                <span class="material-icons confirm-icon">check_circle_outline</span>
                <p class="confirm-text">{{t('subscription_confirmed')}}</p>
                <p class="confirm-text question">{{t('manager_of')}} <b>{{selectedClubRequest.clubName}}</b>
                    {{t('will_receive_mail')}}</p>
            </div>

            <p class="abort margin-top-40 center-button" @click="hideModal()">{{uppercase(t('return_to_dashboard'))}}
            </p>

        </DumbModal>
    </div>
    <div v-else-if="modalToShow === 'REJECTED_OK'">
        <DumbModal :title="t('reject_club')" :isVisible="isVisible" @hide="hideModal()">
            <div class="modal-top-part">
                <span class="material-icons reject-icon">block</span>
                <p class="confirm-text">{{t('subscription_rejected')}}</p>
            </div>

            <p class="abort margin-top-40 center-button" @click="hideModal()">{{uppercase(t('return_to_dashboard'))}}
            </p>

        </DumbModal>
    </div>
    <div v-else-if="detailOpened">
        <DumbModal :title="t('iscription_request') + ' ' + selectedClubRequest.clubName" :isVisible="isVisible"
            @hide="hideModal()">
            <div class="info-text-container">
                {{t('request_info_text')}}
            </div>
            <div class="info-form-container">
                <template v-for="(element, name, index) in selectedClubRequest">
                    <div v-if="name !== 'club_id' && name !== 'requester_id'" class="text-container flex-50"
                        :class="[index % 2 === 0 ? 'fix-width-right' : 'fix-width-left']">
                        <PadelInput :label="t(name)" :value="element" :disabled="true"></PadelInput>
                    </div>
                </template>
            </div>
            <div class="info-buttons-container display-flex justify-space-between">
                <div class="left-side display-flex">
                    <p class="abort" @click="hideModal()">{{uppercase(t('back'))}}</p>
                </div>
                <div class="right-side display-flex">
                    <PadelButton :label="'RIFIUTA'" :type="'secondary'" @click="rejectClub(selectedClubRequest.club_id)"
                        class="margin-right-30">
                    </PadelButton>
                    <PadelButton :label="'ACCETTA'" :type="'primary'" @click="confirmClub(selectedClubRequest.club_id)">
                    </PadelButton>
                </div>
            </div>
        </DumbModal>
    </div>
    <div v-else-if="modalToShow === 'FILTER'">
        <DumbModal :title="t('filter_club_requests')" :isVisible="isVisible" @hide="hideModal()">
            <div class="flexbox-row filter-modal-top-part">
                <div class="radio-container">
                    <p class="color-white montserrat">{{t('request_state')}}</p>
                    <PadelRadio :value="filtersForm.status" :options="radioOptions" :chipMode="true"
                        @input="setFilterFormValue('status', $event)" />
                </div>
                <div class="calendar-container">
                        <PadelCalendar class="calendar" @input="setFilterFormValue('date', $event)" />                  
                </div>
            </div>
            <div class="filter-buttons-container flexbox-row">
                <p class="abort center-button" @click="abortFilters()">{{uppercase(t('abort'))}}</p>
                <PadelButton :label="'APPLICA'" :type="'primary'" @click="applyFilters()"></PadelButton>
            </div>

        </DumbModal>
    </div>
</div>