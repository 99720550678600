<!-- OVERLAY PREVIEW IMAGE -->
<div v-if="showPreview" class="overlay-image">
    <div class="inner-pic-div">
        <img :src="temporaryPic.src" class="image" />
        <span class="material-icons icon-close" @click="closePreview()">
            clear
        </span>
    </div>
</div>

<div class="view-main-container flexbox-column justify-space-between height-100">
    <div class="flexbox-column justify-space-between">
        <div class="nav-container flexbox-row justify-space-between padding-bottom-20 padding-top-20">
            <div class="logo-box">
                <img class="logo-img" src="@/theme/imgs/logo_completo.svg" alt="">
            </div>
            <Nav :dropdownChoices="dropdownChoices" />
        </div>
        <div class="top-section">
            <div class="title-container flexbox-column">
                <h3 class="step-text color-gold barlow margin-bottom-0">{{t('step_1')}}</h3>
                <p class="title-description color-white barlow margin-top-10">{{t('insert_club_info')}}</p>
            </div>
            <div class="form-container flexbox-column margin-top-20">
                <div class="form-row flexbox-row">
                    <div class="flex-item">
                        <PadelInput :label="t('clubName')" :value="myClub.name" :disabled="true" :icon="'lock'" />
                    </div>
                    <div class="flex-item flex-center-item">
                        <PadelInput :label="t('clubAddress')" :value="myClub.location.address" :disabled="true"
                            :icon="'lock'" />
                    </div>
                    <div class="flex-item">
                        <PadelButton v-if="!addClubForm.profilePicture" type="secondary"
                            :label="uppercase(t('add_picture'))" :icon="'add_a_photo'"
                            @click="openInsertPhotoModal()" />
                        <PadelButton v-else type="secondary-white" :label="uppercase(t('picture_added'))"
                            :icon="'check'" @click="openInsertPhotoModal()" />
                    </div>
                </div>
                <div class="form-row flexbox-row">
                    <div class="flex-item">
                        <PadelInput :label="t('club_phonenumber')" :value="addClubForm.phoneNumber"
                            :error="addClubFormErrors.phoneNumber" @validate="validateForm('phoneNumber')"
                            :isPhonenumber="true" @input="updateFormValue($event, 'phoneNumber')" />
                    </div>
                    <div class="flex-item flex-center-item">
                        <PadelInput :label="t('club_email')" :value="addClubForm.email"
                            :error="addClubFormErrors.email" @validate="validateForm('email')"
                             @input="updateFormValue($event, 'email')" />
                    </div>
                    <div class="flex-item">
                        <PadelTimepicker @input="updateFormTimeValue($event, 'openTime')" :value="addClubForm.openTime"
                            :error="addClubFormErrors.openTime" :label="t('opening_hour')" />
                    </div>
                </div>
                <div class="form-row flexbox-row">
                    <div class="flex-item-width">
                        <PadelTimepicker @input="updateFormTimeValue($event, 'closeTime')"
                            :value="addClubForm.closeTime" :error="addClubFormErrors.closeTime"
                            :label="t('closing_hour')" />
                    </div>
                </div>
            </div>
            <div class="services-container">
                <p class="title-description align-initial color-white montserrat">{{t('available_services')}}</p>
                <div class="form-row flexbox-row">
                    <div class="flex-item flexbox-row align-center">
                        <PadelCheckbox v-model:checked="services.LOCKER_ROOM" />
                        <i class="icon icon-locker color-gold margin-left-10 margin-right-10"></i>
                        <p class="color-white montserrat title-description cursor-pointer"
                        :class="formDisabled? 'pointer-none': ''" @click="services.LOCKER_ROOM = !services.LOCKER_ROOM">{{uppercase(t('changing_rooms'))}}</p>
                    </div>
                    <div class="flex-item flex-center-item flexbox-row align-center">
                        <PadelCheckbox v-model:checked="services.SHOWERS" />
                        <i class="icon icon-shower color-gold margin-left-10 margin-right-10"></i>
                        <p class="color-white montserrat title-description cursor-pointer"
                        :class="formDisabled? 'pointer-none': ''" @click="services.SHOWERS = !services.SHOWERS">{{uppercase(t('showers'))}}</p>
                    </div>
                    <div class="flex-item flexbox-row align-center">
                        <PadelCheckbox v-model:checked="services.PARKING" />
                        <i class="icon icon-parking color-gold margin-left-10 margin-right-10"></i>
                        <p class="color-white montserrat title-description cursor-pointer"
                        :class="formDisabled? 'pointer-none': ''" @click="services.PARKING = !services.PARKING">{{uppercase(t('parking'))}}</p>
                    </div>
                </div>
                <div class="form-row flexbox-row">
                    <div class="flex-item flexbox-row align-center">
                        <PadelCheckbox v-model:checked="services.TOURNAMENTS" />
                        <i class="icon icon-cup color-gold margin-left-10 margin-right-10"></i>
                        <p class="color-white montserrat title-description cursor-pointer"
                        :class="formDisabled? 'pointer-none': ''" @click="services.TOURNAMENTS = !services.TOURNAMENTS">{{uppercase(t('tournaments'))}}</p>
                    </div>
                    <div class="flex-item flex-center-item flexbox-row align-center">
                        <PadelCheckbox v-model:checked="services.RENTAL" />
                        <i class="icon icon-rent color-gold margin-left-10 margin-right-10"></i>
                        <p class="color-white montserrat title-description cursor-pointer"
                        :class="formDisabled? 'pointer-none': ''" @click="services.RENTAL = !services.RENTAL">{{uppercase(t('renting'))}}</p>
                    </div>
                    <div class="flex-item flexbox-row align-center">
                        <PadelCheckbox v-model:checked="services.BAR" />
                        <i class="icon icon-bar color-gold margin-left-10 margin-right-10"></i>
                        <p class="color-white montserrat title-description cursor-pointer"
                        :class="formDisabled? 'pointer-none': ''" @click="services.BAR = !services.BAR">{{uppercase(t('bar'))}}</p>
                    </div>
                </div>
                <div class="form-row flexbox-row">
                    <div class="flex-item flexbox-row align-center">
                        <PadelCheckbox v-model:checked="services.RESTAURANT" />
                        <i class="icon icon-restaurant color-gold margin-left-10 margin-right-10"></i>
                        <p class="color-white montserrat title-description cursor-pointer"
                        :class="formDisabled? 'pointer-none': ''" @click="services.RESTAURANT = !services.RESTAURANT">{{uppercase(t('restaurant'))}}</p>
                    </div>
                    <div class="flex-item flex-center-item flexbox-row align-center">
                        <PadelCheckbox v-model:checked="services.SWIMMING_POOL" />
                        <i class="icon icon-swimming-pool color-gold margin-left-10 margin-right-10"></i>
                        <p class="color-white montserrat title-description cursor-pointer"
                        :class="formDisabled? 'pointer-none': ''" @click="services.SWIMMING_POOL = !services.SWIMMING_POOL">{{uppercase(t('swimming_pool'))}}</p>
                    </div>
                    <div class="flex-item flexbox-row align-center">
                        &nbsp;
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom-section margin-bottom-40">
        <PadelButton :label="uppercase(t('forward'))" @click="sendForm()"
            :disabled="isAddFirstClubFormEmpty(addClubForm) || isAddFirstClubFormInvalid(addClubFormErrors)" />
    </div>
</div>

<!-- MODALE INSERISCI FOTO -->
<div v-if="modalToShow === 'INSERT_PHOTO_MODAL'">
    <DumbModal :title="isModalChange? t('club_picture'):insertPhotoTitle" @hide="hideModal()">
        <div class="modal-content flexbox-column justify-space-between">
            <div class="top-part">
                <p v-if="isModalChange" class="color-white montserrat insert_pic_text change_text">
                    {{t('change_picture')}}</p>
                <p v-else class="color-white montserrat insert_pic_text">{{t('be_careful_picture')}}
                    <b>{{t('horizontal')}}</b>, in <b>.jpg</b> o <b>.png </b>{{t('size_more_than')}}<b> 20Mb</b>.
                </p>
                <div class="temporary-container" v-if="temporaryPic">
                    <span v-if="!isModalChange" class="material-icons color-white margin-right-10">check</span>
                    <p class="color-white montserrat insert_pic_text preview-text" @click="openPreview()">
                        {{temporaryPic.name}}</p>
                </div>

                <div v-else-if="!isModalChange" class="margin-top-10">
                    <PadelUpload @change="temporaryUploader($event)" :icon="'add_a_photo'"
                        :label="uppercase(t('add_picture'))" :uploadedLabel="uppercase(t('picture_added'))"
                        @wrong-file-uploaded="handleWrongFileUploaded($event)"></PadelUpload>
                </div>

            </div>
            <div class="buttons-container flexbox-row justify-space-between">
                <p class="abort" @click="hideModal()">{{isModalChange? uppercase(t('back')) :uppercase(t('close'))}}</p>
                <div class="flexbox-row">
                    <div v-if="isModalChange" class="margin-right-30">
                        <PadelUpload @change="temporaryUploader($event)"
                            :label="isModalChange?uppercase(t('change_photo')) : uppercase(t('add_picture'))"
                            :uploadedLabel="uppercase(t('picture_added'))" :border="true"
                            @wrong-file-uploaded="handleWrongFileUploaded($event)"></PadelUpload>
                    </div>
                    <PadelButton :label="uppercase(t('load'))" :disabled="!temporaryPic"
                        @click="confirmUploadPhoto()" />
                </div>
            </div>
        </div>
    </DumbModal>
</div>

<!-- MODALE FOTO MANCANTE -->
<div v-if="modalToShow === 'PHOTO_MISSING_MODAL'">
    <DumbModal :title="insertPhotoTitle" @hide="hideModal()">
        <div class="modal-content flexbox-column justify-space-between">
            <div class="top-part">
                <i class="material-icons icon-alert">warning_amber</i>
                <div class="color-white alert-text montserrat">{{t('no_pic_club_text')}}</div>
            </div>
            <div class="buttons-container flexbox-row justify-space-between">
                <p class="abort" @click="hideModal()">{{uppercase(t('close'))}}</p>
                <div class="flexbox-row">
                    <div class="margin-right-30">
                        <PadelUpload @change="myUploader($event)" :label="uppercase(t('add_picture'))"
                            :uploadedLabel="uppercase(t('picture_added'))"
                            @wrong-file-uploaded="handleWrongFileUploaded($event)">
                        </PadelUpload>
                    </div>
                    <PadelButton :label="uppercase(t('forward'))" @click="addClub()" />
                </div>
            </div>
        </div>
    </DumbModal>
</div>