import { PadelClub, TablePage } from "@/models/club.models";
import { UserFilters } from "@/models/filters.models";
import { ClubForm, LoginForm, LoginFormHandler, PlaygroundForm, SignupForm, SignupFormErrors, SignupFormHandler } from "@/models/forms.models"
import { SchedulerPlayground } from "@/models/playground.models";
import { BookingForm } from "@/models/timeslots.models";
import { AWSSessionTokens } from "@/models/tokens.models";
import { CreateTournamentForm, TournamentDateForm } from "@/models/tournaments.models";
import { BackEndUser, Consents, PadelLocation, PadelManager, PadelUser } from "@/models/user.models";
import { USERS_PAGE_SIZE, FIXED_PHONE_PREFIX } from './constants.constants';
import { PlaygroundSports } from "./playground.enums";


export const emptyLocation: PadelLocation = {
    address: '',
    city: '',
    state: '',
    zip_code: '',
    latitude: null,
    longitude: null
}
export const emptySignupForm: SignupForm = {
    email: '',
    password: '',
    passwordConfirmation: '',
    name: '',
    surname: '',
    clubName: '',
    code: '',
    clubGeoLoc: emptyLocation,
    role: ''
}

export const emptySignupFormErrors: SignupFormErrors = {
    email: '',
    password: '',
    passwordConfirmation: '',
    name: '',
    surname: '',
    clubName: '',
    clubAddress: '',
    clubGeoLoc: '',
    code: ''
}

export const emptyLoginForm: LoginForm = {
    loginEmail: '',
    loginPassword: ''
}

export const emptyLoginFormHandler: LoginFormHandler = {
    errors: emptyLoginForm,
    values: emptyLoginForm
}

export const emptySignupFormHandler: SignupFormHandler = {
    errors: emptySignupFormErrors,
    values: emptySignupForm
}

export const emptyUser: BackEndUser = {
    id: '',
    name: '',
    family_name: '',
    email: '',
    status: '',
    sub: '',
    phone_number: '',
    email_verified: null,
    username: '',
    groups: [],
    alias: '',
    favourite_clubs: null,
    profile_pic_ref: null
}

export const emptyUserAuth = {
    AccessToken: '',
    ExpiresIn: NaN,
    IdToken: '',
    RefreshToken: '',
    TokenType: ''
}

export const emptyAddClubForm: ClubForm = {
    name: '',
    address: '',
    profilePicture: null,
    phoneNumber: '',
    email: '',
    openTime: '',
    closeTime: '',
    services: [],
    club_notes: '',

}

export const emptyAddClubFormError = {
    phoneNumber: '',
    email: '',
    openTime: '',
    closeTime: ''
}

export const emptyServices = {
    LOCKER_ROOM: false,
    SHOWERS: false,
    PARKING: false,
    TOURNAMENTS: false,
    RENTAL: false,
    BAR: false,
    RESTAURANT: false,
    SWIMMING_POOL: false,
}

export const emptyPlaygroundForm: PlaygroundForm = {
    id: '',
    name: '',
    playground_size: null,
    sport: '',
    exposure: '',
    hidden: false,
    profilePicture: null,
    sort_index: 100,
    ground: null,
    walls: null,
}

export const emptySessionTokens: AWSSessionTokens = {
    accessToken: '',
    idToken: '',
    refreshToken: '',
    accessTokenExpiration: null,
    idTokenExpiration: null
}

export const emptyClub: PadelClub = {
    club_id: '',
    club_phone_number: '',
    opening_hour: null,
    closing_hour: null,
    location: emptyLocation,
    name: '',
    services: [],
    status: '',
    email: '',
    force_online_payment: false,
}

export const emptyConfirmSignupUser = {
    username: '',
    password: ''
}

export const emptyAddNewClubForm = {
    clubName: '',
    clubGeoLoc: emptyLocation
}

export const emptyAddNewClubErrors = {
    clubName: '',
    clubGeoLoc: ''
}

export const emptyPageTable: TablePage = {
    values: []
}

export const changeInfoEmpty = {
    name: "",
    surname: "",
    phonenumber: ""
}

export const emptyCalendarFilterArray = [{ type: 'playground', value: null }, { type: 'timeslot', value: null }]

export const emptyUserFilter: UserFilters = {
    banned: null,
    min_bookings: null,
    min_cancellations: null
}

export const emptyConsents: Consents = {
    accepts_commercial_partner: null,
    accepts_commercial_wesmash: null,
    accepts_privacy: null,
    version: ''
}

export const emptyPadelUser: PadelUser = {
    id: '',
    name: '',
    family_name: '',
    email: '',
    phone_number: '',
    level: null,
    bookings_count: null,
    cancellations_count: null,
    banned: null,
    favorite: null,
    gender: null,
    consents: emptyConsents
}

export const emptySchedulerPlayground: SchedulerPlayground = {
    name: '',
    id: '',
    exposure: '',
    playground_size: '',
    playground_image: '',
    half_hour_price: null,
    split: null,
    sport: null,
    ground: null,
    walls: null,
    hidden: false
}

export const emptyNewBookingForm: BookingForm = {
    wesmash_user: emptyPadelUser,
    user_name: '',
    user_last_name: '',
    phone_number_prefix: '39',
    user_phone_number: null,
    user_email: '',
    teacher_name: '',
    date: null,
    playground: emptySchedulerPlayground,
    sport_type: null,
    start_at: null,
    end_at: null,
    start_date: null,
    end_date: null,
    price: null,
    game_duration: null,
    certify_phone_number: false,
    apply_all_playground: false,
    color: null,
    notes: '',
    payment_state: '',
    player_description: '',
    recurring: false,
    bundle_end_at: undefined,
    bundle_id: undefined,
    taking: null,
    match_type: null,
    isPublic: true,
    min_level: 1,
    max_level: 10,
    booking_category: '-'
}

export const emptyNewBookingErrors = {
    wesmash_user: '',
    user_name: '',
    user_last_name: '',
    user_phone_number: '',
    user_email: '',
    teacher_name: '',
    date: '',
    playground: '',
    start_at: '',
    end_at: '',
    start_date: '',
    end_date: '',
    price: '',
    game_duration: '',
    certify_phone_number: '',
    apply_all_playground: '',
    color: '',
    notes: '',
    player_description: '',
    bundle_end_at: '',
    number_of_weeks: '',
    taking: '',
    match_type: '',
    booking_category: ''
}

export const emptyManager: PadelManager = {
    email: '',
    family_name: '',
    id: '',
    name: '',
    phone_number: '',
    status: ''
}

export const emptyCreateTournamentForm = {
    name: '',
    price: null,
    type: '',
    min_couples: null,
    max_couples: null,
    min_level: '',
    max_level: '',
    description: '',
    mode_description: '',
    prizes: '',
    picture: '',
    start_at: null,
    end_at: null,
    registration_end_at: null,
    dates: []
}

export const emptyCreateTournamentFormErrors = {
    name: '',
    price: '',
    type: '',
    min_couples: '',
    max_couples: '',
    min_level: '',
    max_level: '',
    description: '',
    mode_description: '',
    prizes: '',
    picture: '',
    start_at: '',
    end_at: '',
    registration_end_at: '',
    dates: []
}

export const emptyDateTournamentForm: TournamentDateForm = {
    date: null,
    start_at: null,
    end_at: null,
    playground: null
}

export const emptyDateTournamentFormErrors = {
    date: '',
    start_at: '',
    end_at: '',
    playground: ''
}

export const emptyTournament = {
    id: null,
    club_id: null,
    name: null,
    price: null,
    type: null,
    start_at: null,
    end_at: null,
    registration_end_at: null,
    min_couples: null,
    max_couples: null,
    min_level: null,
    max_level: null,
    description: null,
    mode_description: null,
    prizes: null,
    picture_ref: null,
    dates: [],
    teams: []
}

export const emptySlotsForm = {
    start_at: null,
    end_at: null,
    registration_end_at: null
}


export const emptyFirstConflict = {
    date: '',
    playground_id: ''
}

export const emptyDownloadCalendarForm = {
    daily_download: true,
    start_date: undefined
}

export const emptyTimeslotInfo = {
    date: undefined,
    end_at: null,
    id: null,
    player_email: null,
    player_category: null,
    player_family_name: null,
    player_phone_number: null,
    player_name: null,
    playground_id: null,
    playground_name: null,
    price: null,
    taking: null,
    start_at: null,
    title: null,
    type: null,
    payment_state: null,
    sport: null,
    player_description: null,
    event_type: null,
}

export const emptyBookingDetailErrors = {
    type: '',
    name: '',
    family_name: '',
    phone_number: '',
    email: '',
    date: '',
    hour: '',
    hour_end: '',
    playground: '',
    game_duration: '',
    price: '',
    payment_state: '',
    notes: '',
    teacher_name: '',
    player_description: '',
    wesmash_user: '',
    booking_category: ''
}

export const emptyCreatePriceRuleForm = {
    club_id: '',
    name: '',
    start_at: null,
    end_at: null,
    sport_type: null,
    playground_ids: [],
    days: [],
    slots: [],
};

export const emptyCreatePriceRuleFormErrors = {
    name: '',
    start_at: null,
    end_at: null,
    sport_type: null,
    playground_ids: null,
    slots: [],
};

export const emptySlotItem = {
    start_at: null,
    end_at: null,
    duration_60: false,
    duration_90: false,
    duration_120: false,
    show_duration_60: false,
    show_duration_90: false,
    show_duration_120: false,
    price_60: null,
    price_90: null,
    price_120: null,
};

export const emptySlotItemErrors = {
    start_at: null,
    end_at: null,
    duration_60: null,
    duration_90: null,
    duration_120: null,
    price_60: null,
    price_90: null,
    price_120: null,
};

export const emptyExceptionRuleForm = {
    club_id: '',
    name: '',
    exception_at: null,
    sport_type: null,
    playground_ids: [],
    slots: [],
};

export const emptyExceptionRuleFormErrors = {
    name: '',
    exception_at: null,
    sport_type: null,
    playground_ids: null,
    slots: [],
};

export const emptyCreateCardForm = {
    user_id: '',
    card_code: '',
    type: '',
    expiration_date: null,
    club_id: '',
    club_name: '',
    rank: null,
};

export const emptyCreateCardFormErrors = {
    card_code: '',
    type: '',
    expiration_date: null,
    rank: '',
};

export const emptyCreatePlayerType = {
    code: '',
    name: '',
    type: '',
};

export const emptyCreatePlayerTypeErrors = {
    name: '',
    type: '',
};