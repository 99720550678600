export enum ModalTypes {
    LOGIN = 'LOGIN',
    SIGNUP = 'SIGNUP',
    CONFIRM_CODE = 'CONFIRM_CODE',
    USER_CONFIRMED = 'USER_CONFIRMED',
    AUTH_ERROR = 'AUTH_ERROR'
}

export enum AdminModalTypes {
    CONFIRM = 'CONFIRM',
    REJECT = 'REJECT',
    CONFIRMED_OK = 'CONFIRMED_OK',
    REJECTED_OK = 'REJECTED_OK',
    FILTER = 'FILTER'
}