
import { computed, defineComponent, onMounted, ref } from "vue";
import {
  constructPlaygroundMap,
  transformPlaygroundsToOptions,
  uppercase,
} from "@/services/mapping.service";
import { useI18n } from "vue-i18n";
import { formatDateTournament } from "@/services/dates.service";
import { useStore } from "@/store/store";
import { PlaygroundSports, SizesOptions } from "@/constants/playground.enums";
import { PadelClub } from "@/models/club.models";
import { PadelPlayground } from "@/models/playground.models";
import { ActionTypes } from "@/store/action-types";
import { Tournament } from "@/models/tournaments.models";
import { TournamentStatus } from "@/constants/tournaments.enums";
export default defineComponent({
  setup() {
    const store = useStore();
    const { t, locale } = useI18n();
    const tournaments = ref([]);
    const archivedTournaments = ref([]);
    const selectedView = ref("FIRST_PAGE");
    const forceResetAddTournament = ref(0);
    const forceInitializeAddTournament = ref(0);
    const showTemplate = ref(false);
    const openedModal = ref(null);
    const tournamentToDelete = ref(null);
    const tournamentToModify = ref(null);
    const tournamentInitializer = ref(null);

    /* COMPUTED */
    const myClub = computed(function(): PadelClub {
      return store.getters.getMyClub;
    });

    const playgroundMapFull = computed(function(): PadelClub {
      return store.getters.getPlaygroundMapFull;
    });

    function rankTournaments(tourn: Tournament[]) {
      tourn.forEach((el) => {
        if (
          el.end_at < new Date().getTime() ||
          (el.status && el.status === TournamentStatus.CANCELLED)
        ) {
          archivedTournaments.value.push(el);
        } else {
          tournaments.value.push(el);
        }
      });
    }

    function resetTournamentsArray() {
      tournaments.value = [];
      archivedTournaments.value = [];
    }

    async function getMyTournaments() {
      try {
        const _tour = await store.dispatch(
          ActionTypes.GET_TOURNAMENTS,
          myClub.value.club_id
        );
        showTemplate.value = true;
        resetTournamentsArray();
        rankTournaments(_tour);
      } catch (error) {
        showTemplate.value = true;
        return;
      }
    }

    onMounted(() => {
      getMyTournaments();
    });

    function initializeCalendarBoundary(millis: number) {
      return millis / (1000 * 60 * 60);
    }

    const openTime = computed(function(): number {
      return initializeCalendarBoundary(myClub.value.opening_hour);
    });

    const closingTime = computed(function(): number {
      return initializeCalendarBoundary(myClub.value.closing_hour);
    });

    /* COMPUTED */
    const myPlaygrounds = computed(function(): PadelPlayground[] {
      return store.getters.getMyPlaygrounds;
    });

    const playgroundOptions = computed(function() {
      return transformPlaygroundsToOptions(myPlaygrounds.value, [PlaygroundSports.PADEL]);
    });

    const playgroundMap = computed(function() {
      return constructPlaygroundMap(myPlaygrounds.value);
    });

    function changeView(view: string) {
      selectedView.value = view;
    }

    async function goToFirstPage() {
      await getMyTournaments();
      changeView("FIRST_PAGE");
      tournamentInitializer.value = null;
      forceResetAddTournament.value++;
    }

    function copyTournament(tournament) {
      tournamentInitializer.value = tournament;
      forceInitializeAddTournament.value++;
      changeView("ADD_TOURNAMENT_FIRST");
    }

    function modifyTournament(tournament) {
      changeView("MODIFY_TOURNAMENT");
      tournamentToModify.value = tournament;
    }

    function initialize() {
      tournamentToDelete.value = null;
      tournamentToModify.value = null;
    }

    function hideModal() {
      openedModal.value = null;
      initialize();
    }

    function openModal(modal: string) {
      openedModal.value = modal;
    }

    async function deleteTournament() {
      try {
        const res = await store.dispatch(ActionTypes.DELETE_TOURNAMENT, {
          club_id: myClub.value.club_id,
          tournament_id: tournamentToDelete.value.id,
        });
        hideModal();
        getMyTournaments();
      } catch (err) {
        return;
      }
    }

    function handleDeleteTournament(tournament: Tournament) {
      openModal("DELETE_TOURNAMENT");
      tournamentToDelete.value = tournament;
    }

    function returnStatus(status?: string) {
      if (status === TournamentStatus.IN_PROGRESS) {
        return 'FINISHED'
      } else {
        return status;
      }
    }

    function returnChipClass(category: string) {
      if (category === 'FINISHED') {
        return "background-green";
      } else if (category === TournamentStatus.CANCELLED) {
        return "background-red";
      } else {
        return "";
      }
    }

    function onTournamentChanged(event) {
      getMyTournaments();
      tournamentToModify.value = event;
    }

    return {
      t,
      locale,
      uppercase,
      changeView,
      goToFirstPage,
      showTemplate,
      formatDateTournament,
      copyTournament,
      modifyTournament,
      deleteTournament,
      handleDeleteTournament,
      onTournamentChanged,

      selectedView,
      SizesOptions,
      myClub,
      openTime,
      closingTime,
      playgroundOptions,
      playgroundMap,
      playgroundMapFull,
      forceResetAddTournament,
      forceInitializeAddTournament,
      tournaments,
      archivedTournaments,
      openedModal,
      hideModal,
      returnStatus,
      returnChipClass,
      tournamentInitializer,
      tournamentToDelete,
      tournamentToModify,
      getMyTournaments,
    };
  },
});
