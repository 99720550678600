<div class="main-container-timepicker"
    :class="containerClass">
    <AutoComplete 
        ref="autocomplete"
        :class="isError" 
        forceSelection 
        v-model="selectedHour" 
        :suggestions="hourSuggestionFiltered" 
        @complete="searchHour($event.query)" 
        :dropdown="true" 
        @item-select="emitItem($event.value)"
        @dropdown-click="changeClass($event)" 
        @click="changeClass('click')"
        class="timepicker"
     />
     <label :class="get__color">{{label}}</label>
     <div class="color__red error-span">{{error}}</div>
</div>


