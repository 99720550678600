import {
    CardTypes,
    CardLabels,
    CardCategories,
} from './card-types.enum';

export const signupButtons = ['name', 'surname', 'email', 'password', 'passwordConfirmation']

export const sideNavButtons = [
    {
        nav: 'Scheduler',
        label: 'bookings',
        icon: 'calendar_today'
    }, {
        nav: 'Users',
        label: 'users',
        icon: 'people'
    },
    {
        nav: 'MyClub',
        label: 'my_club',
        icon: 'sports_baseball'
    }, {
        nav: 'Stats',
        label: 'stats',
        icon: 'insert_chart_outlined'
    },
    {
        nav: 'Tournaments',
        label: 'tournaments',
        icon: 'emoji_events'
    },
    {
        nav: 'open-bookings',
        label: 'partite',
        icon: 'sports_tennis'
    },
    {
        nav: 'payments',
        label: 'pagamenti',
        icon: 'payments'
    }
]

export const initialSignupForm = {
    email: '',
    password: '',
    passwordConfirmation: '',
    name: '',
    surname: '',
    phonenumber: '',
    clubName: '',
    code: '',
    clubAddress: ''
}

export const WEEKDAYS_SLOTS = {
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    SUNDAY: 0,
};

export const OTP_WAIT_TIME_IN_SECONDS = 10;

export const REQUESTS_PAGE_SIZE = 100;

export const USERS_PAGE_SIZE = 15;

export const CALENDAR_USERS_PAGE_SIZE = 10000;

export const FIXED_PHONE_PREFIX = '+39';

export const DEFAULT_GAME_DURATION = 90;

export const CardTypeOptions = [
    { value: CardTypes.FIT, label: CardLabels.FIT },
    { value: CardTypes.CSAIN, label: CardLabels.CSAIN },
    { value: CardTypes.CSEN, label: CardLabels.CSEN },
    { value: CardTypes.CSI, label: CardLabels.CSI },
    { value: CardTypes.CUSI, label: CardLabels.CUSI },
    { value: CardTypes.ENDAS, label: CardLabels.ENDAS },
    { value: CardTypes.MSP, label: CardLabels.MSP },
    { value: CardTypes.PGS, label: CardLabels.PGS },
    { value: CardTypes.ACSI, label: CardLabels.ACSI },
    { value: CardTypes.UISP, label: CardLabels.UISP },
    { value: CardTypes.USACLI, label: CardLabels.USACLI },
    { value: CardTypes.ASC, label: CardLabels.ASC },
    { value: CardTypes.CNS_LIBERTAS, label: CardLabels.CNS_LIBERTAS },
    { value: CardTypes.OPES, label: CardLabels.OPES },
];

export const PlayerCategoriesOptions = [
    { value: '-', label: '-' },
    { value: CardCategories.COMMERCIAL, label: 'Commerciale' },
    { value: CardCategories.INSTITUTIONAL, label: 'Istituzionale' },
];

export const ClubCategoriesOptions = [
    { value: CardCategories.INSTITUTIONAL, label: 'Associazione sportiva' },
    { value: CardCategories.COMMERCIAL, label: 'Altra natura' },
];

export const ForewarningOptions = [
    { value: 0, label: '0 minuti' },
    { value: 15, label: '15 minuti' },
    { value: 30, label: '30 minuti' },
    { value: 60, label: '1 ora' },
    { value: 120, label: '2 ore' },
    { value: 180, label: '3 ore' },
    { value: 240, label: '4 ore' },
];

export const Levels = [
    { value: 1, label: 'Principiante', short: 'Princ.' },
    { value: 2, label: 'Intermedio', short: 'Int.' },
    { value: 3, label: 'Intermedio alto', short: 'Int. alto' },
    { value: 4, label: 'Avanzato', short: 'Avanz.' },
    { value: 5, label: 'Competizione', short: 'Compet.' },
]

export enum BookingState {
    OPEN = 'OPEN',
    CONFIRMED = 'CONFIRMED'
}