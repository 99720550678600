import { emptyUser } from '@/constants/initial-values.constants'
import { PadelClub, TablePage } from '@/models/club.models'
import { AWSSessionTokens } from '@/models/tokens.models'
import { 
  BackEndUser, 
  //User
} from '@/models/user.models'
import { MutationTree } from 'vuex'
import { MutationTypes } from './mutation-types'
//import { CognitoUser } from 'amazon-cognito-identity-js';
import { COPY, State } from './state'
import { PadelPlayground } from '@/models/playground.models'

export type Mutations<S = State> = {
  [MutationTypes.RESET_STORE](state: S): void;
  [MutationTypes.TOGGLE_LOADER](state: S): void;
  [MutationTypes.OPEN_LOADER](state: S): void;
  [MutationTypes.CLOSE_LOADER](state: S): void;
  [MutationTypes.SET_USER](state: S, payload: BackEndUser): void;
  [MutationTypes.REMOVE_USER](state: S): void;
  [MutationTypes.WRITE_CONFIRM_SIGNUP_USER](state: S, payload): void;
  [MutationTypes.WRITE_TOKENS](state: S, payload: AWSSessionTokens): void;
  [MutationTypes.WRITE_CLUB_REQUESTS](state: S, requests: TablePage): void;
  [MutationTypes.ADD_CLUB_REQUESTS](state: S, requests: TablePage): void;
  [MutationTypes.SET_SHOW_SUCCESS](state: S, payload: string): void;
  [MutationTypes.SET_SHOW_ERROR](state: S, payload: string): void;
  [MutationTypes.WRITE_MY_CLUBS](state: S, payload: PadelClub[]): void;
  [MutationTypes.CLEAR_MY_CLUBS](state: S): void;
  [MutationTypes.WRITE_LOGGED_CLUB](state: S, payload: PadelClub): void;
  [MutationTypes.CLEAR_LOGGED_CLUB](state: S): void;
  [MutationTypes.WRITE_ALLOW_DOWNLOAD](state: S, payload: boolean): void;
  [MutationTypes.WRITE_MY_PLAYGROUNDS](state: S, payload: PadelPlayground[]): void;
  [MutationTypes.WRITE_ADMIN_CLUBS](state: S, payload: PadelClub[]): void;
  [MutationTypes.SET_FORCED_LOGOUT](state: S, payload: boolean): void;
  [MutationTypes.RESET_LAST_EVALUATED_KEY](state: S): void;
  [MutationTypes.SET_CURRENT_DATE](state: S, payload: Date): void;
  [MutationTypes.RESET_CURRENT_DATE](state: S): void;
  [MutationTypes.SET_PRICE_RULE](state: S, payload): void;
  [MutationTypes.SET_EXCEPTION_RULE](state: S, payload): void;
  [MutationTypes.SET_BUNDLE_RESPONSE](state: S, payload): void;
  [MutationTypes.SET_SCROLL_EVENT](state: S, payload): void;
  [MutationTypes.SET_SEARCH_USER](state: S, payload): void;
  [MutationTypes.SET_USER_CARDS](state: S, payload): void;
}

export const mutations: MutationTree<State> & Mutations = {
  [MutationTypes.RESET_STORE](state) {
    const copyState = JSON.parse(COPY);
      Object.keys(state).forEach((key) => delete state[key])
      Object.assign(state, copyState);    
  },
  [MutationTypes.TOGGLE_LOADER](state) {
    state.loader = !state.loader
  },
  [MutationTypes.OPEN_LOADER](state) {
    state.loader = true
  },
  [MutationTypes.CLOSE_LOADER](state) {
    state.loader = false
  },
  [MutationTypes.SET_USER](state, payload) {
    state.user = {...payload}
  },
  [MutationTypes.REMOVE_USER](state) {
    state.user = {...emptyUser}
  },
  [MutationTypes.WRITE_CONFIRM_SIGNUP_USER](state, payload) {
    state.confirmSignupUser = payload;
  },
  [MutationTypes.WRITE_TOKENS](state, payload) {
    state.sessionTokens = {...payload};
  },
  [MutationTypes.WRITE_CLUB_REQUESTS](state, requests) {
    state.joinWesmashRequests = {...requests};
  },
  [MutationTypes.ADD_CLUB_REQUESTS](state, requests) {
    state.joinWesmashRequests = {
      ...state.joinWesmashRequests,
      values: state.joinWesmashRequests.values.concat(requests.values),
      last_evaluated_key: requests.last_evaluated_key
    }
  },
  [MutationTypes.RESET_LAST_EVALUATED_KEY](state) {
    state.joinWesmashRequests = {...state.joinWesmashRequests,
      last_evaluated_key: undefined
    };
  },
  [MutationTypes.SET_SHOW_SUCCESS](state, payload) {
    state.showSuccessToast = payload;
  },
  [MutationTypes.SET_SHOW_ERROR](state, payload) {
    state.showErrorToast = payload;
  },
  [MutationTypes.WRITE_MY_CLUBS](state, payload) {
    state.myClubs = payload;
  },
  [MutationTypes.CLEAR_MY_CLUBS](state) {
    state.myClubs = [];
  },
  [MutationTypes.WRITE_LOGGED_CLUB](state, payload) {
    state.myClub = payload.club_id;
  },
  [MutationTypes.CLEAR_LOGGED_CLUB](state) {
    state.myClub = null;
  },
  [MutationTypes.WRITE_ALLOW_DOWNLOAD](state, payload) {
    state.allowDownloadContract = payload;
  },
  [MutationTypes.WRITE_MY_PLAYGROUNDS](state, payload) {
    state.myPlaygrounds = payload;
  },
  [MutationTypes.WRITE_ADMIN_CLUBS](state, payload) {
    state.adminClubs = [...payload];
  },
  [MutationTypes.SET_FORCED_LOGOUT](state, payload) {
    state.forcedLogout = payload;
  },
  [MutationTypes.SET_CURRENT_DATE](state, payload) {
    state.currentDate = payload;
  },
  [MutationTypes.RESET_CURRENT_DATE](state) {
    state.currentDate = undefined;
  },
  [MutationTypes.SET_PRICE_RULE](state, payload) {
    state.priceRule = payload;
  },
  [MutationTypes.SET_EXCEPTION_RULE](state, payload) {
    state.exceptionRule = payload;
  },
  [MutationTypes.SET_BUNDLE_RESPONSE](state, payload) {
    state.bundleResponse = payload;
  },
  [MutationTypes.SET_SCROLL_EVENT](state, payload) {
    state.scrollEvent = payload;
  },
  [MutationTypes.SET_SEARCH_USER](state, payload) {
    state.searchUser = payload;
  },
  [MutationTypes.SET_USER_CARDS](state, payload) {
    state.userCards = payload;
  },
}