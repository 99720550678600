<DumbModal :title="t('booking_detail')" @hide="hideModal()" :modal="false"
    :minimizable="true"
    :breakpoints="{'720px': '90vw', '640px': '95vw'}"
    :style="{width: '80vw'}">
    <div v-if="bookingInfoForm.type === 'BOOKING' || bookingInfoForm.type === 'BACKOFFICE_BOOKING' ||
    bookingInfoForm.type === 'LESSON' || bookingInfoForm.type === 'BACKOFFICE_LESSON'">
        <TabView 
            class="tab-view p-mb-5" 
            :activeIndex="selectedTab" 
            @tab-change="onChangeTab($event.index)">
            <TabPanel :disabled="formDisabled">
                <template #header>
                    <i class="material-icons" :class="{'color-blue': selectedTab === 0}">how_to_reg</i>
                    <span>{{uppercase(t('registered_user'))}}</span>
                </template>
                <div>
                </div>
            </TabPanel>
            <TabPanel :disabled="formDisabled">
                <template #header>
                    <i class="material-icons" :class="{'color-blue': selectedTab === 1}">person_add</i>
                    <span>{{uppercase(t('user not registered'))}}</span>
                </template>
                <div>
                </div>
            </TabPanel>
            <TabPanel :disabled="formDisabled">
                <template #header>
                    <i class="material-icons" :class="{'color-blue': selectedTab === 2}">description</i>
                    <span>{{uppercase(t('description'))}}</span>
                </template>
                <div>
                </div>
            </TabPanel>
        </TabView>

        <div class="form-container">
            <PadelSearch 
                class="search-element" 
                :label="t('search_user')" 
                :value="searchPlaceholder"
                :disabled="formDisabled" 
                @onEnterPressed="searchUsers($event)" 
                :options="searchOptions"
                @onClosePressed="handleSearchClose()" 
                :error="bookingInfoErrors.wesmash_user"
                @valueClicked="searchValueSelected($event)" 
                @validate="onSearchValidate($event)"
                v-if="selectedTab === 0" />
            <PadelInput 
                :label="t('player_description')" 
                @input="updateFormValue($event, 'player_description')"
                :value="bookingInfoForm.player_description" 
                :disabled="formDisabled" 
                :icon="formDisabled ? 'lock' :''"
                containerClass='grid-full'
                v-if="selectedTab === 2" />
            <PadelInput 
                :label="t('player_name')" 
                @input="updateFormValue($event, 'name')"
                :value="bookingInfoForm.name" 
                :disabled="formDisabled"  
                :icon="formDisabled ? 'lock' :''"
                v-if="selectedTab === 1" />
            <PadelInput 
                :label="t('player_family_name')" 
                @input="updateFormValue($event, 'family_name')"
                :value="bookingInfoForm.family_name" 
                :disabled="formDisabled"  
                :icon="formDisabled ? 'lock' :''"
                v-if="selectedTab === 1" />
            <PadelInput 
                :label="t('phonenumber')" 
                @input="updateFormValue($event, 'phone_number')"
                :value="bookingInfoForm.phone_number" 
                :disabled="formDisabled"  
                :icon="formDisabled ? 'lock' :''"
                v-if="selectedTab === 1" />
            <PadelInput 
                :label="t('email')" 
                @input="updateFormValue($event, 'email')"
                :value="bookingInfoForm.email" 
                :disabled="formDisabled"  
                :icon="formDisabled ? 'lock' :''"
                v-if="selectedTab === 1" />
            <PadelCalendar :label="t('game_date')" @input="validateHourToday($event, 'date')" class="calendar-element"
                :value="bookingInfoForm.date" :disabled="formDisabled" :minDate="new Date()"
                :error="bookingInfoErrors.date" />
            <PadelTimepicker :label="t('from')" :from="openingHour" :to="closingHour" :value="bookingInfoForm.start_at"
                :key="componentKey" @input="validateHourToday($event, 'start_at')" :disabled="formDisabled"
                :error="bookingInfoErrors.start_at" />
            
            <div class="sport-playground-container p-my-1">
                <PadelDropdown :label="t('selected_sport')" 
                    :options="mySports"
                    @input="updateFormValue($event.value, 'sport_type')" 
                    :default="bookingInfoForm.sport_type"
                    :disabled="formDisabled" 
                    :key="componentKey" />
                <PadelDropdown :label="t('selected_playground')" 
                    :options="computedPlaygrounds"
                    @input="updateFormValue($event.value, 'playground')" 
                    :default="bookingInfoForm.playground"
                    :disabled="formDisabled" 
                    :key="componentKey" />
            </div>
            <div class="radio-container flexbox-column p-my-2">
                <div class="flexbox-row p-mb-3">
                    <PadelToggle 
                        v-model:checked="withEndTime"
                        @change="switchEndTime($event)"
                        containerClass="p-d-inline-flex p-ml-2"
                        labelClass="color-white overflow-inherit p-size-mini"
                        :disabled="formDisabled"
                        :label="t('end_hour')" />
                </div>
                <div class="adjust-radio-spacing" v-if="!withEndTime">
                    <PadelRadio :options="formDisabled ? gameDurations : gameDurationOptions" 
                        :value="bookingInfoForm.game_duration"
                        @input="validateHourToday($event, 'game_duration')" 
                        :chipMode="true"  />
                </div>
                <PadelTimepicker 
                    v-else
                    :label="t('end_hour')"
                    :value="bookingInfoForm.hour_end"
                    @input="validateHourToday($event, 'hour_end')" 
                    :from="openingHour"
                    :to="closingHour" 
                    :error="bookingInfoErrors.hour_end"
                    containerClass="full-width"
                    :disabled="formDisabled" />
            </div>

             <!-- <PadelInput :label="t('price')" 
                :value="bookingInfoForm.price" 
                :disabled="formDisabled"
                :inputType="'number'" 
                :isPrice="true"
                :icon="'euro_symbol'" 
                @input="validateHourToday($event, 'price')" />
            <PadelInput :label="t('taking')" 
                :value="bookingInfoForm.taking" 
                :disabled="formDisabled"
                :inputType="'number'" 
                :isPrice="true"
                :icon="'euro_symbol'" 
                @input="updateFormValue($event, 'taking')" />
            <PadelInput v-if="bookingInfoForm.type === 'LESSON' || bookingInfoForm.type === 'BACKOFFICE_LESSON'"
                @input="updateFormValue($event, 'teacher_name')" :label="t('teacher_name')"
                :value="bookingInfoForm.teacher_name" :disabled="formDisabled" />
            <PadelDropdown :label="t('payment_state')" :options="PaymentOptions" class="payment-dropdown"
                @input="updateFormValue($event.value, 'payment_state')" :default="bookingInfoForm.payment_state"
                 :disabled="formDisabled" />
            <PadelDropdown v-if="is__booking__modifiable" :label="t('color_choice')"
                :options="colorChoices" class="payment-dropdown"
                @input="updateFormValue($event.value, 'color')" :default="bookingInfoForm.color"
                :disabled="formDisabled" />      
             -->


            <div class="payment-container p-my-1">
                <PadelInput :label="t('price')" 
                    :value="bookingInfoForm.price" 
                    :disabled="bookingInfoForm.payment_online || formDisabled"
                    :inputType="'number'" 
                    :isPrice="true"
                    :icon="'euro_symbol'" 
                    @input="validateHourToday($event, 'price')" />
                

                <PadelInput :label="t('taking')" 
                    :value="bookingInfoForm.taking" 
                    :disabled="bookingInfoForm.payment_online || formDisabled"
                    :inputType="'number'" 
                    :isPrice="true"
                    :icon="'euro_symbol'" 
                    @input="updateFormValue($event, 'taking')" 
                 /> 
            </div>

           
            <div  class="payment-container p-my-2" >
                <PadelDropdown :label="t('payment_state')" :options="PaymentOptions" class="payment-dropdown"
                    @input="updateFormValue($event.value, 'payment_state')" :default="bookingInfoForm.payment_state"
                    :disabled="bookingInfoForm.payment_online || formDisabled"/> 

                <PadelInput 
                    @input="updateFormValue($event, 'teacher_name')" :label="t('teacher_name')"
                    :value="bookingInfoForm.teacher_name" :disabled="formDisabled" v-if="bookingInfoForm.type === 'LESSON' || bookingInfoForm.type === 'BACKOFFICE_LESSON'" />
                <template v-else>
                    <PadelDropdown v-if="is__booking__modifiable" :label="t('color_choice')"
                        :options="colorChoices" class="payment-dropdown"
                        @input="updateFormValue($event.value, 'color')" :default="bookingInfoForm.color"
                        :disabled="formDisabled"  />     
                </template>
            </div>

            <div  class="payment-container p-my-1" > 
                <PadelInput label="ID Transazione Nexi" 
                    :value="bookingInfoForm.nexi_transaction_id != null ? 
                        (bookingInfoForm.nexi_transaction_id == 'not_auth' ? 'Pagamento non autorizzato' : bookingInfoForm.nexi_transaction_id) : 
                        'In attesa di contabilizzazione'" 
                    :disabled="true"
                    :inputType="'text'" 
                    v-if="bookingInfoForm.payment_online || bookingInfoForm.nexi_transaction_id == 'not_auth'" /> 
                
                <!-- Se il centro è di tipo Altra natura (commerciale): è fisso commerciale -->
                <!-- se è di tipo Associazione sportiva: dipente dal giocatore -->
                <PadelDropdown v-if="is__booking__modifiable" label="Tipologia prenotazione"
                    :options="bookingCategoriesOptions" class="payment-dropdown"
                    @input="updateFormValue($event.value, 'booking_category')"
                    :default="bookingInfoForm.booking_category" 
                    :disabled="formDisabled" /> 
                <div :style="{'width': '50%', 'display': bookingInfoForm.payment_online ? 'none' : 'block', }">&nbsp</div>
            </div>

            <div  class="p-my-2" style="width: 50%; padding-right: 2%" v-if="bookingInfoForm.type === 'LESSON' || bookingInfoForm.type === 'BACKOFFICE_LESSON'">
                <PadelDropdown v-if="is__booking__modifiable" :label="t('color_choice')"
                    :options="colorChoices" class="payment-dropdown"
                    @input="updateFormValue($event.value, 'color')" :default="bookingInfoForm.color"
                    :disabled="formDisabled" />     
            </div>

            
        </div>

        <div class="textbox-container" :class="is__booking__modifiable ? 'margin-top-20' : ''">
            <PadelTextarea  :label="t('notes')" :value="bookingInfoForm.notes" :disabled="formDisabled"
                :showWordCount="true" :key="componentKey"
                @input="updateFormValue($event, 'notes')" />
        </div>

        <div class="margin-top-20">                    
            <RepeatBooking 
                v-if="bookingInfoForm.override_bundle"
                :newBookingForm="bookingInfoForm"
                :newBookingErrors="bookingInfoErrors"
                :formDisabled="formDisabled" />
        </div>
    </div>
    <div v-else-if="bookingInfoForm.type === 'BACKOFFICE_EVENT'">
        <div class="form-container">

            <PadelCalendar :label="t('event_date')" 
                @input="updateFormValue($event, 'date')" 
                class="calendar-element"
                :value="bookingInfoForm.date" 
                :disabled="formDisabled" 
                :minDate="new Date()"
                :error="bookingInfoErrors.date" />

            <PadelTimepicker 
                :label="t('start_hour')"
                :value="bookingInfoForm.start_at || '-'" 
                :disabled="formDisabled"
                @input="validateHourToday($event, 'start_at')"
                :from="timeFrom"
                :to="timeTo"
                :error="bookingInfoErrors.start_at" />

            <PadelTimepicker 
                :label="t('end_hour')"
                :value="bookingInfoForm.hour_end || '-'" 
                :disabled="formDisabled"
                @input="validateHourToday($event, 'hour_end')"
                :from="timeFrom"
                :to="timeTo" 
                :error="bookingInfoErrors.hour_end" />

            <div class="sport-playground-container" style="top: -8px;">
                <PadelDropdown :label="t('selected_sport')" 
                    :options="mySports"
                    @input="updateFormValue($event.value, 'sport_type')" 
                    :default="bookingInfoForm.sport_type"
                    :disabled="formDisabled" 
                    :key="componentKey" />
                <PadelDropdown :label="t('selected_playground')" 
                    :options="computedPlaygrounds"
                    @input="updateFormValue($event.value, 'playground')" 
                    :default="bookingInfoForm.playground"
                    :disabled="formDisabled" 
                    :key="componentKey" />
            </div>

            <PadelDropdown v-if="is__booking__modifiable" 
                :label="t('color_choice')"
                :options="colorChoices" 
                class="payment-dropdown"
                @input="updateFormValue($event.value, 'color')" 
                :default="bookingInfoForm.color"
                :disabled="formDisabled" />
                
            <PadelDropdown :label="t('event_type')" 
                :options="eventTypeOptions"
                class="payment-dropdown"
                @input="setFormValue($event.value, 'event_type')"
                :default="bookingInfoForm.event_type" 
                :disabled="formDisabled" />

        </div>

        <div class="textbox-container" :class="is__booking__modifiable ? 'margin-top-20' : ''">
            <PadelTextarea  :label="t('notes')" :value="bookingInfoForm.notes" :disabled="formDisabled"
                :showWordCount="true" :key="componentKey"
                @input="updateFormValue($event, 'notes')" />
        </div>

        <div class="margin-top-20">                    
            <RepeatBooking 
                v-if="bookingInfoForm.override_bundle"
                :newBookingForm="bookingInfoForm"
                :newBookingErrors="bookingInfoErrors"
                :formDisabled="formDisabled" />
        </div>
    </div>
    <div v-else>
        <div class="form-container">
            <PadelInput :label="t('start_hour')" :value="bookingInfoForm.start_at || '-'" :disabled="formDisabled" />
            <PadelInput :label="t('end_hour')" :value="bookingInfoForm.hour_end || '-'" :disabled="formDisabled" />
            <PadelInput :label="t('selected_playground')" :value="playgroundMap[bookingInfoForm.playground.id] || '-'"
                :disabled="formDisabled" />
            <PadelInput :label="t('type')" :value="t(bookingInfoForm.type) || '-'" :disabled="formDisabled" />
        </div>
        <div 
            class="textbox-container" :class="bookingInfoForm.type === 'LESSON' || bookingInfoForm.type === 'BACKOFFICE_LESSON' ? 'margin-top-20' : ''">
            <PadelTextarea :label="t('notes')" :value="bookingInfoForm.notes || '-'" :disabled="formDisabled"
                :showWordCount="!formDisabled" :key="componentKey" />
        </div>
    </div>

    <div class="button-row flexbox-row justify-space-between margin-top-40">
        <p v-if="formDisabled" class="abort" @click="hideModal()">{{uppercase(t('back'))}}</p>
        <p v-else class="abort" @click="abortChanges()">{{uppercase(t('abort'))}}</p>
        <div v-if="formDisabled" class="right-buttons-container flexbox-row">
            
            <PadelButton :label="uppercase(t('cancel'))"  :type="'secondary'" @click="emitCancel()"
                v-if="!bookingInfoForm.override_bundle"></PadelButton>
            
            <PadelButton v-if="is__booking__modifiable" class="margin-left-10"
                :label="uppercase(t('modify'))" @click="enableForm()"></PadelButton>
        </div>
        <div v-else>
            <PadelButton 
                :label="(bookingInfoForm.override_bundle ? uppercase(t('verify availablilty')) : uppercase(t('save')))" 
                @click="saveChanges()"
                :disabled="isFormBookingEmpty(bookingInfoForm) || !isFormBookingValid(bookingInfoErrors)"></PadelButton>
        </div>
    </div>
</DumbModal>