export const it = {
    "": "",
    "yes": "Si",
    "no": "No",
    "are_you_facility": "Sei una struttura?",
    "access": "Accedi",
    "register": "Iscrivi il tuo centro",
    "register_sport_club": "Iscrivi il tuo centro sportivo",
    "welcome": "Bentornato",
    "generic_welcome": "Benvenuto nella prima community italiana per il gioco del padel!",
    "popular_courts": "I nostri club associati",
    "our_strong_points": "I nostri punti forti",
    "about_us": "Dicono di noi",
    "access_venue": "Accedi e gestisci il tuo campo",
    "email": "Email",
    "password": "Password",
    "address": "Indirizzo del centro sportivo",
    "clubName": "Nome del centro sportivo",
    "name": "Nome",
    "surname": "Cognome",
    "passwordConfirmation": "Conferma password",
    "welcome_to_padelapp": "Benvenuto su PadelApp!",
    "user_id": "Nome e Cognome",
    "prefix": "Prefisso",
    "phonenumber": "Numero di telefono",
    "clubPhonenumber": "Numero di telefono del centro sportivo",
    "clubAddress": "Indirizzo del centro sportivo",
    "insert_code": "Inserisci codice",
    "insert_your_code": "Digita il tuo codice",
    "verify_code": "Codice di verifica",
    "send_code": "Invia il codice",
    "resend_code": "Invia di nuovo il codice",
    "insert_email_code": "Inserisci il codice che hai ricevuto via mail",
    "confirm_code": "Conferma il codice",
    "copyright": "© 2020 FantaPadel. All rights reserved",
    "forgot_password": "Hai dimenticato la password?",
    "not_registered": "Non sei ancora iscritto? Registrati",
    "already_registered": "Hai già un account? Accedi",
    "handle_bookings": "Gestisci le tue prenotazioni",
    "create_tournaments": "Organizza tornei",
    "broadcast_yourself": "Fatti conoscere",
    "are_you_player": "Sei un giocatore?",
    "download_app": "Scarica la nostra app e goditi le partite con i tuoi amici!",
    "book_courts": "Potrai prenotare i tuoi campi preferiti per allenarti o sfidare i tuoi amici",
    "book_tournaments": "Potrai partecipare a fantastici tornei nei campi di Padel di tutto il mondo",
    "pay_online": " Pagamenti e prenotazioni semplici e veloci, tutto in pochi click!",
    "geolocation": "Geolocalizza il tuo campo preferito e inizia a fare pratica",
    "email-confirm": "Abbiamo mandato una mail all'indirizzo da te indicato.\nConferma il tuo profilo",
    "requests": "Richieste",
    "clubs": "Club",
    "transactions": "Transazioni",
    "PENDING": "In sospeso",
    "APPROVED": "Accettata",
    "REJECTED": "Rifiutata",
    "confirm_club": "Conferma iscrizione club",
    "reject_club": "Rifiuta iscrizione club",
    "iscription_request": "Richiesta iscrizione",
    "with_this_operation_confirm": "Con questa operazione consentirai al club",
    "with_this_operation_reject": "Con questa operazione non consentirai al club",
    "enter_wesmash": "di entrare a far parte del team Wesmash",
    "proceed_registration": "e il gestore potrà procedere con la registrazione della sua struttura sul portale",
    "wanna_confirm": "Vuoi accettare l'iscrizione?",
    "wanna_reject": "Vuoi rifiutare l'iscrizione?",
    "return_to_dashboard": "Ritorna alla dashboard",
    "subscription_confirmed": "Iscrizione confermata!",
    "subscription_rejected": "Iscrizione rifiutata con successo!",
    "manager_of": "Ora il gestore di",
    "will_receive_mail": "riceverà una mail di conferma e potrà registrare la sua struttura",
    "request_info_text": "Di seguito i dati inseriti in merito alla richiesta di iscrizione di questo club.\nPuoi scegliere se accettare la richiesta o rifiutarla.",
    "club_name": "Nome del centro",
    "address_only": "Indirizzo",
    "request_date": "Data richiesta",
    "request_state": "Stato richiesta",
    "manager_mail": "Email gestore",
    "no_records": "Nessun record disponibile",
    "download_your_contract": "Scarica il tuo contratto",
    "download_contract": "Scarica il contratto",
    "back": "Indietro",
    "forward": "Avanti",
    "contract_downloaded": "Contratto scaricato",
    "request_completed": "Richiesta di iscrizione completata",
    "close": "Chiudi",
    "request_completed_text": "Complimenti, hai completato la richiesta di iscrizione!",
    "send_contract_to": "Invia il contratto firmato a",
    "and_wait_for_comunication": "e attendi una comunicazione in merito all'esito della tua richiesta!",
    "access_wesmash": "Accedi a Wesmash",
    "signup_text": "Hai un centro sportivo e vuoi entrare a far parte dell'esclusiva community di Wesmash?\nCompila il form sottostante e completa la tua richiesta di iscrizione!",
    "insert_code_text": "Ti abbiamo inviato un codice di conferma all'indirizzo email con cui ti sei registrato, inseriscilo nel form sottostante.",
    "find_your_account": "Invia codice al tuo account",
    "send": "Invia",
    "insert_new_password": "Inserisci nuova password",
    "necessary_change_password": "È necessario inserire una nuova password",
    "insert_username_mail": "Inserisci l'indirizzo mail col quale ti sei registrato:",
    "code_sent": "Codice inviato!",
    "check_your_mail": "Controlla la tua email e inserisci il codice di verifica per poter resettare la tua password.",
    "info": "Informazioni",
    "step_1": "Step 1",
    "step_2": "Step 2",
    "insert_club_info": "Inserisci le informazioni sulla tua struttura",
    "insert_playgrounds_info": "Censisci i tuoi campi sportivi",
    "add_picture": "Carica foto",
    "picture_added": "Foto aggiunta",
    "club_picture": "Foto struttura",
    "club_phonenumber": "Numero di telefono del club",
    "club_email": "Email del club",
    "opening_hour": "Orario di apertura",
    "closing_hour": "Orario di chiusura",
    "available_services": "Servizi offerti:",
    "changing_rooms": "Spogliatoi",
    "showers": "Docce",
    "parking": "Parcheggio",
    "tournaments": "Tornei",
    "renting": "Noleggio",
    "bar": "Bar",
    "restaurant": "Ristorante",
    "swimming_pool": "Piscina",
    "insert_club_picture": "Caricamento foto",
    "insert_playground_picture": "Inserisci una foto del campo",
    "change_picture": "È già stata caricata una foto per questo centro sportivo.\nVuoi sostituirla?",
    "be_careful_picture": "Ricordati che questa immagine sarà la tua vetrina e apparirà nella home dei giocatori: scegli una bella immagine che rappresenti al meglio il tuo club.\nPerchè venga visualizzata correttamente, ti consigliamo di scegliere un'immagine",
    "horizontal": "orizzontale",
    "size_more_than": "e che non pesi più di",
    "add_playground": "Aggiungi campo",
    "add_tournament": "Aggiungi torneo",
    "add_playground_title": "Aggiunta campo",
    "modify_playground_title": "Modifica campo",
    "save": "Salva",
    "sport_type": "Tipologia di sport",
    "playground_type": "Tipologia di campo",
    "game_duration": "Durata partita",
    "playground_name": "Nome campo",
    "ground_type": "Tipologia di terreno",
    "price": "Prezzo",
    "total_price": "Prezzo totale",
    "typology": "Tipologia",
    "calendar": "Calendario",
    "go_to_calendar": "Vai a calendario",
    "go_to_the_calendar": "Vai al calendario",
    "bookings": "Prenotazioni",
    "lessons": "Lezioni",
    "users": "Utenti",
    "my_club": "Il mio club",
    "rejects": "Cancellazioni",
    "stats": "Statistiche",
    "payments": "Pagamenti",
    "guide": "Guida",
    "abort": "Annulla",
    "no_pic_club_text": "Attenzione! Hai confermato l'inserimento del centro senza aggiungere una foto, se procederai comunque te ne assegneremo una di default.\nVuoi procedere senza caricare una foto?",
    "no_pic_playground_text": "Attenzione! Hai confermato l'inserimento del campo senza aggiungere una foto, se procederai comunque te ne assegneremo una di default.\nVuoi procedere senza caricare una foto?",
    "playgrounds_missing": "Non hai ancora inserito nessun campo",
    "tournaments_missing": "Non hai ancora inserito nessun torneo",
    "start_handle_bookings": "Per iniziare a gestire le tue prenotazioni con Wesmash clicca il bottone qui sotto oppure accedi alla sezione",
    "start_handle_tournaments": "Per organizzare il tuo primo torneo clicca il bottone qui sotto ed inizia la configurazione",
    "and_insert_playground": "e censisci il tuo primo campo!",
    "load": "Carica",
    "change_password": "Cambio password",
    "go_to_profile": "Vai al profilo",
    "logout": "Logout",
    "filter_club_requests": "Filtra richieste club",
    "switch_club": "Scelta struttura",
    "which_club_login": "Quale struttura vuoi amministrare?\nRicordati che puoi sempre cambiare struttura dal menù del tuo profilo.",
    "change_profile_picture": "Carica immagine",
    "profile_pic_text": "Stai impostando la tua immagine del profilo, questa foto non verrà visualizzata dai giocatori. Ti consigliamo di caricare un'immagine in formato .jpg o .png e che non abbia un peso superiore a 512Kb",
    "insert_phone_number": "Certifica il tuo numero di telefono",
    "insert_phone_text": "Inserisci nel form sottostante il tuo numero di telefono, ti invieremo un codice di conferma allo scopo di certificare il tuo contratto.",
    "manager_data": "Dati del gestore",
    "modify": "Modifica",
    "modify_data": "Modifica dati",
    "save_modified": "Salva modifiche",
    "confirm": "Conferma",
    "insert_sent_code": "Inserisci il codice di conferma che hai ricevuto al numero di telefono inserito.",
    "profile": "Profilo",
    "register_new_club": "Registra un nuovo club",
    "proceed": "Procedi",
    "wanna_continue": "Desideri procedere?",
    "add_new_club_text": "Con questa funzionalità ti sarà possibile richiedere a Wesmash il censimento di una nuova struttura. Al click su \"conferma\" verrai reindirizzato alla pagina di registrazione della tua struttura, con i tuoi dati già memorizzati e dovrai inserire i dati del tuo nuovo club. Il processo di registrazione è lo stesso che hai eseguito la prima volta.",
    "your_centre": "Il tuo centro",
    "wanna_register_new_club": "Hai un altro centro sportivo e vuoi che sia inserito nel circuito Wesmash?\nInserisci le informazioni sul centro ed invia la richiesta di iscrizione, come hai fatto per il primo centro.",
    "disambiguation_club": "Cambia struttura",
    "change_photo": "Cambia foto",
    "my_centre": "Il mio centro",
    "my_playgrounds": "I miei campi",
    "prices_and_hours": "Prezzi e orari",
    "prices_rules": "Regole prezzi e orari",
    "prices_calendar": "Calendario prezzi e orari",
    "modify-club-tooltip-text": "In questa sezione potrai inserire il messaggio che i giocatori riceveranno via email ogni volta che effettueranno una prenotazione presso il tuo centro. Fai attenzione a non inserire <b>niente di offensivo</b> o il tuo club verrà bannato da Wesmash.",
    "players_message": "Messaggio ai giocatori",
    "insert_message": "Inserisci il messaggio",
    "players_message_not_present": "Non è stato inserito nessun messaggio ai giocatori",
    "playground_size": "Dimensione campo",
    "delete_playground": "Eliminazione campo",
    "attention": "Attenzione!",
    "delete_playground_text": "Con questa operazione procederai alla cancellazione del tuo campo \"<b>{field}</b>\". In automatico verrà eliminata l’associazione alle regole di prenotabilità presenti in \"Regole prezzi e orari\". Se vuoi procedere clicca su Conferma",
    "unable_delete_playground_text": "Sul campo \"<b>{field}</b>\", che stai cercando di eliminare sono ancora presenti delle prenotazioni. Prima di cancellare il campo, vai al calendario e gestisci le prenotazioni.",
    "new_booking": "Nuova prenotazione",
    "filters": "filtri",
    "export": "esporta",
    "calendar_slot": "Fascia oraria",
    "apply": "Applica",
    "reset_filters": "Reset filtri",
    "MORNING": "Mattina",
    "AFTERNOON": "Pomeriggio",
    "EVENING": "Sera",
    "visualize": "Visualizza",
    "min_bookings": "Numero minimo prenotazioni",
    "min_cancellations": "Numero minimo cancellazioni",
    "ABILITATED": "Abilitati",
    "BANNED": "Bannati",
    "fullname": "Nome completo",
    "phonenumber_abbreviated": "N° di telefono",
    "level": "Livello",
    "sex": "Sesso",
    "team_name": "Nome team",
    "bookings_number": "Numero prenotazioni",
    "cancellations_number": "Numero cancellazioni",
    "block": "Blocca",
    "unblock": "Riabilita",
    "block_user": "Blocca utente",
    "unblock_user": "Riabilita utente",
    "ban_user_main_text": "Con questa operazione impedirai all’utente <b>{name}</b> di effettuare prenotazioni nel tuo centro. Potrai sempre riabilitare il giocatore cliccando il bottone sull’ultima colonna della tabella",
    "unban_user_main_text": "Con questa operazione riabiliterai l’utente <b>{name}</b> consentendogli di effettuare di nuovo prenotazioni nel tuo centro. Potrai bloccarlo nuovamente cliccando il bottone sull’ultima colonna della tabella",
    "wanna_ban": "Desideri confermare il blocco dell’utente?",
    "wanna_unban": "Desideri confermare la riabilitazione dell’utente?",
    "view_progress": "Visualizza andamento",
    "booking_detail": "Dettaglio prenotazione",
    "tournament_detail": "Dettaglio torneo",
    "cancel": "Elimina",
    "new_booking_game": "Nuova prenotazione partita",
    "what_booking_type": "Che tipologia di slot vuoi prenotare?",
    "create": "Crea",
    "registered_user": "Utente registrato",
    "new_user": "Nuovo utente",
    "game_date": "Data partita",
    "game_date_time": "Data e orario partita",
    "lesson_date": "Data lezione",
    "playground_choice": "Scelta campo",
    "sport_choice": "Scelta sport",
    "start_hour": "Ora inizio",
    "end_hour": "Ora fine",
    "teacher_name": "Nome maestro",
    "verify_phone_number": "Certifica num. di telefono",
    "all_playgrounds": "Tutti i campi",
    "start_mantainance_date": "Data inizio intervento",
    "end_mantainance_date": "Data fine intervento",
    "mantainance_description": "Descrizione intervento",
    "closing_description": "Descrizione chiusura",
    "start_closing_date": "Data inizio chiusura",
    "end_closing_date": "Data fine chiusura",
    "start_date": "Data inizio",
    "end_date": "Data fine",
    "color_choice": "Scelta colore prenotazione",
    "notes": "Note",
    "no_user_found": "Non è stato trovato alcun utente",
    "search_user": "Cerca giocatore...",
    "SINGLE": "Singolo",
    "DOUBLE": "Doppio",
    "INDOOR": "Indoor",
    "OUTDOOR": "Outdoor",
    "player_name": "Nome giocatore",
    "player_family_name": "Cognome giocatore",
    "player_phone_number": "Numero di telefono giocatore",
    "selected_playground": "Campo selezionato",
    "selected_sport": "Sport selezionato",
    "booking_hour": "Ora prenotazione",
    "OPERATIVE": "Abilitato",
    "DISABLED": "Disabilitato",
    "club_state": "Stato club",
    "manager_phone_number": "Tel. gestore",
    "go_back_to_club": "Torna alla sezione club",
    "search_club_name": "Cerca nome club...",
    "export_report": "Esporta report",
    "disable_club": "Disabilita club",
    "enable_club": "Riabilita club",
    "export_club_report": "Esporta report club",
    "export_club_report_text": "Selezionare il mese di cui si desidera scaricare il report",
    "disable_club_text": "Con questa operazione bloccherai e disabiliterai il club <b>{name}.</b> Come conseguenza i giocatori non potranno più visualizzarlo nelle loro ricerche nè effettuare nuove prenotazioni (sia tramite APP che attraverso il gestionale). Il blocco del club è revocabile dal dettaglio della richiesta.",
    "are_you_sure_disable": "Sei sicuro di voler procedere e disabilitare il club?",
    "enable_club_text": "Con questa operazione riabiliterai il club <b>{name}.</b> Come conseguenza i giocatori lo visualizzeranno nelle loro ricerche e potranno di nuovo effettuare prenotazioni (sia tramite APP che attraverso il gestionale). Una volta riabilitato, il club potrà essere di nuovo disabilitato dalla pagina di dettaglio.",
    "are_you_sure_enable": "Sei sicuro di voler procedere e riabilitare il club?",
    "manager_name": "Nome gestore",
    "manager_surname": "Cognome gestore",
    "manager_email": "Email gestore",
    "manager_phonenumber": "Numero di telefono gestore",
    "verify_phone_number_title": "Certificazione numero di telefono",
    "verify_phone_number_text": "Hai selezionato di voler certificare il numero di telefono del giocatore, verifica che il codice che ha ricevuto sul cellulare coincida con quello riportato qui sotto.",
    "send_again": "Invia di nuovo",
    "eliminate_timeslot_title": "Eliminazione evento",
    "eliminate_app_timeslot_title": "Eliminazione prenotazione giocatore",
    "eliminate_timeslot_text": "Stai eliminando l'evento {type} dal calendario.",
    "eliminate_timeslot_from_bundle_text": "Stai eliminando l'evento {type} dal calendario. Verrà eliminata solo questa Prenotazione e non l’intero abbonamento.",
    "app_timeslot_warning": "Stai eliminando la prenotazione effettuata dal giocatore tramite App.",
    "sure_wanna_continue_timeslot_delete": "Sei sicuro di voler procedere con questa operazione?",
    "type": "Tipo",
    "BOOKING": "Prenotazione",
    "BACKOFFICE_BOOKING": "Prenotazione",
    "LESSON": "Lezione",
    "BACKOFFICE_LESSON": "Lezione",
    "MAINTENANCE": "Manutenzione",
    "CLOSING": "Chiusura",
    "OTHER": "Altro",
    "TOURNAMENT": "Torneo",
    "BACKOFFICE_EVENT": "Eventi o altro",
    "BACKOFFICE_OPEN_BOOKING": "Partita aperta",
    "OPEN_BOOKING": "Partita aperta",
    "disabled_club": "Centro disabilitato",
    "get_in": "Entra",
    "disabled_club_modal_text": "L'admin di Wesmash ha disabilitato il tuo centro. I giocatori non lo visualizzeranno nelle loro ricerche. Per maggiori dettagli scrivi a <b>{mail}.</b>",
    "exit": "Esci",
    "refresh": "Ricarica",
    "hour_price": "Prezzo all'ora",
    "hour_price_text": "Qui verrà definito il prezzo del campo all'ora, nel caso di prenotazione di slot orari inferiori o maggiori il prezzo verrà adeguato moltiplicando o dividendo l'importo",
    "tournaments_first_step": "Inserisci le informazioni generali sul torneo",
    "tournaments_second_step": "Inserisci le date in cui si terrà il torneo",
    "finish_registration_text": "Per ultimare la registrazione del tuo centro a WeSmash mancano pochi passi:",
    "first_bulletpoint": "• Scarica il contratto cliccando sul link sottostante",
    "second_bulletpoint": "• Compila il contratto con i dati richiesti",
    "third_bulletpoint": "• Inoltra il contratto firmato a ",
    "will_receive_mail_contract": "Riceverai una mail di conferma di avvenuta registrazione!",
    "greetings_from_wesmash": "Grazie, dalla Community di WeSmash!",
    "tournaments_slots_title": "Seleziona gli slot in cui occupare i campi",
    "add_day": "Aggiungi giorno",
    "tournament_name": "Nome torneo",
    "tournament_price": "Costo iscrizione",
    "tournament_type": "Tipologia torneo",
    "min_couples": "Numero di coppie minimo",
    "max_couples": "Numero di coppie massimo",
    "min_level": "Livello minimo richiesto",
    "max_level": "Livello massimo richiesto",
    "tournament_description": "Descrizione del torneo",
    "date": "Data",
    "start_at": "Ora inizio",
    "end_at": "Ora fine",
    "player_email": "Email giocatore",
    "player_last_name": "Cognome giocatore",
    "booked_hours": "Ore prenotate",
    "lesson_hours": "Ore lezione",
    "cancelled_hours": "Ore cancellate",
    "income": "Guadagni",
    "banned": "Bannato",
    "tournament_mode": "Descrizione modalità torneo",
    "tournament_prizes": "Premi (opzionale)",
    "registration_deadline": "Scadenza iscrizioni",
    "select_day": "Seleziona giorno",
    "from": "Dalle",
    "to": "Alle",
    "select_playground": "Seleziona campo",
    "max_couples_must_be_positive": "Il valore deve essere positivo",
    "min_couples_must_be_less": "Le coppie minime devono essere minori delle coppie massime",
    "max_couples_must_be_greater": "Le coppie massime devono essere maggiori delle coppie minime",
    "max_level_must_be_greater": "Il livello massimo deve essere maggiore del minimo",
    "min_level_must_be_less": "Il livello minimo deve essere maggiore del massimo",
    "registration_must_be_before_start": "La scadenza deve essere precedente alla data inizio",
    "end_date_must_be_before_start_date": "La data fine deve essere successiva o uguale alla data inizio",
    "start_date_must_be_before_end_date": "La data inizio deve essere precedente o uguale alla data fine",
    "max_couples_cannot_be_decreased": "Il numero di coppie massimo non può diminuire",
    "tournament_date_after": "Data successiva all'inizio torneo",
    "tournament_date_before": "Data precedente all'inizio torneo",
    "end_at_before_start": "Ora fine precedente a ora inizio",
    "start_at_after_end": "Ora inizio successiva ad ora fine",
    "add_tournament_pic": "Carica locandina",
    "tournament_pic_added": "Locandina aggiunta",
    "load_tournament_pic_text": "Ricordati che questa immagine caratterizzerà il torneo che stai creando: scegli una bella immagine che rappresenti al meglio l'evento che stai organizzando. Perchè venga visualizzata correttamente ti consigliamo di scegliere un'immagine <b>verticale</b>, in formato <b>.png</b> o <b>.jpg</b> e che non pesi più di <b>20Mb</b>",
    "change_tournament_picture": "È già stata caricata una foto per questo torneo.\nVuoi sostituirla?",
    "sure_wanna_confirm_without_tournament_pic": "Stai passando allo step 2 della creazione torneo senza caricare una locandina. La locandina è importante in quanto apparirà sulla bacheca del torneo e i giocatori potranno vederla. Se non verrà inserita non sarà mostrata alcuna immagine sulla pagina del torneo. Sei sicuro di voler proseguire senza inserire una locandina?",
    "tournament_creation": "Creazione torneo",
    "tournament_modify": "Modifica torneo",
    "tournament_creation_text_1": "Cliccando il bottone \"conferma\" confermerai la creazione del torneo",
    "tournament_creation_text_2": "Dopo averlo creato alcuni dati (tra cui <b>nome torneo</b>, <b>costo</b>, <b>tipologia</b>, <b>livello minimo e massimo</b> richiesti) non saranno più modificabili nel rispetto dei giocatori che avranno deciso di partecipare.",
    "wanna_confirm_text": "Desideri confermare?",
    "tournament_creation_ok": "Complimenti, hai completato la creazione del torneo.",
    "new_tournament_created": "Ora potrai modificare le informazioni dalla sezione \"tornei\" del menù e visualizzare gli slot sul calendario.",
    "go_back": "Torna indietro",
    "tournament_conflict_text": "hai impostato per il giorno {date} su \"<b>{playground_name}</b>\" uno slot orario in cui esiste già una prenotazione, modifica la fascia oraria oppure cambia campo per poter proseguire con la creazione del torneo.",
    "tournament_conflict_slot_text": "hai impostato per il giorno {date} su \"<b>{playground_name}</b>\" uno slot orario in cui esiste già una prenotazione, modifica la fascia oraria oppure cambia campo per poter proseguire con la modifica del torneo.",
    "MALE": "Maschile",
    "FEMALE": "Femminile",
    "MIXED": "Misto",
    "MALE_GENDER": "Maschio",
    "FEMALE_GENDER": "Femmina",
    "OTHER_GENDER": "-",
    "tournaments_archive": "Archivio tornei",
    "status": "Stato",
    "tournament_deletion": "Eliminazione torneo",
    "delete_tournament_text": "Con questa operazione procederai all'eliminazione del tuo torneo \"<b>{tournament_name}</b>\", il torneo non sarà più visualizzabile in app e i giocatori che hanno già confermato la loro iscrizione riceveranno una mail di notifica. ",
    "CANCELLED": "Annullato",
    "FINISHED": "Terminato",
    "operation_completed": "Operazione completata",
    "operation_completed_success": "Operazione completata con successo!",
    "hour_slots": "Slot orari",
    "registered_players": "Giocatori iscritti",
    "conflicts_text": "La modifica dell'orario del centro va in conflitto con alcune prenotazioni, vai al calendario e risolvi i conflitti per poter procedere con la modifica",
    "conflicts_following": "Di seguito le prenotazioni che dovrai modificare:",
    "modify_club_hours": "Modifica orari centro",
    "principiante": "Principiante",
    "intermedio": "Intermedio",
    "intermedio alto": "Intermedio alto",
    "avanzato": "Avanzato",
    "competizione": "Competizione",
    "tournament_go_back_modal_text": "Attenzione, hai effettuato delle modifiche, proseguendo con questa operazione saranno annullate. Se vuoi salvare torna indietro e clicca \"salva\" oppure prosegui",
    "sure_wanna_delete_tournament_slot": "Attenzione! Con questa operazione procederai alla cancellazione delle partite del giorno xx/xx/xx del torneo che hai organizzato, se vuoi procedere clicca su Conferma.",
    "go_to_tournaments": "Vai a tornei",
    "registrations_state": "Stato iscrizioni",
    "no_teams_registered": "Nessun team iscritto attualmente.",
    "delete_team_text": "Con questa operazione procederai all'eliminazione di questa coppia dal torneo e il giocatore Wesmash riceverà un avviso. Desideri confermare l'eliminazione?",
    "eliminate_team": "Eliminazione team",
    "empty_tournament_archive": "Non sono presenti tornei archiviati.",
    "fullname_player_1": "Nome giocatore 1",
    "fullname_player_2": "Nome giocatore 2",
    "email_player_1": "Email del prenotante",
    "phonenumber_player_1": "Numero di telefono del prenotante",
    "level_player_1": "Livello giocatore 1",
    "level_player_2": "Livello giocatore 2",
    "sex_player_1": "Sesso giocatore 1",
    "sex_player_2": "Sesso giocatore 2",
    "payment_state": "Pagamento",
    "UNPAID": "Da pagare",
    "PAID_CASH": "Pagato in contanti",
    "PAID_CARD": "Pagato con cc",
    "PAID_BANCOMAT": "Pagato con bancomat",
    "PAID_BANK": "Pagato con bonifico",
    "birthdate": "Data di nascita",
    "favourite_clubs": "Club preferiti",
    "consents": "Consensi espressi in App",
    "accepts_commercial_wesmash": "Promozioni e notifiche Wesmash",
    "accepts_commercial_partner": "Promozioni e notifiche partner",
    "accepts_privacy": "Comunicazioni commerciali dai club",
    "event_over_closing_time": "Hai selezionato per la tua prenotazione un orario in cui il centro è chiuso. Torna al calendario e seleziona un orario valido per poter creare un evento.",
    "event_overlaps": "L'orario della prenotazione che hai intenzione di creare coincide con un evento già presente sul calendario. Torna indietro e gestisci le tue prenotazioni per poter proseguire.",
    "load_scoreboard": "Carica tabellone",
    "substitute": "Sostituisci",
    "change_scoreboard": "Sostituisci tabellone",
    "loading_scoreboard": "Caricamento tabellone",
    "load_scoreboard_text": "Con questa funzionalità potrai caricare il tabellone del tuo torneo in formato <b>.pdf</b> e i giocatori visualizzeranno in app i loro turni e le loro partite. Da questa sezione potrai aggiornare il documento e scaricarlo se ne avrai necessità. Ti consigliamo di scegliere un file che non pesi più di <b>7Mb</b>",
    "load_more": "Carica altri",
    "NotAuthorizedException": "Username o password non corretti",
    "UsernameExistsException": "Esiste già uno user registrato con questa mail",
    "InvalidPasswordException": "La password non è valida",
    "UserNotConfirmedException": "È necessario confermare la registrazione col codice fornito per mail",
    "PasswordResetRequiredException": "È necessario resettare la password",
    "UserNotFoundException": "L'account non esiste",
    "ExpiredCodeException": "Il codice di conferma è scaduto",
    "CodeMismatchException": "Il codice inserito non è corretto",
    "LimitExceededException": "Troppi tentativi, riprova più tardi",
    "are_you_robot": "Sei un robot?",
    "sorry_error": "Spiacenti c'è stato un errore",
    "generic_error": "Errore generico",
    "required_field": "Campo obbligatorio",
    "password_not_valid": "La password deve contenere almeno un carattere maiuscolo, un numero e un carattere speciale",
    "must_be_number": "Il campo deve composto di soli numeri",
    "valid_email_error": "Il campo deve essere un email valida",
    "password_min_length": "La password deve contenere almeno 8 caratteri",
    "passwords_dont_match": "Reinserire password",
    "code_length_error": "Il codice deve essere di 6 cifre",
    "number_not_valid": "Numero non valido",
    "error": "Errore",
    "invalid_address": "Indirizzo non valido",
    "error_overlap": "Non puoi creare eventi sovrapposti",
    "export_bookings": "Esporta elenco prenotazioni",
    "export_bookings_text": "Selezionare il periodo di cui si desidera scaricare l'elenco prenotazioni.",
    "select_daily_excel": "Giorno attuale selezionato sul calendario",
    "download": "Scarica",
    "select_month": "Seleziona mese",
    "booking_exceeds_closing_hours": "Prenotazione oltre orario di chiusura",
    "cannot_book_before_now": "Non puoi fare una prenotazione precedente ad adesso",
    "cannot_book_before_start": "Non puoi fare una prenotazione precedente all'ora di inizio",
    "cannot_book_after_end": "Non puoi fare una prenotazione successiva all'ora di fine",
    "start_date_must_be_before_end": "La data di inizio deve essere precedente a quella di fine",
    "end_date_must_be_after_start": "La data di fine deve essere successiva a quella di inizio",
    "cannot_move_started_booking_in_past": "Una prenotazione può essere spostata solo nel futuro",
    "tournament_name_info": "Ricordati di non inserire la parola \"Torneo\" nel nome del tuo evento: gli verrà affiancata di default e apparirà come \"Torneo - Nome torneo\".",
    "privacy_consents": "Consenso Privacy al club",
    "partner_communication_consents": "Consenso a comunicazioni di partner",
    "found_timeslot_conflict": "L'orario non può collidere con eventi già creati",
    "no_rules_defined": "Nessuna regola definita",
    "rule_from": "Dal",
    "rule_to": "Al",
    "rule_name": "Nome regola",
    "valid_days": "Valida il",
    "sport": "Sport",
    "playground": "Campo",
    "price_starting_from": "Prezzo a partire da",
    "actions": "Azioni",
    "active": "Attiva",
    "disabled": "Disabilitata",
    "monday_short": "Lun",
    "tuesday_short": "Mar",
    "wednesday_short": "Mer",
    "thursday_short": "Gio",
    "friday_short": "Ven",
    "saturday_short": "Sab",
    "sunday_short": "Dom",
    "all_days": "Tutti i giorni",
    "no_days": "Nessun giorno",
    "edit": "Modifica",
    "duplicate": "Duplica",
    "disable": "Disabilita",
    "delete": "Cancella",
    "add_new_rule": "Inserisci nuova regola",
    "new_rule": "Nuova regola",
    "insert new rule data": "Inserisci i dettagli della nuova regola",
    "rule": "Regola",
    "valid_period": "Periodo di validità",
    "playgrounds": "Campi",
    "days_of_validity": "Giorni di validità",
    "with_all_days": "(tutti)",
    "select hour slots when rule is valid": "Seleziona gli slot orari in cui è valida la regola",
    "new_slot": "Nuovo slot",
    "minutes": "{time}min",
    "create_new_rule": "Crea nuova regola",
    "edit_rule": "Modifica regola",
    "deleting_rule": "Eliminazione regola",
    "deleting_exception_message": "Attenzione!<br>Stai eliminando l'eccezione <strong>{name}</strong>.<br>Sei sicuro di voler procedere con questa operazione?",
    "deleting_rule_message": "Attenzione!<br>Stai eliminando la regola <strong>{name}</strong>.<br>Sei sicuro di voler procedere con questa operazione?",
    "duplicate_rule": "Duplicazione regola",
    "duplicate_rule_message": "Stai per duplicare la regola <strong>{name}</strong>.<br>Sei sicuro di voler procedere con questa operazione?",
    "prefix mandatory hint": "Prefisso internazionale obbligatorio per la validazione (es: +39)",
    "cannot_slot_before_start": "Non puoi creare uno slot con ora precedente all'ora di inizio",
    "cannot_slot_after_end": "Non puoi creare uno slot con ora successiva all'ora di fine",
    "slot_interfere_with_another": "Questo slot ha gli orari in conflitto con un altro slot",
    "cannot_slot_end_at_least": "L'orario di inizio deve precedere l'orario di fine di almeno {minutes} minuti.",
    "rule_start_before_end": "La data di inizio deve precedere la data di fine.",
    "prenotation_duration": "Durata prenotazione",
    "slot_prices": "Tariffa slot orari",
    "config_overlap": "Sovrapposizione configurazione",
    "config_overlap_body": "Attenzione!<br>È già presente una configurazione per queste date o per questi orari. Non è possibile inserire configurazioni sovrapposte.",
    "exception_overlap": "Sovrapposizione eccezione",
    "exception_overlap_body": "Attenzione!<br>È già presente una eccezione per questa data o per questi campi. Non è possibile inserire eccezioni sovrapposte.",
    "got_it": "OK, ho capito!",

    // EXCEPTION RULES
    "custom_config": "Configurazione personalizzata",
    "custom_config_message": "Attenzione: stai creando una eccezione per una giornata puntuale che avrà priorità sulle \"Regole prezzi e orari\".",
    "exception_name": "Nome eccezione",

    // DUPLICATE
    "duplicate_playground": "Duplica campo",
    "duplicate_playground_message": "Il nuovo campo erediterà tutte le <strong>Regole prezzi e orari</strong> del <strong>{original}</strong>",
    "new_playground_name": "Inserisci nome del nuovo campo",

    // --
    "visibility": "Visibilità",
    "playground_visibility": "Visibilità campo",
    "public": "Pubblico",
    "private": "Privato",
    "playground_creation": "Creazione campo",
    "playground_creation_message": "Il Campo è stato creato correttamente.<br>Vai alla sezione <strong>Regole prezzi e Orari</strong> per configurare il campo.",
    "go_to_rules": "Vai alle regole",

    "months": {
        "January": "Gennaio",
        "February": "Febbraio",
        "March": "Marzo",
        "April": "Aprile",
        "May": "Maggio",
        "June": "Giugno",
        "July": "Luglio",
        "August": "Agosto",
        "September": "Settembre",
        "October": "Ottobre",
        "November": "Novembre",
        "December": "Dicembre",
    },
    "month": "Mese",
    "day": "Giorno",

    "today": "Oggi",

    "sort_index": "Indice ordinamento",
    "order_on": "Conferma ordinamento",
    "order_off": "Riordina",
    "all": "Tutti",
    "exposure": "Copertura",
    "ground": "Superficie",
    "walls": "Pareti",

    "PADEL": "Padel",
    "TENNIS": "Tennis",
    "PADEBALL": "Padeball",
    "PICKLEBALL": "Pickleball",
    "SOCCER_5": "Calcio a 5",
    "SOCCER_7": "Calcio a 7",
    "SOCCER_11": "Calcio",
    "BEACH_VOLLEY": "Beach Volley",
    "BEACH_TENNIS": "Beach Tennis",
    "ASPHALT": "Asfalto",
    "CONCRETE": "Cemento",
    "NATURAL_GRASS": "Erba naturale",
    "SYNTHETIC": "Sintetico",
    "SYNTHETIC_GRASS": "Erba sintetica",
    "RUBBER": "Gomma",
    "LINOLEUM": "Linoleum",
    "PARQUET": "Parquet",
    "PVC": "PVC",
    "RESIN": "Resina",
    "UNHEATED_SAND": "Sabbia",
    "HEATED_SAND": "Sabbia riscaldata",
    "CLAY": "Terra battuta",
    "RED_EARTH": "Terra rossa",
    "ARTIFICIAL_RED_EARTH": "Terra rossa artificiale",
    "TEMPEREDGLASS": 'Vetro temprato',
    "WALL": 'Muro',
    "GRID": 'Griglia',
    "PANORAMIC": 'Panoramico',
    "description": "Descrizione",
    "player_description": "Occupazione",
    "lesson_with": "{lesson} con {name}",

    "make_recurring": "Rendi ricorrente",
    "recurring_message": "Questa partita si ripeterà ogni {day} dalle {from} alle {to}",
    "number_of_weeks": "Numero di settimane",
    "recurring_for": "Ricorrente per",
    "recurring_until": "Ricorrente fino il",
    "num_weeks": "{num} settimane",

    "overall_picture_bookings": "Quadro complessivo delle prenotazioni",
    "recurring_period": "Periodo ricorrente",
    "recurring_period_value": "Dal {from} al {to}",
    "day_hour": "Giorno - Ora",
    "day_hour_value": "{date} dalle {from} alle {to}",
    "total_cost": "Costo totale",
    "included_in_bundle": "Inclusa nel pacchetto",
    "playground_unavailable": "Campo non disponibile",
    "booking_type": "Tipologia prenotazione",
    "day_and_time": "Data e ora",
    "payment_state_label": "Stato pagamento",
    "AVAILABLE": "Disponibile",
    "UNAVAILABLE": "Non disponibile",
    "price_each_booking": "Prezzo singola prenotazione",
    "taking": "Incasso",
    "no_playgrounds_available": "Nessun campo disponibile",

    "import": "Importa",
    "import_bookings": "Importa elenco prenotazioni",
    "import_bookings_text": "In questa sezione potrai caricare i file .csv delle prenotazioni. Crea un file per ciascun campo.",
    "download_pdf": "Scarica guida",
    "download_csv_template": "Scarica template .csv",
    "upload_csv_file": "Carica file.csv",
    "Something went wrong": "Qualcosa è andato storto",
    "Try to upload again": "Prova a ricaricare il file",
    "Elaborating file": "File in lavorazione",
    "Send report after": "Ti invieremo un report via mail quando sarà completato il caricamento",
    "edit_booking": "Modifica prenotazione",
    "editing_bundle": "Stai modificando una prenotazione che fa parte di un <strong>abbonamento</strong>.<br>Vuoi modificare tutto il pacchetto o la singola prenotazione?<br><br>Se modifichi la singola prenotazione verrà tolta dall’abbonamento.",
    "whole_bundle": "Tutto il pacchetto",
    "single_booking": "Singola prenotazione",
    "event_type": "Tipologia evento",
    "start_event_date": "Data inizio",
    "end_event_date": "Data fine",
    "event_date": "Data evento",
    "bundle": "Abbonamento",
    "no_schedules_found": "Nessuna prenotazione trovata",
    "event": "Evento",
    "actives": "Attive",
    "archived": "Passate",
    "canceled": "Cancellate",
    "time": "Orario",
    "verify availablilty": "Verifica disponibilità",
    "user not registered": "Utente non registrato",
    "EVENT": "Evento",
    "registry": "Anagrafica",
    "cards": "Tessere",
    "scheduled": "Elenco prenotazioni giocatore",
    "wesmash_from": "Su WeSmash da",
    "age_range": "Fascia d'età",
    "medical_certificate": "Certificato medico",
    "player_type": "Tipologia giocatore",
    "add_new_card": "Aggiungi tessera",
    "card number": "Tessera n°",
    "card expiration": "Scadenza tessera",
    "added by": "Inserita da",
    "card_type": "Tipo tessera",
    "new_card": "Nuova tessera",
    "new_card_description": "Inserisci i dettagli della nuova tessera",
    "edit_card": "Modifica tessera",
    "edit_card_description": "Modifica i dettagli della nuova tessera",
    "must_be_valid_date": "Il campo deve essere una data valida",
    "certify": "Certifica",
    "certificate_due": "Certificato medico",
    "ranking": "Ranking",
    "deleting_card": "Cancellazione tessera",
    "deleting_card_message": "Sei sicuro di voler cancellare la Tessera n°<strong>{card}</strong>?",
    "settings": "Impostazioni",
    "add_player_type": "Aggiungi tipologia giocatore",
    "category": "Categoria",
    "INSTITUTIONAL": "Istituzionale",
    "COMMERCIAL": "Commerciale",
    "deleting_player_type": "Cancellazione tipologia giocatore",
    "deleting_player_type_message": "Sei sicuro di voler cancellare la tipologia <strong>{name}</strong>",
    "other_center": "Altro Centro",
    "add_new": "Inserisci nuovo",
    "online_booking": "Prenotazione online",
    "importo": "Importo",
    "payed": "Pagato",
    "edit_taking": "Modifica incasso",
    "bookability": "Prenotabilità",
    "minimum_notice": "Preavviso minimo",
    "delete_booking": "Cancellabilità prenotazione",
    "window_booking": "Finestra prenotabilità",
    "bookability_tooltip": "Preavviso minimo: preavviso con cui il giocatore può prenotare un campo. Ad esempio se il preavviso minimo è 60min significa che un campo disponibile alle 18.00 è prenotabile in app fino alle ore 17.00.",
    "price_each_person": "Prezzo per persona",
    "each_person": "Per persona",
    "OPEN_BOOKING_OPEN": "Partita aperta",
    "OPEN_BOOKING_CONFIRMED": "Partita confermata",
    "OPEN_BOOKING_OPEN_PLAYGROUND": "Campo non prenotato",
    "OPEN_BOOKING_CONFIRMED_PLAYGROUND": "Campo prenotato",
    "partite": "Partite",
    "no_games_found": "Nessuna partita presente",
    "players": "Giocatori",
    "players_num": "Numero giocatori",
    "players_with_number": "{num} giocatori",
    "players_with_number_short": "{num} p",
    "game_status": "Stato partita",
    "booked_playground": "Campo prenotato",
    "playground_booking": "Prenotazione campo",
    "not_booked_playground": "Campo non prenotato",
    "confirm_booking": "Conferma prenotazine",
    "add_new_player": "Aggiungi giocatore",
    "remove_player": "Rimuovi giocatore",
    "remove_player_message": "Sei sicuro di voler rimuovere il giocatore selezionato?",
    "game_infos": "Informazioni partita",
    "game_features": "Caratteristiche partita",
    "players_type": "Tipo utenti",
    "players_type_public": "Tutti gli utenti WeSmash",
    "players_type_private": "Solo centro",
    "delete_game": "Elimina Partita",
    "delete_game_message": "Sei sicuro di voler eliminare la partita in oggetto?",
    "copy_on_clipboard": "Copiato negli appunti",
    "game_time": "Orario partita",
}
