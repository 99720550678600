<div class="box playground-box color-white flexbox-column" @click="modifyPlayground()">
    <div class="actions-container"
        v-if="withActions">
        <Actions
            :item="element"
            @edit="modifyPlayground()"
            @duplicate="duplicatePlayground()"
            @delete="deletePlayground()" />
    </div>
    <!--<span v-if="canCopy && !withActions" class="icon-copy material-icons" @click.stop="copyPlayground()">content_copy</span>
    <span v-else-if="!canCopy && !withActions" class="icon-copy material-icons" @click.stop="modifyPlayground()">mode_edit</span>
    <span class="icon-delete material-icons" @click.stop="deletePlayground()"
        v-if="!withActions">delete</span>-->
    <img v-if="element.picture_ref" :src="element.picture_ref + '?' + new Date().getTime()" class="image" />
    <img v-else-if="element.profilePicture && element.profilePicture.src" :src="element.profilePicture.src"
        class="image" />
    <!-- <img v-else-if="element.coverPicture && element.coverPicture.src" :src="element.coverPicture.src" class="image" /> -->

    <img v-else src="@/theme/imgs/default_campo.png" class="image" />
    <div class="box-info-part">
        <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-5">
            {{ t('playground_name') }}: <span class="color-white p-text-normal montserrat">{{element.name}}</span>
        </p>
        <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-5">
            {{ t('sport') }}: <span class="color-white p-text-normal montserrat">{{upperCaseFirstLetter(t(element.sport))}}</span>
        </p>
        <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-5">
            {{ t('typology') }}: <span class="color-white p-text-normal montserrat">{{upperCaseFirstLetter(element.exposure)}}</span>
        </p>
        <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-5">
            {{ t('visibility') }}: <span class="color-white p-text-normal montserrat">{{ upperCaseFirstLetter(element.hidden ? t('private') : t('public')) }}</span>
        </p>
        <!--<div class="number color-white barlow bold-text">{{doubleZeros(index + 1)}}</div>
        <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-5">{{element.name}}</p>
        <p class="box-row color-white margin-0 barlow margin-bottom-5">{{'Sport: '}}<b
                class="montserrat">{{upperCaseFirstLetter(element.sport)}}</b>
        </p>
        <p class="box-row color-white margin-0 barlow">{{t('typology') + ':'}} <b
                class="montserrat">{{upperCaseFirstLetter(element.exposure)}}</b></p>
        <div class="number color-white barlow bold-text">{{doubleZeros(index + 1)}}</div>-->
    </div>

</div>