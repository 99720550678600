<div class="progress-bar-container one-line">
    <div v-if="!hideCounter" class="flexbox-row align-center">
        <img src="@/theme/imgs/icon_coppie.svg" alt="">
        <div class="color-white montserrat counter">
            {{`${doubleZeros(value)}/`}}<b>{{`${doubleZeros(maxValue)}`}}</b>
        </div>
    </div>
    <div class="progress" :class="background_color">
        <div :class="bar_color + ' bar'" :style="{ width: bar__length + '%'}"></div>
    </div>
</div>