<div class="team-divider" v-if="index == 2" />

<div class="player-item" v-if="player">

    <div class="level">
        <template v-if="player.exp_level.level !== undefined">
            Liv. {{ (player.exp_level.level / 100).toFixed(0) }}<br />{{ t(player.exp_level.description) }}
        </template>
        <template v-else>
            Liv. -
        </template>
    </div>
    <div class="player-avatar"
        :style="{ backgroundImage: `url(${player.pic != null ? player.pic : 'https://www.w3schools.com/howto/img_avatar.png'})` }">
        <i class="material-icons icon-remove cursor-pointer" @click="showModal('REMOVE_PLAYER')">highlight_off</i>
    </div>
    <router-link :to="{ name: 'user-details', params: { id: player.id }}" class="name">{{ `${player.name}
        ${player.family_name}` }}</router-link>
</div>

<div class="player-item" v-else>
    <div class="level">&nbsp;</div>
    <div class="empty-avatar cursor-pointer" @click="showModal('ADD_NEW_PLAYER')">
        <i class="material-icons color-blue">add</i>
    </div>
    <div class="name">
        {{ t('add_new_player') }}
    </div>
</div>

<DumbModal :title="t('add_new_player')" @hide="hideModal()" v-if="modalId === 'ADD_NEW_PLAYER'">
    <div class="modal-content flexbox-column justify-space-between">
        <div class="top-part">
            <UsersSearch @userSelected="userSelected" :old="true" />
        </div>
    </div>
</DumbModal>



<DumbModal :title="t('remove_player')" @hide="hideModal()" v-if="modalId === 'REMOVE_PLAYER'">
    <div class="modal-content flexbox-column justify-space-between">
        <div class="top-part">
            <i class="material-icons icon-alert">warning_amber</i>
            <div class="color-white alert-text montserrat">{{t('attention')}}</div>
            <div class="color-white modal-text montserrat" v-html="t('remove_player_message')">
            </div>
        </div>
        <div class="button-row flexbox-row justify-space-between width-100">
            <p class="abort" @click="hideModal()">{{uppercase(t('abort'))}}</p>
            <PadelButton :label="uppercase(t('remove_player'))" @click="removePlayer" />
        </div>
    </div>
</DumbModal>