
<div class="sidenav" @mouseenter="isHover = true" @mouseleave="isHover = false" aria-haspopup="true"
tabindex="0">
    <div class="top">
        <img v-if="isHover" class="logo-img" src="@/theme/imgs/logo_completo.svg" alt="">
        <img v-else class="logo-img" src="@/theme/imgs/logo_mark.svg" alt="">
        <template v-for="button in sideNavButtons">
            <div class="link" @click="goToPage(button.nav)">
                <span class="icon material-icons-outlined" :class="isTabSelected(button.nav)">
                    {{button.icon}}
                </span>
                <span v-if="isHover" :class="isTabSelected(button.nav)">{{uppercase(t(button.label))}}</span>
            </div>
        </template>       

    </div>

    <div class="bottom">
        <div class="link link-bottom" @click="downloadFileFromS3()">
            <span class="icon material-icons" :class="isTabSelected('Guide')">
                help_outline
            </span>
            <span v-if="isHover" :class="isTabSelected('Guide')">{{uppercase(t('guide'))}}</span>
        </div>
        <hr>
        <div class="link link-name"  @click="goToPage('MyProfile')">
            <span class="icon material-icons-outlined" :class="isTabSelected('MyProfile')">
                account_circle
            </span>
            <span v-if="isHover" class="name-info" :class="isTabSelected('MyProfile')">
                <span>{{user.name}}</span>
                <span>{{user.family_name}}</span>
            </span>
        </div>
    </div> 
</div>