<template class="login-container">
    <div class="main-container" >
        <img class="smash-img" src="@/theme/imgs/logo_completo.svg" alt="" >
        <div class="access-wesmash-text color-white font-size barlow"  >{{t("access_wesmash")}}</div>
        <div class="form-container">
            <div class="p-fluid">
                <form>
                    <PadelInput 
                        :placeholder="t('email')" 
                        :label="t('email')" 
                        :value="formValues.loginEmail"
                        :error="formErrors.loginEmail" 
                        @input="updateFormValue($event, 'loginEmail')"
                        :autocomplete="autocomplete"
                        @validate="validate(formValues, 'loginEmail')"
                        @onEnterPressed="handleEnterPressed($event, 'loginEmail')" />

                    <PadelInput 
                        :placeholder="t('password')" 
                        :label="t('password')" 
                        :value="formValues.loginPassword"
                        :error="formErrors.loginPassword" 
                        :inputType="'password'"
                        :autocomplete="autocomplete"
                        @input="updateFormValue($event, 'loginPassword')"
                        @validate="validate(formValues, 'loginPassword')"
                        @autofilled="handleAutofill()"
                        @onEnterPressed="handleEnterPressed($event, 'loginPassword')" />
                </form>
                
            </div>
            <p class="forgot-text" @click="forgotPassword()">{{t('forgot_password')}}</p>
            <PadelButton @click="loginUser(formValues)" :label="uppercase(t('access'))"
                :disabled="!bypassDisabledButton && (!isLoginFormValid(formErrors) || isLoginFormEmpty(formValues))">
            </PadelButton>
            <div class="line">
                <hr />
            </div>
            <h5 class="register-text" @click="goToRegister()" >{{uppercase(t('register_sport_club'))}}</h5>
        </div>
    </div>
</template>

<div v-if="openedModal === 'FIND_ACCOUNT'">
    <ChangePasswordModal @hide="hideModal()" :precompileEmail="formValues.loginEmail" />
</div>

<div v-else-if="openedModal === 'NEW_PASSWORD_REQUIRED'">
    <DumbModal :title="'Reset password'" @hide="hideModal()" style="height: 350px;">
        <div class="inner-div flexbox-column justify-space-between">
            <div>
                <div class="align-initial color-white montserrat line-height-text">{{t('necessary_change_password')}}.</div>
            <PadelInput class="margin-top-20" :placeholder="t('password')" :label="t('password')"
                :value="newPasswordRequiredFormValues.password" :inputType="'password'"
                :error="newPasswordRequiredFormErrors.password"
                @validate="validateNewPasswordRequiredForm(newPasswordRequiredFormValues, 'password')"
                @input="updateNewPasswordRequiredFormValue($event, 'password')" />
            <PadelInput class="margin-top-20" :placeholder="t('passwordConfirmation')"
                :label="t('passwordConfirmation')" :value="newPasswordRequiredFormValues.confirmPassword"
                :inputType="'password'" :error="newPasswordRequiredFormErrors.confirmPassword"
                @validate="validateNewPasswordRequiredForm(newPasswordRequiredFormValues, 'confirmPassword')"
                @input="updateNewPasswordRequiredFormValue($event, 'confirmPassword')" />
            </div>
            
            <div class=" button-container flexbox-row margin-top-20">
                <PadelButton @click="setRequiredPassword()" :label="uppercase(t('send'))"
                    :disabled="!isNewPasswordRequiredFormValid(newPasswordRequiredFormErrors) || isNewPasswordRequiredFormEmpty(newPasswordRequiredFormValues)">
                </PadelButton>
            </div>
        </div>
    </DumbModal>
</div>

<div v-else-if="openedModal === 'DISAMBIGUATION'">
    <Disambiguation :clubs="disambiguationClubs" @hide="hideModal()" @clubSelected="handleClubSelected($event)"></Disambiguation>
</div>