<div class="filters-grid">
    <div class="p-mb-2">
        <div class="dropdown-spacer"
            v-if="!orderable">
            <PadelDropdown 
                @input="setShow($event.value)"
                :label="t('playground_visibility')" 
                :default="selectedVisibility"
                :options="VisiblityOptions" />
        </div>
    </div>
    <div class="p-mb-2">
        <div class="dropdown-spacer"
            v-if="!orderable">
            <PadelDropdown 
                @input="setSport($event.value)"
                :label="t('sport_choice')" 
                :default="selectedSport"
                :options="SportOptions" />
        </div>
    </div>
    <div class="p-mb-2 p-text-center p-text-md-right">
        <ToggleButton v-model="orderable"
            :onLabel="uppercase(t('order_on'))" 
            :offLabel="uppercase(t('order_off'))"
            onIcon="pi pi-pencil"
            offIcon="pi pi-pencil"
            iconPos="right"
            @change="onChangeOrder" />
    </div>
</div>

<div class="playgrounds-container margin-top-0 margin-bottom-10"
    v-if="!orderable">
    <template v-for="(element, index) in currentPlaygrounds" :key="element.id">
        <PlaygroundCard 
            :element="element" 
            :index="index" 
            :canCopy="false" 
            :withActions="true"
            @duplicatePlayground="duplicatePlayground(element)"
            @modifyPlayground="modifyPlayground(element, index)"
            @deletePlayground="deletePlayground(element, index)"  />
    </template>
    <div class="box justify-center" @click="openModal('ADD_PLAYGROUND')">
        <i class="material-icons icon-add color-blue">add</i>
        <p class="font-12 color-blue cursor-pointer bold-text montserrat">{{uppercase(t('add_playground'))}}</p>
    </div>
</div>

<draggable 
    class="playgrounds-container margin-top-0 margin-bottom-10"
    v-model="currentPlaygrounds" 
    @start="drag=true;updatedOrder=true" 
    @end="drag=false" 
    item-key="id"
    v-else>
    <template #item="{element, index}">
        <PlaygroundCard 
            :element="element" 
            :index="index" 
            :canCopy="false" 
            :withActions="false"
            :clickable="false" />
    </template>
</draggable>

<div v-if="modalToShow === 'ADD_PLAYGROUND'">
    <PlaygroundInfoModal :title="isModifyModal? t('modify_playground_title'): t('add_playground_title')" 
        :form="playgroundForm" 
        :isModifyModal="isModifyModal" 
        @hide="hideModal()"
        @formConfirmed="proceedToSavePlayground($event)" />
</div>

<!-- MODALE FOTO MANCANTE -->
<div v-else-if="modalToShow === 'PHOTO_MISSING_MODAL'">
    <DumbModal :title="t('insert_playground_picture')" @hide="openModal('ADD_PLAYGROUND')">
        <div class="modal-content-missing flexbox-column justify-space-between">
            <div class="top-part">
                <i class="material-icons icon-alert">warning_amber</i>
                <div class="color-white alert-text margin-top-30 montserrat">{{t('no_pic_playground_text')}}</div>
            </div>
            <div class="buttons-container flexbox-row justify-space-between">
                <p class="abort" @click="openModal('ADD_PLAYGROUND')">{{uppercase(t('close'))}}</p>
                <div class="flexbox-row">
                    <div class="margin-right-30">
                        <PadelUpload @change="updateFormValue($event, 'profilePicture')"
                            :label="uppercase(t('add_picture'))" :uploadedLabel="uppercase(t('picture_added'))"
                            @wrong-file-uploaded="handleWrongFileUploaded($event)">
                        </PadelUpload>
                    </div>
                    <PadelButton :label="uppercase(t('forward'))" @click="savePlayground(playgroundForm)" />
                </div>
            </div>
        </div>
    </DumbModal>
</div>

<div v-else-if="modalToShow === 'DELETE_PLAYGROUND'">
    <DumbModal :title="t('delete_playground')" @hide="hideModal()">
        <div class="modal-content-missing flexbox-column justify-space-between">
            <div class="top-part">
                <i class="material-icons icon-alert">warning_amber</i>
                <div class="color-white alert-text margin-top-30 montserrat">{{t('attention')}}</div>
                <div v-html="t('delete_playground_text', {field: playgroundToEliminate.name})" class="color-white alert-text montserrat"></div>
            </div>
            <div class="buttons-container flexbox-row justify-space-between">
                <p class="abort" @click="hideModal()">{{uppercase(t('abort'))}}</p>
                <div class="flexbox-row">
                    <PadelButton :label="uppercase(t('confirm'))" @click="confirmDeletePlayground()" />
                </div>
            </div>
        </div>
    </DumbModal>
</div>
<div v-else-if="modalToShow === 'CANNOT_DELETE_PLAYGROUND'">
    <DumbModal :title="t('delete_playground')" @hide="hideModal()">
        <div class="modal-content-missing flexbox-column justify-space-between">
            <div class="top-part">
                <i class="material-icons icon-error">error_outline</i>
                <div class="color-white alert-text margin-top-30 montserrat">{{t('attention')}}</div>
                <div v-html="t('unable_delete_playground_text', {field: playgroundToEliminate.name})" class="color-white alert-text montserrat"></div>
            </div>
            <div class="buttons-container flexbox-row justify-space-between">
                <p class="abort" @click="hideModal()">{{uppercase(t('abort'))}}</p>
                <div class="flexbox-row">
                    <PadelButton :label="uppercase(t('go_to_calendar'))" :type="'secondary'" @click="goToCalendar()" />
                </div>
            </div>
        </div>
    </DumbModal>
</div>

<div v-else-if="modalToShow === 'DUPLICATE_PLAYGROUND'">
    <DumbModal :title="t('duplicate_playground')" @hide="hideModal()">
        <div class="modal-content p-p-2">
            <p class="color-gold">{{ t('new_playground_name') }}</p>
            <PadelInput :label="t('playground_name')"
                @input="duplicateForm.name = $event;"
                :value="duplicateForm.name" />
            <p class="p-text-center color-white" v-html="t('duplicate_playground_message', { original: duplicateForm.original })"></p>
        </div>

        <template #footer>
            <div class="buttons-container flexbox-row justify-space-between">
                <p class="abort" @click="hideModal()">{{uppercase(t('abort'))}}</p>
                <div class="flexbox-row">
                    <PadelButton 
                        :disabled="duplicateForm.name == ''"
                        :label="uppercase(t('confirm'))" 
                        @click="confirmDuplicatePlayground()" />
                </div>
            </div>
        </template>
    </DumbModal>
</div>

<!-- MODALE FOTO MANCANTE -->
<div v-else-if="modalToShow === 'PLAYGROUND_CREATED'">
    <DumbModal :title="t('playground_creation')" @hide="hideModal">
        <div class="modal-content-missing flexbox-column justify-space-between">
            <div class="top-part">
                <img src="@/theme/imgs/regole_prezzi_orari.svg" alt=""
                    class="filter-blue">
                <div class="color-white alert-text margin-top-30 montserrat" v-html="t('playground_creation_message')"></div>
            </div>
            <div class="flexbox-row p-jc-end">
                <div class="flexbox-row">
                    <div class="margin-right-10">
                        <PadelButton 
                            :type="'secondary'"
                            :label="uppercase(t('go_to_rules'))" 
                            @click="goToRules()" />
                    </div>
                    <PadelButton 
                        :label="uppercase(t('got_it'))" 
                        @click="hideModal" />
                </div>
            </div>
        </div>
    </DumbModal>
</div>