
import { PadelClub } from "@/models/club.models";
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import {
  mapPlaygroundToForm,
  uppercase,
  convertPlaygroundFormIntoPayload,
  mySportsByPlaygrounds,
  mapFilteredPlaygrounds,
} from "@/services/mapping.service";
import { computed, defineComponent, onMounted, ref, watch, } from "vue";
import { emptyPlaygroundForm } from "@/constants/initial-values.constants";
import { PadelPlayground } from "@/models/playground.models";
import { PlaygroundForm } from "@/models/forms.models";
import { PlaygroundSports } from "@/constants/playground.enums";
import { ActionTypes } from "@/store/action-types";
import { showToast } from "@/services/error-management.service";
import { ToastErrors } from "@/constants/generic-errors.enum";
import { useRouter } from "vue-router";
import draggable from 'vuedraggable'


export default defineComponent({
  components: {
    draggable,
  },
  name: "ModifyPlaygrounds",
  emits: ['selectTab'],
  setup(props, { emit }) {
    const store = useStore();

    /* COMPUTED */
    const myPlaygrounds = computed(function(): PadelPlayground[] {
      return store.getters.getMyPlaygrounds;
    });
    const mySports = computed(function() {
      return mySportsByPlaygrounds(myPlaygrounds.value);
    });

    const { t, locale } = useI18n();
    const modalToShow = ref(null);
    const playgroundForm = ref({ ...emptyPlaygroundForm });
    const modifyIndex = ref(null);
    const currentPlaygrounds = ref([]);
    const componentKey = ref(0);
    const isModifyModal = ref(false);
    const playgroundToEliminate = ref(null);
    const router = useRouter();
    const duplicateForm = ref({ id: null, name: '', original: '' });
    const createdPlayground = ref(null);
    const selectedVisibility = ref('all');
    const SportOptions  = ref([
      {value: 'all', label: 'all', sport: null},
      ...mySports.value,
    ]);
    const VisiblityOptions  = ref([
      {value: 'all', label: 'all'},
      {value: false, label: 'public'},
      {value: true, label: 'private'},
    ]);
    const selectedSport = ref('all');
    const orderable   = ref(false);
    const drag        = ref(false);
    const updatedOrder = ref(false);


    const myClub = computed(function(): PadelClub {
      return store.getters.getMyClub;
    });

    function initializeForm(data?) {
      if (data) {
        isModifyModal.value = true;
        playgroundForm.value = data.price_for_duration
          ? { ...mapPlaygroundToForm(data) }
          : { ...data };
      } else {
        playgroundForm.value = {
          ...emptyPlaygroundForm,
          sport: PlaygroundSports.PADEL,
        };
      }
      duplicateForm.value = { id: null, name: '', original: '', };
    }

    onMounted(() => {
      initializeForm();
      currentPlaygrounds.value = mapFilteredPlaygrounds(
        myPlaygrounds.value,
        selectedSport.value,
        selectedVisibility.value
      );
    });

    async function getPlaygrounds() {
      try {
        const pl = await store.dispatch(
          ActionTypes.GET_PLAYGROUNDS,
          store.getters.getMyClub.club_id
        );
        currentPlaygrounds.value = mapFilteredPlaygrounds(
          myPlaygrounds.value,
          selectedSport.value,
          selectedVisibility.value
        );
      } catch (error) {
        return;
      }
    }

    function openModal(modal: string) {
      modalToShow.value = modal;
    }

    function initializeValues() {
      playgroundToEliminate.value = null;
      modifyIndex.value = null;
      isModifyModal.value = false;
      modalToShow.value = null;
    }

    function hideModal() {
      initializeValues();
      initializeForm();
      createdPlayground.value = null;
    }

    function modifyPlayground(element, index) {
      initializeForm(element);
      modifyIndex.value = index;
      openModal("ADD_PLAYGROUND");
    }

    function deletePlayground(element, index) {
      playgroundToEliminate.value = { ...element, index: index };
      openModal("DELETE_PLAYGROUND");
    }

    function duplicatePlayground(element) {
      duplicateForm.value = { id: element.id, name: '', original: element.name, };
      openModal("DUPLICATE_PLAYGROUND");
    }

    function refreshAndCloseModal() {
      hideModal();
      getPlaygrounds();
    }

    function eliminatePlayground(index: number) {
      currentPlaygrounds.value.splice(index, 1);
      componentKey.value++;
    }

    async function confirmDeletePlayground() {
      try {
        const response = await store.dispatch(ActionTypes.DELETE_PLAYGROUND, {
          club_id: myClub.value.club_id,
          playground_id: playgroundToEliminate.value.id,
        });
        if(response.conflictEvent) {
          openModal("CANNOT_DELETE_PLAYGROUND");
        } else {
          eliminatePlayground(playgroundToEliminate.value.index);
          refreshAndCloseModal();
        }
        return response;
      } catch (error) {
        return;
      }
/*       hideModal();
      showToast("Eliminazione campo non implementata!"); */
    }

    function addPlaygroundToArray(playground) {
      if (modifyIndex.value === null) {
        currentPlaygrounds.value.push({ ...playground });
      } else {
        currentPlaygrounds.value[modifyIndex.value] = {
          ...playground,
        };
      }
      componentKey.value++;
    }

    async function addNewPlayground(form: PlaygroundForm) {
      try {
        const payload = convertPlaygroundFormIntoPayload(form);
        const club = await store.dispatch(ActionTypes.ADD_PLAYGROUND, {
          club_id: myClub.value.club_id,
          request: { ...payload, handleLoaderInternally: true },
        });
        addPlaygroundToArray({
          ...playgroundForm.value,
          id: club.id,
          profilePicture: {
            name: "cover",
            src:
              form.profilePicture && form.profilePicture.src
                ? form.profilePicture.src
                : null,
          },
        });
        return club;
      } catch (error) {
        return;
      }
    }

    async function modifyExistingPlayground(form: PlaygroundForm) {
      try {
        const payload = convertPlaygroundFormIntoPayload(form);
        const club = await store.dispatch(ActionTypes.CHANGE_PLAYGROUND_INFO, {
          club_id: myClub.value.club_id,
          playground_id: form.id,
          request: payload,
        });
        addPlaygroundToArray({
          ...playgroundForm.value,
          id: club.id,
          profilePicture: {
            name: "cover",
            src:
              form.profilePicture && form.profilePicture.src
                ? form.profilePicture.src
                : null,
          },
        });
        return club;
      } catch (error) {
        return;
      }
    }

    function updateFormValue(value, field) {
      playgroundForm.value[field] = value;
    }

    function handleWrongFileUploaded(file) {
      showToast(ToastErrors.WRONG_FILE_FORMAT);
    }

    async function savePlayground(form: PlaygroundForm) {
      try {
        if (isModifyModal.value) {
          await modifyExistingPlayground(form);
        } else {
          const club = await addNewPlayground(form);
          setTimeout(() => {
            createdPlayground.value = club;
            openModal("PLAYGROUND_CREATED");
          }, 150);
        }
        refreshAndCloseModal();
      } catch(err) {
        return;
      }

    }

    function proceedToSavePlayground(form: PlaygroundForm) {
      if (form.profilePicture || form.picture_ref) {
        savePlayground(form);
      } else {
        openModal("PHOTO_MISSING_MODAL");
      }
    }

    function goToCalendar() {
      router.push("scheduler");
    }

    function goToRules() {
      emit("selectTab", 2);
      hideModal();
    }

    async function execDuplicatePlayground(payload: { id: string; name: string }) {
      try {
        const playground = await store.dispatch(ActionTypes.DUPLICATE_PLAYGROUND, {
          club_id: myClub.value.club_id,
          id: payload.id,
          name: payload.name,
        });
        addPlaygroundToArray({
          ...playground,
          profilePicture: {
            name: "cover",
            src: playground.picture_ref,
          },
        });
        return playground;
      } catch (error) {
        return;
      }
    }

    async function confirmDuplicatePlayground() {
      try {
        await execDuplicatePlayground({ id: duplicateForm.value.id, name: duplicateForm.value.name });
        refreshAndCloseModal();
      } catch(err) {
        return;
      }
    }

    function setShow(value) {
      selectedVisibility.value = value;
    }

    function setSport(value) {
      selectedSport.value = value;
    }

    async function onChangeOrder() {
      if (orderable.value) {
        currentPlaygrounds.value = [...myPlaygrounds.value];
      } else {
        if (!updatedOrder.value) {
          currentPlaygrounds.value = mapFilteredPlaygrounds(
            myPlaygrounds.value,
            selectedSport.value,
            selectedVisibility.value
          );
          return;
        }
        const list = JSON.parse(
          JSON.stringify( currentPlaygrounds.value )
        );
        for (let i = 0; i < list.length; i++) {
          try {
            const data = currentPlaygrounds.value[i];
            const form = data.price_for_duration
              ? { ...mapPlaygroundToForm(data) }
              : { ...data };
            form.sort_index = i + 1;

            // EXECUTE
            await modifyExistingPlayground(form);
          } catch(err) {
            console.error(err);
            return;
          }
        }

        // REFRESH
        getPlaygrounds();
      }
    }

    // WATCH
    watch(selectedVisibility, function(newVal, oldVal) {
      currentPlaygrounds.value = mapFilteredPlaygrounds(
        myPlaygrounds.value,
        selectedSport.value,
        selectedVisibility.value
      );
    });
    watch(selectedSport, function(newVal, oldVal) {
      currentPlaygrounds.value = mapFilteredPlaygrounds(
        myPlaygrounds.value,
        selectedSport.value,
        selectedVisibility.value
      );
    });

    return {
      t,
      locale,
      uppercase,
      myPlaygrounds,
      modalToShow,
      playgroundForm,
      isModifyModal,
      componentKey,
      currentPlaygrounds,
      playgroundToEliminate,
      duplicateForm,
      SportOptions,
      selectedSport,
      VisiblityOptions,
      selectedVisibility,
      orderable,
      drag,
      updatedOrder,

      modifyPlayground,
      deletePlayground,
      duplicatePlayground,
      openModal,
      hideModal,
      savePlayground,
      proceedToSavePlayground,
      updateFormValue,
      handleWrongFileUploaded,
      confirmDeletePlayground,
      confirmDuplicatePlayground,
      goToCalendar,
      goToRules,
      setShow,
      setSport,
      onChangeOrder,
    };
  },
});
