<PadelLateralTab />
<div class="view-main-container">
    <div class="inner-view-container">
        <div class="nav-container flexbox-row justify-space-between padding-bottom-20 padding-top-20">
            <p class="title color-white">{{t('profile')}}</p>
            <Nav :dropdownChoices="dropdownChoices" />
        </div>
        <div class="flexbox-column">
            <div class="button-container color-blue margin-bottom-20">
                <div v-if="formDisabled" class="cursor-pointer flexbox-row align-center" @click="enableForm()">
                    <span class="button-text montserrat">{{uppercase(t('modify_data'))}}</span>
                    <span class="material-icons">mode_edit</span>
                </div>
                <div v-else class="confirm-box flexbox-row ">
                    <div class="cursor-pointer flexbox-row align-center button-abort" @click="disableForm()">
                        <span class="button-text montserrat">{{uppercase(t('abort'))}}</span>
                    </div>
                    <div class="vl"></div>
                    <div class="cursor-pointer flexbox-row align-center button-abort" @click="saveProfileChanges()"
                        :class="saveDisabled || !isChangeInfoFormValid(formErrors) ? 'button-disabled' : '' ">
                        <span class="button-text montserrat">{{uppercase(t('save_modified'))}}</span>
                        <span class="material-icons">save</span>
                    </div>
                </div>
    
            </div>
            <div class="form-title color-white montserrat margin-bottom-40">{{t('manager_data')}}</div>
            <div class="form-container box">
                <div class="input-container">
                    <PadelInput :label="t('name')" :value="formValues.name" :error="formErrors.name"
                        @input="updateFormValue($event, 'name')" @validate="validate(formValues, 'name')"
                        :disabled="formDisabled" />
                </div>
                <div class="input-container">
                    <PadelInput :label="t('surname')" :value="formValues.surname" :error="formErrors.surname"
                        @input="updateFormValue($event, 'surname')" @validate="validate(formValues, 'surname')"
                        :disabled="formDisabled" />
                </div>
                <div class="input-container">
                    <PadelInput :label="t('phonenumber')" :value="formValues.phonenumber" :error="formErrors.phonenumber"
                    :isPhonenumber="true" :forcedPrefix="FIXED_PHONE_PREFIX"
                        @input="updateFormValue($event, 'phonenumber')" @validate="validate(formValues, 'phonenumber')"
                        :disabled="formDisabled" />
                </div>
                <div class="input-container">
                    <PadelInput :label="t('email')" :value="userInfo.email" :disabled="true" :icon="'lock'" />
                </div>
    
            </div>
        </div>
    </div>
    

</div>
<div v-if="confirmPhoneModalOpened">
    <DumbModal :title="t('confirm_code')" @hide="closeOTPModal()">
        <div>
            <div class="confirm-text margin-bottom-40">{{t('insert_sent_code')}}</div>
            <div class="input-container-modal">
                <PadelInput :label="t('phonenumber')" :value="formValues.phonenumber" :disabled="true" />
                <PadelInput :label="t('verify_code')" :inputType="'number'" :value="confirmCodeValue"
                    :error="confirmCodeError" @input="updateCodeValue($event)" @validate="validateCode($event)" />
            </div>
        </div>
        <div class="button-row flexbox-row justify-space-between">
            <p class="color-blue button-text montserrat cursor-pointer" @click="sendCode()">INVIA DI NUOVO</p>
            <PadelButton :label="uppercase(t('confirm'))" :disabled="confirmCodeValue === '' || confirmCodeError != ''"
            @click="confirmPhoneNumber()">
            </PadelButton>
        </div>
    </DumbModal>
</div>