
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import { computed, defineComponent, onMounted, ref, nextTick } from "vue";
import { uppercase, upperCaseFirstLetter } from "@/services/mapping.service";
import { mapBookingEndAt } from "@/services/dates.service";
import { ActionTypes } from "@/store/action-types";
import moment from "moment";
import { BookingType } from "@/constants/booking.enum";

import { PaymentOptions } from "@/constants/payment-choices.constants";

export default defineComponent({
  components: {},
  props: {
    booking: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
  },
  emits: ["hideModal", "creationCompleted"],
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const store = useStore();
    const price = ref(0);
    const taking = ref(0);
    const bookingObject = ref(null);
    const paymentState = ref(null);

    // COMPUTED
    const myClubId = computed(function (): string {
      return store.getters.getMyClub.club_id;
    });

    // SYSTEM
    onMounted(() => {
      nextTick(() => {
        bookingObject.value = JSON.parse(
          JSON.stringify(props.booking.timeslotInfo)
        );

        paymentState.value = bookingObject.value.payment_state;
        price.value = bookingObject.value.price
          ? bookingObject.value.price / 100
          : 0;
        taking.value = bookingObject.value.taking
          ? bookingObject.value.taking / 100
          : 0;
      });
    });

    // METHODS
    function setFormValue(key: string, value) {
      if (key == "price") {
        price.value = value;
        bookingObject.value[key] = value * 100;
      } else if (key == "taking") {
        taking.value = value;
        bookingObject.value[key] = value * 100;
      }
    }

    function hideModal() {
      emit("hideModal", true);
    }

    function updatePaymentState(value) {
      paymentState.value = value;
    }

    async function saveChanges() {
      try {
        // INIT
        const base = JSON.parse(JSON.stringify(bookingObject.value));

        const payload = {
          club_id: myClubId.value,
          booking_id: base.id,
          playground_id: base.playground_id,
          playground_name: base.playground_name,
          apply_all_playground: false,
          date: base.date,
          start_at: base.start_at,
          end_at: base.end_at,
          price: base.price ? base.price : null,
          description: base.notes ? base.notes : "",
          color: base.color,
          payment_state: paymentState.value || null,
          sport_type: base.sport_type,
          taking: base.taking ? base.taking : null,
          type: base.type,
          event_type: base.event_type || undefined,
          bundle_end_at: base.bundle_end_at,
          wesmash_user_id: base.wesmash_user_id || undefined,
          player_name: base.player_name,
          player_family_name: base.player_family_name,
          player_phone_number: base.player_phone_number,
          player_email: base.player_email,
          player_description: base.player_description,
          booking_category: base.booking_category,
        };
        
        if (
          base.type === BookingType.LESSON ||
          base.type === BookingType.BACKOFFICE_LESSON
        ) {
          payload["teacher_name"] = base.teacher_name;
        }

        // SEND
        const bookingResponse = await store.dispatch(
          ActionTypes.MODIFY_BOOKING,
          payload
        );
        emit("creationCompleted", payload);
      } catch (e) {
        console.error(e);
      }
    }

    return {
      t,
      locale,
      uppercase,
      upperCaseFirstLetter,
      setFormValue,
      PaymentOptions,

      // DATA
      bookingObject,
      price,
      taking,
      paymentState,

      // METHODS,
      hideModal,
      saveChanges,
      updatePaymentState,
    };
  },
});
