<div class="box playground-box color-white flexbox-column cursor-pointer" @click="modifyTournament()">
    <span class="icon-delete material-icons" @click.stop="deleteTournament()">delete</span>
    <img v-if="element.picture_ref" :src="element.picture_ref + '?' + new Date().getTime()" class="image" />
    <img v-else-if="club_picture_ref" :src="club_picture_ref" class="image" />
    <img v-else src="@/theme/imgs/default_centro.png" class="image" />
    <div class="box-info-part">
        <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-5">{{element.name}}</p>
        <p class="box-row color-white margin-0 barlow">{{t('typology') + ':'}} <b
                class="montserrat">{{t(element.type)}}</b></p>
        <p class="box-row color-white margin-0 barlow">{{t('start_date') + ':'}} <b
                class="montserrat">{{(formatDateTournament(element.start_at))}}</b></p>
        <div class="counter-container flexbox-row align-center">
            <img src="@/theme/imgs/icon_coppie.svg" alt="">
            <div class="color-white montserrat counter">
                {{`${doubleZeros(element.teams ? element.teams.length : 0)}/`}}<b>{{`${doubleZeros(element.max_couples)}`}}</b>
            </div>
        </div>
        <ProgressBar class="progress-bar-container" :background_color="'black-bg'" :hideCounter="true"
            :value="element.teams ? element.teams.length : 0" :maxValue="element.max_couples" :minValue="element.min_couples" />
    </div>

</div>