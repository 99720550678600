<div v-if="openedModal === 'FIND_ACCOUNT'">
    <DumbModal :title="t('find_your_account')" @hide="hideModal()">
        <div class="inner-div flexbox-column justify-space-between">
            <div class="align-initial color-white montserrat line-height-text">{{t('insert_username_mail')}}</div>
            <PadelInput class="margin-top-20" :placeholder="t('email')" :label="t('email')" :value="findAccountValue"
                :error="findAccountError" @input="updateFindAccountValue($event)"
                @validate="validateFindAccountValue()" />
        </div>
        <div class=" button-container flexbox-row margin-top-20">
            <PadelButton @click="findAccount()" :label="uppercase(t('send'))"
                :disabled="!findAccountValue || findAccountError !== ''">
            </PadelButton>
        </div>
    </DumbModal>
</div>

<div v-else-if="openedModal === 'SEND_NEW_PASSWORD'">
    <DumbModal :title="t('insert_new_password')" @hide="hideModal()">
        <div class="color-white montserrat bold-text margin-bottom-10 line-height-text">
            {{t('code_sent')}}
        </div>
        <div class="color-white montserrat line-height-text">
            {{t('check_your_mail')}}
        </div>
        <div class="form-container margin-top-20">
            <PadelInput :placeholder="t('verify_code')" :label="t('verify_code')" :value="newPasswordFormValues.code"
                :error="newPasswordFormErrors.code" @validate="validateNewPasswordForm(newPasswordFormValues, 'code')"
                @input="updateNewPasswordFormValue($event, 'code')" />
            <PadelInput :placeholder="t('password')" :label="t('password')" :value="newPasswordFormValues.password"
                :inputType="'password'" :error="newPasswordFormErrors.password"
                @validate="validateNewPasswordForm(newPasswordFormValues, 'password')"
                @input="updateNewPasswordFormValue($event, 'password')" />
            <PadelInput :placeholder="t('passwordConfirmation')" :label="t('passwordConfirmation')"
                :value="newPasswordFormValues.confirmPassword" :error="newPasswordFormErrors.confirmPassword"
                @validate="validateNewPasswordForm(newPasswordFormValues, 'confirmPassword')" :inputType="'password'"
                @input="updateNewPasswordFormValue($event, 'confirmPassword')" />

        </div>
        <div class=" button-container flexbox-row">
            <PadelButton @click="changePassword()" :label="uppercase(t('send'))"
                :disabled="!isNewPasswordFormValid(newPasswordFormErrors) || isNewPasswordFormEmpty(newPasswordFormValues)">
            </PadelButton>
        </div>
    </DumbModal>
</div>