import { CompletePasswordPayload, ConfirmSignupForm, ForgotPasswordPayload, LoginForm, SignupForm } from '@/models/forms.models'
import { mapLoginRequest, mapUserInfo } from '@/services/mapping.service'
import { ActionContext, ActionTree } from 'vuex'
import { ActionTypes } from './action-types'
import { MutationTypes } from './mutation-types'
import { Mutations } from './mutations'
import { State } from './state'
import { CognitoUser } from 'amazon-cognito-identity-js';
import {
  handleError,
  //  showToast
} from '@/services/error-management.service';
import { emptyUser } from '@/constants/initial-values.constants';
import { addExpirationToTokens, tokensValid } from '@/services/token-validation.service';
import { GetRequestsPayload, UserFilters } from '@/models/filters.models'
import { ChangeClubInfoRequest } from '@/models/club.models'
import { ClubStatus } from '@/constants/club-status.enum'
// import { ToastErrors } from '@/constants/generic-errors.enum'
import { ErrorMessages } from '@/constants/error.enum'
// import { useStore } from '@/store/store'
// import router from '@/router';
import { CreateTournamentRequest, ModifyTournamentRequest } from '@/models/tournaments.models'
import { adminUserStats, enableOnlinePayment, fetchTransactions, getUsersAxios, nexiSaveConfig, refund } from '@/api/server'


/* PER MOCKARE SPECIFICARE "mock" PER USARE IL SERVER SPECIFICARE "server" */
const apiMode = process.env.VUE_APP_IS_MOCK;
/* eslint-disable */
const {
  signIn, signOut, currentAuthenticatedUser, signUp, confirmSignUp, currentSession, myUserInfo, resendSignUp, forgotPassword,
  forgotPasswordSubmit, completeNewPassword, clubRequests, getUserInfo, changeRequestStatus, myClubs,
  changeClubInfo, getPlaygrounds, getPlaygroundInfo, addPlayground, changePlaygroundInfo, deletePlayground,
  changeProfilePic, updateUserAttributes, verifyUserAttribute, verifySmsCode, postUserInfo, addNewClub,
  getCalendarEvents, getUsers, banUser, unbanUser, getClubStats, getCustomerStats, addBooking, modifyBooking, clubSearch,
  clubDetail, enableClub, disableClub, sendOTP, deleteTimeslot, createTournament,
  modifyTournament, deleteTournament, getTournaments, getTournamentDetails, getTournamentTeams, deleteTeam, uploadScoreboard,
  getPricesRules, getPriceRule, createPricesRule, updatePriceRule, deletePriceRule,
  getExceptionRules, createExceptionRule, updateExceptionRule, deleteExceptionRule,
  duplicatePlayground,
  checkBundle, confirmBundle, updateBundle,
  uploadBookingCSV,
  getCards, getCard, createCard, updateCard, deleteCard,
  getUser, changePlayerCategory,
  getOpenGames, getOpenGame, subscribeToGame, unsubscribeToGame,
} = require(`@/api/${apiMode}/index`);


type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
} & Omit<ActionContext<State, State>, 'commit'>

export interface Actions {
  [ActionTypes.LOGIN](
    { commit }: AugmentedActionContext,
    payload: LoginForm
  ): Promise<any>
  [ActionTypes.CURRENT_AUTHENTICATED_USER](
    { commit }: AugmentedActionContext
  ): Promise<any>
  [ActionTypes.LOGOUT](
    { commit }: AugmentedActionContext
  ): Promise<any>
  [ActionTypes.SIGNUP](
    { commit }: AugmentedActionContext,
    payload: SignupForm
  ): Promise<any>
  [ActionTypes.CONFIRM_SIGNUP](
    { commit }: AugmentedActionContext,
    payload: ConfirmSignupForm
  ): Promise<any>
  [ActionTypes.AUTH_ACTION](
    { commit }: AugmentedActionContext
  ): Promise<any>
  [ActionTypes.CURRENT_SESSION]({ commit }: AugmentedActionContext): Promise<any>
  [ActionTypes.RESEND_CODE]({ commit }: AugmentedActionContext,
    username: string
  ): Promise<any>
  [ActionTypes.FORGOT_PASSWORD]({ commit }: AugmentedActionContext, username: string): Promise<any>
  [ActionTypes.FORGOT_PASSWORD_SUBMIT]({ commit }: AugmentedActionContext, payload: ForgotPasswordPayload): Promise<any>
  [ActionTypes.COMPLETE_NEW_PASSWORD]({ commit }: AugmentedActionContext, payload: CompletePasswordPayload): Promise<any>
  [ActionTypes.CHECK_TOKEN_VALIDITY]({ commit }: AugmentedActionContext): Promise<any>
  [ActionTypes.GET_CLUB_REQUESTS]({ commit }: AugmentedActionContext, filters: GetRequestsPayload): Promise<any>
  [ActionTypes.GET_USER_INFO]({ commit }: AugmentedActionContext, mail: string): Promise<any>
  [ActionTypes.CHANGE_REQUEST_STATUS]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.MY_CLUBS]({ commit }: AugmentedActionContext): Promise<any>
  [ActionTypes.CHANGE_CLUB_INFO]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.GET_PLAYGROUNDS]({ commit }: AugmentedActionContext, club_id: string): Promise<any>
  [ActionTypes.PLAYGROUND_DETAIL]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.ADD_PLAYGROUND]({ commit }: AugmentedActionContext, payload: any): Promise<any>
  [ActionTypes.CHANGE_PLAYGROUND_INFO]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.DELETE_PLAYGROUND]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.CHANGE_PROFILE_PIC]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.UPDATE_USER_ATTR]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.VERIFY_USER_ATTR]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.VERIFY_SMS_CODE]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.POST_USER_INFO]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.ADD_NEW_CLUB]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.GET_CALENDAR_EVENTS]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.GET_USERS]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.GET_USERS_AXIOS]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.BAN_USER]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.UNBAN_USER]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.GET_CLUB_STATS]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.GET_CUSTOMER_STATS]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.ADD_BOOKING]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.MODIFY_BOOKING]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.GET_CLUBS]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.CLUB_DETAIL]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.DISABLE_CLUB]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.ENABLE_CLUB]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.SEND_OTP]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.DELETE_TIMESLOT]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.CREATE_TOURNAMENT]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.MODIFY_TOURNAMENT]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.DELETE_TOURNAMENT]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.GET_TOURNAMENTS]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.GET_TOURNAMENT_DETAILS]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.GET_TOURNAMENT_TEAMS]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.DELETE_TEAM]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.UPLOAD_SCOREBOARD]({ commit }: AugmentedActionContext, payload): Promise<any>

  [ActionTypes.GET_PRICES_RULES]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.GET_PRICE_RULE]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.CREATE_PRICE_RULE]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.UPDATE_PRICE_RULE]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.DELETE_PRICE_RULE]({ commit }: AugmentedActionContext, payload): Promise<any>

  [ActionTypes.GET_EXCEPTION_RULES]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.CREATE_EXCEPTION_RULE]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.UPDATE_EXCEPTION_RULE]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.DELETE_EXCEPTION_RULE]({ commit }: AugmentedActionContext, payload): Promise<any>

  [ActionTypes.DUPLICATE_PLAYGROUND]({ commit }: AugmentedActionContext, payload: any): Promise<any>

  [ActionTypes.BOOKING_BUNDLE_CHECK]({ commit }: AugmentedActionContext, payload: any): Promise<any>
  [ActionTypes.BOOKING_BUNDLE_CONFIRM]({ commit }: AugmentedActionContext, payload: any): Promise<any>

  [ActionTypes.UPLOAD_BOOKING_CSV]({ commit }: AugmentedActionContext, payload: any): Promise<any>

  [ActionTypes.GET_CARDS]({ commit }: AugmentedActionContext, payload: any): Promise<any>
  [ActionTypes.GET_CARD]({ commit }: AugmentedActionContext, payload: any): Promise<any>
  [ActionTypes.CREATE_CARD]({ commit }: AugmentedActionContext, payload: any): Promise<any>
  [ActionTypes.UPDATE_CARD]({ commit }: AugmentedActionContext, payload: any): Promise<any>
  [ActionTypes.DELETE_CARD]({ commit }: AugmentedActionContext, payload: any): Promise<any>

  [ActionTypes.GET_USER]({ commit }: AugmentedActionContext, payload: any): Promise<any>
  [ActionTypes.UPDATE_USER_CATEGORY]({ commit }: AugmentedActionContext, payload: any): Promise<any>

  [ActionTypes.GET_OPEN_GAMES]({ commit }: AugmentedActionContext, payload: any): Promise<any>
  [ActionTypes.GET_OPEN_GAME]({ commit }: AugmentedActionContext, payload: any): Promise<any>
  [ActionTypes.SUBSCRIBE_TO_GAME]({ commit }: AugmentedActionContext, payload: any): Promise<any>
  [ActionTypes.UNSUBSCRIBE_TO_GAME]({ commit }: AugmentedActionContext, payload: any): Promise<any>

  [ActionTypes.ADMIN_GET_USER_STATS]({ commit }: AugmentedActionContext, payload): Promise<any>

  [ActionTypes.NEXI_SAVE_CONFIG]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.ENABLE_ONLINE_PAYMENT]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.FETCH_TRANSACTIONS]({ commit }: AugmentedActionContext, payload): Promise<any>
  [ActionTypes.REFUND_TRANSACTION]({ commit }: AugmentedActionContext, payload): Promise<any>
}


export const actions: ActionTree<State, State> & Actions = {

  /* ACTION LOGIN */
  async [ActionTypes.LOGIN]({ commit }, payload: LoginForm) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const signInInfo: CognitoUser = await signIn({
        username: payload.loginEmail,
        password: payload.loginPassword
      });
      commit(MutationTypes.CLOSE_LOADER)
      return signInInfo
    } catch (error) {
      handleError(error as Error);
      throw error;
    }
  },

  /* ACTION CURRENT USER INFO */
  async [ActionTypes.CURRENT_AUTHENTICATED_USER]({ commit }) {
    commit(MutationTypes.OPEN_LOADER);
    try {
      const returnedObj = await currentAuthenticatedUser();
      commit(MutationTypes.CLOSE_LOADER);
      return returnedObj;
    } catch (error) {
      handleError(new Error(ErrorMessages.ERROR_REFRESH_TOKENS));
      throw error;
    }
  },

  /* ACTION LOGOUT */
  async [ActionTypes.LOGOUT]({ commit }) {
    commit(MutationTypes.OPEN_LOADER);
    try {
      const returnedObj = await signOut();
      commit(MutationTypes.SET_USER, emptyUser);
      commit(MutationTypes.CLOSE_LOADER);
      return returnedObj;
    } catch (error) {
      handleError(error as Error);
      throw error;
    }
  },

  /* ACTION SIGNUP */
  async [ActionTypes.SIGNUP]({ commit }, payload: SignupForm) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const obj = mapLoginRequest(payload);
      const returnedObj = await signUp(obj)
      commit(MutationTypes.CLOSE_LOADER);
      return returnedObj;
    } catch (error) {
      handleError(error as Error);
      throw error;
    }
  },

  /* ACTION CONFIRM SIGNUP */
  async [ActionTypes.CONFIRM_SIGNUP]({ commit }, payload: ConfirmSignupForm) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const returnedObj = await confirmSignUp(payload.username, payload.code);
      commit(MutationTypes.CLOSE_LOADER);
      return returnedObj;
    } catch (error) {
      console.log(error)
      handleError(error as Error);
      throw error;
    }
  },

  /* ACTION CURRENT SESSION */
  async [ActionTypes.CURRENT_SESSION]({ commit }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      let _currentSession = await currentSession();
      commit(MutationTypes.CLOSE_LOADER);
      commit(MutationTypes.WRITE_TOKENS, addExpirationToTokens(_currentSession));
      return _currentSession;
    } catch (error) {
      //FAI LOGOUT DELL'UTENTE          
      handleError(new Error(ErrorMessages.ERROR_REFRESH_TOKENS));
      throw new Error(ErrorMessages.ERROR_REFRESH_TOKENS);
    }
  },

  /* ACTION CHECK_TOKEN_VALIDITY */
  async [ActionTypes.CHECK_TOKEN_VALIDITY]({ commit, getters, dispatch }) {
    let tokens = getters.getTokens;
    let error = false;
    let idToken = null;
    let accessToken = null;
    try {
      idToken = await getters.getTokens.idToken.jwtToken;
      accessToken = await getters.getTokens.accessToken.jwtToken;
    } catch (err) { error = true }
    // commit(MutationTypes.OPEN_LOADER);
    try {
      if (!tokensValid(tokens) || error) {
        const _user = await dispatch(ActionTypes.CURRENT_AUTHENTICATED_USER);
        let _currentSession = await dispatch(ActionTypes.CURRENT_SESSION);
        // commit(MutationTypes.OPEN_LOADER);
        _user.refreshSession(_currentSession.refreshToken, (err, session) => {
          console.log('session', err, session);
          if (err) {
            handleError(new Error(ErrorMessages.ERROR_REFRESH_TOKENS));
          }
          let { idToken, refreshToken, accessToken } = session;
          session = addExpirationToTokens(session);
          return session;
        })
        commit(MutationTypes.WRITE_TOKENS, _currentSession);
        idToken = _currentSession.idToken.jwtToken;
        accessToken = _currentSession.accessToken.jwtToken;
      }
      return {
        idToken: idToken,
        accessToken: accessToken
      };
    }
    catch (error) {
      handleError(new Error(ErrorMessages.ERROR_REFRESH_TOKENS));
      throw new Error(ErrorMessages.ERROR_REFRESH_TOKENS);
    }
  },

  /* ACTION RESEND SIGNUP CODE */
  async [ActionTypes.RESEND_CODE]({ commit }, username: string) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const obj = await resendSignUp(username)
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error);
      throw error;
    }
  },

  /* ACTION FORGOT PASSWORD */
  async [ActionTypes.FORGOT_PASSWORD]({ commit }, username: string) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const obj = await forgotPassword(username)
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error);
      throw error;
    }
  },

  /* ACTION FORGOT PASSWORD SUBMIT */
  async [ActionTypes.FORGOT_PASSWORD_SUBMIT]({ commit }, payload: ForgotPasswordPayload) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const obj = await forgotPasswordSubmit(payload.username, payload.code, payload.new_password)
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error);
      throw error;
    }
  },

  /* ACTION COMPLETE NEW PASSWORD */
  async [ActionTypes.COMPLETE_NEW_PASSWORD]({ commit }, payload: CompletePasswordPayload) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const obj = await completeNewPassword(payload.user, payload.newPassword, payload.requiredAttributes)
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error);
      throw error;
    }
  },

  /* ACTION UPDATE USER ATTRIBUTE */
  async [ActionTypes.UPDATE_USER_ATTR]({ commit }, payload: any) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const obj = await updateUserAttributes(payload.user, payload.attributes)
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION VERIFY USER ATTRIBUTE */
  async [ActionTypes.VERIFY_USER_ATTR]({ commit }, payload: any) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const obj = await verifyUserAttribute(payload.user, payload.userAttribute)
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION AUTH */
  async [ActionTypes.AUTH_ACTION]({ commit, dispatch }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const _userInfo = await myUserInfo(tokens.idToken)
      const mappedInfo = mapUserInfo(_userInfo);
      commit(MutationTypes.CLOSE_LOADER);
      commit(MutationTypes.SET_USER, _userInfo);
      return _userInfo;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION GET CLUB REQUESTS */
  async [ActionTypes.GET_CLUB_REQUESTS]({ commit, dispatch }, filters) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await clubRequests(tokens.idToken, filters);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION GET USER INFO */
  async [ActionTypes.GET_USER_INFO]({ commit, dispatch }, id: string) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await getUserInfo(tokens.idToken, id);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION CHANGE REQUEST STATUS */
  async [ActionTypes.CHANGE_REQUEST_STATUS]({ commit, dispatch }, payload: { status: string, club_id: string }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await changeRequestStatus(tokens.idToken, payload.status, payload.club_id);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION MY CLUBS */
  async [ActionTypes.MY_CLUBS]({ commit, dispatch }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await myClubs(tokens.idToken);
      commit(MutationTypes.CLOSE_LOADER);
      await commit(MutationTypes.WRITE_MY_CLUBS, obj);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION CHANGE CLUB INFO */
  async [ActionTypes.CHANGE_CLUB_INFO]({ commit, dispatch }, payload: { club_id: string, request: ChangeClubInfoRequest }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const club = await changeClubInfo(tokens.idToken, payload.club_id, payload.request);
      commit(MutationTypes.CLOSE_LOADER);
      return club;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION GET PLAYGROUNDS */
  async [ActionTypes.GET_PLAYGROUNDS]({ commit, dispatch }, club_id: string) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const playgroundsObj = await getPlaygrounds(tokens.idToken, club_id);
      //OPERAZIONE PER FILTRARE I CAMPI ELIMINATI
      const filteredArray = playgroundsObj.playgrounds.filter((el) => !el.status || el.status !== ClubStatus.DELETED);
      await commit(MutationTypes.WRITE_MY_PLAYGROUNDS, filteredArray);
      commit(MutationTypes.CLOSE_LOADER);
      return filteredArray;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION PLAYGROUND DETAIL */
  async [ActionTypes.PLAYGROUND_DETAIL]({ commit, dispatch }, payload: {
    club_id: string, playground_id: string
  }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const club = await getPlaygroundInfo(tokens.idToken, payload.club_id, payload.playground_id);
      commit(MutationTypes.CLOSE_LOADER);
      return club;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION ADD PLAYGROUND */
  async [ActionTypes.ADD_PLAYGROUND]({ commit, dispatch }, payload: {
    club_id: string, request: any, handleLoaderInternally: boolean
  }) {
    if (payload.handleLoaderInternally) { commit(MutationTypes.OPEN_LOADER) }
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const club = await addPlayground(tokens.idToken, payload.club_id, payload.request);
      if (payload.handleLoaderInternally) { commit(MutationTypes.CLOSE_LOADER); }
      return club;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION CHANGE PLAYGROUND INFO */
  async [ActionTypes.CHANGE_PLAYGROUND_INFO]({ commit, dispatch }, payload: {
    club_id: string, playground_id: string, request: any
  }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const club = await changePlaygroundInfo(tokens.idToken, payload.club_id, payload.playground_id, payload.request);
      commit(MutationTypes.CLOSE_LOADER);
      return club;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION DELETE PLAYGROUND */
  async [ActionTypes.DELETE_PLAYGROUND]({ commit, dispatch }, payload: {
    club_id: string, playground_id: string
  }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await deletePlayground(tokens.idToken, payload.club_id, payload.playground_id);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION CHANGE PROFILE PICTURE */
  async [ActionTypes.CHANGE_PROFILE_PIC]({ commit, dispatch }, picture: string) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const urlPic = await changeProfilePic(tokens.idToken, picture);
      commit(MutationTypes.CLOSE_LOADER);
      return urlPic;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION CHANGE PROFILE PICTURE */
  async [ActionTypes.VERIFY_SMS_CODE]({ commit, dispatch }, _code: string) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const urlPic = await verifySmsCode(tokens.idToken, { access_token: tokens.accessToken, code: _code });
      commit(MutationTypes.CLOSE_LOADER);
      return urlPic;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION POST USER INFO */
  async [ActionTypes.POST_USER_INFO]({ commit, dispatch }, attributes) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await postUserInfo(tokens.idToken, { attributes: attributes });
      commit(MutationTypes.SET_USER, obj);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION ADD NEW CLUB */
  async [ActionTypes.ADD_NEW_CLUB]({ commit, dispatch }, payload: {
    club: any, token: string
  }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await addNewClub(tokens.idToken, payload);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION GET CALENDAR EVENTS */
  async [ActionTypes.GET_CALENDAR_EVENTS]({ commit, dispatch }, payload: {
    club_id: string,
    date?: string,
    user_id?: string,
    future?: boolean,
    archived?: boolean,
    deleted?: boolean,
    hideLoader?: boolean
  }) {

    if (!payload.hideLoader) {
      commit(MutationTypes.OPEN_LOADER);
    }
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await getCalendarEvents(tokens.idToken, payload);
      if (!payload.hideLoader) {
        commit(MutationTypes.CLOSE_LOADER);
      }
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      if (!payload.hideLoader) {
        commit(MutationTypes.CLOSE_LOADER);
      }
      throw error;
    }
  },

  /* ACTION GET USERS */
  async [ActionTypes.GET_USERS]({ commit, dispatch }, payload: {
    club_id: string, filters: UserFilters
  }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await getUsers(tokens.idToken, payload.club_id, payload.filters);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  async [ActionTypes.GET_USERS_AXIOS]({ commit, dispatch }, payload: {
    club_id: string, filters: UserFilters
  }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await getUsersAxios(tokens.idToken, payload.club_id, payload.filters);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION BAN USER */
  async [ActionTypes.BAN_USER]({ commit, dispatch }, payload: {
    club_id: string, user_id: string
  }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await banUser(tokens.idToken, payload.club_id, payload.user_id);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION UNBAN USER */
  async [ActionTypes.UNBAN_USER]({ commit, dispatch }, payload: {
    club_id: string, user_id: string
  }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await unbanUser(tokens.idToken, payload.club_id, payload.user_id);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION GET CLUB STATISTICS */
  async [ActionTypes.GET_CLUB_STATS]({ commit, dispatch }, payload: {
    club_id: string, start_date: string, handleLoaderInternally: boolean
  }) {
    if (payload.handleLoaderInternally) { commit(MutationTypes.OPEN_LOADER) }
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await getClubStats(tokens.idToken, payload.club_id, payload.start_date);
      if (payload.handleLoaderInternally) { commit(MutationTypes.CLOSE_LOADER); }
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  async [ActionTypes.GET_CUSTOMER_STATS]({ commit, dispatch }, payload: {
    club_id: string,
    start_date: string
  }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await getCustomerStats(tokens.idToken, payload.club_id, payload.start_date);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION ADD BOOKING */
  async [ActionTypes.ADD_BOOKING]({ commit, dispatch }, booking) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await addBooking(tokens.idToken, booking);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION MODIFY BOOKING */
  async [ActionTypes.MODIFY_BOOKING]({ commit, dispatch }, booking) {
    commit(MutationTypes.OPEN_LOADER)
    try {

      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await modifyBooking(tokens.idToken, booking);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, false);
      throw error;
    }
  },

  /* ACTION GET CLUBS */
  async [ActionTypes.GET_CLUBS]({ commit, dispatch }, nameFilter: string) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await clubSearch(tokens.idToken, nameFilter);
      commit(MutationTypes.CLOSE_LOADER);
      return obj.clubs;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION CLUB DETAIL */
  async [ActionTypes.CLUB_DETAIL]({ commit, dispatch }, club_id: string) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await clubDetail(tokens.idToken, club_id);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION DISABLE CLUB */
  async [ActionTypes.DISABLE_CLUB]({ commit, dispatch }, club_id: string) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await disableClub(tokens.idToken, club_id);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION ENABLE CLUB */
  async [ActionTypes.ENABLE_CLUB]({ commit, dispatch }, club_id: string) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await enableClub(tokens.idToken, club_id);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION SEND OTP */
  async [ActionTypes.SEND_OTP]({ commit, dispatch }, phone_number: string) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await sendOTP(tokens.idToken, phone_number);
      commit(MutationTypes.CLOSE_LOADER);
      return obj.otp;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION DELETE TIMESLOT */
  async [ActionTypes.DELETE_TIMESLOT]({ commit, dispatch }, booking) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await deleteTimeslot(tokens.idToken, booking);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },


  /* ACTION CREATE_TOURNAMENT */
  async [ActionTypes.CREATE_TOURNAMENT]({ commit, dispatch }, payload: {
    club_id: string,
    tournament: CreateTournamentRequest
  }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await createTournament(tokens.idToken, payload.club_id, payload.tournament);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, false);
      throw error;
    }
  },

  /* ACTION MODIFY_TOURNAMENT */
  async [ActionTypes.MODIFY_TOURNAMENT]({ commit, dispatch }, payload: {
    club_id: string,
    tournament_id: string,
    tournament: ModifyTournamentRequest
  }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await modifyTournament(tokens.idToken, payload.club_id, payload.tournament_id, payload.tournament);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION DELETE_TOURNAMENT */
  async [ActionTypes.DELETE_TOURNAMENT]({ commit, dispatch }, payload: {
    club_id: string,
    tournament_id: string
  }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await deleteTournament(tokens.idToken, payload.club_id, payload.tournament_id);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION GET_TOURNAMENTS */
  async [ActionTypes.GET_TOURNAMENTS]({ commit, dispatch }, club_id: string) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await getTournaments(tokens.idToken, club_id);
      commit(MutationTypes.CLOSE_LOADER);
      return obj.tournaments;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },


  /* ACTION GET_TOURNAMENTS_DETAILS */
  async [ActionTypes.GET_TOURNAMENT_DETAILS]({ commit, dispatch }, payload: {
    club_id: string,
    tournament_id: string
  }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await getTournamentDetails(tokens.idToken, payload.club_id, payload.tournament_id);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION GET_TOURNAMENT_TEAMS */
  async [ActionTypes.GET_TOURNAMENT_TEAMS]({ commit, dispatch }, payload: {
    club_id: string,
    tournament_id: string
  }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await getTournamentTeams(tokens.idToken, payload.club_id, payload.tournament_id);
      commit(MutationTypes.CLOSE_LOADER);
      return obj.teams;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION DELETE_TEAM */
  async [ActionTypes.DELETE_TEAM]({ commit, dispatch }, payload: {
    club_id: string,
    tournament_id: string,
    booker_id: string
  }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await deleteTeam(tokens.idToken, payload.club_id, payload.tournament_id, payload.booker_id);
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION UPLOAD_SCOREBOARD */
  async [ActionTypes.UPLOAD_SCOREBOARD]({ commit, dispatch }, payload: {
    club_id: string,
    tournament_id: string,
    file: string
  }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await uploadScoreboard(tokens.idToken, payload.club_id, payload.tournament_id, { scoreboard: payload.file });
      commit(MutationTypes.CLOSE_LOADER);
      return obj;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION GET_PRICES_RULES */
  async [ActionTypes.GET_PRICES_RULES]({ commit, dispatch }, payload: object) {

    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await getPricesRules(tokens.idToken, payload);
      commit(MutationTypes.CLOSE_LOADER);
      return obj.data;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION GET_PRICE_RULE */
  async [ActionTypes.GET_PRICE_RULE]({ commit, dispatch }, payload: { id: string }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await getPriceRule(tokens.idToken, payload.id);
      commit(MutationTypes.CLOSE_LOADER);
      return obj.data;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION CREATE_PRICE_RULE */
  async [ActionTypes.CREATE_PRICE_RULE]({ commit, dispatch }, payload: object) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await createPricesRule(tokens.idToken, payload);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      // TODO : Check on 409
      commit(MutationTypes.CLOSE_LOADER);
      // handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION UPDATE_PRICE_RULE */
  async [ActionTypes.UPDATE_PRICE_RULE]({ commit, dispatch }, payload: object) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await updatePriceRule(tokens.idToken, payload);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      // TODO : Check on 409
      commit(MutationTypes.CLOSE_LOADER);
      // handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION DELETE_PRICE_RULE */
  async [ActionTypes.DELETE_PRICE_RULE]({ commit, dispatch }, payload: { id: string }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await deletePriceRule(tokens.idToken, payload.id);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      // TODO : Check on 409
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION GET_EXCEPTION_RULES */
  async [ActionTypes.GET_EXCEPTION_RULES]({ commit, dispatch }, payload: object) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const obj = await getExceptionRules(tokens.idToken, payload);
      commit(MutationTypes.CLOSE_LOADER);
      return obj.data;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION CREATE_EXCEPTION_RULE */
  async [ActionTypes.CREATE_EXCEPTION_RULE]({ commit, dispatch }, payload: object) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await createExceptionRule(tokens.idToken, payload);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      // TODO : Check on 409
      commit(MutationTypes.CLOSE_LOADER);
      // handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION UPDATE_EXCEPTION_RULE */
  async [ActionTypes.UPDATE_EXCEPTION_RULE]({ commit, dispatch }, payload: object) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await updateExceptionRule(tokens.idToken, payload);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      // TODO : Check on 409
      commit(MutationTypes.CLOSE_LOADER);
      // handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION DELETE_EXCEPTION_RULE */
  async [ActionTypes.DELETE_EXCEPTION_RULE]({ commit, dispatch }, payload: { id: string }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await deleteExceptionRule(tokens.idToken, payload.id);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      // TODO : Check on 409
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION DUPLICATE_PLAYGROUND */
  async [ActionTypes.DUPLICATE_PLAYGROUND]({ commit, dispatch }, payload: {
    club_id: string, id: string, name: string
  }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const club = await duplicatePlayground(tokens.idToken, payload.club_id, payload.id, payload.name);
      commit(MutationTypes.CLOSE_LOADER);
      return club;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION BOOKING_BUNDLE_CHECK */
  async [ActionTypes.BOOKING_BUNDLE_CHECK]({ commit, dispatch }, payload: object[]) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await checkBundle(tokens.idToken, payload);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION BOOKING_BUNDLE_CONFIRM */
  async [ActionTypes.BOOKING_BUNDLE_CONFIRM]({ commit, dispatch }, payload: object[]) {
    commit(MutationTypes.OPEN_LOADER);
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await confirmBundle(tokens.idToken, payload);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION UPLOAD_BOOKING_CSV */
  async [ActionTypes.UPLOAD_BOOKING_CSV]({ commit, dispatch }, payload: { club_id: string, id: string, file: File }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await uploadBookingCSV(tokens.idToken, payload.club_id, payload.id, payload.file);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION GET_CARDS */
  async [ActionTypes.GET_CARDS]({ commit, dispatch }, payload: { user_id: string, limit: number, club_id?: string, }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await getCards(tokens.idToken, payload.user_id, payload.limit, payload.club_id);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION GET_CARD */
  async [ActionTypes.GET_CARD]({ commit, dispatch }, payload: { card_id: string }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await getCard(tokens.idToken, payload.card_id);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION CREATE_CARD */
  async [ActionTypes.CREATE_CARD]({ commit, dispatch }, payload: object) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await createCard(tokens.idToken, payload);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION UPDATE_CARD */
  async [ActionTypes.UPDATE_CARD]({ commit, dispatch }, payload: object) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await updateCard(tokens.idToken, payload);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION DELETE_CARD */
  async [ActionTypes.DELETE_CARD]({ commit, dispatch }, payload: object) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await deleteCard(tokens.idToken, payload);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION GET_USER */
  async [ActionTypes.GET_USER]({ commit, dispatch }, payload: { club_id: string, user_id: string }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await getUser(tokens.idToken, payload.club_id, payload.user_id);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION UPDATE_USER_CATEGORY */
  async [ActionTypes.UPDATE_USER_CATEGORY]({ commit, dispatch }, payload: { club_id: string, user_id: string, category: string }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await changePlayerCategory(tokens.idToken, payload.club_id, payload.user_id, payload.category);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION GET_OPEN_GAMES */
  async [ActionTypes.GET_OPEN_GAMES]({ commit, dispatch }, payload: { club_id: string, match_type?: string, status?: string }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await getOpenGames(tokens.idToken, payload.club_id, payload.match_type, payload.status);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION GET_OPEN_GAME */
  async [ActionTypes.GET_OPEN_GAME]({ commit, dispatch }, payload: { id: string }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await getOpenGame(tokens.idToken, payload.id);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION SUBSCRIBE_TO_GAME */
  async [ActionTypes.SUBSCRIBE_TO_GAME]({ commit, dispatch }, payload: { booking_id: string, wesmash_user_id: string, spot: number }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await subscribeToGame(tokens.idToken, payload.booking_id, payload.wesmash_user_id, payload.spot);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* ACTION UNSUBSCRIBE_TO_GAME */
  async [ActionTypes.UNSUBSCRIBE_TO_GAME]({ commit, dispatch }, payload: { booking_id: string, wesmash_user_id: string }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await unsubscribeToGame(tokens.idToken, payload.booking_id, payload.wesmash_user_id);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  async [ActionTypes.ADMIN_GET_USER_STATS]({ commit, dispatch }, payload: { month: string, year: string }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await adminUserStats(tokens.idToken, payload.month, payload.year);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  /* NEXI */
  async [ActionTypes.NEXI_SAVE_CONFIG]({ commit, dispatch }, payload: { club_id: string, mac: string, alias: string }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await nexiSaveConfig(tokens.idToken, payload.club_id, payload.mac, payload.alias);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  async [ActionTypes.ENABLE_ONLINE_PAYMENT]({ commit, dispatch }, payload: { club_id: string, enable: boolean }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await enableOnlinePayment(tokens.idToken, payload.club_id, payload.enable);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  async [ActionTypes.FETCH_TRANSACTIONS]({ commit, dispatch }, payload: { club_id: string, refund: boolean, date: string }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await fetchTransactions(tokens.idToken, payload.club_id, payload.refund, payload.date);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },

  async [ActionTypes.REFUND_TRANSACTION]({ commit, dispatch }, payload: { club_id: string, bookingId: string, refundPercentage: number }) {
    commit(MutationTypes.OPEN_LOADER)
    try {
      const tokens = await dispatch(ActionTypes.CHECK_TOKEN_VALIDITY);
      const response = await refund(tokens.idToken, payload.club_id, payload.bookingId, payload.refundPercentage);
      commit(MutationTypes.CLOSE_LOADER);
      return response;
    } catch (error) {
      handleError(error as Error, true);
      throw error;
    }
  },
}
