
import { defineComponent } from 'vue';
import { doubleZeros } from "@/services/dates.service";
export default defineComponent({
    setup() {
        return { doubleZeros }
    },
    props: {
    value: { type: Number, required: true, default: 0 },
    maxValue: { type: Number, required: true, default: 1 },
    minValue: { type: Number, required: true, default: 0 },
    background_color: {type: String, required: true, default: 'black-bg-secondary'},
    hideCounter: {type: Boolean, required: true, default: false}
  },
  computed: {
      bar__length() {
          let percentage;
          if(this.value < this.maxValue) {
              percentage = ((this as any).value / (this as any).maxValue) * 100;
          } else {
              percentage = 100;
          }          
          return percentage;
      },
      bar_color() {
          if(this.value >= this.minValue) {
              return "blue";
          } else { return "disabled-text"}
      }
  }
});
