import { BookingType } from "@/constants/booking.enum";
import { UserFilters } from "@/models/filters.models";
import { NewPasswordForm, PlaygroundForm } from "@/models/forms.models";
import { BookingDetail, BookingForm } from "@/models/timeslots.models";
import { number, object, ref, string } from "yup";
import * as Yup from 'yup';
import { isDurationOverClosing, isSameOrBefore, isTimeBeforeNow, isToday } from "./dates.service";
import Reference from "yup/lib/Reference";
import { CreateTournamentRequest, TournamentFormDate } from "@/models/tournaments.models";
import { CardTypes } from "@/constants/card-types.enum";


/* SCHEMAS */
export const signupSchemaValidation = object().shape({
    email: string().required().email(),
    password: string().required().min(8).matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/),
    passwordConfirmation: string().oneOf([ref('password'), 'Passwords do not match']).required(),
    name: string().required(),
    surname: string().required(),
    clubName: string().required(),
    clubGeoLoc: object().shape({ address: string().required() }),
});

export const loginFormSchemaValidation = object().shape({
    loginEmail: string().required().email(),
    loginPassword: string().required()
});

export const confirmsignupSchemaValidation = object().shape({
    code: string().transform((cv, ov) => {
        return ov === '' ? undefined : cv;
    }).test('len', 'Must be exactly 6 characters', (val) => { if (val) return val.toString().length === 6 })
});

export const phoneSchemaValidation = object().shape({
    phonenumber: string().transform((cv, ov) => {
        return ov === '' ? undefined : cv;
        //numero valido con prefisso internazionale e numero di cifre da 6 a 14
    }).matches(/^[0-9]{6,14}$/, { message: 'Numero non valido' })
})

export const changeProfileInfoSchemaValidation = object().shape({
    name: string().required(),
    surname: string().required(),
    phonenumber: string().required().transform((cv, ov) => {
        return ov === '' ? undefined : cv;
        //numero valido con prefisso internazionale e numero di cifre da 6 a 14
    }).matches(/^[0-9]{6,14}$/, { message: 'Numero non valido' })
})

export const newPasswordFormSchemaValidation = object().shape({
    code: string().required().transform((cv, ov) => {
        return ov === '' ? undefined : cv;
    }).test('len', 'Must be exactly 6 characters', (val) => { if (val) return val.toString().length === 6 }),
    password: string().required().min(8).matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/),
    confirmPassword: string().oneOf([ref('password'), 'Passwords do not match']).required(),
});

export const validNumberSchema = object().shape({
    user_phone_number: string().required().transform((cv, ov) => {
        return ov === '' ? undefined : cv;
        //numero valido con prefisso internazionale e numero di cifre da 6 a 14
    })
});

export const validCellPhoneNumberSchema = object().shape({
    user_phone_number: string().required().transform((cv, ov) => {
        return ov === '' ? undefined : cv;
        //numero valido con prefisso internazionale e numero di cifre da 6 a 14
    }).matches(/^[0-9]{6,12}$/, { message: 'Numero non valido' })
});

export const findAccountSchemaValidation = object().shape({
    email: string().email()
});

export const newBookingSchemaValidation = object().shape({
    user_email: string().email()
});

export const addClubSchemaValidation = object().shape({
    phoneNumber: string().transform((cv, ov) => {
        return ov === '' ? undefined : cv;
    }),
    email: string().email()
});

export const tournamentsSchemaValidation = object().shape({
    min_couples: number().typeError('you must specify a number').max(ref('max_couples') as Reference<number>),
    max_couples: number().typeError('you must specify a number').min(ref('min_couples') as Reference<number>),
    min_level: number().typeError('you must specify a number').max(ref('max_level') as Reference<number>),
    max_level: number().typeError('you must specify a number').min(ref('min_level') as Reference<number>),
    start_at: Yup.date().required().max(ref('end_at') as Reference<Date>),
    end_at: Yup.date().required().min(ref('start_at') as Reference<Date>),
    registration_end_at: Yup.date().required().max(ref('start_at') as Reference<Date>),
});


export function isFormEmpty(values: any) {
    const isEmptyWithoutCode = (!values.email ||
        !values.password ||
        !values.passwordConfirmation ||
        !values.name ||
        !values.surname ||
        !values.clubName ||
        !values.clubGeoLoc.address)
    return isEmptyWithoutCode;
}

export function isFormValid(errors: any) {
    const validationWithoutCode = (!errors.email &&
        !errors.password &&
        !errors.passwordConfirmation &&
        !errors.name && !errors.surnname &&
        !errors.phonenumber &&
        !errors.clubName &&
        !errors.clubGeoLoc)
    return validationWithoutCode
}

export function isLoginFormValid(errors: any) {
    return !errors.loginEmail && !errors.loginPassword;
}

export function isLoginFormEmpty(values: any) {
    return (!values.loginEmail || !values.loginPassword)
}

export function isNewPasswordFormValid(errors: any) {
    return (!errors.code && !errors.password && !errors.confirmPassword);
}

export function isNewPasswordFormEmpty(values: any) {
    return (!values.code || !values.password || !values.confirmPassword);
}

export function isNewPasswordRequiredFormValid(errors: any) {
    return (!errors.password && !errors.confirmPassword);
}

export function isNewPasswordRequiredFormEmpty(values: any) {
    return (!values.password || !values.confirmPassword);
}

export function isPhoneFormValid(errors: any) {
    return (!errors.phonenumber && !errors.code);
}

export function isPhoneFormEmpty(values: any) {
    return (!values.phonenumber || !values.code);
}

export function isChangeInfoFormValid(errors: any) {
    return (!errors.name && !errors.surname && !errors.phonenumber);
}

export function isAddClubFormEmpty(values: any) {
    return (!values.clubName ||
        !values.clubGeoLoc.address);
}

export function isAddClubFormValid(errors: any) {
    return (!errors.clubName &&
        !errors.clubGeoLoc);
}

export function isChangeClubFormEmpty(values: any) {
    return (!values.phoneNumber || !values.openTime || !values.closeTime || !values.email);
}

export function isChangeClubFormValid(errors: any) {
    return (!errors.openTime && !errors.closeTime && !errors.email);
}


export function formPlaygroundEmpty(form: PlaygroundForm) {
    return (
        !form.name ||
        !form.sport ||
        !form.exposure ||
        !(form.sort_index >= 1 && form.sort_index <= 100)
    );
}

export function formUserFilterEmpty(form: UserFilters) {
    return (
        !form.banned &&
        !form.min_bookings && !form.min_cancellations
    );
}

export function isNewBookingFormEmpty(formType: string, form: BookingForm) {
    if (form.recurring && !form.bundle_end_at) {
        return true;
    } else if (!form.apply_all_playground && !form.playground.id) {
        return true;
    } else if (form.certify_phone_number && !form.user_phone_number) {
        return true;
    } else if (formType === BookingType.BOOKING) {
        return (!form.wesmash_user.id ||
            !form.wesmash_user.email ||
            !form.date ||
            !form.start_at ||
            !form.game_duration ||
            !form.price ||
            !form.color ||
            !form.payment_state)
    } else if (formType === BookingType.BACKOFFICE_BOOKING) {
        return (!form.user_name ||
            !form.user_last_name ||
            !form.user_email ||
            !form.user_phone_number ||
            !form.date ||
            !form.start_at ||
            !form.game_duration ||
            !form.price ||
            !form.color ||
            !form.payment_state)
    } else if (formType === BookingType.BACKOFFICE_BOOKING_DESCRIPTION) {
        return (!form.player_description ||
            !form.date ||
            !form.start_at ||
            !form.game_duration ||
            !form.price ||
            !form.color ||
            !form.payment_state)
    } else if (formType === BookingType.LESSON) {
        return (!form.wesmash_user.id ||
            !form.teacher_name ||
            !form.date ||
            !form.start_at ||
            !form.game_duration ||
            !form.price ||
            !form.color ||
            !form.payment_state)
    } else if (formType === BookingType.BACKOFFICE_LESSON) {
        return (!form.user_name ||
            !form.user_last_name ||
            !form.user_email ||
            !form.user_phone_number ||
            !form.teacher_name ||
            !form.date ||
            !form.start_at ||
            !form.game_duration ||
            !form.price ||
            !form.color ||
            !form.payment_state)
    } else if (formType === BookingType.BACKOFFICE_LESSON_DESCRIPTION) {
        return (!form.player_description ||
            !form.teacher_name ||
            !form.date ||
            !form.start_at ||
            !form.game_duration ||
            !form.price ||
            !form.color ||
            !form.payment_state)
    } else if (formType === BookingType.MAINTENANCE) {
        return (!form.start_date ||
            !form.end_date ||
            !form.start_at ||
            !form.end_at ||
            !form.notes ||
            !form.color)
    } else if (formType === BookingType.CLOSING) {
        return (!form.start_date ||
            !form.end_date ||
            !form.notes ||
            !form.color)
    } else if (formType === BookingType.OTHER) {
        return (!form.start_date ||
            !form.end_date ||
            !form.start_at ||
            !form.end_at ||
            !form.notes ||
            !form.color)
    } else if (formType === BookingType.BACKOFFICE_EVENT) {
        return (!form.start_date ||
            !form.end_date ||
            !form.start_at ||
            !form.end_at ||
            !form.notes ||
            !form.color ||
            !form.event_type ||
            !form.playground)
    } else if (formType === BookingType.BACKOFFICE_OPEN_BOOKING) {
        return (!form.date ||
            !form.start_at ||
            !form.game_duration ||
            !form.price ||
            !form.color ||
            !form.match_type ||
            !form.min_level ||
            !form.max_level);
    } else { return true; }
}

export function isAddFirstClubFormEmpty(form) {
    return (!form.openTime ||
        !form.closeTime ||
        !form.phoneNumber ||
        !form.email)
}

export function isAddFirstClubFormInvalid(form) {
    return (
        !!form.openTime ||
        !!form.closeTime ||
        !!form.phoneNumber ||
        !!form.email
    );
}

export function isFormBookingEmpty(values: BookingDetail): boolean {
    /*
        console.log("Date: ", values.date);
        console.log("Start at: ", values.start_at);
        console.log("Playground: ", values.playground);
        console.log("Playground ID: ", values.playground.id);
        console.log("Game duration: ", values.game_duration);
        console.log("Event type: ", values.type);
        console.log("Price: ", values.price);
        console.log("Payment state: ", values.payment_state);
        console.log("Color: ", values.color);
        console.log("Teacher: ", values.teacher_name);
    */

    return (
        !values.date ||
        !values.start_at ||
        !values.playground ||
        !values.playground.id ||
        !values.game_duration ||
        (values.type !== BookingType.BACKOFFICE_EVENT ? !values.price : false) ||
        (values.type !== BookingType.BACKOFFICE_EVENT ? !values.payment_state : false) ||
        !values.color ||
        (values.type === BookingType.LESSON || values.type === BookingType.BACKOFFICE_LESSON ? !values.teacher_name : false) ||
        (values.type === BookingType.BACKOFFICE_EVENT ? !values.event_type : false)
    )
}

export function isFormBookingValid(errors: any) {
    const index = Object.values(errors).find(val => val != '');
    return (!index);
}

export function isTournamentDatesEmpty(values) {
    return (!values.start_at || !values.end_at || !values.registration_end_at);
}

export function validateDates(values) {
    return (
        (values.start_at && values.end_at) ||
        (values.start_at && values.registration_end_at)
    );
}

export function isFirstStepEmpty(form) {
    return (!form.name ||
        !form.price ||
        !form.type ||
        (form.min_couples != 0 && !form.min_couples) ||
        !form.max_couples ||
        !form.min_level ||
        !form.max_level ||
        !form.description ||
        !form.mode_description);
}

export function isFirstStepValid(errors) {
    return (!errors.min_couples &&
        !errors.max_couples &&
        !errors.min_level &&
        !errors.max_level);
}

export function isSlotEmpty(form: TournamentFormDate) {
    return (!form.date ||
        !form.start_at ||
        !form.end_at ||
        !form.playground);
}

export function isSlotValid(errors) {
    return (!errors.date &&
        !errors.start_at &&
        !errors.end_at);
}

export function isSecondStepEmpty(form) {
    let stepsEmpty = false;
    if (form.dates) {
        form.dates.forEach((el) => {
            if (isSlotEmpty(el)) {
                stepsEmpty = true;
            }
        })
    }

    return (!form.start_at ||
        !form.end_at ||
        !form.registration_end_at ||
        stepsEmpty)
}

export function isSecondStepValid(errors) {
    let stepsValid = true;
    if (errors.dates) {
        errors.dates.forEach((el) => {
            if (!isSlotValid(el)) {
                stepsValid = false;
            }
        })
    }

    return (!errors.start_at &&
        !errors.end_at &&
        !errors.registration_end_at &&
        stepsValid)
}

export function isValidRegistrationEnd(values) {
    return values.registration_end_at.getTime && values.start_at.getTime ?
        values.registration_end_at.getTime() < values.start_at.getTime() :
        true
}

export function formHasValidSlots(slots) {
    for (let i = 0; i < slots.length; i++) {
        const slot = slots[i];
        const validSlots = !!slot.start_at
            && !!slot.end_at
            && (slot.duration_60 || slot.duration_90 || slot.duration_120)
            && (slot.duration_60 ? parseFloat(slot.price_60) > 0 : true)
            && (slot.duration_90 ? parseFloat(slot.price_90) > 0 : true)
            && (slot.duration_120 ? parseFloat(slot.price_120) > 0 : true);
        if (!validSlots) return false;
    }
    return true;
}

export function isFormPriceRuleEmpty(form, DaysOptions) {
    // INIT
    let validSlots = false;
    if (form.slots.length > 0) {
        validSlots = formHasValidSlots(form.slots);
    }
    const validDays = (DaysOptions.monday
        || DaysOptions.tuesday
        || DaysOptions.wednesday
        || DaysOptions.thursday
        || DaysOptions.friday
        || DaysOptions.saturday
        || DaysOptions.sunday)

    // RETURN
    return (!form.club_id ||
        !form.name ||
        !form.start_at ||
        !form.end_at ||
        !form.sport_type ||
        form.playground_ids.length <= 0 ||
        form.slots.length <= 0 ||
        !validSlots ||
        !validDays)
}

export function isFormPriceRuleValid(errors: any) {
    let index = Object.values(errors).find(val => val != '');
    if (!index) return (!index);

    // CHECK OVER SLOTS
    errors.slots.forEach(slot => {
        index = Object.values(slot).find(val => {
            return val != '';
        });
        if (!index) return (!index);
    });

    // GENERIC
    return true;
}

export function isFormExceptionRuleEmpty(form) {
    return (!form.club_id ||
        !form.name ||
        !form.exception_at ||
        !form.sport_type ||
        form.playground_ids.length <= 0 ||
        form.slots.length <= 0)
}

export function isFormExceptionRuleValid(errors: any) {
    let index = Object.values(errors).find(val => val != '');
    if (!index) return (!index);

    // CHECK OVER SLOTS
    errors.slots.forEach(slot => {
        index = Object.values(slot).find(val => val != '');
        if (!index) return (!index);
    });

    // GENERIC
    return true;
}

export function bundleIsFilled(bundle) {
    return bundle.filter(el => !!el.selected == true)
        .filter(el => (el.playground_id == null || parseInt(el.price) < 0))
        .length <= 0;
}

export function isFormCardEmpty(form, edit = false) {
    return (!form.user_id ||
        !form.card_code ||
        !form.type ||
        !form.expiration_date ||
        (edit ? false : !form.club_id) ||
        (edit ? false : !form.club_name) ||
        (form.type === CardTypes.FIT ? !form.rank : false))
}

export function isFormCardValid(errors) {
    const index = Object.values(errors).find(val => val != '');
    return (!index);
}

export function isFormPlayerTypeEmpty(form) {
    return (!form.type ||
        !form.name ||
        !form.code);
}

export function isFormPlayerTypeValid(errors) {
    const index = Object.values(errors).find(val => val != '');
    return (!index);
}