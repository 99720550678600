
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { 
    computed, 
    defineComponent, 
    onMounted, 
    onUnmounted,
    ref,
    nextTick,
    watch,
} from "vue";
import { PadelClub } from "@/models/club.models";
import { PadelUser } from "@/models/user.models";
import { ActionTypes } from "@/store/action-types";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import {
    uppercase,
} from "@/services/mapping.service";
import { MutationTypes } from "@/store/mutation-types";
import UserScheduledTable from "@/components/user-scheduled-table/UserScheduledTable.vue";


export default defineComponent({
    name: "Scheduled",
    components: {
        UserScheduledTable,
    },
    setup() {
        const route     = useRoute()
        const store     = useStore();
        const router    = useRouter();
        const { t, locale } = useI18n();
        const userId    = ref(route.params.id);


        /* COMPUTED */
        const myClub = computed(function(): PadelClub {
            return store.getters.getMyClub;
        });
        const searchUser = computed(function(): PadelUser {
            return store.getters.getSearchUser;
        });

        // DATA

        // LIFECYCLE
        onMounted(() => {
            nextTick(() => {
            });
        });

        onUnmounted(() => {
        });

        /** WATCH */
        
        // FUNCTIONS
        function userSelected(user: PadelUser) {
            if (user) {
                store.commit(MutationTypes.SET_SEARCH_USER, user);
                router.replace({ name: 'scheduled', params: { id: user.id }});
            }
        }

        function onClickEvent(event: any) {
            const item = JSON.parse( JSON.stringify(event) )
            store.commit(MutationTypes.SET_SCROLL_EVENT, item);
            setTimeout(() => {
                router.go(-1);
            }, 100);
        }

        // RETURN
        return {
            t,
            locale,
            router,
            uppercase,
            moment,

            // DATA
            myClub,
            userId,
            searchUser,

            // METHODS
            userSelected,
            onClickEvent,
        };
    },
});
