
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  nextTick,
  watch,
} from "vue";
import {
  uppercase,
  upperCaseFirstLetter,
  returnSearchUserDropdownChoices,
} from "@/services/mapping.service";
import {
  gameDurationOptions,
  matchTypesOptions,
} from "@/constants/radio-options.constants";
import {
  emptyPadelUser,
  emptySchedulerPlayground,
} from "@/constants/initial-values.constants";
import {} from "@/services/dates.service";
import { ActionTypes } from "@/store/action-types";
import {
  CALENDAR_USERS_PAGE_SIZE,
  Levels,
  BookingState,
} from "@/constants/constants.constants";
import { PadelUser } from "@/models/user.models";

export default defineComponent({
  components: {},
  props: {
    bookingForm: {
      type: Object,
      default: null,
    },
    bookingFormErrors: {
      type: Object,
      default: null,
    },
    openingHour: null,
    closingHour: null,
  },
  emits: ["hourValue", "formValue", "switchEndTime"],
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const store = useStore();
    const withEndTime = ref(false);
    const minLevel = 1;
    const maxLevel = 5;
    const levelRange = ref([minLevel, maxLevel]);
    const priceEachPerson = ref("0.0");
    const confirmedBooking = ref(false);

    // COMPUTED
    const myClubId = computed(function (): string {
      return store.getters.getMyClub.club_id;
    });
    const computedMatchTypesOptions = computed(function () {
      return matchTypesOptions.filter(
        (el: any) => el.sport === props.bookingForm.playground.sport
      );
    });
    const minLevelLabel = computed(function () {
      const item = Levels.find((el) => el.value === levelRange.value[0]);
      if (!item) return "n/a";
      return item.label;
    });
    const maxLevelLabel = computed(function () {
      const item = Levels.find((el) => el.value === levelRange.value[1]);
      if (!item) return "n/a";
      return item.label;
    });
    const bookingState = computed(function () {
      return confirmedBooking.value
        ? BookingState.CONFIRMED
        : BookingState.OPEN;
    });

    // SYSTEM
    onMounted(() => {
      nextTick(() => {
        emit("formValue", "isPublic", true);
        emit("formValue", "min_level", minLevel);
        emit("formValue", "max_level", maxLevel);
        emit("switchEndTime", withEndTime.value);
      });
    });

    // METHODS
    function setFormValue(key: string, value) {
      switch (key) {
        case "date":
        case "start_at":
        case "game_duration":
        case "end_at":
          emit("hourValue", key, value);
          break;
        default:
          emit("formValue", key, value);
          break;
      }

      // CHECK
      if (key === "match_type") {
        calcPricePerPlayer();
      } else if (key === "price") {
        calcPricePerPlayer();
      }
    }

    function calcPricePerPlayer() {
      const option = matchTypesOptions.find(
        (el: any) => el.value === props.bookingForm.match_type
      );
      const participants = option ? (option as any).participants : 1;

      priceEachPerson.value = (
        parseInt(props.bookingForm.price) / participants
      ).toFixed(2);
    }

    function switchEndTime(value) {
      withEndTime.value = value;
      emit("switchEndTime", value);
    }

    function levelTooltip(value) {
      return Levels.find((el) => el.value === value).short;
    }

    // WATCH
    watch(levelRange, function () {
      const min = levelRange.value[0];
      const max = levelRange.value[1];
      setFormValue("min_level", min);
      setFormValue("max_level", max);
    });

    watch(confirmedBooking, function () {
      setFormValue("booking_state", bookingState.value);
    });

    return {
      t,
      locale,
      uppercase,
      upperCaseFirstLetter,

      // DATA
      gameDurationOptions,
      computedMatchTypesOptions,
      withEndTime,
      minLevel,
      maxLevel,
      levelRange,
      priceEachPerson,
      minLevelLabel,
      maxLevelLabel,
      confirmedBooking,

      // METHODS
      setFormValue,
      switchEndTime,
      levelTooltip,
    };
  },
});
