export function getDataset(entries, field: string) {
  const data = [];
  entries.forEach((stat) => {
    if (field === "income") {
      data.push(stat[1][field] / 100); //CONVERSION FROM CENTS TO EUROS
    } else {
      data.push(stat[1][field]);// / (1000 * 60 * 60)); //CONVERSION FROM MS TO H 
    }
  });
  return data;
}

export function sumArrayValues(entries: number[]) {
  let sum = 0;
  entries.forEach((el) => sum += el);
  return sum;
}

export function getChartDataset(dataset, field: string) {
  if (field === 'bookings') {
    return [
      {
        label: "Ore Prenotate",
        data: dataset[0],
        backgroundColor: "#C2DFED",
        barPercentage: 0.8,
        stack: "bar",
        minBarLength: 5
      },
      {
        label: "Ore lezione",
        data: dataset[1],
        backgroundColor: "#6BA4D3",
        barPercentage: 0.8,
        stack: "bar",
        minBarLength: 5
      },
    ]
  } else if (field === 'cancellations') {
    return [
      {
        label: "Ore Cancellate",
        data: dataset,
        backgroundColor: "#EDC8BB",
        barPercentage: 0.8,
        stack: "bar",
        minBarLength: 5
      },
    ]
  }
  else if (field === 'income') {
    return [
      {
        label: "Guadagni",
        data: dataset,
        backgroundColor: "#99E0B6",
        barPercentage: 0.8,
        stack: "bar",
        minBarLength: 5
      },
    ]
  }


}