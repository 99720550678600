
import {
  formatItalianPhone,
  mapCognitoError,
  mapInputError,
  uppercase,
} from "@/services/mapping.service";
import { ActionTypes } from "@/store/action-types";
import { useStore } from "@/store/store";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import {
  confirmsignupSchemaValidation,
  phoneSchemaValidation,
  isPhoneFormValid,
  isPhoneFormEmpty,
} from "@/services/form-validation.service";
import { MutationTypes } from "@/store/mutation-types";
import { addExpirationToTokens } from "@/services/token-validation.service";
import { showToast } from "@/services/error-management.service";
import { ToastErrors } from "@/constants/generic-errors.enum";
import { FIXED_PHONE_PREFIX, OTP_WAIT_TIME_IN_SECONDS } from "@/constants/constants.constants";
import { RoleTypes } from "@/constants/role-types.enum";

export default defineComponent({
  name: "PadelConfirmPhone",
  setup() {
    const store = useStore();
    const router = useRouter();
    const formValues = ref({ phonenumber: "", code: "" });
    const formErrors = ref({ phonenumber: "", code: "" });
    const signInInfo = ref(null);
    const codeSchema = confirmsignupSchemaValidation;
    const phoneSchema = phoneSchemaValidation;
    const focusOnPhone = ref(false);
    const phoneChanged = ref(true);
    const sendCodeAllowed = ref(true);
    const codeInputId = ref(null);
    const { t, locale } = useI18n();

    /* COMPUTED */
    const confirmSignupUser = computed(function() {
      return store.getters.getConfirmSignupUser;
    });

    const userInfo = computed(function() {
      return store.getters.getUser;
    });

    //FLUSSO DI CHIAMATE PER TRIGGERARE LA CONFERMA DEL NUMERO DI TELEFONO
    onMounted(async () => {
      try {
        const _signInInfo = await store.dispatch(ActionTypes.LOGIN, {
          loginEmail: confirmSignupUser.value.username,
          loginPassword: confirmSignupUser.value.password,
        });
        const currentSession = addExpirationToTokens(
          _signInInfo.signInUserSession
        );
        store.commit(MutationTypes.WRITE_TOKENS, currentSession);
        signInInfo.value = _signInInfo;
      } catch (error) {
        return;
      }
    });

    /* FUNCTIONS */
    function goBack() {
      router.back();
    }

    function redirect(page: string) {
      router.push(page);
    }

    function updateFormError(value: string, field) {
      formErrors.value[field] = value;
    }

    function resetCodeValueAndError() {
      formValues.value.code = "";
      formErrors.value.code = "";
    }

    function validateCode(value) {
      if (value.length > 0) {
        codeSchema
          .validateAt("code", { code: value })
          .then(() => {
            updateFormError("", "code");
          })
          .catch((err) => {
            updateFormError(mapInputError(err.message, t), "code");
          });
      }
    }

    function validatePhone(value) {
      resetCodeValueAndError();
      phoneChanged.value = true;
      phoneSchema
        .validateAt("phonenumber", { phonenumber: value })
        .then(() => {
          updateFormError("", "phonenumber");
        })
        .catch((err) => {
          updateFormError(mapInputError(err.message, t), "phonenumber");
        });
    }

    function validate(value, field) {
      if (field === "code") {
        validateCode(value);
      } else {
        validatePhone(value);
      }
    }

    function updateFormValue(event, field) {
      formValues.value[field] = event;
    }

    function showWaitError() {
      showToast(ToastErrors.WAIT);
    }

    function blockSendCodeForSeconds(seconds: number) {
      if (sendCodeAllowed.value) {
        sendCodeAllowed.value = false;
        setTimeout(() => {
          sendCodeAllowed.value = true;
        }, seconds * 1000);
      }
    }

    function changeFocusOnNextInput() {
      if(codeInputId.value) {
        const codeInput = document.getElementById(codeInputId.value);
        codeInput.focus();
      }
    }

    async function sendCode() {
      if (sendCodeAllowed.value) {
        phoneChanged.value = false;
        try {
          blockSendCodeForSeconds(OTP_WAIT_TIME_IN_SECONDS);
          await store.dispatch(ActionTypes.VERIFY_USER_ATTR, {
            user: signInInfo.value,
            userAttribute: "phone_number",
          });
          changeFocusOnNextInput()
        } catch (error) {
          updateFormError(mapCognitoError(error, t), "phonenumber");
        }
      } else {
        showWaitError();
      }
    }

    async function sendPhoneNumberAndCode() {
      if (sendCodeAllowed.value) {
        try {
          await store.dispatch(ActionTypes.UPDATE_USER_ATTR, {
            user: signInInfo.value,
            attributes: { phone_number: formatItalianPhone(formValues.value.phonenumber) },
          });
          await sendCode();
        } catch (error) {
          updateFormError(mapCognitoError(error, t), "phonenumber");
        }
      } else {
        showWaitError();
      }
    }

    async function confirmPhoneNumber() {
      try {
        await store.dispatch(
          ActionTypes.VERIFY_SMS_CODE,
          formValues.value.code
        );
        //CHECK IF ADMIN GO TO ADMIN
        if (userInfo.value.groups.indexOf(RoleTypes.ADMIN) > -1) {
          redirect("admin");
        } else {
          store.commit(MutationTypes.WRITE_ALLOW_DOWNLOAD, true);
          redirect("download-contract");
        }
      } catch (error) {
        if (error.message === "invalid code") {
          updateFormError("Codice errato", "code");
        } else {
          updateFormError("Reinserire codice", "code");
        }
      }
    }

    function toggleFocus(event) {
      focusOnPhone.value = event;
    }

    const returnCodeDisabled = computed(function(): boolean {
      return (
        !formValues.value.phonenumber ||
        formErrors.value.phonenumber.length > 0 ||
        phoneChanged.value
      );
    });

    function validatePhoneAndSendCode(event) {
      resetCodeValueAndError();
      phoneChanged.value = true;
      phoneSchema
        .validateAt("phonenumber", { phonenumber: event })
        .then(() => {
          updateFormError("", "phonenumber");
          sendPhoneNumberAndCode();
        })
        .catch((err) => {
          updateFormError(mapInputError(err.message, t), "phonenumber");
        });

    }

    function validateCodeAndConfirm(event) {
      if (event.length > 0) {
        codeSchema
          .validateAt("code", { code: event })
          .then(() => {
            updateFormError("", "code");
            updateFormValue(event, "code")
            confirmPhoneNumber();
          })
          .catch((err) => {
            updateFormError(mapInputError(err.message, t), "code");
          });
      }
    }

    function saveCodeInputId(event) {
      codeInputId.value = event;
    }

    return {
      t,
      locale,
      validate,
      redirect,
      uppercase: uppercase,
      goBack: goBack,
      formValues,
      formErrors,
      updateFormValue,
      sendCode,
      sendPhoneNumberAndCode,
      confirmPhoneNumber,
      toggleFocus,
      focusOnPhone,
      phoneChanged,
      returnCodeDisabled,
      isPhoneFormValid,
      isPhoneFormEmpty,
      FIXED_PHONE_PREFIX,
      validatePhoneAndSendCode,
      validateCodeAndConfirm,
      saveCodeInputId
    };
  },
});
