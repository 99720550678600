export enum CardTypes {
    FIT = 'FIT',
    CSAIN = "CSAIN",
    CSEN = "CSEN",
    CSI = "CSI",
    CUSI = "CUSI",
    ENDAS = "ENDAS",
    MSP = "MSP",
    PGS = "PGS",
    ACSI = "ACSI",
    UISP = "UISP",
    USACLI = "USACLI",
    ASC = 'ASC',
    CNS_LIBERTAS = 'CNS_LIBERTAS',
    OPES = 'OPES',
}

export enum CardLabels {
    FIT     = "FIT - Federazione Italiana Tennis",
    CSAIN   = "CSAIN - Centri Sportivi Aziendali Industriali",
    CSEN    = "CSEN - Centro Sportivo Educativo Nazionale",
    CSI     = "CSI - Centro Sportivo Italiano",
    CUSI    = "CUSI - Centro Universitario Sportivo Italiano",
    ENDAS   = "ENDAS - Ente Nazionale Democratico di Azione Sociale e Sportiva",
    MSP     = "MSP - Movimento Sportivo Popolare Italia",
    PGS     = "PGS - Polisportive Giovanili Salesiane",
    ACSI    = "ACSI - Associazione Centri Sportivi Italiani",
    UISP    = "UISP - Unione Italiana Sport Per tutti",
    USACLI  = "USACLI - Unione Sportiva ACLI",
    ASC     = "ASC - Attività Sportive Confederate",
    CNS_LIBERTAS = "CNS LIBERTAS - Centro Nazionale Sportivo Libertas",
    OPES    = "OPES - Organizzazione Per l'Educazione allo Sport",
}

export enum CardCategories {
    INSTITUTIONAL = 'INSTITUTIONAL',
    COMMERCIAL = 'COMMERCIAL',
}