
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  uppercase,
  upperCaseFirstLetter,
  convertPlaygroundFormIntoPayload,
} from "@/services/mapping.service";
import { useRouter } from "vue-router";
import { emptyPlaygroundForm } from "@/constants/initial-values.constants";
import { PlaygroundForm } from "@/models/forms.models";
import { doubleZeros } from "@/services/dates.service";
import {
  sportOptions,
  playgroundsOptions,
  gameDurationOptions,
} from "@/constants/radio-options.constants";
import { showToast } from "@/services/error-management.service";
import { ToastErrors } from "@/constants/generic-errors.enum";
import { useStore } from "@/store/store";
import { ActionTypes } from "@/store/action-types";
import {
  PlaygroundSports,
  SizesOptions,
} from "@/constants/playground.enums";
import { returnNavTooltipChoices } from "@/services/routing.service";
import { MutationTypes } from "@/store/mutation-types";

export default defineComponent({
  name: "AddPlaygrounds",
  setup() {
    const router = useRouter();
    const modalToShow = ref(null);
    const addPlaygroundForm = ref(emptyPlaygroundForm);
    const modifyIndex = ref(null);
    const isModifyModal = ref(false);
    const showPreview = ref(false);
    const currentPlaygrounds = ref([]);
    const sportRadioValues = ref(sportOptions);
    const playgroundRadioValues = ref(playgroundsOptions);
    const durationRadioValues = ref({...gameDurationOptions});
    const fieldTypesValues = ref(SizesOptions);
    const dropdownChoices = ref(returnNavTooltipChoices());
    const store = useStore();
    const { t, locale } = useI18n();

    const myClub = computed(function() {
      return store.getters.getMyClub;
    });

    function initializeForm(data?: PlaygroundForm) {
      if (data) {
        isModifyModal.value = true;
        addPlaygroundForm.value = { ...data };
      } else {
        addPlaygroundForm.value = {
          id: "",
          name: "",
          playground_size: "",
          hidden: false,
          sport: PlaygroundSports.PADEL,
          exposure: "",
          profilePicture: null,
          ground: null,
          walls: null,
          sort_index: 100,
        };
      }
    }

    onMounted(() => {
      initializeForm();
    });

    async function sendForm() {
      if (currentPlaygrounds.value.length > 0) {
        store.commit(MutationTypes.OPEN_LOADER, null)
        for (const pl of currentPlaygrounds.value) {
          try {
            const payload = convertPlaygroundFormIntoPayload(pl);
            await store.dispatch(ActionTypes.ADD_PLAYGROUND, {
              club_id: myClub.value.club_id,
              request: {...payload, handleLoaderInternally: false},
            });
          } catch (error) {
            store.commit(MutationTypes.CLOSE_LOADER, null);
            router.push("scheduler");
            return;
          }
        }
      }
      store.commit(MutationTypes.CLOSE_LOADER, null);
      router.push("scheduler");
    }

    function goBack() {
      router.back();
    }

    function openMissingPhotoModal() {
      modalToShow.value = "PHOTO_MISSING_MODAL";
    }

    function openAddPlaygroundModal() {
      modalToShow.value = "ADD_PLAYGROUND";
    }

    function hideModal() {
      isModifyModal.value = false;
      modalToShow.value = null;
      initializeForm();
    }

    function initializeAfterSave() {
      initializeForm();
      hideModal();
      modifyIndex.value = null;
    }

    function addPlaygroundToArray() {
      if (modifyIndex.value === null) {
        currentPlaygrounds.value.push({ ...addPlaygroundForm.value });
      } else {
        currentPlaygrounds.value[modifyIndex.value] = {
          ...addPlaygroundForm.value,
        };
      }
      initializeAfterSave();
    }

    function savePlayground(event) {
      addPlaygroundForm.value = { ...event };
      if (event.profilePicture) {
        addPlaygroundToArray();
      } else {
        openMissingPhotoModal();
      }
    }

    function uploadDefaultPicAndSendForm() {
      addPlaygroundToArray();
    }

    function updateFormValue(value, field) {
      addPlaygroundForm.value[field] = value;
    }

    function modifyPlayground(element: PlaygroundForm, index: number) {
      initializeForm(element);
      modifyIndex.value = index;
      openAddPlaygroundModal();
    }

    function copyPlayground(element: PlaygroundForm) {
      initializeForm({ ...element, name: element.name + " - copia" });
      openAddPlaygroundModal();
    }

    function deletePlayground(index: number) {
      currentPlaygrounds.value.splice(index, 1);
    }

    function closePreview() {
      showPreview.value = false;
    }

    function openPreview() {
      showPreview.value = true;
    }

    function formEmpty() {
      return (
        !addPlaygroundForm.value.name ||
        !addPlaygroundForm.value.sport ||
        !addPlaygroundForm.value.playground_size ||
        !addPlaygroundForm.value.exposure
      );
    }

    function handleWrongFileUploaded(file) {
      showToast(ToastErrors.WRONG_FILE_FORMAT);
    }

    return {
      t,
      locale,
      sportRadioValues,
      playgroundRadioValues,
      durationRadioValues,
      fieldTypesValues,
      uppercase,
      sendForm,
      goBack,
      showPreview,
      isModifyModal,
      openAddPlaygroundModal,
      modalToShow,
      hideModal,
      savePlayground,
      addPlaygroundForm,
      updateFormValue,
      formEmpty,
      currentPlaygrounds,
      modifyPlayground,
      copyPlayground,
      doubleZeros,
      uploadDefaultPicAndSendForm,
      openPreview,
      closePreview,
      deletePlayground,
      handleWrongFileUploaded,
      upperCaseFirstLetter,
      dropdownChoices,
    };
  },
});
