
import { defineComponent } from "vue";

export default defineComponent({
  name: "PadelTextarea",
  data() {
    return {
      focus: false,
      textValue: this.value,
      charactersRemaining: 0,
      id: "padel-textarea-" + Math.floor(Math.random() * 100000000),
    };
  },
  emits: ["input"],
  props: {
    value: { type: String, required: false, default: "" },
    label: { type: String, required: false, default: "Note" },
    maxLength: { type: Number, required: false, default: 500 },
    showWordCount: { type: Boolean, required: false, default: true },
    disabled: { type: Boolean, required: false, default: false },
  },
  computed: {
    get__color() {
      if (this.disabled) {
        return "color__disabled";
      } else if (this.focus) {
        return "color__blue";
      } else {
        return "color__grey";
      }
    },
    get__border() {
      if (this.disabled) {
        return "border__disabled";
      } else if (this.focus) {
        return "border__blue";
      } else {
        return "border__grey";
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.textValue = this.value;
      this.charactersRemaining =
        (this.maxLength as number) - ((this.value as string) || "").length;
      this.adjustHeight();
    });
  },
  watch: {
    textValue(newVal) {
      this.charactersRemaining = (this.maxLength as number) - newVal.length;
      if (this.charactersRemaining >= 0) {
        this.$emit("input", newVal);
      }
      // this.adjustHeight();
    },
  },
  methods: {
    adjustHeight() {
      // const el = document.getElementById(this.id);
      /* o.style.height = "1px"; */
      // setTimeout(function() {
      //   el.style.cssText = "height:auto;";
      //   el.style.cssText = "height:" + el.scrollHeight + "px";
      // }, 0);
    },
    toggleFocus() {
      this.focus = !this.focus;
    },
    clearText(value) {
      this.textValue = value;
    },
  },
});
