<div class="custom-select" :class="select__class" :tabindex="tabindex" @blur="open = false">
    <div class="selected-row" :class="{ open: open }" @click="handleClick()">
        <div class="selected">
            {{ getLabel() }}
        </div>
        <span class="material-icons" :class="color__error">
            {{ getIcon() }}
        </span>
    </div>
    <div v-if="error" class="error-container color-error">
        {{error}}
    </div>
    <div class="items" :class="items__class" :id="id">
        <div class="outer" v-for="(option, i) of options" :key="i" @click="
          selected = option;
          open = false;
          $emit('input', option);
        " :class="{'option-selected': option.value === getValue()}">
            <div class="inner">
                <div class="inner-color" v-if="option.color">
                    <div class="color" :style="`background-color: ${option.color}`">
                    </div>
                    {{ trans(option.label) }}
                </div>
                <template v-else>{{ trans(option.label) }}</template>                
            </div>
        </div>
    </div>
    <label class="label" :class="label__class" @click="handleClick()">{{ label }}</label>
</div>