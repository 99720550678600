<div class="modify-container flexbox-column">
    <div class="first-row flexbox-row justify-space-between">
        <div class="image-container" :class="formDisabled? 'image-disabled': ''" @click="openChangePicModal()">
            <img v-if="formValues.profilePicture && formValues.profilePicture.src" class="club-img"
            :src="formValues.profilePicture.src" alt="">
            <img v-else-if="formValues.picture_ref" class="club-img"
                :src="formValues.picture_ref + '?' + new Date().getTime()" alt="">           
            <img v-else class="club-img" src="@/theme/imgs/default_centro.png">
            <span class="material-icons edit-img-icon">mode_edit</span>
        </div>
        <div class="button-container color-blue margin-bottom-20">
            <div v-if="formDisabled" class="cursor-pointer flexbox-row align-center" @click="enableForm()">
                <span class="button-text montserrat">{{uppercase(t('modify'))}}</span>
                <span class="material-icons">mode_edit</span>
            </div>
            <div v-else class="confirm-box flexbox-row ">
                <div class="cursor-pointer flexbox-row align-center button-abort" @click="disableForm()">
                    <span class="button-text montserrat">{{uppercase(t('abort'))}}</span>
                </div>
                <div class="vl"></div>
                <div class="cursor-pointer flexbox-row align-center button-abort" @click="saveClubChanges()"
                    :class="isChangeClubFormEmpty(formValues) || !isChangeClubFormValid(formErrors) ? 'button-disabled' : '' ">
                    <span class="button-text montserrat">{{uppercase(t('save_modified'))}}</span>
                    <span class="material-icons">save</span>
                </div>
            </div>

        </div>
    </div>
    <div class="form-container margin-top-20">
        <div class="form-row">
            <div class="flex-item">
                <PadelInput :label="t('clubName')" :disabled="true" :value="myClub.name" :icon="'lock'" />
            </div>
            <div class="flex-item flex-center-item">
                <PadelInput :label="t('clubAddress')" :disabled="true" :value="myClub.location.address"
                    :icon="'lock'" />
            </div>
            <div class="flex-item">
                <PadelInput :value="formValues.email" :label="t('club_email')" @validate="validateForm('email')"
                :error="formErrors.email"
                    :key="componentKey" @input="updateFormValue($event, 'email')" :disabled="formDisabled" />
            </div>
        </div>
        <div class="form-row">
            <div class="flex-item">
                <PadelInput :value="formValues.phoneNumber" :label="t('club_phonenumber')" 
                @validate="validateForm('email')" :isPhonenumber="true"
                    :key="componentKey" @input="updateFormValue($event, 'phoneNumber')" :disabled="formDisabled" />
            </div>
            <div class="flex-item flex-center-item">
                <PadelTimepicker :value="formValues.openTime" :label="t('opening_hour')" :key="componentKey"
                    :error="formErrors.openTime" :disabled="formDisabled"
                    @input="updateFormTimeValue($event, 'openTime')" />
            </div>
            <div class="flex-item">
                <PadelTimepicker :value="formValues.closeTime" :label="t('closing_hour')" :key="componentKey"
                    :error="formErrors.closeTime" :disabled="formDisabled"
                    @input="updateFormTimeValue($event, 'closeTime')" />
            </div>
        </div>
    </div>

    <div class="services-container">
        <p class="title-description align-initial color-white montserrat">{{t('available_services')}}</p>
        <div class="form-row checkbox-container">
            <div class="flex-item flexbox-row align-center"
                :class="!services.LOCKER_ROOM && formDisabled ? 'add-opacity': ''">
                <PadelCheckbox v-model:checked="services.LOCKER_ROOM" :disabled="formDisabled" :key="componentKey" />
                <i class="icon icon-locker color-gold margin-left-10 margin-right-10"></i>
                <p class="color-white montserrat title-description cursor-pointer"
                :class="formDisabled? 'pointer-none': ''" @click="services.LOCKER_ROOM = !services.LOCKER_ROOM">
                {{uppercase(t('changing_rooms'))}}</p>
            </div>
            <div class="flex-item flex-center-item flexbox-row align-center"
                :class="!services.SHOWERS && formDisabled ? 'add-opacity': ''">
                <PadelCheckbox v-model:checked="services.SHOWERS" :disabled="formDisabled" :key="componentKey" />
                <i class="icon icon-shower color-gold margin-left-10 margin-right-10"></i>
                <p class="color-white montserrat title-description cursor-pointer"
                :class="formDisabled? 'pointer-none': ''" @click="services.SHOWERS = !services.SHOWERS">{{uppercase(t('showers'))}}</p>
            </div>
            <div class="flex-item flexbox-row align-center"
                :class="!services.PARKING && formDisabled ? 'add-opacity': ''">
                <PadelCheckbox v-model:checked="services.PARKING" :disabled="formDisabled" :key="componentKey" />
                <i class="icon icon-parking color-gold margin-left-10 margin-right-10"></i>
                <p class="color-white montserrat title-description cursor-pointer"
                :class="formDisabled? 'pointer-none': ''" @click="services.PARKING = !services.PARKING">{{uppercase(t('parking'))}}</p>
            </div>
            <div class="flex-item flexbox-row align-center"
                :class="!services.TOURNAMENTS && formDisabled ? 'add-opacity': ''">
                <PadelCheckbox v-model:checked="services.TOURNAMENTS" :disabled="formDisabled" :key="componentKey" />
                <i class="icon icon-cup color-gold margin-left-10 margin-right-10"></i>
                <p class="color-white montserrat title-description cursor-pointer"
                :class="formDisabled? 'pointer-none': ''" @click="services.TOURNAMENTS = !services.TOURNAMENTS"> {{uppercase(t('tournaments'))}}</p>
            </div>
            <div class="flex-item flex-center-item flexbox-row align-center"
                :class="!services.RENTAL && formDisabled ? 'add-opacity': ''">
                <PadelCheckbox v-model:checked="services.RENTAL" :disabled="formDisabled" :key="componentKey" />
                <i class="icon icon-rent color-gold margin-left-10 margin-right-10"></i>
                <p class="color-white montserrat title-description cursor-pointer"
                :class="formDisabled? 'pointer-none': ''" @click="services.RENTAL = !services.RENTAL">{{uppercase(t('renting'))}}</p>
            </div>
            <div class="flex-item flexbox-row align-center" :class="!services.BAR && formDisabled ? 'add-opacity': ''">
                <PadelCheckbox v-model:checked="services.BAR" :disabled="formDisabled" :key="componentKey" />
                <i class="icon icon-bar color-gold margin-left-10 margin-right-10"></i>
                <p class="color-white montserrat title-description cursor-pointer"
                :class="formDisabled? 'pointer-none': ''" @click="services.BAR = !services.BAR">{{uppercase(t('bar'))}}</p>
            </div>
            <div class="flex-item flex-center-item flexbox-row align-center"
                :class="!services.RESTAURANT && formDisabled ? 'add-opacity': ''">
                <PadelCheckbox v-model:checked="services.RESTAURANT" :disabled="formDisabled" :key="componentKey" />
                <i class="icon icon-restaurant color-gold margin-left-10 margin-right-10"></i>
                <p class="color-white montserrat title-description cursor-pointer"
                :class="formDisabled? 'pointer-none': ''" @click="services.RESTAURANT = !services.RESTAURANT">{{uppercase(t('restaurant'))}}</p>
            </div>
            <div class="flex-item flexbox-row align-center" 
                :class="!services.SWIMMING_POOL && formDisabled ? 'add-opacity': ''">
                <PadelCheckbox v-model:checked="services.SWIMMING_POOL" :disabled="formDisabled" :key="componentKey" />
                <i class="icon icon-swimming-pool color-gold margin-left-10 margin-right-10"></i>
                <p class="color-white montserrat title-description cursor-pointer"
                :class="formDisabled? 'pointer-none': ''" @click="services.SWIMMING_POOL = !services.SWIMMING_POOL">{{uppercase(t('swimming_pool'))}}</p>
            </div>
        </div>
    </div>
    <div class="msg-container flexbox-column margin-top-20">
        <div class="tooltip-row flexbox-row align-center">
            <p class="title-description align-initial color-white montserrat">{{t('players_message')}}</p>
            <div class="position-relative flexbox-row">
                <span class="i-icon margin-left-10 material-icons-outlined  cursor-pointer" id="info-icon"
                    :class="showTooltip? 'color-white':'color-blue'" @click="showTooltip = !showTooltip">
                    info
                </span>
                <PadelTooltip v-if="showTooltip" class="tooltip" label="modify-club-tooltip-text"
                    :arrowPosition="'LEFT'" />
            </div>
        </div>
        <div v-if="formDisabled">
            <p class="montserrat msg-txt" v-if="formValues.club_notes">{{formValues.club_notes}}</p>
            <p class="montserrat msg-txt" v-else>{{t('players_message_not_present')}}</p>
        </div>
        <PadelTextarea v-else :label="t('insert_message')" :value="formValues.club_notes" :maxLength="textMaxLength"
            @input="updateFormValue($event, 'club_notes')" />
    </div>
</div>

<div v-if="modalToShow === 'INSERT_PHOTO_MODAL'">
    <DumbModal :title="t('insert_club_picture')" @hide="hideModal()">
        <div class="modal-content flexbox-column justify-space-between">
            <div class="top-part flexbox-column align-center">
                <p class="color-white montserrat insert_pic_text">{{t('be_careful_picture')}}
                    <b>{{t('horizontal')}}</b>, in <b>.jpg</b> o <b>.png </b>{{t('size_more_than')}}<b> 20Mb</b>.
                </p>
                <div class="flexbox-row">
                    <PadelUpload :label="uppercase(t('add_picture'))" :icon="'add_a_photo'"
                        :uploadedLabel="uppercase(t('picture_added'))" @change="temporaryUploader($event)"
                        @wrong-file-uploaded="handleWrongFileUploaded($event)"></PadelUpload>
                </div>

            </div>
        </div>
        <div class="buttons-container flexbox-row justify-space-between">
            <p class="abort" @click="hideModal()">{{uppercase(t('close'))}}</p>
            <div class="flexbox-row">
                <PadelButton :label="uppercase(t('load'))" :disabled="!temporaryPic" @click="confirmUploadPhoto()" />
            </div>
        </div>
    </DumbModal>
</div>

<div v-if="modalToShow === 'SHOW_CONFLICTS'">
    <DumbModal :title="t('modify_club_hours')" @hide="hideModal()">
        <div class="modal-content flexbox-column align-center justify-space-between">
            <span class="material-icons-outlined reject-icon">error_outline</span>
                <p class="modal-error-text">{{t('attention')}}</p>
                <p class="modal-error-text">{{t('conflicts_text')}}</p>
                <p class="modal-error-text">{{t('conflicts_following')}}</p>
                <div class="conflicts-container margin-top-20">
                    <template v-for="conflict in conflicts">
                        <div class="conflict-container flexbox-column align-center">
                            <div class="montserrat color-white">{{convertConflictTime(conflict.start_hour)}}</div>
                            <div class="playground-name-text barlow">{{(conflict.playground_name)}}</div>
                            </div>
                        
                    </template>
                </div>         

        </div>
        <div class="buttons-container flexbox-row justify-space-between margin-top-20">
            <p class="abort" @click="hideModal()">{{uppercase(t('close'))}}</p>
            <PadelButton :label="uppercase(t('go_to_calendar'))" :type="'secondary'" @click="goToCalendar()" />
        </div>
    </DumbModal>
</div>

<div v-else-if="modalToShow === 'MODAL_SUCCESS'">
    <ModalSuccess @hide="handleHideModalSuccess()" />
</div>