
import { defineComponent, PropType } from "vue";
import { isElementInViewport } from "@/services/window.service";
import { useI18n } from "vue-i18n";
export default defineComponent({
  name: "PadelDropdown",
  props: {
    options: {
      type: Array as PropType<Array<{ value: string; label: string }>>,
      required: true,
    },
    default: { type: String, required: false, default: "" },
    tabindex: { type: Number, required: false, default: 0 },
    label: { type: String, required: false, default: "" },
    disabled: { type: Boolean, required: false, default: false },
    error: { type: String, required: false, default: "" },
    isDropdownInModal: { type: Boolean, required: false, default: false },
    static: { type: Boolean, required: false, default: false },
  },
  methods: {
    trans(string) {
      const { t } = useI18n();
      return t(string);
    },
    returnSelectedOption(_opt, options: { label: null; value: null }[]) {
      const provv = options.find((opt) => opt.value === _opt);
      return provv ? provv : { label: null, value: null };
    },
    getIcon() {
      if (this.open) {
        return "keyboard_arrow_up";
      } else {
        return "keyboard_arrow_down";
      }
    },
    getLabel() {
      const foundLabel = (this.options as {
        value: string;
        label: string;
      }[]).find((opt) => opt.value === this.default);
      return foundLabel ? this.trans(foundLabel.label.toString()) : null;
    },
    getValue() {
      const foundLabel = (this.options as {
        value: string;
        label: string;
      }[]).find((opt) => opt.value === this.default);
      return foundLabel ? foundLabel.value : null;
    },
    returnColor(color: string) {
      return `background-color: map-get($colors, ${color});`;
    },
    getDistanceFromTop(el: Element | Window) {
    if (el instanceof Window) {
      return el.scrollY;
    }
    return el.scrollTop;
  },

    handleDropdownBottom() {
      const el = document.getElementById(this.id);
      if (!isElementInViewport(el)) {
        const dropelement = this.isDropdownInModal ? document.getElementsByClassName("p-dialog-content")[0] : window
        const topOfElement =
          this.getDistanceFromTop(dropelement) +
          el.getBoundingClientRect().top;
        dropelement.scroll({ top: topOfElement, behavior: "smooth" });
      }
    },
    toggleOpen() {
      this.open = !this.open;
    },
    handleClick() {
      this.toggleOpen();
      if (this.open) {
        setTimeout(() => {
          this.handleDropdownBottom();
        }, 0);
      }
    },
  },
  data() {
    return {
      selected: this.default
        ? this.returnSelectedOption(this.default, this.options)
        : { label: null, value: null },
      open: false,
      id: "dropdown-items-container-" + Math.floor(Math.random() * 100000000),
      moveDropdownUp: false,
    };
  },
  computed: {
    items__class() {
      if (!this.open) {
        return "selectHide";
      } else {
        return "";
      }
    },
    select__class() {
      const classes = [];
      if (this.error) {
        classes.push("color-error");
      } else if (this.open) {
        classes.push("open");
      } else if (this.disabled) {
        classes.push("disabled");
      }
      if (this.static) {
        classes.push("static");
      }
      return classes.join(" ");
    },
    color__error() {
      if (this.error) {
        return "color-error";
      } else {
        return "";
      }
    },
    label__class() {
      if (this.getValue() !== null) {
        if (this.error) {
          return "color-error content-selected";
        } else {
          return "content-selected";
        }
      } else {
        return "";
      }
    },
  },
});
