<div class="form-row flexbox-row justify-space-between">
    <PadelDropdown @input="setFormValue('match_type', $event.value)" :label="t('sport_choice')"
        :default="bookingForm.match_type" :options="computedMatchTypesOptions" :isDropdownInModal="true" />
    <div class="calendar-element">
        <PadelCalendar :label="t('game_date')" :value="bookingForm.date" :minDate="new Date()"
            @input="setFormValue('date', $event)" :error="bookingFormErrors.date" />
    </div>
</div>

<div class="form-row flexbox-row justify-space-between margin-top-20">
    <div style="margin-top: 26px;">
        <PadelTimepicker :label="t('start_hour')" :value="bookingForm.start_at"
            @input="setFormValue('start_at', $event)" :from="openingHour" :to="closingHour"
            :error="bookingFormErrors.start_at" />
    </div>
    <div class="radio-container flexbox-column">
        <div class="flexbox-row p-mb-2">
            <PadelToggle v-model:checked="withEndTime" @change="switchEndTime($event)"
                containerClass="p-d-inline-flex p-ml-2" labelClass="color-white overflow-inherit p-size-mini"
                :label="t('end_hour')" />
        </div>
        <div class="adjust-radio-spacing" style="margin-top: 10px;" v-if="!withEndTime">
            <PadelRadio :options="gameDurationOptions" :value="bookingForm.game_duration"
                @input="setFormValue('game_duration', $event)" :chipMode="true" />
        </div>
        <div v-else style="width: 100%; margin-top: 3px;">
            <PadelTimepicker :label="t('end_hour')" :value="bookingForm.end_at" @input="setFormValue('end_at', $event)"
                :from="openingHour" :to="closingHour" :error="bookingFormErrors.end_at" containerClass="full-width" />
        </div>
    </div>
</div>


<div class="form-row flexbox-row justify-space-between">
    <div class="p-mt-2 p-d-flex p-jc-between">
        <PadelInput :label="t('price_each_booking')" :inputType="'number'" :isPrice="true" :icon="'euro_symbol'"
            :value="bookingForm.price" @input="setFormValue('price', $event)" :hideError="false"
            containerClass="full-width" />
    </div>
    <div class="p-mt-2">
        <PadelInput :label="t('price_each_person')" :inputType="'number'" :isPrice="true" :icon="'euro_symbol'"
            :value="priceEachPerson" :disabled="true" containerClass="full-width" />
    </div>
</div>

<div class="form-row flexbox-row justify-space-between margin-top-20">
    <div class="">
        <label>{{ t('level') }}</label>
        <div class="form-row flexbox-row justify-space-between">
            <p class="color-white">{{ minLevelLabel }}</p>
            <p class="color-white p-text-right">{{ maxLevelLabel }}</p>
        </div>
        <vue-slider v-model="levelRange" :min="minLevel" :max="maxLevel" :min-range="1" :interval="1"
            :enable-cross="false" :marks="true" :hideLabel="true" :tooltip-formatter="levelTooltip"
            :railStyle="{ height: '4px' }" :stepStyle="{ height: '4px', backgroundColor: '#FEFEFE' }" />
        <!--<Slider 
            v-model="levelRange"
            :range="true"
            :step="1"
            :min="minLevel"
            :max="maxLevel"
            class="p-mt-2" />-->
    </div>

    <PadelToggle v-model:checked="confirmedBooking" @change="confirmedBooking = $event"
        containerClass="p-d-inline-flex p-ml-2" labelClass="color-white overflow-inherit p-size-mini"
        :label="t('booked_playground')" />
</div>