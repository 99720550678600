export enum PlaygroundExposures {
    INDOOR = 'INDOOR',
    OUTDOOR = 'OUTDOOR',
    MARQUEE = 'MARQUEE'
}

export enum PlaygroundSports {
    PADEL = 'PADEL',
    TENNIS = 'TENNIS',
    PADEBALL = 'PADEBALL',
    PICKLEBALL = 'PICKLEBALL',
    SOCCER_5 = 'SOCCER_5',
    SOCCER_7 = 'SOCCER_7',
    SOCCER_11 = 'SOCCER_11',
    BEACH_VOLLEY = 'BEACH_VOLLEY',
    BEACH_TENNIS = 'BEACH_TENNIS',
}

export enum PlaygroundSizes {
    SINGLE = 'SINGLE',
    DOUBLE = 'DOUBLE'
}

export enum PlaygroundGrounds {
    ASPHALT = 'ASPHALT',
    CONCRETE = 'CONCRETE',
    NATURAL_GRASS = 'NATURAL_GRASS',
    SYNTHETIC_GRASS = 'SYNTHETIC_GRASS',
    SYNTHETIC = 'SYNTHETIC',
    RUBBER = 'RUBBER',
    LINOLEUM = 'LINOLEUM',
    PARQUET = 'PARQUET',
    PVC = 'PVC',
    RESIN = 'RESIN',
    UNHEATED_SAND = 'UNHEATED_SAND',
    HEATED_SAND = 'HEATED_SAND',
    CLAY = 'CLAY',
    RED_EARTH = 'RED_EARTH',
    ARTIFICIAL_RED_EARTH = 'ARTIFICIAL_RED_EARTH'
}

//wall
export enum PlaygroundTypes {
    TEMPEREDGLASS = 'TEMPEREDGLASS',
    WALL = 'WALL',
    GRID = 'GRID',
    PANORAMIC = 'PANORAMIC'
}

export const GroundBaseOptions = [
    { value: PlaygroundGrounds.ASPHALT, label: 'Asfalto', sports: [] },
    { value: PlaygroundGrounds.CONCRETE, label: 'Cemento', sports: [PlaygroundSports.TENNIS, PlaygroundSports.SOCCER_5, PlaygroundSports.SOCCER_7] },
    { value: PlaygroundGrounds.NATURAL_GRASS, label: 'Erba naturale', sports: [PlaygroundSports.TENNIS, PlaygroundSports.SOCCER_11] },
    { value: PlaygroundGrounds.SYNTHETIC, label: 'Sintetico', sports: [PlaygroundSports.PADEL, PlaygroundSports.PADEBALL, PlaygroundSports.PICKLEBALL] },
    { value: PlaygroundGrounds.SYNTHETIC_GRASS, label: 'Erba sintetica', sports: [PlaygroundSports.TENNIS, PlaygroundSports.SOCCER_5, PlaygroundSports.SOCCER_7, PlaygroundSports.SOCCER_11] },
    { value: PlaygroundGrounds.RUBBER, label: 'Gomma', sports: [PlaygroundSports.SOCCER_5, PlaygroundSports.SOCCER_7] },
    { value: PlaygroundGrounds.LINOLEUM, label: 'Linoleum', sports: [] },
    { value: PlaygroundGrounds.PARQUET, label: 'Parquet', sports: [PlaygroundSports.SOCCER_5] },
    { value: PlaygroundGrounds.PVC, label: 'PVC', sports: [] },
    { value: PlaygroundGrounds.RESIN, label: 'Resina', sports: [PlaygroundSports.PADEL, PlaygroundSports.PADEBALL, PlaygroundSports.TENNIS] },
    { value: PlaygroundGrounds.UNHEATED_SAND, label: 'Sabbia', sports: [PlaygroundSports.BEACH_VOLLEY, PlaygroundSports.BEACH_TENNIS] },
    { value: PlaygroundGrounds.HEATED_SAND, label: 'Sabbia riscaldata', sports: [] },
    { value: PlaygroundGrounds.CLAY, label: 'Terra battuta', sports: [PlaygroundSports.SOCCER_11] },
    { value: PlaygroundGrounds.RED_EARTH, label: 'Terra rossa', sports: [PlaygroundSports.TENNIS] },
    { value: PlaygroundGrounds.ARTIFICIAL_RED_EARTH, label: 'Terra rossa artificiale', sports: [] },
]

export const SizesOptions = [
    { value: PlaygroundSizes.SINGLE, label: 'Singolo' },
    { value: PlaygroundSizes.DOUBLE, label: 'Doppio' },
]

export const PlaygroundTypesOptions = [
    { value: PlaygroundTypes.TEMPEREDGLASS, label: 'Vetro temprato' },
    { value: PlaygroundTypes.WALL, label: 'Muro' },
    { value: PlaygroundTypes.GRID, label: 'Griglia' },
    { value: PlaygroundTypes.PANORAMIC, label: 'Panoramico' },
]

export const VisibilityOptions = [
    { value: false, label: 'public' },
    { value: true, label: 'private' },
]

export const DEFAULT_VALUES = {
    [PlaygroundSports.PADEL]: {
        exposure: PlaygroundExposures.OUTDOOR,
        playground_size: PlaygroundSizes.DOUBLE,
        ground: PlaygroundGrounds.RESIN,
        walls: PlaygroundTypes.TEMPEREDGLASS,
    },
    [PlaygroundSports.TENNIS]: {
        exposure: PlaygroundExposures.OUTDOOR,
        playground_size: PlaygroundSizes.DOUBLE,
        ground: PlaygroundGrounds.RED_EARTH,
        walls: null,
    },
    [PlaygroundSports.PADEBALL]: {
        exposure: PlaygroundExposures.OUTDOOR,
        playground_size: null,
        ground: PlaygroundGrounds.RESIN,
        walls: PlaygroundTypes.TEMPEREDGLASS,
    },
    [PlaygroundSports.SOCCER_5]: {
        exposure: PlaygroundExposures.OUTDOOR,
        playground_size: null,
        ground: PlaygroundGrounds.SYNTHETIC_GRASS,
        walls: null,
    },
    [PlaygroundSports.SOCCER_5]: {
        exposure: PlaygroundExposures.OUTDOOR,
        playground_size: null,
        ground: PlaygroundGrounds.SYNTHETIC_GRASS,
        walls: null,
    },
    [PlaygroundSports.SOCCER_7]: {
        exposure: PlaygroundExposures.OUTDOOR,
        playground_size: null,
        ground: PlaygroundGrounds.SYNTHETIC_GRASS,
        walls: null,
    },
    [PlaygroundSports.SOCCER_11]: {
        exposure: PlaygroundExposures.OUTDOOR,
        playground_size: null,
        ground: PlaygroundGrounds.SYNTHETIC_GRASS,
        walls: null,
    },
    [PlaygroundSports.BEACH_VOLLEY]: {
        exposure: PlaygroundExposures.OUTDOOR,
        playground_size: null,
        ground: PlaygroundGrounds.UNHEATED_SAND,
        walls: null,
    },
    [PlaygroundSports.BEACH_TENNIS]: {
        exposure: PlaygroundExposures.OUTDOOR,
        playground_size: null,
        ground: PlaygroundGrounds.UNHEATED_SAND,
        walls: null,
    },
}

export enum OpenBookingSports {
    PADEL = 'PADEL',
    PADEL_SINGOLO = 'PADEL_SINGOLO',
    TENNIS = 'TENNIS',
    TENNIS_DOUBLE = 'TENNIS_DOUBLE',
}