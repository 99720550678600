
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { 
    computed, 
    defineComponent, 
    onMounted, 
    onUnmounted,
    ref,
    nextTick,
    watch,
} from "vue";
import {
} from "@/services/dates.service";
import {
    bundleIsFilled,
} from "@/services/form-validation.service";
import { PadelClub } from "@/models/club.models";
import { ActionTypes } from "@/store/action-types";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import {
    uppercase,
    mapBundleConfirmation,
} from "@/services/mapping.service";
import BundleItemCard from "@/components/bundle-item-card/BundleItemCard.vue";
import { MutationTypes } from "@/store/mutation-types";
import { uuid } from 'vue-uuid';
import { BookingType } from "@/constants/booking.enum";


export default defineComponent({
    name: "ConfirmBundle",
    components: {
        BundleItemCard,
    },
    setup() {
        const route     = useRoute()
        const store     = useStore();
        const router    = useRouter();
        const { t, locale } = useI18n();


        /* COMPUTED */
        const myClub = computed(function(): PadelClub {
            return store.getters.getMyClub;
        });
        const myPlaygrounds = computed(function() {
            return store.getters.getMyPlaygrounds;
        });
        const bundleResponse = computed(function() {
            const bundle = store.getters.getBundleResponse;
            if (!bundle) return [];
            return bundle.map((el) => {
                el.selected = true;
                if (el.status != "AVAILABLE") {
                    el.playground_id = null;
                }
                if (!el.key) {
                    el.key = uuid.v1();
                }
                return el;
            });
        });
        const firstBooking = computed(function() {
            return (bundleResponse.value.length > 0 ? bundleResponse.value[0] : {});
        });
        const lastBooking = computed(function() {
            const lastIndex = bundleResponse.value.length - 1;
            return (bundleResponse.value.length > 0 ? bundleResponse.value[ lastIndex ] : {});
        });
        const fromDate = computed(function() {
            return moment(firstBooking.value.date).format("DD/MM/YYYY");
        });
        const endDate = computed(function() {
            return moment(firstBooking.value.bundle_end_at).format("DD/MM/YYYY");
        });
        const fromTime = computed(function() {
            return moment(firstBooking.value.start_at).format("HH:mm");
        });
        const toTime = computed(function() {
            return moment(firstBooking.value.end_at).format("HH:mm");
        });
        const date = computed(function() {
            const date = moment(firstBooking.value.start_at).format("dddd");
            return (' '+date).replace(/ [\w]/g, a => a.toLocaleUpperCase()).trim();
        });
        const selectedBookings = computed(function() {
            return bundleResponse.value.filter(el => !!el.selected == true);
        });
        const totalPrice = computed(function() {
            return selectedBookings.value.reduce((partial, el) => partial + (el.price || 0), 0) / 100;
        });
        const isEvent = computed(function() {
            return firstBooking.value.type == BookingType.BACKOFFICE_EVENT;
        });
        const playgroundSelected = computed(function() {
            return myPlaygrounds.value.find(el => el.id === firstBooking.value.playground_id);
        });

        // DATA

        // LIFECYCLE
        onMounted(() => {
            nextTick(() => {
            });
        });

        onUnmounted(() => {
        });

        onBeforeRouteLeave((to, from) => {
            store.commit(MutationTypes.SET_BUNDLE_RESPONSE, null);
            return true;
        });

        /** WATCH */
        
        // FUNCTIONS
        async function save() {
            // PARSE
            const body      = mapBundleConfirmation(bundleResponse.value);
            store.dispatch(
                ActionTypes.BOOKING_BUNDLE_CONFIRM,
                body
            ).then(() => {
                // CLEAN STORE
                const item = body[0];
                store.commit(MutationTypes.SET_BUNDLE_RESPONSE, null);
                store.commit(MutationTypes.SET_SCROLL_EVENT, item);
                store.commit(MutationTypes.SET_CURRENT_DATE, new Date(item.date));

                // RESET ROUTE
                setTimeout(() => {
                    router.replace({ name: 'Scheduler' });
                }, 100);
            }).catch((error) => {
                console.log(error);
                if (error.status == 409) {
                    store.commit(MutationTypes.SET_BUNDLE_RESPONSE, error.response.data);
                }
            })
        }

        // RETURN
        return {
            t,
            locale,
            router,
            uppercase,
            moment,
            bundleIsFilled,

            // DATA
            bundleResponse,
            firstBooking,
            lastBooking,
            date,
            fromDate,
            endDate,
            fromTime,
            toTime,
            totalPrice,
            isEvent,
            playgroundSelected,

            // METHODS
            save,
        };
    },
});
