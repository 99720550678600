export const adminDropdownChoices = [
  { label: "change_password", disabled: false },
  { label: "logout", disabled: false },
]

export const basicChoices = [
  { label: "change_profile_picture", disabled: false },
  { label: "change_password", disabled: false },
  { label: "logout", disabled: false },
]

export const allChoices = [
  { label: "disambiguation_club", disabled: false },
  { label: "change_profile_picture", disabled: false },
  { label: "change_password", disabled: false },
  { label: "logout", disabled: false }
]