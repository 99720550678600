
import { useI18n } from "vue-i18n";
import { mapTournamentToInfoForm, uppercase } from "@/services/mapping.service";
import { computed, defineComponent, ref } from "vue";

export default defineComponent({
  props: ["tournament"],
  emits: ["hideModal", "goToTournaments"],
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const formDisabled = ref(true);

    const tournamentInfo = computed(function() {
      return mapTournamentToInfoForm(props.tournament, t);
    });

    function goToTournaments() {
      emit("goToTournaments", true);
    }

    function hideModal() {
      emit("hideModal", true);
    }

    return {
      t,
      locale,
      uppercase,
      formDisabled,
      tournamentInfo,

      goToTournaments,
      hideModal,
    };
  },
});
