<div class="first-row">    
    <div class="access-box">
        <p class="access-text color-white margin-right-10"><b>{{ returnUserName(user) }}</b></p>
        <div id="img-box">
            <img v-if="user.profile_pic_ref" :src="user.profile_pic_ref" class="picture_ref cursor-pointer" alt=""
            @click="toggleBox()">
        <span v-else class="material-icons color-white icon-profile cursor-pointer"
            @click="toggleBox()">perm_identity</span>
        </div>        
        <div v-if="dropdownOpened" class="dropdown flexbox-column">
            <div class="arrow-up"></div>
            <div class="dropdown-inner">
                <template v-for="element in dropdownChoices">
                    <div class="dropdown-row color-white" @click="elementClicked(element.label)">
                        {{uppercase(t(element.label))}}</div>
                </template>
            </div>
        </div>
    </div>

    <div v-if="openedModal === 'FIND_ACCOUNT'" class="modal">
        <ChangePasswordModal @hide="hideModal()" />
    </div>

    <div v-else-if="openedModal === 'DISAMBIGUATION'">
        <Disambiguation :clubs="myClubs" @hide="hideModal()" 
        @clubSelected="handleClubSelected($event)"></Disambiguation>
    </div>
    
    <div v-else-if="openedModal === 'CHANGE_PROFILE_PIC'" class="modal">
        <DumbModal :title="t('change_profile_picture')" @hide="hideModal()" style="height: 350px;">
            <p class="change-pic-text montserrat">{{t('profile_pic_text')}}.</p>
            <PadelUpload @change="saveFile($event)" :icon="'add_a_photo'" :label="uppercase(t('add_picture'))"
                :uploadedLabel="uppercase(t('picture_added'))" @wrong-file-uploaded="handleWrongFileUploaded($event)">
            </PadelUpload>
            <div class=" button-container flexbox-row margin-top-20 justify-space-between">
                <p class="abort cursor-pointer" @click="hideModal()">{{uppercase(t('back'))}}</p>
                <PadelButton @click="uploadProfilePic()" :label="uppercase(t('load'))" :disabled="!provvPic">
                </PadelButton>
            </div>
        </DumbModal>
    </div>
</div>

