<div class="principal-container justify-space-between flexbox-column padding-main-container">
    <div class="first-part">
        <div class="header display-flex">
            <img class="smash-img" src="@/theme/imgs/logo_completo.svg" alt="">
        </div>
        <div class="signup-text-container">
            <div class="signup-title color-gold barlow margin-top-20">
                {{t("register_sport_club")}}
            </div>
            <div class="montserrat signup-text color-white margin-top-10">
                {{t("wanna_register_new_club")}}
            </div>
        </div>
        <div class="form-container flexbox-row margin-top-20">
            <div class="box">
                <div class="input-container">
                    <PadelInput :label="t('name')" :value="userInfo.name"
                        :disabled="true" :icon="'lock'" />
                </div>
                <div class="input-container">
                    <PadelInput :label="t('surname')" :value="userInfo.family_name"
                        :disabled="true" :icon="'lock'" />
                </div>
                <div class="input-container">
                    <PadelInput :label="t('phonenumber')" :value="userInfo.phone_number"
                        :disabled="true" :icon="'lock'" />
                </div>
                <div class="input-container">
                    <PadelInput :label="t('email')" :value="userInfo.email"
                        :disabled="true" :icon="'lock'" />
                </div>
            </div>
        </div>
        <div class="flexbox-column width-100">
            <p class="your-centre margin-top-0 barlow color-white align-initial">{{t('your_centre')}}</p>
            <div class="flexbox-row box">
                <div class="input-container">
                    <PadelInput :value="formValues.clubName"
                    :label="t('clubName')" @input="updateFormValue($event, 'clubName')" />
                </div>
                <div class="input-container add-margin">
                    <PadelInput :value="formValues['clubGeoLoc'].address" 
                    :googleAutocomplete="true" :label="t('address')"
                    :error="formErrors['clubGeoLoc']" :inputType="'text'" />
                </div>
            </div>
        </div>
    </div>
    <div class="buttons-container flexbox-row justify-space-between width-100">
        <p class="font-12 color-blue cursor-pointer bold-text" @click="goBack()">{{uppercase(t('back'))}}</p>
        <PadelButton :label="uppercase(t('forward'))" @click="executeCaptcha()"
        :disabled="isAddClubFormEmpty(formValues) || !isAddClubFormValid(formErrors)" />
    </div>
</div>
<div class="g-recaptcha" id="g-recaptcha" :data-sitekey="captchaSiteKey"
    data-callback="onSubmitCaptcha" data-size="invisible">
</div>