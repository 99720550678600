
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from "vue";
import Chart from "chart.js/auto";
import { useI18n } from "vue-i18n";
import { statsRadioOptions } from "@/constants/radio-options.constants";
import {
  bookingsStatsOptions,
  cancellationsOrIncomeStatsOptions,
} from "@/constants/stats.options";
import { uppercase } from "@/services/mapping.service";
import {
  getChartDataset,
  getDataset,
  sumArrayValues,
} from "@/services/stats.service";
import { store } from "@/store/store";
import { ActionTypes } from "@/store/action-types";
import { formatDateYYYYMMDD } from "@/services/dates.service";
import { MutationTypes } from "@/store/mutation-types";
export default defineComponent({
  name: "Stats",
  setup() {
    const { t, locale } = useI18n();
    const selectedRadio = ref("bookings");

    //const myChart = ref(null);
    //const myChart2 = ref(null);
    //const myChart3 = ref(null);
    const show_calendar = ref(false);
    const selectedDate = ref(
      new Date(new Date().getFullYear(), new Date().getMonth(), 1)
    );

    const booked_hours_sum = ref(0);
    const lessons_hours_sum = ref(0);
    const income_sum = ref(0);
    const canc_sum = ref(0);
    const bookedChartData = ref(null);
    const cancChartData = ref(null);
    const incomeChartData = ref(null);
    const statsLoaded = ref(false);

    /* COMPUTED */
    const mainInfoValue = computed(function() {
      if (selectedRadio.value === "bookings") {
        return (booked_hours_sum.value + lessons_hours_sum.value);
      } else if (selectedRadio.value === "cancellations") {
        return canc_sum.value;
      } else {
        return income_sum.value;
      }
    });

    const mainInfoClass = computed(function() {
      if (selectedRadio.value === "bookings") {
        return "color-blue";
      } else if (selectedRadio.value === "cancellations") {
        return "color-red";
      } else {
        return "color-green";
      }
    });

    function handleClickCalendar(event) {
      const specifiedElement = document.getElementById(
        "toggle-calendar-button"
      );
      const schedulerBox = document.getElementById("calendar-stats-box");
      const isMonthPickClicked =
        (event.target as HTMLElement).classList.contains(
          "p-datepicker-prev-icon"
        ) ||
        (event.target as HTMLElement).classList.contains("p-datepicker-prev") ||
        (event.target as HTMLElement).classList.contains(
          "p-datepicker-next-icon"
        ) ||
        (event.target as HTMLElement).classList.contains(
          "p-datepicker-next-icon"
        );
      const isClickInside = specifiedElement.contains(event.target as Node);
      const isClickInsideCalendar = schedulerBox.contains(event.target as Node);
      if (isMonthPickClicked || isClickInsideCalendar) {
        show_calendar.value = true;
      } else if (!isClickInside) {
        show_calendar.value = false;
      }
    }

    function handleButtonCalendarClickOut() {
      document.addEventListener("click", handleClickCalendar );
    }

    onUnmounted(() => {
      window.removeEventListener("click", handleClickCalendar);
    });

    function drawCharts() {
      Chart.defaults.font.family = "Montserrat";
      Chart.defaults.scale.grid.color = "#FFFFFF";
      statsLoaded.value = true;
    }

    function writeTotals(
      booked_hours: number[],
      lessons_hours: number[],
      income_dataset: number[],
      cancellated_dataset: number[]
    ) {
      booked_hours_sum.value = sumArrayValues(booked_hours);
      lessons_hours_sum.value = sumArrayValues(lessons_hours);
      income_sum.value = sumArrayValues(income_dataset);
      canc_sum.value = sumArrayValues(cancellated_dataset);
    }

    function calculateDatasetsAndOptions(statistics) {
      const _labels = Object.keys(statistics);
      const entries = Object.entries(statistics);
      const booked_hours = getDataset(entries, "booked_hours");
      const lessons_hours = getDataset(entries, "lessons_hours");
      const cancellated_dataset = getDataset(entries, "cancelled_hours");
      const income_dataset = getDataset(entries, "income");

      // debugger;
      writeTotals(
        booked_hours,
        lessons_hours,
        income_dataset,
        cancellated_dataset
      );
      bookedChartData.value = {
        labels: _labels,
        datasets: getChartDataset([booked_hours, lessons_hours], "bookings"),
      };
      cancChartData.value = {
        labels: _labels,
        datasets: getChartDataset(cancellated_dataset, "cancellations"),
      };

      incomeChartData.value = {
        labels: _labels,
        datasets: getChartDataset(income_dataset, "income"),
      };
    }

    function calculateDatasets(statistics) {
      const entries = Object.entries(statistics);
      const _labels = Object.keys(statistics);
      const booked_hours = getDataset(entries, "booked_hours");
      const lessons_hours = getDataset(entries, "lessons_hours");
      const cancellated_dataset = getDataset(entries, "cancelled_hours");
      const income_dataset = getDataset(entries, "income");
      writeTotals(
        booked_hours,
        lessons_hours,
        income_dataset,
        cancellated_dataset
      );
      bookedChartData.value = {
        labels: _labels,
        datasets: getChartDataset(
          [booked_hours, lessons_hours],
          "bookings"
        ),
      };
      cancChartData.value = {
        labels: _labels,
        datasets: getChartDataset(
          cancellated_dataset,
          "cancellations"
        ),
      };
      incomeChartData.value = {
        labels: _labels,
        datasets: getChartDataset(
          income_dataset,
          "income"
        ),
      };
    }

    /* COMPUTED */
    const myClubId = computed(function(): string {
      return store.getters.getMyClub.club_id;
    });

    async function initializeCharts() {
      try {
        store.commit(MutationTypes.OPEN_LOADER);
        const val = await store.dispatch(ActionTypes.GET_CLUB_STATS, {
          club_id: myClubId.value,
          start_date: formatDateYYYYMMDD(selectedDate.value),
          handleLoaderInternally: false,
        });
        await calculateDatasetsAndOptions(val);
        drawCharts();
        store.commit(MutationTypes.CLOSE_LOADER);
      } catch (error) {
        store.commit(MutationTypes.CLOSE_LOADER);
        return;
      }
    }

    onMounted(() => {
      try {
        initializeCharts();
      } catch (error) {
        console.log(error);
      }

      //LOGICA PER CHIUDERE IL CALENDARIO QUANDO C'è UN CLICK FUORI DAL BOTTONE
      handleButtonCalendarClickOut();
    });

    async function redrawCharts(date: Date) {
      try {
        store.commit(MutationTypes.OPEN_LOADER);
        const val = await store.dispatch(ActionTypes.GET_CLUB_STATS, {
          club_id: myClubId.value,
          start_date: formatDateYYYYMMDD(date),
          handleLoaderInternally: false,
        });
        await calculateDatasets(val);
        //myChart.value.update();
        //myChart2.value.update();
        //myChart3.value.update();
        store.commit(MutationTypes.CLOSE_LOADER);
      } catch (err) {
        store.commit(MutationTypes.CLOSE_LOADER);
        return;
      }
    }

    watch(selectedDate, async function(currentValue, oldValue) {
      redrawCharts(currentValue);
    });

    function updateRadioValue(event) {
      selectedRadio.value = event;
    }

    function formatDate() {
      const options: Intl.DateTimeFormatOptions = {
        month: "long",
        year: "numeric",
      };
      const stringDate = selectedDate.value.toLocaleDateString(
        "it-IT",
        options
      );
      return stringDate.charAt(0).toUpperCase() + stringDate.slice(1);
    }

    function goToPrevMonth() {
      selectedDate.value = new Date(
        selectedDate.value.getFullYear(),
        selectedDate.value.getMonth() - 1,
        1
      );
    }

    function goToNextMonth() {
      selectedDate.value = new Date(
        selectedDate.value.getFullYear(),
        selectedDate.value.getMonth() + 1,
        1
      );
    }

    function toggleCalendar() {
      show_calendar.value = !show_calendar.value;
    }

    function changeDateWithCalendar(event) {
      selectedDate.value = event;
      setTimeout(()=> {toggleCalendar();}, 1);       
    }

    return {
      t,
      locale,
      statsRadioOptions,
      selectedRadio,
      updateRadioValue,
      show_calendar,
      booked_hours_sum,
      lessons_hours_sum,
      income_sum,
      canc_sum,
      mainInfoValue,
      statsLoaded,
      bookedChartData,
      cancChartData,
      incomeChartData,

      goToPrevMonth,
      goToNextMonth,
      toggleCalendar,
      changeDateWithCalendar,
      formatDate,
      uppercase,
      mainInfoClass,
      bookingsStatsOptions,
      cancellationsOrIncomeStatsOptions,

    };
  },
});
