
export const awsConfiguration = {
    Auth: {
        region: "eu-central-1",
        userPoolId: process.env.VUE_APP_AWS_USERPOOL_ID,
        userPoolWebClientId: process.env.VUE_APP_AWS_CLIENT_ID,
        mandatorySignIn: false,
    },
    API: {
        endpoints: [
            {
                name: "WeSmashApi",
                endpoint: process.env.VUE_APP_AWS_ENDPOINT
            }
        ]
    }
};



