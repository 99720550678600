<PadelLateralTab />
<div class="view-main-container">

    <div class="inner-view-container">
        <div class="nav-container flexbox-row justify-space-between padding-bottom-20 padding-top-20">
            <p class="title color-white barlow">{{t('users')}}</p>
            <div class="cursor-pointer color-blue flexbox-row align-center" @click="exportData()">
                <span class="button-text montserrat">{{uppercase(t('export'))}}</span>
                <span class="material-icons">file_download</span>
            </div>
        </div>
        <div class="table-main-container flexbox-column">
            <div class="filter-row flexbox-row justify-space-between"
                v-if="activeFilters.length > 0 || userTable.values.length > 0">
                <div class="padel-search-container">
                    <PadelSearch @onEnterPressed="searchValueChanged($event)" @onClosePressed="eraseSearchValue()"
                        :value="searchFormValue" />
                </div>
                <div class="scheduler-button filter-button cursor-pointer display-flex" @click="openModal('FILTER')">
                    <p class="scheduler-button-text color-blue">
                        {{uppercase(t('filters'))}}</p>
                    <i class="material-icons color-blue">filter_alt</i>
                </div>

            </div>
            <div v-if="activeFilters.length > 0" class="chip-container">
                <template v-for="chip in activeFilters">
                    <template v-if="chip.key !== 'name'">
                        <Chip class="chip-table">
                            <div class="chip-content">
                                <span class="chip-text">{{uppercase(t(formatValue(chip)))}}</span><span
                                    class="material-icons icon-close-chip" @click="removeFilter(chip.key)">
                                    close
                                </span>
                            </div>
                        </Chip>
                    </template>
                </template>
            </div>
            <div class="table-container" v-if="userTable.values.length > 0">
                <List :values="userTable.values" :hoverEnabled="hoverEnabled" @row-clicked="onClickEvent">
                    <template v-slot:header>
                        <th>{{t('fullname')}}</th>
                        <th>{{t('sex')}}</th>
                        <th>{{t('birthdate')}}</th>
                        <th>{{t('email')}}</th>
                        <th>{{t('phonenumber_abbreviated')}}</th>
                        <th>{{t('level')}}</th>
                        <th>{{t('privacy_consents')}}</th>
                        <th>{{t('partner_communication_consents')}}</th>
                        <th>{{t('bookings_number')}}</th>
                        <th>{{t('cancellations_number')}}</th>
                        <th></th>
                    </template>
                    <template v-slot:tile="slotProps">
                        <td>
                            <div class="flexbox-row align-center">
                                <div class="circle-name margin-right-10"
                                    :class="slotProps.value.banned ? 'color-red': 'color-green'"></div>
                                <div class="name-element">
                                    {{ slotProps.value.name + ' ' + slotProps.value.family_name }}
                                </div>
                            </div>

                        </td>
                        <td>
                            <span class="material-icons-outlined gender-icon"
                                :class="returnGender(slotProps.value.gender)">
                                {{returnGender(slotProps.value.gender)}}
                            </span>
                        </td>
                        <td>{{ slotProps.value.birth_date || 'NA' }}</td>
                        <td>{{ slotProps.value.email }}</td>
                        <td>{{ slotProps.value.phone_number }}</td>
                        <td>{{ upperCaseFirstLetter(slotProps.value.exp_level?.description || '') }}</td>
                        <td>{{ slotProps.value.favorite ? t("yes") : t("no") }}</td>
                        <td>{{ slotProps.value.consents.accepts_commercial_partner ? t("yes") : t("no") }}</td>
                        <!-- <td>
                            <div class="flexbox-column">
                                <div class="consent-box">{{slotProps.value.consents.accepts_commercial_partner ? t("accepts_commercial_partner") : '-'}}</div>
                                <div class="consent-box">{{slotProps.value.consents.accepts_commercial_wesmash ? t("accepts_commercial_wesmash") : '-'}}</div>
                                <div class="consent-box">{{slotProps.value.consents.accepts_privacy ? t("accepts_privacy") : '-'}}</div>
                            </div>
                        </td> -->
                        <td>{{ slotProps.value.bookings_count }}</td>
                        <td>{{ slotProps.value.cancellations_count }}</td>
                        <td>
                            <p class="abort" @click.stop.prevent="handleUserBlocking(slotProps.value)">
                                {{ slotProps.value.banned ? uppercase(t('unblock')) : uppercase(t('block')) }}
                            </p>
                        </td>

                    </template>
                </List>

                <div v-if="showLoadMore" class="load-more-button-container flexbox-row margin-top-10">
                    <p class="abort" @click="loadMoreRequests()">{{uppercase(t('load_more'))}}</p>
                </div>
            </div>
            <div class="no-records-text" v-else>{{t('no_records')}}</div>
        </div>

    </div>
</div>
<div v-if="modalOpened === 'FILTER'">
    <DumbModal :title="t('filter_club_requests')" :isVisible="isVisible" @hide="closeModal()">
        <div class="flexbox-row filter-modal-top-part">
            <PadelInput :label="t('min_bookings')" :value="filtersForm.min_bookings" :inputType="'number'"
                @input="setFilterFormValue('min_bookings', $event)" />
            <PadelInput :label="t('min_cancellations')" :value="filtersForm.min_cancellations" :inputType="'number'"
                @input="setFilterFormValue('min_cancellations', $event)" />
            <div class="radio-container">
                <p class="color-white montserrat margin-bottom-20">{{t('visualize')}}</p>
                <div class="inner-radio-container">
                    <PadelRadio :value="filtersForm.banned" :options="userAbilitationOptions" :chipMode="true"
                        @input="setFilterFormValue('banned', $event)" />
                </div>

            </div>

        </div>
        <div class="filter-buttons-container flexbox-row justify-space-between">
            <p class="abort center-button" @click="closeModal()">{{uppercase(t('abort'))}}</p>
            <div class="right-buttons flexbox-row">
                <PadelButton class="initialize-button margin-right-20" :label="uppercase(t('reset_filters'))"
                    :type="'secondary'" @click="initializeFilterForm()"></PadelButton>
                <PadelButton :label="uppercase(t('apply'))" :type="'primary'" @click="applyFilters()"
                    :disabled="formUserFilterEmpty(filtersForm)"></PadelButton>
            </div>
        </div>
    </DumbModal>
</div>
<div v-else-if="modalOpened === 'BAN_USER'">
    <DumbModal :title="t('block_user')" @hide="closeModalAndResetProvvUser()">
        <div class="flexbox-column align-center">
            <i class="material-icons icon-alert margin-bottom-20">warning_amber</i>
            <div class="modal-text color-white">{{t('attention')}}</div>
            <div class="modal-text color-white"
                v-html="t('ban_user_main_text', {name: `${provvUser.name} ${provvUser.family_name}`})"></div>
            <div class="modal-text color-white">{{t('wanna_ban')}}</div>
        </div>
        <div class="filter-buttons-container flexbox-row justify-space-between">
            <p class="abort center-button" @click="closeModalAndResetProvvUser()">{{uppercase(t('abort'))}}</p>
            <div class="flexbox-row">
                <PadelButton :label="uppercase(t('confirm'))" :type="'primary'" @click="banUser()"></PadelButton>
            </div>
        </div>
    </DumbModal>
</div>
<div v-else-if="modalOpened === 'UNBAN_USER'">
    <DumbModal :title="t('unblock_user')" @hide="closeModalAndResetProvvUser()">
        <div class="flexbox-column align-center">
            <i class="material-icons icon-alert margin-bottom-20">warning_amber</i>
            <div class="modal-text color-white">{{t('attention')}}</div>
            <div class="modal-text color-white"
                v-html="t('unban_user_main_text', {name: `${provvUser.name} ${provvUser.family_name}`})"></div>
            <div class="modal-text color-white">{{t('wanna_unban')}}</div>
        </div>
        <div class="filter-buttons-container flexbox-row justify-space-between">
            <p class="abort center-button" @click="closeModalAndResetProvvUser()">{{uppercase(t('abort'))}}</p>
            <div class="flexbox-row">
                <PadelButton :label="uppercase(t('confirm'))" :type="'primary'" @click="unbanUser()"></PadelButton>
            </div>
        </div>
    </DumbModal>
</div>