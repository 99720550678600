<PadelLateralTab />
<div class="view-main-container">

    <div class="inner-view-container">
        <div class="nav-container flexbox-row justify-space-between align-center padding-bottom-20 padding-top-20">
            <p class="title color-white barlow">{{t('stats')}}</p>
            <div class="date-container flexbox-row">
                <i class="pi pi-chevron-left color-blue cursor-pointer" @click="goToPrevMonth()"></i>
                <div class="selected-date cursor-pointer" id="toggle-calendar-button" @click="toggleCalendar()">
                    {{formatDate()}}
                </div>
                <div :class="show_calendar? '': 'display-none'" class="calendar-container"
                id="calendar-stats-box">
                    <PadelCalendar :showLabel="false" @input="changeDateWithCalendar($event)" :viewType="'month'" />
                </div>
                <i class="pi pi-chevron-right color-blue cursor-pointer" @click="goToNextMonth()"></i>
            </div>
        </div>

        <div class="main-chart-container">
            <div
                class="chart-header-row margin-bottom-20 margin-top-10  flexbox-row justify-space-between align-center">
                <div class="radio-container flexbox-column justify-space-between">
                    <p class="color-white margin-bottom-20">{{t('view_progress')}}:</p>
                    <PadelRadio class="radio-values" :value="selectedRadio" :options="statsRadioOptions"
                        @input="updateRadioValue($event, 'sport')" :chipMode="true" />
                </div>
                <div class="main-info-container flexbox-row" :class="mainInfoClass">
                    <div class="info-value barlow">
                        {{mainInfoValue.toFixed(2)}}
                    </div>
                    <div class="margin-left-10 value-end barlow">
                        {{selectedRadio === 'income' ? ' €' : ' h'}}
                    </div>
                </div>
            </div>
            <div class="chart-container" :class="selectedRadio === 'bookings' ? '' : 'display-none' ">
                <Chart type="bar" :data="bookedChartData" :options="bookingsStatsOptions"
                    v-if="bookedChartData != null" />
                <div class="color-white bottom-row flexbox-row justify-space-between align-center">
                    <div class="legend flexbox-row align-center">
                        <div class="circle color-bookings margin-right-10"></div>
                        <div class="legend-item-container barlow">{{`${t('bookings')}`}}
                            <b>{{`(${booked_hours_sum}h)`}}</b></div>
                        <div class="vl"></div>
                        <div class="circle color-lessons margin-right-10"></div>
                        <div class="legend-item-container barlow">{{`${t('lessons')}`}}
                            <b>{{`(${lessons_hours_sum}h)`}}</b></div>
                    </div>
                    <p class="color-white barlow">{{uppercase(formatDate())}}</p>
                </div>
            </div>
            <div class="chart-container" :class="selectedRadio === 'cancellations' ? '' : 'display-none' ">
                <Chart type="bar" :data="cancChartData" :options="cancellationsOrIncomeStatsOptions"
                    v-if="cancChartData != null" />
            </div>
            <div class="chart-container" :class="selectedRadio === 'income' ? '' : 'display-none' ">
                <Chart type="bar" :data="incomeChartData" :options="cancellationsOrIncomeStatsOptions"
                    v-if="incomeChartData != null" />
            </div>
        </div>

        <PadelLateralTab />

    </div>
</div>