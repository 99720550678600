<PadelLateralTab />

<div class="view-main-container">
    
    <div class="inner-view-container">

        <div class="nav-container flexbox-row justify-space-between padding-bottom-20 padding-top-20">
            <p class="title color-white">{{t('partite')}}</p>
        </div>
        
        <div class="p-col-12 p-md-3 p-d-flex p-flex-sm-row p-flex-column p-align-center p-justify-start">
            <PadelDropdown 
                class="dropdown adjust-dropdown-alignment" 
                :label="t('sport')"
                :options="matchTypesOptions" 
                @input="selectMatchType($event.value)"
                :default="selectedMatchType" />
        </div>

        <TabView class="tab-view" 
            :activeIndex="selectedTab" 
            @tab-change="onChangeTab($event.index)">
            <TabPanel>
                <template #header>
                    <span>{{uppercase(t('actives'))}}</span>
                </template>
                <div></div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <span>{{uppercase(t('archived'))}}</span>
                </template>
                <div></div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <span>{{uppercase(t('canceled'))}}</span>
                </template>
                <div></div>
            </TabPanel>
        </TabView>        

        <div class="table-main-container">
            <List 
                :values="openGames" 
                :hoverEnabled="true" 
                :emptyState="t('no_games_found')"
                @row-clicked="onClickEvent">
                <template class="header" v-slot:header>
                    <th>{{t('date')}}</th>
                    <th>{{t('game_duration')}}</th>
                    <th>{{t('playground')}}</th>
                    <th>{{t('players_num')}}</th>
                    <th>{{t('game_status')}}</th>
                    <th>{{t('start_hour')}}</th>
                    <th>{{t('level')}}</th>
                    <th>{{t('booked_playground')}}</th>
                </template>
                <template v-if="openGames.length > 0" v-slot:tile="slotProps">
                    <td>
                        <div class="td-content">
                            {{ formatDate(slotProps.value) }}
                        </div>
                    </td>
                    <td>
                        <div class="td-content">
                            {{ t('minutes', { time: formatGameDuration(slotProps.value) }) }}
                        </div>
                    </td>
                    <td>
                        <div class="td-content">
                            {{ slotProps.value.playground_name }}
                        </div>
                    </td>
                    <td>
                        <div class="td-content">
                            <span :class="{'confirmed': isGameConfirmed(slotProps.value), 'open': !isGameConfirmed(slotProps.value)}"></span>
                            {{ t('players_with_number', { num: getPlayersNumber(slotProps.value) }) }}
                        </div>
                    </td>
                    <td>
                        <div class="td-content">
                            {{ confirmedGame(t, slotProps.value, 'other') }}
                        </div>
                    </td>
                    <td>
                        <div class="td-content">
                            {{ formatTime(slotProps.value.start_at, 'HH:mm') }}
                        </div>
                    </td>
                    <td>
                        <div class="td-content">
                            {{ getLevelsLabel(slotProps.value.min_level, slotProps.value.max_level) }}
                        </div>
                    </td>
                    <td>
                        <div class="td-content">
                            {{ slotProps.value.booking_state === 'OPEN' ? t('no') : t('yes') }}
                        </div>
                    </td>
                </template>
            </List>
        </div>

    </div>

</div>