import { AWSSessionTokens } from "@/models/tokens.models";

export function tokensValid(tokens: AWSSessionTokens) {
    const now = Math.floor(new Date().getTime() / 1000);
    if(tokens.idToken.getExpiration && tokens.accessToken.getExpiration) {
        return now < tokens.idToken.getExpiration() && now < tokens.accessToken.getExpiration();
    } else {
        return now < tokens.accessTokenExpiration && now < tokens.idTokenExpiration;
    }
    
}

export function addExpirationToTokens(_currentSession) {
    return {
        ..._currentSession,
        accessTokenExpiration: _currentSession.getAccessToken().getExpiration(),
        idTokenExpiration: _currentSession.getIdToken().getExpiration()
    }
}

export function isViewPostLogin(view: string) {
    return (view !== "PadelLogin" &&
        view !== "PadelSignup" && 
        view !== "PadelConfirmSignup" && 
        view !== "PadelConfirmPhone" && 
        view !== "DownloadContract")
}