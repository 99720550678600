<div class="main-container flex-column padding-main-container">
    <div>
        <div class="header display-flex">
            <img class="smash-img" src="@/theme/imgs/logo_completo.svg" alt="">
            <div class="already-have-account font-12 color-blue cursor-pointer" @click="goToLogin()">
                {{uppercase(t('already_registered'))}}
            </div>
        </div>
        <div class="text-section flex-column">
            <h3 class="title">
                {{t('download_your_contract')}}
            </h3>
            <div class="text-info-container flexbox-column margin-top-20">
                <div class="color-white text" >{{t('finish_registration_text')}}</div>
            <div class="color-white text" >{{t('first_bulletpoint')}}</div>
            <div class="color-white text" >{{t('second_bulletpoint')}}</div>
            <div class="color-white text" >{{t('third_bulletpoint')}}admin@wesmash.it</div>
            <div class="color-white text" >{{t('will_receive_mail_contract')}}</div>
            <p class="color-white subtext">{{t('greetings_from_wesmash')}}</p>
            </div>
            
            <div v-if="contractDownloaded" class="button display-flex">
                <p class="button-text color-white font-12 bold-text">{{uppercase(t('contract_downloaded'))}}</p>
                <i class="material-icons color-white margin-left">check</i>
            </div>
            <div v-else class="button display-flex cursor-pointer" @click="downloadFileFromS3()">
                <p class="button-text color-blue font-12 bold-text">{{uppercase(t('download_contract'))}}</p>
                <i class="material-icons color-blue margin-left">file_download</i>
            </div>
        </div>
    </div>

    <div class="buttons-container display-flex">
        <p class="font-12 color-blue cursor-pointer bold-text" @click="goBack()">{{uppercase(t('back'))}}</p>
        <PadelButton @click="confirm()" :label="'CONFERMA'" :disabled="!contractDownloaded" />
    </div>
</div>

<div v-if="modalOpened">
    <DumbModal :title="confirmTitle" @hide="hideModal()">
        <div class="main-modal-container justify-space-between text-container">
            <div class="text-container">
                <span class="material-icons confirm-icon">check_circle_outline</span>
                <div class="text-container margin-top-20">
                    <p class="confirm-text text">{{t('request_completed_text')}}</p>
                    <!-- <p class="confirm-text text-2 margin-top-none text">{{t('send_contract_to')}} <b>admin@wesmash.it</b>
                        {{t('and_wait_for_comunication')}}</p> -->
                </div>
            </div>
            <p class="abort margin-top-40 color-blue font-12 cursor-pointer montserrat" @click="goToNextPage()">
                {{uppercase(t('close'))}}</p>
        </div>
    </DumbModal>
</div>