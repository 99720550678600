<div class="box card-box color-white flexbox-column"
    :style="{ backgroundImage: `url('${cardImage}')`, backgroundPosition: 'right 10px center', backgroundRepeat: 'no-repeat', backgroundSize: '72px auto' }">
    <img src="@/theme/imgs/user_type.svg" class="image" />
    
    <div class="box-info-part">
        <div class="actions-container">
            <Actions
                :item="element"
                :editable="true"
                :deletable="deletable"
                :duplicable="false"
                @edit="onEditType"
                @delete="onDeleteType" />
        </div>
        <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-5">
            {{ t('player_type') }}: <span class="color-white p-text-normal montserrat">{{ upperCaseFirstLetter(element.name) }}</span>
        </p>
        <p class="box-row color-gold margin-0 barlow bold-text margin-bottom-5">
            {{ t('category') }}: <span class="color-white p-text-normal montserrat">{{ t(element.type) }}</span>
        </p>
    </div>
</div>