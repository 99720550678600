import { 
//  mptyAddClubForm, 
//  emptyClub, 
  emptyConfirmSignupUser, 
  emptyPageTable, 
  emptySessionTokens, 
  emptyUser
} from "@/constants/initial-values.constants";
import { 
  //ClubCreationRequest, 
  PadelClub, 
  TablePage
} from "@/models/club.models";
//import { ClubForm } from "@/models/forms.models";
import { AWSSessionTokens } from "@/models/tokens.models";
//import { CognitoUser } from 'amazon-cognito-identity-js';
import { 
  BackEndUser, 
//  User
} from "@/models/user.models";
import { PadelPlayground } from "@/models/playground.models";

export const initialState = {
  loader: false,
  showSuccessToast: '',
  showErrorToast: '',
  allowDownloadContract: false,
  user: emptyUser,
  confirmSignupUser: emptyConfirmSignupUser,
  joinWesmashRequests: emptyPageTable,
  adminClubs: [],
  sessionTokens: emptySessionTokens,
  myClubs: [],
  myClub: null,
  myPlaygrounds: [],
  forcedLogout: false,
  currentDate: undefined,
  priceRule: null,
  exceptionRule: null,
  bundleResponse: null,
  scrollEvent: null,
  searchUser: null,
  userCards: [],
}

export const COPY = JSON.stringify(initialState);

export const state = {...initialState};
  
export type State = { 
  loader: boolean;
  showSuccessToast: string;
  showErrorToast: string;
  allowDownloadContract: boolean;
  user: BackEndUser;
  confirmSignupUser: {username: string; password: string};
  joinWesmashRequests: TablePage;
  adminClubs: PadelClub[];
  sessionTokens: AWSSessionTokens;
  myClubs: PadelClub[];
  myClub: string;
  myPlaygrounds: PadelPlayground[];
  forcedLogout: boolean;
  currentDate: Date;
  priceRule: any;
  exceptionRule: any;
  bundleResponse: any;
  scrollEvent: any;
  searchUser: any;
  userCards: object[];
};