
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { uppercase } from "@/services/mapping.service";
import { useStore } from "@/store/store";
import { MutationTypes } from "@/store/mutation-types";
import { downloadFile } from "@/services/files.service";
import { BackEndUser } from "@/models/user.models";
import { PadelClub } from "@/models/club.models";
import { postLoginPagesAvailable } from "@/services/routing.service";
export default defineComponent({
  name: "DownloadContract",
  setup() {
    const store = useStore();
    const contractDownloaded = ref(false);
    const modalOpened = ref(false);
    const { t, locale } = useI18n();
    const router = useRouter();
    const confirmTitle = ref(null);
    onMounted(() => {
      confirmTitle.value = t("request_completed");
    });

    /* COMPUTED */
    const myClub = computed(function(): PadelClub {
      return store.getters.getMyClub;
    });

    function goToLogin() {
      router.push("login");
    }

    function confirm() {
      modalOpened.value = true;
    }

    function downloadFileFromS3() {
      downloadFile(process.env.VUE_APP_WESMASH_CONTRACT_LINK, "weSmash_contract.pdf");
      contractDownloaded.value = true;
    }

    function goBack() {
      router.back();
    }

    function handleRouting() {
      if (postLoginPagesAvailable(myClub.value)) {
        router.push("scheduler");
      } else {
        router.push("login");
      }
    }

    function goToNextPage() {
      store.commit(MutationTypes.WRITE_CONFIRM_SIGNUP_USER, {
        username: "",
        password: "",
      });
      store.commit(MutationTypes.WRITE_ALLOW_DOWNLOAD, false);
      handleRouting();
    }

    function hideModal() {
      modalOpened.value = false;
      goToNextPage();
    }

    return {
      t,
      locale,
      uppercase,
      confirm,
      downloadFileFromS3,
      goBack,
      goToNextPage,
      contractDownloaded,
      modalOpened,
      confirmTitle,
      hideModal,
      goToLogin
    };
  },
});
