
import { computed, defineComponent, onMounted, ref } from "vue";
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import {
  formatPhoneWithPrefix,
  mapBookingError,
  mapFormError,
  mapInputError,
  returnSearchUserDropdownChoices,
  uppercase,
  mySportsByOptions,
} from "@/services/mapping.service";
import {
  bookingRadioOptions,
  openGamesRadioOptions,
  gameDurationOptions,
  eventTypeOptions,
} from "@/constants/radio-options.constants";
import {
  emptyNewBookingErrors,
  emptyNewBookingForm,
  emptyPadelUser,
  emptySchedulerPlayground,
} from "@/constants/initial-values.constants";
import {
  isSameOrBefore,
  isDateBefore,
  isToday,
  isTimeBeforeNow,
  isTimeBeforeStart,
  isTimeAfterEnd,
  isDurationOverClosing,
  isSameDate,
  convertMinutesOfDayToHHMM,
  convertHHMMtoMillisecondsOfDay,
  convertMillisecondsOfDaytoHHMM,
  calculateGameDuration,
  mappedWeekDay,
} from "@/services/dates.service";
import { ActionTypes } from "@/store/action-types";
import { CALENDAR_USERS_PAGE_SIZE, PlayerCategoriesOptions } from "@/constants/constants.constants";
import { PadelUser } from "@/models/user.models";
import { BookingType } from "@/constants/booking.enum";
import {
  mapNewBookingPayload,
  mapRecurringBookingPayload,
} from "@/services/mapping.service";
import {
  isNewBookingFormEmpty,
  isFormBookingValid,
  newBookingSchemaValidation,
} from "@/services/form-validation.service";
import { validCellPhoneNumberSchema } from "@/services/form-validation.service";
import { BookingError, VueCalEvent } from "@/models/timeslots.models";
import { FIXED_PHONE_PREFIX } from "@/constants/constants.constants";
import { PaymentOptions } from "@/constants/payment-choices.constants";
import {
  BookingColorChoices,
  ClosureColorChoices,
  LessonColorChoices,
  MantainanceColorChoices,
  OtherColorChoices,
} from "@/constants/color-choices.constants";
import { PaymentState } from "@/constants/payment.enum";
import moment from 'moment';
import { PlaygroundSports } from "@/constants/playground.enums";
import RepeatBooking from "@/components/repeat-booking/RepeatBooking.vue";
import { CardCategories } from "@/constants/card-types.enum";
import { PadelClub } from "@/models/club.models";


export default defineComponent({
  components: {
    RepeatBooking
  },
  props: [
    "playgroundOptions",
    "sportsOptions",
    "openingHour",
    "closingHour",
    "playgroundMap",
    "provvNewEvent",
    "selectedDate"
  ],
  emits: ["hideModal", "creationCompleted", "refreshPage", "checkBundle"],
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const store = useStore();
    const selectedBookingType = ref(null);
    const isCreateEnabled = ref(false);
    const selectedTab = ref(0);
    const newBookingForm = ref({ ...emptyNewBookingForm });
    const newBookingErrors = ref({ ...emptyNewBookingErrors });
    const textMaxLength = ref(500);
    const hideError = ref(false);
    const searchPlaceholder = ref(null);
    const searchOptions = ref([]);
    const modalOpened = ref(null);
    const isPhone = ref(true);
    const otp = ref(null);
    const isModal = ref(true);
    const withEndTime = ref(false);

    let formWithDefaultValues = { ...emptyNewBookingForm };
    const DEFAULT_GAME_DURATION = 90;
    const DEFAULT_LESSON_DURATION = 60;


    /* COMPUTED */
    const myClub = computed(function (): PadelClub {
      return store.getters.getMyClub;
    });

    const myClubId = computed(function(): string {
      return store.getters.getMyClub.club_id;
    });
    const mySports = computed(function() {
      return mySportsByOptions(props.sportsOptions, props.playgroundOptions);
    });

    const colorChoices = computed(function() {
      switch (selectedBookingType.value) {
        case BookingType.BOOKING:
        case BookingType.OPEN_BOOKING: 
          return BookingColorChoices;
        case BookingType.LESSON:
          return LessonColorChoices;
        case BookingType.MAINTENANCE:
          return MantainanceColorChoices;
        case BookingType.CLOSING:
          return ClosureColorChoices;
        case BookingType.OTHER:
          return OtherColorChoices;
        default:
          return OtherColorChoices;
      }
    });

    /* COMPUTED */
    const computedBookingType = computed(function(): string {
      if (
        selectedTab.value === 1 &&
        selectedBookingType.value === BookingType.BOOKING
      ) {
        return BookingType.BACKOFFICE_BOOKING;
      } else if (
        selectedTab.value === 2 &&
        selectedBookingType.value === BookingType.BOOKING
      ) {
        return BookingType.BACKOFFICE_BOOKING_DESCRIPTION;
      } else if (
        selectedTab.value === 1 &&
        selectedBookingType.value === BookingType.LESSON
      ) {
        return BookingType.BACKOFFICE_LESSON;
      } else if (
        selectedTab.value === 2 &&
        selectedBookingType.value === BookingType.LESSON
      ) {
        return BookingType.BACKOFFICE_LESSON_DESCRIPTION;
      } else if (
        selectedBookingType.value === BookingType.OPEN_BOOKING
      ) {
        return BookingType.BACKOFFICE_OPEN_BOOKING;
      } else {
        return selectedBookingType.value;
      }
    });

    const computedPlaygrounds = computed(function(): {value: string; label: string; sport: string}[] {
      return props.playgroundOptions.filter(el => el.sport === newBookingForm.value.sport_type);
    })

    const computedBookingRadioOptions = computed(function() {
      const arr = JSON.parse( JSON.stringify(bookingRadioOptions) );
      if (!props.provvNewEvent) return arr;
      if (!props.provvNewEvent.playground_id) return arr;
      const playground = props.playgroundOptions.find(el => el.value.id === props.provvNewEvent.playground_id);
      if ([PlaygroundSports.PADEL, PlaygroundSports.TENNIS].includes(playground.sport)) {
        arr.push(openGamesRadioOptions[0])
      }
      return arr;
    });


    const bookingCategoriesOptions = computed(function (): Array<any> {
      if (myClub.value.club_category == CardCategories.COMMERCIAL) {
        return [PlayerCategoriesOptions[1]]; // PlayerCategoriesOptions[1] => Commerciale
      }
      return PlayerCategoriesOptions;
    });


    function getPriceFromSlotInRules(playgroundId, rules) {
      // INIT
      let endTime   = newBookingForm.value.end_at;
      if (!withEndTime.value) {
        const time = (convertHHMMtoMillisecondsOfDay(newBookingForm.value.start_at) + (newBookingForm.value.game_duration * 60 * 1000));
        endTime  = convertMillisecondsOfDaytoHHMM(time);
      }
      const date    = moment(newBookingForm.value.date);
      const bookingDay = date.weekday();
      const realDay   = mappedWeekDay(bookingDay);
      const startAt = moment(`${date.format("YYYY-MM-DD")} ${newBookingForm.value.start_at}`);
      const endAt   = moment(`${date.format("YYYY-MM-DD")} ${endTime}`);

      // ITERATE
      for (let x = 0; x < rules.length; x++) {
        const rule        = rules[x];
        if (!rule.playground_ids.includes(playgroundId)) continue;
        if (rule.days) {
          if (!rule.days.includes(realDay)) continue;
        }
        const slots = rule.slots.reverse();

        // DATES
        let ruleStartAt = null;
        let ruleEndAt   = null;

        // CHECK TYPE
        if (rule.exception_at) {
          ruleStartAt = moment(`${rule.exception_at}`);
          ruleEndAt   = moment(`${rule.exception_at}`).endOf('date');
        } else {
          ruleStartAt = moment(`${date.format("YYYY")}-${rule.start_at}`);
          ruleEndAt   = moment(`${date.format("YYYY")}-${rule.end_at}`).endOf('date');
        }

        // EXIT POINT
        if (!(date.valueOf() >= ruleStartAt.valueOf() && date.valueOf() <= ruleEndAt.valueOf())) continue;
        for (let i = 0; i < slots.length; i++) {
          const slot        = slots[i];
          const slotStartAt = moment(`${date.format("YYYY-MM-DD")} ${slot.start_at}`);
          const slotEndAt   = moment(`${date.format("YYYY-MM-DD")} ${slot.end_at}`);
          
          if ((startAt.valueOf() >= slotStartAt.valueOf() && endAt.valueOf() <= slotEndAt.valueOf())
            || (startAt.valueOf() >= slotStartAt.valueOf() && startAt.valueOf() <= slotEndAt.valueOf())) {
            const minutes = moment(endAt).diff(moment(startAt), 'minutes');
            if (minutes == 120 && slot.price_120 > -1) return slot.price_120 / 100;
            if (minutes >= 90 && minutes < 120 && slot.price_90 > -1) return slot.price_90 / 100;
            if (minutes >= 60 && minutes < 90 && slot.price_60 > -1) return slot.price_60 / 100;
            return null;
          }
        }
      }
      return null;
    }

    async function syncPrice() {
      // GET RULES
      try {
        const startAt = moment(newBookingForm.value.date).startOf('date');
        const endAt   = moment(newBookingForm.value.date).endOf('date');
        let price     = null;

        // GET EXCEPTIONS
        const exceptions = await store.dispatch(ActionTypes.GET_EXCEPTION_RULES, {
            club_id: myClubId.value,
            exception_at: startAt.toISOString(),
            limit: 1,
        });
        price = getPriceFromSlotInRules(newBookingForm.value.playground.id, exceptions.records);
        
        if (!price) {
          // GET RULES
          const rules = await store.dispatch(ActionTypes.GET_PRICES_RULES, {
            club_id: myClubId.value,
            between_start: startAt.toISOString(),
            between_end: endAt.toISOString(),
            limit: 999,
          });
          price = getPriceFromSlotInRules(newBookingForm.value.playground.id, rules.records);
        }
        
        // CHECK
        if (price) {
          newBookingForm.value.price = price;
        } else {
          newBookingForm.value.price = null;
        }
      } catch (error) {
          return;
      }
    }

    function hideModal() {
      emit("hideModal", true);
    }

    function updateFormError(key: string, value) {
      newBookingErrors.value[key] = value;
    }

    function handleExceedingOverClosureTimeError() {
      if (newBookingForm.value.game_duration) {
        if (
          isDurationOverClosing(
            newBookingForm.value.start_at,
            newBookingForm.value.game_duration,
            props.closingHour,
            props.openingHour
          )
        ) {
          updateFormError("start_at",  t('booking_exceeds_closing_hours'));
        } else {
          updateFormError("start_at", "");
          // CALL PRICE RELOAD
          syncPrice();
        }
      } else if (newBookingForm.value.end_at) {
        const duration = calculateGameDuration(newBookingForm.value.end_at, newBookingForm.value.start_at);
        if (
          isDurationOverClosing(
            newBookingForm.value.start_at,
            duration,
            props.closingHour,
            props.openingHour
          )
        ) {
          updateFormError("end_at",  t('booking_exceeds_closing_hours'));
        } else {
          updateFormError("end_at", "");
          // CALL PRICE RELOAD
          syncPrice();
        }
      } else {
        updateFormError("start_at", "");
        updateFormError("end_at", "");
      }
    }

    function setFormValue(key: string, value) {
      newBookingForm.value[key] = value;
      if (key === "sport_type") {
        setFormValue("playground", emptySchedulerPlayground);
      } else if (key === 'playground') {
        syncPrice();
      }
    }



    function updateTimeErrors(from: string, to: string) {
      updateFormError("start_at", from);
      updateFormError("end_at", to);
    }

    function updateDateErrors(from: string, to: string) {
      updateFormError("start_date", from);
      updateFormError("end_date", to);
    }

    function isBookingExtendable() {
      return (selectedBookingType.value === BookingType.OTHER ||
        selectedBookingType.value === BookingType.MAINTENANCE)
    }

    function needsToValidate(end_at: string, start_at: string) {
      if (isBookingExtendable()) {
        if (
          isSameDate(
            newBookingForm.value.start_date,
            newBookingForm.value.end_date
          )
        ) {
          return isSameOrBefore(end_at, start_at);
        }
      } else {
        return isSameOrBefore(end_at, start_at);
      }
    }

    function updateFormTimeValue(field: string, value: string) {
      setFormValue(field, value);
      if (field === "start_at") {
        if (needsToValidate(newBookingForm.value.end_at, value)) {
          updateTimeErrors(
            "L'orario di inizio deve essere precedente a quello di fine",
            ""
          );
        } else {
          updateTimeErrors("", "");
        }
      } else if (field === "end_at") {
        if (needsToValidate(value, newBookingForm.value.start_at)) {
          updateTimeErrors(
            "",
            "L'orario di fine deve essere successivo a quello di inizio"
          );
        } else {
          updateTimeErrors("", "");
        }
      }
    }
    function validateAndUploadStartAtEndEndAt() {
      updateFormTimeValue("start_at", newBookingForm.value.start_at);
      updateFormTimeValue("end_at", newBookingForm.value.end_at);
    }

    function validateMail(values, field: string) {
      newBookingSchemaValidation
        .validateAt(field, values)
        .then(() => {
          updateFormError(field, "");
        })
        .catch((err) => {
          updateFormError(field, mapInputError(err.message, t));
        });
    }

    function updateFormDateValue(field: string, value) {
      setFormValue(field, value);
      if (field === "start_date") {
        //PRESELEZIONE END DATE A PARTIRE DA START DATE
        if (!newBookingForm.value.end_date) {
          newBookingForm.value.end_date = value;
        }
        if (isDateBefore(newBookingForm.value.end_date, value)) {
          updateDateErrors(t('start_date_must_be_before_end'),
            ""
          );
        } else {
          updateDateErrors("", "");
        }
      } else if (field === "end_date") {
        if (isDateBefore(value, newBookingForm.value.start_date)) {
          updateDateErrors(
            "",t('end_date_must_be_after_start')
          );
        } else {
          updateDateErrors("", "");
        }
      }
      validateAndUploadStartAtEndEndAt();
    }

    function updateDateAndTimeErrors(date: string, time: string, end: string) {
      updateFormError("date", date);
      updateFormError("start_at", time);
      updateFormError("end_at", end);
    }

    function validateHourToday(field: string, value) {
      setFormValue(field, value);
      if (field === "game_duration") {
        if (
          !(
            isToday(newBookingForm.value.date) &&
            isTimeBeforeNow(newBookingForm.value.start_at)
          )
        ) {
          handleExceedingOverClosureTimeError();
        } else {
          // CALL PRICE RELOAD
          syncPrice();
        }
      } else if (field === "start_at") {
        if (!withEndTime.value) {
          newBookingForm.value.end_at = convertMillisecondsOfDaytoHHMM( convertHHMMtoMillisecondsOfDay(newBookingForm.value.start_at) + (newBookingForm.value.game_duration * 60 * 1000) );
        }
        if (isToday(newBookingForm.value.date)) {
          if (isTimeBeforeNow(newBookingForm.value.start_at) && !isBookingExtendable()) {
            updateFormError(
              "start_at",t('cannot_book_before_now')
            );
          } else if (isTimeAfterEnd(newBookingForm.value.start_at, newBookingForm.value.end_at) && !isBookingExtendable() && withEndTime.value) {
            updateFormError(
              "start_at",t('cannot_book_after_end')
            );
          } else {
            updateDateAndTimeErrors("", "", "");
            handleExceedingOverClosureTimeError();
          }
        } else {
          if (isTimeAfterEnd(newBookingForm.value.start_at, newBookingForm.value.end_at) && !isBookingExtendable() && withEndTime.value) {
            updateFormError(
              "start_at",t('cannot_book_after_end')
            );
          } else {
            updateDateAndTimeErrors("", "", "");
            handleExceedingOverClosureTimeError();
          }
        }
      } else if (field === "end_at") {
        if (isToday(newBookingForm.value.date)) {
          if (isTimeBeforeNow(newBookingForm.value.end_at) && !isBookingExtendable()) {
            updateFormError(
              "end_at", t('cannot_book_before_now')
            );
          } else if (isTimeBeforeStart(newBookingForm.value.end_at, newBookingForm.value.start_at) && !isBookingExtendable()) {
            updateFormError(
              "end_at", t('cannot_book_before_start')
            );
          } else {
            updateDateAndTimeErrors("", "", "");
            handleExceedingOverClosureTimeError();
          }
        } else {
          if (isTimeBeforeStart(newBookingForm.value.end_at, newBookingForm.value.start_at) && !isBookingExtendable()) {
            updateFormError(
              "end_at", t('cannot_book_before_start')
            );
          } else {
            updateDateAndTimeErrors("", "", "");
            handleExceedingOverClosureTimeError();
          }
        }
      } else if (field === "date") {
        if (isToday(newBookingForm.value.date)) {
          if (isTimeBeforeNow(newBookingForm.value.start_at) && !isBookingExtendable()) {
            updateFormError(
              "date",t('cannot_book_before_now')
            );
          } else {
            updateDateAndTimeErrors("", "", "");
            handleExceedingOverClosureTimeError();
          }
        } else {
          updateDateAndTimeErrors("", "", "");
          handleExceedingOverClosureTimeError();
        }
      }
    }

    function validateHours() {
      validateHourToday("game_duration", newBookingForm.value.game_duration);
      validateHourToday("start_at", newBookingForm.value.start_at);
     /*  validateHourToday("date", newBookingForm.value.date); */
    }

    function mapDefaultValues() {
      return {
        ...emptyNewBookingForm,
        price: null,
        game_duration: DEFAULT_GAME_DURATION,        
        payment_state: PaymentState.UNPAID,
      };
    }

    function initializeNewBookingValues() {
      formWithDefaultValues = {
        ...mapDefaultValues(),
        date: props.selectedDate,
        start_date: props.selectedDate,
        end_date: props.selectedDate,
        playground: emptySchedulerPlayground
      };
      

      console.log(formWithDefaultValues);
    }

    function returnGameDuration(event: VueCalEvent) {
      const duration = event.endTimeMinutes - event.startTimeMinutes;
      if (duration < 60) { return 60}
      else if(duration > 120) { return 120}
      else return duration;
    }

    function initializeNewBookingValuesWithDraggedEvent(event: VueCalEvent) {
      const defaultPlayground = props.playgroundOptions.find((pl) => pl.value.id === event.playground_id);
      formWithDefaultValues = {
        ...mapDefaultValues(),
        playground: defaultPlayground.value,
        date: event.start,
        start_date: event.start,
        end_date: event.end,
        start_at: convertMinutesOfDayToHHMM(event.startTimeMinutes),
        end_at: event.endTimeMinutes > 0 ? convertMinutesOfDayToHHMM(event.endTimeMinutes) : '24:00',
        price: null,
        game_duration: returnGameDuration(event), 
        sport_type: defaultPlayground.sport,
      };
      if (event.endTimeMinutes > 0) {
        withEndTime.value = true;
      }
    }

    function initializeFormWithDefaultValues() {
      if (props.provvNewEvent) {
        initializeNewBookingValuesWithDraggedEvent(props.provvNewEvent);
      } else {
        initializeNewBookingValues();
      }
    }

    onMounted(() => {
      initializeFormWithDefaultValues();
    });

    function initializeForm() {
      
      const actualForm = JSON.parse( JSON.stringify(newBookingForm.value) );
      if (props.provvNewEvent) {
        newBookingForm.value = {
          ...formWithDefaultValues, 
          color: colorChoices.value[0].value
        };
      } else {
        newBookingForm.value = { 
          ...formWithDefaultValues,
          game_duration: selectedBookingType.value === BookingType.LESSON ? DEFAULT_LESSON_DURATION: DEFAULT_GAME_DURATION,
          color: colorChoices.value[0].value
        };
      }
      if (actualForm.game_duration) {
        newBookingForm.value.game_duration = actualForm.game_duration;
      }
      if (actualForm.date) {
        newBookingForm.value.date = new Date(actualForm.date);
      }
      if (actualForm.start_at) {
        newBookingForm.value.start_at = actualForm.start_at;
      }
      if (actualForm.end_at) {
        newBookingForm.value.end_at = actualForm.end_at;
      }
      if (actualForm.start_date) {
        newBookingForm.value.start_date = new Date(actualForm.start_date);
      }
      if (actualForm.end_date) {
        newBookingForm.value.end_date = new Date(actualForm.end_date);
      }
      if (!newBookingForm.value.sport_type) {
        setFormValue('sport_type', PlaygroundSports.PADEL);
        setFormValue('playground', computedPlaygrounds.value[0].value);
      }

      if (myClub.value.club_category == "COMMERCIAL") {
        // Se il centro è di tipo commerciale => Default Commerciale
        newBookingForm.value.booking_category = bookingCategoriesOptions.value[0].value;
      }
    }

    function initializeFormErrors() {
      newBookingErrors.value = { ...emptyNewBookingErrors };
    }

    function initialize() {
      initializeForm();      
      initializeFormErrors();
      validateHours();
      searchPlaceholder.value = null;
    }

    function onChangeTab(event) {
      initialize();    
      selectedTab.value = event;
    }

    function changeSlotTypology(event) {      
      selectedTab.value = 0;
      selectedBookingType.value = event;
      initialize();   
      if (event === 0 || event === 1) {
        withEndTime.value = false;
      } else {
        withEndTime.value = true;
      }   
    }

    async function searchUsers(event: string) {
      searchPlaceholder.value = event;
      try {
        const usersArray = await store.dispatch(ActionTypes.GET_USERS, {
          club_id: myClubId.value,
          filters: { page_size: CALENDAR_USERS_PAGE_SIZE, name: event },
        });
        if (usersArray.values.length > 0) {
          newBookingErrors.value.wesmash_user = null;
          //POPOLAMENTO DROPDOWN CHOICES
          searchOptions.value = returnSearchUserDropdownChoices(
            usersArray.values
          );
        } else {
          newBookingErrors.value.wesmash_user = t("no_user_found");
          searchOptions.value = [];
        }
      } catch (error) {
        return;
      }
    }

    function handleSearchClose() {
      searchOptions.value = null;
      searchPlaceholder.value = null;
      newBookingForm.value.wesmash_user = { ...emptyPadelUser };
      newBookingErrors.value.wesmash_user = null;
    }



    async function checkUserCards(userId: string) {
      const response = await store.dispatch(ActionTypes.GET_CARDS, { user_id: userId, limit: 1 });
      return response.data.records;
    }


    async function searchValueSelected(event: { label: string; value: PadelUser }) {
      searchPlaceholder.value = event.label;
      newBookingForm.value.wesmash_user = event.value;

      if (myClub.value.club_category == "COMMERCIAL") {
        // Se il centro è di tipo commerciale => Default Commerciale
        newBookingForm.value.booking_category = bookingCategoriesOptions.value[0].value;
      }
      else {
        const category = myClub.value.player_categories.filter((el) => el.code == event.value.player_category)[0];
        if (category != null) {
          newBookingForm.value.booking_category = category.type;
        }
        else{ 
          const cards = await checkUserCards(event.value.id);
          if (cards.length > 0) {
            newBookingForm.value.booking_category = "INSTITUTIONAL";
          }
        }
      }
    }

    function onToggleChange(event) {
      if (event) {
        newBookingForm.value.playground = { ...emptySchedulerPlayground };
      }
    }

    async function sendPhoneCode() {
      try {
        otp.value = await store.dispatch(
          ActionTypes.SEND_OTP,
          formatPhoneWithPrefix(newBookingForm.value.phone_number_prefix, newBookingForm.value.user_phone_number)
        );
      } catch (err) {
        return;
      }
    }

    function handleNotCreatedBookingError(error: BookingError[]) {
      if (selectedBookingType.value === BookingType.CLOSING) {
        updateFormError(
          "start_date",
          mapBookingError(error[0], props.playgroundMap)
        );
      } else {
        updateFormError(
          "start_at",
          mapBookingError(error[0], props.playgroundMap)
        );
      }
    }

    function hideConfirmPhoneModal() {
      modalOpened.value = null;
    }

    async function createBooking() {
      try {
        if (newBookingForm.value.recurring) {
          const payload = mapRecurringBookingPayload(
            myClubId.value,
            computedBookingType.value,
            newBookingForm.value,
            withEndTime.value
          );
          const bookingResponse = await store.dispatch(
            ActionTypes.BOOKING_BUNDLE_CHECK,
            payload
          );
          emit("checkBundle", bookingResponse);
          hideModal();
          hideConfirmPhoneModal();
        } else {
          const payload = mapNewBookingPayload(
            myClubId.value,
            computedBookingType.value,
            newBookingForm.value,
            withEndTime.value
          )
          const bookingResponse = await store.dispatch(
            ActionTypes.ADD_BOOKING,
            payload
          );
          if (
            bookingResponse.notUpdated &&
            bookingResponse.notUpdated.length > 0
          ) {
            handleNotCreatedBookingError(bookingResponse.notUpdated);
            emit("refreshPage", true);
            hideConfirmPhoneModal();
          } else {
            emit("creationCompleted", payload);
            hideModal();
            hideConfirmPhoneModal();
          }
        }
      } catch (error) {
        return;
      }
    }

    async function checkConfirm() {
      if (!newBookingForm.value.certify_phone_number) {
        createBooking();
      } else {
        try {
          await sendPhoneCode();
          modalOpened.value = "CONFIRM_PHONE_NUMBER";
        } catch (err) {
          return;
        }
      }
    }    

    function validatePhoneNumber(event: string) {
      validCellPhoneNumberSchema
        .validate(
          {
            user_phone_number: event,
          },
          { abortEarly: false }
        )
        .then(() => {
          newBookingErrors.value.user_phone_number = null;
        })
        .catch((err) => {
          console.log(err.inner);
          newBookingErrors.value.user_phone_number = mapFormError(
            err.inner[0].message, t
          );
        });
    }

    function handlePhoneValidation(event) {
      if (newBookingForm.value.certify_phone_number) {
        validatePhoneNumber(event);
      }
    }

    function erasePhonePrefix() {
      const str = newBookingForm.value.user_phone_number;
      const result = str.split("+39");
      if(result.length > 1) {
        newBookingForm.value.user_phone_number = result[1];
      }
    }

    function handleToggleCertify(value: boolean) {
      if (value) {
        erasePhonePrefix();
        validatePhoneNumber(newBookingForm.value.user_phone_number);
      } else {
        newBookingErrors.value.user_phone_number = null;
      }
    }

    function onSearchValidate(event) {
      setTimeout(() => {
        searchPlaceholder.value = "";
      }, 10);
      searchPlaceholder.value = null;
      handleSearchClose();
    }

    function switchEndTime(value) {
      withEndTime.value = value;
    }

    return {
      t,
      locale,
      selectedBookingType,
      bookingRadioOptions,
      computedBookingRadioOptions,
      gameDurationOptions,
      eventTypeOptions,
      isCreateEnabled,
      newBookingForm,
      selectedTab,
      textMaxLength,
      hideError,
      newBookingErrors,
      searchPlaceholder,
      searchOptions,
      colorChoices,
      computedBookingType,
      modalOpened,
      isPhone,
      otp,
      FIXED_PHONE_PREFIX,
      isModal,
      withEndTime,
      switchEndTime,
      computedPlaygrounds,
      mySports,
      bookingCategoriesOptions,

      hideModal,
      uppercase,
      checkConfirm,
      createBooking,
      onChangeTab,
      setFormValue,
      initializeForm,
      changeSlotTypology,
      updateFormTimeValue,
      updateFormDateValue,
      validateHourToday,
      searchUsers,
      handleSearchClose,
      searchValueSelected,
      isNewBookingFormEmpty,
      isFormBookingValid,
      onToggleChange,
      hideConfirmPhoneModal,
      sendPhoneCode,
      validatePhoneNumber,
      handleToggleCertify,
      handlePhoneValidation,
      validateMail,
      onSearchValidate,
      PaymentOptions,
      syncPrice,
    };
  },
});
