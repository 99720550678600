<div class="width-100">
    <div class="p-grid p-jc-between margin-bottom-20">
        <div class="p-col-12 p-md-4 p-text-md-left">
            <MultiSelect v-model="selectedPlaygrounds" :options="playgrounds" optionLabel="label"
                :placeholder="t('select_playground')" :filter="false" class="multiselect-custom" />
        </div>
        <div class="p-col-12 p-md-4">
            <div class="cursor-pointer color-blue flexbox-row align-center p-mt-md-3" @click="goToCreate()">
                <span class="button-text montserrat">{{ t('add_new_rule') }}</span>
                <span class="material-icons">add</span>
            </div>
        </div>
    </div>
    <div class="table-main-container">
        <List :values="rulesList" :hoverEnabled="false" :emptyState="t('no_rules_defined')">
            <template class="header" v-slot:header>
                <th>{{t('rule_from')}}</th>
                <th>{{t('rule_to')}}</th>
                <th>{{t('rule_name')}}</th>
                <th>{{t('status')}}</th>
                <th>{{t('valid_days')}}</th>
                <th>{{t('sport')}}</th>
                <th>{{t('playground')}}</th>
                <th>{{t('price_starting_from')}}</th>
                <th>{{t('actions')}}</th>
            </template>
            <template v-if="rulesList.length > 0" v-slot:tile="slotProps">
                <td class="width-1">
                    <div class="td-content">
                        {{ moment(slotProps.value.start_at).format("DD/MM") }}
                    </div>
                </td>
                <td class="width-1">
                    <div class="td-content">
                        {{ moment(slotProps.value.end_at).format("DD/MM") }}
                    </div>
                </td>
                <td>
                    <div class="td-content">
                        <p class="td-content-row">{{slotProps.value.name}}</p>
                    </div>
                </td>
                <td>
                    <div class="td-content">
                        <p class="td-content-row">
                            <span class="active">{{ t('active') }}</span>
                        </p>
                    </div>
                </td>
                <td>
                    <div class="td-content">
                        <p class="td-content-row">{{mapDays(slotProps.value.days)}}</p>
                    </div>
                </td>
                <td>
                    <div class="td-content">
                        <p class="td-content-row">{{t(slotProps.value.sport_type)}}</p>
                    </div>
                </td>
                <td>
                    <div class="td-content">
                        <p class="td-content-row" v-if="showMappedPlaygrounds(slotProps.value.playground_ids)">
                            {{mapPlaygrounds(slotProps.value.playground_ids)}}</p>
                        <p class="" v-else>
                            <Badge :value="slotProps.value.playground_ids.length"
                                v-tooltip.bottom="mapPlaygrounds(slotProps.value.playground_ids, '\n')" />
                        </p>
                    </div>
                </td>
                <td>
                    <div class="td-content">
                        <p class="td-content-row">{{(slotProps.value.starting_price / 100)}}€</p>
                    </div>
                </td>
                <td class="width-1">
                    <div class="td-content">
                        <Actions :item="slotProps.value" @edit="onEdit" @duplicate="confirmDuplicate"
                            @delete="confirmDelete" />
                    </div>
                </td>
            </template>
        </List>
        <div v-if="hasMore" class="load-more-button-container flexbox-row margin-top-10">
            <p class="abort" @click="loadMoreRules()">CARICA</p>
        </div>
    </div>
</div>

<div v-if="showDeleteDialog">
    <DumbModal :title="t('deleting_rule')" @hide="hideModal()">
        <div class="modal-content p-text-center p-p-6">
            <i class="pi pi-exclamation-triangle color-warning" style="font-size: 2rem"></i>
            <p class="p-text-center color-white" v-html="t('deleting_rule_message', { name: selectedRule.name })"></p>
        </div>

        <template #footer>
            <div class="p-d-flex p-jc-end">
                <PadelButton :label="t('confirm')" @click="onDelete(selectedRule.id)" />
            </div>
        </template>
    </DumbModal>
</div>

<div v-if="showDuplicateDialog">
    <DumbModal :title="t('duplicate_rule')" @hide="hideModal()">
        <div class="modal-content p-text-center p-p-6">
            <i class="pi pi-exclamation-triangle color-warning" style="font-size: 2rem"></i>
            <p class="p-text-center color-white" v-html="t('duplicate_rule_message', { name: selectedRule.name })"></p>
        </div>

        <template #footer>
            <div class="p-d-flex p-jc-end">
                <PadelButton :label="t('confirm')" @click="onDuplicate" />
            </div>
        </template>
    </DumbModal>
</div>