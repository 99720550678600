
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import moment from "moment";
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
  nextTick,
} from "vue";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/drag-and-drop.js";
import "vue-cal/dist/i18n/it.js";
import ExceptionRuleDialog from "@/components/exception-rules/Dialog/ExceptionRuleDialog.vue";
import { formatDateYYYYMMDD } from "@/services/dates.service";
import {
  priceCalendarClass,
  mapRulesIntoExceptionRule,
  mapExceptionRuleIntoForm,
  uppercase,
  mapContentForTooltip,
  mySportsByOptions,
} from "@/services/mapping.service";
import {
  sportOptions,
  getSportByValue,
} from "@/constants/radio-options.constants";
import { PadelClub } from "@/models/club.models";
import { ActionTypes } from "@/store/action-types";
import { useRouter } from "vue-router";
import { PadelPlayground } from "@/models/playground.models";
import { WEEKDAYS_SLOTS } from "@/constants/constants.constants";
import { RuleType } from "@/constants/rules.enum";

export default defineComponent({
  name: "PricesCalendar",
  components: {
    VueCal,
    ExceptionRuleDialog,
  },
  props: ["containerClass", "withSelectedDate", "onlyAllPlaygrounds"],
  setup(props) {
    const calendar = ref(null);
    const dateCalendar = ref(null);
    const store = useStore();
    const { t, locale } = useI18n();
    const rulesList = ref([]);
    const calendarView = ref("week");
    const timeCellHeight = ref(50);
    //const computedTimeFrom = ref(0 * 60);
    //const computedTimeTo = ref((24 * 60));
    // const computedSplits = ref([]);
    const router = useRouter();
    const selectedDate = ref(new Date());
    const filterAllPlaygrounds = ref(false);
    const selectedPlayground = ref(null);
    const showExceptionDialog = ref(false);
    const selectedExceptionRule = ref(null);
    const eventDate = ref(null);
    const eventsList = ref([]);
    const tooltipId = ref(null);
    const tooltipX = ref(0);
    const tooltipY = ref(0);

    /* COMPUTED */
    const myClub = computed(function (): PadelClub {
      return store.getters.getMyClub;
    });
    const myPlaygrounds = computed(function (): PadelPlayground[] {
      return store.getters.getMyPlaygrounds;
    });
    const mappedPlaygrounds = computed(function (): {
      value: string;
      label: string;
      sport: string;
    }[] {
      return myPlaygrounds.value.map((el) => {
        return { value: el.id, label: el.name, sport: el.sport };
      });
    });
    const mySports = computed(function () {
      return mySportsByOptions(sportOptions, mappedPlaygrounds.value);
    });
    const selectedSport = ref((mySports.value[0] || {}).value);
    const computedTimeFrom = computed(function () {
      return myClub.value.opening_hour / 1000 / 60;
    });
    const computedTimeTo = computed(function () {
      return myClub.value.closing_hour / 1000 / 60;
    });
    const specialHours = computed(function (): Object {
      const output = {};
      /*const openingHour = openingTime.value;
            const closingHour = closingTime.value;
            for (let i = 1; i <= 7; i++) {
                output[i] = [
                    { from: 0 * 60, to: openingHour, class: "closed" },
                    {
                        from: openingHour,
                        to: closingHour,
                        class: "special-hours-opened",
                    },
                    { from: closingHour, to: 24 * 60, class: "closed" },
                ];
            }*/
      return output;
    });
    const weekFormat = computed(function (): string {
      const start = getStartDate();
      const end = getEndDate();
      if (start.year() === end.year()) {
        return `${start.format("dddd D MMMM")} - ${end.format(
          "dddd D MMMM"
        )} ${start.format("yy")}`;
      }
      return `${start.format(
        "dddd D MMMM yy"
      )} - ${end.format("dddd D MMMM yy")}`;
    });

    const computedPlaygrounds = computed(function (): {
      value: string;
      label: string;
      sport: string;
    }[] {
      return mappedPlaygrounds.value.filter(
        (el) => el.sport === selectedSport.value
      );
    });

    // -- PRESET
    if (myPlaygrounds.value.length > 0) {
      selectedPlayground.value = (computedPlaygrounds.value[0] || {}).value;
    }

    /** COMPUTED */
    const computedSplits = computed(function () {
      if (props.onlyAllPlaygrounds)
        return myPlaygrounds.value.map((el) => {
          return { id: el.id, playground: el };
        });
      if (filterAllPlaygrounds.value) {
        return myPlaygrounds.value
          .filter((el) => el.sport === selectedSport.value)
          .map((el) => {
            return { id: el.id, playground: el };
          });
      }
      return [];
    });

    /** WATCH */
    watch(filterAllPlaygrounds, function (currentValue, oldValue) {
      if (!props.withSelectedDate) {
        selectedDate.value = new Date();
      }
      setTimeout(() => {
        if (currentValue === true) {
          calendarView.value = "day";
          selectedPlayground.value = null;
        } else {
          calendarView.value = "week";
          if (myPlaygrounds.value.length > 0) {
            selectedPlayground.value = myPlaygrounds.value[0].id;
          }
        }

        setTimeout(() => getRules(), 200);
      }, 150);
    });

    // FUNCTIONS
    function getStartDate(utc = true) {
      if (filterAllPlaygrounds.value) {
        const date = moment(selectedDate.value);
        return date.startOf("day");
      }
      if (utc) {
        const date = moment.utc(selectedDate.value);
        return date.startOf("week");
      }
      const date = moment(selectedDate.value);
      return date.startOf("week");
    }
    function getEndDate(utc = true) {
      if (filterAllPlaygrounds.value) {
        const date = moment(selectedDate.value);
        return date.endOf("day");
      }
      if (utc) {
        const date = moment.utc(selectedDate.value);
        return date.endOf("week");
      }
      const date = moment.utc(selectedDate.value);
      return date.endOf("week");
    }

    function remapRulesWithEvents() {
      setTimeout(() => {
        // INIT
        let events = [];

        // MAP
        rulesList.value.forEach((rule) => {
          let hasPlayground = false;
          let playground = null;
          if (selectedPlayground.value) {
            hasPlayground = rule.playground_ids.includes(
              selectedPlayground.value
            );
            if (hasPlayground) {
              playground = myPlaygrounds.value.find(
                (el) => el.id == selectedPlayground.value
              );
            }
          }
          if (
            (selectedPlayground.value && hasPlayground) ||
            filterAllPlaygrounds.value
          ) {
            let slots = rule.slots;
            slots.forEach((slot) => {
              let startYear = getStartDate().year();
              let endYear = getEndDate().year();
              let ruleException = moment(`${rule.exception_at}`);
              let range = [];
              if (rule.type == RuleType.RULE) {
                range = getDateRange(
                  getStartDate(),
                  getEndDate(),
                  "YYYY-MM-DD"
                );
              } else if (rule.type === RuleType.EXCEPTION) {
                range = [moment(rule.exception_at).format("YYYY-MM-DD")];
              }
              if (filterAllPlaygrounds.value && !range) {
                range = [moment(selectedDate.value).format("YYYY-MM-DD")];
              }
              // TODO: Pensare di fare un refactor usando i "repeat" di vue-cal (https://antoniandre.github.io/vue-cal/#ex--recurring-events)
              for (let i = 0; i < range.length; i++) {
                let d = range[i];
                if (rule.type == RuleType.RULE) {
                  let rangeDate = moment(`${d}`);
                  let ruleStart = moment(
                    `${rangeDate.year()}-${rule.start_at}`
                  );
                  let ruleEnd = moment(`${rangeDate.year()}-${rule.end_at}`);
                  if (
                    !(
                      rangeDate.valueOf() >= ruleStart.valueOf() &&
                      rangeDate.valueOf() <= ruleEnd.valueOf()
                    )
                  ) {
                    continue;
                  }
                  let weekDay = rangeDate.weekday();
                  let isMonday =
                    weekDay === 0 && rule.days.includes(WEEKDAYS_SLOTS.MONDAY);
                  let isTuesday =
                    weekDay === 1 && rule.days.includes(WEEKDAYS_SLOTS.TUESDAY);
                  let isWednesday =
                    weekDay === 2 &&
                    rule.days.includes(WEEKDAYS_SLOTS.WEDNESDAY);
                  let isThursday =
                    weekDay === 3 &&
                    rule.days.includes(WEEKDAYS_SLOTS.THURSDAY);
                  let isFriday =
                    weekDay === 4 && rule.days.includes(WEEKDAYS_SLOTS.FRIDAY);
                  let isSaturday =
                    weekDay === 5 &&
                    rule.days.includes(WEEKDAYS_SLOTS.SATURDAY);
                  let isSunday =
                    weekDay === 6 && rule.days.includes(WEEKDAYS_SLOTS.SUNDAY);

                  if (
                    isMonday ||
                    isTuesday ||
                    isWednesday ||
                    isThursday ||
                    isFriday ||
                    isSaturday ||
                    isSunday
                  ) {
                    if (filterAllPlaygrounds.value) {
                      rule.playground_ids.forEach((id) => {
                        let tmpPlayground = myPlaygrounds.value.find(
                          (pl) => pl.id === id
                        );
                        events.push({
                          day: moment(d),
                          start: `${d} ${slot.start_at}`,
                          end: `${d} ${slot.end_at}`,
                          title:
                            `${tmpPlayground.name}<br>${slot.start_at} ${slot.end_at}` +
                            `<div class="vuecal__notes">${formatPrices(
                              slot
                            )}</div>`,
                          class: priceCalendarClass(
                            rule,
                            moment(d),
                            rulesList.value,
                            id
                          ),
                          split: id,
                          parent: rule,
                        });
                      });
                    } else {
                      let event = {
                        day: moment(d),
                        start: `${d} ${slot.start_at}`,
                        end: `${d} ${slot.end_at}`,
                        title:
                          `${playground.name}<br>${slot.start_at} ${slot.end_at}` +
                          `<div class="vuecal__notes">${formatPrices(
                            slot
                          )}</div>`,
                        class: priceCalendarClass(
                          rule,
                          moment(d),
                          rulesList.value,
                          selectedPlayground.value
                        ),
                        parent: rule,
                      };
                      events.push(event);
                    }
                  }
                } else if (rule.type == RuleType.EXCEPTION) {
                  let rangeDate = moment(`${d}`);
                  if (!(d === ruleException.format("YYYY-MM-DD"))) {
                    continue;
                  }
                  let weekDay = rangeDate.weekday();
                  let ruleDay = moment(rule.exception_at).weekday();

                  if (weekDay === ruleDay) {
                    if (filterAllPlaygrounds.value) {
                      rule.playground_ids.forEach((id) => {
                        let tmpPlayground = myPlaygrounds.value.find(
                          (pl) => pl.id === id
                        );
                        console.log(id);

                        if (tmpPlayground) {
                          events.push({
                            day: moment(d),
                            start: `${d} ${slot.start_at}`,
                            end: `${d} ${slot.end_at}`,
                            title: `${tmpPlayground.name}<br>${rule.name}`,
                            content:
                              `${slot.start_at} ${slot.end_at}` +
                              `<div class="vuecal__notes">${formatPrices(
                                slot
                              )}</div>`,
                            class: priceCalendarClass(rule),
                            split: id,
                            parent: rule,
                          });
                        }
                      });
                    } else {
                      let event = {
                        day: moment(d),
                        start: `${d} ${slot.start_at}`,
                        end: `${d} ${slot.end_at}`,
                        title: rule.name,
                        content:
                          `${playground.name}<br>${slot.start_at} ${slot.end_at}` +
                          `<div class="vuecal__notes">${formatPrices(
                            slot
                          )}</div>`,
                        class: priceCalendarClass(rule),
                        parent: rule,
                      };
                      events.push(event);
                    }
                  }
                }
              }
            });
          }
        });

        // SET
        eventsList.value = events;
      }, 100);
    }

    async function getRules() {
      try {
        let startAt = getStartDate(false);
        let endAt = getEndDate(false);
        let list = [];

        // GET RULES
        const response = await store.dispatch(ActionTypes.GET_PRICES_RULES, {
          club_id: myClub.value.club_id,
          between_start: startAt.toISOString(),
          between_end: endAt.toISOString(),
          limit: 999,
        });
        const records = response.records;
        records.forEach((el) => {
          el.type = RuleType.RULE;
        });
        list = list.concat(records);

        // GET EXCEPTIONS
        const exceptions = await store.dispatch(
          ActionTypes.GET_EXCEPTION_RULES,
          {
            club_id: myClub.value.club_id,
            between_start: startAt.toISOString(),
            between_end: endAt.toISOString(),
            limit: 999,
          }
        );
        const records2 = exceptions.records;
        records2.forEach((el) => {
          el.type = RuleType.EXCEPTION;
        });
        list = list.concat(records2);

        // SET
        rulesList.value = list;

        // REMAP
        remapRulesWithEvents();

        // SCROLL
        scrollToOpeningTime();
      } catch (error) {
        return;
      }
    }

    function getDateRange(startDate, endDate, dateFormat) {
      let dates = [],
        end = endDate.clone(),
        diff = endDate.diff(startDate, "days");

      if (!startDate.isValid() || !endDate.isValid() || diff <= 0) {
        return;
      }

      for (let i = 0; i < diff; i++) {
        dates.push(end.subtract(1, "d").format(dateFormat));
      }
      if (!dates.find((el) => el === endDate.format(dateFormat))) {
        dates.push(endDate.format(dateFormat));
      }

      return dates.sort();
    }

    function formatPrices(slot) {
      // INIT
      const val = JSON.parse(JSON.stringify(slot));
      let prices = [];

      if (val.price_60 > 0)
        prices.push(`${t("minutes", { time: 60 })} - ${val.price_60 / 100}€`);
      if (val.price_90 > 0)
        prices.push(`${t("minutes", { time: 90 })} - ${val.price_90 / 100}€`);
      if (val.price_120 > 0)
        prices.push(`${t("minutes", { time: 120 })} - ${val.price_120 / 100}€`);

      // RETURN
      return prices.join("<br>");
    }

    function selectSport(value: string) {
      selectedSport.value = value;
    }

    function selectPlayground(value: string) {
      selectedPlayground.value = value;
      setTimeout(() => getRules(), 150);
    }

    function openCalendar() {
      if (dateCalendar.value) {
        dateCalendar.value.overlayVisible = true;
      }
    }

    // SYSTEM
    onMounted(() => {
      nextTick(() => {
        let defaultFlow = true;
        if (props.withSelectedDate && props.withSelectedDate != undefined) {
          selectedDate.value = props.withSelectedDate;
          defaultFlow = false;
        }
        if (props.onlyAllPlaygrounds && props.onlyAllPlaygrounds === true) {
          filterAllPlaygrounds.value = true;
          defaultFlow = false;
        }
        if (defaultFlow) getRules();
      });
    });

    function onEventClick(event, e) {
      // SET
      const parent = JSON.parse(JSON.stringify(event.parent));
      const playgroundId = selectedPlayground.value || event.split || "";
      eventDate.value = moment(event.start_at).startOf("day");

      if (parent.type === RuleType.RULE) {
        selectedExceptionRule.value = mapRulesIntoExceptionRule(
          event,
          rulesList,
          parent.sport_type,
          playgroundId
        );
      } else if (parent.type === RuleType.EXCEPTION) {
        selectedExceptionRule.value = mapExceptionRuleIntoForm(parent);
      }

      // SHOW
      showExceptionDialog.value = true;

      // Prevent navigating to narrower view (default vue-cal behavior).
      e.stopPropagation();
    }

    function addDays(days) {
      let d = moment(selectedDate.value);
      d.add(days, "days");
      selectedDate.value = d.toDate();

      // RELOAD
      setTimeout(() => getRules(), 150);
    }

    function goToToday() {
      selectedDate.value = new Date();

      // RELOAD
      setTimeout(() => getRules(), 150);
    }

    function hideExceptionDialog(reload) {
      // SET
      showExceptionDialog.value = false;
      selectedExceptionRule.value = null;
      eventDate.value = null;

      // --
      if (reload) {
        setTimeout(() => getRules(), 150);
      }
    }

    function onDateSelected(value) {
      let d = moment(value);
      if (!filterAllPlaygrounds.value) {
        d.add(1, "day");
      }
      selectedDate.value = d.toDate();

      setTimeout(() => getRules(), 150);
    }

    function scrollToOpeningTime() {
      const tmp = document.querySelector("#vuecal .vuecal__bg");
      // const hours = computedTimeFrom.value / 60 * 2;
      // tmp.scrollTo({ top: hours * timeCellHeight.value, behavior: 'smooth' })
      tmp.scrollTo({ top: 0, behavior: "smooth" });
    }

    function switchAllPlaygrounds(value) {
      filterAllPlaygrounds.value = value;
    }

    function showTooltip($event, id) {
      const rect = $event.srcElement.getBoundingClientRect();
      tooltipId.value = id;
      tooltipX.value = rect.x - 240;
      tooltipY.value = rect.bottom - rect.top + 25;
    }

    function hideTooltip() {
      tooltipId.value = null;
      tooltipX.value = null;
      tooltipY.value = null;
    }

    return {
      t,
      locale,
      uppercase,
      calendar,
      dateCalendar,

      myClub,
      myPlaygrounds,
      mappedPlaygrounds,
      computedPlaygrounds,
      rulesList,
      eventsList,
      specialHours,
      timeCellHeight,
      computedTimeFrom,
      computedTimeTo,
      calendarView,
      sportOptions,
      mySports,
      computedSplits,
      selectedDate,
      filterAllPlaygrounds,
      selectedPlayground,
      selectedSport,
      addDays,
      goToToday,
      getStartDate,
      getEndDate,
      selectSport,
      selectPlayground,
      weekFormat,
      openCalendar,
      showExceptionDialog,
      selectedExceptionRule,
      eventDate,
      hideExceptionDialog,
      onDateSelected,
      scrollToOpeningTime,
      switchAllPlaygrounds,
      showTooltip,
      hideTooltip,
      tooltipId,
      tooltipX,
      tooltipY,
      mapContentForTooltip,

      moment,
      onEventClick,
      formatDateYYYYMMDD,
      getSportByValue,
    };
  },
});
