
import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { uppercase } from "@/services/mapping.service";
import { useStore } from "@/store/store";
import { ActionTypes } from "@/store/action-types";
import { useRouter } from "vue-router";
import { BackEndUser, User } from "@/models/user.models";
import { RoleTypes } from "@/constants/role-types.enum";
import { showToast } from "@/services/error-management.service";
import { ToastErrors } from "@/constants/generic-errors.enum";
import { MutationTypes } from "@/store/mutation-types";
import { ClubStatus } from "@/constants/club-status.enum";

export default defineComponent({
  name: "Nav",
  props: {
    dropdownChoices: { type: Array, required: true },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const dropdownOpened = ref(false);
    const openedModal = ref(null);
    const provvPic = ref(null);
    const { t, locale } = useI18n();

    /* COMPUTED */
    const myClubs = computed(function() {
      return store.getters.getMyClubs;
    });

    
    function handleClickCalendar(event) {
      const specifiedElement = document.getElementById(
        "img-box"
      );
      const isClickInside = specifiedElement.contains(event.target as Node);
      if (isClickInside) {
        dropdownOpened.value = true;
      } else if (!isClickInside) {
        dropdownOpened.value = false;
      }
    }

    onMounted(()=>{
      document.addEventListener("click", handleClickCalendar);
    }) 

    onUnmounted(() => {
      document.removeEventListener("click", handleClickCalendar);
    });

    function logout() {
      store.dispatch(ActionTypes.LOGOUT, null);
      router.push("login");
    }

    function openBox() {
      dropdownOpened.value = true
    }

    function closeBox() {
      dropdownOpened.value = false;
    }

    function toggleBox() {
      dropdownOpened.value = !dropdownOpened.value;
    }

    function returnUserName(user: BackEndUser) {
      if (user.groups.indexOf(RoleTypes.ADMIN) > -1) {
        return 'ADMIN'
      } else {
      return user.name.toUpperCase() + ' ' + user.family_name.toUpperCase().charAt(0) + '.'
      }
    }

    function hideModal() {
      openedModal.value = null;
      provvPic.value = null;
    }

    function openChangePasswordModal() {
      openedModal.value = 'FIND_ACCOUNT'
    }

    function openChangeProfilePicModal() {
      openedModal.value = 'CHANGE_PROFILE_PIC'
    }

    function openDisambiguation() {
      openedModal.value = 'DISAMBIGUATION'
    }

    function elementClicked(label: string) {
      if (label === "logout") {
        logout();
      } else if (label === "change_password") {
        openChangePasswordModal();
      } else if (label === "change_profile_picture") {
        openChangeProfilePicModal();
      }
      else if(label === "disambiguation_club") {
        openDisambiguation();
      }
      closeBox();
    }

    function handleWrongFileUploaded(file) {
      showToast(ToastErrors.WRONG_FILE_FORMAT);
    }

    function saveFile(file) {
      provvPic.value = file;
    }

    function handleClubSelected(club) {
      store.commit(MutationTypes.WRITE_LOGGED_CLUB, club);
      if (club.status === ClubStatus.CREATION) {
        router.push("add-club");
      } else {
        router.push("scheduler");
      }
      hideModal();
    }

    async function uploadProfilePic() {
     /*  console.log(provvPic.value) */
     try {
       const url = await store.dispatch(ActionTypes.CHANGE_PROFILE_PIC, provvPic.value.src);
       await store.dispatch(ActionTypes.AUTH_ACTION, null);
       hideModal();
     } catch (error) {
       console.error(error);
     }
      
    }

    /* COMPUTED */
    const user = computed(function() {
      return store.getters.getUser;
    });
    return {
      t,
      locale,
      uppercase,
      user,
      dropdownOpened,
      logout,
      openBox,
      toggleBox,
      provvPic,
      elementClicked,
      openedModal,
      hideModal,
      returnUserName,
      handleWrongFileUploaded,
      saveFile,
      uploadProfilePic,
      myClubs,
      handleClubSelected
    };
  },
});
