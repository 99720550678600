
import { defineComponent, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import {
  uppercase,
} from "@/services/mapping.service";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const openedModal = ref(null);
    const selectedTab = ref(0);
    const { t, locale } = useI18n();

    switch (route.query.tab) {
      case "price-rules":
        selectedTab.value = 2;
        break;
    }

    // CLEAR QUERY
    setTimeout(() => {
        const query = Object.assign({}, route.query);
        delete query.tab;
        router.replace({ query });
    }, 150);

    function redirect(page: string) {
      router.push(page);
    }

    function closeModal() {
      openedModal.value = null;
    }

    function openModal(modal: string) {
      openedModal.value = modal;
    }

    function onChangeTab(event) {
      selectedTab.value = event;
    }

    function goToAddClubPage() {
      redirect("add-new-club");
    }

    function selectTab(event) {
      selectedTab.value = event;
    }
    
    return {
      t,
      locale,
      openModal,
      uppercase,
      openedModal,
      closeModal,
      goToAddClubPage,
      selectedTab,
      onChangeTab,
      selectTab,
    };
  },
});
