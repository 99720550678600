<div class="view-main-container paddin-bottom-20">

    <PadelLateralTab />

    <div class="header" id="scheduler-header">
        <div class="first-row padding-bottom-20 padding-top-20 margin-left-40">
            <p class="scheduler-title color-white">
                {{t('calendar')}}
                <PadelToggle v-model:checked="showPriceRules" @change="switchCalendarView($event)"
                    containerClass="p-d-inline-flex p-ml-2" labelClass="color-white overflow-inherit font-12"
                    :label="t('prices_and_hours')" />
            </p>
            <div class="flexbox-row align-center" v-if="!showPriceRules">
                <div class="scheduler-button export-button cursor-pointer display-flex"
                    :class="[playgrounds.length > 0 ? '' : 'disabled']" @click="openModal('UPLOAD_EXCEL')">
                    <p class="scheduler-button-text color-blue" :class="[playgrounds.length > 0 ? '' : 'disabled']">
                        {{uppercase(t('import'))}}</p>
                    <i class="material-icons color-blue"
                        :class="[playgrounds.length > 0 ? '' : 'disabled']">file_upload</i>
                </div>
                <div class="vl"></div>
                <div class="scheduler-button export-button cursor-pointer display-flex"
                    :class="[playgrounds.length > 0 ? '' : 'disabled']" @click="openModal('DOWNLOAD_EXCEL')">
                    <p class="scheduler-button-text color-blue" :class="[playgrounds.length > 0 ? '' : 'disabled']">
                        {{uppercase(t('export'))}}</p>
                    <i class="material-icons color-blue"
                        :class="[playgrounds.length > 0 ? '' : 'disabled']">file_download</i>
                </div>
                <div class="vl"></div>
                <div class="scheduler-button refresh-button cursor-pointer display-flex"
                    :class="[playgrounds.length > 0 ? '' : 'disabled']" @click="refreshPage()">
                    <p class="scheduler-button-text color-blue" :class="[playgrounds.length > 0 ? '' : 'disabled']">
                        {{uppercase(t('refresh'))}}</p>
                    <i class="material-icons color-blue" :class="[playgrounds.length > 0 ? '' : 'disabled']">refresh</i>
                </div>
            </div>
        </div>

        <div class="p-d-flex p-flex-column p-flex-sm-row p-jc-between margin-left-40 sub-header" v-if="!showPriceRules">
            <div class="display-flex calendar-selector" :class="[playgrounds.length > 0 ? '' : 'disabled']">
                <PadelButton type="dark" :label="uppercase(t('today'))" class="margin-right-20" @click="goToToday()" />
                <i class="pi pi-chevron-left color-blue cursor-pointer"
                    :class="[playgrounds.length > 0 ? '' : 'disabled']" @click="goToYesterday()"></i>
                <i class="pi pi-chevron-right color-blue cursor-pointer"
                    :class="[playgrounds.length > 0 ? '' : 'disabled']" @click="goToTomorrow()"></i>
                <div class="selected-date cursor-pointer" id="toggle-calendar-button" @click="toggleCalendar()">
                    {{selectedDate ? formatDate() : ''}}
                </div>
                <div :class="show_calendar? '': 'display-none'" class="calendar-container-scheduler">
                    <PadelCalendar :showLabel="false" @input="changeDateWithCalendar($event)" class="calendaaar"
                        id="calendar-scheduler-box" :value="selectedDate" />
                </div>
            </div>
            <div class="display-flex calendar-selector">
                <div class="display-flex">
                    <UsersSearch @userSelected="userSelected" :old="true" />
                </div>
                <div class="vl"></div>
                <div class="scheduler-button cursor-pointer display-flex"
                    :class="[playgrounds.length > 0 ? '' : 'disabled']" @click="openModal('FILTER')">
                    <p class="scheduler-button-text color-blue" :class="[playgrounds.length > 0 ? '' : 'disabled']">
                        {{uppercase(t('filters'))}}
                    </p>
                    <i class="material-icons color-blue"
                        :class="[playgrounds.length > 0 ? '' : 'disabled']">filter_alt</i>
                </div>
                <div class="vl"></div>
                <div class="scheduler-button cursor-pointer display-flex" :class="new__booking"
                    @click="openNewBooking()">
                    <p class="scheduler-button-text color-blue" :class="new__booking">
                        {{uppercase(t('new_booking'))}}
                    </p>
                    <i class="pi pi-plus color-blue" :class="new__booking"></i>
                </div>
            </div>
        </div>

        <div v-if="activeFilters.length > 0 && !showPriceRules" class="chip-container">
            <template v-for="chip in activeFilters">
                <Chip class="chip-table">
                    <div class="chip-content">
                        <span class="chip-text">{{chip.type==='timeslot'?uppercase(t(chip.label)):
                            uppercase(chip.label)}}</span><span class="material-icons icon-close-chip"
                            @click="removeFilter(chip)">
                            close
                        </span>
                    </div>
                </Chip>
            </template>
        </div>

        <div v-if="activeFilters.length > 0 && isFilterActive(activeFilters, 'playground') && !showPriceRules"
            class="third-row playground-box">
            <div class="table-head display-flex">
                <div class="image-container">
                    <Avatar v-if="activeFilters[0].value.playground_image" shape="circle"
                        :image="activeFilters[0].value.playground_image" />
                    <img v-else src="@/theme/imgs/default_campo.png" class="image-circle" />
                </div>
                <div class="calendar-header-description">
                    <div class="calendar-header-title">{{ activeFilters[0].value.name || '-' }}</div>
                    <div class="calendar-header-address color-white">
                        {{ getSportByValue(activeFilters[0].value.sport) }}<br>
                        {{ t(activeFilters[0].value.hidden ? 'private' : 'public') }}
                    </div>
                    <div class="position-relative flexbox-row" style="width: 100%">
                        <span class="i-icon margin-left-10 material-icons-outlined cursor-pointer color-blue"
                            id="info-icon" @click="showTooltip($event, activeFilters[0].value.id)">
                            info
                        </span>
                    </div>
                    <PadelTooltip v-if="tooltipId == activeFilters[0].value.id" :absolute="true" :x="tooltipX"
                        :y="tooltipY" :dark="true" class="tooltip" :arrowPosition="'NONE'"
                        :content="mapContentForTooltip(activeFilters[0].value, t)" @close="hideTooltip" />
                </div>
            </div>
        </div>

        <div v-else-if="playgrounds.length > 0 && splits.length > 0 && !showPriceRules"
            class="third-row playground-box">
            <PadelButton class="left-button position-absolute" v-if="currentPage > 0" @click="goToPreviousPage()"
                :icon="'chevron_left'" />
            <template v-for="playground in playgroundPages[currentPage]" class="playgrounds" :key="playground.id">
                <div class="table-head display-flex">
                    <div class="image-container">
                        <Avatar v-if="playground.playground_image" shape="circle"
                            :image="playground.playground_image" />
                        <img v-else src="@/theme/imgs/default_campo.png" class="image-circle" />
                    </div>
                    <div class="calendar-header-description">
                        <div class="calendar-header-title">{{ playground.name || '-' }}</div>
                        <div class="calendar-header-address color-white">
                            {{ getSportByValue(playground.sport) }}<br>
                            {{ t(playground.hidden ? 'private' : 'public') }}
                        </div>
                        <div class="position-relative flexbox-row" style="width: 100%">
                            <span class="i-icon margin-left-10 material-icons-outlined cursor-pointer color-blue"
                                id="info-icon" @click="showTooltip($event, playground.id)">
                                info
                            </span>
                        </div>
                        <PadelTooltip v-if="tooltipId == playground.id" :absolute="true" :x="tooltipX" :y="tooltipY"
                            :dark="true" class="tooltip" :arrowPosition="'NONE'"
                            :content="mapContentForTooltip(playground, t)" @close="hideTooltip" />
                    </div>
                </div>
            </template>
            <PadelButton class="right-button position-absolute" v-if="currentPage < playgroundPages.length - 1"
                @click="goToNextPage()" :icon="'chevron_right'" />
        </div>
    </div>

    <!-- @cell-dblclick="handleEvents('cell-dblclick', $event)" snapToTime="30" -->
    <div v-if="playgrounds.length > 0 && splits.length > 0 && !showPriceRules" class="cal-container margin-bottom-20">
        <vue-cal :time-cell-height="timeCellHeight" :selected-date="formatDateYYYYMMDD(selectedDate)" locale="it"
            :time-from="computedTimeFrom" :time-to="computedTimeTo" hide-view-selector
            :disable-views="['years', 'year', 'month', 'week']" hide-weekends :time-step="30"
            :special-hours="specialHours" :min-event-width="100" :snapToTime="30"
            :editable-events="{ delete: true, create: createValue }" :events="computedEvents"
            :split-days="computedSplits" @ready="handleEvents('ready', $event)"
            @event-delete="handleEvents('event-delete', $event)"
            @event-drag-create="handleEvents('drag-create' ,$event)" @event-focus="handleEvents('cell-click', $event)"
            @cell-dblclick="handleEvents('dbl-click', $event)">
            <!--<template v-slot:event="{ event, view }">
                <p>pippo</p>

                <div class="vuecal__event-title" v-html="event.title" />
                <div class="vuecal__event-time" v-html="event.time" />
                <div class="vuecal__event-content" v-html="event.content" />
            </template>-->
        </vue-cal>
        <div class="closing-hour position-absolute barlow">{{convertMinutesOfDayToHHMM(computedTimeTo)}}</div>
    </div>

    <div v-else-if="showCalendarBox && playgrounds.length <= 0 && !showPriceRules" class="empty-playgrounds-container">
        <img src="@/theme/imgs/pallina_empty.svg" alt="">
        <div class="color-white empty-text-title margin-top-20">{{t('playgrounds_missing')}}.</div>
        <div class="color-white empty-text">{{t('start_handle_bookings')}}
            <b>{{ '"' + t('my_club').toLowerCase() + '"' }} </b> {{t('and_insert_playground')}}
        </div>
        <div class="scheduler-button new-event-button cursor-pointer display-flex margin-top-20"
            @click="goToAddPlaygrounds()">
            <p class="scheduler-button-text color-blue">{{uppercase(t('add_playground'))}}</p>
            <i class="pi pi-plus color-blue"></i>
        </div>
    </div>

    <div v-if="showPriceRules" class="cal-container p-mb-0 margin-left-40">
        <PricesCalendar containerClass='in-scheduler' :withSelectedDate="selectedDate" :onlyAllPlaygrounds="true" />
    </div>

    <div v-if="modalToShow === 'FILTER'">
        <DumbModal :title="t('filter_club_requests')" :isVisible="true" @hide="hideModal()">
            <div class="flexbox-row filter-modal-top-part">
                <div class="main-filter-container">
                    <div class="dropdown-container">
                        <PadelDropdown class="dropdown" @input="setFilterFormValue(2, $event)"
                            :default="filtersForm[2].value" :options="mySports" :label="t('sport_choice')" />
                    </div>

                    <div class="dropdown-container">
                        <PadelDropdown class="dropdown" @input="setFilterFormValue(0, $event)"
                            :default="filtersForm[0].value" :options="computedPlaygrounds"
                            :label="t('playground_choice')" />
                    </div>

                    <div class="radio-container">
                        <p class="color-white montserrat">{{t('calendar_slot')}}</p>
                        <PadelRadio :value="filtersForm[1].value" :options="computedCalendarFilterOptions"
                            :chipMode="true" @input="setFilterFormValue(1, {value: $event, label: $event})" />
                    </div>

                    <div class="radio-container">
                        <p class="color-white montserrat">{{t('playground_visibility')}}</p>
                        <PadelToggle v-model:checked="filtersForm[3].value" @change="toggleFilterFormValue(3, $event)"
                            labelClass="color-white overflow-inherit" :label="t('private')" />
                    </div>
                </div>

            </div>
            <div class="filter-buttons-container flexbox-row">
                <div>
                    <p class="abort center-button" @click="abortFilters()">{{uppercase(t('abort'))}}</p>
                </div>
                <div class="flexbox-row">
                    <PadelButton class="margin-right-20" :label="uppercase(t('reset_filters'))" :type="'secondary'"
                        @click="initializeFilterForm()"></PadelButton>
                    <PadelButton :label="uppercase(t('apply'))" :type="'primary'" @click="applyFilters()"></PadelButton>
                </div>

            </div>

        </DumbModal>
    </div>

    <div v-else-if="modalToShow === 'TOURNAMENT_DETAIL'">
        <TournamentDetailModal :tournament="tournamentDetails" @goToTournaments="goToTournaments()"
            @hideModal="hideModal()" />
    </div>

    <div v-else-if="modalToShow === 'BOOKING_DETAIL'">
        <BookingDetailModal :booking="detailBooking" :playgroundMap="playgroundMap" :hasCards="userHasCards"
            :playgroundOptions="playgroundOptions" @hideModal="hideModalAndRefresh()" :openingHour="timeFrom / 60"
            :closingHour="timeTo / 60" :sportsOptions="sportOptions" @onCancel="handleDelete(detailBooking)"
            @creationCompleted="scrollOntoEvent($event)" @checkBundle="checkBundle($event)" />
    </div>

    <div v-else-if="modalToShow === 'NEW_BOOKING'">
        <NewBookingModal :playgroundMap="playgroundMap" :playgroundOptions="playgroundOptions"
            :sportsOptions="sportOptions" :openingHour="timeFrom / 60" :closingHour="timeTo / 60"
            @hideModal="hideModalAndRefresh()" :provvNewEvent="provvNewEvent" @refreshPage="refreshPage()"
            :selectedDate="selectedDate" @creationCompleted="scrollOntoEvent($event)"
            @checkBundle="checkBundle($event)" />
    </div>

    <div v-else-if="modalToShow === 'BOOKING_TAKING'">
        <BookingTakingModal :booking="detailBooking" @hideModal="hideModalAndRefresh()"
            @creationCompleted="scrollOntoEvent($event)" />
    </div>

    <div v-else-if="modalToShow === 'SURE_WANNA_DELETE'">
        <DumbModal :title="bookingToDelete.type === 'BOOKING'?
         t('eliminate_app_timeslot_title') : t('eliminate_timeslot_title')" @hide="hideModal()">
            <div class="top-part flexbox-column align-center">
                <i class="material-icons icon-alert margin-bottom-20">warning_amber</i>
                <div class="color-white alert-text montserrat">{{t('attention')}}</div>
                <div v-if="bookingToDelete.type === 'BOOKING'" class="color-white alert-text montserrat">
                    {{t('app_timeslot_warning')}}</div>
                <div v-else-if="!!bookingToDelete.bundle_id" class="color-white alert-text montserrat"
                    v-html="t('eliminate_timeslot_from_bundle_text', {type: t(bookingToDelete.type)})"></div>
                <div v-else class="color-white alert-text montserrat"
                    v-html="t('eliminate_timeslot_text', {type: t(bookingToDelete.type)})"></div>
                <div class="color-white alert-text montserrat">{{t('sure_wanna_continue_timeslot_delete')}}</div>
                
                <template v-if="bookingToDelete.payment_choice === 'ONLINE' && bookingToDelete.nexi_transaction_id !== undefined && bookingToDelete.nexi_transaction_id !== null">
                    <div class="color-white alert-text montserrat margin-top-20"><span style="color: #E64040">Prenotazione pagata con carta di credito - €{{(bookingToDelete.price / 100).toFixed(2)}}</span></div>
                    <div class="color-white alert-text montserrat margin-top-20 margin-bottom-20" style="padding: 20px; border: 1px solid #38A8FC;text-align: left"><span style="color: #38A8FC;">VUOI RIMBORSARE IL GIOCATORE?</span><BR />Se annulli la prenotazione il rimborso non è automatico.<br />Per rimobrsare il giocatore vai nella sezione ”Pagamenti”.</div>
                </template>
                
            </div>
            <div class="filter-buttons-container flexbox-row">
                <div>
                    <p class="abort center-button" @click="hideModal()">{{uppercase(t('abort'))}}</p>
                </div>
                <div class="flexbox-row">
                    <PadelButton :label="uppercase(t('confirm'))" :type="'primary'"
                        @click="deleteTimeslot(bookingToDelete)"></PadelButton>
                </div>
            </div>
        </DumbModal>
    </div>

    <div v-else-if="modalToShow === 'CLUB_DISABLED'">
        <DumbModal :title="t('disabled_club')" @hide="hideModal()">
            <div class="top-part flexbox-column align-center">
                <i class="material-icons icon-alert margin-bottom-20">warning_amber</i>
                <div class="color-white alert-text montserrat">{{t('attention')}}</div>
                <div class="color-white alert-text montserrat"
                    v-html="t('disabled_club_modal_text', {mail: 'info@wesmash.it'})"></div>
            </div>
            <div class="filter-buttons-container flexbox-row">
                <div>
                    <p class="abort center-button" @click="logout()">{{uppercase(t('exit'))}}</p>
                </div>
                <div class="flexbox-row">
                    <PadelButton :label="uppercase(t('get_in'))" :type="'primary'" @click="hideModal()"></PadelButton>
                </div>
            </div>
        </DumbModal>
    </div>

    <div v-else-if="modalToShow === 'EVENT_OVER_CLOSING_TIME'">
        <DumbModal :title="t('new_booking')" @hide="hideModal()">
            <div class="top-part flexbox-column align-center">
                <span class="material-icons-outlined reject-icon">error_outline</span>
                <div class="color-white alert-text montserrat">{{t('attention')}}</div>
                <div class="color-white alert-text montserrat">{{t('event_over_closing_time')}}</div>
            </div>
            <div class="drag-error-buttons flexbox-row">
                <div>
                    <p class="abort center-button" @click="hideModal()">{{uppercase(t('close'))}}</p>
                </div>
            </div>
        </DumbModal>
    </div>

    <div v-else-if="modalToShow === 'EVENT_OVERLAPS'">
        <DumbModal :title="t('new_booking')" @hide="hideModal()">
            <div class="top-part flexbox-column align-center">
                <span class="material-icons-outlined reject-icon">error_outline</span>
                <div class="color-white alert-text montserrat">{{t('attention')}}</div>
                <div class="color-white alert-text montserrat">{{t('event_overlaps')}}</div>
            </div>
            <div class="drag-error-buttons flexbox-row">
                <div>
                    <p class="abort center-button" @click="hideModal()">{{uppercase(t('close'))}}</p>
                </div>
            </div>
        </DumbModal>
    </div>

    <div v-else-if="modalToShow === 'DOWNLOAD_EXCEL'">
        <DumbModal :title="t('export_bookings')" @hide="hideModal()">
            <div class="top-part flexbox-column align-center">
                <div class="color-white alert-text montserrat margin-bottom-20">{{t('export_bookings_text')}}</div>
                <div class="form-excel-container flexbox-row">
                    <div class="toggle-container flexbox-row align-center flex-100">
                        <div class="download-text color-white montserrat margin-right-10">
                            {{t('select_daily_excel')}}</div>
                        <PadelToggle class="toggle" v-model:checked="downloadCalendarForm.daily_download"
                            @change="handleToggleDailyDownload($event)" />
                    </div>
                    <div class="calendar-excel-container flex-100">
                        <PadelCalendar @input="updateFormDownloadCalendar($event, 'start_date')"
                            :label="t('select_month')" :value="downloadCalendarForm.start_date" :viewType="'month'"
                            :disabled="downloadCalendarForm.daily_download" />
                    </div>
                </div>
            </div>
            <div class="buttons-container flexbox-row justify-space-between">
                <p class="abort center-button" @click="hideModal()">{{uppercase(t('abort'))}}</p>
                <PadelButton :label="uppercase(t('download'))" :type="'primary'"
                    :disabled="!downloadCalendarForm.daily_download && !downloadCalendarForm.start_date"
                    @click="downloadExcel()"></PadelButton>
            </div>
        </DumbModal>
    </div>

    <div v-else-if="modalToShow === 'UPLOAD_EXCEL'">
        <DumbModal :title="t('import_bookings')" @hide="hideModal()">
            <div class="top-part flexbox-column align-center" v-if="uploadStep === 1">
                <div class="color-white alert-text montserrat margin-bottom-20 p-text-left">
                    {{t('import_bookings_text')}}</div>
                <div class="form-excel-container flexbox-row">
                    <div class="flexbox-row align-center flex-100">
                        <div class="download-button">
                            <img src="/images/template_download.png" /><br>
                            {{ uppercase(t('download_pdf')) }}
                        </div>
                    </div>
                    <div class="flexbox-row align-center flex-100">
                        <div class="download-button"
                            @click="downloadCSV('/import_bookings_sample.csv', 'import_bookings_sample.csv')">
                            <img src="/images/template_download.png" /><br>
                            {{ uppercase(t('download_csv_template')) }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="top-part flexbox-column align-center" v-else-if="uploadStep === 2">

                <div class="upload-form flexbox-row">
                    <PadelDropdown @input="setUploadPlayground($event.value)" :label="t('playground_choice')"
                        :default="uploadPlayground" :options="playgroundOptions" :isDropdownInModal="isModal" />
                </div>

                <div class="flexbox-row align-center w-100 flex-100 p-mt-5">
                    <ADFileUpload ref="fileUploader" mode="custom" :accept="'.csv'" :maxFileSize="1000000"
                        :fileLimit="1" :chooseLabel="$t('upload_csv_file')" :showUploadButton="false"
                        :showCancelButton="false" @change="fileAdded" @remove="fileRemoved" />
                </div>

            </div>
            <div class="top-part flexbox-column align-center" v-else-if="uploadStep === 3">
                <div class="top-part flexbox-column align-center">
                    <div class="icon-alert margin-bottom-20">
                        <img src="/images/elaborating_icon.png" />
                    </div>
                    <div class="color-white alert-text montserrat"><strong>{{t('Elaborating file')}}</strong></div>
                    <div class="color-white alert-text montserrat" v-html="t('Send report after')"></div>
                </div>
            </div>
            <div class="top-part flexbox-column align-center" v-else-if="uploadStep === 4">
                <div class="top-part flexbox-column align-center">
                    <div class="icon-alert margin-bottom-20">
                        <img src="/images/error_icon.png" />
                    </div>
                    <div class="color-white alert-text montserrat"><strong>{{t('Something went wrong')}}</strong></div>
                    <div class="color-white alert-text montserrat" v-html="t('Try to upload again')"></div>
                </div>
            </div>
            <div class="buttons-container flexbox-row justify-space-between">
                <p class="abort center-button" @click="hideModal()" v-if="uploadStep === 1 || uploadStep === 2">
                    {{uppercase(t('abort'))}}</p>
                <PadelButton v-if="uploadStep === 1" :label="uppercase(t('proceed'))" :type="'primary'"
                    @click="uploadStep = 2"></PadelButton>
                <PadelButton v-if="uploadStep === 2" :label="uppercase(t('load'))" :type="'primary'"
                    :disabled="isUploadEmpty(uploadPlayground, uploadFile)" @click="uploadBookings()"></PadelButton>
                <PadelButton v-if="uploadStep === 3 || uploadStep === 4" :label="uppercase(t('close'))"
                    :type="'primary'" @click="hideModal()"></PadelButton>
            </div>
        </DumbModal>
    </div>

    <div v-else-if="modalToShow === 'BOOKING_BUNDLE_ALERT'">
        <DumbModal :title="t('edit_booking')" @hide="hideModal()">
            <div class="top-part flexbox-column align-center">
                <i class="material-icons icon-alert margin-bottom-20">warning_amber</i>
                <div class="color-white alert-text montserrat">{{t('attention')}}</div>
                <div class="color-white alert-text montserrat" v-html="t('editing_bundle')"></div>
            </div>
            <div class="buttons-bundle-dialog flexbox-row">
                <div class="flexbox-row"
                    v-if="detailBooking.timeslotInfo.type === 'BOOKING' || detailBooking.timeslotInfo.type === 'BACKOFFICE_BOOKING' || detailBooking.timeslotInfo.type === 'LESSON' || detailBooking.timeslotInfo.type === 'BACKOFFICE_LESSON'">
                    <PadelButton :label="uppercase(t('edit_taking'))" :type="'secondary'" @click="editTaking()">
                    </PadelButton>
                </div>
                <div class="flexbox-row">
                    <PadelButton :label="uppercase(t('whole_bundle'))" :type="'secondary'" @click="editBundle()">
                    </PadelButton>
                </div>
                <div class="flexbox-row">
                    <PadelButton :label="uppercase(t('single_booking'))" :type="'primary'" @click="editSingleBooking()">
                    </PadelButton>
                </div>
            </div>
        </DumbModal>
    </div>
</div>