
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import { 
    computed, 
    defineComponent, 
    onMounted, 
    ref,
    nextTick,
    watch,
} from "vue";
import { uppercase, upperCaseFirstLetter } from "@/services/mapping.service";
import { PadelClub } from "@/models/club.models";
import Actions from "@/components/actions/Actions.vue";
import moment from "moment";

export default defineComponent({
    components: {
        Actions,
    },
    props: {
        element: {
            type: Object,
            required: true,
            default: () => {
                return {};
            },
        },
        index: { type: Number, required: true, default: null },
    },
    emits: ['edit', 'delete'],
    setup(props, {emit}) {
        const { t, locale } = useI18n();
        const store         = useStore();

        // COMPUTED
        const myClub = computed(function(): PadelClub {
            return store.getters.getMyClub;
        });
        const deletable = computed(function() {
            return props.element.code != "standard";
        });

        // SYSTEM
        onMounted(() => {
            nextTick(() => {
            })
        });

        // METHODS
        function onEditType(type) {
            emit('edit', type)
        }

        function onDeleteType(type) {
            emit('delete', type)
        }

        return {
            t, 
            locale, 
            uppercase,
            upperCaseFirstLetter,

            // DATA
            myClub,
            deletable,

            // FUNCTIONS
            onEditType,
            onDeleteType,
        };
    },
});
