<div class="margin-top-20"
    v-show="!newBookingForm.override_bundle">
    <div class="p-field-checkbox">
        <Checkbox id="repeat" v-model="repeat" :binary="true" />
        <label for="repeat" class="color-white montserrat font-13">{{ t('make_recurring') }}</label>
    </div>
</div>

<div class="margin-top-10 margin-bottom-10"
    v-if="repeat">
    <strong class="color-white montserrat">{{ t('recurring_message', { day: weekday, from: from, to: to }) }}</strong>
    <div class="flexbox-row p-mt-4 p-mb-2">
        <PadelToggle 
            v-model:checked="useNumberOfWeeks"
            @change="switchView($event)"
            containerClass="p-d-inline-flex p-ml-2"
            labelClass="color-white overflow-inherit p-size-mini"
            :disabled="formDisabled"
            :label="t('number_of_weeks')" />
    </div>
    <div class="flexbox-row p-mb-2"
        v-if="useNumberOfWeeks">
        <PadelDropdown 
            :label="t('recurring_for')" 
            :options="WeeksOptions"
            @input="setFormValue('number_of_weeks', $event.value)" 
            :default="numberOfWeeks"
            :isDropdownInModal="isModal"
            :error="newBookingErrors.number_of_weeks"
            :disabled="formDisabled" />
    </div>
    <div class="flexbox-row p-mb-2"
        v-else>
        <PadelCalendar 
            :label="t('recurring_until')"
            :value="newBookingForm.bundle_end_at" 
            :minDate="newBookingForm.date"
            @input="setFormValue('bundle_end_at', $event)" 
            :error="newBookingErrors.bundle_end_at"
            :disabled="formDisabled" />
    </div>
</div>