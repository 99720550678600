<div class="padel-search-container flexbox-row" v-if="clubList.length > 0 || searchFormValue">
    <PadelSearch @onEnterPressed="searchValueChanged($event)" @onClosePressed="eraseSearchValue()"
        :value="searchFormValue" :label="t('search_club_name')" />
</div>
<div v-if="clubList.length > 0">
    <List :values="clubList" :hoverEnabled="hoverEnabled">
        <template v-slot:header>
            <th>{{t('club_name')}}</th>
            <th>{{t('address_only')}}</th>
            <th>{{t('club_state')}}</th>
            <th>{{t('phonenumber')}}</th>
            <th></th>
        </template>

        <template v-slot:tile="slotProps">
            <td>{{ slotProps.value.name }}</td>
            <td>{{ slotProps.value.location.address }}</td>
            <td>
                <Chip class="chip" :class="returnChipClass(slotProps.value.status)"
                    :label="uppercase(t(slotProps.value.status))" />
            </td>
            <td>{{ slotProps.value.club_phone_number || '-' }}</td>
            <td>
                <div class="button-container">
                    <span class="span-button" @click.stop="goToClubDetail(slotProps.value)"><i
                            class="material-icons color-blue cursor-pointer">keyboard_arrow_right</i></span>
                </div>
            </td>

        </template>
    </List>
</div>
<div class="no-records-text" v-else>{{t('no_records')}}</div>