<div class="container flexbox-column">
    <div class="first-row flexbox-row justify-space-between">
        <div class="image-container" @click="openModal('INSERT_PHOTO_MODAL')">
            <img v-if="formValues.picture && formValues.picture.src" class="tournament-img"
                :src="formValues.picture.src" alt="">
            <img v-else-if="tournament.picture_ref" class="tournament-img"
                :src="tournament.picture_ref + '?' + new Date().getTime()" alt="">
                <img v-else-if="myClub.cover_pic_ref" class="tournament-img"
                :src="myClub.cover_pic_ref" alt="">
            <img v-else class="tournament-img" src="@/theme/imgs/default_centro.png">
            <span class="material-icons edit-img-icon">mode_edit</span>
        </div>
        <div class="button-container flexbox-row align-center color-blue margin-bottom-20">
            <div class="cursor-pointer flexbox-row align-center" @click="openModal('LOAD_SCOREBOARD')">
                <span class="button-text montserrat">{{tournament.scoreboard_ref ? 
                    uppercase(t("change_scoreboard")) : 
                    uppercase(t("load_scoreboard"))}}</span>
                <span class="material-icons">upload</span>
            </div>
            <div class="vl"></div>
            <div class="cursor-pointer flexbox-row align-center button-abort" @click="saveTournamentChanges()"
             :class="isFirstStepEmpty(formValues) || 
                    !isFirstStepValid(formErrors) ? 'button-disabled' : '' ">
                <span class="button-text montserrat">{{uppercase(t('save_modified'))}}</span>
                <span class="material-icons">save</span>
            </div>

        </div>
    </div>
    <div class="form-container margin-top-20">
        <div class="form-row">
            <div class="flex-item">
                <PadelInput :value="formValues.name" :label="t('tournament_name')" :disabled="formDisabled" :icon="'lock'" />
            </div>
            <div class="flex-item flex-center-item">
                <PadelInput :value="formValues.price + ' €'" :label="t('tournament_price')" :disabled="formDisabled"
                    :icon="'lock'" />
            </div>
            <div class="flex-item">
                <PadelInput :value="t(formValues.type)" :label="t('tournament_type')" :disabled="formDisabled" :icon="'lock'" />
            </div>
        </div>
        <div class="form-row">
            <div class="flex-item">
                <PadelInput :value="formValues.min_couples" :error="t(formErrors.min_couples)" :label="t('min_couples')"
                    @validate="validateForm(formValues, 'min_couples')" :inputType="'number'" isInteger="true"
                    @input="updateFormValue($event, 'min_couples')" />
            </div>
            <div class="flex-item flex-center-item">
                <PadelInput :value="formValues.max_couples" :error="t(formErrors.max_couples)" :label="t('max_couples')"
                    :inputType="'number'" isInteger="true" @validate="validateForm(formValues, 'max_couples')"
                    @input="updateFormValue($event, 'max_couples')" />
            </div>
        </div>
        <div class="form-row">
            <div class="flex-item">
                <!-- <PadelInput :value="mapLevel(formValues.min_level)" :label="t('min_level')" /> -->
                <div class=" margin-bottom-20">
                    <PadelDropdown class="dropdown " :label="t('min_level')" :options="LevelOptions"
                        @input="updateFormValue($event.value, 'min_level', true)"
                        :error="formErrors.min_level ? t(formErrors.min_level) : ''"
                        :default="formValues.min_level" />
                </div>
                    
            </div>
            <div class="flex-item flex-center-item">
                <!-- <PadelInput :value="mapLevel(formValues.max_level)" :label="t('max_level')" /> -->
                <div class=" margin-bottom-20">
                    <PadelDropdown class="dropdown  " :label="t('max_level')" :options="LevelOptions"
                        @input="updateFormValue($event.value, 'max_level', true)"
                        :error="formErrors.max_level ? t(formErrors.max_level) : ''"
                        :default="formValues.max_level" />
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="flex-item">
                <PadelTextarea class="textarea" :label="t('tournament_description')" :key="keyComponent"
                    @input="updateFormValue($event, 'description')" :value="formValues.description" />
            </div>
            <div class="flex-item flex-center-item">
                <PadelTextarea class="textarea" :label="t('tournament_mode')" :key="keyComponent"
                    @input="updateFormValue($event, 'mode_description')" :value="formValues.mode_description" />
            </div>
            <div class="flex-item">
                <PadelTextarea class="textarea" :label="t('tournament_prizes')" :key="keyComponent"
                    @input="updateFormValue($event, 'prizes')" :value="formValues.prizes" />
            </div>
        </div>
    </div>
</div>

<div v-if="modalToShow === 'INSERT_PHOTO_MODAL'">
    <DumbModal :title="t('insert_club_picture')" @hide="hideModal()">
        <div class="modal-content flexbox-column justify-space-between">
            <div class="top-part flexbox-column align-center">
                <p class="color-white montserrat insert_pic_text" v-html="t('load_tournament_pic_text')">
                </p>
                <div class="flexbox-row">
                    <PadelUpload :label="uppercase(t('add_picture'))" :icon="'add_a_photo'"
                        :uploadedLabel="uppercase(t('picture_added'))" @change="temporaryUploader($event)"
                        @wrong-file-uploaded="handleWrongFileUploaded($event)"></PadelUpload>
                </div>

            </div>
        </div>
        <div class="buttons-container flexbox-row justify-space-between">
            <p class="abort" @click="hideModal()">{{uppercase(t('close'))}}</p>
            <div class="flexbox-row">
                <PadelButton :label="uppercase(t('load'))" :disabled="!temporaryPic" @click="confirmUploadPhoto()" />
            </div>
        </div>
    </DumbModal>
</div>
<div v-if="modalToShow === 'LOAD_SCOREBOARD'">
    <DumbModal :title="t('loading_scoreboard')" @hide="hideModal()">
        <div class="modal-content flexbox-column justify-space-between">
            <div class="top-part flexbox-column align-center">
                <p class="color-white montserrat insert_pic_text" v-html="t('load_scoreboard_text')">
                </p>
                <div v-if="tournament.scoreboard_ref">
                    <a class="color-white montserrat preview-text" :href="tournament.scoreboard_ref" target="_blank">scoreboard</a>
                </div>
                <div v-else class="flexbox-row">
                    <PadelUpload :label="uppercase(t('load_scoreboard'))" :icon="'picture_as_pdf'"
                        :uploadedLabel="uppercase(t('load_scoreboard'))" @change="temporaryUploaderScoreboard($event)"
                        @file-too-heavy="handleFileTooHeavy($event)"
                        @wrong-file-uploaded="handleWrongFileUploaded($event)" :accept="'application/pdf'"></PadelUpload>
                </div>

            </div>
        </div>
        <div class="buttons-container flexbox-row justify-space-between">
            <p class="abort" @click="hideModal()">{{uppercase(t('close'))}}</p>
            <div class="flexbox-row button-row">
                <div v-if="tournament.scoreboard_ref" class="margin-right-10">
                    <PadelUpload :label="uppercase(t('substitute'))"
                        :uploadedLabel="uppercase(t('load_scoreboard'))" @change="temporaryUploaderScoreboard($event)"
                        @file-too-heavy="handleFileTooHeavy($event)" :border="true"
                        @wrong-file-uploaded="handleWrongFileUploaded($event)" :accept="'application/pdf'"></PadelUpload>
                </div>
                <PadelButton :label="uppercase(t('load'))" :disabled="!scoreboard" @click="confirmUploadScoreboard()" />
            </div>
        </div>
    </DumbModal>
</div>
<div v-else-if="modalToShow === 'MODAL_SUCCESS'">
    <ModalSuccess @hide="hideModal()" />
</div>