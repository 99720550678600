
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import {
  mapCognitoError,
  mapInputError,
  uppercase,
} from "@/services/mapping.service";
import {
  findAccountSchemaValidation,
  newPasswordFormSchemaValidation,
  isNewPasswordFormValid,
  isNewPasswordFormEmpty,
} from "@/services/form-validation.service";
import { ActionTypes } from "@/store/action-types";
import { useStore } from "@/store/store";

export default defineComponent({
  name: "ChangePasswordModal",
  props: ["precompileEmail"],
  emits: ["hide"],
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const findAccountValue = ref("");
    const findAccountError = ref("");
    const findAccountSchema = findAccountSchemaValidation;
    const newPasswordFormSchema = newPasswordFormSchemaValidation;
    const store = useStore();
    const openedModal = ref(null);

    function updateFindAccountValue(value: string) {
      findAccountValue.value = value;
    }

    function updateFindAccountError(value: string) {
      findAccountError.value = value;
    }

    function validateFindAccountValue() {
      findAccountSchema
        .validateAt("email", { email: findAccountValue.value })
        .then(() => {
          updateFindAccountError("");
        })
        .catch((err) => {
          updateFindAccountError(mapInputError(err.message, t));
        });
    }

    onMounted(() => {
      console.log("prop..", props.precompileEmail);
      if (props.precompileEmail) {
        findAccountValue.value = props.precompileEmail;
        validateFindAccountValue();
      }
    });

    const newPasswordFormValues = ref({
      code: "",
      password: "",
      confirmPassword: "",
    });
    const newPasswordFormErrors = ref({
      code: "",
      password: "",
      confirmPassword: "",
    });

    function openModal(modal: string) {
      openedModal.value = modal;
    }

    onMounted(() => {
      openModal("FIND_ACCOUNT");
    });

    function hideModal() {
      emit("hide", true);
    }

    function updateNewPasswordFormValue(value: string, field: string) {
      newPasswordFormValues.value[field] = value;
    }

    function updateNewPasswordFormError(value: string, field: string) {
      newPasswordFormErrors.value[field] = value;
    }

    function validateNewPasswordForm(values: any, field: string) {
      newPasswordFormSchema
        .validateAt(field, values)
        .then(() => {
          updateNewPasswordFormError("", field);
        })
        .catch((err) => {
          updateNewPasswordFormError(mapInputError(err.message, t), field);
        });
      if (field === "password" && values.confirmPassword) {
        validateNewPasswordForm(values, "confirmPassword");
      }
    }

    async function findAccount() {
      updateFindAccountError("");
      try {
        await store.dispatch(
          ActionTypes.FORGOT_PASSWORD,
          findAccountValue.value
        );
        openModal("SEND_NEW_PASSWORD");
      } catch (error) {
        findAccountError.value = mapCognitoError(error.code, t);
      }
    }

    async function changePassword() {
      try {
        await store.dispatch(ActionTypes.FORGOT_PASSWORD_SUBMIT, {
          username: findAccountValue.value,
          code: newPasswordFormValues.value["code"],
          new_password: newPasswordFormValues.value["password"],
        });
        hideModal();
      } catch (error) {
        updateNewPasswordFormError(mapCognitoError(error.code, t), "password");
      }
    }

    return {
      t,
      locale,
      findAccountValue,
      findAccountError,
      openedModal,

      uppercase,
      hideModal,
      updateFindAccountValue,
      validateFindAccountValue,
      updateNewPasswordFormValue,
      newPasswordFormValues,
      newPasswordFormErrors,
      validateNewPasswordForm,
      changePassword,
      findAccount,
      isNewPasswordFormValid,
      isNewPasswordFormEmpty,
    };
  },
});
