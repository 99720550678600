<div class="width-100">
    <div class="calendar-row margin-bottom-20 flexbox-row align-center">
        <div class="calendars-container">
            <PadelCalendar :label="t('start_date')" @input="updateFormValue($event, 'start_at', true)"
                :minDate="minDate" :value="formValues.start_at"
                :disabled="isEdit || isDateFixed(formValues.start_at)" :error="formErrors.start_at && 
                !isDateFixed(formValues.start_at)? t(formErrors.start_at) : ''" />
            <PadelCalendar :label="t('end_date')" :value="formValues.end_at"
                :disabled="isEdit || isDateFixed(formValues.end_at)"
                @input="updateFormValue($event, 'end_at', true)" :minDate="minDate" :error="formErrors.end_at && 
                !isDateFixed(formValues.end_at)? t(formErrors.end_at) : ''" />
            <PadelCalendar :label="t('registration_deadline')" :minDate="registrationMinDate"
                :disabled="isEdit || isDateFixed(formValues.start_at)"
                :value="formValues.registration_end_at" @input="updateFormValue($event, 'registration_end_at', true)"
                :error="formErrors.registration_end_at && 
                !isDateFixed(formValues.registration_end_at) ? t(formErrors.registration_end_at) : ''" />
        </div>
        <i class="margin-left-20 change-calendar-icon cursor-pointer material-icons color-white margin-right-10
        color-blue" @click="handleCalendarButtonClick()"
            :class="isSecondStepEmpty(formValues) || 
                    !isSecondStepValid(formErrors) || addDayFormEnabled ? 'button-disabled' : 'not-disabled' ">{{isEdit ? 'edit' : 'save'}}</i>
    </div>
    <div class="gold-separator"></div>
    <div class="slots-container margin-top-20 flexbox-column align-center">
        <div class="top-slots flexbox-column align-center width-100">
            <template v-for="(slotForm, index) in formValues.dates">
                <div class="slot-row-container flexbox-row width-100 align-center">
                    <div class="slot-row flexbox-row">
                        <div class="row-item">
                            <PadelCalendar :label="t('select_day')" :minDate="minDate" :value="slotForm.date"
                                :disabled="slotDisabled" />
                        </div>
                        <div class="row-item flexbox-row justify-center timepickers">
                            <div class="timepicker-container margin-right-20">
                                <PadelTimepicker :label="t('from')" :from="openTime" :to="closeTime"
                                    :value="slotForm.start_at" :disabled="slotDisabled" />
                            </div>
                            <div class="timepicker-container">
                                <PadelTimepicker :label="t('to')" :from="openTime" :to="closeTime"
                                    :value="slotForm.end_at" :disabled="slotDisabled" />
                            </div>
                        </div>
                        <div class="row-item flexbox-row align-center playground-container">
                            <PadelDropdown :options="playgroundOptions" :label="t('select_playground')"
                                :default="slotForm.playground" :disabled="slotDisabled" />

                        </div>
                    </div>
                    <span class="icon-delete material-icons color-blue cursor-pointer margin-left-20 margin-right-10
                    padding-top-20" @click="deleteSlot(slotForm)">delete</span>
                </div>
            </template>
            <div v-if="addDayFormEnabled" class="new-slot-row flexbox-row width-100">
                <div class="slot-row flexbox-row">
                    <div class="row-item">
                        <PadelCalendar :label="t('select_day')" :minDate="formValues.start_at"
                            :maxDate="formValues.end_at" :value="newSlotForm.date"
                            @input="updateSlotFormValue($event, 'date')" />
                    </div>
                    <div class="row-item flexbox-row justify-center timepickers">
                        <div class="timepicker-container margin-right-20">
                            <PadelTimepicker :label="t('from')" :from="openTime" :to="closeTime"
                                :error="t(newSlotFormErrors.start_at)"
                                @input="updateSlotFormValue($event, 'start_at', true)" :value="newSlotForm.start_at" />
                        </div>
                        <div class="timepicker-container">
                            <PadelTimepicker :label="t('to')" :from="openTime" :to="closeTime"
                                :error="t(newSlotFormErrors.end_at)"
                                @input="updateSlotFormValue($event, 'end_at', true)" :value="newSlotForm.end_at" />
                        </div>
                    </div>
                    <div class="row-item flexbox-row align-center playground-container">
                        <PadelDropdown :options="playgroundOptions" :label="t('select_playground')"
                            :default="newSlotForm.playground"
                            @input="updateSlotFormValue($event.value, 'playground')" />
                    </div>
                </div>
                <span class="icon-save-slot material-icons color-blue cursor-pointer margin-left-20 margin-right-10"
                    :class="{'disabled': isSlotEmpty(newSlotForm) || !isSlotValid(newSlotFormErrors)}"
                    @click="saveNewSlot()">save</span>
            </div>
            <div class="flexbox-row align-center" @click="addSlot()" :class="{'disabled': addDayDisabled()}">
                <i class="pi pi-plus color-blue margin-right-10" :class="{'disabled': addDayDisabled()}"></i>
                <p class="abort" :class="{'disabled': addDayDisabled()}">
                    {{uppercase(t('add_day'))}}</p>
            </div>
        </div>
    </div>


    <div v-if="modalToShow === 'MODAL_SUCCESS'">
        <ModalSuccess @hide="hideModal()" />
    </div>

    <!-- MODALE CONFLITTO TROVATO -->
    <div v-else-if="modalToShow === 'CONFLICT_DETECTED'">
        <DumbModal :title="t('tournament_modify')" @hide="hideModal()">
            <div class="modal-content flexbox-column justify-space-between">
                <div class="top-part">
                    <i class="material-icons icon-alert">warning_amber</i>
                    <div class="color-white alert-text montserrat">{{t('attention')}}</div>
                    <div class="color-white modal-text montserrat" v-html="t('tournament_conflict_slot_text', {date: formatDateDDMMYYYY(firstConflict.date), 
                            playground_name: playgroundMap[firstConflict.playground_id]})">
                    </div>
                </div>
                <div class="buttons-container flexbox-column align-center">
                    <p class="abort" @click="hideModal()">{{uppercase(t('go_back'))}}</p>
                </div>
            </div>
        </DumbModal>
    </div>

</div>