
import { defineComponent } from "vue";
export default defineComponent({
  name: "PadelToast",
  methods: {
    emitClose() {
      this.$emit("close-clicked", true);
    },
  },
  props: {
    message: { type: String, required: false, default: "Error" },
    icon: { type: String, required: false, default: "warning" },
    type: { type: String, required: false, default: "error" },
  },
  emits: ["close-clicked"],
});
