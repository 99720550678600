<PadelLateralTab />

<div class="view-main-container">  
  <div class="inner-view-container">

    <div class="nav-container flexbox-row justify-space-between padding-bottom-20 padding-top-20">
      <p class="title color-white">Pagamenti</p>

      
      <div class="scheduler-button export-button cursor-pointer display-flex" @click="openModalDownload()">
          <p class="scheduler-button-text color-blue" >
              {{uppercase(t('export'))}}
          </p>
          <i class="material-icons color-blue">file_download</i>
        </div>
    </div>
      
    <TabView class="tab-view p-mb-5" :activeIndex="selectedTab" @tab-change="onChangeTab($event.index)">
      <TabPanel>
        <template #header>
          <div class="p-text-left">
            <span>PAGAMENTI</span>
          </div>
        </template>

        <div class="table-main-container">
          <div class="payments-container">
            <div class="p-d-flex p-flex-column p-flex-sm-row p-jc-between sub-header margin-top-20">
              <div class="display-flex calendar-selector">
                  <PadelButton type="dark" :label="uppercase(t('today'))" class="margin-right-20" @click="goToToday()" />
                  <i class="pi pi-chevron-left color-blue cursor-pointer" @click="goToYesterday()"></i>
                  <i class="pi pi-chevron-right color-blue cursor-pointer" @click="goToTomorrow()"></i>
                  <div class="selected-date cursor-pointer" id="toggle-calendar-button" @click="toggleCalendar()">
                      {{selectedDate ? formatDate() : ''}}
                  </div>
                  <div :class="showCalendar ? '': 'display-none'" class="calendar-container-scheduler">
                      <PadelCalendar :showLabel="false" @input="changeDateWithCalendar($event)" class="calendaaar"
                          id="calendar-scheduler-box" :value="selectedDate" />
                  </div>
              </div> 
            </div>
          </div>
         
          <List 
            :values="transactions" 
            :hoverEnabled="true" 
            emptyState="Nessuna pagamento presente"
            @row-clicked="onClickEvent"
            >
            <template class="header" v-slot:header>
                <th>Data/Ora pagamento</th>
                <th>ID transazione Nexi</th>
                <th>{{t('price')}}</th>

                <th>Data prenotazione</th>
                <th>{{t('start_hour')}}</th>
                <th>{{t('game_duration')}}</th>
                <th>Nome Cognome</th>
                <th>{{t('playground')}}</th>
                <th>Stato prenotazione</th>
            </template>
            <template v-if="transactions.length > 0" v-slot:tile="transaction">
              <td>
                <div class="td-content">
                  {{ moment(transaction.value.paid_at).format("DD MMMM YYYY - HH:mm")  }}
                </div>
              </td>
              
              <td>
                <div class="td-content">
                  <span v-if="transaction.value.booking.booking_state != 'CANCELLED'">
                    {{ transaction.value.booking.nexi_transaction_id != undefined && transaction.value.booking.nexi_transaction_id != null ? transaction.value.booking.nexi_transaction_id : 'In attesa di contabilizzazione'}}
                  </span>
                  <span v-else>
                    {{ transaction.value.booking.nexi_transaction_id != undefined && transaction.value.booking.nexi_transaction_id != null ? transaction.value.booking.nexi_transaction_id : '-'}}
                  </span>
                </div>
              </td>

                <td>
                <div class="td-content">
                  {{ (transaction.value.price / 100).toFixed(2) }} €
                </div>
              </td>

              <!-- Prenotazione -->
              <td>
                <div class="td-content">
                  {{ moment(transaction.value.booking.date, "M/D/YYYY").format("DD MMMM YYYY") }}
                </div>
              </td>
              <td>
                <div class="td-content">
                  {{ moment(transaction.value.booking.start_at).format('HH:mm') }}
                </div>
              </td>
              <td>
                <div class="td-content">
                  {{ t('minutes', { time: rowFormatGameDuration(transaction.value) }) }}
                </div>
              </td>
              <td>
                <div class="td-content">
                  {{ transaction.value.user.name }} {{ transaction.value.user.family_name }}
                </div>
              </td>
              <td>
                <div class="td-content">
                  {{ transaction.value.booking.playground_name }}
                </div>
              </td>
              
              <td>
                <div class="td-content" >
                  {{transaction.value.booking.booking_state == 'CONFIRMED' ? 'Confermata' : 'Cancellata'}}
                </div>
              </td>
            
              
              <!-- CTA Storno -->
              <td  v-if="transaction.value.booking.booking_state == 'CANCELLED'">
                <p class="refund" @click.stop.prevent="showRefund(transaction.value)" v-if="transaction.value.booking.nexi_transaction_id != undefined && transaction.value.booking.nexi_transaction_id != null">
                  RIMBORSO
                </p>
              </td>
              <td v-else></td>
            </template>
          </List>
        </div>
      
      </TabPanel>

      <TabPanel>
        <template #header>
          <span>RIMBORSI</span>
        </template>

        <div class="table-main-container">
          <div class="payments-container">
            <div class="p-d-flex p-flex-column p-flex-sm-row p-jc-between sub-header margin-top-20">
              <div class="display-flex calendar-selector">
                  <PadelButton type="dark" :label="uppercase(t('today'))" class="margin-right-20" @click="goToToday()" />
                  <i class="pi pi-chevron-left color-blue cursor-pointer" @click="goToYesterday()"></i>
                  <i class="pi pi-chevron-right color-blue cursor-pointer" @click="goToTomorrow()"></i>
                  <div class="selected-date cursor-pointer" id="toggle-calendar-button" @click="toggleCalendar()">
                      {{selectedDate ? formatDate() : ''}}
                  </div>
                  <div :class="showCalendar ? '': 'display-none'" class="calendar-container-scheduler">
                      <PadelCalendar :showLabel="false" @input="changeDateWithCalendar($event)" class="calendaaar"
                          id="calendar-scheduler-box" :value="selectedDate" />
                  </div>
              </div> 
            </div>
          </div>
          <List 
            :values="transactions" 
            :hoverEnabled="true" 
            emptyState="Nessuna rimborso presente"
            
            >
            <template class="header" v-slot:header>
                <th>Data/Ora storno</th>
                <th>ID transazione Nexi</th>
                <th>{{t('price')}}</th>
                <th>Rimborso</th>

                <th>Data prenotazione</th>
                <th>{{t('start_hour')}}</th>
                <th>{{t('game_duration')}}</th>
                <th>Nome Cognome</th>
                <th>{{t('playground')}}</th>
                <th>Stato prenotazione</th>
            </template>

            <template v-if="transactions.length > 0" v-slot:tile="transaction" >
                <td>
                  <div class="td-content">
                    {{ moment(transaction.value.refunded_at).format("DD MMMM YYYY - HH:mm") }}
                  </div>
                </td>  
                <td>
                  <div class="td-content">
                    <span v-if="transaction.value.booking.booking_state != 'CANCELLED'">
                      {{ transaction.value.booking.nexi_transaction_id != undefined && transaction.value.booking.nexi_transaction_id != null ? transaction.value.booking.nexi_transaction_id : 'In attesa di contabilizzazione'}}
                    </span>
                    <span v-else>
                      {{ transaction.value.booking.nexi_transaction_id != undefined && transaction.value.booking.nexi_transaction_id != null ? transaction.value.booking.nexi_transaction_id : '-'}}
                    </span>
                  </div>
                </td>
                <td>
                  <div class="td-content">
                    {{ (transaction.value.price / 100).toFixed(2) }} €
                  </div>
                </td>
                <td>
                  <div class="td-content">
                    {{ (transaction.value.refund_price / 100).toFixed(2) }} €
                  </div>
                </td>

                <!-- Prenotazione -->
                <td>
                  <div class="td-content">
                    {{ moment(transaction.value.booking.date, "M/D/YYYY").format("DD MMMM YYYY") }}
                  </div>
                </td>
                <td>
                  <div class="td-content">
                    {{ moment(transaction.value.booking.start_at).format('HH:mm') }}
                  </div>
                </td>
                
                <td>
                  <div class="td-content">
                    {{ t('minutes', { time: rowFormatGameDuration(transaction.value) }) }}
                  </div>
                </td>
                <td>
                  <div class="td-content">
                    {{ transaction.value.user.name }} {{ transaction.value.user.family_name }}
                  </div>
                </td>
                <td>
                  <div class="td-content">
                    {{ transaction.value.booking.playground_name }}
                  </div>
                </td>
                
                <td>
                  <div class="td-content">
                    {{transaction.value.booking.booking_state == 'CONFIRMED' ? 'Confermata' : 'Cancellata'}}
                  </div>
                </td>
              </template>
          </List>
        </div>
      </TabPanel>

      <!-- Impostazioni -->
      <TabPanel>
        <template #header>
          <span>IMPOSTAZIONI</span>
        </template>
        <div class="payments-settings-container">
                    
          <div class="header margin-top-20 margin-bottom-20 p-text-left ">
            
            <span class="header-title">PAGAMENTI ONLINE</span>
            
            <div class="p-d-inline-flex p-mr-4">
              <PadelToggle                                   
                containerClass="p-d-inline-flex mr-2"
                labelClass="color-white overflow-inherit p-size-mini p-mr-4"
                label="Abilitazione pagamenti online"
                v-model:checked="paymentEnabled"
                @change="handleToggleEnableNexiPayment($event)" />
              <span class="header-title">&nbsp;</span>
            </div>

            <div class="p-d-inline-flex p-ml-4">
              <PadelToggle                                   
                containerClass="p-d-inline-flex mr-2 ml-4"
                labelClass="color-white overflow-inherit p-size-mini p-mr-4"
                label="Accetta solo pagamenti online"
                v-model:checked="forceOnlinePayment"
                @change="handleToggleForceNexiPayment($event)"
                v-if="paymentEnabled" />
                
              
                </div>

            </div>

          <div class="margin-top-40 margin-bottom-20 ">
            <div class="nexi-subscription-detail-container margin-top-10 margin-bottom-20">
              <div class="margin-bottom-20">
                Permetti ai tuoi clienti di pagare in anticipo online. WeSmash permette di ricevere i pagamenti tramite circuito Nexi.
              </div>

              <div>
                <div class="margin-top-40">
                  <strong>Come funziona:</strong>
                </div>

                <div class="margin-top-20">
                  <strong>1. Vai su Nexi.</strong> ​​L’attivazione e la firma (elettronica) del contratto avviene online sul sito Nexi. 
                  <div class="margin-top-10">
                    <div class="p-text-left p-d-flex" >
                      <!-- @click="toggleForm()"-->
                      <div class="button-container primary p-mt-2">
                        <a class="button primary" target="_blank" href="https://ecommerce.nexi.it/guida-primafase?p=2">
                          <span class="label primary">ATTIVA CONTRATTO NEXI</span>
                        </a>
                      </div>
                      
                    </div>
                  </div>
                </div>

                <div class="margin-top-40">
                  <div>
                    <strong>2. Usa il codice promozionale.</strong> Utilizza il codice promozionale WeSmash in fase di attivazione del servizio.
                  </div>
                  <div class="margin-top-10" style="font-size: 20px !important">
                    <strong>WESMASH</strong>
                  </div>
                </div>

                <div class="margin-top-40">
                  <div>
                    <strong>3. Inserisci MAC e Alias.</strong>  Dopo circa 8 giorni dall’attivazione del contratto Nexi riceverai i codici Alias e MAC di Nexi, e saremo in grado di indirizzare i pagamenti in modo corretto al tuo centro.
                  </div>

                  
                  <div class="p-grid margin-top-10" style="font-size: 20px !important">
                    <div class="p-col-12 p-md-3 p-text-left">
                      <PadelInput label="Alias" :inputType="'string'" containerClass="full-width" :value="alias" @input="updateFormValue($event, 'alias')"/>
                    </div>
                    
                    <div class="p-col-12 p-md-3 p-text-left">
                      <PadelInput label="MAC" :inputType="'string'"  containerClass="full-width" :value="mac"  @input="updateFormValue($event, 'mac')"/>
                    </div>
                  </div>
                  
                  <div class="p-text-left p-d-flex" >
                    <PadelButton class="p-mt-2" label="CONFERMA" @click="saveNexiPaymentSettings()" ></PadelButton>
                  </div>
                </div>
              </div>
            </div>

              
            <div class="nexi-subscription-detail-container margin-top-20">
              <div>
                <strong class="margin-top-40">
                  Costi del servizio con il codice promozionale WeSmash (6f89gs4hnd9):<br /><br />
                </strong>
                <ul>
                  <li>
                    Attivazione: 7,50€ una tantum
                  </li>
                  <li>
                    Canone mensile: 2,50€
                  </li>
                  <li>
                    0,95% valore transazione
                  </li>
                  <li>
                    0,10€ per transazione
                  </li>
                  <li>
                    0,10€ per storno transazione
                  </li>
                </ul>
              </div>

              <div class="margin-top-40">
                Sei già cliente Nexi? Richiedi una consulenza al tuo referente Nexi.
              </div>
            </div>
          </div> 
        </div>
      </TabPanel>
    </TabView>
  </div>

  <div v-if="showRefundModal">
    <DumbModal title="Rimborso" @hide="hideModal()">
      <div class="modal-content">
        <p class="color-white montserrat"><strong>Totale pagato: {{ (selectedTransaction.price  / 100).toFixed(2) }} €.</strong><br /><br />Indica l'importo che vuoi rimborsare all'utente:</p>

        <div class="p-d-flex p-jc-center">
          <PadelRadio :value="refundPercentage" :options="refundOptions"
                          :chipMode="true" @input="setRefundValue($event)" style="margin-top: 10px"/>
        </div>
      </div>

      <template #footer>
        <div class="p-d-flex p-jc-end">
          <PadelButton :label="uppercase('rimborsa')" :type="'primary'" @click="refund()"></PadelButton>
        </div>
      </template>
      
    </DumbModal>
  </div>

  <div v-if="showExportModal">
    <DumbModal title="Esporta pagamenti" @hide="hideModal()">
      <div class="top-part flexbox-column align-center">
        <div class="color-white alert-text montserrat margin-bottom-20">
          Selezionare il periodo di cui si desidera scaricare il dettaglio pagamenti
        </div>
        <div class="form-excel-container flexbox-row">
            <div class="toggle-container flexbox-row align-center flex-100">
              <div class="download-text color-white montserrat margin-right-10">
                  {{t('select_daily_excel')}}</div>
              <PadelToggle class="toggle" v-model:checked="downloadCalendarForm.daily_download"
                  @change="handleToggleDailyDownload($event)" />
            </div>
            <div class="calendar-excel-container flex-100">
              <PadelCalendar @input="updateFormDownloadCalendar($event, 'start_date')"
                  :label="t('select_month')" :value="downloadCalendarForm.start_date" :viewType="'month'"
                  :disabled="downloadCalendarForm.daily_download" />
            </div>
        </div>
      </div>
      <div class="buttons-container flexbox-row justify-space-between">
        <p class="abort center-button" @click="hideModal()">{{uppercase(t('abort'))}}</p>
        <PadelButton :label="uppercase(t('download'))" :type="'primary'" @click="downloadExcel()"></PadelButton>
      </div>
    </DumbModal>
  </div>
</div>