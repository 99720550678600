export const WeSmashBookingColorChoices = [
    { label: 'Azzurro', color: '#38A8FC', value: 'custom-wesmash-blue' },
    { label: 'Rosso', color: '#E64040', value: 'custom-red' },
    { label: 'Blu', color: '#0573CE', value: 'custom-blue' },
    { label: 'Rosa', color: '#C490D1', value: 'custom-pink' },
    { label: 'Oro', color: '#C8B67F', value: 'custom-gold' },
    { label: 'Verde acqua', color: '#1cb5ac', value: 'custom-water-green' },
    { label: 'Fucsia', color: '#f00146', value: 'custom-fucsia' },
    { label: 'Bianco', color: '#ffffff', value: 'custom-white' },
]

export const BookingColorChoices = [
    { label: 'Verde', color: '#75E261', value: 'custom-green' },
    { label: 'Rosso', color: '#E64040', value: 'custom-red' },
    { label: 'Blu', color: '#0573CE', value: 'custom-blue' },
    { label: 'Rosa', color: '#C490D1', value: 'custom-pink' },
    { label: 'Oro', color: '#C8B67F', value: 'custom-gold' },
    { label: 'Verde acqua', color: '#1cb5ac', value: 'custom-water-green' },
    { label: 'Fucsia', color: '#f00146', value: 'custom-fucsia' },
    { label: 'Bianco', color: '#ffffff', value: 'custom-white' },
]

export const LessonColorChoices = [
    { label: 'Verde acqua', color: '#53B2AB', value: 'custom-water-green' },
    { label: 'Rosso', color: '#E64040', value: 'custom-red' },
    { label: 'Blu', color: '#0573CE', value: 'custom-blue' },
    { label: 'Rosa', color: '#C490D1', value: 'custom-pink' },
    { label: 'Oro', color: '#C8B67F', value: 'custom-gold' },
    { label: 'Fucsia', color: '#f00146', value: 'custom-fucsia' },
    { label: 'Bianco', color: '#ffffff', value: 'custom-white' },
    { label: 'Arancione', color: '#FAA916', value: 'custom-orange' },
]

export const MantainanceColorChoices = [
    { label: 'Grigio', color: '#A7A7A7', value: 'custom-gray' },
    { label: 'Rosso', color: '#E64040', value: 'custom-red' },
    { label: 'Blu', color: '#0573CE', value: 'custom-blue' },
    { label: 'Rosa', color: '#C490D1', value: 'custom-pink' },
    { label: 'Oro', color: '#C8B67F', value: 'custom-gold' }
]

export const ClosureColorChoices = [
    { label: 'Giallo', color: '#FDF266', value: 'custom-yellow' },
    { label: 'Rosso', color: '#E64040', value: 'custom-red' },
    { label: 'Blu', color: '#0573CE', value: 'custom-blue' },
    { label: 'Rosa', color: '#C490D1', value: 'custom-pink' },
    { label: 'Oro', color: '#C8B67F', value: 'custom-gold' }
]

export const OtherColorChoices = [
    { label: 'Bianco', color: '#FFFFFF', value: 'custom-white' },
    { label: 'Rosso', color: '#E64040', value: 'custom-red' },
    { label: 'Blu', color: '#0573CE', value: 'custom-blue' },
    { label: 'Rosa', color: '#C490D1', value: 'custom-pink' },
    { label: 'Oro', color: '#C8B67F', value: 'custom-gold' }
]

