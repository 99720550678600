
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import { 
    computed, 
    defineComponent, 
    onMounted, 
    onUnmounted,
    ref,
    nextTick,
    watch,
} from "vue";
import {
    uppercase,
} from "@/services/mapping.service";
import {
    emptyCreateCardForm,
    emptyCreateCardFormErrors,
} from "@/constants/initial-values.constants";
import {
    isFormCardEmpty,
    isFormCardValid,
} from "@/services/form-validation.service";
import {
    CardTypeOptions,
} from "@/constants/constants.constants";
import { PadelClub } from "@/models/club.models";
import { PadelUser } from "@/models/user.models";
import { ActionTypes } from "@/store/action-types";
import { CardTypes } from "@/constants/card-types.enum";
import moment from "moment";


export default defineComponent({
    name: "CreateCard",
    components: {
    },
    setup() {
        const route     = useRoute()
        const store     = useStore();
        const router    = useRouter();
        const { t, locale } = useI18n();
        const card      = ref(null);
        const form      = ref(
            JSON.parse( JSON.stringify(emptyCreateCardForm) )
        );
        const formErrors= ref(
            JSON.parse( JSON.stringify(emptyCreateCardFormErrors) )
        );
        const editMode  = ref(false);
        const renewId   = ref(route.query.renew_id);

        /* COMPUTED */
        const myClub = computed(function(): PadelClub {
            return store.getters.getMyClub;
        });
        const user = computed(function(): PadelUser {
            return store.getters.getSearchUser;
        });
        const userCards = computed(function() {
            return store.getters.getUserCards
        });
        const oldCard = computed(function() {
            if (!renewId.value) return null;
            return userCards.value.find(el => el.id === renewId.value);
        });
        const allowedCards = computed(function() {
            if (renewId.value) {
                const types = userCards.value.filter(el => el.id === renewId.value).map(el => el.type);
                return CardTypeOptions.filter(el => types.includes(el.value));
            }
            if (route.params.card_id) {
                const types = userCards.value.filter(el => el.id === route.params.card_id).map(el => el.type);
                return CardTypeOptions.filter(el => types.includes(el.value));
            }
            const types = userCards.value.map(el => el.type);
            return CardTypeOptions.filter(el => !types.includes(el.value));
        });

        // DATA

        // LIFECYCLE
        onMounted(() => {
            nextTick(() => {
                if (route.params.card_id) {
                    editMode.value = true;
                    getCard();
                } else {
                    form.value.user_id    = user.value.id;
                    form.value.club_id    = myClub.value.club_id;
                    form.value.club_name  = myClub.value.name;

                    // CHECK
                    if (renewId.value) {
                        setFormValue('type', oldCard.value.type);
                        setFormValue('card_code', oldCard.value.card_code);
                    }
                }
            });
        });

        onUnmounted(() => {
        });
        
        // FUNCTIONS
        function getCard() {
            store.dispatch(ActionTypes.GET_CARD, { card_id: route.params.card_id })
                .then((response) => {
                    const data = response.data;
                    delete data.certification;
                    card.value = data;
                    form.value = {
                        ...JSON.parse( JSON.stringify(card.value) )
                    };
                    card.value.expiration_date = new Date(card.value.expiration_date);
                    form.value.expiration_date = new Date(form.value.expiration_date);
                });
        }

        function validate(key: string, value: any) {
            switch (key) {
                case 'card_code':
                    if (value === null || value === '') {
                        setFormError('card_code', t('required_field'));
                    }
                    break;
                case 'type':
                    const values = CardTypeOptions.map(el => el.value);
                    if (!values.includes(value)) {
                        setFormError('type', t('required_field'));
                    }
                    break;
                case 'expiration_date':
                    const date = moment(value);
                    if (!date.isValid()) {
                        setFormError('expiration_date', t('must_be_valid_date'));
                    }
                    break;
            }
        }

        function setFormValue(key: string, value: any) {
            form.value[key] = value;
            validate(key, value);
        }

        function setFormError(key: string, value) {
            formErrors.value[key] = value;
        }

        function createCardWithBody(body) {
            store.dispatch(
                ActionTypes.CREATE_CARD,
                body,
            ).then(() => {
                router.replace({ name: 'user-details', params: { id: user.value.id },  query: { tab: 'cards' } });
            });
        }

        function save() {
            // INIT
            const body = JSON.parse(JSON.stringify(form.value));

            // DISPATCH
            if (card.value) {
                store.dispatch(
                    ActionTypes.UPDATE_CARD,
                    body,
                ).then(() => {
                    router.replace({ name: 'user-details', params: { id: user.value.id },  query: { tab: 'cards' } });
                });
            } else {
                if (renewId.value) {
                    store.dispatch(
                        ActionTypes.DELETE_CARD, 
                        { id: renewId.value }
                    )
                    .then(() => {
                        createCardWithBody(body);
                    });
                } else {
                    createCardWithBody(body);
                }
            }
        }

        /** WATCH */
        watch(() => form.value, function() {
            if (form.value.type === CardTypes.FIT) {
                if (form.value.rank) {
                    setFormError('rank', '');
                } else {
                    setFormError('rank', t('required_field'))
                }
            }
        }, { deep: true })


        // RETURN
        return {
            t,
            locale,
            router,
            uppercase,
            moment,

            // DATA
            user,
            myClub,
            card,
            allowedCards,
            form,
            formErrors,
            editMode,
            renewId,

            // METHODS

            // VALIDATION
            isFormCardEmpty,
            isFormCardValid,

            // FORM
            setFormValue,
            save,
        };
    },
});
