import { BookingType } from '@/constants/booking.enum';
import { WEEKDAYS_SLOTS } from "@/constants/constants.constants";
import moment from 'moment';
import 'moment-timezone';

export const MONTHS = [
  { value: 1, label: 'months.January' },
  { value: 2, label: 'months.February' },
  { value: 3, label: 'months.March' },
  { value: 4, label: 'months.April' },
  { value: 5, label: 'months.May' },
  { value: 6, label: 'months.June' },
  { value: 7, label: 'months.July' },
  { value: 8, label: 'months.August' },
  { value: 9, label: 'months.September' },
  { value: 10, label: 'months.October' },
  { value: 11, label: 'months.November' },
  { value: 12, label: 'months.December' },
];

export function convertMMDDToDDMM(date: string) {
  return moment(date, 'MM/DD/YYYY').format('DD/MM/YYYY');
}

export function formatDateDDMMYYYY(date: string | Date) {
  return moment(date).format('DD/MM/YYYY');
}

export function formatStatsDate(date: string) {
  return moment(date, 'YYYY/MM/DD').format('DD/MM/YYYY');
}

export function formatDateYYYYMMDD(date: string | Date) {
  return moment(date).format('YYYY/MM/DD');
}

export function formatDateYYYYMMDDForCalendar(date: string | Date) {
  return moment(date).format('YYYY-MM-DD');
}

export function formatDateMMDDYYYY(date: string | Date) {
  return moment(date).format('MM/DD/YYYY');
}

export function formatDateMMYYYY(date: string | Date) {
  return moment(date).format('MM/YYYY');
}

export function formatISOString(date: string | Date) {
  return moment(date).toISOString();
}

export function transformStringToDate(date: string, format: string): Date {
  return moment(date, format).toDate();
}

export function transformMillisecondsToHHMM(mill: number) {
  return moment(new Date(mill)).format('HH:mm');
}

export function isSameOrBefore(startTime, endTime) {
  return moment(startTime, "HH:mm").isSameOrBefore(
    moment(endTime, "HH:mm")
  );
}

export function isSameOrAfter(startTime, endTime) {
  return moment(startTime, "HH:mm").isSameOrAfter(
    moment(endTime, "HH:mm")
  );
}

export function isBefore(startTime, endTime) {
  return moment(startTime, "HH:mm").isBefore(
    moment(endTime, "HH:mm")
  );
}

export function transformDate(date: Date, time: string) {
  const a = time.split(':');
  return date.setHours(a[0] as unknown as number, a[1] as unknown as number, 0, 0);
}

export function isDateBefore(startDate, endDate) {
  return moment(startDate).isBefore(
    moment(endDate)
  );
}

export function isDateAfter(startDate, endDate) {
  return moment(startDate).isAfter(
    moment(endDate)
  );
}

export function isSameDate(startDate, endDate) {
  return formatDateMMDDYYYY(startDate) === formatDateMMDDYYYY(endDate);
}

export function isToday(date) {
  return moment(date).isSame(new Date(), "day");
}

export function isTimeBeforeNow(time, date = null) {
  if (date) {
    return isSameOrBefore(time, moment(date).format("HH:mm"))
  }
  return isSameOrBefore(time, moment(new Date()).format("HH:mm"))
}

export function isTimeBeforeStart(time, start) {
  return isSameOrBefore(time, start)
}

export function isTimeAfterEnd(time, end) {
  return isSameOrAfter(time, end)
}

export function isTimeStrictlyBeforeNow(time) {
  return isBefore(time, moment(new Date()).format("HH:mm"))
}

export function doubleZeros(value: number) {
  const valueToString = value.toString()
  if (valueToString.length > 1) {
    return valueToString;
  }
  else {
    return "0" + value.toString();
  }
}


export function convertHHMMtoMinutesOfDay(hour: string) {
  if (hour === "24:00") {
    return 1440;
  } else {
    const mmt = moment(hour, "HH:mm");
    return (mmt.clone().hours() * 60) + mmt.clone().minutes();
    //const mmtMidnight = mmt.clone().startOf('day');
    //const diffMinutes = mmt.diff(mmtMidnight, 'minutes');
    //return diffMinutes;
  }
}

export function convertHHMMtoMillisecondsOfDay(hour: string) {
  return convertHHMMtoMinutesOfDay(hour) * 60 * 1000;
}

export function convertMillisecondsOfDaytoHHMM(mill: number) {
  if (Number.isNaN(mill)) return null;
  if (mill >= 1440 * 60 * 1000) {
    return "24:00";
  }
  const seconds = mill / 1000;
  const m = (seconds / 60) % 60;
  const h = (seconds / (60 * 60)) % 24;
  return `${doubleZeros(Math.floor(h))}:${doubleZeros(m)}`;
}

export function mapTimestamp(timestampDay: number, timestampHour: number) {
  return (new Date((new Date(timestampDay).setHours(0, 0, 0, 0))).setMilliseconds(timestampHour))
}

export function convertTimestampToCalendarDateFormat(date) {
  //MI ASSICURO DI TRASFORMARE LE DATE CON L'ORARIO DI ROMA
  const d = new Date(date);
  //console.log(d, moment(d));
  return moment.tz(date, "Europe/Rome").format('YYYY/MM/DD HH:mm')
  // return moment.utc(date).format('YYYY/MM/DD HH:mm');
  // return moment.tz(date, "Europe/Rome").format('YYYY/MM/DD HH:mm');
}

export function convertCalendarDateFormatToHHMM(date: string | Date) {
  return moment(date, 'YYYY/MM/DD HH:mm').format('HH:mm');
}

export function handleCalendarEventHourMapping(date: number, timestampOfDay: number, eventType: string) {
  if (eventType !== BookingType.CLOSING) {
    return convertTimestampToCalendarDateFormat(date);
  } else { return convertTimestampToCalendarDateFormat(mapTimestamp(date, timestampOfDay)) }

}

export function convertMinutesOfDayToHHMM(minutes: number) {
  return convertMillisecondsOfDaytoHHMM(minutes * 60 * 1000);
}

export function isDurationOverClosing(startHour: string, minutes: number, endHour: number, openHour: number) {
  if (openHour === 0 && endHour === 24) {
    return false;
  } else {
    return (convertHHMMtoMinutesOfDay(startHour) + minutes > (endHour * 60));
  }
}

export function convertMillisecondsToHHMM(mill: number) {
  return convertMillisecondsOfDaytoHHMM(mill - (new Date(mill).setHours(0, 0, 0, 0)));
}

export function mapBookingEndAt(start: string | Date, end: string | Date, start_at: number, end_at: number) {
  if ((convertCalendarDateFormatToHHMM(start) === '00:00' && ((end_at - start_at) == (24 * 60 * 60 * 1000)) ||
    convertCalendarDateFormatToHHMM(end) === '00:00')) {
    return "24:00"
  } else {
    return convertCalendarDateFormatToHHMM(end)
  }
}
export function changeDateTime(date: Date, millisecondsOfDay: number) {
  return (new Date(date.setHours(0, 0, 0, 0)).setMilliseconds(millisecondsOfDay));
}
export function convertDateAndTimeToTimestamp(date: Date, timeHHMM: string) {
  return changeDateTime(date, convertHHMMtoMillisecondsOfDay(timeHHMM))
}

export function convertTimestampToDDMMYYYY(timestamp: number) {
  return (new Date(timestamp).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }));
}

export function getTomorrowTimestamp(date: Date) {
  return new Date(
    date.getTime() + 24 * 60 * 60 * 1000
  ).getTime()
}

export function formatDateTournament(timestamp: number) {
  const month = new Date(timestamp).toLocaleDateString(
    "it-IT",
    { month: "long" }
  );
  const uppercasedMonth = month.charAt(0).toUpperCase() + month.slice(1);
  const dayOpt: Intl.DateTimeFormatOptions = {
    day: "2-digit",
  };
  const day = new Date(timestamp).toLocaleDateString(
    "it-IT",
    dayOpt
  );
  const yearOpt: Intl.DateTimeFormatOptions = {
    year: "numeric",
  };
  const year = new Date(timestamp).toLocaleDateString(
    "it-IT",
    yearOpt
  );
  return `${day} ${uppercasedMonth} ${year}`
}

export function convertConflictTime(timestamp: number) {
  return moment(new Date(timestamp)).format('DD/MM/YYYY - HH:mm');
}

export function convertEventTimeToMinutesOfDay(event_time: string): number {
  const mmt = moment(event_time, 'YYYY/MM/DD - HH:mm');
  return (mmt.clone().hours() * 60) + mmt.clone().minutes();
  //const mmtMidnight = mmt.clone().startOf('day');
  //const diffMinutes = mmt.diff(mmtMidnight, 'minutes');
  //return diffMinutes;
}

export function returnYesterday(date: Date, times = 1) {
  return new Date(
    date.getTime() - (times * (24 * 60 * 60 * 1000))
  );
}

export function returnTomorrow(date: Date, times = 1) {
  return new Date(
    date.getTime() + (times * (24 * 60 * 60 * 1000))
  );
}

export function getNextDayMidnight(date: Date, numberOfDays: number): number {
  return new Date(returnTomorrow(date, (1 + numberOfDays))).setHours(0, 0, 0, 0);
}

export function returnDateAdMidnight(date: Date) {
  return new Date(date.setHours(0, 0, 0, 0));
}

export function returnMillisecondsOfDay(date: Date) {
  const mmt = moment(date);
  const mmtMidnight = mmt.clone().startOf('day');
  const diffMinutes = mmt.diff(mmtMidnight, 'minutes');
  return (diffMinutes * 60 * 1000);
}

export function calculateGameDuration(end, start) {
  return moment(end).diff(moment(start), 'minutes');
}

export function isTimeInsideAnotherSlot(index, slot, slots) {
  const now = moment().format("YYYY-MM-DD");
  const startAt = moment(`${now} ${slot.start_at}`);
  const endAt = moment(`${now} ${slot.end_at}`);

  // ITERATE SLOTS
  for (let i = 0; i < slots.length; i++) {
    if (i === index) continue;
    const s = slots[i];
    if (startAt.isBetween(moment(`${now} ${s.start_at}`), moment(`${now} ${s.end_at}`)) ||
      endAt.isBetween(moment(`${now} ${s.start_at}`), moment(`${now} ${s.end_at}`))) {
      return true;
    }
  }
  return false;
}

export function isTimeLowerThan(start, end, value) {
  const now = moment().format("YYYY-MM-DD");
  const startAt = moment(`${now} ${start}`);
  const endAt = moment(`${now} ${end}`);

  // ---
  const diff = endAt.diff(startAt, 'minutes');
  return diff < value;
}

export function durationIsHigherThan(item, val) {
  try {
    if (Number.isInteger(item.start_at) && Number.isInteger(item.end_at)) {
      const diff = (item.end_at - item.start_at) / 60000;
      return diff >= val;
    }

    // --
    const start = moment(item.start_at, "HH:mm");
    const end = moment(item.end_at, "HH:mm");

    const duration = moment.duration(end.diff(start));
    if (isNaN(duration.asMinutes())) return null;
    return duration.asMinutes() >= val;
  } catch (e) {
    console.error(e);
  }

  return false;
}

export function daysByMonth(month) {
  if (!month) return [];
  const paddedMonth = month.toString().padStart(2, '0');
  const days = moment(`${moment().year()}-${paddedMonth}`, "YYYY-MM").daysInMonth();
  const output = [];
  for (let i = 1; i <= days; i++) {
    output.push({
      value: i,
      label: i,
    });
  }
  return output;
}

export function mappedWeekDay(weekday) {
  switch (weekday) {
    case 0:
      return WEEKDAYS_SLOTS.MONDAY;
    case 1:
      return WEEKDAYS_SLOTS.TUESDAY;
    case 2:
      return WEEKDAYS_SLOTS.WEDNESDAY;
    case 3:
      return WEEKDAYS_SLOTS.THURSDAY;
    case 4:
      return WEEKDAYS_SLOTS.FRIDAY;
    case 5:
      return WEEKDAYS_SLOTS.SATURDAY;
    case 6:
      return WEEKDAYS_SLOTS.SUNDAY;
  }
  return weekday;
}