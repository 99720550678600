
import { defineComponent, onMounted } from "vue";
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import { MutationTypes } from "./store/mutation-types";
//import { showToast } from "./services/error-management.service";
//import { ToastErrors } from "./constants/generic-errors.enum";

export default defineComponent({
  setup() {
    const store = useStore();
    const { t, locale } = useI18n();

    function initializeStore() {
      store.commit(MutationTypes.CLOSE_LOADER, null);
      store.commit(MutationTypes.SET_SHOW_ERROR, null);
      /*       setTimeout(() => {
        if (isViewPostLogin(router.currentRoute.value.name as string)) {
          store.dispatch(ActionTypes.AUTH_ACTION, null);
        }
      }, 0); */
    }

    function handleOrientation() {
      /* window.addEventListener("resize", () => {
        const isMobileDevice = window.navigator.userAgent.toLowerCase().includes("mobi");
        if (window.matchMedia("(orientation: landscape)").matches && isMobileDevice) {
          showToast(ToastErrors.ORIENTATION_ERROR);
        }
      }); */
    }

    onMounted(function() {
      initializeStore();
      handleOrientation();
    });

    return { t, locale };
  },
  methods: {
    hideError() {
      this.$store.commit(MutationTypes.SET_SHOW_ERROR, null);
    },
  },
  computed: {
    loader: function() {
      return this.$store.getters.getLoader;
    },
    showSuccess: function() {
      return this.$store.getters.getShowSuccess;
    },
    showError: function() {
      return this.$store.getters.getShowError;
    },
  },
});
