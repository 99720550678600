<template>
  <div class="main-div">
    <div v-if="showSuccess" class="error-toast">
      <PadelToast :message="showSuccess" @close-clicked="hideError($event)"
        type="primary"
        icon="check_circle" />
    </div>
    <div v-if="showError" class="error-toast">
      <PadelToast :message="showError" @close-clicked="hideError($event)" />
    </div>
    <div v-if="loader">
      <Loader />
    </div>
    <router-view />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import { MutationTypes } from "./store/mutation-types";
//import { showToast } from "./services/error-management.service";
//import { ToastErrors } from "./constants/generic-errors.enum";

export default defineComponent({
  setup() {
    const store = useStore();
    const { t, locale } = useI18n();

    function initializeStore() {
      store.commit(MutationTypes.CLOSE_LOADER, null);
      store.commit(MutationTypes.SET_SHOW_ERROR, null);
      /*       setTimeout(() => {
        if (isViewPostLogin(router.currentRoute.value.name as string)) {
          store.dispatch(ActionTypes.AUTH_ACTION, null);
        }
      }, 0); */
    }

    function handleOrientation() {
      /* window.addEventListener("resize", () => {
        const isMobileDevice = window.navigator.userAgent.toLowerCase().includes("mobi");
        if (window.matchMedia("(orientation: landscape)").matches && isMobileDevice) {
          showToast(ToastErrors.ORIENTATION_ERROR);
        }
      }); */
    }

    onMounted(function() {
      initializeStore();
      handleOrientation();
    });

    return { t, locale };
  },
  methods: {
    hideError() {
      this.$store.commit(MutationTypes.SET_SHOW_ERROR, null);
    },
  },
  computed: {
    loader: function() {
      return this.$store.getters.getLoader;
    },
    showSuccess: function() {
      return this.$store.getters.getShowSuccess;
    },
    showError: function() {
      return this.$store.getters.getShowError;
    },
  },
});
</script>

<style lang="scss">
@import "./theme/fonts.styles.scss";
@import "./theme/palette.styles.scss";

/* body::-webkit-scrollbar {
  width: 1em;
}
 
body.p-dialog-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
 
body::-webkit-scrollbar-thumb {
  background-color: map-get($colors, disabled-button);
} */

/* @media screen and (max-width: 720px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
} */

.main-div {
  height: 100%;
}

#app {
  height: 100%;
  font-family: $montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #000;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
