export function extractAddressData(place) {
    const padel_location = {
      address: place.formatted_address,
      city: "",
      state: "",
      zip_code: "",
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
    };
    let error = false;
    for (const component of place.address_components) {
      component.types.forEach((type) => {
        if (type === "street_number") {
          /* errStreetNumber = !errStreetNumber; */
        } else if (type === "postal_code") {
          padel_location.zip_code = component.long_name;
        } else if (type === "administrative_area_level_3") {
          padel_location.city = component.long_name;
        } else if (type === "country") {
          padel_location.state = component.long_name;
        }
      });
    }
    //ADDRESS MUST CONTAIN CITY AND STATE
    if(!padel_location.city || !padel_location.state) {
      error = true;
    }
    return { location: padel_location, err: error };
  }
