<template src="./add-new-club.template.html"> </template>

<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { uppercase } from "@/services/mapping.service";
import { useStore } from "@/store/store";
import {
  emptyAddNewClubErrors,
  emptyAddNewClubForm,
} from "@/constants/initial-values.constants";
import { useRouter } from "vue-router";
import { extractAddressData } from "@/services/google-management.service";
import {
  isAddClubFormValid,
  isAddClubFormEmpty,
} from "@/services/form-validation.service";
import { ActionTypes } from "@/store/action-types";
import { MutationTypes } from "@/store/mutation-types";
export default defineComponent({
  name: "AddNewClub",
  setup() {
    const router = useRouter();
    const store = useStore();
    const formValues = ref(emptyAddNewClubForm);
    const formErrors = ref(emptyAddNewClubErrors);
    const captchaSiteKey = ref(process.env.VUE_APP_CAPTCHA_SITE_KEY);
    const { t, locale } = useI18n();

    /* COMPUTED */
    const userInfo = computed(function() {
      return store.getters.getUser;
    });


    function updateFormValue(value, field) {
      formValues.value[field] = value;
    }

    function updateFormError(value, field) {
      formErrors.value[field] = value;
    }

    function updateVenue(place) {
      if (!place.geometry) {
        updateFormError("Inserire un luogo valido", "clubGeoLoc");
      } else {
        const resp = extractAddressData(place);
        updateFormValue(resp.location, "clubGeoLoc");
        //SE NON C'È IL CIVICO MOSTRARE ERRORE
        if (resp.err) {
          updateFormError(
            t("invalid_address"),
            "clubGeoLoc"
          );
        } else {
          updateFormError("", "clubGeoLoc");
        }
      }
    }

    function goBack() {
      router.back();
    }

    function executeCaptcha() {
      /* eslint-disable */
      grecaptcha.execute();
    }

    function handlePostClubAdded() {
      store.commit(MutationTypes.WRITE_ALLOW_DOWNLOAD, true);
      router.push("download-contract");
    }

    async function addNewClub(_token) {
      try {
        await store.dispatch(ActionTypes.ADD_NEW_CLUB, {
          club: {
            clubName: formValues.value.clubName,
            clubAddress: formValues.value.clubGeoLoc,
          },
          token: _token,
        });
        handlePostClubAdded();
      } catch (error) {
        return;
      }
    }

    function onSubmitCaptcha(token) {
      addNewClub(token);
    }

    function onPlaceChanged(autocomplete) {
      const place = autocomplete.getPlace();
      updateVenue(place);
    }

    function loadCaptcha() {
      window.onSubmitCaptcha = onSubmitCaptcha;
      //LOAD CAPTCHA script
      const recaptchaScript = document.createElement("script");
      recaptchaScript.setAttribute(
        "src",
        `https://www.google.com/recaptcha/api.js`
      );
      document.head.appendChild(recaptchaScript);
    }

    function initializeAutocomplete() {
      loadCaptcha();
      const input = document.getElementById("autocomplete");
      /* eslint-disable */
      const autocomplete = new google.maps.places.Autocomplete(input, {
        fields: ["geometry", "formatted_address", "address_components"],
        types: ["address"],
      });
      autocomplete.setComponentRestrictions({
        country: ["it"],
      });
      /* eslint-disable */
      google.maps.event.addListener(autocomplete, "place_changed", () => {
        onPlaceChanged(autocomplete);
      });
    }

    function initializePlacesAndCaptcha() {
      if (window.google && window.google.maps) {
        initializeAutocomplete();
        return;
      }
      var script = document.createElement("script");
      script.onload = function() {
        if (!window.google && !window.google.maps)
          return void console.error("no google maps script included");
        initializeAutocomplete();
      };
      script.async = true;
      script.defer = true;
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.VUE_APP_GOOGLE_PLACES_KEY}&libraries=places`;
      document.getElementsByTagName("head")[0].appendChild(script);
    }

    onMounted(() => {
      initializePlacesAndCaptcha();
    });

    return {
      t,
      locale,
      uppercase,
      userInfo,
      formValues,
      formErrors,
      updateFormValue,
      captchaSiteKey,
      goBack,
      isAddClubFormEmpty,
      isAddClubFormValid,
      executeCaptcha,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "./add-new-club.style.scss";
</style>

/* CLASSES FOR GOOGLE AUTOCOMPLETE */
<style lang="scss">
@import "@/theme/google-autocomplete.styles.scss";
</style>
