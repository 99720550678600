
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { uppercase } from "@/services/mapping.service";
export default defineComponent({
  emits: ["hide"],
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    function hideModal() {
      emit("hide", true);
    }

    return {t, locale, uppercase, hideModal}
  },
});
