
/* eslint-disable */
import { useStore } from "@/store/store";
import { useI18n } from "vue-i18n";
import moment from "moment";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  nextTick,
  watch,
} from "vue";
import { PadelClub } from "@/models/club.models";
import { ActionTypes } from "@/store/action-types";
import { useRouter, useRoute } from "vue-router";
import {
  uppercase,
  mapCancelBookingPayload,
  confirmedGame,
  mapModifyBookingPayload,
} from "@/services/mapping.service";
import { matchTypesOptions } from "@/constants/radio-options.constants";
import MatchPlayers from "@/components/match-players/MatchPlayers.vue";
import { Levels, PlayerCategoriesOptions } from "@/constants/constants.constants";
import { ToastErrors } from "@/constants/generic-errors.enum";
import {
  showToast,
  showSuccessToast,
} from "@/services/error-management.service";
import { CardCategories } from "@/constants/card-types.enum";
import { PaymentOptions } from "@/constants/payment-choices.constants";

export default defineComponent({
  name: "OpenBookingDetail",
  components: {
    MatchPlayers,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const router = useRouter();
    const { t, locale } = useI18n();
    const openBookingId = ref(route.params.id);
    const openGame = ref(null);
    const modalId = ref(null);
    const linkInput = ref(null);
    const formDisabled = ref(true);
    const originalGame = ref(null);
    const notesInput = ref(null);
    const hideError = ref(true);

    // TABS
    const selectedTab = ref(0);

    /* COMPUTED */
    const myClub = computed(function (): PadelClub {
      return store.getters.getMyClub;
    });
    const myClubId = computed(function (): string {
      return store.getters.getMyClub.club_id;
    });
    const minLevelLabel = computed(function () {
      const item = Levels.find((el) => el.value === openGame.value.min_level);
      if (!item) return "n/a";
      return item.label;
    });
    const maxLevelLabel = computed(function () {
      const item = Levels.find((el) => el.value === openGame.value.max_level);
      if (!item) return minLevelLabel.value;
      return item.label;
    });
    const appLink = computed(function () {
      if (!openGame.value) return ``;
      return `${process.env.VUE_APP_MATCH_LINK}${openGame.value.id}`;
    });
    const confirmedBooking = computed({
      get: () => openGame.value.booking_state === "CONFIRMED",
      set: (val) => {
        console.log("val", val);
        openGame.value.booking_state = val ? "CONFIRMED" : "OPEN";
      },
    });

    
    const bookingCategoriesOptions = computed(function (): Array<any> {
      if (myClub.value.club_category == CardCategories.COMMERCIAL) {
        return [PlayerCategoriesOptions[1]]; // PlayerCategoriesOptions[1] => Commerciale
      }
      return PlayerCategoriesOptions;
    });

    const playersList = computed(function () {
      // INIT
      const arr = [];

      // ITERATE
      for (let i = 0; i < openGame.value.players; i++) {
        let item = openGame.value.match[`player_${i + 1}`];
        if (item) {
          if (i === 0) {
            item.id = openGame.value.match[`wesmash_user_id`];
          } else {
            item.id = openGame.value.match[`wesmash_user${i + 1}_id`];
          }
        }
        arr.push(item);
      }

      // RETURN
      return arr;
    });


    // LIFECYCLE
    onMounted(() => {
      nextTick(() => {
        getOpenGame();
      });
    });

    onUnmounted(() => {});


    /** WATCH */

    // FUNCTIONS
    function getOpenGame() {
      store
        .dispatch(ActionTypes.GET_OPEN_GAME, { id: openBookingId.value })
        .then((response) => {
          openGame.value = response.data;
          openGame.value.taking = openGame.value.taking / 100 || 0;
          openGame.value.player_1_taking = openGame.value.player_1_taking ? openGame.value.player_1_taking / 100 : null;
          openGame.value.player_2_taking = openGame.value.player_2_taking ? openGame.value.player_2_taking / 100 : null;
          openGame.value.player_3_taking = openGame.value.player_3_taking ? openGame.value.player_3_taking / 100 : null;
          openGame.value.player_4_taking = openGame.value.player_4_taking ? openGame.value.player_4_taking / 100 : null;

          if (myClub.value.club_category == "COMMERCIAL") {
            // Se il centro è di tipo commerciale => Default Commerciale
            if (openGame.value.player_1) {
              openGame.value.player_1_booking_category = bookingCategoriesOptions.value[0].value;
            }
            if (openGame.value.player_2) {
              openGame.value.player_2_booking_category = bookingCategoriesOptions.value[0].value;
            }
            if (openGame.value.player_3) {
              openGame.value.player_3_booking_category = bookingCategoriesOptions.value[0].value;
            }
            if (openGame.value.player_4) {
              openGame.value.player_4_booking_category = bookingCategoriesOptions.value[0].value;
            }
          }
          else {
            if (openGame.value.player_1 && !openGame.value.player_1_booking_category) {
              if (openGame.value.player_1.player_category && openGame.value.player_1.player_category.length > 0) {
                const category = myClub.value.player_categories.filter((el) => el.code == openGame.value.player_1.player_category)[0];
                if (category != null) {
                  openGame.value.player_1_booking_category = category.type;
                }
                else {
                  checkUserCards(openGame.value.wesmash_user_id).then((cards) => {
                    if (cards.length > 0) {
                      openGame.value.player_1_booking_category = "INSTITUTIONAL";
                    }
                  });
                }
              }
              else {
                checkUserCards(openGame.value.wesmash_user_id).then((cards) => {
                  if (cards.length > 0) {
                    openGame.value.player_1_booking_category = "INSTITUTIONAL";
                  }
                });
              }
            }
            
            if (openGame.value.player_2 && !openGame.value.player_2_booking_category) {
              if (openGame.value.player_2.player_category && openGame.value.player_2.player_category.length > 0) {
                const category = myClub.value.player_categories.filter((el) => el.code == openGame.value.player_2.player_category)[0];
                
                if (category != null) {
                  openGame.value.player_2_booking_category = category.type;
                }
                else {
                  checkUserCards(openGame.value.wesmash_user2_id).then((cards) => {
                    if (cards.length > 0) {
                      openGame.value.player_2_booking_category = "INSTITUTIONAL";
                    }
                  });
                }
              }
              else {
                checkUserCards(openGame.value.wesmash_user2_id).then((cards) => {
                  if (cards.length > 0) {
                    openGame.value.player_2_booking_category = "INSTITUTIONAL";
                  }
                });
              }
            }
            if (openGame.value.player_3 && !openGame.value.player_3_booking_category) {
              if (openGame.value.player_3.player_category && openGame.value.player_3.player_category.length > 0) {
                const category = myClub.value.player_categories.filter((el) => el.code == openGame.value.player_3.player_category)[0];
                if (category != null) {
                  openGame.value.player_3_booking_category = category.type;
                }
                else {
                  checkUserCards(openGame.value.wesmash_user3_id).then((cards) => {
                    if (cards.length > 0) {
                      openGame.value.player_3_booking_category = "INSTITUTIONAL";
                    }
                  });
                }
              }
              else {
                checkUserCards(openGame.value.wesmash_user3_id).then((cards) => {
                  if (cards.length > 0) {
                    openGame.value.player_3_booking_category = "INSTITUTIONAL";
                  }
                });
              }
            }
            if (openGame.value.player_4 && !openGame.value.player_4_booking_category) {
              if (openGame.value.player_4.player_category && openGame.value.player_4.player_category.length > 0) {
                const category = myClub.value.player_categories.filter((el) => el.code == openGame.value.player_4.player_category)[0];
                if (category != null) {
                  openGame.value.player_4_booking_category = category.type;
                }
                else {
                  checkUserCards(openGame.value.wesmash_user4_id).then((cards) => {
                    if (cards.length > 0) {
                      openGame.value.player_4_booking_category = "INSTITUTIONAL";
                    }
                  });
                }
              }
              else {
                checkUserCards(openGame.value.wesmash_user4_id).then((cards) => {
                  if (cards.length > 0) {
                    openGame.value.player_4_booking_category = "INSTITUTIONAL";
                  }
                });
              }
            }
          }

          openGame.value.description = openGame.value.description || "";
          confirmedBooking.value = openGame.value.booking_state === "CONFIRMED";
        });
    }

    function selectMatchType(value) {}

    function formatDate() {
      // INIT
      const formattedDate = moment(openGame.value.date, "M/D/YYYY").format(
        "DD MMMM YYYY"
      );
      return `${formattedDate}`;
    }

    function formatGameDuration() {
      const duration =
        (openGame.value.end_at - openGame.value.start_at) / 1000 / 60;
      return duration;
    }

    function getPlayersNumber() {
      const matchType = matchTypesOptions.find(
        (el) => el.value === openGame.value.match_type
      );
      return matchType.participants;
    }

    function formatTime(value, format) {
      return moment(value).format(format);
    }

    function onChangeTab(event) {
      selectedTab.value = event;
    }

    function showModal(id) {
      modalId.value = id;
    }

    function hideModal() {
      modalId.value = null;
    }

    async function deleteGame() {
      try {
        await store.dispatch(
          ActionTypes.DELETE_TIMESLOT,
          mapCancelBookingPayload(openGame.value, myClub.value.club_id)
        );
        router.go(-1);
      } catch (err) {
        return;
      }
    }

    function copyToClipboard() {
      navigator.clipboard.writeText(appLink.value).then(
        function () {
          showSuccessToast(t("copy_on_clipboard"));
        },
        function () {
          showToast(ToastErrors.GENERIC);
        }
      );
    }

    function toggleForm() {
      formDisabled.value = !formDisabled.value;
      originalGame.value = JSON.parse(JSON.stringify(openGame.value));
    }

    function abortForm() {
      // RESET
      openGame.value.description = originalGame.value.description
        ? originalGame.value.description
        : "";
      openGame.value.taking = originalGame.value.taking;
      confirmedBooking.value =
        originalGame.value.booking_state === "OPEN_BOOKING_CONFIRMED";
      notesInput.value.clearText(openGame.value.description); // WORKAROUND

      // TOGGLE
      toggleForm();
    }

    function updateFormValue(event: string, key: string) {
      openGame.value[key] = event;
    }

    async function checkUserCards(userId: string) {
      const response = await store.dispatch(ActionTypes.GET_CARDS, { user_id: userId, limit: 20 });
      return response.data.records;
    }

    

    async function update() {
      try {
        const payload = {
          club_id: myClubId.value,
          booking_id: openGame.value.id,
          playground_id: openGame.value.playground_id,
          playground_name: openGame.value.playground_name,
          apply_all_playground: false,
          date: openGame.value.date,
          start_at: openGame.value.start_at,
          end_at: openGame.value.end_at,
          price: openGame.value.price ? openGame.value.price : null,
          description: openGame.value.description
            ? openGame.value.description
            : "",
          color: openGame.value.color,
          payment_state: openGame.value.payment_state,
          sport_type: openGame.value.sport_type,
          taking: openGame.value.taking
            ? parseFloat(openGame.value.taking) * 100
            : null,
          type: openGame.value.type,
          event_type: openGame.value.event_type || undefined,
          wesmash_user_id: openGame.value.wesmash_user_id,
          wesmash_user2_id: openGame.value.wesmash_user2_id || undefined,
          wesmash_user3_id: openGame.value.wesmash_user3_id || undefined,
          wesmash_user4_id: openGame.value.wesmash_user4_id || undefined,
          player_1_taking: openGame.value.player_1_taking ? parseFloat(openGame.value.player_1_taking) * 100 : undefined,
          player_2_taking: openGame.value.player_2_taking ? parseFloat(openGame.value.player_2_taking) * 100 : undefined,
          player_3_taking: openGame.value.player_3_taking ? parseFloat(openGame.value.player_3_taking) * 100 : undefined,
          player_4_taking: openGame.value.player_4_taking ? parseFloat(openGame.value.player_4_taking) * 100 : undefined,
          player_1_payment_state: openGame.value.player_1_payment_state || undefined,
          player_2_payment_state: openGame.value.player_2_payment_state || undefined,
          player_3_payment_state: openGame.value.player_3_payment_state || undefined,
          player_4_payment_state: openGame.value.player_4_payment_state || undefined,
          player_1_payment_choice: openGame.value.player_1_payment_choice || undefined,
          player_2_payment_choice: openGame.value.player_2_payment_choice || undefined,
          player_3_payment_choice: openGame.value.player_3_payment_choice || undefined,
          player_4_payment_choice: openGame.value.player_4_payment_choice || undefined,
          player_1_booking_category: openGame.value.player_1_booking_category || undefined,
          player_2_booking_category: openGame.value.player_2_booking_category || undefined,
          player_3_booking_category: openGame.value.player_3_booking_category || undefined,
          player_4_booking_category: openGame.value.player_4_booking_category || undefined,

          booking_state: openGame.value.booking_state,
        };

        if (openGame.value.player_1_taking > 0 || openGame.value.player_2_taking > 0 || openGame.value.player_3_taking > 0 || openGame.value.player_4_taking > 0) {
          payload['taking'] = 
            (payload['player_1_taking'] ? payload['player_1_taking'] : 0) + 
            (payload['player_2_taking'] ? payload['player_2_taking'] : 0) + 
            (payload['player_3_taking'] ? payload['player_3_taking'] : 0) +
            (payload['player_4_taking'] ? payload['player_4_taking'] : 0);
        }
        const bookingResponse = await store.dispatch(
          ActionTypes.MODIFY_BOOKING,
          payload
        );

        // TOGGLE
        toggleForm();

        // GET UPDATED
        getOpenGame();
      } catch (error) {
        showToast((error as any).message);
      }
    }

    // RETURN
    return {
      t,
      locale,
      router,
      uppercase,
      moment,

      bookingCategoriesOptions,
      playersList,

      // REFS
      linkInput,
      notesInput,

      // OPTIONS
      matchTypesOptions,

      // DATA
      myClub,
      myClubId,
      openGame,
      modalId,
      minLevelLabel,
      maxLevelLabel,
      appLink,
      formDisabled,
      hideError,
      confirmedBooking,
      selectedTab,
      PaymentOptions,

      // METHODS
      getOpenGame,
      selectMatchType,
      formatDate,
      formatGameDuration,
      getPlayersNumber,
      formatTime,
      onChangeTab,
      showModal,
      hideModal,
      deleteGame,
      confirmedGame,
      copyToClipboard,
      toggleForm,
      abortForm,
      updateFormValue,
      update,
    };
  },
});
