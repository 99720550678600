export enum MutationTypes {
  RESET_STORE = 'RESET_STORE',
  TOGGLE_LOADER = 'TOGGLE_LOADER',
  OPEN_LOADER = 'OPEN_LOADER',
  CLOSE_LOADER = 'CLOSE_LOADER',
  SET_USER = 'SET_USER',
  REMOVE_USER = 'REMOVE_USER',
  WRITE_CONFIRM_SIGNUP_USER = 'WRITE_CONFIRM_SIGNUP_USER',
  WRITE_TOKENS = 'WRITE_TOKENS',
  ADD_CLUB_REQUESTS = 'ADD_CLUB_REQUESTS',
  WRITE_CLUB_REQUESTS = 'WRITE_CLUB_REQUESTS',
  SET_SHOW_SUCCESS = 'SET_SHOW_SUCCESS',
  SET_SHOW_ERROR = 'SET_SHOW_ERROR',
  WRITE_MY_CLUBS = 'WRITE_MY_CLUBS',
  CLEAR_MY_CLUBS = 'CLEAR_MY_CLUBS',
  WRITE_LOGGED_CLUB = 'WRITE_LOGGED_CLUB',
  CLEAR_LOGGED_CLUB = 'CLEAR_LOGGED_CLUB',
  WRITE_ALLOW_DOWNLOAD = 'WRITE_ALLOW_DOWNLOAD',
  WRITE_MY_PLAYGROUNDS = 'WRITE_MY_PLAYGROUNDS',
  WRITE_ADMIN_CLUBS = 'WRITE_ADMIN_CLUBS',
  SET_FORCED_LOGOUT = 'SET_FORCED_LOGOUT',
  RESET_LAST_EVALUATED_KEY = 'RESET_LAST_EVALUATED_KEY',
  SET_CURRENT_DATE = 'SET_CURRENT_DATE',
  RESET_CURRENT_DATE = 'RESET_CURRENT_DATE',
  SET_PRICE_RULE = 'SET_PRICE_RULE',
  SET_EXCEPTION_RULE = 'SET_EXCEPTION_RULE',
  SET_BUNDLE_RESPONSE = "SET_BUNDLE_RESPONSE",
  SET_SCROLL_EVENT = "SET_SCROLL_EVENT",
  SET_SEARCH_USER = "SET_SEARCH_USER",
  SET_USER_CARDS = "SET_USER_CARDS",
}