<div class="width-100">
    <div class="header margin-bottom-20">
        <div class="p-text-left">
            <span class="header-title">{{ t('bookability') }}</span>
        </div>
    </div>

    <div class="margin-bottom-10">
        <div class="form-container as-row">
            <div style="text-align: left;">
                <label class="label">
                    {{ t('minimum_notice') }} 
                    <i class="pi pi-info-circle p-p-1"
                        v-tooltip.bottom="t('bookability_tooltip')"></i>
                </label>
                <PadelDropdown 
                    :label="t('minimum_notice')" 
                    :options="ForewarningOptions"
                    @input="setFormValue('forewarning', $event.value)" 
                    :default="myClub.forewarning" />
            </div>
            <div style="text-align: left; display: none;">
                <label class="label">
                    {{ t('delete_booking') }} 
                    <i class="pi pi-info-circle p-p-1" style="visibility: hidden;"></i>
                </label>
                <PadelInput 
                    class="p-mt-2"
                    :label="t('delete_booking')" 
                    :disabled="true"
                    :value="'24 ore'" />
            </div>
            <div style="text-align: left; display: none;">
                <label class="label">
                    {{ t('window_booking') }} 
                    <i class="pi pi-info-circle p-p-1" style="visibility: hidden;"></i>
                </label>
                <PadelInput 
                    class="p-mt-2"
                    :label="t('window_booking')" 
                    :disabled="true"
                    :value="'3 settimane'" />
            </div>
        </div>
    </div>

    <!-- TIPOLOGIA CENTRO -->
    <div class="header margin-top-40 margin-bottom-10">
        <div class="p-text-left">
            <span class="header-title">TIPOLOGIA CENTRO</span>
        </div>
    </div>
    <div class="margin-bottom-10">
        <div class="form-container as-row">
            <div style="text-align: left;">
                <PadelDropdown 
                    :label="t('category')" 
                    :options="ClubCategoriesOptions"
                    @input="setFormValue('club_category', $event.value)" 
                    :default="myClub.club_category != null ?  myClub.club_category : ClubCategoriesOptions[0].value" />
            </div>
        </div>
    </div>

    <!-- TIPOLOGIE GIOCATORI -->
    <div class="header margin-top-40 margin-bottom-10">
        <div class="p-text-left">
            <span class="header-title">{{ t('player_type') }}</span>
        </div>

        <div class="p-text-right">
            <div class="cursor-pointer color-blue flexbox-row align-center"
                @click="createType()">
                <span class="button-text montserrat">{{ t('add_player_type') }}</span>
                <span class="material-icons">add</span>
            </div>
        </div>
    </div>

    <div class="cards-container margin-bottom-10">
        <UserTypeCard 
            v-for="(element, index) in types"
            :key="index"
            :element="element"
            :index="index"
            @edit="editType"
            @delete="deleteType" />
    </div>
</div>


<DumbModal 
    v-if="dialog === 'CREATE'"
    :title="t('add_player_type')" 
    @hide="hideDialog()">
    <div class="modal-content p-text-center p-p-6">
        <div class="form-container">
            <PadelInput 
                :label="t('player_type')" 
                @input="setFormValue('name', $event)"
                :value="form.name"  />
            <PadelDropdown 
                :label="t('category')" 
                :options="PlayerCategoriesOptions"
                @input="setFormValue('type', $event.value)" 
                :default="form.type" />
        </div>
    </div>

    <template #footer>
        <div class="p-d-flex p-jc-end">
            <PadelButton 
                :label="t('confirm')"
                :disabled="isFormPlayerTypeEmpty(form) || !isFormPlayerTypeValid(formErrors)"
                @click="save()"  />
        </div>
    </template>
</DumbModal>

<ModalSuccess @hide="hideDialog()"
    v-if="dialog === 'SUCCESS'" />

<DumbModal :title="t('deleting_player_type')" 
    @hide="hideDialog()"
    v-if="dialog === 'DELETE'" >
    <div class="modal-content p-text-center p-p-6">
        <i class="pi pi-exclamation-triangle color-warning" style="font-size: 2rem"></i>
        <p class="p-text-center color-white" v-html="t('deleting_player_type_message', { name: category.name })"></p>
    </div>

    <template #footer>
        <div class="p-d-flex p-jc-end">
            <PadelButton 
                :label="t('confirm')" 
                @click="confirmDelete()" />
        </div>
    </template>
</DumbModal>