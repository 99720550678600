
import { useI18n } from "vue-i18n";
import { uppercase, upperCaseFirstLetter } from "@/services/mapping.service";
import { doubleZeros } from "@/services/dates.service";
import { defineComponent } from "vue";
import { emptyPlaygroundForm } from "@/constants/initial-values.constants";
import Actions from "@/components/actions/Actions.vue";

export default defineComponent({
  components: {
    Actions,
  },
  props: {
    element: {
      type: Object,
      required: true,
      default: () => {
        return { ...emptyPlaygroundForm };
      },
    },
    index: { type: Number, required: true, default: null },
    canCopy: { type: Boolean, required: true, default: true },
    withActions: { type: Boolean, required: true, default: true },
    clickable: { type: Boolean, required: false, default: true },
  },
  emits: ['copyPlayground', 'deletePlayground', 'modifyPlayground', 'duplicatePlayground'],
  setup(props, {emit}) {
    const { t, locale } = useI18n();

    function copyPlayground() {
        emit("copyPlayground");
    }

    function deletePlayground() {
        emit("deletePlayground");
    }

    function modifyPlayground() {
      if (!props.clickable) return;
      emit("modifyPlayground");
    }

    function duplicatePlayground() {
      emit("duplicatePlayground");
    }

    return {
      t, 
      locale, 
      uppercase,
      upperCaseFirstLetter,
      doubleZeros,
      copyPlayground,
      deletePlayground,
      modifyPlayground,
      duplicatePlayground,
    };
  },
});
