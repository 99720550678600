
import { PadelClub } from "@/models/club.models";
import { ActionTypes } from "@/store/action-types";
import { useStore } from "@/store/store";
import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { convertTeamsToArray, uppercase } from "@/services/mapping.service";
import { convertArrayIntoCsv } from "@/services/files.service";
export default defineComponent({
  props: ["tournament"],
  emits: ["tournamentChanged"],
  setup(props, { emit }) {
    const teamsList = ref([]);
    const store = useStore();
    const hoverEnabled = ref(false);
    const { t, locale } = useI18n();
    const modalToShow = ref(null);
    const teamToDelete = ref(null);

    /* COMPUTED */
    const myClub = computed(function(): PadelClub {
      return store.getters.getMyClub;
    });

    function openModal(modal: string) {
      modalToShow.value = modal;
    }

    function hideModal() {
      teamToDelete.value = null;
      modalToShow.value = null;
    }

    async function getTeams() {
      try {
        const resp = await store.dispatch(ActionTypes.GET_TOURNAMENT_TEAMS, {
          club_id: myClub.value.club_id,
          tournament_id: props.tournament.id,
        });
        teamsList.value = resp;
      } catch (error) {
        return;
      }
    }

    onMounted(() => {
      getTeams();
    });

    async function refreshPage() {
      try {
        const resp = await store.dispatch(ActionTypes.GET_TOURNAMENT_DETAILS, {
          club_id: myClub.value.club_id,
          tournament_id: props.tournament.id,
        });
        emit("tournamentChanged", resp);
      } catch (error) {
        return;
      }
    }

    async function removeTeam(team) {
      try {
        await store.dispatch(ActionTypes.DELETE_TEAM, {
          club_id: myClub.value.club_id,
          tournament_id: props.tournament.id,
          booker_id: team.booker_id,
        });
        await getTeams();
        refreshPage();
      } catch (error) {
        return;
      }
    }

    function deleteTeam() {
      removeTeam(teamToDelete.value);
      hideModal();
    }

    function handleDeleteClick(team) {
      teamToDelete.value = team;
      openModal("SURE_WANNA_DELETE_TEAM");
    }

    function downloadTeams() {
      convertArrayIntoCsv(convertTeamsToArray(teamsList.value, t), "teams_list");
    }

    return {
      t,
      locale,
      teamsList,
      uppercase,
      hoverEnabled,
      modalToShow,

      removeTeam,
      openModal,
      hideModal,
      handleDeleteClick,
      deleteTeam,
      downloadTeams
    };
  },
});
