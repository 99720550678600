export enum ToastErrors {
    GENERIC = 'Ops si è verificato un errore! Riprovare più tardi',
    WRONG_FILE_FORMAT = 'Il formato del file non è supportato',
    WAIT = 'Attendi prima di inviare un nuovo codice',
    CLUB_DISABLED = 'Non potrai effettuare nessuna nuova prenotazione finche non sarai riabilitato dal team Wesmash',
    FORCE_LOGOUT = 'Ops si è verificato un errore! Effettuare nuovamente il login',
    LOGIN_NOT_POSSIBLE = 'Impossibile effettuare il login con questa utenza',
    FILE_TOO_HEAVY = 'Il file selezionato è troppo pesante',
    ORIENTATION_ERROR = 'Visualizzazione landscape non supportata',
    USER_HAS_DELETED_BOOKING = 'L\'utente ha eliminato la prenotazione'
}