
import { defineComponent } from "vue";

export default defineComponent({
  name: "PadelCalendar",
  props: {
    value: { type: Date, required: false, default: null },
    error: { type: String, required: false, default: null },
    label: { type: String, required: false, default: "Data richiesta" },
    showLabel: { type: Boolean, required: false, default: true },
    viewType: { type: String, required: false, default: "date" },
    minDate: { type: Date, required: false, default: null },
    maxDate: { type: Date, required: false, default: null },
    disabled: { type: Boolean, required: false, default: false },
    manualInput: { type: Boolean, required: false, default: true },
  },
  data() {
    return {
      internalValue: this.value,
      labelUp: false,
    };
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal !== oldVal) this.internalValue = newVal;
    },
    internalValue(newVal, oldVal) {
      if (newVal !== oldVal) this.$emit("input", newVal);
    },
  },
  computed: {
    label__class() {
      if (this.disabled && this.labelUp) {
        return "label calendar-label-up-disabled";
      } else if (this.labelUp) {
        return "label calendar-label-up";
      } else if (this.internalValue) {
        return "label calendar-label-up-grey";
      } else {
        return "label calendar-label";
      }
    },
    color__class() {
      if (this.disabled) {
        return "color-disabled";
      } else if (this.internalValue) {
        return "color-gray";
      } else if (this.labelUp){ return ""}else { return "color-gray";}
    },
    calendar__class() {
      if (this.disabled) {
        return "inline-calendar disabled-calendar";
      } else if (!this.showLabel && this.error) {
        return "inline-calendar error-calendar";
      } else if (!this.showLabel) {
        return "inline-calendar";
      } else if (this.error) {
        return "error-calendar";
      } else {
        return "";
      }
    },
    dateFormat() {
      if (this.viewType === "date") {
        return "dd/mm/yy";
      } else if (this.viewType === "daymonth") {
        return "dd/mm";
      } else if (this.viewType === "mmdd") {
        return "mm/dd";
      } else {
        return "mm/yy";
      }
    },
  },
  methods: {
    toggleLabel() {
      this.labelUp = !this.labelUp;
    },
    onMonthChange(event) {
      this.$emit("monthChange", event);
    },
    calendarViewType() {
      if (this.viewType === "daymonth" || this.viewType === "mmdd") {
        return "date";
      }
      return this.viewType;
    },
  },
  emits: ["input", "monthChange"],
});
