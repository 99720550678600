<div v-if="modalOpened !== 'CONFIRM_PHONE_NUMBER'">
    <DumbModal :title="t('new_booking')" @hide="hideModal()" :modal="false"
        :minimizable="true"
        :breakpoints="{'720px': '90vw', '640px': '95vw'}"
        :style="{width: '80vw'}">
        <div class="main-part flexbox-column align-center">
            <p class="montserrat color-white">{{t('what_booking_type')}}</p>
            <PadelRadio 
                :value="selectedBookingType" 
                :options="computedBookingRadioOptions" 
                @input="changeSlotTypology($event)"
                :chipMode="true" />
            <div class="form-container">
                <div v-if="selectedBookingType === 'BOOKING'" class="form game-form">
                    <TabView class="tab-view" :activeIndex="selectedTab" @tab-change="onChangeTab($event.index)">
                        <TabPanel>
                            <template #header>
                                <i class="material-icons" :class="{'color-blue': selectedTab === 0}">how_to_reg</i>
                                <span>{{uppercase(t('registered_user'))}}</span>
                            </template>
                            <div class="registered-container margin-top-20 flexbox-column">
                                <PadelSearch class="search-element" :label="t('search_user')" :value="searchPlaceholder"
                                    @onEnterPressed="searchUsers($event)" :options="searchOptions"
                                    @onClosePressed="handleSearchClose()" :error="newBookingErrors.wesmash_user"
                                    @valueClicked="searchValueSelected($event)" @validate="onSearchValidate($event)" />
                                <div class="form-row flexbox-row justify-space-between registered-first-row">
                                    <div class="calendar-element">
                                        <PadelCalendar v-if="selectedTab === 0" :label="t('game_date')"
                                            :value="newBookingForm.date" :minDate="new Date()"
                                            @input="validateHourToday('date', $event)" :error="newBookingErrors.date" />
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-12 p-sm-6">
                                            <PadelDropdown @input="setFormValue('sport_type', $event.value)"
                                                :label="t('sport_choice')" :default="newBookingForm.sport_type"
                                                :options="mySports" :isDropdownInModal="isModal" />
                                        </div>
                                        <div class="p-col-12 p-sm-6">
                                            <PadelDropdown @input="setFormValue('playground', $event.value)"
                                                :label="t('playground_choice')" :default="newBookingForm.playground"
                                                :options="computedPlaygrounds" :isDropdownInModal="isModal" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row flexbox-row justify-space-between margin-top-20">
                                    <div style="margin-top: 26px;">
                                        <PadelTimepicker v-if="selectedTab === 0" 
                                            :label="t('start_hour')"
                                            :value="newBookingForm.start_at"
                                            @input="validateHourToday('start_at', $event)" 
                                            :from="openingHour"
                                            :to="closingHour" 
                                            :error="newBookingErrors.start_at" />
                                    </div>
                                    <div class="radio-container flexbox-column">
                                        <div class="flexbox-row p-mb-2">
                                            <PadelToggle 
                                                v-model:checked="withEndTime"
                                                @change="switchEndTime($event)"
                                                containerClass="p-d-inline-flex p-ml-2"
                                                labelClass="color-white overflow-inherit p-size-mini"
                                                :label="t('end_hour')" />
                                        </div>
                                        <div class="adjust-radio-spacing" style="margin-top: 10px;"
                                            v-if="!withEndTime">
                                            <PadelRadio :options="gameDurationOptions" :value="newBookingForm.game_duration"
                                                @input="validateHourToday('game_duration', $event)" :chipMode="true"  />
                                        </div>
                                        <PadelTimepicker 
                                            v-else
                                            :label="t('end_hour')"
                                            :value="newBookingForm.end_at"
                                            @input="validateHourToday('end_at', $event)" 
                                            :from="openingHour"
                                            :to="closingHour" 
                                            :error="newBookingErrors.end_at"
                                            containerClass="full-width" />
                                    </div>
                                </div>
                                <div class="form-row flexbox-row justify-space-between">
                                    <div class="p-mt-2 p-d-flex p-jc-between">
                                        <PadelInput :label="t('price_each_booking')" 
                                            :inputType="'number'" 
                                            :isPrice="true"
                                            :icon="'euro_symbol'" 
                                            :value="newBookingForm.price"
                                            @input="setFormValue('price', $event)" 
                                            :hideError="hideError"
                                            containerClass="full-width" />
                                        <span class="material-icons p-ml-2 p-mt-3 color-white cursor"
                                            v-if="!withEndTime"
                                            @click.prevent="syncPrice">
                                            sync
                                        </span>
                                    </div>
                                    <div class="p-mt-2">
                                        <PadelInput :label="t('taking')" 
                                            :inputType="'number'" 
                                            :isPrice="true"
                                            :icon="'euro_symbol'" 
                                            :value="newBookingForm.taking"
                                            @input="setFormValue('taking', $event)" 
                                            :hideError="hideError"
                                            containerClass="full-width" />
                                    </div>
                                </div>
                                <div class="form-row flexbox-row justify-space-between">
                                    <PadelDropdown :label="t('payment_state')" :options="PaymentOptions"
                                        @input="setFormValue('payment_state', $event.value)" :default="newBookingForm.payment_state"
                                        :isDropdownInModal="isModal" />
                                    <PadelDropdown :label="t('color_choice')" :options="colorChoices"
                                        class="add-10-small-screen" @input="setFormValue('color', $event.value)"
                                        :default="newBookingForm.color" :isDropdownInModal="isModal" />
                                </div>

                                <div class="form-row flexbox-row justify-space-between margin-top-20">
                                    <!-- Se il centro è di tipo commerciale: è fisso commerciale -->
                                    <!-- se è di altra natura: dipente dal giocatore -->
                                    <PadelDropdown label="Tipologia prenotazione"
                                        :options="bookingCategoriesOptions" class="payment-dropdown"
                                        @input="setFormValue('booking_category', $event.value,)"
                                        :default="newBookingForm.booking_category" 
                                        :disabled="formDisabled" /> 
                                </div>
                                <div class="margin-top-20 margin-bottom-10">
                                    <PadelTextarea :label="t('notes')" :value="newBookingForm.notes"
                                        :maxLength="textMaxLength" @input="setFormValue('notes', $event)" />
                                </div>
                            
                                <RepeatBooking 
                                    :newBookingForm="newBookingForm"
                                    :newBookingErrors="newBookingErrors"
                                    :withEndTime="withEndTime" />
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <i class="material-icons" :class="{'color-blue': selectedTab === 1}">person_add</i>
                                <span>{{uppercase(t('new_user'))}}</span>
                            </template>
                            <div class="main-form-container margin-top-20 margin-bottom-20 flexbox-column">
                                <div class="form-row flexbox-row justify-space-between">
                                    <PadelInput :label="t('name') + '*'" :hideError="hideError"
                                        :value="newBookingForm.user_name" @input="setFormValue('user_name', $event)" />
                                    <PadelInput :label="t('surname') + '*'" :hideError="hideError"
                                        class="subtract-10-small-screen" :value="newBookingForm.user_last_name"
                                        @input="setFormValue('user_last_name', $event)" />
                                </div>
                                <div class="form-row flexbox-row justify-space-between">
                                    <div class="p-grid">
                                        <div class="p-col-4">
                                            <PadelInput :label="t('prefix') + '*'" 
                                                :hideError="hideError"
                                                :value="newBookingForm.phone_number_prefix" 
                                                @input="setFormValue('phone_number_prefix', $event)"
                                                forcedPrefix="+"
                                                :error="newBookingErrors.phone_number_prefix" />
                                        </div>
                                        <div class="p-col">
                                            <PadelInput :label="t('phonenumber') + '*'" 
                                                :hideError="hideError"
                                                :value="newBookingForm.user_phone_number" 
                                                :isPhonenumber="isPhone"
                                                @validate="handlePhoneValidation($event)"
                                                @input="setFormValue('user_phone_number', $event)"
                                                :error="newBookingErrors.user_phone_number" />
                                        </div>
                                    </div>
                                    <PadelInput :label="t('email') + '*'" :hideError="hideError"
                                        class="subtract-10-small-screen" :value="newBookingForm.user_email"
                                        :error="newBookingErrors.user_email"
                                        @validate="validateMail(newBookingForm, 'user_email')"
                                        @input="setFormValue('user_email', $event)" />
                                </div>
                                <div class="p-grid p-mb-4" style="margin-top: -2rem;"
                                    v-if="newBookingForm.certify_phone_number">
                                    <div class="p-col-12">
                                        <span class="p-text-muted">{{t('prefix mandatory hint')}}</span>
                                    </div>
                                </div>
                                <div class="form-row flexbox-row justify-space-between adjust-5 margin-bottom-20">
                                    <div class="calendar-element">
                                        <PadelCalendar v-if="selectedTab === 1" :label="t('game_date')"
                                            :value="newBookingForm.date" :minDate="new Date()"
                                            @input="validateHourToday('date', $event)" :error="newBookingErrors.date" />
                                    </div>
                                    <!--<PadelDropdown :label="t('playground_choice')" :options="playgroundOptions"
                                        @input="setFormValue('playground', $event.value)"
                                        :default="newBookingForm.playground" :isDropdownInModal="isModal" />-->
                                    <div class="p-grid">
                                        <div class="p-col-12 p-sm-6">
                                            <PadelDropdown @input="setFormValue('sport_type', $event.value)"
                                                :label="t('sport_choice')" :default="newBookingForm.sport_type"
                                                :options="mySports" :isDropdownInModal="isModal" />
                                        </div>
                                        <div class="p-col-12 p-sm-6">
                                            <PadelDropdown @input="setFormValue('playground', $event.value)"
                                                :label="t('playground_choice')" :default="newBookingForm.playground"
                                                :options="computedPlaygrounds" :isDropdownInModal="isModal" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row flexbox-row justify-space-between margin-top-20">
                                    <div style="margin-top: 26px;">
                                        <PadelTimepicker v-if="selectedTab === 1" 
                                            :label="t('start_hour')"
                                            :value="newBookingForm.start_at"
                                            @input="validateHourToday('start_at', $event)" 
                                            :from="openingHour"
                                            :to="closingHour" 
                                            :error="newBookingErrors.start_at" />
                                    </div>
                                    <div class="radio-container flexbox-column">
                                        <div class="flexbox-row p-mb-2">
                                            <PadelToggle 
                                                v-model:checked="withEndTime"
                                                @change="switchEndTime($event)"
                                                containerClass="p-d-inline-flex p-ml-2"
                                                labelClass="color-white overflow-inherit p-size-mini"
                                                :label="t('end_hour')" />
                                        </div>
                                        <div class="adjust-radio-spacing" style="margin-top: 10px;"
                                            v-if="!withEndTime">
                                            <PadelRadio :options="gameDurationOptions" :value="newBookingForm.game_duration"
                                                @input="validateHourToday('game_duration', $event)" :chipMode="true"  />
                                        </div>
                                        <PadelTimepicker 
                                            v-else
                                            :label="t('end_hour')"
                                            :value="newBookingForm.end_at"
                                            @input="validateHourToday('end_at', $event)" 
                                            :from="openingHour"
                                            :to="closingHour" 
                                            :error="newBookingErrors.end_at"
                                            containerClass="full-width" />
                                    </div>
                                </div>
                                <div class="form-row flexbox-row justify-space-between">
                                    <div class="p-mt-2 p-d-flex p-jc-between">
                                        <PadelInput :label="t('price_each_booking')" 
                                            :inputType="'number'" 
                                            :isPrice="true"
                                            :icon="'euro_symbol'" 
                                            :value="newBookingForm.price"
                                            @input="setFormValue('price', $event)" 
                                            :hideError="hideError"
                                            containerClass="full-width" />
                                        <span class="material-icons p-ml-2 p-mt-3 color-white cursor"
                                            v-if="!withEndTime"
                                            @click.prevent="syncPrice">
                                            sync
                                        </span>
                                    </div>
                                    <div class="p-mt-2">
                                        <PadelInput :label="t('taking')" 
                                            :inputType="'number'" 
                                            :isPrice="true"
                                            :icon="'euro_symbol'" 
                                            :value="newBookingForm.taking"
                                            @input="setFormValue('taking', $event)" 
                                            :hideError="hideError"
                                            containerClass="full-width" />
                                    </div>
                                </div>
                                <div class="form-row flexbox-row justify-space-between margin-bottom-10 align-flex-end">
                                    <PadelDropdown :label="t('payment_state')" :options="PaymentOptions"
                                        @input="setFormValue('payment_state', $event.value)" :default="newBookingForm.payment_state"
                                        :isDropdownInModal="isModal" />
                                    <PadelDropdown :label="t('color_choice')" :options="colorChoices"
                                        class="add-10-small-screen" @input="setFormValue('color', $event.value)"
                                        :default="newBookingForm.color" :isDropdownInModal="isModal" />
                                </div>
                                <div class="form-row flexbox-row justify-space-between margin-bottom-10 align-flex-end">
                                    <div class="certify-phone-number-container flexbox-row padding-12">
                                        <div class="color-white montserrat radio-title margin-right-10">
                                            {{t('verify_phone_number')}}</div>
                                        <PadelToggle v-model:checked="newBookingForm.certify_phone_number"
                                            @change="handleToggleCertify($event)" />
                                    </div>
                                </div>
                                 <div class="form-row flexbox-row justify-space-between margin-top-20">
                                    <!-- Se il centro è di tipo commerciale: è fisso commerciale -->
                                    <!-- se è di altra natura: dipente dal giocatore -->
                                    <PadelDropdown label="Tipologia prenotazione"
                                        :options="bookingCategoriesOptions" class="payment-dropdown"
                                        @input="setFormValue('booking_category', $event.value,)"
                                        :default="newBookingForm.booking_category" 
                                        :disabled="formDisabled" /> 
                                </div>
                                <div class="margin-top-20 margin-bottom-20">
                                    <PadelTextarea :label="t('notes')" :value="newBookingForm.notes"
                                        :maxLength="textMaxLength" @input="setFormValue('notes', $event)" />
                                </div>
                            
                                <RepeatBooking 
                                    :newBookingForm="newBookingForm"
                                    :newBookingErrors="newBookingErrors"
                                    :withEndTime="withEndTime" />
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <i class="material-icons" :class="{'color-blue': selectedTab === 2}">description</i>
                                <span>{{uppercase(t('description'))}}</span>
                            </template>
                            <div class="main-form-container margin-top-20 margin-bottom-20 flexbox-column">
                                <div class="">
                                    <PadelInput 
                                        :label="t('player_description') + '*'" 
                                        :hideError="hideError"
                                        :value="newBookingForm.player_description" 
                                        @input="setFormValue('player_description', $event)" />
                                </div>

                                <div class="form-row flexbox-row justify-space-between adjust-5 margin-bottom-20">
                                    <div class="calendar-element">
                                        <PadelCalendar 
                                            v-if="selectedTab === 2"
                                            :label="t('game_date')"
                                            :value="newBookingForm.date" 
                                            :minDate="new Date()"
                                            @input="validateHourToday('date', $event)" 
                                            :error="newBookingErrors.date" />
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-12 p-sm-6">
                                            <PadelDropdown 
                                                @input="setFormValue('sport_type', $event.value)"
                                                :label="t('sport_choice')" 
                                                :default="newBookingForm.sport_type"
                                                :options="mySports" 
                                                :isDropdownInModal="isModal" />
                                        </div>
                                        <div class="p-col-12 p-sm-6">
                                            <PadelDropdown 
                                                @input="setFormValue('playground', $event.value)"
                                                :label="t('playground_choice')" 
                                                :default="newBookingForm.playground"
                                                :options="computedPlaygrounds" 
                                                :isDropdownInModal="isModal" />
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row flexbox-row justify-space-between">
                                    <div style="margin-top: 26px;">
                                        <PadelTimepicker 
                                            v-if="selectedTab === 2" 
                                            :label="t('start_hour')"
                                            :value="newBookingForm.start_at"
                                            @input="validateHourToday('start_at', $event)" 
                                            :from="openingHour"
                                            :to="closingHour" 
                                            :error="newBookingErrors.start_at" />
                                    </div>
                                    <div class="radio-container flexbox-column">
                                        <div class="flexbox-row p-mb-2">
                                            <PadelToggle 
                                                v-model:checked="withEndTime"
                                                @change="switchEndTime($event)"
                                                containerClass="p-d-inline-flex p-ml-2"
                                                labelClass="color-white overflow-inherit p-size-mini"
                                                :label="t('end_hour')" />
                                        </div>
                                        <div class="adjust-radio-spacing" style="margin-top: 10px;"
                                            v-if="!withEndTime">
                                            <PadelRadio 
                                                :options="gameDurationOptions" 
                                                :value="newBookingForm.game_duration"
                                                @input="validateHourToday('game_duration', $event)" 
                                                :chipMode="true"  />
                                        </div>
                                        <PadelTimepicker 
                                            v-else
                                            :label="t('end_hour')"
                                            :value="newBookingForm.end_at"
                                            @input="validateHourToday('end_at', $event)" 
                                            :from="openingHour"
                                            :to="closingHour" 
                                            :error="newBookingErrors.end_at"
                                            containerClass="full-width" />
                                    </div>
                                </div>
                                <div class="form-row flexbox-row justify-space-between">
                                    <div class="p-mt-2 p-d-flex p-jc-between">
                                        <PadelInput :label="t('price_each_booking')" 
                                            :inputType="'number'" 
                                            :isPrice="true"
                                            :icon="'euro_symbol'" 
                                            :value="newBookingForm.price"
                                            @input="setFormValue('price', $event)" 
                                            :hideError="hideError"
                                            containerClass="full-width" />
                                        <span class="material-icons p-ml-2 p-mt-3 color-white cursor"
                                            v-if="!withEndTime"
                                            @click.prevent="syncPrice">
                                            sync
                                        </span>
                                    </div>
                                    <div class="p-mt-2">
                                        <PadelInput :label="t('taking')" 
                                            :inputType="'number'" 
                                            :isPrice="true"
                                            :icon="'euro_symbol'" 
                                            :value="newBookingForm.taking"
                                            @input="setFormValue('taking', $event)" 
                                            :hideError="hideError"
                                            containerClass="full-width" />
                                    </div>
                                </div>
                                <div class="form-row flexbox-row justify-space-between margin-bottom-10 align-flex-end margin-negative-10">
                                    <PadelDropdown 
                                        :label="t('payment_state')" 
                                        :options="PaymentOptions"
                                        @input="setFormValue('payment_state', $event.value)" 
                                        :default="newBookingForm.payment_state"
                                        :isDropdownInModal="isModal" />
                                    <PadelDropdown 
                                        :label="t('color_choice')" 
                                        :options="colorChoices"
                                        class="add-10-small-screen" 
                                        @input="setFormValue('color', $event.value)"
                                        :default="newBookingForm.color" 
                                        :isDropdownInModal="isModal" />
                                </div>

                                 <div class="form-row flexbox-row justify-space-between margin-top-20">
                                    <!-- Se il centro è di tipo commerciale: è fisso commerciale -->
                                    <!-- se è di altra natura: dipente dal giocatore -->
                                    <PadelDropdown label="Tipologia prenotazione"
                                        :options="bookingCategoriesOptions" class="payment-dropdown"
                                        @input="setFormValue('booking_category', $event.value,)"
                                        :default="newBookingForm.booking_category" 
                                        :disabled="formDisabled" /> 
                                </div>

                                <div class="margin-top-20 margin-bottom-20">
                                    <PadelTextarea 
                                        :label="t('notes')" 
                                        :value="newBookingForm.notes"
                                        :maxLength="textMaxLength" 
                                        @input="setFormValue('notes', $event)" />
                                </div>
                            
                                <RepeatBooking 
                                    :newBookingForm="newBookingForm"
                                    :newBookingErrors="newBookingErrors"
                                    :withEndTime="withEndTime" />
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
                <div v-else-if="selectedBookingType === 'LESSON'" class="form lesson-form">
                    <TabView class="tab-view" :activeIndex="selectedTab" @tab-change="onChangeTab($event.index)">
                        <TabPanel>
                            <template #header>
                                <i class="material-icons" :class="{'color-blue': selectedTab === 0}">how_to_reg</i>
                                <span>{{uppercase(t('registered_user'))}}</span>
                            </template>
                            <div class="registered-container flexbox-column margin-top-20">
                                <PadelSearch class="search-element" :label="t('search_user')" :value="searchPlaceholder"
                                    @onEnterPressed="searchUsers($event)" :options="searchOptions"
                                    @onClosePressed="handleSearchClose()" :error="newBookingErrors.wesmash_user"
                                    @valueClicked="searchValueSelected($event)" @validate="onSearchValidate($event)" />
                                <PadelInput :label="t('teacher_name')" :value="newBookingForm.teacher_name"
                                    @input="setFormValue('teacher_name', $event)"
                                    class="subtract-10-small-screen-spacing" />
                                <div class="form-row flexbox-row justify-space-between lesson-registered-first-row">
                                    <div class="calendar-element">
                                        <PadelCalendar v-if="selectedTab === 0" :label="t('lesson_date')"
                                            :value="newBookingForm.date" :minDate="new Date()"
                                            @input="validateHourToday('date', $event)" :error="newBookingErrors.date" />
                                    </div>
                                    <div class="p-grid">
                                        <div class="p-col-12 p-sm-6">
                                            <PadelDropdown @input="setFormValue('sport_type', $event.value)"
                                                :label="t('sport_choice')" :default="newBookingForm.sport_type"
                                                :options="mySports" :isDropdownInModal="isModal" />
                                        </div>
                                        <div class="p-col-12 p-sm-6">
                                            <PadelDropdown @input="setFormValue('playground', $event.value)"
                                                :label="t('playground_choice')" :default="newBookingForm.playground"
                                                :options="computedPlaygrounds" :isDropdownInModal="isModal" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form-row flexbox-row justify-space-between margin-top-20 margin-bottom-20">
                                    <div style="margin-top: 26px;">
                                        <PadelTimepicker v-if="selectedTab === 0" :label="t('start_hour')"
                                            :value="newBookingForm.start_at"
                                            @input="validateHourToday('start_at', $event)" :from="openingHour"
                                            :to="closingHour" :error="newBookingErrors.start_at" />
                                    </div>

                                    <div class="radio-container flexbox-column adjust-radio-spacing">
                                        <div class="flexbox-row p-mb-2">
                                            <PadelToggle 
                                                v-model:checked="withEndTime"
                                                @change="switchEndTime($event)"
                                                containerClass="p-d-inline-flex p-ml-2"
                                                labelClass="color-white overflow-inherit p-size-mini"
                                                :label="t('end_hour')" />
                                        </div>
                                        <div class="adjust-radio-spacing" style="margin-top: 10px;"
                                            v-if="!withEndTime">
                                            <PadelRadio :options="gameDurationOptions" :value="newBookingForm.game_duration"
                                                @input="validateHourToday('game_duration', $event)" :chipMode="true"  />
                                        </div>
                                        <PadelTimepicker v-else
                                            :label="t('end_hour')"
                                            :value="newBookingForm.end_at"
                                            @input="validateHourToday('end_at', $event)" 
                                            :from="openingHour"
                                            :to="closingHour" 
                                            :error="newBookingErrors.end_at"
                                            containerClass="full-width" />
                                    </div>
                                </div>
                                <div class="form-row flexbox-row justify-space-between">
                                    <div class="margin-top-10 p-d-flex p-jc-between">
                                        <PadelInput :label="t('price_each_booking')" 
                                            :inputType="'number'" 
                                            :isPrice="true"
                                            :icon="'euro_symbol'" 
                                            :value="newBookingForm.price"
                                            @input="setFormValue('price', $event)" 
                                            :hideError="hideError"
                                            containerClass="full-width" />
                                        <span class="material-icons p-ml-2 p-mt-3 color-white cursor"
                                            v-if="!withEndTime"
                                            @click.prevent="syncPrice">
                                            sync
                                        </span>
                                        <!--<PadelInput :label="t('price')" :inputType="'number'" :isPrice="true"
                                            :icon="'euro_symbol'" :value="newBookingForm.price"
                                            @input="setFormValue('price', $event)" :hideError="hideError"
                                            class="subtract-10-small-screen-spacing" />-->
                                    </div>
                                    <div class="margin-top-10">
                                        <PadelInput :label="t('taking')" 
                                            :inputType="'number'" 
                                            :isPrice="true"
                                            :icon="'euro_symbol'" 
                                            :value="newBookingForm.taking"
                                            @input="setFormValue('taking', $event)" 
                                            :hideError="hideError"
                                            containerClass="full-width" />
                                    </div>
                                </div>
                                <div class="form-row flexbox-row justify-space-between">
                                    <PadelDropdown :label="t('payment_state')" :options="PaymentOptions"
                                        @input="setFormValue('payment_state', $event.value)" :default="newBookingForm.payment_state"
                                        :isDropdownInModal="isModal" />
                                    <PadelDropdown :label="t('color_choice')" :options="colorChoices"
                                        class="add-10-small-screen" @input="setFormValue('color', $event.value)"
                                        :default="newBookingForm.color" :isDropdownInModal="isModal" />
                                </div>
                                 <div class="form-row flexbox-row justify-space-between margin-top-20">
                                    <!-- Se il centro è di tipo commerciale: è fisso commerciale -->
                                    <!-- se è di altra natura: dipente dal giocatore -->
                                    <PadelDropdown label="Tipologia prenotazione"
                                        :options="bookingCategoriesOptions" class="payment-dropdown"
                                        @input="setFormValue('booking_category', $event.value,)"
                                        :default="newBookingForm.booking_category" 
                                        :disabled="formDisabled" /> 
                                </div>
                                <div class="margin-top-20 margin-bottom-20">
                                    <PadelTextarea :label="t('notes')" :value="newBookingForm.notes"
                                        :maxLength="textMaxLength" @input="setFormValue('notes', $event)" />
                                </div>
                            
                                <RepeatBooking 
                                    :newBookingForm="newBookingForm"
                                    :newBookingErrors="newBookingErrors"
                                    :withEndTime="withEndTime" />
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <template #header>
                                <i class="material-icons" :class="{'color-blue': selectedTab === 1}">person_add</i>
                                <span>{{uppercase(t('new_user'))}}</span>
                            </template>
                            <div class="main-form-container margin-top-20 margin-bottom-20 flexbox-column">
                                <div class="form-row flexbox-row justify-space-between">
                                    <PadelInput :label="t('name') + '*'" :hideError="hideError"
                                        :value="newBookingForm.user_name" @input="setFormValue('user_name', $event)" />
                                    <PadelInput :label="t('surname') + '*'" :hideError="hideError"
                                        class="subtract-10-small-screen" :value="newBookingForm.user_last_name"
                                        @input="setFormValue('user_last_name', $event)" />
                                </div>
                                <div class="form-row flexbox-row justify-space-between">
                                    <PadelInput :label="t('phonenumber') + '*'" :hideError="hideError"
                                        :value="newBookingForm.user_phone_number" :isPhonenumber="isPhone"
                                        :forcedPrefix="newBookingForm.certify_phone_number ? FIXED_PHONE_PREFIX : null"
                                        :error="newBookingErrors.user_phone_number"
                                        @validate="handlePhoneValidation($event)"
                                        @input="setFormValue('user_phone_number', $event)" />
                                    <PadelInput :label="t('email') + '*'" :hideError="hideError"
                                        class="subtract-10-small-screen" :error="newBookingErrors.user_email"
                                        @validate="validateMail(newBookingForm, 'user_email')"
                                        :value="newBookingForm.user_email"
                                        @input="setFormValue('user_email', $event)" />
                                </div>
                                <div class="form-row flexbox-row justify-space-between">
                                    <PadelInput :label="t('teacher_name')" :hideError="hideError"
                                        :value="newBookingForm.teacher_name"
                                        @input="setFormValue('teacher_name', $event)"
                                        class="subtract-10-small-screen-spacing" />
                                    <div class="calendar-element next-to-input subtract-10-small-screen">
                                        <PadelCalendar v-if="selectedTab === 1" :label="t('lesson_date')"
                                            :value="newBookingForm.date" :minDate="new Date()"
                                            @input="validateHourToday('date', $event)" :error="newBookingErrors.date" />
                                    </div>

                                </div>
                                <div class="form-row flexbox-row justify-space-between">
                                    <div class="dropdown-container">
                                        <PadelDropdown @input="setFormValue('sport_type', $event.value)"
                                            :label="t('sport_choice')" :default="newBookingForm.sport_type"
                                            :options="mySports" :isDropdownInModal="isModal" />
                                    </div>
                                    <div class="dropdown-container">
                                        <PadelDropdown :label="t('playground_choice')" :options="computedPlaygrounds"
                                            @input="setFormValue('playground', $event.value)"
                                            :default="newBookingForm.playground" :isDropdownInModal="isModal" />
                                    </div>
                                </div>
                                <div class="form-row flexbox-row justify-space-between margin-top-20 margin-bottom-20">
                                    <div style="margin-top: 26px;">
                                        <PadelTimepicker v-if="selectedTab === 1" :label="t('start_hour')"
                                            :value="newBookingForm.start_at"
                                            @input="validateHourToday('start_at', $event)" :from="openingHour"
                                            :to="closingHour" :error="newBookingErrors.start_at" />
                                    </div>

                                    <div class="radio-container flexbox-column adjust-radio-spacing">
                                        <div class="flexbox-row p-mb-2">
                                            <PadelToggle 
                                                v-model:checked="withEndTime"
                                                @change="switchEndTime($event)"
                                                containerClass="p-d-inline-flex p-ml-2"
                                                labelClass="color-white overflow-inherit p-size-mini"
                                                :label="t('end_hour')" />
                                        </div>
                                        <div class="adjust-radio-spacing" style="margin-top: 10px;"
                                            v-if="!withEndTime">
                                            <PadelRadio :options="gameDurationOptions" :value="newBookingForm.game_duration"
                                                @input="validateHourToday('game_duration', $event)" :chipMode="true"  />
                                        </div>
                                        <PadelTimepicker v-else
                                            :label="t('end_hour')"
                                            :value="newBookingForm.end_at"
                                            @input="validateHourToday('end_at', $event)" 
                                            :from="openingHour"
                                            :to="closingHour" 
                                            :error="newBookingErrors.end_at"
                                            containerClass="full-width" />
                                    </div>
                                </div>
                                <div class="form-row flexbox-row justify-space-between">
                                    <div class="p-mt-2 p-d-flex p-jc-between">
                                        <PadelInput :label="t('price_each_booking')" 
                                            :inputType="'number'" 
                                            :isPrice="true"
                                            :icon="'euro_symbol'" 
                                            :value="newBookingForm.price"
                                            @input="setFormValue('price', $event)" 
                                            :hideError="hideError"
                                            containerClass="full-width" />
                                        <span class="material-icons p-ml-2 p-mt-3 color-white cursor"
                                            v-if="!withEndTime"
                                            @click.prevent="syncPrice">
                                            sync
                                        </span>
                                    </div>
                                    <div class="margin-top-10">
                                        <PadelInput :label="t('taking')" 
                                            :inputType="'number'" 
                                            :isPrice="true"
                                            :icon="'euro_symbol'" 
                                            :value="newBookingForm.taking"
                                            @input="setFormValue('taking', $event)" 
                                            :hideError="hideError"
                                            containerClass="full-width" />
                                    </div>
                                </div>
                                <div class="form-row flexbox-row justify-space-between margin-bottom-10 align-flex-end">
                                    <PadelDropdown :label="t('payment_state')" :options="PaymentOptions"
                                        @input="setFormValue('payment_state', $event.value)" :default="newBookingForm.payment_state"
                                        :isDropdownInModal="isModal" />
                                    <PadelDropdown :label="t('color_choice')" :options="colorChoices"
                                        class="add-10-small-screen" @input="setFormValue('color', $event.value)"
                                        :default="newBookingForm.color" :isDropdownInModal="isModal" />
                                </div>
                                <div class="form-row flexbox-row justify-space-between margin-bottom-10 align-flex-end">
                                    <div class="certify-phone-number-container flexbox-row padding-12">
                                        <div class="color-white montserrat radio-title margin-right-10">
                                            {{t('verify_phone_number')}}</div>
                                        <PadelToggle v-model:checked="newBookingForm.certify_phone_number"
                                            @change="handleToggleCertify($event)" />
                                    </div>
                                </div>
                                 <div class="form-row flexbox-row justify-space-between margin-top-20">
                                    <!-- Se il centro è di tipo commerciale: è fisso commerciale -->
                                    <!-- se è di altra natura: dipente dal giocatore -->
                                    <PadelDropdown label="Tipologia prenotazione"
                                        :options="bookingCategoriesOptions" class="payment-dropdown"
                                        @input="setFormValue('booking_category', $event.value,)"
                                        :default="newBookingForm.booking_category" 
                                        :disabled="formDisabled" /> 
                                </div>
                                <div class="margin-top-20 margin-bottom-20">
                                    <PadelTextarea :label="t('notes')" :value="newBookingForm.notes"
                                        :maxLength="textMaxLength" @input="setFormValue('notes', $event)" />
                                </div>
                            
                                <RepeatBooking 
                                    :newBookingForm="newBookingForm"
                                    :newBookingErrors="newBookingErrors"
                                    :withEndTime="withEndTime" />
                            </div>
                        </TabPanel>
                        
                        <TabPanel>
                            <template #header>
                                <i class="material-icons" :class="{'color-blue': selectedTab === 2}">description</i>
                                <span>{{uppercase(t('description'))}}</span>
                            </template>
                            <div class="main-form-container margin-top-20 margin-bottom-20 flexbox-column">
                                <div class="">
                                    <PadelInput 
                                        :label="t('player_description') + '*'" 
                                        :hideError="hideError"
                                        :value="newBookingForm.player_description" 
                                        @input="setFormValue('player_description', $event)" />
                                </div>

                                <div class="form-row flexbox-row justify-space-between">
                                    <PadelInput :label="t('teacher_name')" 
                                        :hideError="hideError"
                                        :value="newBookingForm.teacher_name"
                                        @input="setFormValue('teacher_name', $event)"
                                        class="subtract-10-small-screen-spacing" />
                                    <div class="calendar-element next-to-input subtract-10-small-screen">
                                        <PadelCalendar 
                                            v-if="selectedTab === 2" 
                                            :label="t('lesson_date')"
                                            :value="newBookingForm.date" 
                                            :minDate="new Date()"
                                            @input="validateHourToday('date', $event)" 
                                            :error="newBookingErrors.date" />
                                    </div>
                                </div>

                                <div class="form-row flexbox-row justify-space-between">
                                    <div class="dropdown-container">
                                        <PadelDropdown 
                                            @input="setFormValue('sport_type', $event.value)"
                                            :label="t('sport_choice')" 
                                            :default="newBookingForm.sport_type"
                                            :options="mySports" 
                                            :isDropdownInModal="isModal" />
                                    </div>

                                    <div class="dropdown-container">
                                        <PadelDropdown 
                                            :label="t('playground_choice')" 
                                            :options="computedPlaygrounds"
                                            @input="setFormValue('playground', $event.value)"
                                            :default="newBookingForm.playground" 
                                            :isDropdownInModal="isModal" />
                                    </div>
                                </div>

                                <div class="form-row flexbox-row justify-space-between margin-top-20 margin-bottom-20">
                                    <div style="margin-top: 26px;">
                                        <PadelTimepicker 
                                            v-if="selectedTab === 2" 
                                            :label="t('start_hour')"
                                            :value="newBookingForm.start_at"
                                            @input="validateHourToday('start_at', $event)" 
                                            :from="openingHour"
                                            :to="closingHour" 
                                            :error="newBookingErrors.start_at" />
                                    </div>

                                    <div class="radio-container flexbox-column adjust-radio-spacing">
                                        <div class="flexbox-row p-mb-2">
                                            <PadelToggle 
                                                v-model:checked="withEndTime"
                                                @change="switchEndTime($event)"
                                                containerClass="p-d-inline-flex p-ml-2"
                                                labelClass="color-white overflow-inherit p-size-mini"
                                                :label="t('end_hour')" />
                                        </div>
                                        <div class="adjust-radio-spacing" style="margin-top: 10px;"
                                            v-if="!withEndTime">
                                            <PadelRadio 
                                                :options="gameDurationOptions" 
                                                :value="newBookingForm.game_duration"
                                                @input="validateHourToday('game_duration', $event)" 
                                                :chipMode="true"  />
                                        </div>
                                        <PadelTimepicker 
                                            v-else
                                            :label="t('end_hour')"
                                            :value="newBookingForm.end_at"
                                            @input="validateHourToday('end_at', $event)" 
                                            :from="openingHour"
                                            :to="closingHour" 
                                            :error="newBookingErrors.end_at"
                                            containerClass="full-width" />
                                    </div>
                                </div>
                                <div class="form-row flexbox-row justify-space-between">
                                    <div class="p-mt-2 p-d-flex p-jc-between">
                                        <PadelInput :label="t('price_each_booking')" 
                                            :inputType="'number'" 
                                            :isPrice="true"
                                            :icon="'euro_symbol'" 
                                            :value="newBookingForm.price"
                                            @input="setFormValue('price', $event)" 
                                            :hideError="hideError"
                                            containerClass="full-width" />
                                        <span class="material-icons p-ml-2 p-mt-3 color-white cursor"
                                            v-if="!withEndTime"
                                            @click.prevent="syncPrice">
                                            sync
                                        </span>
                                    </div>
                                    <div class="p-mt-2">
                                        <PadelInput :label="t('taking')" 
                                            :inputType="'number'" 
                                            :isPrice="true"
                                            :icon="'euro_symbol'" 
                                            :value="newBookingForm.taking"
                                            @input="setFormValue('taking', $event)" 
                                            :hideError="hideError"
                                            containerClass="full-width" />
                                    </div>
                                </div>
                                <div class="form-row flexbox-row justify-space-between margin-bottom-10 align-flex-end margin-negative-10">
                                    <PadelDropdown 
                                        :label="t('payment_state')" 
                                        :options="PaymentOptions"
                                        @input="setFormValue('payment_state', $event.value)" 
                                        :default="newBookingForm.payment_state"
                                        :isDropdownInModal="isModal" />
                                    <PadelDropdown 
                                        :label="t('color_choice')" 
                                        :options="colorChoices"
                                        class="add-10-small-screen" 
                                        @input="setFormValue('color', $event.value)"
                                        :default="newBookingForm.color" 
                                        :isDropdownInModal="isModal" />
                                </div>
                                 <div class="form-row flexbox-row justify-space-between margin-top-20">
                                    <!-- Se il centro è di tipo commerciale: è fisso commerciale -->
                                    <!-- se è di altra natura: dipente dal giocatore -->
                                    <PadelDropdown label="Tipologia prenotazione"
                                        :options="bookingCategoriesOptions" class="payment-dropdown"
                                        @input="setFormValue('booking_category', $event.value,)"
                                        :default="newBookingForm.booking_category" 
                                        :disabled="formDisabled" /> 
                                </div>
                                <div class="margin-top-20 margin-bottom-20">
                                    <PadelTextarea 
                                        :label="t('notes')" 
                                        :value="newBookingForm.notes"
                                        :maxLength="textMaxLength" 
                                        @input="setFormValue('notes', $event)" />
                                </div>
                            
                                <RepeatBooking 
                                    :newBookingForm="newBookingForm"
                                    :newBookingErrors="newBookingErrors"
                                    :withEndTime="withEndTime" />
                            </div>
                        </TabPanel>
                    </TabView>
                </div>
                <div v-else-if="selectedBookingType === 'BACKOFFICE_EVENT'" class="form event-form">

                    <div class="form-row flexbox-row justify-space-between">
                        <PadelCalendar v-if="selectedBookingType === 'BACKOFFICE_EVENT'" :label="t('start_event_date')"
                            class="start-date-calendar" :minDate="new Date()"
                            @input="updateFormDateValue('start_date', $event)" :value="newBookingForm.start_date"
                            :error="newBookingErrors.start_date" />
                        <PadelCalendar v-if="selectedBookingType === 'BACKOFFICE_EVENT'" :label="t('end_event_date')"
                            :value="newBookingForm.end_date" :minDate="new Date()"
                            @input="updateFormDateValue('end_date', $event)" :error="newBookingErrors.end_date" />
                    </div>
                    <div class="form-row flexbox-row justify-space-between margin-top-10">
                        <PadelTimepicker 
                            v-if="selectedBookingType === 'BACKOFFICE_EVENT'" 
                            :label="t('start_hour')"
                            :value="newBookingForm.start_at" 
                            @input="updateFormTimeValue('start_at', $event)"
                            :from="openingHour" 
                            :to="closingHour" 
                            :error="newBookingErrors.start_at" />
                        <PadelTimepicker 
                            v-if="selectedBookingType === 'BACKOFFICE_EVENT'" 
                            :label="t('end_hour')"
                            :value="newBookingForm.end_at" 
                            @input="updateFormTimeValue('end_at', $event)"
                            :from="openingHour" 
                            :to="closingHour" 
                            :error="newBookingErrors.end_at" />
                    </div>
                    <div class="form-row flexbox-row justify-space-between margin-top-10">
                        <div class="p-grid">
                            <div class="p-col-12 p-sm-6">
                                <PadelDropdown 
                                    @input="setFormValue('sport_type', $event.value)"
                                    :label="t('sport_choice')" 
                                    :default="newBookingForm.sport_type"
                                    :options="mySports" 
                                    :isDropdownInModal="isModal" />
                            </div>
                            <div class="p-col-12 p-sm-6">
                                <PadelDropdown @input="setFormValue('playground', $event.value)"
                                    :label="t('playground_choice')" 
                                    :default="newBookingForm.playground"
                                    :options="computedPlaygrounds" 
                                    :isDropdownInModal="isModal" />
                            </div>
                        </div>
                        <div class="">
                            <PadelDropdown :label="t('color_choice')" 
                                :options="colorChoices"
                                class="add-10-small-screen" 
                                @input="setFormValue('color', $event.value)"
                                :default="newBookingForm.color" 
                                :isDropdownInModal="isModal" />
                        </div>
                    </div>

                    <div class="form-row flexbox-row">
                        <div class="margin-top-10">
                            <PadelDropdown :label="t('event_type')" 
                                :options="eventTypeOptions"
                                class="add-10-small-screen" 
                                @input="setFormValue('event_type', $event.value)"
                                :default="newBookingForm.event_type" 
                                :isDropdownInModal="isModal" />
                        </div>
                    </div>

                    <div class="margin-top-20 margin-bottom-20">
                        <PadelTextarea :label="t('notes')" 
                            :value="newBookingForm.notes"
                            :maxLength="textMaxLength" 
                            @input="setFormValue('notes', $event)" />
                    </div>
                            
                    <RepeatBooking 
                        :newBookingForm="newBookingForm"
                        :newBookingErrors="newBookingErrors"
                        :withEndTime="withEndTime" />

                </div>
                <div v-else-if="selectedBookingType === 'MAINTENANCE'" class="form mantainance-form">
                    <div class="form-row flexbox-row justify-space-between">
                        <PadelCalendar v-if="selectedBookingType === 'MAINTENANCE'" :label="t('start_mantainance_date')"
                            class="start-date-calendar" :minDate="new Date()"
                            @input="updateFormDateValue('start_date', $event)" :value="newBookingForm.start_date"
                            :error="newBookingErrors.start_date" />
                        <PadelCalendar v-if="selectedBookingType === 'MAINTENANCE'" :label="t('end_mantainance_date')"
                            :value="newBookingForm.end_date" :minDate="new Date()"
                            @input="updateFormDateValue('end_date', $event)" :error="newBookingErrors.end_date" />
                    </div>
                    <div class="form-row flexbox-row justify-space-between margin-top-10">
                        <PadelTimepicker v-if="selectedBookingType === 'MAINTENANCE'" :label="t('start_hour')"
                            :value="newBookingForm.start_at" @input="updateFormTimeValue('start_at', $event)"
                            :from="openingHour" :to="closingHour" :error="newBookingErrors.start_at" />
                        <PadelTimepicker v-if="selectedBookingType === 'MAINTENANCE'" :label="t('end_hour')"
                            :value="newBookingForm.end_at" @input="updateFormTimeValue('end_at', $event)"
                            :from="openingHour" :to="closingHour" :error="newBookingErrors.end_at" />
                    </div>
                    <div class="form-row flexbox-row justify-space-between margin-top-10">
                        <div class="certify-phone-number-container flexbox-row">
                            <div class="color-white montserrat radio-title margin-right-10 align-to-dropdown">
                                {{t('all_playgrounds')}}</div>
                            <PadelToggle v-model:checked="newBookingForm.apply_all_playground"
                                @change="onToggleChange($event)" />
                        </div>
                        <div class="dropdown-container">
                            <PadelDropdown :label="t('playground_choice')" :options="playgroundOptions"
                                @input="setFormValue('playground', $event.value)" :default="newBookingForm.playground"
                                :disabled="newBookingForm.apply_all_playground" :isDropdownInModal="isModal" />
                        </div>
                    </div>
                    <div class="form-row flexbox-row">
                        <div class="margin-top-10">
                            <PadelDropdown :label="t('color_choice')" :options="colorChoices"
                                class="add-10-small-screen" @input="setFormValue('color', $event.value)"
                                :default="newBookingForm.color" :isDropdownInModal="isModal" />
                        </div>
                    </div>
                    <div class="margin-top-20 margin-bottom-20">
                        <PadelTextarea :label="t('mantainance_description')" :value="newBookingForm.notes"
                            :maxLength="textMaxLength" @input="setFormValue('notes', $event)" />
                    </div>

                </div>
                <div v-else-if="selectedBookingType === 'CLOSING'" class="form closure-form">
                    <div class="form-row flexbox-row justify-space-between">
                        <PadelCalendar v-if="selectedBookingType === 'CLOSING'" :label="t('start_closing_date')"
                            class="start-date-calendar" :minDate="new Date()"
                            @input="updateFormDateValue('start_date', $event)" :value="newBookingForm.start_date"
                            :error="newBookingErrors.start_date" />
                        <PadelCalendar v-if="selectedBookingType === 'CLOSING'" :label="t('end_closing_date')"
                            :value="newBookingForm.end_date" :minDate="new Date()"
                            @input="updateFormDateValue('end_date', $event)" :error="newBookingErrors.end_date" />
                    </div>
                    <div class="form-row flexbox-row justify-space-between">
                        <div class="certify-phone-number-container flexbox-row">
                            <div class="color-white montserrat radio-title margin-right-10 align-to-dropdown">
                                {{t('all_playgrounds')}}</div>
                            <PadelToggle v-model:checked="newBookingForm.apply_all_playground"
                                @change="onToggleChange($event)" />
                        </div>
                        <PadelDropdown :label="t('playground_choice')" :options="playgroundOptions"
                            @input="setFormValue('playground', $event.value)" :default="newBookingForm.playground"
                            :disabled="newBookingForm.apply_all_playground" :isDropdownInModal="isModal" />
                    </div>
                    <div class="form-row flexbox-row">
                        <div class="margin-top-10">

                            <PadelDropdown :label="t('color_choice')" :options="colorChoices"
                                class="add-10-small-screen" @input="setFormValue('color', $event.value)"
                                :default="newBookingForm.color" :isDropdownInModal="isModal" />
                        </div>
                    </div>
                    <div class="margin-top-20 margin-bottom-20">
                        <PadelTextarea :label="t('closing_description')" :value="newBookingForm.notes"
                            :maxLength="textMaxLength" @input="setFormValue('notes', $event)" />
                    </div>

                </div>
                <div v-else-if="selectedBookingType === 'OTHER'" class="form other-form flexbox-column">
                    <div class="form-row flexbox-row justify-space-between">
                        <PadelCalendar v-if="selectedBookingType === 'OTHER'" :label="t('start_date')"
                            :minDate="new Date()" :value="newBookingForm.start_date" class="start-date-calendar"
                            @input="updateFormDateValue('start_date', $event)" :error="newBookingErrors.start_date" />
                        <PadelCalendar v-if="selectedBookingType === 'OTHER'" :label="t('end_date')"
                            :minDate="new Date()" :value="newBookingForm.end_date"
                            @input="updateFormDateValue('end_date', $event)" :error="newBookingErrors.end_date" />
                    </div>
                    <div class="form-row flexbox-row justify-space-between margin-top-10">
                        <PadelTimepicker v-if="selectedBookingType === 'OTHER'" :label="t('start_hour')"
                            :value="newBookingForm.start_at" @input="updateFormTimeValue('start_at', $event)"
                            :from="openingHour" :to="closingHour" :error="newBookingErrors.start_at" />
                        <PadelTimepicker v-if="selectedBookingType === 'OTHER'" :label="t('end_hour')"
                            :value="newBookingForm.end_at" @input="updateFormTimeValue('end_at', $event)"
                            :from="openingHour" :to="closingHour" :error="newBookingErrors.end_at" />
                    </div>
                    <div class="form-row flexbox-row justify-space-between margin-top-5">
                        <PadelDropdown :label="t('playground_choice')" :options="playgroundOptions"
                            @input="setFormValue('playground', $event.value)" :default="newBookingForm.playground"
                            :isDropdownInModal="isModal" />
                        <PadelDropdown :label="t('color_choice')" :options="colorChoices" class="add-10-small-screen"
                            @input="setFormValue('color', $event.value)" :default="newBookingForm.color"
                            :isDropdownInModal="isModal" />
                    </div>
                    <div class="margin-top-20 margin-bottom-20">
                        <PadelTextarea :label="t('notes')" :value="newBookingForm.notes" :maxLength="textMaxLength"
                            @input="setFormValue('notes', $event)" />
                    </div>
                </div>
                <div v-else-if="selectedBookingType === 'OPEN_BOOKING'" class="form open-form">
                    <OpenBookingForm 
                        :bookingForm="newBookingForm"
                        :bookingFormErrors="newBookingErrors"
                        @hourValue="(key, value) => { validateHourToday(key, value) }"
                        @formValue="(key, value) => { setFormValue(key, value) }"
                        @switchEndTime="switchEndTime" />
                </div>
            </div>

        </div>
        <div class="button-row flexbox-row justify-space-between">
            <p class="abort" @click="hideModal()">{{uppercase(t('abort'))}}</p>
            <PadelButton :label="uppercase(t('create'))" :type="'primary'" @click="checkConfirm()" :disabled="isNewBookingFormEmpty(computedBookingType, newBookingForm) ||
                !isFormBookingValid(newBookingErrors)"></PadelButton>
        </div>
    </DumbModal>
</div>

<div v-if="modalOpened === 'CONFIRM_PHONE_NUMBER'">
    <DumbModal :title="t('verify_phone_number_title')" @hide="hideConfirmPhoneModal()">
        <div class="top-part flexbox-column align-center margin-top-20">
            <div class="color-white line-height-text montserrat">{{t('verify_phone_number_text')}}
            </div>
            <div class="otp-text color-gold barlow margin-top-20">{{otp}}</div>

        </div>
        <div class="button-row flexbox-row justify-space-between">
            <p class="abort" @click="hideConfirmPhoneModal()">{{uppercase(t('abort'))}}</p>
            <div class="flexbox-row">
                <PadelButton :label="uppercase(t('send_again'))" :type="'secondary'" @click="sendPhoneCode()"
                    class="margin-right-20"></PadelButton>
                <PadelButton :label="uppercase(t('confirm'))" :type="'primary'" @click="createBooking()"></PadelButton>
            </div>

        </div>
    </DumbModal>
</div>