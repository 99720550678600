
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'PadelToggle',
    inheritAttrs: false,
    emits: ['update:checked', 'change'],
    props: {
      label: { type: String, required: false, default: '' },
      checked: { type: Boolean, required: true },
      labelClass: { type: String, required: false, default: '' },
      containerClass: { type: String, required: false, default: '' },
      disabled: { type: Boolean, required: false, default: false },
    },
    methods: { 
      onChange(event) {
        this.$emit('update:checked', event);
        this.$emit('change', event);
      },
      fullContainerClass() {
        const classes = [this.containerClass];
        if (this.disabled) classes.push("input-disabled");
        return classes.join(" ");
      },
    },
})
