export enum PlayerLevels {
    BEGINNER = 'principiante',
    INTERMEDIATE = 'intermedio',
    HIGH_INTERMEDIATE = 'intermedio alto',
    ADVANCED = 'avanzato',
    COMPETITION = 'competizione',
}

export enum TournamentTypes {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    MIXED = 'MIXED'
}

export const LevelOptions = [
    {value: 1, label: 'Principiante'},
    {value: 2, label: 'Intermedio'},
    {value: 3, label: 'Intermedio alto'},
    {value: 4, label: 'Avanzato'},
    {value: 5, label: 'Competizione'},
]

export const LevelMap = {
    1: PlayerLevels.BEGINNER,
    2: PlayerLevels.INTERMEDIATE,
    3: PlayerLevels.HIGH_INTERMEDIATE,
    4: PlayerLevels.ADVANCED,
    5: PlayerLevels.COMPETITION
}

export const InverseLevelMap = {
   [PlayerLevels.BEGINNER]: 1,
   [PlayerLevels.INTERMEDIATE]: 2,
   [PlayerLevels.HIGH_INTERMEDIATE]: 3,
   [PlayerLevels.ADVANCED]: 4,
   [PlayerLevels.COMPETITION]: 5,
}

export const TournamentOptions = [
    {value: TournamentTypes.MALE, label: 'Maschile'},
    {value: TournamentTypes.FEMALE, label: 'Femminile'},
    {value: TournamentTypes.MIXED, label: 'Misto'},
]

export enum TournamentStatus {
    CANCELLED = 'CANCELLED',
    IN_PROGRESS = 'IN_PROGRESS'
}