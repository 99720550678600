<ADDialog :style="customStyle" 
    :minimizable="minimizable" 
    :maximizable="maximizable" 
    :modal="modal" 
    v-model:visible="isVisible" 
    @hide="hideModal()"
    :breakpoints="breakpoints">

    <template class="header" #header>
        <img class="footer-logo" src="@/theme/imgs/logo_mark_white_gold.svg" alt="">
        <h3 class="modal-title">{{title}}</h3>
    </template>

    <slot></slot>

    <slot name="footer"></slot>

</ADDialog>