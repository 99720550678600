<div class="flexbox-column"
    :class="{'dark': dark, 'float-container': absolute === true, 'fake-container': absolute === false}"
    :style="style">

    <button class="p-dialog-header-icon p-dialog-header-close p-link" 
        @click="close" 
        v-if="absolute" 
        type="button" 
        tabindex="-1">
        <span class="p-dialog-header-close-icon pi pi-times-circle"></span>
    </button>
    
    <div class="flexbox-column align-center">
        <div v-if="arrowPosition === 'TOP'" 
            class="arrow-up"
            :class="alignRight ? 'align-right' : ''"></div>
        <div class="flexbox-row align-center">
            <div v-if="arrowPosition === 'LEFT'" class="arrow-left"></div>
            <div class="flexbox-row tooltip-container" v-if="label">
                <div v-html="t(label)" class="text-content montserrat"></div>
            </div>
            <div class="flexbox-column tooltip-container p-text-left" v-else-if="content">
                <div v-for="(value, label) in content"
                    :key="label">
                    <label class="montserrat">{{ label }}</label><br>
                    <span class="text-content montserrat">{{ value }}</span>
                </div>
            </div>
            <div v-if="arrowPosition === 'RIGHT'" class="arrow-right"></div>
        </div>
        <div v-if="arrowPosition === 'BOTTOM'" class="arrow-down"></div>
    </div>
</div>