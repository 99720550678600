import { ClubStatus } from "@/constants/club-status.enum";
import { basicChoices, allChoices } from "@/constants/dropdown-choices.constants";
import { PadelClub } from "@/models/club.models";
import { useStore } from "@/store/store";

export function postLoginPagesAvailable(myClub: PadelClub) {
    return (myClub.club_id && myClub.status !== ClubStatus.CREATION);
  }

  export function returnNavTooltipChoices() {
    const store = useStore();
    const clubs = store.getters.getMyClubs;
    if(clubs.length > 1) {
      return allChoices;
    } else {
      return basicChoices;
    }
  }