
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useStore } from "@/store/store";
import { uppercase } from "@/services/mapping.service";
import { useI18n } from "vue-i18n";
import { ActionTypes } from "@/store/action-types";
import { ClubStatus } from "@/constants/club-status.enum";
import { AdminPadelClub, PadelClub } from "@/models/club.models";
import { MutationTypes } from "@/store/mutation-types";
export default defineComponent({
  props: ["forceUnmountInfiniteScroll", "forceRefresh"],
  emits: ["clubClicked"],
  setup(props, { emit }) {
    const store = useStore();
    const { t, locale } = useI18n();
    const hoverEnabled = ref(false);
    const searchFormValue = ref("");

    const clubList = computed(function() {
      return store.getters.getAdminClubs;
    });

    async function getClubs(nameFilter: string) {
      try {
        store
          .dispatch(ActionTypes.GET_CLUBS, nameFilter)
          .then((list: PadelClub[]) => {
            const clubArray = list.filter(
              (val) =>
                val.status === ClubStatus.OPERATIVE ||
                val.status === ClubStatus.DISABLED
            );
            store.commit(MutationTypes.WRITE_ADMIN_CLUBS, clubArray);
            return clubArray;
          });
      } catch (error) {
        return;
      }
    }

    watch(searchFormValue, async function(currentValue, oldValue) {
      await getClubs(currentValue);
    });

    //FORZO IL REFRESH QUANDO SI RITORNA ALLA PAGINA DALLA PAGINA DI DETTAGLIO
    watch(
      () => props.forceRefresh,
      async (first, second) => {
        await getClubs(searchFormValue.value);
      }
    );

    async function goToClubDetail(value) {
      try {
        const detail = await store.dispatch(ActionTypes.CLUB_DETAIL, value.club_id);
        emit("clubClicked", detail);
      } catch(error) {
        return;
      }      
    }

    function returnChipClass(category: string) {
      if (category === ClubStatus.OPERATIVE) {
        return "background-green";
      } else if (category === ClubStatus.DISABLED) {
        return "background-gray";
      } else {
        return "";
      }
    }

    function searchValueChanged(value: string) {
      searchFormValue.value = value;
    }

    function eraseSearchValue() {
      searchFormValue.value = "";
    }

    return {
      t,
      locale,
      uppercase,
      goToClubDetail,
      returnChipClass,
      searchValueChanged,
      eraseSearchValue,

      clubList,
      hoverEnabled,
      searchFormValue,
    };
  },
});
