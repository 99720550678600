<label class="container"
    :class="fullContainerClass()">
    <input class="input"
        v-bind="$attrs" 
        type="checkbox" 
        :checked="checked" 
        :disabled="disabled"
        @change="onChange($event.target.checked)" />
    <span class="switch"></span>
    <span class="label"
        :class="labelClass">{{ label }}</span>
</label>